import React from "react";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import sortArray from "sort-array";
import EducationInfoDetails from "../Education/education_details";
import EducationInfoEdit from "../Education/education_edit";
import educationGetDetails from "../../../../../services/requests/student_educationdetails_get";
import { EDUCATION } from "resources/constants/strings";
import {
  CARD_STYLE,
  CARD_HEADING_STYLE,
  CARD_EDIT_ICON_STYLE,
} from "../../style.js";

export default function EducationInfo(props) {
  const [css, theme] = useStyletron();
  const [educationData, setEducationData] = React.useState();
  const [visible, setVisible] = React.useState(false);
  const [updateData, setUpdateData] = React.useState(false);

  React.useEffect(() => {
    educationGetDetails(localStorage.getItem("email")).then(function (
      response
    ) {
      if (response?.status === 200) {
        if (response?.data) {
          const sortedData = sortArray(response?.data, {
            by: "date_end",
            order: "desc",
          });
          setEducationData(sortedData);
          setUpdateData(false);
        }
      }
    });
  }, [updateData]);

  const eduInfoEdit = () => {
    setVisible(!visible);
  };
  const callbackFunction = (childData) => {
    setVisible(childData);
  };
  return (
    <Block className={css(CARD_STYLE(theme))}>
      <Block as="h3" className={css(CARD_HEADING_STYLE)} data-testid="title">
        {EDUCATION}
      </Block>
      {visible ? (
        <EducationInfoEdit
          eduData={educationData}
          parentCallback={callbackFunction}
          setUpdateData={setUpdateData}
          loader={props.loader}
        />
      ) : (
        <Block>
          <Block
            as="span"
            data-testid="edit"
            className={css(CARD_EDIT_ICON_STYLE(theme))}
            onClick={eduInfoEdit}
          >
            <svg width="12" height="12" viewBox="0 0 20 18">
              <path
                d="M0,14.2 L0,18 L3.8,18 L14.8,6.9 L11,3.1 L0,14.2 L0,14.2 Z M17.7,4 C18.1,3.6 18.1,3 17.7,2.6 L15.4,0.3 C15,-0.1 14.4,-0.1 14,0.3 L12.2,2.1 L16,5.9 L17.7,4 L17.7,4 Z M9,16 L7,18 L20,18 L20,16 L9,16 L9,16 Z"
                fill="currentColor"
              />
            </svg>
          </Block>
          <EducationInfoDetails
            eduData={educationData}
            updateData={updateData}
            loader={props.loader}
          />
        </Block>
      )}
    </Block>
  );
}
