import React from "react";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import {
  COMMON_BORDER_COLOR,
  COMMON_BORDER_WIDTH,
  COMMON_RADIUS,
} from "utils/style";
import { Input } from "baseui/input";
import closeIcon from "assets/images/close-btn-white.png";
import { useSetState, useTrackedState } from "store/store";

const COMMON_TEXT_STYLE = {
  fontFamily: "Manrope",
  lineHeight: "20px",
  fontWeight: 700,
};
const SEPARATOR = {
  margin: "30px 0px",
};
const HEADING_TEXT_STYLE = (theme) => ({
  ...COMMON_TEXT_STYLE,
  fontSize: "24px",
  width: "max-content",
  color: theme.RAColors?.black,
});
const LABEL_STYLE = (theme) => ({
  ...COMMON_TEXT_STYLE,
  fontFamily: "Manrope-medium",
  fontWeight: 500,
  lineHeight: "22px",
  margin: "0 0 9px",
  [theme.mediaQuery.small]: {
    fontSize: "14px",
  },
  [theme.mediaQuery.medium]: {
    fontSize: "16px",
  },
});
const INPUT_OVERRIDE_STYLE = {
  Input: ({ $theme }) => ({
    backgroundColor: "none",
    color: $theme.RAColors?.gray900,
  }),
  Root: ({ $theme }) => ({
    backgroundColor: "none",
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    width: "100%",
  }),
};
const ADD_TASK_STYLE = (theme) => ({
  cursor: "pointer",
  fontFamily: "Manrope-semibold",
  fontWeight: 700,
  lineHeight: "22px",
  color: "#3036B2",
  fontSize: "16px",
  marginTop: "15px",
});

export default function Traits({ title, moduleName, fieldName = 'Trait Name' }) {
  const [css, theme] = useStyletron();
  const AVATAR_STYLE = {
    width: "24px",
    height: "24px",
    borderRadius: "100%",
    cursor: "pointer",
  };
  const setState = useSetState();
  const state = useTrackedState();
  // let jobMap = key=="JobRoleTraits"? state?.jobObject?.JobRoleTraits : state?.jobObject?.JobRoleCompetencies

  const addForm = () => {
    const obj = state?.jobObject?.[moduleName];
    let lastId = obj?.length > 0 ? obj[obj.length - 1]?.id + 1 : 0;
    let forms;
    if (moduleName === "JobRoleTraits") {
      forms = {
        id: lastId,
        DesiredTrait: "",
      };
    } else {
      forms = {
        id: lastId,
        DOLCompetency: "",
      };
    }
    
    setState(
      (prev) => ({
        jobObject: {
          ...prev.jobObject,
          [moduleName]: [...obj, forms],
        },
      }),
      () => {}
    );
  };
  const removeForm = (formId) => {
    const obj = state?.jobObject?.[moduleName];

    const newArr = obj.filter((activity) => {
      return activity.id !== formId;
    });
    setState((prev) => ({
      jobObject: {
        ...prev.jobObject,
        [moduleName]: newArr,
      },
    }));
  };

  const changeValue = (name, value, index) => {
    const newData = state?.jobObject?.[moduleName].map((obj) => {
      if (obj.id === index) {
        return { ...obj, [name]: value };
      }
      return obj;
    });

    setState(
      (prev) => ({
        ...prev,
        jobObject: {
          ...prev.jobObject,
          [moduleName]: newData,
        },
      }),
      () => {}
    );
  };

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <Block>
      <Block width={["100%", "100%", "100%", "300px"]}>
        <Block className={css(HEADING_TEXT_STYLE(theme))} marginBottom="30px">
          {title + " (optional)"}
        </Block>
        <Block display="flex" flexDirection="column" gridGap="24px">
        {state?.jobObject?.[moduleName].length > 0 &&
          state?.jobObject?.[moduleName].map((item, index) => (
            <Block key={index}>
              <Block as="h5" className={css({ ...LABEL_STYLE(theme) })} data-testid="traitNameLabel">
                {fieldName}
              </Block>
              <Block display="flex" alignItems="center" gridGap="20px"   data-testid="trait_name_block">
                <Input
                  value={
                    moduleName === "JobRoleTraits"
                      ? item?.DesiredTrait
                      : item?.DOLCompetency
                  }
                  onChange={(e) =>
                    changeValue(
                      moduleName === "JobRoleTraits"
                        ? "DesiredTrait"
                        : "DOLCompetency",
                      capitalizeFirstLetter(e.target.value),
                      index
                    )
                  }
                  placeholder={"Enter Text here"}
                  clearOnEscape
                  data-testid="traitNameInput"
                  overrides={{
                    Input: { style: INPUT_OVERRIDE_STYLE.Input },
                    Root: { style: INPUT_OVERRIDE_STYLE.Root },
                  }}
                />
                <Block
                  as="img"
                  src={closeIcon}
                  alt="Close icon"
                  data-testid="remove-form"
                  className={css(AVATAR_STYLE)}
                  onClick={() => removeForm(item?.id)}
                />
              </Block>
            </Block>
          ))}
        </Block>
        <Block className={css(ADD_TASK_STYLE(theme))} data-testid="add-more" onClick={() => addForm()}>
          {"Add more"}
        </Block>
      </Block>
      <Block className={css(SEPARATOR)} />
    </Block>
  );
}
