import { LEARNER_LIST } from "../apiEndpoint";
import axios from "axios";
import { studentURL } from "../config";

const LinkforceControllerLearnersList = axios.create({
  baseURL: studentURL
})

LinkforceControllerLearnersList.defaults.headers.common = {
  ...(LinkforceControllerLearnersList.defaults.headers.common || {}),
  ApiKey: 'TESTDEV-0000-1111-2222-333333333333'
}

const studentList = (course_name) => LinkforceControllerLearnersList.get(`${LEARNER_LIST}?`)

export default studentList;
