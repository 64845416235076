import { Block } from "baseui/block"
import { useStyletron } from "baseui";
import ForwardArrowIcon from "assets/images/forward-arrow-icon.png";
import {
  COMMON_BORDER_COLOR,
  COMMON_BORDER_WIDTH,
  COMMON_RADIUS,
  COMMON_BORDER_STYLE,
} from "utils/style";
import { Checkbox, STYLE_TYPE } from 'baseui/checkbox';
import { useState } from "react";
import { Button } from "baseui/button";
import { Modal, ModalBody, SIZE, ROLE } from "baseui/modal";
import { ButtonGroup } from "baseui/button-group";

const TILE_STYLE = (theme) => ({
  backgroundColor: "white",
  borderRadius: "16px",
  border: `1px solid ${theme.RAColors?.gray200}`,
  height: '64px',
  width: 'auto',
  paddingLeft: '16px',
  paddingRight: '16px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

const PANEL_TITLE_STYLE = (theme) => ({
  display: "flex",
  alignItems: "center",
  fontFamily: "Manrope-semibold",
  color: theme.RAColors?.black,
  fontSize: "18px",
  justifyContent: 'center',
});

const ARROW_STYLE = theme => ({
  width: "20px",
  height: "20px",
  objectFit: 'contain',

  [theme.mediaQuery.small]: {
    transform: 'rotate(90deg)',
  },
  [theme.mediaQuery.medium]: {
    transform: 'none',
  },
});

const STATUS = {
  fontSize: "14px",
  lineHeight: "20px",
  fontFamily: "Manrope",
  marginTop: '4px'
}

const LABEL_STYLE = {
  fontFamily: "Manrope-Semibold",
  fontSize: "18px",
};

const CATEGORY = (theme) => ({
  borderTop: `1px solid ${theme.RAColors?.gray200}`,
  paddingTop: "20px",
});

const COMMON_BUTTON_STYLE = {
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingRight: "24px",
  paddingLeft: "24px",
  lineHeight: "22px",
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  fontFamily: "Manrope-Bold",
};

const DUPLICATE_BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_BUTTON_STYLE,
  ...COMMON_BORDER_COLOR($theme.RAColors?.purple),
  backgroundColor: "white",
  ":hover": { backgroundColor: $theme.RAColors?.gray300 },
  color: $theme.RAColors?.purple,
});

const MODAL_OVERRIDE_STYLE = {
  Dialog: {
    style: ({ $theme }) => ({
      ...COMMON_RADIUS("8px"),
    }),
  },
};
const MODAL_BODY_STYLE = (theme) => ({
  margin: "24px !important",
  [theme.mediaQuery.small]: {
    margin: "16px !important",
  },
  [theme.mediaQuery.medium]: {
    margin: "24px !important",
  },
});

const TITLE_STYLE = (theme) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "16px",
  flexWrap: "wrap",

  [theme.mediaQuery.small]: {
    marginBottom: "16px",
  },
  [theme.mediaQuery.medium]: {
    marginBottom: "36px",
  },
});

const HEADING_TEXT_STYLE = (theme) => ({
  fontSize: "24px",
  lineHeight: '32px',
  fontFamily: 'Manrope-Bold',
  color: theme.RAColors?.black,
});

const TOGGLE_OVERRIDE_STYLE = {
  Root: () => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  }),
  Label: () => ({
    fontFamily: 'Manrope'
  })
}

const SAVE_BTN_STYLE = ({ $theme }) => ({
  ...COMMON_BUTTON_STYLE,
  ...COMMON_BORDER_COLOR($theme.RAColors?.blue700),
  backgroundColor: $theme.RAColors?.blue700,
  ":hover": {
    backgroundColor: $theme.RAColors?.blue800,
  },
  color: "#FFFFFF",
});
const CANCEL_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_BUTTON_STYLE,
  backgroundColor: "white",
  borderColor: $theme.RAColors?.purple,
  ":hover": { backgroundColor: $theme.RAColors?.gray300 },
  color: $theme.RAColors?.purple,
  marginLeft: '16px'
});

const Flow = ({ isCriteria = true, flow, saveFlow }) => {
  const [css, theme] = useStyletron()
  const [showSettings, setShowSettings] = useState(false)
  const [settings, setSettings] = useState({ ...flow })

  const toggleList = [
    ...(isCriteria ? [
      { name: 'RTI', key: 'rti' },
      { name: 'JS', key: 'js' },
    ] : []),
    { name: 'OJT', key: 'ojt' },
    { name: 'PROFICIENT - Manager Sign Off', key: 'proficientmanager' },
    { name: 'PROFICIENT - Learner Sign Off', key: 'proficientlearner' },
    { name: 'Final Approval - Training Manager Sign Off', key: 'finalapproval' },
  ]

  const cancel = () => {
    setShowSettings(false)
    setSettings({ ...flow })
  }

  const save = () => {
    setShowSettings(false)
    saveFlow({ ...settings })
  }

  return (
    <Block className={css(CATEGORY(theme))}>
      <Block display="flex" justifyContent="space-between" alignItems="center" marginBottom="20px">
        <Block className={css(LABEL_STYLE)} data-testid="flow_text">{isCriteria ? 'Criteria' : 'Activity'} Flow</Block>
        <Button
          overrides={{ BaseButton: { style: DUPLICATE_BUTTON_OVERRIDE_STYLE } }}
          onClick={() => setShowSettings(true)}
          data-testid="edit_text"
        >
          Edit
        </Button>
      </Block>
      <Block display="flex" alignItems="center" flexDirection={["column", 'column', 'row']}>
        {isCriteria && flow.rti && (
          <>
            <Block className={css(TILE_STYLE(theme))}>
              <Block className={css(PANEL_TITLE_STYLE(theme))} data-testid="rti_text">RTI</Block>
            </Block>
            <Block
              as="img"
              src={ForwardArrowIcon}
              alt="User Avatar"
              className={css(ARROW_STYLE(theme))}
            />
          </>
        )}
        <Block display="flex" flexDirection={['row', 'row', 'column']} gridGap="4px">
          {flow.js && (
            <Block className={css(TILE_STYLE(theme))}>
              <Block className={css(PANEL_TITLE_STYLE(theme))} data-testid="js_text">JS</Block>
            </Block>  
          )}
          {flow.ojt && (
            <Block className={css(TILE_STYLE(theme))}>
              <Block className={css(PANEL_TITLE_STYLE(theme))} data-testid="ojt_text">OJT</Block>
            </Block>
          )}
        </Block>
        {(flow.js || flow.ojt) && (
          <Block
            as="img"
            src={ForwardArrowIcon}
            alt="User Avatar"
            className={css(ARROW_STYLE(theme))}
          />
        )}
        {flow.proficientmanager && (
          <>
            <Block className={css(TILE_STYLE(theme))}>
              <Block>
                <Block className={css(PANEL_TITLE_STYLE(theme))} data-testid="proficient_manager_text">Proficient</Block>
                <Block className={css(STATUS)}>Manager Sign Off</Block>
              </Block>
            </Block>
            <Block
              as="img"
              src={ForwardArrowIcon}
              alt="User Avatar"
              className={css(ARROW_STYLE(theme))}
            />
          </>
        )}
        {flow.proficientlearner && (
          <>
            <Block className={css(TILE_STYLE(theme))}>
              <Block>
                <Block className={css(PANEL_TITLE_STYLE(theme))} data-testid="proficient_learner_text">Proficient</Block>
                <Block className={css(STATUS)}>Learner Sign Off</Block>
              </Block>
            </Block>
            <Block
              as="img"
              src={ForwardArrowIcon}
              alt="User Avatar"
              className={css(ARROW_STYLE(theme))}
            />
          </>
        )}
        {flow.finalapproval && (
          <Block className={css(TILE_STYLE(theme))}>
            <Block>
              <Block className={css(PANEL_TITLE_STYLE(theme))} data-testid="final_approval_text">Final Approval</Block>
              <Block className={css(STATUS)}>Training Manager Sign Off</Block>
            </Block>
          </Block>
        )}
      </Block>
      

      <Modal
        onClose={() => setShowSettings(false)}
        closeable
        isOpen={showSettings}
        animate
        autoFocus={false}
        focusLock
        size={SIZE.default}
        role={ROLE.dialog}
        overrides={MODAL_OVERRIDE_STYLE}
      >
        <ModalBody className={css(MODAL_BODY_STYLE(theme))}>
          <Block
            display="block"
            justifyContent="space-between"
            alignItems="center"
            gridGap="16px"
            marginBottom={["16px", "16px", "20px"]}
            flexWrap="wrap"
          >
            <Block className={css(TITLE_STYLE(theme))}>
              <Block as="h5" margin={0} className={css(HEADING_TEXT_STYLE(theme))} data-testid="flow_setting_text">
                {isCriteria ? 'Criteria' : 'Activity'} Flow Settings
              </Block>
            </Block>
            <Block display="flex" flexDirection="column" gridGap="8px">
              {toggleList.map((item, index) => (
                <Checkbox
                  data-testid="flow_setting_checkbox"
                  key={index}
                  checked={settings[item.key]}
                  onChange={e => {
                    setSettings({ ...settings, [item.key]: e.currentTarget.checked })
                  }}
                  checkmarkType={STYLE_TYPE.toggle}
                  overrides={{
                    Root: { style: TOGGLE_OVERRIDE_STYLE.Root },
                    Label: { style: TOGGLE_OVERRIDE_STYLE.Label }
                  }}
                  disabled={item.key === 'finalapproval'}
                >
                  {item.name}
                </Checkbox>
              ))}
            </Block>
            <Block display="flex" justifyContent="end" marginTop="24px">
              <ButtonGroup>
                <Button
                  type="submit"
                  onClick={save}
                  data-testid="save_btn"
                  overrides={{ BaseButton: { style: SAVE_BTN_STYLE } }}
                >
                  Save
                </Button>
                <Button
                  onClick={cancel}
                  data-testid="cancel_btn"
                  overrides={{ BaseButton: { style: CANCEL_OVERRIDE_STYLE } }}
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </Block>
          </Block>
        </ModalBody>
      </Modal>
    </Block>
  )
}

export default Flow