import { EDUCATION_GET_UPDATE } from "../apiEndpoint";
import { studentAxios } from "../http/http";

export default function educationGetDetails(useremail, payload) {
  return studentAxios.get(EDUCATION_GET_UPDATE + "?user_email=" + useremail, {
    headers: {
      "ApiKey":process.env.REACT_APP_STUDENT_GO_APIKEY
    },
  });
}
