import * as React from "react";
import { Link } from "react-router-dom";
import * as PropTypes from "prop-types";
import logo from "assets/svg/logo.svg";
import logoText from "assets/images/logo-text.png";
import userAvatar from "assets/images/user-profile.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff, faSliders } from "@fortawesome/free-solid-svg-icons";
import { Settings, Logout } from "resources/constants/strings.js";
import "./styles.css";
import { useTrackedState } from "store/store";
import { VERSION } from "resources/version";
import { Popover as BasePopover } from "baseui/popover";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import { ChevronDown } from "baseui/icon";
import KeyCloakService from "services/http/KeycloakService";
import { Select } from "baseui/select";
import { useHistory } from "react-router-dom";
import {
  COMMON_BORDER_COLOR,
  COMMON_BORDER_WIDTH,
  COMMON_RADIUS,
} from "utils/style";

const POPOVER_STYLE = {
  zIndex: 10000,
};

const PROFILE_POPUP_INNER_STYLE = {
  display: "flex",
  padding: "0 16px",
  cursor: "pointer",
};

const PROFILE_OPTION_ICON_STYLE = {
  width: "40px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const PROFILE_OPTION_TEXT_STYLE = {
  fontFamily: "Manrope-Bold",
  fontSize: "14px",
  lineHeight: "25px",
  color: "#3d3d3d",
  paddingLeft: "10px",
};

const SEPRATOR = {
  borderBottom: "1px solid #e0e0e0",
  margin: "10px 0px",
};

const NAME_TEXT = {
  fontFamily: "Manrope-Semibold",
  fontStyle: "normal",
  fontSize: "16px",
  lineHeight: "22px",
  color: "#3d3d3d",
  textTransform: "capitalize",
};

const EMAIL_TEXT = {
  fontSize: "13px",
  color: "rgba(0, 0, 0, 0.75)",
  display: "block",
  fontFamily: "Manrope-Semibold",
};

const CONTAINER = (theme) => ({
  height: "68px",
  backgroundColor: "white",
  borderBottom: "2px solid rgba(103, 120, 138, 0.1)",
  position: "sticky",
  zIndex: 9999,
  padding: "8px",
  boxSizing: "border-box",
  fontSize: "14px",
  lineHeight: 2,
  display: "flex",
  gap: "8px",
  alignItems: "center",
  justifyContent: "space-between",
  overflow: "hidden",
  [theme.mediaQuery.small]: {
    height: "fit-content",
    display: "block",
  },
  [theme.mediaQuery.medium]: {
    height: "fit-content",
    display: "flex",
  },
  [theme.mediaQuery.large]: {
    height: "68px",
    display: "flex",
  },
});
const MAILTO_CONTAINER = (theme) => ({
  display: "block",
  justifyContent: "flex-start",
  [theme.mediaQuery.small]: {
    display: "flex",
    justifyContent: "center",
  },
  [theme.mediaQuery.medium]: {
    display: "block",
  },
});

const VERSION_STYLE = (theme) => ({
  marginLeft: "8px",
  [theme.mediaQuery.small]: {
    fontSize: "12px",
  },
  [theme.mediaQuery.medium]: {
    fontSize: "16px",
  },
});
const HEADING = {
  fontFamily: "Manrope-Medium",
  fontSize: "14px",
  lineHeight: "20px",
  display: "inline-block",
  marginRight: "4px",
};
const View_AS = {
  display: "inline-block",
  marginLeft: "4px",
};

export const SELECT_OVERRIDE_STYLE = {
  ControlContainer: ({ $theme, $disabled }) => ({
    backgroundColor: "none",
    opacity: $disabled ? 0.5 : 1,
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    color: $theme.RAColors?.gray900,
  }),
};

const ButtonMailto = ({ mailto, label }) => {
  const [css] = useStyletron();

  return (
    <Link
      to="#"
      onClick={(e) => {
        window.location.href = mailto;
        e.preventDefault();
      }}
      className={css({ fontFamily: "Manrope" })}
    >
      {label}
    </Link>
  );
};

const OPTIONS = [
  { label: "ADMIN", id: 1 },
  { label: "TENANT OWNER", id: 2 },
  { label: "MANAGER", id: 3 },
  { label: "MENTOR", id: 4 },
  { label: "LEARNER", id: 5 },
];

export default ButtonMailto;
export const Header = (props) => {
  const [css, theme] = useStyletron();
  const history = useHistory();
  const [visible, setVisible] = React.useState(false);
  const state = useTrackedState();
  const [value, setValue] = React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  React.useEffect(() => {
    if (
      localStorage.getItem("role") === "ADMIN" &&
      localStorage.getItem("viewAs") === null
    ) {
      localStorage.setItem("viewAs", localStorage.getItem("role"));
    }
  });

  if (state?.image) {
    localStorage.setItem("image", state.image);
  }

  const reset = () => {
    localStorage.setItem("viewAs", localStorage.getItem("resetViewAs"));
    localStorage.setItem("email", localStorage.getItem("resetEmail"));
    localStorage.setItem("lastName", localStorage.getItem("resetLastName"));
    localStorage.setItem("username", localStorage.getItem("resetUsername"));
    localStorage.setItem("firstName", localStorage.getItem("resetFirstName"));
    localStorage.setItem("role", localStorage.getItem("resetRole"));
    localStorage.setItem("superAdmin", null);
    history.push("/student");
  };

  const handlePopUp = () => {
    setVisible(!visible);
  };

  // const logout = () => {
  //   localStorage.clear();
  //   localStorage.setItem("logout","1");

  //   KeyCloakService.initKeycloakForSSO(()=>{
  //   //  let logoutOptions = { redirectUri : `${process.env.REACT_APP_LOGOUT_REDIRECT_URL}` }
  //     KeyCloakService.doLogout()

  //   },()=>{})

  // };

  const logout = () => {
    localStorage.clear();
    localStorage.setItem("logout", "1");
    let reloadVal;
    if (window.performance) {
      if (performance.navigation.type == 1) {
        setRefresh(true);
        reloadVal = 1;
      }
    }
    if (refresh || reloadVal === 1) {
      KeyCloakService.initKeycloakForSSO(
        () => {
          //     //let logoutOptions = { redirectUri : `${process.env.REACT_APP_LOGOUT_REDIRECT_URL}` }
          KeyCloakService.doLogout();
        },
        () => {}
      );
    } else {
      KeyCloakService.doLogout();
      //localStorage.clear();
      //history.push('/login')
    }
  };

  const onChangeRole = (params) => {
    setValue(params.value);
    localStorage.setItem("viewAs", params.value[0].label);
    history.push("/sso-home");
  };

  return (
    <Block className={css(CONTAINER(theme))}>
      <Block marginLeft={["40px", "40px", 0]}>
        <Link to="/student">
          <Block display="flex" alignItems="center">
            <Block as="img" src={logo} alt="" width="36px" />
            <Block
              paddingLeft={["8px", "8px", "16px"]}
              display="flex"
              alignItems="center"
            >
              <Block as="img" src={logoText} alt="" height="16px" />
              <Block as="b" className={css(VERSION_STYLE(theme))}>
                <Block display={["none", "none", "inline-block"]}>BETA</Block>{" "}
                {VERSION}
              </Block>
            </Block>
          </Block>
        </Link>
      </Block>
      {/* <Block display={["none", "none", "block"]}> */}
      {/* <Block className={css(MAILTO_CONTAINER(theme))}>
        <ButtonMailto
          mailto="mailto:platformrequests@rapidascent.io?Subject=feature%20request"
          label="Submit Feature Request"
        />
      </Block> */}
      <Block display="flex" justifyContent="space-between">
        <Block style={{ display: "inline-flex", alignItems: "center" }}>
          {localStorage.getItem("superAdmin") === "RA-ADMIN" ? (
            <Block
              style={{
                margin: "5px 30px",
                padding: "10px",
                borderRadius: 10,
                color: "#fff",
                backgroundColor: "rgb(58, 63, 223)",
                cursor: "pointer",
              }}
              marginBottom="4px"
              className={css(HEADING)}
              data-testid="job-group-text"
              onClick={reset}
            >
              RESET
            </Block>
          ) : null}
          {localStorage.getItem("role") === "ADMIN" ? (
            <Block style={{ display: "block" }}>
              <Block
                marginBottom="4px"
                className={css(HEADING)}
                data-testid="job-group-text"
              >
                View as
              </Block>

              <Block className={css(View_AS)}>
                <Select
                  clearable={false}
                  searchable={false}
                  options={OPTIONS}
                  value={OPTIONS.filter(
                    (a) => a.label === localStorage.getItem("viewAs")
                  )}
                  data-testid="job-group-input"
                  onChange={(params) => onChangeRole(params)}
                  overrides={{
                    Root: {
                      style: ({ $theme }) => ({
                        width: "100%",
                        [$theme.mediaQuery.medium]: {
                          width: "200px",
                        },
                      }),
                    },
                    ControlContainer: {
                      style: SELECT_OVERRIDE_STYLE.ControlContainer,
                    },
                  }}
                />
              </Block>
            </Block>
          ) : null}
        </Block>

        <BasePopover
          isOpen={visible}
          placement="bottomRight"
          autoFocus={false}
          overrides={{
            Body: { style: POPOVER_STYLE },
          }}
          content={
            <Block
              width="305px"
              className={css({
                borderRadius: "8px",
                padding: "16px 0px",
                background: "white",
              })}
              onMouseLeave={handlePopUp}
            >
              <Block className={css(PROFILE_POPUP_INNER_STYLE)}>
                <Block className={css(PROFILE_OPTION_ICON_STYLE)}>
                  <img
                    src={
                      localStorage.getItem("image") !== null &&
                      localStorage.getItem("image") !== "null" &&
                      localStorage.getItem("image") !== "" &&
                      localStorage.getItem("image") !== undefined
                        ? localStorage.getItem("image")
                        : userAvatar
                    }
                    alt="Avatar"
                    height="25"
                  />
                </Block>
                <Block paddingLeft="10px">
                  <Block as="span" className={css(NAME_TEXT)}>
                    {localStorage.getItem("username")}
                  </Block>
                  {/* <Block as="span" className={css(EMAIL_TEXT)}>
                  {localStorage.getItem("email")}
                </Block> */}
                </Block>
              </Block>
              <Block className={css(SEPRATOR)} />
              <Block className={css(PROFILE_POPUP_INNER_STYLE)}>
                <Block className={css(PROFILE_OPTION_ICON_STYLE)}>
                  <FontAwesomeIcon icon={faSliders} />
                </Block>
                <Link to="/personal-info">
                  <Block
                    as="span"
                    className={css(PROFILE_OPTION_TEXT_STYLE)}
                    onClick={handlePopUp}
                  >
                    {Settings}
                  </Block>
                </Link>
              </Block>
              <Block className={css(SEPRATOR)} />
              <Block className={css(PROFILE_POPUP_INNER_STYLE)}>
                <Block className={css(PROFILE_OPTION_ICON_STYLE)}>
                  <FontAwesomeIcon icon={faPowerOff} />
                </Block>
                <Block
                  as="span"
                  className={css(PROFILE_OPTION_TEXT_STYLE)}
                  onClick={logout}
                >
                  {Logout}
                </Block>
              </Block>
            </Block>
          }
        >
          <Block
            className={css({
              cursor: "pointer",
              padding: "0 8px",
              borderRadius: "4px",
              backgroundColor: visible ? "#eaeeff" : "transparent",
            })}
          >
            <Block onClick={handlePopUp} display="flex" alignItems="center">
              <Block paddingTop="10px">
                <Block
                  as="img"
                  className={css({ borderRadius: "50%" })}
                  src={
                    localStorage.getItem("image") !== null &&
                    localStorage.getItem("image") !== "null" &&
                    localStorage.getItem("image") !== "" &&
                    localStorage.getItem("image") !== undefined
                      ? localStorage.getItem("image")
                      : userAvatar
                  }
                  alt="Avatar"
                  height="25px"
                />
              </Block>
              <Block
                padding="10px 0 10px 10px"
                className={css({
                  fontFamily: "Manrope-SemiBold",
                  textTransform: "capitalize",
                })}
                display={["none", "none", "none", "block"]}
              >
                {localStorage.getItem("username")}
              </Block>
              <ChevronDown size={24} />
            </Block>
          </Block>
        </BasePopover>
      </Block>
    </Block>
  );
};

Header.displayName = "Header";
Header.propTypes = {
  page: PropTypes.string,
  onButtonClick: PropTypes.func,
};
