import React from "react";
import { Block } from "baseui/block";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { useStyletron } from "baseui";
import CircleIcon from "assets/images/circle-complete.svg";
import RightCircleIcon from "assets/images/right-circleTick.svg";
import ChatIconBlank from "assets/images/chat-blank.svg";
import ChatIcon from "assets/images/chat-grey.svg";
import CompetencyLevel1 from "assets/images/comp_level 1.svg";
import CompetencyLevel2 from "assets/images/comp_level 2.svg";
import CompetencyLevel3 from "assets/images/comp_level 3.svg";
import RightCircleGrayIcon from "assets/images/right-circleTickGray.svg";
import dayjs from "dayjs";
import VerifyCompetencyPopUp from "../dolCompetency/verifyCompetencyPopUp";

const BLOCK_STYLE = (theme) => ({
  backgroundColor: theme.RAColors?.emerald50,
  ":hover": {
    backgroundColor: "rgba(12, 57, 26, 0.08)",
    // border: `1px solid rgba(12, 57, 26, 0.08)`,
  },
  borderRadius: "16px",
  padding: "16px",
  border: `1px solid ${theme.RAColors?.emerald500}`,
  width: "auto",
  [theme.mediaQuery.small]: {
    width: "max-content",
  },
  [theme.mediaQuery.medium]: {
    width: "max-content",
  },
  [theme.mediaQuery.large]: {
    width: "auto",
  },
});
const CONTENT_BLOCK_STYLE = (theme) => ({
  display: "flex",
  gridGap: "7px",
  alignItems: "center",
  // ":hover": {
  //   backgroundColor: "rgba(12, 57, 26, 0.08)",
  //   borderRadius: "12px",
  // },
});
const OTHER_BLOCK_STYLE = (theme) => ({
  backgroundColor: "white",
  borderRadius: "16px",
  border: `1px solid ${theme.RAColors?.gray200}`,
  width: "auto",
  [theme.mediaQuery.small]: {
    padding: "16px",
    width: "max-content",
  },
  [theme.mediaQuery.medium]: {
    padding: "24px",
    width: "max-content",
  },
  [theme.mediaQuery.large]: {
    padding: "16px",
    width: "auto",
  },
});
const ACTIVE_BLOCK_STYLE = (theme) => ({
  backgroundColor: "white",
  borderRadius: "16px",
  border: `1px solid black`,
  width: "auto",
  [theme.mediaQuery.small]: {
    padding: "16px",
    width: "max-content",
  },
  [theme.mediaQuery.medium]: {
    padding: "24px",
    width: "max-content",
  },
  [theme.mediaQuery.large]: {
    padding: "16px",
    width: "auto",
  },
});
const PANEL_TITLE_STYLE = (theme) => ({
  display: "flex",
  alignItems: "center",
  fontFamily: "Manrope-semibold",
  fontWeight: 600,
  lineHeight: "19px",
  color: theme.RAColors?.black,
  fontSize: "14px",
});
const VERIFY_TEXT_STYLE = (theme) => ({
  fontFamily: "Manrope",
  fontWeight: 400,
  lineHeight: "16px",
  color: theme.RAColors?.gray700,
  fontSize: "14px",
});
const SUB_TITLE_STYLE = (theme) => ({
  fontFamily: "Manrope-Medium",
  fontWeight: 500,
  lineHeight: "16px",
  color: theme.RAColors?.gray700,
  fontSize: "12px",
  cursor: "pointer",
});
const CHAT_STYLE = { width: "8px", height: "8px", padding: "6px" };
const CHAT_BOX_STYLE = {
  borderRadius: "50%",
  backgroundColor: "rgba(2, 31, 11, 0.1)",
  display: "flex",
};
const SEPARATOR = {
  borderBottom: "1px solid #e0e0e0",
  margin: "20px 0px",
};
const ConditionalTaskBlock = ({
  item,
  itemData,
  categoryData,
  handleClick,
  changeDolData,
  selectedIndex,
  rolespermission,
  isRoleSpecificActivity,
  index,
}) => {
  
  const [css, theme] = useStyletron();
  const setTitle = (itemName) => {
    let title;
    if (itemName === "RTI") {
      title = "Related Technical Instruction";
    } else if (itemName === "JS" || itemName === "JSStatus") {
      title = "Job Simulation";
    } else if (
      itemName === "OJS" ||
      itemName === "OJT" ||
      itemName === "OJTStatus"
    ) {
      title = "On the Job Simulation";
    } else if (itemName === "LearnerProficientStatus") {
      title = "Learner Sign-Off";
    } else if (itemName === "MentorProficientStatus") {
      title = "Technical Sign-Off";
    } else if (itemName === "FinalApprovalStatus") {
      title = "Final Sign-Off";
    }
    return title;
  };
  const filteredactions = rolespermission?.filter(
    (obj) => obj.Action_name === setTitle(item)
  );
  const showCommentAndStatusElementBasedOnTaskStatus = (
    taskName,
    itemObject,
    changeTaskStatus = false
  ) => {
    let preDefinedSignedTask = ["RTI", "RTIStatus", "JS", "JSStatus"];
    let definedSignedTask = [
      // "OJT",
      // "OJTStatus",
      "MentorProficientStatus",
      "LearnerProficientStatus",
      "FinalApprovalStatus",
    ];
    let isOJTEnable = itemObject?.OJT;
    let availableListedTask = Object.keys(itemObject);
    let show = false;
    let filteredAvailableListedTask = definedSignedTask.filter(function (n) {
      return availableListedTask.indexOf(n) !== -1;
    });
    definedSignedTask = definedSignedTask.filter(function (n) {
      return availableListedTask.indexOf(n) !== -1;
    });
    if (preDefinedSignedTask.includes(taskName)) {
      for (const element of preDefinedSignedTask) {
        if (taskName === element) {
          return show;
        }
      }
    }
    preDefinedSignedTask = preDefinedSignedTask.filter(function (n) {
      return availableListedTask.indexOf(n) !== -1;
    });
    const calculateDefinedSignedTask = () => {
      if (definedSignedTask.includes(taskName)) {
        for (let i = 0; i < definedSignedTask.length; i++) {
          let definedTaskName = definedSignedTask[i];
          let previousDefinedTaskName = definedSignedTask[i - 1];
          let nextDefinedTaskName = definedSignedTask[i + 1];

          if (itemObject[previousDefinedTaskName]?.CompletionStatus === false) {
            return false;
          } else {
            if (taskName === definedTaskName) {
              if (
                itemObject[nextDefinedTaskName]?.CompletionStatus === false &&
                (isOJTEnable || isRoleSpecificActivity)
              ) {
                return true;
              } else if (
                itemObject[nextDefinedTaskName]?.CompletionStatus ===
                  undefined &&
                (isOJTEnable || isRoleSpecificActivity)
              ) {
                return true;
              }
              for (const ele of filteredAvailableListedTask) {
                if (
                  ele === taskName &&
                  itemObject[ele]?.CompletionStatus === false &&
                  (isOJTEnable || isRoleSpecificActivity)
                ) {
                  return true;
                }
                if (
                  ("FinalApprovalStatus" === taskName ||
                    "LearnerProficientStatus" === taskName) &&
                  !isOJTEnable
                ) {
                  return true;
                }
              }
              return false;
            }
          }
        }
      }
    };
    if (preDefinedSignedTask.length >= 1) {
      let show = 1;

      for (const element of preDefinedSignedTask) {
        if (itemObject[element]?.CompletionStatus === true) {
          show = show * 1;
        } else {
          show = show * 0;
        }
      }
      if (show === 0) {
        return false;
      } else {
        return calculateDefinedSignedTask();
      }
    } else {
      return calculateDefinedSignedTask();
    }
  };
  const checkRTIJSEnable = (taskName, itemData) => {
    if (taskName === "RTI") {
      return true;
    } else if (taskName === "JS" && itemData["RTI"]?.CompletionStatus) {
      return true;
    } 
    else if (taskName === "JS" && !itemData?.hasOwnProperty("RTI")) {
      return true;
    } 
    else {
      return false;
    }
  };
  const getAvailableCommentsStatus = (commentsList) => {
    const newArray = commentsList?.filter((a) => a.IsDeleted !== true);
    if (newArray?.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      {itemData[item]?.CompletionStatus ? (
        <Block
          key={index}
          display="inline-block"
          alignItems="center"
          flexDirection="row"
          data-testid="tasks-onclick-block"
          gridGap="10px"
          style={{ cursor: "pointer" }}
          className={
            itemData[item]?.CompletionStatus === true
              ? css({ ...BLOCK_STYLE(theme) })
              : css(OTHER_BLOCK_STYLE(theme))
          }
        >
          <Block
            className={css(CONTENT_BLOCK_STYLE(theme))}
            data-testid="tasks-onclick-block"
          >
            {showCommentAndStatusElementBasedOnTaskStatus(item, itemData) ? (
              <StatefulPopover
                content={() => (
                  <VerifyCompetencyPopUp
                    data={itemData}
                    taskData={item}
                    changeDolData={changeDolData}
                    selectedIndex={selectedIndex}
                    rolespermission={rolespermission}
                    isRoleSpecificActivity={isRoleSpecificActivity}
                  />
                )}
                triggerType={TRIGGER_TYPE.click}
                placement="rightBottom"
                showArrow
                returnFocus
                autoFocus
              >
                <Block
                  as="img"
                  data-testid="conditional-status-icon"
                  src={
                    itemData[item]?.ChangeStatusText === "Not Yet Rated" ||
                    itemData[item]?.ChangeStatusText === ""
                      ? CircleIcon
                      : itemData[item]?.ChangeStatusText ===
                        "Learning- Observed through job training or shadowing"
                      ? CompetencyLevel1
                      : itemData[item]?.ChangeStatusText ===
                        "With help - Completes with assistance"
                      ? CompetencyLevel2
                      : itemData[item]?.ChangeStatusText ===
                        "On their own - Completes independently, with review and guidance"
                      ? CompetencyLevel3
                      : itemData[item]?.ChangeStatusText ===
                  "Unable to Assess - Did not get opportunity to validate on the job due to available work"
                ? RightCircleGrayIcon
                      : RightCircleIcon
                  }
                  alt="Status Circle"
                  style={{ height: "20px", width: "20px" }}
                />
              </StatefulPopover>
            ) : (
              <Block
                as="img"
                data-testid="conditional-status-icon"
                src={
                  itemData[item]?.ChangeStatusText === "Not Yet Rated" ||
                  itemData[item]?.ChangeStatusText === ""
                    ? CircleIcon
                    : itemData[item]?.ChangeStatusText ===
                      "Learning- Observed through job training or shadowing"
                    ? CompetencyLevel1
                    : itemData[item]?.ChangeStatusText ===
                      "With help - Completes with assistance"
                    ? CompetencyLevel2
                    : itemData[item]?.ChangeStatusText ===
                      "On their own - Completes independently, with review and guidance"
                    ? CompetencyLevel3
                    : itemData[item]?.ChangeStatusText ===
                    "Unable to Assess - Did not get opportunity to validate on the job due to available work"
                  ? RightCircleGrayIcon
                    : RightCircleIcon
                }
                alt="Status Circle"
                style={{ height: "20px", width: "20px" }}
              />
            )}
            <StatefulPopover
              content={() => (
                <Block
                  className={css(OTHER_BLOCK_STYLE(theme))}
                  data-testid="tasks-block"
                >
                  <Block
                    className={css(PANEL_TITLE_STYLE(theme))}
                    data-testid="tasks-title"
                  >
                    {setTitle(item)}
                  </Block>
                  <Block className={css(SEPARATOR)} />
                  <Block
                    className={css(VERIFY_TEXT_STYLE(theme))}
                    data-testid="tasks-verified-date"
                  >
                    {"Verified on " +
                      dayjs(itemData[item]?.CompletionDate).format(
                        "MM/DD/YYYY"
                      )}
                  </Block>
                </Block>
              )}
              triggerType={TRIGGER_TYPE.hover}
              placement="rightBottom"
              showArrow
              returnFocus
              autoFocus
            >
              <Block
                overflow="hidden"
                onClick={() =>
                  handleClick(
                    itemData,
                    item,
                    categoryData,
                    isRoleSpecificActivity
                  )
                }
              >
                <Block
                  className={css(PANEL_TITLE_STYLE(theme))}
                  data-testid="tasks-title-text"
                >
                  {itemData[item]?.TaskName === "MentorProficientStatus" ||
                  item === "MentorProficientStatus"
                    ? "Technical Sign-Off"
                    : itemData[item]?.TaskName === "FinalApprovalStatus" ||
                      item === "FinalApprovalStatus"
                    ? "Final Sign-Off"
                    : item === "JSStatus"
                    ? "Job Simulation"
                    : item === "OJTStatus"
                    ? "OJT"
                    : item === "RTI"
                    ? "Related Technical Instruction"
                    : item === "JS"
                    ? "Job Simulation"
                    : item}
                </Block>
                <Block
                  display="flex"
                  marginTop="4px"
                  gridGap="4px"
                  alignItems="center"
                >
                  <Block className={css(CHAT_BOX_STYLE)}>
                    <Block
                      as="img"
                      data-testid="tasks-chat-icon"
                      src={
                        itemData[item]?.CompletionStatus === true &&
                        getAvailableCommentsStatus(itemData[item]?.NoteList)
                          ? ChatIcon
                          : ChatIconBlank
                      }
                      alt="Chat Icon"
                      className={css(CHAT_STYLE)}
                    />
                  </Block>
                  <Block
                    className={css(SUB_TITLE_STYLE(theme))}
                    data-testid="tasks-add-comments"
                  >
                    {"View details"}
                  </Block>
                </Block>
              </Block>
            </StatefulPopover>
          </Block>
        </Block>
      ) : (
        <>
          {showCommentAndStatusElementBasedOnTaskStatus(item, itemData) ||
          checkRTIJSEnable(item, itemData) ? (
            <Block
              key={index}
              display="inline-block"
              alignItems="center"
              data-testid="tasks-onclick-block"
              flexDirection="row"
              gridGap="10px"
              style={{ cursor: "pointer" }}
              className={
                itemData[item]?.CompletionStatus === true
                  ? css({ ...BLOCK_STYLE(theme) })
                  : showCommentAndStatusElementBasedOnTaskStatus(
                      item,
                      itemData
                    ) || checkRTIJSEnable(item, itemData)
                  ? css(ACTIVE_BLOCK_STYLE(theme))
                  : css(OTHER_BLOCK_STYLE(theme))
              }
            >
              <Block
                className={css(CONTENT_BLOCK_STYLE(theme))}
                data-testid="tasks-onclick-block"
              >
                {showCommentAndStatusElementBasedOnTaskStatus(
                  item,
                  itemData
                ) ? (
                  <StatefulPopover
                    content={() => (
                      <VerifyCompetencyPopUp
                        data={itemData}
                        taskData={item}
                        changeDolData={changeDolData}
                        selectedIndex={selectedIndex}
                        rolespermission={rolespermission}
                        isRoleSpecificActivity={isRoleSpecificActivity}
                      />
                    )}
                    triggerType={TRIGGER_TYPE.click}
                    showArrow
                    placement={"rightBottom"}
                    returnFocus
                    autoFocus
                  >
                    <Block
                      as="img"
                      data-testid="conditional-status-icon"
                      src={
                        itemData[item]?.ChangeStatusText === "Not Yet Rated" ||
                        itemData[item]?.ChangeStatusText === ""
                          ? CircleIcon
                          : itemData[item]?.ChangeStatusText ===
                            "Learning- Observed through job training or shadowing"
                          ? CompetencyLevel1
                          : itemData[item]?.ChangeStatusText ===
                            "With help - Completes with assistance"
                          ? CompetencyLevel2
                          : itemData[item]?.ChangeStatusText ===
                            "On their own - Completes independently, with review and guidance"
                          ? CompetencyLevel3
                          : RightCircleIcon
                      }
                      alt="Status Circle"
                      style={{ height: "20px", width: "20px" }}
                    />
                  </StatefulPopover>
                ) : (
                  <Block
                    as="img"
                    data-testid="conditional-status-icon"
                    src={
                      itemData[item]?.ChangeStatusText === "Not Yet Rated" ||
                      itemData[item]?.ChangeStatusText === ""
                        ? CircleIcon
                        : itemData[item]?.ChangeStatusText ===
                          "Learning- Observed through job training or shadowing"
                        ? CompetencyLevel1
                        : itemData[item]?.ChangeStatusText ===
                          "With help - Completes with assistance"
                        ? CompetencyLevel2
                        : itemData[item]?.ChangeStatusText ===
                          "On their own - Completes independently, with review and guidance"
                        ? CompetencyLevel3
                        : RightCircleIcon
                    }
                    alt="Status Circle"
                    style={{ height: "20px", width: "20px" }}
                  />
                )}
                <Block
                  overflow="hidden"
                  onClick={() =>
                    handleClick(
                      itemData,
                      item,
                      categoryData,
                      isRoleSpecificActivity
                    )
                  }
                >
                  <Block
                    className={css(PANEL_TITLE_STYLE(theme))}
                    data-testid="tasks-title-text"
                  >
                    {itemData[item]?.TaskName === "MentorProficientStatus" ||
                    item === "MentorProficientStatus"
                      ? "Technical Sign-Off"
                      : itemData[item]?.TaskName === "FinalApprovalStatus" ||
                        item === "FinalApprovalStatus"
                      ? "Final Sign-Off"
                      : item === "JSStatus"
                      ? "Job Simulation"
                      : item === "OJTStatus"
                      ? "OJT"
                      : item === "JS"
                      ? "Job Simulation"
                      : item === "RTI"
                      ? "Related Technical Instruction"
                      : item}
                  </Block>
                  <Block
                    display="flex"
                    gridGap="4px"
                    marginTop="4px"
                    alignItems="center"
                  >
                    <Block className={css(CHAT_BOX_STYLE)}>
                      <Block
                        as="img"
                        data-testid="tasks-chat-icon"
                        src={
                          itemData[item]?.CompletionStatus === true ||
                          getAvailableCommentsStatus(itemData[item]?.NoteList)
                            ? ChatIcon
                            : ChatIconBlank
                        }
                        alt="Chat Icon"
                        className={css(CHAT_STYLE)}
                      />
                    </Block>
                    <Block
                      className={css(SUB_TITLE_STYLE(theme))}
                      data-testid="tasks-add-comments"
                    >
                      {"View details"}
                    </Block>
                  </Block>
                </Block>
              </Block>
            </Block>
          ) : (
            <StatefulPopover
              content={() => (
                <Block
                  className={css(OTHER_BLOCK_STYLE(theme))}
                  data-testid="tasks-block"
                >
                  <Block>
                    {
                      "Preceding steps must be completed prior to signing off this item"
                    }
                  </Block>
                </Block>
              )}
              triggerType={TRIGGER_TYPE.hover}
              placement="rightBottom"
              showArrow
              returnFocus
              autoFocus
            >
              <Block
                key={index}
                display="inline-block"
                alignItems="center"
                data-testid="tasks-onclick-block"
                flexDirection="row"
                gridGap="10px"
                className={
                  itemData[item]?.CompletionStatus === true
                    ? css({ ...BLOCK_STYLE(theme) })
                    : css(OTHER_BLOCK_STYLE(theme))
                }
              >
                <Block
                  className={css(CONTENT_BLOCK_STYLE(theme))}
                  data-testid="tasks-onclick-block"
                  style={{ cursor: "pointer", opacity: "40%" }}
                >
                  {showCommentAndStatusElementBasedOnTaskStatus(
                    item,
                    itemData
                  ) ? (
                    <StatefulPopover
                      content={() => (
                        <VerifyCompetencyPopUp
                          data={itemData}
                          taskData={item}
                          changeDolData={changeDolData}
                          selectedIndex={selectedIndex}
                          rolespermission={rolespermission}
                          isRoleSpecificActivity={isRoleSpecificActivity}
                        />
                      )}
                      triggerType={TRIGGER_TYPE.click}
                      showArrow
                      placement={"rightBottom"}
                      returnFocus
                      autoFocus
                    >
                      <Block
                        as="img"
                        data-testid="conditional-status-icon"
                        src={
                          itemData[item]?.ChangeStatusText ===
                            "Not Yet Rated" ||
                          itemData[item]?.ChangeStatusText === ""
                            ? CircleIcon
                            : itemData[item]?.ChangeStatusText ===
                              "Learning- Observed through job training or shadowing"
                            ? CompetencyLevel1
                            : itemData[item]?.ChangeStatusText ===
                              "With help - Completes with assistance"
                            ? CompetencyLevel2
                            : itemData[item]?.ChangeStatusText ===
                              "On their own - Completes independently, with review and guidance"
                            ? CompetencyLevel3
                            : RightCircleIcon
                        }
                        alt="Status Circle"
                        style={{ height: "20px", width: "20px" }}
                      />
                    </StatefulPopover>
                  ) : (
                    <Block
                      as="img"
                      data-testid="conditional-status-icon"
                      src={
                        itemData[item]?.ChangeStatusText === "Not Yet Rated" ||
                        itemData[item]?.ChangeStatusText === ""
                          ? CircleIcon
                          : itemData[item]?.ChangeStatusText ===
                            "Learning- Observed through job training or shadowing"
                          ? CompetencyLevel1
                          : itemData[item]?.ChangeStatusText ===
                            "With help - Completes with assistance"
                          ? CompetencyLevel2
                          : itemData[item]?.ChangeStatusText ===
                            "On their own - Completes independently, with review and guidance"
                          ? CompetencyLevel3
                          : RightCircleIcon
                      }
                      alt="Status Circle"
                      style={{ height: "20px", width: "20px" }}
                    />
                  )}
                  <Block
                    overflow="hidden"
                    onClick={() =>
                      handleClick(
                        itemData,
                        item,
                        categoryData,
                        isRoleSpecificActivity
                      )
                    }
                  >
                    <Block
                      className={css(PANEL_TITLE_STYLE(theme))}
                      data-testid="tasks-title-text"
                    >
                      {itemData[item]?.TaskName === "MentorProficientStatus" ||
                      item === "MentorProficientStatus"
                        ? "Technical Sign-Off"
                        : itemData[item]?.TaskName === "FinalApprovalStatus" ||
                          item === "FinalApprovalStatus"
                        ? "Final Sign-Off"
                        : item === "JSStatus"
                        ? "Job Simulation"
                        : item === "OJTStatus"
                        ? "OJT"
                        : item === "JS"
                        ? "Job Simulation"
                        : item === "RTI"
                        ? "Related Technical Instruction"
                        : item}
                    </Block>
                    <Block
                      display="flex"
                      gridGap="4px"
                      marginTop="4px"
                      alignItems="center"
                    >
                      <Block className={css(CHAT_BOX_STYLE)}>
                        <Block
                          as="img"
                          data-testid="tasks-chat-icon"
                          src={
                            itemData[item]?.CompletionStatus === true ||
                            getAvailableCommentsStatus(itemData[item]?.NoteList)
                              ? ChatIcon
                              : ChatIconBlank
                          }
                          alt="Chat Icon"
                          className={css(CHAT_STYLE)}
                        />
                      </Block>
                      <Block
                        className={css(SUB_TITLE_STYLE(theme))}
                        data-testid="tasks-add-comments"
                      >
                        {"View details"}
                      </Block>
                    </Block>
                  </Block>
                </Block>
              </Block>
            </StatefulPopover>
          )}
        </>
      )}
    </>
  );
};
export default ConditionalTaskBlock;
