import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { ButtonGroup } from "baseui/button-group";
import { FormControl } from "baseui/form-control";
import DeleteAlt from "baseui/icon/delete-alt";
import { Input } from "baseui/input";
import { KIND, Notification } from "baseui/notification";
import Skeleton from "components/Skeleton";
import SnackBar from "components/SnackBar";
import UploadImage from "components/uploadProfile";
import React, { useEffect, useState } from "react";
import {
  ABOUT_YOU,
  Basic_info_name,
  Basic_info_role,
  Basic_lastname,
  EMail,
  Kahoot_Username,
  THM_USERNAME,
} from "resources/constants/strings";
import basicInfoUpdate from "services/requests/student_basicinfo_update";
import {
  CANCEL_BTN_STYLE,
  COLUMN_INPUTS_STYLE,
  INPUT_LABEL_STYLE,
  INPUT_OVERRIDE_STYLE,
  SAVE_BTN_STYLE,
} from "../../style.js";
import { THM_UPDATE_MESSAGE } from "resources/constants/messages.js";

export default function BasicInfoEdit(props) {
  const [css, theme] = useStyletron();
  const [apiStatusMessage, setApiStatusMessage] = React.useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [payloadObj, setPayLoadObj] = React.useState({
    firstName: "",
    lastName: "",
    role: "",
    thmusername: "",
    kahootusername: "",
  });
  const [isEditableThmuser, setIsEditableThmuser] = useState({
    state: false,
    message: "Data saved successfully",
  });
  useEffect(() => {
    setPayLoadObj({
      firstName: props.basicProfileData
        ? props.basicProfileData?.firstname
        : localStorage.getItem("firstName"),
      lastName: props.basicProfileData
        ? props.basicProfileData?.lastname
        : localStorage.getItem("lastName"),
      dateOfBirth: props.basicProfileData?.dateofbirth,
      role: props.basicProfileData
        ? props.basicProfileData?.role
        : localStorage.getItem("role"),
      address1: props.basicProfileData?.address1,
      address2: props.basicProfileData?.address2,
      city: props.basicProfileData?.city,
      state: props.basicProfileData?.state,
      countryCode: props.basicProfileData?.countrycode,
      zipCode: props.basicProfileData?.zipcode,
      thmusername: props.basicProfileData?.thmusername,
      kahootusername: props.basicProfileData?.kahootusername,
    });
  }, [props.basicProfileData]);
  const inputsHandler = (e) => {
    const { name, value } = e.target;
    setPayLoadObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    name === "thmusername" &&
      setIsEditableThmuser({
        ...isEditableThmuser,
        state: true,
      });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    //payloadObj.state = payloadObj.state[0].label;
    basicInfoUpdate(localStorage.getItem("email"), payloadObj).then(function (
      response
    ) {
      //setApiStatusMessage(true);
      if (response.status === 200) {
        //setApiStatusMessage(false);
        setSuccessMessage(true);
        // props.setUpdateData(true);
        setTimeout(() => {
          // props.parentCallback();
          setSuccessMessage(false);
          if (isEditableThmuser.state) {
            setIsEditableThmuser({
              ...isEditableThmuser,
              message: THM_UPDATE_MESSAGE,
            });

            setSuccessMessage(true);
            setTimeout(() => {
              props.setUpdateData(true);
              setSuccessMessage(false);
              // props.parentCallback();
            }, 5000);
          } else {
            setSuccessMessage(false);
            props.setUpdateData(true);
            props.parentCallback();
          }
        }, 3000);
      }
    });
  };
  const cancel = () => {
    props.parentCallback(false);
  };
  const uploadFunction = (childData) => {
    props.uploadedBack(childData);
  };

  const handleThmUser = () => {
    return (
      !payloadObj?.thmusername && localStorage.getItem("role") === "LEARNER"
    );
  };
  // const ALL_STATES = states?.map((state) => ({ label: state, id: state }));
  return (
    <Block>
      <UploadImage uploadImage={uploadFunction} />
      {!apiStatusMessage ? (
        <form onSubmit={handleSubmit}>
          <Block as="h5" data-testid="title">
            {ABOUT_YOU}
          </Block>
          <Block width="80%" className={css(COLUMN_INPUTS_STYLE(theme, 2))}>
            <Block>
              <Block
                className={css(INPUT_LABEL_STYLE(theme))}
                data-testid="first_name_label"
              >
                {Basic_info_name}
              </Block>
              <Input
                overrides={{
                  Input: { style: INPUT_OVERRIDE_STYLE.Input },
                  Root: { style: INPUT_OVERRIDE_STYLE.Root },
                }}
                inputProps={{
                  "data-testid": "firstName",
                }}
                name="firstName"
                data-testid="firstName"
                minLength={2}
                aria-label="cost-input"
                // required={true}
                onChange={(e) => inputsHandler(e)}
                value={payloadObj?.firstName || ""}
                // pattern="^[a-zA-Z ]+$"
              />
            </Block>
            <Block>
              <Block
                className={css(INPUT_LABEL_STYLE(theme))}
                data-testid="last_name_label"
              >
                {Basic_lastname}
              </Block>
              <Input
                overrides={{
                  Input: { style: INPUT_OVERRIDE_STYLE.Input },
                  Root: { style: INPUT_OVERRIDE_STYLE.Root },
                }}
                name="lastName"
                data-testid="lastName"
                minLength={2}
                // required={true}
                onChange={inputsHandler}
                value={payloadObj?.lastName || ""}
                // pattern="^[a-zA-Z ]+$"
              />
            </Block>
          </Block>
          <Block width="80%" className={css(COLUMN_INPUTS_STYLE(theme, 2))}>
            <Block>
              <Block
                className={css(INPUT_LABEL_STYLE(theme))}
                data-testid="basic_role_label"
              >
                {Basic_info_role}
              </Block>
              <Input
                overrides={{
                  Input: { style: INPUT_OVERRIDE_STYLE.Input },
                  Root: { style: INPUT_OVERRIDE_STYLE.Root },
                }}
                name="role"
                minLength={2}
                required={true}
                disabled
                onChange={inputsHandler}
                value={localStorage.getItem("role") || ""}
              />
            </Block>
            <Block>
              <Block
                className={css(INPUT_LABEL_STYLE(theme))}
                data-testid="basic_email_label"
              >
                {EMail}
              </Block>
              <Input
                overrides={{
                  Input: { style: INPUT_OVERRIDE_STYLE.Input },
                  Root: { style: INPUT_OVERRIDE_STYLE.Root },
                }}
                name="email"
                minLength={2}
                required={true}
                disabled
                onChange={inputsHandler}
                value={localStorage.getItem("email") || ""}
              />
            </Block>
          </Block>

          {localStorage.getItem("role") === "MENTOR"
            ? true
            : localStorage.getItem("viewAs") !== "MENTOR" && (
                <Block
                  width="80%"
                  className={css(COLUMN_INPUTS_STYLE(theme, 2))}
                >
                  {props.basicProfileData && (
                    <Block>
                      <Block
                        className={css(INPUT_LABEL_STYLE(theme))}
                        data-testid="basic_thmUsername_label"
                      >
                        {THM_USERNAME}
                      </Block>
                      <FormControl
                        error={
                          handleThmUser()
                            ? "Please enter the THM Username"
                            : undefined
                        }
                        caption=""
                      >
                        <Input
                          overrides={{
                            Input: {
                              style: INPUT_OVERRIDE_STYLE.Input,
                              ...{
                                style: handleThmUser() && {
                                  border: "1px solid red",
                                },
                              },
                            },
                            Root: {
                              style: INPUT_OVERRIDE_STYLE.Root,
                            },
                          }}
                          name="thmusername"
                          data-testid="thmusername"
                          minLength={2}
                          onChange={inputsHandler}
                          value={payloadObj?.thmusername || ""}
                          // pattern="^[a-zA-Z ]+$"
                          error={
                            !payloadObj?.thmusername &&
                            localStorage.getItem("role") === "LEARNER"
                          }
                        />
                      </FormControl>
                    </Block>
                  )}
                  <Block>
                    <Block
                      className={css(INPUT_LABEL_STYLE(theme))}
                      data-testid="first_name_label"
                    >
                      {Kahoot_Username}
                    </Block>
                    <Input
                      overrides={{
                        Input: { style: INPUT_OVERRIDE_STYLE.Input },
                        Root: { style: INPUT_OVERRIDE_STYLE.Root },
                      }}
                      inputProps={{
                        "data-testid": "kahootusername",
                      }}
                      name="kahootusername"
                      data-testid="kahootusername"
                      minLength={2}
                      aria-label="cost-input"
                      required={true}
                      onChange={(e) => inputsHandler(e)}
                      value={payloadObj?.kahootusername || ""}
                      // pattern="^[a-zA-Z ]+$"
                    />
                  </Block>
                </Block>
              )}

          <Block>
            <ButtonGroup
              size="compact"
              overrides={{
                Root: { style: { display: "flex", gap: "20px" } },
              }}
            >
              <Button
                type="submit"
                data-testid="save"
                // disabled={handleThmUser()}
                overrides={{ BaseButton: { style: SAVE_BTN_STYLE } }}
              >
                Save
              </Button>
              <Button
                onClick={cancel}
                overrides={{ BaseButton: { style: CANCEL_BTN_STYLE } }}
                data-testid="cancel"
              >
                Cancel
              </Button>
            </ButtonGroup>
          </Block>
          {apiStatusMessage && (
            <SnackBar message={"Values Updated Successfully"} />
          )}
        </form>
      ) : (
        <Block marginTop={"15px"}>
          <Skeleton oval={false} height="auto" width="auto" rows={4} />
        </Block>
      )}
      {successMessage && (
        <Notification
          overrides={{
            Body: {
              style: ({ $theme }) => ({
                position: "absolute",
                top: "10%;",
                right: "0",
                background: "green",
                color: "#fff",
              }),
            },
            CloseIcon: {
              component: DeleteAlt,
              style: { float: "right", cursor: "pointer" },
            },
          }}
          kind={KIND.positive}
          closeable
        >
          {isEditableThmuser.message}
        </Notification>
      )}
    </Block>
  );
}
