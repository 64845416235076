
import axios from "axios";
import { kahootGameURL } from "../config";
import { KAHOOT_GAMES } from "../apiEndpoint";

const KahootGameAxios = axios.create({
  baseURL: kahootGameURL
})

KahootGameAxios.defaults.headers.common = {
  ...(KahootGameAxios.defaults.headers.common || {}),
  'Content-Type' : 'application/x-www-form-urlencoded'
}

export default function fetchKahoot(auth) {
  return KahootGameAxios.post(`${KAHOOT_GAMES}?Authorization=${auth}`);
}

// export default function fetchKahoot(auth) {
//     KahootRoomScoreBoardAxios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
//   return KahootRoomScoreBoardAxios.post(KAHOOT_AUTH+`?Authorization=${auth}`);
//   }