import React, {
  useState,
  useImperativeHandle,
  useEffect,
  forwardRef,
} from "react";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import { ChevronDown, ChevronUp } from "baseui/icon";
import { Panel, StatelessAccordion } from "baseui/accordion";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import InfoIcon from "assets/images/info_icon.svg";
import Loader from "react-js-loader";
import TaskBlock from "../dolCompetency/taskBlock";
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "../style.css";

import CompletedCircle from "assets/svg/completedCircle.svg";
import IncompletedCircle from "assets/svg/incompletedCircle.svg";
import EmptyCircle from "assets/svg/emptyCircle.svg";
import Progress1 from "assets/svg/progress1.svg";
import Progress2 from "assets/svg/progress2.svg";
import Progress3 from "assets/svg/progress3.svg";
import Progress4 from "assets/svg/progress4.svg";
import Progress5 from "assets/svg/progress5.svg";
import Progress6 from "assets/svg/progress6.svg";
import Progress7 from "assets/svg/progress7.svg";
import Progress8 from "assets/svg/progress8.svg";
import Progress9 from "assets/svg/progress9.svg";
import Progress10 from "assets/svg/progress10.svg";

const COMMON_TEXT_STYLE = {
  fontSize: "14px",
  lineHeight: "19px",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
const PANEL_TITLE_STYLE = (theme) => ({
  display: "flex",
  alignItems: "center",
  fontFamily: "Manrope-semibold",
  fontWeight: 600,
  lineHeight: "22px",
  color: theme.RAColors?.black,
  fontSize: "18px",
});
const ToggleIcon = ({ $expanded, $theme }) => {
  const [css] = useStyletron();
  const color = $theme?.RAColors?.gray900;
  if ($expanded)
    return (
      <ChevronUp
        data-html2canvas-ignore
        className={css({ minWidth: "20px" })}
        size={20}
        color={color}
      />
    );
  return (
    <ChevronDown
      data-html2canvas-ignore
      size={20}
      className={css({ minWidth: "20px" })}
      color={color}
    />
  );
};

const CATEGORY_ACCORDION_STYLE = {
  Header: ({ $theme }) => ({
    paddingBottom: "0px",
    paddingRight: "0px",
    paddingLeft: "0px",
    paddingTop: "0px",
  }),
  Content: ({ $theme }) => ({
    paddingRight: "0px",
    paddingLeft: "32px",
    paddingTop: "20px",
    paddingBottom: "0px",
    [$theme.mediaQuery.small]: {
      paddingLeft: "0px",
    },
    [$theme.mediaQuery.medium]: {
      paddingLeft: "32px",
    },
    [$theme.mediaQuery.large]: {
      paddingLeft: "32px",
    },
  }),
};
const ROLE_ATTR_STYLE = (theme) => ({
  display: "grid",
  width: "100%",
  "grid-template-columns": "32% 2% 32% 2% 32%",
  border: "0",
  padding: "10px",
  borderRadius: "8px",
  backgroundColor: "#fff",
  "box-sizing": "border-box",
  fontSize: "14px",
  overflow: "hidden",
});
const ATTR_NAME = (theme) => ({
  display: "block",
  textAlign: "center",
  overflow: "hidden",
  borderRadius: "8px",
});
const ATTR_TITLE = (theme) => ({
  backgroundColor: "rgb(73, 94, 235)",
  color: "#fff",
  padding: "6px 0",
});
const ATTR_VALUE = (theme) => ({
  display: "block",
  padding: "10px 0px",
  "border-radius": "0 0 8px 8px",
  border: "1px solid rgba(0,0,0,0.4)",
  "border-top": "0",
});
const COMPETENCY_ACCORDION_STYLE = {
  Header: ({ $theme }) => ({
    paddingBottom: "10px",
    paddingRight: "16px",
    paddingLeft: "16px",
    paddingTop: "10px",
    backgroundColor: "#F9F9F9",
  }),
  Content: ({ $theme }) => ({
    paddingRight: "0px",
    paddingLeft: "16px",
    paddingTop: "16px",
    paddingBottom: "16px",
    [$theme.mediaQuery.small]: {
      marginRight: "0px",
      overflowX: "none",
    },
    [$theme.mediaQuery.medium]: {
      marginRight: "16px",
      overflowX: "auto",
    },
    [$theme.mediaQuery.large]: {
      marginRight: "0px",
      overflowX: "none",
    },
  }),
  Root: ({ $theme }) => ({
    border: `1px solid ${$theme.RAColors?.gray200}`,
    borderRadius: "8px",
    overflow: "hidden",
    boxSizing: "border-box",
  }),
};
const DESCRIPTION_TEXT_STYLE = (theme) => ({
  ...COMMON_TEXT_STYLE,
  fontFamily: "Manrope-semibold",
  fontWeight: 600,
  color: theme.RAColors?.black,
});
const TASKS_BLOCK_STYLE = (theme) => ({
  display: "flex",
  alignItems: "center",
  [theme.mediaQuery.small]: {
    display: "block",
  },
  [theme.mediaQuery.medium]: {
    display: "flex",
  },
  [theme.mediaQuery.large]: {
    display: "flex",
  },
});
const COMPETENCY_ICON_STYLE = { width: "16px", height: "16px" };
const SEPRATOR = {
  borderBottom: "1px solid #e0e0e0",
  marginBottom: "24px",
};
const OTHER_BLOCK_STYLE = (theme) => ({
  backgroundColor: "white",
  borderRadius: "16px",
  border: `1px solid ${theme.RAColors?.gray200}`,
  width: "60px",
  height: "60px",
  padding: "12px",
});
const RoleSpecificActivities = forwardRef(
  (
    {
      Data,
      dolDetails,
      getAvailableTasks,
      changeDolData,
      selectedIndex,
      rolespermission,
      handleClick,
      learnerCheckboxValue,
      technicalCheckboxValue,
      managerCheckboxVlue,
      rtiCheckboxValue,
      selectedValue,
      openVerifyCompetencyModal,
      loader,
      bulkItems,
      collapse
    },
    ref
  ) => {
    const [css, theme] = useStyletron();
    const [expands, setExpands] = useState(null);
    const [rolesitems, setRolesItems] = useState(null);
    const [bulksignoffItems, setBulkSignOffItems] = React.useState([]);
    const [filteredsignoffItems, setFilteredSignOffItems] = React.useState([]);
    if(selectedValue === 'All competencies'){
      learnerCheckboxValue = false;
      technicalCheckboxValue = false; 
      managerCheckboxVlue = false;
      rtiCheckboxValue = false;
    }
    useEffect(() => {
      if(selectedValue === 'All competencies'){
        learnerCheckboxValue = false;
        technicalCheckboxValue = false;
        managerCheckboxVlue = false;
        rtiCheckboxValue = false;
      }
      fetchData(dolDetails);
    }, [Data,learnerCheckboxValue,technicalCheckboxValue,selectedValue,managerCheckboxVlue,rtiCheckboxValue,dolDetails]);

    const fetchData  = (data) => {
      let result = [];

      let parents = [{ expanded: false, checked:false,children: [] }];
      parents[0].children =
        dolDetails?.RoleSpecificActivities?.map((activity) => ({
          expanded:  collapse === "collapse" ? false : activity.isAccordionOpen === true,
          checked:false,
          completion: activity.CircleFillValue,
        })) || [];
      parents[0].expanded = parents[0].children.some((child) => child.expanded);
      parents[0].checked = parents[0].children.some((child) => child.checked);
      parents[0].completion = parents[0].children[0]?.completion;

      if(learnerCheckboxValue){ 
        data?.RoleSpecificActivities?.filter((obj,ind1) => {
              if(obj?.["LearnerProficientStatus"]?.CompletionStatus !== true && obj?.hasOwnProperty("LearnerProficientStatus")){
                result.push(obj)
              }
              checkedFilters(0,ind1,"Enable",'',parents)
        });
      
      }
      if(technicalCheckboxValue){
        data?.RoleSpecificActivities?.filter((obj,ind1) => {
              if(obj?.["MentorProficientStatus"]?.CompletionStatus !== true && obj?.hasOwnProperty("MentorProficientStatus")){
                result.push(obj)
              }checkedFilters(0,ind1,"Enable",'',parents)
      });
     
    }if(managerCheckboxVlue){
      let signoffarr = [];
      data?.RoleSpecificActivities?.filter((obj,ind1) => {
            if(obj?.["LearnerProficientStatus"]?.CompletionStatus === true && obj?.["MentorProficientStatus"]?.CompletionStatus === true && obj?.["FinalApprovalStatus"]?.CompletionStatus !== true && obj?.hasOwnProperty("FinalApprovalStatus")){
              obj.parentIndex = 0;
              obj.childIndex = ind1;
              result.push(obj)
              signoffarr.push(obj)  
            }
            setBulkSignOffItems(signoffarr)
            checkedFilters(0,ind1,"Enable",signoffarr,parents)
    });
   
    
  }if(rtiCheckboxValue && !learnerCheckboxValue && !technicalCheckboxValue && !managerCheckboxVlue){
    result = []
  }
  if(!rtiCheckboxValue && !learnerCheckboxValue && !technicalCheckboxValue && !managerCheckboxVlue){
      result = data?.RoleSpecificActivities ?  data?.RoleSpecificActivities : []
      data?.RoleSpecificActivities?.filter((obj,ind1) => {
          checkedFilters(0,ind1,"Disable",'',parents)
    })
    }

    let filteres_list = result.filter( (ele, ind) => ind === result.findIndex( elem => elem.TaskID === ele.TaskID))
    setRolesItems(filteres_list);
  }

    const changeParent = (parentIndex,method) => {
      if (!expands?.[parentIndex]) return;

      const newExpands = [...expands];
      if(method === "checkbox"){
        newExpands[parentIndex].checked = !newExpands[parentIndex].checked;
      }else{
        newExpands[parentIndex].expanded = !newExpands[parentIndex].expanded;
      } 

      setExpands(newExpands);
    };

    const changeChild = (parentIndex, childIndex,method,Id,val) => {
      if (!expands?.[parentIndex].children?.[childIndex]) return;

      const newExpands = [...expands];
      const newBulkItems = [...bulksignoffItems]
      if(method === "checkbox"){
        newExpands[parentIndex].children[childIndex].checked =
        !newExpands[parentIndex].children[childIndex].checked;
        var arr = []
        if(filteredsignoffItems && !val){
          var newArray = filteredsignoffItems.filter(function(item)
          {
              return  !(item.TaskID === Id)
          });
          arr = newArray;
        }else{
          var addArr = newBulkItems.find(function(item)
          {
              return (item.TaskID === Id)
          });
          const newArray = [...filteredsignoffItems];
          newArray.push(addArr)
          arr = newArray
        }
        setFilteredSignOffItems(arr)
        bulkItems(arr,"roles");
      }else{
        newExpands[parentIndex].children[childIndex].expanded =
        !newExpands[parentIndex].children[childIndex].expanded;
      }
      setExpands(newExpands);
    };

    const checkedFilters = (parentIndex, childIndex,val,data,parents) =>{
      if (!parents?.[parentIndex]?.children?.[childIndex]) return;
  
        const newExpands = [...parents];
        if(val === "Enable"){
          newExpands[parentIndex].children[childIndex].checked = true
          newExpands[parentIndex].children[childIndex].expanded = true
          if(data){
            setFilteredSignOffItems(data)
            bulkItems(data,"roles");
          } 
        }else{
          newExpands[parentIndex].children[childIndex].checked = false
          setFilteredSignOffItems([])
          bulkItems([],"roles");
        }   
        setExpands(newExpands);
    }
    const expandAll = () => {
      const newExpands = expands.map((expand) => {
        const { children } = expand;
        const newChildren = children.map((child) => ({
          expanded: true,
          checked: child?.checked,
          completion: child?.completion,
        }));
        return {
          expanded: true,
          children: newChildren,
          checked: expand?.checked,
          completion: expand?.completion,
        };
      });
      setExpands(newExpands);
    };

    const collapseAll = () => {
      const newExpands = expands.map((expand) => {
        const { children } = expand;
        const newChildren = children.map((child) => ({
          expanded: false,
          checked: child?.checked,
          completion: child?.completion,
        }));
        return {
          expanded: false,
          children: newChildren,
          checked: expand?.checked,
          completion: expand?.completion,
        };
      });
      setExpands(newExpands);
    };

    const getCircleIcon = (status = "empty") => {
      const maps = {
        empty: EmptyCircle,
        incompleted: IncompletedCircle,
        completed: CompletedCircle,
      };

      return maps[status] || EmptyCircle;
    };

    const getCircleValue = (progress) => {
      if (progress === 100) return "completed";
      else if (progress === 50) return "incompleted";
      return "empty";
    };

    useImperativeHandle(ref, () => ({
      expandAll,
      collapseAll,
    }));

    // useEffect(() => {
    //   let parents = [{ expanded: false, checked:false,children: [] }];
    //   parents[0].children =
    //     dolDetails?.RoleSpecificActivities?.map((activity) => ({
    //       expanded: activity.isAccordionOpen === true,
    //       checked:false,
    //       completion: activity.CircleFillValue,
    //     })) || [];
    //   parents[0].expanded = parents[0].children.some((child) => child.expanded);
    //   parents[0].checked = parents[0].children.some((child) => child.checked);
    //   parents[0].completion = parents[0].children[0]?.completion;
    //   setExpands(parents);
    // }, [dolDetails]);

    const RolesAttribute = (data) => {
      return data?.data?.Importance &&
        data?.data?.Difficulty &&
        data?.data?.Frequency ? (
        <Block className={css(ROLE_ATTR_STYLE(theme))}>
          <Block className={css(ATTR_NAME(theme))}>
            <Block className={css(ATTR_TITLE(theme))}>{"Importance"}</Block>
            <Block className={css(ATTR_VALUE(theme))}>
              {data?.data?.Importance || "Medium"}
            </Block>
          </Block>
          <Block style={{ lineHeight: "40px", color: "#fff" }}>{"|"}</Block>
          <Block className={css(ATTR_NAME(theme))}>
            <Block className={css(ATTR_TITLE(theme))}>{"Difficulty"}</Block>
            <Block className={css(ATTR_VALUE(theme))}>
              {data?.data?.Difficulty || "Medium"}
            </Block>
          </Block>
          <Block style={{ lineHeight: "40px", color: "#fff" }}>{"|"}</Block>
          <Block className={css(ATTR_NAME(theme))}>
            <Block className={css(ATTR_TITLE(theme))}>{"Frequency"}</Block>
            <Block className={css(ATTR_VALUE(theme))}>
              {data?.data?.Frequency || "Daily"}
            </Block>
          </Block>
        </Block>
      ) : null;
    };
    const RoleSpecificTaskBlock = ({
      itemData,
      isRoleSpecificActivity = false,
    }) => {
      return (
        <>
          <Block className={css(TASKS_BLOCK_STYLE(theme))}>
            {Object.keys(itemData).map((item, index) => {
              if (getAvailableTasks(itemData)?.includes(item)) {
                return (
                  <TaskBlock
                    item={item}
                    itemData={itemData}
                    Data={Data}
                    changeDolData={changeDolData}
                    selectedIndex={selectedIndex}
                    rolespermission={rolespermission}
                    handleClick={handleClick}
                    openVerifyCompetencyModal={openVerifyCompetencyModal}
                    isRoleSpecificActivity={isRoleSpecificActivity}
                    key={index}
                    index={index}
                  />
                );
              }
            })}
          </Block>
        </>
      );
    };
    const RoleSpecificActivities = ({ itemData, isRoleSpecificActivity }) => {
      return (
        <Block paddingLeft="5px">
          <RoleSpecificTaskBlock
            itemData={itemData}
            isRoleSpecificActivity={isRoleSpecificActivity}
          />
        </Block>
      );
    };
    const RenderAccordion = ({
      item,
      index,
      expand,
      changeParent,
      changeChild,
    }) => {
      const accordionItem = item;

      return (

              <Block display="flex" flexDirection="column" gridGap="16px">
                {accordionItem?.map((itemData, indexItem) => (
                  <Block key={indexItem} display="flex" alignItems="baseline">
                    {managerCheckboxVlue && !technicalCheckboxValue && !learnerCheckboxValue ?
                    <Checkbox
                     onChange={() => changeChild(index, indexItem,"checkbox",itemData?.TaskID,!expand?.children?.[indexItem]?.checked)}
                     checked={expand?.children?.[indexItem]?.checked}
                      data-testid="learner-category"
                      labelPlacement={LABEL_PLACEMENT.right}
                      overrides={{
                        Root: {
                          style: {
                            marginRight: "12px",
                          },
                        },
                      }}
                    /> : null}
                    <StatelessAccordion
                      accordion={false}
                      expanded={
                        expand?.children?.[indexItem]?.expanded
                          ? ["childPanel"]
                          : []
                      }
                      onChange={() => changeChild(index, indexItem,'','','')}
                      overrides={{
                        Content: {
                          style: COMPETENCY_ACCORDION_STYLE.Content,
                        },
                        Header: {
                          style: COMPETENCY_ACCORDION_STYLE.Header,
                        },
                        Root: {
                          style: COMPETENCY_ACCORDION_STYLE.Root,
                        },
                        PanelContainer: {
                          style: { borderBottomWidth: "0px" },
                        },
                      }}
                    >
                      <Panel
                        key="childPanel"
                        title={
                          <Block
                            display="flex"
                            gridGap="10px"
                            alignItems="center"
                          >
                          
                            <StatefulPopover
                              content={() => (
                                <Block
                                  className={css(OTHER_BLOCK_STYLE(theme))}
                                  data-testid="percentage-popup-block"
                                >
                                  <CircularProgressbar
                                    value={ itemData?.CircleFillValue
                                      //expand?.children?.[indexItem]?.completion
                                    }
                                    text={`${itemData?.CircleFillValue}%`}
                                    styles={buildStyles({
                                      pathColor: `#09702B`,
                                      textColor: `#09702B`,
                                    })}
                                  />
                                </Block>
                              )}
                              triggerType={TRIGGER_TYPE.hover}
                              showArrow
                              returnFocus
                              autoFocus
                            >
                              <Block
                                as="img"
                                src={
                                  itemData?.CircleFillValue >=
                                    10 &&
                                    itemData?.CircleFillValue < 19
                                    ? Progress2
                                    : itemData?.CircleFillValue >= 20 &&
                                    itemData?.CircleFillValue < 29
                                    ? Progress3
                                    : itemData?.CircleFillValue >= 30 &&
                                    itemData?.CircleFillValue < 39
                                    ? Progress4
                                    : itemData?.CircleFillValue >= 40 &&
                                    itemData?.CircleFillValue < 49
                                    ? Progress5
                                    : itemData?.CircleFillValue >= 50 &&
                                    itemData?.CircleFillValue < 59
                                    ? Progress6
                                    : itemData?.CircleFillValue >= 60 &&
                                    itemData?.CircleFillValue < 69
                                    ? Progress7
                                    : itemData?.CircleFillValue >= 70 &&
                                    itemData?.CircleFillValue < 79
                                    ? Progress8
                                    : itemData?.CircleFillValue >= 80 &&
                                    itemData?.CircleFillValue < 89
                                    ? Progress9
                                    : itemData?.CircleFillValue >= 90 &&
                                    itemData?.CircleFillValue < 99
                                    ? Progress10
                                    : itemData?.CircleFillValue === 100
                                    ? CompletedCircle
                                    : Progress1
                                }
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "100%",
                                  marginRight: "12px",
                                }}
                              />
                            </StatefulPopover>
                            <Block
                              className={css(DESCRIPTION_TEXT_STYLE(theme))}
                              key={indexItem}
                            >
                              {itemData?.TaskName}
                            </Block>
                            <StatefulPopover
                              content={() => (
                                <Block width="500px">
                                  <RolesAttribute data={itemData} />
                                </Block>
                              )}
                              triggerType={TRIGGER_TYPE.hover}
                              returnFocus
                              autoFocus
                              placement={"rightBottom"}
                            >
                              <Block
                                as="img"
                                data-testid="proficient-learner-info-icon"
                                src={InfoIcon}
                                alt="Info Icon"
                                className={css(COMPETENCY_ICON_STYLE)}
                              />
                            </StatefulPopover>
                          </Block>
                        }
                      >
                        <RoleSpecificActivities
                          itemData={itemData}
                          isRoleSpecificActivity
                        />
                      </Panel>
                    </StatelessAccordion>
                  </Block>
                ))}
              </Block>
      );
    };
    const RenderAccordionWrapper = ({
      data,
      isRoleSpecificActivity = false,
      expands,
      changeParent,
      changeChild,
    }) => { 
      return (
        <Block>
          {data?.length ? (
            <RenderAccordion
              item={data}
              index={0}
              isRoleSpecificActivity={isRoleSpecificActivity}
              expand={expands[0]}
              changeParent={changeParent}
              changeChild={changeChild}
            />
          ) : (
            <Block>No activities match the filter selection</Block>
          )}
        </Block>
      );
    };

    if (!dolDetails || !expands) return <Block />;

    return (
      <Block>
        <Block display="flex" flexDirection="column">
          {/* <Block className={css(SEPRATOR)} /> */}
          <RenderAccordionWrapper
            //data={dolDetails?.RoleSpecificActivities}
            data={rolesitems}
            isRoleSpecificActivity={true}
            expands={expands}
            changeParent={changeParent}
            changeChild={changeChild}
          />
        </Block>
        {loader ? (
          <Loader
            type="bubble-loop"
            bgColor={"#332ff0"}
            color={"#332ff0"}
            size={100}
          />
        ) : null}
      </Block>
    );
  }
);

export default RoleSpecificActivities;
