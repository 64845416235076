import { useState } from "react";

const getDefaultSortTableValue = (storageKey) => {
  if (!storageKey || !sessionStorage.getItem(storageKey))
    return { column: "", asc: true };

  try {
    const storageItem = JSON.parse(sessionStorage.getItem(storageKey));

    if (storageItem && typeof storageItem === 'object')
      return storageItem;
  
    return {}
  } catch {
    return {}
  }
};

const useSortTable = (storageKey = null) => {
  const { column = "", asc = true } = getDefaultSortTableValue(storageKey);

  const [sortColumn, setSortColumn] = useState(column);
  const [sortAsc, setSortAsc] = useState(asc);

  const onSort = (name) => {
    const newSorAsc = name === sortColumn ? !sortAsc : true;
    setSortAsc(newSorAsc);
    setSortColumn(name);

    // set value to localStorageKey
    if (storageKey) {
      sessionStorage.setItem(
        storageKey,
        JSON.stringify({ column: name, asc: newSorAsc })
      );
    }
  };

  return { sortColumn, sortAsc, onSort };
};

export default useSortTable;
