import React, { useEffect } from "react";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import Skeleton from "components/Skeleton";
import * as dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import {
  Name,
  Basic_info_role,
  EMail,
  THM_USERNAME,
} from "resources/constants/strings";
import userAvatar from "assets/images/profile-placeholder.png";
import {
  COLUMN_LEFT_STYLE,
  COLUMN_RIGHT_STYLE,
  THM_ALERT_MESSAGE_STYLE,
} from "../../style.js";
import { THM_UPDATE_MESSAGE } from "resources/constants/messages.js";

export default function BasicInfoDetails(props) {
  dayjs.extend(advancedFormat);
  const [css, theme] = useStyletron();
  const [userProfileDetails, setUserProfileDetails] = React.useState();
  useEffect(() => {
    setUserProfileDetails(props.basicProfileData);
  }, [props.basicProfileData]);
  return (
    <Block>
      {props.loader ? (
        <Skeleton oval={true} height="100px" width="100px" />
      ) : (
        <Block display="flex" marginBottom="20px">
          <img
            src={
              localStorage.getItem("image") !== "null" &&
              localStorage.getItem("image") !== null &&
              localStorage.getItem("image") !== "" &&
              localStorage.getItem("image") !== undefined
                ? localStorage.getItem("image")
                : userAvatar
            }
            alt=""
            style={{
              width: 100,
              height: 100,
              borderRadius: "100%",
            }}
          />
        </Block>
      )}

      {!props.loader ? (
        <Block display="flex" flexDirection="column" gridGap="16px">
          <Block display="flex" flexDirection={["column", "column", "row"]}>
            <Block
              as="span"
              className={css(COLUMN_LEFT_STYLE(theme))}
              data-testid="name_label"
            >
              {Name}
            </Block>
            <Block
              as="span"
              className={css(COLUMN_RIGHT_STYLE(theme))}
              data-testid="name_value"
            >
              {userProfileDetails?.firstname
                ? userProfileDetails?.firstname +
                  " " +
                  userProfileDetails?.lastname
                : localStorage.getItem("username")}
            </Block>
          </Block>

          <Block display="flex" flexDirection={["column", "column", "row"]}>
            <Block
              as="span"
              className={css(COLUMN_LEFT_STYLE(theme))}
              data-testid="role_label"
            >
              {Basic_info_role}
            </Block>
            <Block
              as="span"
              className={css(COLUMN_RIGHT_STYLE(theme))}
              data-testid="role_value"
            >
              {localStorage.getItem("role")}
            </Block>
          </Block>
          <Block display="flex" flexDirection={["column", "column", "row"]}>
            <Block
              as="span"
              className={css(COLUMN_LEFT_STYLE(theme))}
              data-testid="email_label"
            >
              {EMail}
            </Block>
            <Block
              as="span"
              className={css(COLUMN_RIGHT_STYLE(theme))}
              data-testid="email_value"
            >
              {localStorage.getItem("email")}
            </Block>
          </Block>
          {localStorage.getItem("thm_username") && (
            <Block display="flex" flexDirection={["column", "column", "row"]}>
              <Block
                as="span"
                className={css(COLUMN_LEFT_STYLE(theme))}
                data-testid="thm_label"
              >
                {THM_USERNAME}
              </Block>
              <Block
                as="span"
                className={css(COLUMN_RIGHT_STYLE(theme))}
                data-testid="thm_value"
              >
                {localStorage.getItem("thm_username")}
                <Block
                  as="span"
                  color="#332FF0"
                  className={css(THM_ALERT_MESSAGE_STYLE(theme))}
                >
                  <sup>{THM_UPDATE_MESSAGE}</sup>
                </Block>
              </Block>
            </Block>
          )}
          {/* <Block display="flex" flexDirection={["column", "column", "row"]}>
            <Block as="span" className={css(COLUMN_LEFT_STYLE(theme))}>
              {Basic_info_dob}
            </Block>
            <Block as="span" className={css(COLUMN_RIGHT_STYLE(theme))}>
              {userProfileDetails?.dateOfBirth
                ? dayjs(userProfileDetails.dateOfBirth).format("MMMM Do, YYYY")
                : "Not specified"}
            </Block>
          </Block>
          <Block display="flex" flexDirection={["column", "column", "row"]}>
            <Block as="span" className={css(COLUMN_LEFT_STYLE(theme))}>
              {Basic_info_address}
            </Block>
            <Block as="span" className={css(COLUMN_RIGHT_STYLE(theme))}>
              {(userProfileDetails?.address1 === "" ||
                userProfileDetails?.address1 === null) &&
              (userProfileDetails?.address2 === "" ||
                userProfileDetails?.address2 !== null) &&
              (userProfileDetails?.city === "" ||
                userProfileDetails?.city === null) &&
              (userProfileDetails?.state === "" ||
                userProfileDetails?.state === null) &&
              (userProfileDetails?.countryName === "" ||
                userProfileDetails?.countryName === null) &&
              (userProfileDetails?.zipCode === "" ||
                userProfileDetails?.zipCode === null)
                ? "Not specified"
                : (userProfileDetails.address1
                    ? userProfileDetails.address1 + ","
                    : "") +
                  (userProfileDetails.address2
                    ? userProfileDetails.address2 + ","
                    : "") +
                  (userProfileDetails.city
                    ? userProfileDetails.city + ","
                    : "") +
                  (userProfileDetails.state
                    ? userProfileDetails.state + ","
                    : "") +
                  (userProfileDetails.countryName
                    ? userProfileDetails.countryName + ","
                    : "") +
                  (userProfileDetails.zipCode
                    ? userProfileDetails.zipCode
                    : "")}
            </Block>
          </Block> */}
        </Block>
      ) : (
        <Block marginTop={"15px"}>
          <Skeleton height="auto" width="auto" rows={4} />
        </Block>
      )}
    </Block>
  );
}
