import { AUTO_SIGN_OFF } from "../apiEndpoint";
import { educationAxios } from "../http/http";
export default function automaticSignoffFunction(
  payload = "",
  email = "",
  methodType = ""
) {
  if (methodType === "GET") {
    return educationAxios.get(`${AUTO_SIGN_OFF}?user_email=${email}&key=${"autosignoff"}`, payload);
  } else if (methodType === "POST") {
    return educationAxios.post(`${AUTO_SIGN_OFF}?user_email=${email}`, payload);
  } else {
    return educationAxios.put(`${AUTO_SIGN_OFF}?user_email=${email}`, payload);
  }
}
