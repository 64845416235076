//export const STUDENGT_LIST = 'Genius/Students';
export const STUDENGT_LIST = "/studentsgo";
export const USER_PROFILE = "./studentinfo";
//export const USER_PROFILE = "./Genius/StudentsInfo";
//export const UPLOAD_PROFILE = "./Files/Upload";
export const UPLOAD_PROFILE = "./profileimageupload";
export const USER_LOGIN = "./user/authenticate";
//export const GET_PROFILE = "./user/getProfileImage";
export const GET_PROFILE = "./profileimage";
// export const STUDENGT_PROFILE = '/Students';
export const USER_SIGNUP = "/SignUp/UserSignUp";
//export const PROFILE_UPDATE = "./user/UpdateBasicInfo";
export const PROFILE_UPDATE = "./basicinfo";
//export const SOCIAL_UPDATE = "./user/UpdateSocialMedia";
export const SOCIAL_UPDATE = "./socialmedia";
export const EDUCATION_GET_UPDATE = "./education";
export const FORGOT_PASSWORD = "./Accounts/ForgotPassword";
export const RESET_PASSWORD = "./Accounts/ChangePassword";
export const EMAIL_UPDATE = "./Accounts/ChangeEmail";
export const CONFIRM_EMAIL_CHANGE = "./Accounts/ConfirmToChangeEmail";
export const PHONENUMBER_UPDATE = "./user/UpdatePrivacyDetails";
export const ALL_STATES = "./genius/states";
export const DOL_GET_CHECKLIST = "./dolchecklist";
export const JOB_ROLE = "./jobrole";
export const JOB_GROUP = "./jobgroup";
export const COMPETENCY_CHECKLIST = "./template";
export const ASSIGN_JOB_ROLE = "./assignjobrole";
export const CHECKLIST_MANAGER = "./checklistmanager";
export const ROLE_PERMISSIONS ="./roles"
export const AUTO_SIGN_OFF ="./flag"
export const BULK_SIGNOFF = "./bulksignoff"
export const USER_DATA = "./thmmanual/users"
export const THM_SCOREBOARD = "./thmmanual/scoreboard"
export const COURSE_NAME = "./courseId"
export const GRADE_ADDS = "./gradeAddendums"
export const KAHOOT_AUTH = "/token"
export const KAHOOT_GAMES = "/games"
export const LOGGEDIN_USER = 'loggedTime'
export const TENANTS = '/tenantList'
export const LEARNER_LIST = '/studentList'
export const KAHOOT_GAME_SCORE = "/kahootgamescores";
export const CHOROT = "/chorot";
export const QUEST = "/quest";
export const GRADES = "/grades";
export const STUDENT_LIST = "/studentslist";

