import {
  COMMON_RADIUS,
  COMMON_BORDER_COLOR,
  COMMON_BORDER_WIDTH,
} from "utils/style";

export const TILE_STYLE = (theme) => ({
  backgroundColor: "white",
  borderRadius: "8px",
  padding: "24px",
  border: `1px solid ${theme.RAColors?.gray200}`,

  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "24px",
  },
});

export const TITLE_STYLE = {
  fontFamily: "Manrope-Bold",
  fontSize: "24px",
  lineHeight: "33px",
  marginBottom: "20px",
};

export const HINT_STYLE = {
  borderRadius: "4px",
  backgroundColor: "rgba(0, 0, 0, 0.75)",
  padding: "8px",
};

export const HINT_TITLE_STYLE = {
  color: "white",
  fontSize: "14px",
  fontFamily: "Manrope-SemiBold",
  marginBottom: '4px'
}

export const HINT_TEXT_STYLE = {
  color: "white",
  fontSize: "12px",
  fontFamily: "Manrope-SemiBold",
}

export const SELECT_OVERRIDE_STYLE = {
  ControlContainer: ({ $theme, $disabled }) => ({
    backgroundColor: "none",
    opacity: $disabled ? 0.5 : 1,
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    color: $theme.RAColors?.gray900,
  }),
};

export const getPercentage = (value, total) => Math.round(value / total * 100)

export const VISUALIZATION_HEIGHT = 350
export const MOBILE_SCREEN_BREAKPOINT = 600

export const stringToHex = function(str) {
  let hash = 0;

  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let colour = '#';

  for (let i = 0; i < 3; i++) {
    let value = (hash >> (i * 8)) & 0xFF;
    colour += ('00' + value.toString(16)).substr(-2);
  }

  return colour;
}
