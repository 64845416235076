import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect, useMemo, useRef, useState } from "react";
import { Block } from "baseui/block";

Chart.register(...(registerables || []));
Chart.register(ChartDataLabels);

const getChartOption = (visibleLabel = false , showChartValue,max_value) => ({
  responsive: true,
  maintainAspectRatio: false,
  animation: { duration: 0 },
  elements: {
    line: { borderWidth: visibleLabel ? 2 : 1 },
  },
  plugins: {
    legend: { display: false },
    title: { display: false },
    subtitle: { display: false },
    tooltip: { enabled: false },
    datalabels: {
      display: visibleLabel ? !showChartValue ? "auto" : true : false,
      color: "black",
      formatter: (v) => v + "%",
      align: "end",
      anchor: "end",
    },
  },
  scales: {
    r: {
      max: max_value === 0 ? 100 : max_value,
      min: 0,
      beginAtZero: true,
      angleLines: { display: true },
      ticks: { display: false, count: 6 },
      pointLabels: {
        padding: 30,
        display: visibleLabel,
      },
    },
  },
});

const getColorOption = (visibleLabel = false) => ({
  backgroundColor: "rgba(123, 172, 250, 0.7)",
  borderColor: "rgb(0, 99, 255)",
  pointBackgroundColor: "#fff",
  pointBorderColor: "rgb(0, 99, 255)",
  pointBorderWidth: visibleLabel ? 2 : 1,
  radius: visibleLabel ? 3 : 1,
});

const ChartComponent = ({ data, visibleLabel, style ,showChartValue, skillMax}) => {
  const chartRef = useRef();
  const chart = useRef();
  const [stackedLabel, setStackedLabel] = useState(false)

  const chartData = useMemo(() => {
    if (!data) return null;
    const labels = data.map((category) => {
      if (stackedLabel) return  category?.tag_name?.split(" ")
      return  category?.tag_name
    });
    const values = data.map((category) =>  showChartValue ? skillMax === 0 ? 0 : ((100 * category?.tag_weight)/skillMax).toFixed(2) : category?.tag_weight)
      // (100 * category?.skill_score  category?.skill_completion_pct);
   const  max_value = Math.max(...values);
    return {
      labels,
      datasets: [{ data: values, fill: true, ...getColorOption(visibleLabel) }],
      max_value
    };
  }, [data, stackedLabel]);

  const draw = () => {
    if (!chartRef.current) return;

    const ctx = chartRef.current.getContext("2d");
    chart.current = new Chart(ctx, {
      type: "radar",
      data: chartData,
      options: getChartOption(visibleLabel,showChartValue,chartData.max_value),
    });
  };

  useEffect(() => {
    let isMounted = true;  
    const resizeEvent = window.addEventListener('resize', () => {
      if (isMounted) setStackedLabel(window.innerWidth < 600)
    })

    return () => {
      window.removeEventListener('resize', resizeEvent)
      isMounted = false
    }
  })

  useEffect(() => {
    setStackedLabel(window.innerWidth < 600)
    
    if (chart.current) {
      chart.current.destroy();
    }
    draw()
  }, [chartData])

  if (!data || !Object.keys(data).length) return <div></div>;

  return (
    <Block style={style}>
      <canvas ref={chartRef} />
    </Block>
  );
};

export default ChartComponent;
