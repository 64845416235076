import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { useStyletron } from "baseui";
import {
  COMMON_RADIUS,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_STYLE,
  COMMON_BORDER_COLOR,
} from "utils/style";
import {
  CANCEL,
  NEW_JOB_ROLE_TITLE,
  PUBLISH,
  SAVE_AS_DRAFT,
} from "resources/constants/strings.js";
import Actions from "../Components/actions";
import { useTrackedState } from "store/store";

const TITLE_STYLE = (theme) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "16px",
  flexWrap: "wrap",
  [theme.mediaQuery.small]: {
    marginBottom: "16px",
  },
  [theme.mediaQuery.medium]: {
    marginBottom: "48px",
  },
});
const HEADING_STYLE = (theme) => ({
  fontFamily: "Epilogue-Semibold",
  fontSize: "28px",
  lineHeight: "40px",
  color: theme.RAColors?.gray900,
});
const BUTTONS_CONTAINER_STYLE = (theme) => ({
  display: "flex",
  width: "auto",
  [theme.mediaQuery.small]: {
    display: "inline-block",
    width: "100%",
  },
  [theme.mediaQuery.medium]: {
    display: "flex",
    width: "auto",
  },
})
const BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  backgroundColor: "white",
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  borderColor: $theme.RAColors?.purple,
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingRight: "24px",
  paddingLeft: "24px",
  marginLeft: "16px",
  marginBottom: "0px",
  fontFamily: "Manrope-Bold",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "22px",
  display: "flex",
  ":hover": { backgroundColor: $theme.RAColors?.gray300 },
  color: $theme.RAColors?.purple,

  [$theme.mediaQuery.small]: {
    width: "100%",
    marginLeft: "0px",
    marginBottom: "16px",
  },
  [$theme.mediaQuery.medium]: {
    width: "initial",
    marginLeft: "16px",
    marginBottom: "0px",
  },
});
export const SAVE_BTN_STYLE = ($theme) => ({
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  ...COMMON_BORDER_COLOR($theme.RAColors?.blue700),
  width: "max-content",
  paddingLeft: "24px",
  paddingRight: "24px",
  paddingBottom: "10px",
  paddingTop: "10px",
  fontFamily: "Manrope-Bold",
  fontStyle: "normal",
  fontSize: "16px",
  fontWeight: "700",
  justifyContent: "center",
  alignItems: "center",
  lineHeight: "22px",
  ...COMMON_RADIUS('8px'),
  marginBottom: "0px",
  backgroundColor: $theme.RAColors?.blue700,
  color: "#FFFFFF",
  ":hover": {
    backgroundColor: $theme.RAColors?.blue800,
  },
  [$theme.mediaQuery.small]: {
    width: "100%",
    marginBottom: "12px",
  },
  [$theme.mediaQuery.medium]: {
    width: "initial",
    marginBottom: "0px",
  },
});
export const CANCEL_BTN_STYLE = (theme) => ({
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontSize: "700",
  width: "initial",
  height: "44px",
  borderRadius: "8px",
  borderColor: theme.RAColors?.purple,
  background: "#FFFFFF",
  color: "#666666",
});

const Title = (props) => {
  const [css, theme] = useStyletron();
  const state = useTrackedState()

  return (
    <Block className={css(TITLE_STYLE(theme))}>
      <Block
        as="h3"
        className={css(HEADING_STYLE(theme))}
        data-testid="new-job-title"
      >
        {NEW_JOB_ROLE_TITLE}
      </Block>
      <Block className={css(BUTTONS_CONTAINER_STYLE(theme))}>
        <Button
          onClick={() => props.saveAndPublish("Draft")}
          overrides={{ BaseButton: { style: SAVE_BTN_STYLE(theme) } }}
          data-testid="create-role-save"
        >
          <span>{SAVE_AS_DRAFT}</span>
        </Button>
        <Button
          onClick={() => props.saveAndPublish("Published")}
          overrides={{ BaseButton: { style: BUTTON_OVERRIDE_STYLE } }}
          data-testid="create-role-publish"
        >
          <span>{PUBLISH}</span>
        </Button>
        <Button
          onClick={() => props.cancel()}
          overrides={{ BaseButton: { style: BUTTON_OVERRIDE_STYLE } }}
          data-testid="create-role-cancel"
        >
          <span>{CANCEL}</span>
        </Button>
        {state?.jobObjectType === 'Draft' && (
          <Actions onDelete={props.delete}>
            <Button
              overrides={{ BaseButton: { style: BUTTON_OVERRIDE_STYLE } }}
            >
              •••
            </Button>
          </Actions>
        )}
      </Block>
    </Block>
  );
};

export default Title;
