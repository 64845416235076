import { Block } from 'baseui/block';
import { useStyletron } from 'baseui';

const COMMON_PROGRESS_STYLE = { height: '6px', borderRadius: '100px', minWidth: '0px' };

export const Progress = ({ value = 0, targetValue, min = 0, max = 100 }) => {
  const [css, theme] = useStyletron();

  const range = max - min

  const firstValue = !targetValue ?
    value :
    targetValue >= value ?
      value : targetValue

  const secondValue = !targetValue ?
    value :
    targetValue >= value ?
      targetValue : value

  const firstValuePercent = (firstValue - min) / range * 100
  const secondValuePercent = (secondValue - min) / range * 100

  return (
    <Block
      display="flex"
      position="relative"
      className={css({
        ...COMMON_PROGRESS_STYLE,
        backgroundColor: theme?.RAColors?.gray200,
      })}
    >
      <Block
        position="absolute"
        className={css({
          ...COMMON_PROGRESS_STYLE,
          backgroundColor: theme?.RAColors?.blue600,
          width: `${firstValuePercent}%`,
        })}
      />
      {targetValue ? (
        <Block
          className={css({
            ...COMMON_PROGRESS_STYLE,
            backgroundColor: targetValue > value
              ? '#edc12f'
              : theme?.RAColors?.emerald400,
            width: `${secondValuePercent}%`,
          })}
        />
      ) : null}
    </Block>
  );
};

export default Progress