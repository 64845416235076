import axios from "axios";
import { jsURL } from "../config";

const JumpStartData = axios.create({
  baseURL: jsURL
})

JumpStartData.defaults.headers.common = {
  ...(JumpStartData.defaults.headers.common || {}),
  'x-api-key': 'jdjdDkd$dk$k#1ks'
}
const getWordpressData = (email) => JumpStartData.get(`master/all_course_data?email_id=${email}`)
//const getWordpressData = (email) => JumpStartData.get(`master/all_course_data?email_id=bsamani@rapidascent.io`)

export default getWordpressData

