import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import DefaultAvatar from "assets/images/user-profile.svg";
import Chart from "components/newChart";
import { StatefulTooltip } from "baseui/tooltip";
import {
  Core_Skill,
  ADD_SKILL,
  Sum_Of_All_Skill,
  Score,
  Mentor,
  Learner,
  Profile,LEARNER_PROFILE_SCORE_TEXT
} from "resources/constants/strings";
import { COMMON_CARD_STYLE } from "utils/style";
import { lazy, Suspense, useState } from "react";
import InfoIcon from "assets/images/info-icon.png";

const ModalComponent = lazy(() => import("./modal/index.js"));

const COMMON_TEXT_STYLE = {
  fontSize: "14px",
  lineHeight: "19px",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const HEADING_STYLE = (theme) => ({
  fontFamily: "Manrope-bold",
  fontSize: "18px",
  lineHeight: "25px",
  color: theme.RAColors?.gray900,
  marginBottom: "20px",
});

const AVATAR_STYLE = { width: "60px", height: "60px", borderRadius: "100%" };

const SEPRATOR = {
  borderBottom: "1px solid #e0e0e0",
  margin: "20px 0px",
};
const PROFILE_HEADING_STYLE_ICON = {
  marginLeft: 0,
  marginTop: 0,
  marginBottom: 0,
  fontFamily: "Manrope-Medium",
  fontSize: "14px",
  lineHeight: "19px",
  display:"inline",
  marginRight:"5px"
};

const NAME_STYLE = (theme) => ({
  ...COMMON_TEXT_STYLE,
  fontFamily: "Manrope-Bold",
  color: theme.RAColors?.blue700,
});

const EMAIL_STYLE = {
  ...COMMON_TEXT_STYLE,
  fontFamily: "Manrope-Semibold",
};

const PROFILE_ITEM_TITLE_STYLE = {
  margin: 0,
  fontFamily: "Manrope-Bold",
  fontSize: "14px",
  lineHeight: "19px",
};

const PROFILE_HEADING_STYLE = {
  margin: 0,
  fontFamily: "Manrope-Medium",
  fontSize: "14px",
  lineHeight: "19px",
};


const PROFILE_SUB_HEADING_STYLE = (theme) => ({
  margin: 0,
  fontFamily: "Manrope-Semibold",
  color: theme?.RAColors?.gray600,
  fontSize: "12px",
  lineHeight: "19px",
});

export const LearnerProfile = ({ track }) => {
  const [css, theme] = useStyletron();
  const [flex, setFlex] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleClick = (e) => {
    e.stopPropagation();
    setShowModal(true);
    setFlex(false);
  };
  const handleClickFlex = (e) => {
    e.stopPropagation();
    setShowModal(true);
    setFlex(true);
  };

  return (
    <Block className={css(COMMON_CARD_STYLE(theme))}>
      <Block>
        <Block as="h3" className={css(HEADING_STYLE(theme))} data-testid="learner-profile-title">
          {Learner + "'s"} {Profile}
        </Block>
        <Block display="flex" gridGap="12px" alignItems="center">
          <Block
            as="img"
            src={track?.LearnerProfileImageUrl ? track?.LearnerProfileImageUrl : DefaultAvatar
            }
            alt="User Avatar"
            className={css(AVATAR_STYLE)}
            data-testid="learner-profile-image"
          />
          <Block overflow="hidden">
            <Block className={css(NAME_STYLE(theme))} marginBottom="4px" data-testid="learner-profile-name">
            {track?.name}
            </Block>
            <Block className={css(EMAIL_STYLE)} data-testid="learner-profile-email">{track?.email_id}</Block>
          </Block>
        </Block>
        <Block className={css(SEPRATOR)} />

        <Block className={css(PROFILE_ITEM_TITLE_STYLE)}>{Core_Skill}</Block>
        <Block
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={handleClick}
          marginTop={"20px"}
          marginBottom={"24px"}
          data-testid="learner-profile-chart"
        >
          <Chart
            data={track?.learner_radar_skills?.sub_category_map}
            style={{ height: "140px", width: "140px", cursor: "pointer" }}
          />
        </Block>
        <Block display="flex" flexDirection="column" gridGap="12px">
          <Block display="flex" justifyContent="space-between">
            <Block>
            <Block>
              <Block className={css(PROFILE_HEADING_STYLE_ICON)}>{Score}</Block>
              <StatefulTooltip
          accessibilityType={"tooltip"}
          content={LEARNER_PROFILE_SCORE_TEXT}
          showArrow
          placement="top"
        >
          <Block style={{top:"2px",position:"relative"}}data-testid="info-image" as="img" src={InfoIcon} alt="info Icon" />
        </StatefulTooltip>
              </Block>
              <Block className={css(PROFILE_SUB_HEADING_STYLE(theme))} data-testid="learner-profile-sum-all-skill">
                {Sum_Of_All_Skill}
              </Block>
            </Block>
            <Block>
              <Block className={css(PROFILE_HEADING_STYLE)} data-testid="learner-profile-sum-all-skill-content">
                {track?.learner_radar_skills?.skill_completion_pct + "%"}
              </Block>
            </Block>
          </Block>
        </Block>
        <Block className={css(SEPRATOR)} />

        <Block className={css(PROFILE_ITEM_TITLE_STYLE)}>{ADD_SKILL}</Block>
        <Block
          display="flex"
          alignItems="center"
          justifyContent="center"
          onClick={handleClickFlex}
          marginTop={"20px"}
          marginBottom={"24px"}
          data-testid="radar_skill_chart"
        >
          <Chart
            data={track?.learner_radar_skills_flex?.sub_category_map}
            style={{ height: "140px", width: "140px", cursor: "pointer" }}
            showChartValue={true}
            skillMax={track?.learner_radar_skills_flex?.skill_score}
           
          />
        </Block>
        <Block display="flex" flexDirection="column" gridGap="12px">
          <Block display="flex" justifyContent="space-between">
            <Block>
              <Block className={css(PROFILE_HEADING_STYLE)} data-testid="score_text">{Score}</Block>
              <Block className={css(PROFILE_SUB_HEADING_STYLE(theme))} data-testid="additional_points">
                {"Additional Practice Points"}
              </Block>
            </Block>
            <Block>
              <Block className={css(PROFILE_HEADING_STYLE)}>
                {track?.learner_radar_skills_flex?.skill_score}
              </Block>
            </Block>
          </Block>
        </Block>
      </Block>
      <Suspense fallback={<div />}>
        {showModal && (
          <ModalComponent row={track} isFlex={flex} close={() => setShowModal(false)} />
        )}
      </Suspense>
    </Block>
  );
};

export const MentorProfile = ({ track = {} }) => {
  const [css, theme] = useStyletron();

  return (
    track?.mentor_profile?.first_name ? 
    <Block className={css(COMMON_CARD_STYLE(theme))}>
      <Block>
        <Block as="h3" className={css(HEADING_STYLE(theme))} data-testid="mentor-profile-title">
          {Mentor}
        </Block>
        <Block display="flex" gridGap="12px" alignItems="center">
          <Block
            as="img"
            src={
              track?.mentor_profile?.image_information?.file_record
                ? "data:image/png;base64," +
                  track?.mentor_profile?.image_information?.file_record
                : DefaultAvatar
            }
            alt="User Avatar"
            className={css(AVATAR_STYLE)}
            data-testid="mentor-profile-image"
          />
          <Block overflow="hidden">
            <Block className={css(NAME_STYLE(theme))} data-testid="mentor-profile-name">
              {track?.mentor_profile?.first_name +
                " " +
                track?.mentor_profile?.last_name}
            </Block>
            <Block className={css(EMAIL_STYLE)} data-testid="mentor-profile-email">
              {track?.mentor_profile?.email_id}
            </Block>
          </Block>
        </Block>
      </Block>
    </Block> : null
  );
};
