import { useStyletron } from "baseui";
import { ChevronDown, ChevronUp } from "baseui/icon";

const ToggleIcon = ({ $expanded, $theme }) => {
  const [css] = useStyletron();

  const color = $theme?.RAColors?.gray900;
  if ($expanded)
    return (
      <ChevronUp
        data-html2canvas-ignore
        className={css({ minWidth: "20px" })}
        size={20}
        color={color}
        data-testid="track-progress-chevron-up"
      />
    );
  return (
    <ChevronDown
      data-html2canvas-ignore
      size={20}
      className={css({ minWidth: "20px" })}
      color={color}
      data-testid="track-progress-chevron-down"
    />
  );
};

export default ToggleIcon