import { Header } from "components/Header";
import DrawerRouterContainer from "components/newDrawer";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import {
  COMMON_RADIUS,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_STYLE,
  COMMON_BORDER_COLOR,
  COMMON_CARD_STYLE,
} from "utils/style";
import { Redirect, useHistory } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import EditButtons from "./Components/editButtons";
import Actions from "./Components/actions";
import {
  BasicInfo,
  List,
  ActivityList,
  CompetencyChecklist,
} from "./Components/inputs";
import { useSetState, useTrackedState } from "store/store";
import {
  fetchJobRoles,
  updateJobRole,
  deleteJobRole,
  createJobRole,
} from "services/requests/job_role";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import { TableHeader } from "./Components/table";
import BreadCrumb from "./Components/breadcrumb";
import { fetchDOLTemplate } from "services/requests/dol_template";
import TemplateTable from "pages/DolCriteria/components/templateTable";
import DeleteRejection from "components/deleteRejection";
import { StatefulPopover,TRIGGER_TYPE } from "baseui/popover";
import getRolesPageVisibility from "../../components/getPageVisibility"


const MAIN_STYLE = (theme) => ({
  height: "calc(100vh - 68px)",
  boxSizing: "border-box",
  overflow: "auto",
  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "40px",
  },
});

const TITLE = (theme) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  marginBottom: "16px",
  flexDirection: 'column',
  gap: '16px',

  [theme.mediaQuery.large]: {
    flexDirection: 'row',
    alignItems: "center",
    marginBottom: "60px",
  },
});

const CONTENT = (theme) => ({
  display: "flex",

  [theme.mediaQuery.small]: {
    flexDirection: "column",
  },
  [theme.mediaQuery.large]: {
    flexDirection: "row",
  },
});

const COMMON_BUTTON_STYLE = {
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingRight: "24px",
  paddingLeft: "24px",
  lineHeight: "22px",
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  fontFamily: "Manrope-Bold",
};

const DUPLICATE_BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_BUTTON_STYLE,
  ...COMMON_BORDER_COLOR($theme.RAColors?.purple),
  backgroundColor: "white",
  ":hover": { backgroundColor: $theme.RAColors?.gray300 },
  color: $theme.RAColors?.purple,

  [$theme.mediaQuery.small]: {
    width: "100%",
  },
  [$theme.mediaQuery.medium]: {
    width: "initial",
  },
});

const NEW_ROLE_BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_BUTTON_STYLE,
  ...COMMON_BORDER_COLOR($theme.RAColors?.blue700),
  backgroundColor: $theme.RAColors?.blue700,
  ":hover": { backgroundColor: $theme.RAColors?.blue800 },
  color: "white",

  [$theme.mediaQuery.small]: {
    width: "100%",
  },
  [$theme.mediaQuery.medium]: {
    width: "initial",
  },
});

const SIDEBAR = (theme) => ({
  width: "200px",
  flexDirection: "column",
  gridGap: "20px",
  position: "sticky",
  top: "92px",
  alignSelf: "flex-start",
  [theme.mediaQuery.small]: {
    display: "none",
  },
  [theme.mediaQuery.large]: {
    display: "flex",
  },
});

const HEADING = (isSelected = false) => ({
  fontSize: "20px",
  lineHeight: "27px",
  fontFamily: "Manrope",
  fontWeight: isSelected ? 700 : 400,
  cursor: "pointer",
});

const SMALL_HEADING = {
  fontSize: "16px",
  lineHeight: "22px",
  fontFamily: "Manrope-Bold",
};

const SMALL_TEXT = {
  fontSize: "14px",
  lineHeight: "20px",
  fontFamily: "Manrope",
};

const SMALL_HEADING_2 = {
  fontSize: "14px",
  lineHeight: "20px",
  fontFamily: "Manrope-Semibold",
};

const NAME = {
  fontSize: "20px",
  lineHeight: "27px",
  fontFamily: "Manrope-Bold",
};

const CODE = {
  fontFamily: "Epilogue",
  fontSize: "20px",
  lineHeight: "40px",
  marginTop: "4px",
};

const TEXT_CONTENT = {
  fontSize: "16px",
  lineHeight: "22px",
  fontFamily: "Manrope",
};

const EDIT_BUTTON = (theme) => ({
  fontSize: "16px",
  lineHeight: "22px",
  borderRadius: "8px",
  backgroundColor: "#dce3ff",
  paddingTop: "6px",
  paddingBottom: "6px",
  paddingRight: "12px",
  paddingLeft: "12px",
  fontFamily: "Manrope-Bold",
  color: theme.RAColors?.blue700,
  cursor: "pointer",
});
const POPOVER_STYLE =(theme) =>({
  fontSize: "14px",
  fontFamily: "Manrope-Medium",
})

const EDIT_BUTTON_DISABLE = (theme) => ({
  fontSize: "16px",
  lineHeight: "22px",
  borderRadius: "8px",
  backgroundColor: "#dce3ff",
  paddingTop: "6px",
  paddingBottom: "6px",
  paddingRight: "12px",
  paddingLeft: "12px",
  fontFamily: "Manrope-Bold",
  color: theme.RAColors?.blue700,
  cursor: "pointer",
  "pointer-events":"null",
  opacity:"0.5",
  backgroundColor:"#d6d6d6",
        cursor: "not-allowed"
});

const TABLE_OVERRIDE_STYLE = {
  Root: ({ $theme }) => ({
    ...COMMON_RADIUS("8px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    ...COMMON_BORDER_STYLE("solid"),
  }),
  NameCell: { width: "70%", maxWidth: "70%", minWidth: "200px" },
  ImportanceCell: { width: "10%", maxWidth: "10%", minWidth: "95px" },
  DifficultyCell: { width: "10%", maxWidth: "10%", minWidth: "95px" },
  FrequencyCell: { width: "10%", maxWidth: "10%", minWidth: "95px" },

  CriteriaCell: { width: "65%", maxWidth: "65%", minWidth: "200px" },
  IDCell: { width: "5%", maxWidth: "5%", minWidth: "20px" },
  CategoryCell: { width: "30%", maxWidth: "30%", minWidth: "100px" },
};

const NAME_STYLE = (theme) => ({
  fontFamily: "Epilogue",
  color: theme.RAColors?.black,
  fontSize: "14px",
  lineHeight: "19px",
});

const EditIcon = ({ open, disable= false }) => {
  const [css, theme] = useStyletron();
 
  return (
    <StatefulPopover
    content={() => (disable ?
      <Block padding="10px" width="400px" className={ css(POPOVER_STYLE(theme))}>
       {"Job Role has been assigned to a user and can no longer change the given property"}
      </Block> : null
    )}
    triggerType={TRIGGER_TYPE.hover}
    showArrow
    accessibilityType={'tooltip'}
    placement="bottomRight"
  >
    <Block
      data-testid="edit-job-edit-icon"
      className={disable ? css(EDIT_BUTTON_DISABLE(theme)) : css(EDIT_BUTTON(theme))}
      onClick={disable ? null : open}
    >

      <svg width="12" height="12" viewBox="0 0 20 18">
        <path
          d="M0,14.2 L0,18 L3.8,18 L14.8,6.9 L11,3.1 L0,14.2 L0,14.2 Z M17.7,4 C18.1,3.6 18.1,3 17.7,2.6 L15.4,0.3 C15,-0.1 14.4,-0.1 14,0.3 L12.2,2.1 L16,5.9 L17.7,4 L17.7,4 Z M9,16 L7,18 L20,18 L20,16 L9,16 L9,16 Z"
          fill="currentColor"
        />
      </svg>
    </Block>
    </StatefulPopover>
  );
};

const EditJob = ({ match }) => {
  const [css, theme] = useStyletron();
  const history = useHistory();
  const setState = useSetState();
  const state = useTrackedState();

  const [tempData, setTempData] = useState(null);
  const [editTypes, setEditTypes] = useState([]);
  const [navigation, setNavigation] = useState("Overview");
  const [showDeleteRejection, setShowDeleteRejection] = useState(false);
  const [rejectionType, setRejectionType] = useState("");

  const mainRef = useRef();

  const userEmail = localStorage.getItem("email") || "";
  const userType = useMemo(() => {
    const username = localStorage.getItem("username");
    if ([null, undefined, ""].includes(username)) return null;
    //page access control - based on role
    var role = localStorage.getItem("viewAs") !== null ? localStorage.getItem("viewAs") : localStorage.getItem("role");
    var permittedRoles = getRolesPageVisibility("Job Roles");
    if (permittedRoles.includes(role))
      return "manager";

    return "member";
  }, []);

  



  const sections = {
    overview: "Overview",
    desiredTraits: "Desired-Traits",
    activities: "Activities",
    dolCompetency: "Competency-Checklist",
    description: "Description",
  };

  const currentRole = useMemo(() => {
    if (!state.jobRoles) return null;

    const roles = state.jobRoles?.JobRoles || [];

    return roles.find((role) => role.Index === String(match.params.id)) || null;
  }, [state.jobRoles, match]);

  const save = async (type, keys = []) => {
    const overrideData = keys.reduce((acc, key) => {
      acc[key] = tempData[key];
      return acc;
    }, {});

    const newRole = { ...currentRole, ...overrideData };
    const newJobRoles = state.jobRoles.JobRoles.map((role) => {
      if (role.Index !== newRole.Index) return role;
      return newRole;
    });

    // put request
    try {
      await updateJobRole(newRole, userEmail);
      setState((prev) => ({
        ...prev,
        jobRoles: { ...state.jobRoles, JobRoles: newJobRoles },
      }));
    } catch {
      showWarningDialogue("Updation");
    }
    setEditTypes(editTypes.filter((t) => t !== type));
  };

  const showWarningDialogue = (type) => {
    setRejectionType(type);
    setShowDeleteRejection(true);
  };

  const close = (type, keys = []) => {
    const overrideData = keys.reduce((acc, key) => {
      acc[key] = currentRole[key];
      return acc;
    }, {});

    setEditTypes(editTypes.filter((t) => t !== type));
    setTempData({ ...tempData, ...overrideData });
  };
  const open = (type) => setEditTypes([...editTypes, type]);

  const onInputChange = (key, value, index = null) => {
    if (index === null) {
      setTempData({ ...tempData, [key]: value });
    } else {
      const newKeyValue = [...tempData[key]];
      newKeyValue[index] = value;

      setTempData({ ...tempData, [key]: newKeyValue });
    }
  };

  const onAddInput = (key, placeholder) => {
    const newKeyValue = [...(tempData[key] || [])];
    newKeyValue[newKeyValue.length] = placeholder;

    setTempData({ ...tempData, [key]: newKeyValue });
  };

  const onDeleteInput = (key, index) => {
    let newKeyValue = [...tempData[key]];
    newKeyValue = newKeyValue.filter((_, i) => index !== i);

    setTempData({ ...tempData, [key]: newKeyValue });
  };

  const onDuplicateRole = async () => {
    const newRole = { ...(currentRole || {}) };

    newRole.RoleName = "Duplicate of " + newRole.RoleName;
    newRole.RoleCode = newRole.RoleCode + "-1";
    newRole.Status = "Draft";
    delete newRole.Index;

    await createJobRole(newRole, userEmail);

    // add to store
    setState((prev) => ({
      ...prev,
      jobRoles: {
        ...state.jobRoles,
        JobRoles: [...state.jobRoles.JobRoles, newRole],
      },
    }));

    // redirect
    history.push(`/job-roles`);
  };

  const onDeleteRole = async () => {
    // delete request
    try {
      await deleteJobRole(currentRole, userEmail);

      // delete in store
      setState((prev) => ({
        ...prev,
        jobRoles: {
          ...state.jobRoles,
          JobRoles: state.jobRoles.JobRoles.filter(
            (i) => i.Index !== currentRole.Index
          ),
        },
      }));

      history.push("/job-roles");
    } catch {
      showWarningDialogue("Deletion");
    }
  };

  const goTo = (id) => {
    setNavigation(id);
    const dom = document.querySelector(`#${id}`);

    if (mainRef.current && dom) {
      mainRef.current.scrollTo({
        top: dom.offsetTop - 40 - 30,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    let isMounted = true;
    const fetchRoles = async () => {
      try {
        const [jobResponse, dolTemplatesResponse] = await Promise.all([
          fetchJobRoles(userEmail, ""),
          fetchDOLTemplate(userEmail),
        ]);

        if (
          jobResponse.status !== 200 ||
          dolTemplatesResponse.status !== 200 ||
          !isMounted
        )
          return;
        setState((prev) => ({
          ...prev,
          jobRoles: jobResponse.data,
          dolTemplates: [...dolTemplatesResponse.data.reverse()],
        }));
      } catch (e) {
        if (!isMounted) return;
        console.log(e);
      }
    };

    if (!state.jobRoles || !state.dolTemplates) fetchRoles();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (tempData === null && currentRole) setTempData(currentRole);
  }, [currentRole, tempData]);

  const dolTemplate = useMemo(() => {
    if (!currentRole || !state.dolTemplates) return null;

    return state.dolTemplates.find(
      (d) => d.TemplateId === currentRole.TemplateID
    );
  }, [state.dolTemplates, currentRole]);

  if (state.jobRoles && !currentRole) {
    return <Redirect to="/job-roles" />;
  }

  if (!userType) return <Redirect to="/login" />;
  if (userType !== 'manager') return <Redirect to="/student" />;
  return (
    <>
      <Header />
      <DrawerRouterContainer>
        <Block as="main" className={css(MAIN_STYLE(theme))} ref={mainRef}>
          <Block className={css(TITLE(theme))}>
            <BreadCrumb currentRole={currentRole} />
            <Block display="flex" flexDirection={['column', 'column', 'row']} width={['100%', '100%', 'initial']} gridGap="16px">
              <Button
                overrides={{
                  BaseButton: { style: NEW_ROLE_BUTTON_OVERRIDE_STYLE },
                }}
                onClick={() => history.push("/job-roles/create")}
                data-testid="edit-job-new-role"
              >
                New Role
              </Button>
              <Button
                overrides={{
                  BaseButton: { style: DUPLICATE_BUTTON_OVERRIDE_STYLE },
                }}
                onClick={onDuplicateRole}
                disabled={!state.jobRoles}
                data-testid="edit-job-duplicate-role"
              >
                Duplicate This Role
              </Button>
              <Actions onDelete={onDeleteRole}>
                <Button
                  overrides={{
                    BaseButton: { style: DUPLICATE_BUTTON_OVERRIDE_STYLE },
                  }}
                  data-testid="edit-job-dot"
                >
                  •••
                </Button>
              </Actions>
            </Block>
          </Block>
          {state.jobRoles && (
            <Block className={css(CONTENT(theme))}>
              <Block className={css(SIDEBAR(theme))}>
                {Object.values(sections)
                  .filter((i) => i !== "Description")
                  .map((id, index) => (
                    <Block
                      key={index}
                      className={css(HEADING(id === navigation))}
                      onClick={() => goTo(id)}
                      data-testid="edit-job-nav"
                    >
                      {id.replace("-", " ")}
                    </Block>
                  ))}
              </Block>
              <Block display={"block"} width="100%">
                <Block
                  id={sections.overview}
                  className={css(COMMON_CARD_STYLE(theme))}
                  marginBottom="20px"
                >
                  <Block
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    marginBottom="9px"
                  >
                    <Block
                      className={css(NAME)}
                      data-testid="edit-job-basic-info-title"
                    >
                      {editTypes.includes(sections.overview)
                        ? "Basic Info"
                        : currentRole.RoleName}
                    </Block>
                    {currentRole?.CreatedBy ===
                    localStorage.getItem("email") ? (
                      <EditIcon open={() => open(sections.overview)} disable={currentRole?.UserCount === 0 ? false : true}/>
                    ) : null}
                  </Block>
                  {editTypes.includes(sections.overview) ? (
                    <Block data-testid="edit-job-basic-info-edit">
                      <BasicInfo data={tempData} onChange={onInputChange} />
                      <EditButtons
                        save={() =>
                          save(sections.overview, [
                            "RoleName",
                            "RoleCode",
                            "Function",
                            "Department",
                            "JobGroup",
                            "Description",
                          ])
                        }
                        close={() =>
                          close(sections.overview, [
                            "RoleName",
                            "RoleCode",
                            "Function",
                            "Department",
                            "JobGroup",
                            "Description",
                          ])
                        }
                      />
                    </Block>
                  ) : (
                    <Block>
                      <Block
                        display="flex"
                        gridGap={["16px", "16px", "24px"]}
                        flexWrap="wrap"
                        marginBottom="20px"
                        marginTop="20px"
                        data-testid="edit-job-basic-info"
                      >
                        <Block>
                          <Block
                            className={css(SMALL_HEADING_2)}
                            data-testid="code_label"
                          >
                            Code
                          </Block>
                          <Block className={css(CODE)} data-testid="code_value">
                            {currentRole.RoleCode}
                          </Block>
                        </Block>
                        <Block>
                          <Block
                            className={css(SMALL_HEADING_2)}
                            data-testid="function_label"
                          >
                            Function
                          </Block>
                          <Block
                            className={css(CODE)}
                            data-testid="function_value"
                          >
                            {currentRole.Function}
                          </Block>
                        </Block>
                        <Block>
                          <Block
                            className={css(SMALL_HEADING_2)}
                            data-testid="department_label"
                          >
                            Department
                          </Block>
                          <Block
                            className={css(CODE)}
                            data-testid="department_value"
                          >
                            {currentRole.Department}
                          </Block>
                        </Block>
                        <Block>
                          <Block
                            className={css(SMALL_HEADING_2)}
                            data-testid="jobgroup_label"
                          >
                            JobGroup
                          </Block>
                          <Block
                            className={css(CODE)}
                            data-testid="jobgroup_value"
                          >
                            {currentRole.JobGroup}
                          </Block>
                        </Block>
                      </Block>
                      <Block>
                        <Block
                          className={css(SMALL_HEADING_2)}
                          marginBottom="4px"
                          data-testid="description_label"
                        >
                          Description
                        </Block>
                        <Block
                          className={css(TEXT_CONTENT)}
                          data-testid="description_value"
                        >
                          {currentRole.Description}
                        </Block>
                      </Block>
                    </Block>
                  )}
                </Block>

                <Block
                  id={sections.desiredTraits}
                  className={css(COMMON_CARD_STYLE(theme))}
                  marginBottom="20px"
                >
                  <Block
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    marginBottom="20px"
                  >
                    <Block
                      className={css(SMALL_HEADING)}
                      data-testid="desired_label"
                    >
                      Desired Traits
                    </Block>
                    {currentRole?.CreatedBy ===
                    localStorage.getItem("email") ? (
                      <EditIcon open={() => open(sections.desiredTraits)} disable={currentRole?.UserCount === 0 ? false : true}/>
                    ) : null}
                  </Block>

                  {editTypes.includes(sections.desiredTraits) ? (
                    <Block data-testid="edit-job-desired-traits-edit">
                      <List
                        value={tempData.JobRoleTraits}
                        onChange={(item, index) =>
                          onInputChange("JobRoleTraits", item, index)
                        }
                        name="DesiredTrait"
                        onAdd={() =>
                          onAddInput("JobRoleTraits", { DesiredTrait: "Trait" })
                        }
                        onDelete={(index) =>
                          onDeleteInput("JobRoleTraits", index)
                        }
                        fieldName="Trait Name"
                      />
                      <EditButtons
                        save={() =>
                          save(sections.desiredTraits, ["JobRoleTraits"])
                        }
                        close={() =>
                          close(sections.desiredTraits, ["JobRoleTraits"])
                        }
                      />
                    </Block>
                  ) : (
                    <Block data-testid="edit-job-desired-traits">
                      {currentRole.JobRoleTraits?.length > 0 ? (
                        currentRole.JobRoleTraits.map(
                          ({ DesiredTrait }, index) =>
                            DesiredTrait !== "" ? (
                              <Block
                                key={index}
                                as="ul"
                                paddingLeft="24px"
                                margin={0}
                                className={css(TEXT_CONTENT)}
                              >
                                <Block as="li" key={index}>
                                  {DesiredTrait}
                                </Block>
                              </Block>
                            ) : (
                              <Block as="b" key={index}>
                                {" "}
                                Not Specified{" "}
                              </Block>
                            )
                        )
                      ) : (
                        <Block as="b"> Not Specified </Block>
                      )}
                    </Block>
                  )}
                </Block>

                <Block
                  id={sections.activities}
                  className={css(COMMON_CARD_STYLE(theme))}
                  marginBottom="20px"
                >
                  <Block
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    marginBottom="20px"
                  >
                    <Block
                      className={css(SMALL_HEADING)}
                      data-testid="activities_label"
                    >
                      Activities
                    </Block>
                    {currentRole?.CreatedBy ===
                    localStorage.getItem("email") ? (
                      <EditIcon open={() => open(sections.activities)} disable={currentRole?.UserCount === 0 ? false : true}/>
                    ) : null}
                  </Block>
                  {editTypes.includes(sections.activities) ? (
                    <Block data-testid="edit-job-activities-edit">
                      <ActivityList
                        value={tempData.JobRoleActivities}
                        onChange={(item, index) =>
                          onInputChange("JobRoleActivities", item, index)
                        }
                        onAdd={() =>
                          onAddInput("JobRoleActivities", {
                            ActivityName: "Activity",
                            Importance: "High",
                            Difficulty: "High",
                            Frequency: "Weekly",
                          })
                        }
                        onDelete={(index) =>
                          onDeleteInput("JobRoleActivities", index)
                        }
                      />
                      <EditButtons
                        save={() =>
                          save(sections.activities, ["JobRoleActivities"])
                        }
                        close={() =>
                          close(sections.activities, ["JobRoleActivities"])
                        }
                      />
                    </Block>
                  ) : (
                    <TableBuilder
                      data-testid="edit-job-activities"
                      data={currentRole.JobRoleActivities}
                      emptyMessage="No data"
                      overrides={{
                        Root: { style: TABLE_OVERRIDE_STYLE.Root },
                        TableBodyRow: {
                          component: ({ children }) => (
                            <Block as="tr">{children}</Block>
                          ),
                        },
                      }}
                    >
                      <TableBuilderColumn
                        header={<TableHeader name="Name" />}
                        overrides={{
                          TableHeadCell: {
                            style: TABLE_OVERRIDE_STYLE.NameCell,
                          },
                          TableBodyCell: {
                            style: TABLE_OVERRIDE_STYLE.NameCell,
                          },
                        }}
                      >
                        {(row) => (
                          <Block className={css(NAME_STYLE(theme))}>
                            {row?.ActivityName || "Not Specified"}
                          </Block>
                        )}
                      </TableBuilderColumn>
                      <TableBuilderColumn
                        header={<TableHeader name="Importance" />}
                        overrides={{
                          TableHeadCell: {
                            style: TABLE_OVERRIDE_STYLE.ImportanceCell,
                          },
                          TableBodyCell: {
                            style: TABLE_OVERRIDE_STYLE.ImportanceCell,
                          },
                        }}
                      >
                        {(row) => (
                          <Block className={css(NAME_STYLE(theme))}>
                            {row?.Importance || "Not Specified"}
                          </Block>
                        )}
                      </TableBuilderColumn>
                      <TableBuilderColumn
                        header={<TableHeader name="Difficulty" />}
                        overrides={{
                          TableHeadCell: {
                            style: TABLE_OVERRIDE_STYLE.DifficultyCell,
                          },
                          TableBodyCell: {
                            style: TABLE_OVERRIDE_STYLE.DifficultyCell,
                          },
                        }}
                      >
                        {(row) => (
                          <Block className={css(NAME_STYLE(theme))}>
                            {row?.Difficulty || "Not Specified"}
                          </Block>
                        )}
                      </TableBuilderColumn>
                      <TableBuilderColumn
                        header={<TableHeader name="Frequency" />}
                        overrides={{
                          TableHeadCell: {
                            style: TABLE_OVERRIDE_STYLE.FrequencyCell,
                          },
                          TableBodyCell: {
                            style: TABLE_OVERRIDE_STYLE.FrequencyCell,
                          },
                        }}
                      >
                        {(row) => (
                          <Block className={css(NAME_STYLE(theme))}>
                            {row?.Frequency || "Not Specified"}
                          </Block>
                        )}
                      </TableBuilderColumn>
                    </TableBuilder>
                  )}
                </Block>

                <Block
                  id={sections.dolCompetency}
                  className={css(COMMON_CARD_STYLE(theme))}
                  marginBottom="20px"
                >
                  <Block
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    marginBottom="20px"
                  >
                    <Block
                      className={css(SMALL_HEADING)}
                      data-testid="dol_competency_label"
                    >
                      Competency Checklist
                    </Block>
                    {currentRole?.CreatedBy ===
                    localStorage.getItem("email")  ? (
                      <EditIcon open={() => open(sections.dolCompetency)} disable={currentRole?.UserCount === 0 ? false : true}/>
                    ) : null}
                  </Block>
                  {editTypes.includes(sections.dolCompetency) ? (
                    <Block data-testid="edit-job-dol-competencies-edit">
                      <CompetencyChecklist
                        value={tempData.TemplateID}
                        onChange={(item) => onInputChange("TemplateID", item)}
                      />
                      <EditButtons
                        save={() =>
                          save(sections.dolCompetency, ["TemplateID"])
                        }
                        close={() =>
                          close(sections.dolCompetency, ["TemplateID"])
                        }
                      />
                    </Block>
                  ) : (
                    <Block data-testid="edit-job-dol-competencies">
                      {currentRole.TemplateID && dolTemplate ? (
                        <>
                          <Block
                            marginBottom="16px"
                            data-testid="description_label"
                          >
                            <Block as="span" className={css(SMALL_TEXT)}>
                              Template Name:{" "}
                            </Block>
                            <Block as="span" className={css(SMALL_HEADING_2)}>
                              {dolTemplate.TemplateName}{" - "}{dolTemplate.TemplateVersion}
                            </Block>
                          </Block>
                          <TemplateTable data={dolTemplate} />
                        </>
                      ) : (
                        <Block as="b"> Not Specified </Block>
                      )}
                    </Block>
                  )}
                </Block>
              </Block>
            </Block>
          )}

          {showDeleteRejection && (
            <DeleteRejection
              rejectionType={rejectionType}
              type="job role"
              close={() => setShowDeleteRejection(false)}
            />
          )}
        </Block>
      </DrawerRouterContainer>
    </>
  );
};

export default EditJob;
