import Keycloak from "keycloak-js";

const _kc = new Keycloak('/keycloak.json');
/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (successCallback,failureCallback,action=null) => {
    let initObj ={onLoad: 'login-required', 
    enableLogging: true ,         
    checkLoginIframe: false, 
    pkceMethod: 'S256',
    redirectUri: process.env.REACT_APP_LOGIN_REDIRECT_URL,
  
   }
   if(action){
    initObj.action = 'UPDATE_PASSWORD'

   }
  _kc.init(initObj)
    .then((authenticated) => {
    
      console.log("user is not authenticated..!",authenticated);

      successCallback(authenticated)
    })
    .catch(e=>{
        failureCallback(e)
    });
};

const initKeycloakForSSO = (successCallback,failureCallback) => {
    _kc.init({onLoad: 'check-sso', 
    enableLogging: true ,         
    checkLoginIframe: false, 
    pkceMethod: 'S256',
    silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
  
   })
      .then((authenticated) => {
      
        console.log("user is not authenticated..!",authenticated);
  
        successCallback(authenticated)
      })
      .catch(e=>{
          failureCallback(e)
      });
  };

const doRegister =(option)=> _kc.register(option);


const doLogin = _kc.login;

const doLogout = (option) =>_kc.logout(option);

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5)
    .then(successCallback)
    .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const getParsedToken = () => _kc.tokenParsed;


const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const loadProfileDetail = (successCallback,failureCallback) =>
  _kc.loadUserProfile()
    .then((profile) =>successCallback(profile))
    .catch(e=>failureCallback(e));

const KeyCloakService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  loadProfileDetail,
  getParsedToken,
  initKeycloakForSSO,
  doRegister
};

export default KeyCloakService;