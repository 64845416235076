import { useState } from 'react';
import { createContainer } from 'react-tracked';
import { useStateWithCallbackLazy } from 'use-state-with-callback';

const useValue = () => useStateWithCallbackLazy({},()=>{
  
});

export const {
  Provider,
  useTrackedState,
  useUpdate: useSetState
} = createContainer(useValue);

