import React, { useState, useEffect} from "react";
import { Tab, StatefulTabs } from "baseui/tabs";
import { Block } from "baseui/block";
import DrawerRouterContainer from "components/newDrawer";
import { Header } from "components/Header";
import { useStyletron } from "baseui";
import TableData from "./tableData";
//import Data from "../MockData/rolespermission.json";
import { Accordion, Panel } from "baseui/accordion";
import RolesPermission from "services/requests/rolesPermissions";
import { useSetState } from "store/store";
import getRolesPageVisibility from "../../components/getPageVisibility"
import { Redirect } from "react-router-dom";

const MAIN_STYLE = (theme) => ({
  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "40px",
  },
});
const HEADING_STYLE = (theme) => ({
  fontFamily: "Epilogue-Semibold",
  fontSize: "28px",
  lineHeight: "40px",
  color: theme.RAColors?.gray900,
  // display: "inline",
  marginBottom: "20px",
});

export default function Roles() {
  const [css, theme] = useStyletron();
  const [activeKey, setActiveKey] = React.useState("0");
  const [update, setUpdate] = React.useState(false);
  const [data, setData] = React.useState([]);
  const setState = useSetState();

  useEffect(() => {
    const userEmail = localStorage.getItem("email");
    localStorage.setItem("currentPage","roles")
    rolesPermissions(userEmail,false);
  }, []);

  const rolesPermissions = (userEmail, val) => {
    RolesPermission(userEmail,localStorage.getItem("role"))
      .then(function (response) {
        if (response.status === 200) {
          setData(
          response.data.filter(obj => {
            return obj.role !== "ADMIN";
          }));
          //setState({rolespermissionData : response.data})
          localStorage.setItem("rolesPermission",JSON.stringify(response.data))
          if(val === true){
              setUpdate(response.data)
           }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updatedData = (updatedValues) =>{
     if(updatedValues === "Updated"){
      rolesPermissions(localStorage.getItem("email"),true)
     }
  }

   //page access control - based on role
   var role = localStorage.getItem("viewAs") !== null ? localStorage.getItem("viewAs") : localStorage.getItem("role");
   var permittedRoles = getRolesPageVisibility("Roles & Permissions");
   if (!permittedRoles.includes(role)) return <Redirect to="/student" />;

  return (
    <Block>
      <Header />
      <DrawerRouterContainer update={update} >
        <Block className={css(MAIN_STYLE(theme))}>
          <Block
            width="100%"
            display="flex"
            alignItems={["flex-start", "flex-start"]}
            justifyContent="space-between"
            flexDirection={["column", "column", "row"]}
            gridGap="16px"
          >
            <Block
              as="h3"
              className={css(HEADING_STYLE(theme))}
              data-testid="title"
            >
              {"Roles & Permissions"}
            </Block>
          </Block>
          <Block>
            {data.map((item, index) => (
              <Accordion
                onChange={({ expanded }) => console.log(expanded)}
                accordion
              >
                <Panel
                  overrides={{
                    Content: {
                      style: { paddingTop: "5px", paddingBottom: "10px" },
                    },
                    PanelContainer: {
                      style: {
                        borderBottomWidth: "1px",
                        borderBottomColor: "#E0E0E0",
                      },
                    },
                  }}
                  title={item.role}
                >
                  <StatefulTabs
                    initialState={{ activeKey: "0" }}
                    overrides={{
                      Root: { style: { paddingTop: "0px" } },
                      Tab: { style: { padding: "16px", margin: 0 } },
                      TabBar: {
                        style: { paddingLeft: 0, borderRadius: "8px  8px 0 0" },
                      },
                      TabContent: {
                        style: {
                          paddingLeft: 0,
                          paddingRight: 0,
                          paddingTop: 0,
                        },
                      },
                    }}
                  >
                    {/* <Tabs
              onChange={({ activeKey }) => {
                setActiveKey(activeKey);
              }}
              activeKey={activeKey}
            > */}

                    {item.data.map((obj, index1) => {
                      return (
                        <Tab title={obj.page}>
                          <TableData
                            dataValues={obj.items}
                            updatedData={updatedData}
                            obj={obj}
                            roleVal={item.role}
                          />
                        </Tab>
                      );
                    })}
                  </StatefulTabs>
                </Panel>
              </Accordion>
            ))}
          </Block>
        </Block>
      </DrawerRouterContainer>
    </Block>
  );
}
