import React, { useEffect, useState } from "react";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import BasicInfoDetails from "../BasicInfo/basic_info_details";
import BasicInfoEdit from "../BasicInfo/basic_info_edit";
import {
  CARD_STYLE,
  CARD_HEADING_STYLE,
  CARD_EDIT_ICON_STYLE,
} from "../../style.js";
import { BASIC_INFO_TITLE } from "resources/constants/strings";

export default function BasicInfo(props) {
  const [css, theme] = useStyletron();
  const [visible, setVisible] = React.useState(false);
  const [uploadedImage, setUploadedImage] = React.useState();
  const [studProfile, setStudProfile] = useState([]);


  const thm_username = localStorage.getItem("thm_username");
  const role = localStorage.getItem("thm_username");
  React.useEffect(() => {
    setVisible(
      !localStorage.getItem("thm_username") &&
        localStorage.getItem("role") === "LEARNER"
    );
  }, [thm_username, role]);
  useEffect(() => {
    setStudProfile(props.basicProfileData);
  }, [props.basicProfileData]);
  const basicInfoEdit = () => {
    setVisible(true);
  };
  const callbackFunction = (childData) => {
    setVisible(childData);
  };
  const uploadedFunction = (childData) => {
    setUploadedImage(childData);
  };

  return (
    <Block className={css(CARD_STYLE(theme))}>
      <Block as="h3" className={css(CARD_HEADING_STYLE)} data-testid="title">
        {BASIC_INFO_TITLE}
      </Block>
      {visible ? (
        <BasicInfoEdit
          basicProfileData={studProfile}
          parentCallback={callbackFunction}
          uploadedBack={uploadedFunction}
          setUpdateData={props.setUpdateData}
          loader={props.loader}
        />
      ) : (
        <Block>
          <Block
            as="span"
            data-testid="edit"
            className={css(CARD_EDIT_ICON_STYLE(theme))}
            onClick={basicInfoEdit}
          >
            <svg width="12" height="12" viewBox="0 0 20 18">
              <path
                d="M0,14.2 L0,18 L3.8,18 L14.8,6.9 L11,3.1 L0,14.2 L0,14.2 Z M17.7,4 C18.1,3.6 18.1,3 17.7,2.6 L15.4,0.3 C15,-0.1 14.4,-0.1 14,0.3 L12.2,2.1 L16,5.9 L17.7,4 L17.7,4 Z M9,16 L7,18 L20,18 L20,16 L9,16 L9,16 Z"
                fill="currentColor"
              />
            </svg>
          </Block>
          <BasicInfoDetails
            basicProfileData={studProfile}
            uploadedImage={uploadedImage}
            updateData={props.updateData}
            loader={props.loader}
          />
        </Block>
      )}
    </Block>
  );
}
