import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import { ChevronRight } from "baseui/icon";
import { Link } from "react-router-dom";
import { Summary_Text, Details } from "resources/constants/strings.js";
import { filteredAdmin } from "utils/app-utils";

const TITLE_STYLE = (theme) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "16px",
  flexWrap: "wrap",
});

const ITEM_TITLE_STYLE = {
  fontFamily: "Manrope-Medium",
  fontSize: "12px",
  lineHeight: "16px",
};

const ITEM_CONTENT_STYLE = {
  fontFamily: "Manrope-Bold",
  fontSize: "18px",
  lineHeight: "25px",
  textTransform: "capitalize",
};

const BreadCrumb = ({ currentRole }) => {
  const [css, theme] = useStyletron();
  return (
    <Block className={css(TITLE_STYLE(theme))}>
      <Block
        display="flex"
        flexDirection="row"
        gridGap="20px"
        alignItems="center"
      >
        <Block display="flex" flexDirection="column" gridGap="4px">
          <Block
            className={css(ITEM_TITLE_STYLE)}
            data-testid="breadcrumb-summary"
          >
            {Summary_Text}
          </Block>
          <Link
            to="/job-roles"
            className={css({
              ...ITEM_CONTENT_STYLE,
              color: theme?.RAColors?.blue700,
            })}
          >
            {"Job Roles"}
          </Link>
        </Block>
        <ChevronRight size={32} />
        <Block display="flex" flexDirection="column" gridGap="4px">
          <Block className={css(ITEM_TITLE_STYLE)} data-testid="details_text">{Details}</Block>
          <Block
            className={css({
              ...ITEM_CONTENT_STYLE,
              color: theme?.RAColors?.gray800,
            })}
          >
            {currentRole?.RoleName}
          </Block>
        </Block>
      </Block>
    </Block>
  );
};

export default BreadCrumb;
