import React from "react";
import { Block } from "baseui/block";
import { ButtonGroup } from "baseui/button-group";
import { Button } from "baseui/button";
import { useStyletron } from "baseui";
import { Modal, ModalBody, SIZE, ROLE } from "baseui/modal";
import {
  COMMON_RADIUS,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_COLOR,
  COMMON_BORDER_STYLE,
} from "utils/style";

const MODAL_OVERRIDE_STYLE = {
  Dialog: {
    style: ({ $theme }) => ({
      ...COMMON_RADIUS("8px"),
    }),
  },
};
const MODAL_BODY_STYLE = (theme) => ({
  margin: "24px !important",
  [theme.mediaQuery.small]: {
    margin: "16px !important",
  },
  [theme.mediaQuery.medium]: {
    margin: "24px !important",
  },
});

const TITLE_STYLE = (theme) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "16px",
  flexWrap: "wrap",

  [theme.mediaQuery.small]: {
    marginBottom: "16px",
  },
  [theme.mediaQuery.medium]: {
    marginBottom: "36px",
  },
});
const HEADING_TEXT_STYLE = (theme) => ({
  fontSize: "24px",
  lineHeight: '32px',
  fontFamily: 'Manrope-Bold',
  color: theme.RAColors?.black,
});

const COMMON_BUTTON_STYLE = {
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  paddingLeft: "24px",
  paddingRight: "24px",
  paddingBottom: "10px",
  paddingTop: "10px",
  fontFamily: "Manrope-Bold",
  fontSize: "16px",
  lineHeight: "22px",
  ...COMMON_RADIUS('8px'),
}

const SAVE_BTN_STYLE = ({ $theme }) => ({
  ...COMMON_BUTTON_STYLE,
  ...COMMON_BORDER_COLOR($theme.RAColors?.blue700),
  backgroundColor: $theme.RAColors?.blue700,
  ":hover": {
    backgroundColor: $theme.RAColors?.blue800,
  },
  color: "#FFFFFF",
  marginLeft: '16px'
});
const CANCEL_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_BUTTON_STYLE,
  backgroundColor: "white",
  borderColor: $theme.RAColors?.purple,
  ":hover": { backgroundColor: $theme.RAColors?.gray300 },
  color: $theme.RAColors?.purple,
  marginLeft: '16px'
});

const SaveModal = ({ isOpen, cancel, update, version }) => {
  const [css, theme] = useStyletron();

  return (
    <Modal
      onClose={cancel}
      closeable
      isOpen={isOpen}
      animate
      autoFocus={false}
      focusLock
      size={SIZE.default}
      role={ROLE.dialog}
      overrides={MODAL_OVERRIDE_STYLE}
    >
      <ModalBody className={css(MODAL_BODY_STYLE(theme))}>
        <Block
          display="block"
          justifyContent="space-between"
          alignItems="center"
          gridGap="16px"
          marginBottom={["16px", "16px", "20px"]}
          flexWrap="wrap"
        >
          <Block className={css(TITLE_STYLE(theme))}>
            <Block
              as="h5" margin={0} className={css(HEADING_TEXT_STYLE(theme))}
              data-testid="save-modal-title"
            >
              Save Settings
            </Block>
          </Block>
          <Block display="flex" justifyContent="end">
            <ButtonGroup>
              <Button
                onClick={update}
                overrides={{ BaseButton: { style: SAVE_BTN_STYLE } }}
                data-testid="save-modal-update"
              >
                Update
              </Button>
              <Button
                onClick={version}
                overrides={{ BaseButton: { style: SAVE_BTN_STYLE } }}
                data-testid="save-modal-version"
              >
                Version
              </Button>
              <Button
                onClick={cancel}
                overrides={{ BaseButton: { style: CANCEL_OVERRIDE_STYLE } }}
                data-testid="save-modal-cancel"
              >
                Cancel
              </Button>
            </ButtonGroup>
          </Block>
        </Block>
      </ModalBody>
    </Modal>
  );
};

export default SaveModal;
