import React, { useState } from "react";
import { Block } from "baseui/block";
import { Input } from "baseui/input";
import { ButtonGroup } from "baseui/button-group";
import { Button } from "baseui/button";
import { useStyletron } from "baseui";
import { Modal, ModalBody, SIZE, ROLE } from "baseui/modal";
import Loader from "react-js-loader";
import {
  COMMON_RADIUS,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_COLOR,
  COMMON_BORDER_STYLE,
} from "utils/style";
import jobGroupApi from "services/requests/jobGroup";

const MODAL_OVERRIDE_STYLE = {
  Dialog: {
    style: ({ $theme }) => ({
      ...COMMON_RADIUS("8px"),
    }),
  },
};
const MODAL_BODY_STYLE = (theme) => ({
  margin: "24px !important",
  [theme.mediaQuery.small]: {
    margin: "16px !important",
  },
  [theme.mediaQuery.medium]: {
    margin: "24px !important",
  },
});
const COMMON_TEXT_STYLE = {
  fontFamily: "Manrope",
  lineHeight: "24px",
  fontWeight: 700,
};
const TITLE_STYLE = (theme) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "16px",
  flexWrap: "wrap",

  [theme.mediaQuery.small]: {
    marginBottom: "16px",
  },
  [theme.mediaQuery.medium]: {
    marginBottom: "36px",
  },
});
const HEADING_TEXT_STYLE = (theme) => ({
  fontSize: "24px",
  lineHeight: "32px",
  fontFamily: "Manrope-Bold",
  color: theme.RAColors?.black,
});

const LABEL_STYLE = (theme) => ({
  ...COMMON_TEXT_STYLE,
  fontFamily: "Manrope-medium",
  fontWeight: 500,
  lineHeight: "22px",
  margin: "0 0 9px",
  [theme.mediaQuery.small]: {
    fontSize: "14px",
  },
  [theme.mediaQuery.medium]: {
    fontSize: "16px",
  },
});
const INPUT_OVERRIDE_STYLE = {
  Input: ({ $theme }) => ({
    backgroundColor: "none",
    color: $theme.RAColors?.gray900,
  }),
  Root: ({ $theme, $error }) => ({
    backgroundColor: "none",
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_COLOR(
      $error ? $theme.RAColors?.red600 : $theme.RAColors?.gray200
    ),
    ...COMMON_BORDER_WIDTH("1px"),
    width: "100%",
  }),
};
const SAVE_BTN_STYLE = ($theme) => ({
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  ...COMMON_BORDER_COLOR($theme.RAColors?.blue700),
  width: "87",
  paddingLeft: "24px",
  paddingRight: "24px",
  paddingBottom: "10px",
  paddingTop: "10px",
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontSize: "16px",
  fontWeight: "700",
  justifyContent: "center",
  alignItems: "center",
  lineHeight: "22px",
  ...COMMON_RADIUS("8px"),
  backgroundColor: $theme.RAColors?.blue700,
  ":hover": {
    backgroundColor: $theme.RAColors?.blue800,
  },
  color: "#FFFFFF",
});
const BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  backgroundColor: "white",
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  borderColor: $theme.RAColors?.purple,
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingRight: "24px",
  paddingLeft: "24px",
  marginLeft: "12px",
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "22px",
  display: "flex",
  ":hover": { backgroundColor: $theme.RAColors?.gray300 },
  color: $theme.RAColors?.purple,

  [$theme.mediaQuery.small]: {
    width: "100%",
  },
  [$theme.mediaQuery.medium]: {
    width: "initial",
  },
});

const SUBTEXT_STYLE = theme => ({
  fontSize: '14px',
  lineHeight: '20px',
  marginTop: '4px',
  color: theme?.RAColors?.red600,
  display: 'inline-block'
})


const ModalComponent = ({ showModal, close, jobGroup=null, datavals }) => {
  const [css, theme] = useStyletron();
  const [jobName, setJobName] = useState("");
  const [loader, setLoader] = React.useState(false);
  const [error, setError] = useState(false)

  const userEmail = localStorage.getItem("email") || "";

  const saveJob = (event) => {
    event.preventDefault();
    // validation unique name
    const isNameUnique = datavals.every(d => d?.JobGroup !== jobName)
    setError(!isNameUnique)
    if (!isNameUnique) return

    if (jobName !== "") {
      const payload = { JobGroup: jobName };
      setLoader(true);
      jobGroupApi("Create", payload, userEmail)
        .then(function (response) {
          if (response.status === 200) {
            setLoader(false);
            if(jobGroup){
              jobGroup("Created");

            }
            close();
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    }
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <Modal
      onClose={close}
      closeable
      isOpen={Boolean(showModal)}
      animate
      autoFocus
      size={SIZE.default}
      role={ROLE.dialog}
      overrides={MODAL_OVERRIDE_STYLE}
    >
      <ModalBody className={css(MODAL_BODY_STYLE(theme))}>
        <form>
          <Block
            display="block"
            justifyContent="space-between"
            alignItems="center"
            gridGap="16px"
            marginBottom={["16px", "16px", "20px"]}
            flexWrap="wrap"
          >
            <Block className={css(TITLE_STYLE(theme))}>
              <Block
                as="h5"
                margin={0}
                className={css(HEADING_TEXT_STYLE(theme))}
                data-testid="modal-name"
              >
                New Job Group
              </Block>
            </Block>
            <Block
              width={["100%", "100%", "100%"]}
              marginTop="20px"
              marginBottom="30px"
            >
              <Block as="h5" className={css(LABEL_STYLE(theme))} data-testid="modal-name-label">
                {"Name"}
              </Block>
              <Input
                value={jobName}
                onChange={(e) =>
                  setJobName(capitalizeFirstLetter(e.target.value))
                }
                placeholder="Enter text here"
                clearOnEscape
                data-testid="modal-name-input"
                required
                overrides={{
                  Input: { style: INPUT_OVERRIDE_STYLE.Input },
                  Root: { style: INPUT_OVERRIDE_STYLE.Root },
                }}
                error={error}
              />
              <Block
                as="span" className={css(SUBTEXT_STYLE(theme))}
                data-testid="new-template-name-sub"
              >
                * Job group name must be unique
              </Block>
            </Block>
            <Block display="flex" justifyContent="end">
              <ButtonGroup>
                <Button
                  type="submit"
                  onClick={(event) => saveJob(event)}
                  data-testid="modal-save-btn"
                  overrides={{ BaseButton: { style: SAVE_BTN_STYLE(theme) } }}
                >
                  <span>{"Save"}</span>
                </Button>
                <Button
                  onClick={() => close()}
                  data-testid="modal-cancel-btn"
                  overrides={{ BaseButton: { style: BUTTON_OVERRIDE_STYLE } }}
                >
                  <span>{"Cancel"}</span>
                </Button>
              </ButtonGroup>
            </Block>
          </Block>
        </form>
      </ModalBody>
      {loader ? (
        <Loader
          type="bubble-loop"
          bgColor={"#332ff0"}
          color={"#332ff0"}
          size={100}
        />
      ) : null}
    </Modal>
  );
};

export default ModalComponent;
