import React, { lazy, Suspense, useState, useEffect, useRef } from "react";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import { Input } from "baseui/input";
import { Button } from "baseui/button";
import { Select } from "baseui/select";
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import { Tabs, Tab, ORIENTATION } from "baseui/tabs";
import ProgressBar from "components/progress";
import Skeleton from "components/Skeleton";
import Loader from "react-js-loader";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import DefaultAvatar from "assets/images/user-profile.svg";
import NoAssignRolesAvatar from "assets/images/no_assign_avatar.svg";
import DolCompetencies from "../components/dolCompetency/dolCompetencies";
import RoleSpecificActivities from "../components/dolCompetency/roleSpecificActivities";
import { fetchJobRoles } from "services/requests/job_role";
import assignJobRole from "services/requests/assignJobRole";
import bulkSignoff from "services/requests/bulkSignoff";
import { deepCopy } from "pages/DolCriteria/util";
import { useSetState, useTrackedState } from "store/store";
import {StatelessAccordion, Panel} from 'baseui/accordion';
import {
  StatefulPopover,
  PLACEMENT,
  TRIGGER_TYPE,
} from 'baseui/popover';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalButton,ModalHeader
} from 'baseui/modal';

import { Basic_info_role, EDIT, MISSING } from "resources/constants/strings.js";
import SearchIcon from "baseui/icon/search";
import {
  COMMON_RADIUS,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_COLOR,
  COMMON_BORDER_STYLE,
} from "utils/style";
import { sortAZSelectOptions } from "utils/select";
import { concat } from "mathjs";

const TAB_CONTENT_OVERRIDE_STYLE = ({ $theme }) => ({
  paddingLeft: "0px",
  paddingRight: "0px",
  paddingTop: "0px",
  paddingBottom: "0px",
  width: "100%",
});
const TAB_OVERRIDE_STYLE = ({ $theme, $active }) => ({
  paddingRight: "16px",
  paddingTop: "8px",
  paddingBottom: "8px",
  paddingLeft: "16px",
  fontFamily: "Manrope-Medium",
  fontSize: "18px",
  lineHeight: "25px",
  color: $active ? "#FFFFFF" : $theme?.RAColors?.black,
  backgroundColor: $active
    ? $theme?.RAColors?.gray900
    : $theme?.RAColors?.gray100,
  borderRadius: "24px",
  border: $active ? "none" : "2px solid #AFAFAF",
  marginLeft: 0,
  marginRight: 0,
  cursor: "pointer",
  [$theme.mediaQuery.small]: {
    width: "100%",
  },
  [$theme.mediaQuery.medium]: {
    width: "auto",
  },
  [$theme.mediaQuery.large]: {
    width: "auto",
  },
});
const BLOCK_STYLE = (theme) => ({
  backgroundColor: "white",
  borderRadius: "8px",
  padding: "16px",
  border: `1px solid ${theme.RAColors?.gray200}`,
  width: "auto",
  [theme.mediaQuery.small]: {
    width: "100%",
  },
  [theme.mediaQuery.medium]: {
    width: "auto",
  },
  [theme.mediaQuery.large]: {
    width: "auto",
  },
});
const BLOCK_STYLE_MAIN = (theme) => ({
  padding: "16px 8px 16px 0",
  width: "auto",
  [theme.mediaQuery.small]: {
    width: "100%",
  },
  [theme.mediaQuery.medium]: {
    width: "auto",
  },
  [theme.mediaQuery.large]: {
    width: "auto",
  },
});
const BLOCK_STYLE_MAIN_ALL =(theme) =>({
  padding: "16px 8px 16px 0",
  width: "161px",
  [theme.mediaQuery.small]: {
    width: "100%",
  },
  [theme.mediaQuery.medium]: {
    width: "auto",
  },
  [theme.mediaQuery.large]: {
    width: "auto",
  },
})
const BLOCK_STYLE_NEW = (theme) => ({
  backgroundColor: "white",
  // borderRadius: "8px",
  // padding: "16px",
  // border: `1px solid ${theme.RAColors?.gray200}`,
  width: "auto",
  [theme.mediaQuery.small]: {
    width: "100%",
  },
  [theme.mediaQuery.medium]: {
    width: "auto",
  },
  [theme.mediaQuery.large]: {
    width: "auto",
  },
});
const SORTBY_BLOCK_STYLE = (theme) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: "white",
  padding: "0",
  gridGap: "10px",
  width: "auto",
  [theme.mediaQuery.small]: {
    width: "100%",
  },
  [theme.mediaQuery.medium]: {
    width: "auto",
  },
  [theme.mediaQuery.large]: {
    width: "auto",
  },
});
const COMMON_TEXT_STYLE = {
  fontSize: "14px",
  lineHeight: "19px",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
const ITEM_TITLE_STYLE = (theme) => ({
  fontFamily: "Manrope",
  fontSize: "14px",
  lineHeight: "19px",
  color: theme?.RAColors?.gray800,
  paddingBottom: "8px",
  fontWeight:700
});
const ITEM_TITLE_STYLE_SUB = (theme) => ({
  fontFamily: "Manrope-Bold",
  fontWeight: 700,
  fontSize: "12px",
  lineHeight: "19px",
  color: theme?.RAColors?.gray800,
  paddingBottom: "8px",
});
const EDIT_TEXT_STYLE = (theme) => ({
  fontFamily: "Manrope-Semibold",
  fontSize: "14px",
  lineHeight: "19px",
  cursor: "pointer",
  color: theme?.RAColors?.blue800,

});
const MISSING_TEXT_STYLE = (theme) => ({
  fontFamily: "Manrope-Semibold",
  fontSize: "16px",
  marginTop: "5px",
  lineHeight: "22px",
  marginBottom: "4px",
  color: theme?.RAColors?.red800,
});
const ITEM_CONTENT_STYLE = (theme) => ({
  fontFamily: "Epilogue-Medium",
  fontSize: "28px",
  lineHeight: "40px",
  color: theme?.RAColors?.gray800,
});
const ITEM_CONTENT_STYLE_NEW = (theme) =>({
  fontFamily: "Manrope",
  fontSize: "16px",
  lineHeight: "22px",
  color: theme?.RAColors?.gray800,
  fontWeight:600
})
const ROLE_NAME_CONTENT_STYLE = (theme) => ({
  fontFamily: "Manrope",
  fontSize: "12px",
  lineHeight: "16.39px",
  fontWeight:500,
  color: theme?.RAColors?.gray800,
});
const NAME_STYLE = (theme) => ({
  ...COMMON_TEXT_STYLE,
  fontFamily: "Manrope-Semibold",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "22px",
  color: theme.RAColors?.gray800,
});
const EMAIL_STYLE = {
  ...COMMON_TEXT_STYLE,
  fontSize: "12px",
  fontFamily: "Manrope",
  fontWeight: 500,
};
const LEARNERS_MESSAGE_STYLE = (theme) => ({
  fontFamily: "Manrope-medium",
  marginTop: "20px",
  fontSize: "18px",
  fontWeight: "500",
  lineHeight: "25px",
  color: theme.RAColors?.black,
});
const LABEL_STYLE = (theme) => ({
  ...COMMON_TEXT_STYLE,
  fontFamily: "Manrope-medium",
  fontWeight: 500,
  lineHeight: "22px",
  margin: "0 0 9px",
  [theme.mediaQuery.small]: {
    fontSize: "14px",
  },
  [theme.mediaQuery.medium]: {
    fontSize: "16px",
  },
});
const FILTERS_LABEL_STYLE = (theme) => ({
  ...COMMON_TEXT_STYLE,
  fontFamily: "Manrope-medium",
  fontWeight: 500,
  lineHeight: "22px",
  color: "#252525",
  margin: 0,
  width:'53px',
  [theme.mediaQuery.small]: {
    fontSize: "14px",
  },
  [theme.mediaQuery.medium]: {
    fontSize: "16px",
  },
});
const STICKY_SECTION = (theme) => ({
  // position: "-webkit-sticky",
  // position: "sticky",
  top: 0,
  backgroundColor: "white",
  paddingBottom: "10px",
  [theme.mediaQuery.small]: {
    zIndex: 9999,
  },
  [theme.mediaQuery.medium]: {
    zIndex: 1,
  },
  [theme.mediaQuery.large]: {
    zIndex: 1,
  },
});
const SELECT_OVERRIDE_STYLE = {
  Root: ({ $theme }) => ({ width: "100%" }),
  ControlContainer: ({ $theme, $error }) => ({
    backgroundColor: "none",
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_COLOR(
      $error ? $theme.RAColors?.red600 : $theme.RAColors?.gray200
    ),
    ...COMMON_BORDER_WIDTH("1px"),
    color: $theme.RAColors?.gray900,
  }),
  DropdownContainer: ({ $theme }) => ({ width: "max-content" }),
};
const INPUT_OVERRIDE_STYLE = {
  Input: ({ $theme }) => ({
    backgroundColor: "none",
    color: $theme.RAColors?.gray900,
  }),
  Root: ({ $theme }) => ({
    backgroundColor: "none",
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    width: "198px",
    height: "48px",
    [$theme.mediaQuery.small]: {
      width: "100%",
    },
    [$theme.mediaQuery.medium]: {
      width: "198px",
    },
    [$theme.mediaQuery.large]: {
      width: "198px",
    },
  }),
  StartEnhancer: ({ $theme }) => ({
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: "transparent",
  }),
};
const BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  //borderColor: $theme.RAColors?.purple,
  paddingTop: "12px",
  paddingBottom: "12px",
  paddingRight: "24px",
  paddingLeft: "24px",
  ...COMMON_BORDER_COLOR($theme.RAColors?.blue700),
  backgroundColor: $theme.RAColors?.blue700,
  ":hover": { backgroundColor: $theme.RAColors?.blue800 },
  color: "white",

  [$theme.mediaQuery.small]: {
    width: "100%",
  },
  [$theme.mediaQuery.medium]: {
    width: "initial",
  },
});
const SAVE_BTN_STYLE = ($theme) => ({
  borderRadius: "10px",
  paddingTop: "14px",
  paddingBottom: "14px",
  paddingLeft: "16px",
  paddingRight: "16px",
  backgroundColor: "#141414",
  borderColor: "#332FF1",
  color: "#FFFFFF",
  ":hover": { backgroundColor: "#141414" },
});
const AVATAR_STYLE = { width: "60px", height: "60px", borderRadius: "100%" };
const NO_ASSIGN_AVATAR_STYLE = {
  width: "14px",
  height: "14px",
};
const SEPRATOR = {
  borderBottom: "1px solid #e0e0e0",
};
const ModalComponent = lazy(() => import("./switchRoles/switchRoles.js"));

const SearchRender = ({ collapse, expand }) => {
  const [css, theme] = useStyletron();
  return (
    <>
      <Block display="flex" gridGap="10px" justifyContent="flex-end">
        <Block
          className={css(EDIT_TEXT_STYLE(theme))}
          data-testid="collapse-all"
          onClick={collapse}
        >
          {"Collapse All"}
        </Block>
        <Block
          className={css(EDIT_TEXT_STYLE(theme))}
          data-testid="expand-all"
          onClick={expand}
        >
          {"Expand All"}
        </Block>
      </Block>
    </>
  );
};

const Summary = ({
  Data,
  changeDolData,
  allLearnerData,
  selectedIndex,
  updateData,
  rolespermission,
  handleClick,
  openVerifyCompetencyModal,
  loader,
}) => {
  const [css, theme] = useStyletron();
  const [row, setRow] = useState(false);
  const [switchRoleRow, setSwitchRoleRow] = useState(false);
  const [activeKey, setActiveKey] = React.useState("0");
  const [jobRole, setJobRole] = useState("");
  const [dolDetails, setDolDetails] = useStateWithCallbackLazy([]);
  const [jobRoleOptions, setJobRoleOptions] = React.useState([]);
  const [assignJobLoader, setAssignJobLoader] = React.useState(false);
  const [searchString, setSearchString] = React.useState("");
  const [learnerCheckboxValue, setLearnerCheckboxValue] = useState(false);
  const [technicalCheckboxValue, setTechnicalCheckboxValue] = useState(false);
  const [managerCheckboxVlue, setManagerCheckboxVlue] = useState(false);
  const [rtiCheckboxValue, setRTICheckboxValue] = useState(false);
  const userEmail = localStorage.getItem("email") || "";
  const [openedId, setOpenedId] = useState("");
  const [isSpecificActivity, setSpecificActivity] = useState(false);
  const [value, setValue] = React.useState("All competencies");
  const [values, setValues] = React.useState("1");
  const setState = useSetState();
  const state = useTrackedState();
  const DolCompetenciesRef = useRef(null);
  const RoleSpecificActivitiesRef = useRef(null);
  const [expanded, setExpanded] = React.useState(['L1', 'L2']);
  const [valuess, setValuess] = useState([true, true]);
  const [collpse, setCollapse] = useState("");
  let dolrit = 0; let dollearners = 0; let doltechnical =0;let dolmanager =0 ;let roleslearners = 0;let rolestechnical = 0;let rolesmanager = 0;
  const [dolrits, setDolRTI] = useState(0);
  const [learnerscount, setLearnerCount] = useState(0);
  const [technicalscount, setTechnicalCount] = useState(0);
  const [managerscount, setManagerCount] = useState(0);
  const [checked, setChecked] = React.useState(false);
  //const [bulkItemsrolecount, setBulkItemsRoleCount] = React.useState(0);
  //const [bulkItemscorecount, setBulkItemsCoreCount] = React.useState(0);
  const [corecount, setCoreCount] = React.useState([]);
  const [rolescount, setRolesCount] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [lemail, setLEmail] = React.useState('');
let mainCount =0;

let finalArr = []

  useEffect(() => {
    const fetchOption = async () => {
      let jobRoleArray = await getJobRole(userEmail);
      setJobRoleOptions(jobRoleArray);
    };
    fetchOption();
  }, []);

  function toggleAll() {
      setValuess([true, true]);
  }

  function close() {
    setIsOpen(false);
    //setBulkItemsRoleCount(0)
    //setBulkItemsCoreCount(0)
  }

  // const BulkItems = (val,method) =>{
  //   console.log(method,val)
  //  let obj = {}
  //  val.map((item) => {
  //      obj.ID = (method === "core") ? item?.CriteriaID : item?.TaskID;
  //      obj.isTask = (method === "core") ? false : true
  //      finalArr.push(obj)
  //  });

  //  finalArr = finalArr.filter( (ele, ind) => ind === finalArr.findIndex( elem => elem.ID === ele.ID))
  //  setBulkItemsCount(finalArr)
  // }
  
  const BulkItems = (val,method) =>{
    let finalArr =[];
    if(method === "core"){
      val.map((item) => {
        let obj = {}
           obj.ID = item.CriteriaID
           obj.isTask = false 
           finalArr.push(obj)
       });
       setCoreCount(finalArr)
    }else{
      val.map((item) => {
        let obj = {}
           obj.ID = item.TaskID;
           obj.isTask = true
           finalArr.push(obj)
       });
       setRolesCount(finalArr)
    }
    // setBulkItemsCount(mainarr)
  }

if(corecount !== null && rolescount !== null){
  let vals = corecount.length + rolescount.length
  //console.log("vals0------>",vals)
  mainCount = vals
}

  function handleClicks(index,radioVal) {
    let newValues = [];
    if (radioVal) {   
      if (index === 0) {
        newValues = [true, false];
      } else if(index === 1){
        newValues = [false, true];
      }
    } else {
      const nextValues = [...valuess];
      nextValues[index] = !nextValues[index];
      newValues = nextValues
    }
    setValuess(newValues);
  }

  useEffect(() => {
    uniqueDolList = addTaskName(uniqueDolList);
    uniqueDolList = addRoleSpecificActivitiesField(uniqueDolList,'');
    if (Data) {
      setState((prev) => ({ ...prev, learnerEmail: Data["EmailId"] }));
      setState((prev) => ({
        ...prev,
        learnerName: Data["LastName"] + " " + Data["FirstName"],
      }));
    }

    if (row) {
      if (isSpecificActivity) {
        let result;
        uniqueDolList.RoleSpecificActivities.filter((obj) => {
          if (obj.TaskID === openedId) {
            result = obj;
          }
        });
        setRow(result);
      } else {
        let result;
        uniqueDolList.Category.filter((obj) =>
          obj.Criteria.filter((criteria) => {
            if (criteria.CriteriaID === openedId) {
              result = criteria;
            }
          })
        );
        setRow(result);
      }
    }
    setDolDetails(uniqueDolList);
    setJobRole(""); //added to reset the assign job role dropdown once learner is changed.
    // if(value === "Only competencies that are ready to sign off by"){
    //   setValue("All competencies")
    // }
  }, [Data]);

  let uniqueDolList = Data?.LearnerChecklist;
  // to make the search functionality work with Role Specific Activities
  const addRoleSpecificActivitiesField = (data,Term) => {
    if (
      !data?.RoleSpecificActivities ||
      !Array.isArray(data?.RoleSpecificActivities)
    )
      return data;

    const addedFieldRoleSpecificActivities = data.RoleSpecificActivities.map(
      (item) => ({
        ...item,
        ParentName: "Role Specific Activities",
      })
    );
    if(Term === ''){
      let lcount = [], mcount =[], mncount =[];
      addedFieldRoleSpecificActivities.filter((obj) => {
        if(obj?.["LearnerProficientStatus"]?.CompletionStatus !== true && obj?.hasOwnProperty("LearnerProficientStatus")){
          lcount.push(obj) 
        }
        if(obj?.["MentorProficientStatus"]?.CompletionStatus !== true && obj?.hasOwnProperty("MentorProficientStatus")){
          mcount.push(obj)
        }
        if(obj?.["LearnerProficientStatus"]?.CompletionStatus === true && obj?.["MentorProficientStatus"]?.CompletionStatus === true && obj?.["FinalApprovalStatus"]?.CompletionStatus !== true && obj?.hasOwnProperty("FinalApprovalStatus")){
          mncount.push(obj)
        }
      });
      totalCount(0,lcount.length,mcount.length,mncount.length,"roles")
    }  
    return {
      ...data,
      RoleSpecificActivities: addedFieldRoleSpecificActivities,
    };
  };
  const addTaskName = (data) => {
    countData(data)
    try {
      for (var obj in data) {
        if (
          data.hasOwnProperty(obj) &&
          typeof data[obj] === "object" &&
          Array.isArray(data[obj]) &&
          data[obj] !== null
        ) {
          data[obj]?.forEach((items) => {
            items?.Criteria?.forEach((criteria) => {
              for (var key in criteria) {
                if (
                  typeof criteria[key] === "object" &&
                  !Array.isArray(criteria[key]) &&
                  criteria[key] !== null
                ) {
                  criteria[key].TaskName = key;
                }
              }

              criteria.isAccordionOpen = !criteria.IsCriteriaCompleted;
            });
            if (obj === "Category") {
              items.isAccordionOpen = !items.IsCatagoryCompleted;
            } else {
              items.isAccordionOpen = !items.IsTaskCompleted;
            }
          });
          //
        }
      }
      return data;
    } catch (e) {
      //console.log(e);
    }
  };

const countData = (data) => {
  let larr = [],tarr = [], marr = [],rarr =[];
  data?.Category?.filter((obj,ind) => {
  obj?.Criteria?.filter((objs,ind1) => {
    if( (objs?.hasOwnProperty("RTI") && objs?.hasOwnProperty("JS") && objs?.["RTI"]?.CompletionStatus === true && objs?.["JS"]?.CompletionStatus === true) ||
        (objs?.hasOwnProperty("RTI") && objs?.["RTI"]?.CompletionStatus === true &&  !objs?.hasOwnProperty("JS")) ||
        (!objs?.hasOwnProperty("RTI") && objs?.hasOwnProperty("JS") &&  objs?.["JS"]?.CompletionStatus === true) ||
        (!objs?.hasOwnProperty("RTI") && !objs?.hasOwnProperty("JS")) 
      )
      {
        if(objs?.hasOwnProperty("LearnerProficientStatus") && objs?.["LearnerProficientStatus"]?.CompletionStatus !== true)
        {
          larr.push(objs)
        }
      }
    if( (objs?.hasOwnProperty("RTI") && objs?.hasOwnProperty("JS") && objs?.["RTI"]?.CompletionStatus === true && objs?.["JS"]?.CompletionStatus === true) ||
      (objs?.hasOwnProperty("RTI") && objs?.["RTI"]?.CompletionStatus === true &&  !objs?.hasOwnProperty("JS")) ||
      (!objs?.hasOwnProperty("RTI") && objs?.hasOwnProperty("JS") &&  objs?.["JS"]?.CompletionStatus === true) ||
      (!objs?.hasOwnProperty("RTI") && !objs?.hasOwnProperty("JS")) 
      )
      {
        if(objs?.hasOwnProperty("MentorProficientStatus") && objs?.["MentorProficientStatus"]?.CompletionStatus !== true)
        {
          tarr.push(objs)
        }
      }
    if( (objs?.hasOwnProperty("MentorProficientStatus") && objs?.["MentorProficientStatus"]?.CompletionStatus === true && !objs?.hasOwnProperty("LearnerProficientStatus")) ||
      (!objs?.hasOwnProperty("MentorProficientStatus") && objs?.hasOwnProperty("LearnerProficientStatus") && objs?.["LearnerProficientStatus"]?.CompletionStatus === true) ||
      (objs?.hasOwnProperty("MentorProficientStatus") && objs?.hasOwnProperty("LearnerProficientStatus") && objs?.["MentorProficientStatus"]?.CompletionStatus === true && objs?.["LearnerProficientStatus"]?.CompletionStatus === true)
      )
      {
        if(objs?.hasOwnProperty("FinalApprovalStatus") && objs?.["FinalApprovalStatus"]?.CompletionStatus !== true)
        {
          marr.push(objs)
        }
      }
    if(objs?.["RTI"]?.CompletionStatus !== true && objs?.hasOwnProperty("RTI"))
    {
        rarr.push(objs)
    }
  });
 });
 totalCount(rarr.length,larr.length,tarr.length,marr.length,"core")
}

  const searchKeywords = (event) => {
    const searchString = event.target.value;
    setSearchString(searchString);
    let values = deepCopy(uniqueDolList);
    values = addRoleSpecificActivitiesField(values,"search");
    if (searchString !== "") {
      let newCategory = [];
      let newRoleSpecificActivities = [];

      values.Category.forEach((category) => {
        const { Criteria } = category;
        const newCriteria = Criteria.filter((c) =>
          c.CriteriaName.toLowerCase().includes(
            searchString.toLowerCase().trim()
          )
        );

        if (newCriteria.length) {
          newCategory.push({ ...category, Criteria: newCriteria });
        }
      });

      values.RoleSpecificActivities.forEach((activity) => {
        if (
          activity.TaskName.toLowerCase().includes(
            searchString.toLowerCase().trim()
          )
        ) {
          newRoleSpecificActivities.push(activity);
        }
      });

      setDolDetails({
        ...dolDetails,
        Category: newCategory,
        RoleSpecificActivities: newRoleSpecificActivities,
      });
    } else {
      setDolDetails(values);
    }
  };
  const getAvailableTasks = (itemObject) => {
    let array = [
      "RTI",
      "RTIStatus",
      "JS",
      "JSStatus",
      // "OJT",
      // "OJTStatus",
      "MentorProficientStatus",
      "LearnerProficientStatus",
      "FinalApprovalStatus",
    ];
    let availableListedTask = Object.keys(itemObject);

    let filteredAvailableListedTask = array.filter(function (n) {
      return availableListedTask.indexOf(n) !== -1;
    });
    return filteredAvailableListedTask;
  };
  const openModal = (data) => {
    setSwitchRoleRow(data);
  };
  function closeModal() {
    setSwitchRoleRow(false);
    updateData(true);
  }
  const getJobRole = async () => {
    const response = await fetchJobRoles(userEmail, "Published");
    let jobRolesArray = [];
    var newArray = response?.data?.JobRoles?.filter(function (el) {
      return el.RoleName !== "";
    });
    newArray?.forEach((element, index) => {
      let obj = {};
      obj.label = element.RoleName;
      obj.id = index + 1;
      obj.value = element.RoleName;
      obj.index = parseInt(element.Index);
      jobRolesArray.push(obj);
    });
    return [
      ...new Map(jobRolesArray.map((item) => [item["value"], item])).values(),
    ];
  };
  const saveJob = (event, learnerEmail) => {
    event.stopPropagation();
    event.preventDefault();
    let userEmail = localStorage.getItem("email")
    if (jobRole !== "") {
      const payload = {
        RoleIndex: jobRole[0].index,
        LearnerEmail: learnerEmail,
      };
      setAssignJobLoader(true);
      assignJobRole(payload, userEmail)
        .then(function (response) {
          if (response.status === 200) {
            setAssignJobLoader(false);
            allLearnerData(userEmail);
            changeDolData(learnerEmail, selectedIndex);
          }
        })
        .catch((err) => {
          setAssignJobLoader(false);
          console.log(err);
        });
    }
  };
  const handleCallback = (childData) => {
    if (childData !== Data?.RoleName) {
      changeDolData(state?.learnerEmail, selectedIndex);
    }
  };
  const collapseAll = () => {
    // const refValue =
    //   activeKey === "0" ? DolCompetenciesRef : RoleSpecificActivitiesRef;
    // if (refValue.current) refValue.current?.collapseAll();
    DolCompetenciesRef.current?.collapseAll();
    RoleSpecificActivitiesRef.current?.collapseAll();
    setValuess([false, false]);
    setCollapse("collapse")
  };
  const expandAll = () => {
    // const refValue =
    //   activeKey === "0" ? DolCompetenciesRef : RoleSpecificActivitiesRef;
    // if (refValue.current) refValue.current?.expandAll();
    DolCompetenciesRef.current?.expandAll();
    RoleSpecificActivitiesRef.current?.expandAll();
    setValuess([true, true]);
    setCollapse("")
  };
  //role permission
  const filteredactions = rolespermission?.filter(
    (obj) => obj.Action_name === "Edit Job Role"
  );
  console.log("testingssss",filteredactions)
  const learner_filteredactions = rolespermission?.filter(
    (obj) => obj.Action_name === "Learner Sign-Off"
  );
  const technical_filteredactions = rolespermission?.filter(
    (obj) => obj.Action_name === "Technical Sign-Off"
  );
  const manager_filteredactions = rolespermission?.filter(
    (obj) => obj.Action_name === "Final Sign-Off"
  );
  const radioOverrides = {
    Root:{
      style: ({$theme}) => ({
        border : '1px solid rgb(224, 224, 224)',
        borderRadius : '8px',
        paddingLeft :"8px",
        width:"188px",
        [$theme.mediaQuery.small]: {
          width: "188px",
        },
        [$theme.mediaQuery.medium]: {
          width:"184px",
        },
    })},
    RadioMarkOuter: {
      style: ({$theme}) => ({
        backgroundColor: "#495EEB",
        position:"relative",
        bottom : "14px"
      }),
    },
  };
  const radioOverrides_all = {
    Root:{
      style: ({$theme}) => ({
        border : '1px solid rgb(224, 224, 224)',
        borderRadius : '8px',
        paddingLeft :"8px",
        width:"173px",
        [$theme.mediaQuery.small]: {
          width: "173px",
        },
        [$theme.mediaQuery.medium]: {
          width: "143px",
        },
       
    })},
    RadioMarkOuter: {
      style: ({$theme}) => ({
        backgroundColor: "#495EEB",
        position:"relative",
        bottom : "14px"
      }),
    },
  }

  const valueSwitch  = (val) => {
    setValues(val);
    if(val === "1"){
      toggleAll();
    }
    if(val === "2"){
      handleClicks(0,true)
    }
    else if(val === "3"){
     handleClicks(1,true)
    }
  }

  const totalCount = (rcount,lcount,tcount,mcount,branch) =>{
    console.log(branch,"-mcount:",mcount)
   if (branch === "core") {
        dolrit = rcount;
        dollearners= lcount;
        doltechnical= tcount;
        dolmanager=mcount;
    } else {
      roleslearners = lcount;
      rolestechnical = tcount;
      rolesmanager = mcount;
    }
    setDolRTI(dolrit)
    setLearnerCount(dollearners+roleslearners)
    setTechnicalCount(doltechnical + rolestechnical)
    setManagerCount(dolmanager+rolesmanager)
  }

  const BulkClick = (event,email) =>{
    event.stopPropagation();
    event.preventDefault();
    if(corecount.length >=1 || rolescount.length >= 1){
      setIsOpen(true); 
      setLEmail(email)
    }
  }

  const BulkSignoff = (event,learnerEmail) => {
    event.stopPropagation();
    event.preventDefault();
    var arr = concat(corecount,rolescount)
        const payload = {
          SOList: arr,
        };
        if(arr.length >= 1) {
          var userEmail=localStorage.getItem("email")
          var userName=localStorage.getItem("username")
          //setBulkItemsRoleCount(rolescount.length)
          //setBulkItemsCoreCount(corecount.length)
          bulkSignoff(payload, userEmail,learnerEmail,userName)
            .then(function (response) {
              if (response.status === 200) {
                //bulkContent(corecount.length,rolescount.length)
                setIsOpen(false);
                setAssignJobLoader(false);
                allLearnerData(learnerEmail);
                changeDolData(learnerEmail, selectedIndex);
              }
            })
            .catch((err) => {
              setAssignJobLoader(false);
              console.log(err);
            });
        } 
  }

  // function bulkContent () {
  //   let cnt = (corecount.length >= 1 && rolescount.length >= 1) ? "and" : ''
  //   return 'Bulk Signoff will complete ' + (corecount.length > 1 ? corecount.length + ' core competencies ' : corecount.length === 1 ?  corecount.length + ' core competency ' : '') + cnt + '  ' + (rolescount.length > 1 ? rolescount.length + ' role specific activities' : rolescount.length === 1 ? rolescount.length + ' role specific activity' : '');
  // }
  
  return (
    <Block>
      <Block
        display="flex"
        flexWrap="wrap"
        gridGap="10px"
        alignItems="stretch"
        marginBottom="10px"
      >
        <Block
          display="flex"
          alignItems="center"
          flexDirection="row"
          gridGap="16px"
          minWidth="250px"
          className={css({ ...BLOCK_STYLE_NEW(theme) })}
        >
          <Block
            as="img"
            src={Data?.ProfileImage || DefaultAvatar}
            alt="User Avatar"
            className={css(AVATAR_STYLE)}
            data-testid="learner-profile-image"
          />
          {loader ? (
            <Skeleton oval={false} width="100px" rows={2} />
          ) : (
            <Block overflow="hidden">
              <Block
                className={css(NAME_STYLE(theme))}
                data-testid="learner-profile-name"
              >
                {Data ? Data?.LastName + " " + Data?.FirstName : localStorage.getItem("username")}
              </Block>
              <Block
                className={css(EMAIL_STYLE)}
                data-testid="learner-profile-email"
              >
                {Data?.EmailId}
              </Block>
              <Block display={"flex"} alignItems="baseline">
                <Block
                  className={css(ITEM_TITLE_STYLE(theme))}
                  data-testid="role_title"
                >
                  <b>
                    {" "}
                    {Basic_info_role}
                    {": "}
                  </b>
                </Block>
                {Data?.RoleName ? (
                  // <Block display="flex" gridGap="16px" alignItems="center">
                  <Block
                    className={css(ROLE_NAME_CONTENT_STYLE(theme))}
                    paddingLeft="6px"
                    // marginTop="8px"
                    // marginBottom="8px"
                    data-testid="role-name"
                    width="100%"
                  >
                    {Data?.RoleName?.length > 15
                      ? 
                      <StatefulPopover
                      content={() => (
                        <Block padding={"5px"} style={{fontSize:'10px'}}>
                         {Data?.RoleName}
                        </Block>
                      )}
                      triggerType={TRIGGER_TYPE.hover}
                      accessibilityType={'tooltip'}
                      returnFocus
                      autoFocus
                      placement={PLACEMENT.bottom}
                    >
                      {Data?.RoleName.substring(0, 15) + "..."}
                       </StatefulPopover>
                      : Data?.RoleName}
                  </Block>
                ) : (
                  // </Block>
                  <Block
                    display={"flex"}
                    gridGap="12px"
                    alignItems="center"
                    marginLeft="10px"
                  >
                    <Block
                      as="img"
                      src={NoAssignRolesAvatar}
                      alt="no assigned Avatar"
                      className={css(NO_ASSIGN_AVATAR_STYLE)}
                    />
                    <Block
                      className={css(MISSING_TEXT_STYLE(theme))}
                      width="100%"
                      data-testid="missing-text"
                    >
                      {MISSING}
                    </Block>
                  </Block>
                )}
                {Data?.RoleName &&
                  filteredactions &&
                  filteredactions[0]?.Edit === "Y" && (
                    <Block
                      className={css(EDIT_TEXT_STYLE(theme))}
                      data-testid="role_edit"
                      onClick={() => openModal(Data)}
                      paddingLeft="4px"
                      width="30px"
                    >
                      {EDIT}
                    </Block>
                  )}
              </Block>
            </Block>
          )}
        </Block>

        <RadioGroup
      value={values}
      onChange={e => valueSwitch(e.currentTarget.value)}
      name="number"
      align={ALIGN.horizontal}
    >
      <Radio overrides={radioOverrides_all} value="1">    
        <Block className={css({ ...BLOCK_STYLE_MAIN_ALL(theme) })} data-testid="dol-active" onClick={() => setActiveKey("0")}>
          <Block
            className={css(ITEM_TITLE_STYLE_SUB(theme))}
            data-testid="progress_title"
            width="80%"
          >
            {"All"}
          </Block>
          <Block display="flex" gridGap="16px" alignItems="center" width="80%">
            <Block
              className={css(ITEM_CONTENT_STYLE_NEW(theme))}
              data-testid="learner-profile-progress"
            >
              {Data?.LearnerChecklist?.Progress
                ? Data?.LearnerChecklist?.Progress
                : 0}
              %
            </Block>
            <Block width="85px">
              <ProgressBar
                value={Data?.LearnerChecklist?.Progress}
                targetValue={0}
              />
            </Block>
          </Block>
        </Block>
        </Radio>
    <Radio overrides={radioOverrides} value="2">    
        <Block className={css({ ...BLOCK_STYLE_MAIN(theme) })} data-testid="dol-active" onClick={() => setActiveKey("0")}>
          <Block
            className={css(ITEM_TITLE_STYLE_SUB(theme))}
            data-testid="progress_title"
          >
            {"Core Competencies"}
          </Block>
          <Block display="flex" gridGap="16px" alignItems="center">
            <Block
              className={css(ITEM_CONTENT_STYLE_NEW(theme))}
              data-testid="learner-profile-progress"
            >
              {Data?.LearnerChecklist?.CompetencyProgress
                ? Data?.LearnerChecklist?.CompetencyProgress
                : 0}
              %
            </Block>
            <Block width="85px">
              <ProgressBar
                value={Data?.LearnerChecklist?.CompetencyProgress}
                targetValue={0}
              />
            </Block>
          </Block>
        </Block>
        </Radio>  
        <Radio overrides={radioOverrides} value="3">
        <Block className={css({ ...BLOCK_STYLE_MAIN(theme) })}  data-testid="role-specific-active"
                      onClick={() => setActiveKey("1")}>
          <Block
            className={css(ITEM_TITLE_STYLE_SUB(theme))}
            data-testid="progress_title"
          >
            {"Job-Specific Activities"}
          </Block>
          <Block display="flex" gridGap="16px" alignItems="center">
            <Block
              className={css(ITEM_CONTENT_STYLE_NEW(theme))}
              data-testid="learner-profile-progress"
              fontSize="16px"
            >
              {Data?.LearnerChecklist?.RoleSpecificProgress
                ? Data?.LearnerChecklist?.RoleSpecificProgress
                : 0}
              %
            </Block>
            <Block width="85px">
              <ProgressBar
                value={Data?.LearnerChecklist?.RoleSpecificProgress}
                targetValue={0}
              />
            </Block>
          </Block>
        </Block>
        </Radio>
        </RadioGroup>
        </Block>
        <Block className={css({ ...SORTBY_BLOCK_STYLE(theme) })} width="100%" marginBottom="15px">
          <RadioGroup
            value={value}
            onChange={(e) => setValue(e.currentTarget.value)}
            align={ALIGN.horizontal}
          >
            <Radio value="All competencies">Show all</Radio>
            <Radio value="Only competencies that are ready to sign off by">
            Filter - Ready to Signoff:
            </Radio>
          </RadioGroup>
          <Block display="flex" gridGap="10px" alignItems="center">
                <Block
                  className={css(ITEM_CONTENT_STYLE(theme))}
                  data-testid="sort-by-manager"
                >
                  <Checkbox
                    checked={rtiCheckboxValue}
                    data-testid="manager-checkbox"
                    disabled={
                      value !==
                      "Only competencies that are ready to sign off by"
                        ? true
                        : false
                    }
                    onChange={(e) => {setRTICheckboxValue(e.target.checked);}}
                    labelPlacement={LABEL_PLACEMENT.right}
                  >
                    RTI {'('}{dolrits}{')'}
                  </Checkbox>
                </Block>
            {/* {learner_filteredactions &&
              learner_filteredactions[0]?.Edit === "Y" && ( */}
                <Block
                  className={css(ITEM_CONTENT_STYLE(theme))}
                  data-testid="sort-by-learner"
                >
                  <Checkbox
                    checked={learnerCheckboxValue}
                    data-testid="learner-checkbox"
                    disabled={
                      value !==
                      "Only competencies that are ready to sign off by"
                        ? true
                        : false
                    }
                    onChange={(e) => {setLearnerCheckboxValue(e.target.checked);}}
                    labelPlacement={LABEL_PLACEMENT.right}
                  >
                    Learner {'('}{learnerscount}{')'}
                  </Checkbox>
                </Block>
              {/* )} */}
            {/* {technical_filteredactions &&
              technical_filteredactions[0]?.Edit === "Y" && ( */}
                <Block
                  className={css(ITEM_CONTENT_STYLE(theme))}
                  data-testid="sort-by-technical"
                >
                  <Checkbox
                    checked={technicalCheckboxValue}
                    data-testid="technical-checkbox"
                    disabled={
                      value !==
                      "Only competencies that are ready to sign off by"
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      {setTechnicalCheckboxValue(e.target.checked);}
                    }
                    labelPlacement={LABEL_PLACEMENT.right}
                  >
                    Mentor {'('}{technicalscount}{')'}
                  </Checkbox>
                </Block>
              {/* )} */}
            {/* {manager_filteredactions &&
              manager_filteredactions[0]?.Edit === "Y" && ( */}
                <Block
                  className={css(ITEM_CONTENT_STYLE(theme))}
                  data-testid="sort-by-manager"
                >
                  <Checkbox
                    checked={managerCheckboxVlue}
                    data-testid="manager-checkbox"
                    disabled={
                      value !==
                      "Only competencies that are ready to sign off by"
                        ? true
                        : false
                    }
                    onChange={(e) => setManagerCheckboxVlue(e.target.checked)}
                    labelPlacement={LABEL_PLACEMENT.right}
                  >
                    Manager {'('}{managerscount}{')'}
                  </Checkbox>
                </Block>
              {/* )} */}
          </Block>
        </Block>
      
      {!Data?.RoleName && <Block className={css(SEPRATOR)} />}
      {Data?.RoleName ? (
        <Block>
          <Block
            display={"flex"}
            justifyContent="space-between"
            alignItems={"baseline"}
          >
            <Block display="flex" flexDirection="column" width={"100%"}>
              <Block className={css(STICKY_SECTION(theme))}>
                <Block
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  flexWrap="wrap"
                  gridGap="16px"
                >
                  <Block
                    display="flex"
                    alignItems="center"
                    gridGap="16px"
                    flexWrap="wrap"
                    data-testid="filter-item"
                  >
                    
                    { value ==="Only competencies that are ready to sign off by" && managerCheckboxVlue && !technicalCheckboxValue && !learnerCheckboxValue && !rtiCheckboxValue && localStorage.getItem('viewAs') !== "LEARNER" && localStorage.getItem('viewAs') !== "MENTOR" && (localStorage.getItem('role') === "TENANT OWNER" || localStorage.getItem('role') === "MANAGER" || localStorage.getItem('role') === "ADMIN") ?  
                    // <Block display="flex"  alignItems="center"
                    // gridGap="16px"
                    // flexWrap="wrap"
                    // data-testid="filter-item">
                    // <Checkbox
                    //   checked={checked}
                    //   data-testid="all-select-checkbox"
                    //   onChange={e => {setChecked(e.target.checked);if(e.target.checked){setselectAllval("Yes")}else{setselectAllval("No")} }}
                    //   labelPlacement={LABEL_PLACEMENT.right}
                    //   disabled = {managerCheckboxVlue && !technicalCheckboxValue && !learnerCheckboxValue && !rtiCheckboxValue && (localStorage.getItem('role') === "TENANT OWNER" || localStorage.getItem('role') === "MANAGER" || localStorage.getItem('role') === "ADMIN") ? false : true}
                    // />
                    <Button
                      onClick={(event) => BulkClick(event,Data?.EmailId)}
                      data-testid="title-new-template"
                      overrides={{
                        BaseButton: { style: BUTTON_OVERRIDE_STYLE },
                      }}
                      disabled={managerCheckboxVlue && !technicalCheckboxValue && !learnerCheckboxValue && !rtiCheckboxValue && (localStorage.getItem('role') === "TENANT OWNER" || localStorage.getItem('role') === "MANAGER" || localStorage.getItem('role') === "ADMIN") ? false : true}
                    >
                      {"Bulk sign off"}{" ("}{mainCount}{')'}
                    </Button> 
                    // </Block>
                     : null}
                    <Input
                      value={searchString}
                      onChange={(e) => searchKeywords(e)}
                      placeholder={"Enter keywords"}
                      clearOnEscape
                      name="search_keywords"
                      data-testid="search_text_input"
                      overrides={{
                        Input: { style: INPUT_OVERRIDE_STYLE.Input },
                        Root: { style: INPUT_OVERRIDE_STYLE.Root },
                        StartEnhancer: {
                          style: INPUT_OVERRIDE_STYLE.StartEnhancer,
                        },
                      }}
                      startEnhancer={
                        <SearchIcon size={24} color={theme.RAColors?.gray900} />
                      }
                    />
                  </Block>
                  <SearchRender expand={expandAll} collapse={collapseAll} />
                </Block>
              </Block>
               <div>
      <ul
        className={css({
          marginBlockStart: "unset",
          marginBlockEnd: "unset",
          paddingInlineStart: "unset",
        })}
      >
        <Panel
          onClick={() => handleClicks(0,false)}
          title="Core Competencies"
          expanded={valuess[0]}
          overrides={{
            Header: {
              style: ({ $theme }) => ({
                backgroundColor: '#F3F3F3',
                "box-shadow":"0px 1px 0px rgba(0, 0, 0, 0.25)",
              })
            }
          }}
        >
           <Block>
                    <DolCompetencies
                      ref={DolCompetenciesRef}
                      Data={Data}
                      dolDetails={dolDetails}
                      getAvailableTasks={getAvailableTasks}
                      changeDolData={changeDolData}
                      selectedIndex={selectedIndex}
                      rolespermission={rolespermission}
                      handleClick={handleClick}
                      learnerCheckboxValue={learnerCheckboxValue}
                      technicalCheckboxValue={technicalCheckboxValue}
                      managerCheckboxVlue={managerCheckboxVlue}
                      rtiCheckboxValue={rtiCheckboxValue}
                      selectedValue={value}
                      openVerifyCompetencyModal={openVerifyCompetencyModal}
                      loader={loader}
                      bulkItems = {BulkItems}
                      collapse={collpse}
                    />
                  </Block>
        </Panel>
        <Panel
          onClick={() => handleClicks(1,false)}
          title="Job-Specific Activities"
          expanded={valuess[1]}
          overrides={{
            Header: {
              style: ({ $theme }) => ({
               // outline: `${$theme.colors.warning200} solid`,
                backgroundColor: '#F3F3F3',
                "box-shadow":"0px 1px 0px rgba(0, 0, 0, 0.25)",
                // borderBottom:"1px solid #E0E0E0"
              })
            }
          }}
        >
         <Block>
                    <RoleSpecificActivities
                      ref={RoleSpecificActivitiesRef}
                        Data={Data}
                        dolDetails={dolDetails}
                        getAvailableTasks={getAvailableTasks}
                        loader={loader}
                        changeDolData={changeDolData}
                        allLearnerData={allLearnerData}
                        selectedIndex={selectedIndex}
                        rolespermission={rolespermission}
                        handleClick={handleClick}
                        learnerCheckboxValue={learnerCheckboxValue}
                        technicalCheckboxValue={technicalCheckboxValue}
                        managerCheckboxVlue={managerCheckboxVlue}
                        rtiCheckboxValue={rtiCheckboxValue}
                        selectedValue={value}
                        openVerifyCompetencyModal={openVerifyCompetencyModal}
                        bulkItems = {BulkItems}
                        collapse={collpse}
                      />
                    </Block>
          </Panel>
        </ul>
      </div>
            </Block>
          </Block>
        </Block>
      ) : (
        <>
          {filteredactions && filteredactions[0]?.Edit === "Y" && (
            <Block
              height={"600px"}
              display="flex"
              justifyContent={"center"}
              alignItems="center"
              flexDirection={"column"}
            >
              <Block className={css(LEARNERS_MESSAGE_STYLE(theme))}>
                {"There’s no role assigned to this learner"}
              </Block>
              <Block width={"328px"} marginTop="20px" marginBottom="30px">
                <Block as="h5" className={css(LABEL_STYLE(theme))}>
                  {"Assign a job role"}
                </Block>
                <Select
                  clearable={false}
                  searchable={false}
                  options={sortAZSelectOptions(jobRoleOptions)}
                  placeholder="Select a job role"
                  value={jobRole}
                  maxDropdownHeight="300px"
                  onChange={(params) => setJobRole(params.value)}
                  overrides={{
                    Root: { style: SELECT_OVERRIDE_STYLE.Root },
                    ControlContainer: {
                      style: SELECT_OVERRIDE_STYLE.ControlContainer,
                    },
                  }}
                />
              </Block>
              <Block display="flex" alignItems="center">
                <Button
                  type="submit"
                  overrides={{ BaseButton: { style: SAVE_BTN_STYLE(theme) } }}
                  data-testid="save_text"
                  onClick={(event) => saveJob(event, Data?.EmailId)}
                >
                  {"Assign"}
                </Button>
              </Block>
            </Block>
          )}
        </>
      )}
      <Suspense fallback={<div />}>
        {switchRoleRow && (
          <ModalComponent
            row={switchRoleRow}
            changeDolData={changeDolData}
            selectedIndex={selectedIndex}
            close={() => closeModal()}
            parentCallback={handleCallback}
          />
        )}
      </Suspense>
      {assignJobLoader ? (
        <Loader
          type="bubble-loop"
          bgColor={"#332ff0"}
          color={"#332ff0"}
          size={100}
        />
      ) : null}
      {/* <React.Fragment> */}
      {/* <Button onClick={() => setIsOpen(true)}>Open Modal</Button> */}
      <Modal onClose={close} isOpen={isOpen}>
      <ModalHeader>Confirm Bulk Signoff</ModalHeader>
        <ModalBody style={{padding:"0px 0 0", marginBottom :"10px"}}>
        {corecount.length >= 1 ? <Block marginBottom={'10px'} marginTop={'5px'}>{'Bulk Signoff will complete ' + (corecount.length > 1 ? corecount.length + ' core competencies ' : corecount.length === 1 ?  corecount.length + ' core competency ' : '')}</Block> : null}
        {rolescount.length >= 1 ?  <Block marginBottom={'10px'} marginTop={'5px'}>{'Bulk Signoff will complete ' + (rolescount.length > 1 ? rolescount.length + ' role specific activities' : rolescount.length === 1 ? rolescount.length + ' role specific activity' : '')}</Block> : null}
        </ModalBody>
        <ModalFooter style={{padding:"0 0 10px", marginTop:"0"}}>
          <ModalButton style={{background: 'rgb(58, 63, 223)', color:"#fff", borderRadius : "8px"}} onClick={(event) => { BulkSignoff(event,lemail)}}>
            Confirm
          </ModalButton>
          <ModalButton onClick={close} style={{background: 'rgb(58, 63, 223)', color:"#fff", borderRadius : "8px"}}>Cancel</ModalButton>
        </ModalFooter>
      </Modal>
    {/* </React.Fragment> */}
    </Block>
  );
};
export default Summary;
