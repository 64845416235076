
export default function getRolesPermission(loginRole, currentPage) {
  const RolesPermissionData = JSON.parse(localStorage.getItem("rolesPermission"));
  var matchedObj = RolesPermissionData?.filter(function (el) {
    return el?.role === loginRole;
  });
  const filteredrolespermission = matchedObj && matchedObj[0]?.data?.filter(
    (obj) => obj?.page === currentPage
  );
  return filteredrolespermission;
}
