import axios from "axios";
import { studentURL } from "../config";

const LinkforceControllerJobRole = axios.create({
  baseURL: studentURL
})

LinkforceControllerJobRole.defaults.headers.common = {
  ...(LinkforceControllerJobRole.defaults.headers.common || {}),
  ApiKey: 'TESTDEV-0000-1111-2222-333333333333'
}

export const fetchJobRoles = (email,status) => LinkforceControllerJobRole.get(`/jobrole?user_email=${email}&role_status=${status}`)
export const createJobRole = (payload, email) => LinkforceControllerJobRole.post(`/jobrole?user_email=${email}`, payload)
export const updateJobRole = (payload, email) => LinkforceControllerJobRole.put(`/jobrole?user_email=${email}`, payload)
export const deleteJobRole = (payload, email) => LinkforceControllerJobRole.delete(`/jobrole?user_email=${email}`, {
  data: payload
})

export const fetchJobGroups = (email) => LinkforceControllerJobRole.get(`/jobgroup?user_email=${email}`)
export const deleteJobGroups = (payload,email) => LinkforceControllerJobRole.delete(`/jobgroup?user_email=${email}`, {
  data: payload
})