import React, { useMemo, useState } from "react";
import { Header } from "components/Header";
import DrawerRouterContainer from "components/newDrawer";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import Title from "./Components/title";
import { useSetState, useTrackedState } from "store/store";
import { useEffect } from "react";
import getRolesPageVisibility from "../../components/getPageVisibility";
import { Redirect } from "react-router-dom";
import { Select } from "baseui/select";
import { Input } from "baseui/input";
import { FormControl } from "baseui/form-control";
import { Alert } from "baseui/icon";
import { Button } from "baseui/button";
import { FileUploader } from "baseui/file-uploader";
import userData from "services/requests/userData";
import thmScoreboard from "services/requests/thmScoreboard";
import {Notification, KIND} from 'baseui/notification';
import DeleteAlt from "baseui/icon/delete-alt";
import fetchCohortData from "services/requests/cohort_list";
//$FlowFixMe
import { validate as validateEmail } from "email-validator";
import {
  COMMON_RADIUS,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_COLOR,
  COMMON_BORDER_STYLE,
} from "utils/style";

const MAIN_STYLE = (theme) => ({
  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "40px",
  },
});

const BLOCK_STYLE = (theme) => ({
  backgroundColor: "white",
  borderRadius: "8px",
  padding: "24px",
  border: `1px solid ${theme.RAColors?.gray200}`,
  // width: '100%',
  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "24px",
  },
});
const BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  //borderColor: $theme.RAColors?.purple,
  paddingTop: "12px",
  paddingBottom: "12px",
  paddingRight: "24px",
  paddingLeft: "24px",
  ...COMMON_BORDER_COLOR($theme.RAColors?.blue700),
  backgroundColor: $theme.RAColors?.blue700,
  ":hover": { backgroundColor: $theme.RAColors?.blue800 },
  color: "white",

  [$theme.mediaQuery.small]: {
    width: "100%",
  },
  [$theme.mediaQuery.medium]: {
    width: "initial",
  },
});

const SUMMARY_DROPDOWN = (theme) => ({
  display: "flex",
  width: "100%",
});

const SAVE_BTN_STYLE = ($theme) => ({
  borderRadius: "10px",
  paddingTop: "14px",
  paddingBottom: "14px",
  paddingLeft: "16px",
  paddingRight: "16px",
  backgroundColor: "#141414",
  borderColor: "#332FF1",
  color: "#FFFFFF",
  ":hover": { backgroundColor: "#141414" },
});

const AdminData = ({ match }) => {
  const [css, theme] = useStyletron();
  const setState = useSetState();
  const state = useTrackedState();
  const [firstname, setFirstName] = React.useState();
  const [lastname, setLastName] = React.useState();
  const [value, setValue] = React.useState("");
  const [username, setUserName] = React.useState("");
  const [cohortname,setCohortName] = React.useState("");
  const [thmusername, setThmUserName] = React.useState("");
  const [wordData, setWordData] = React.useState(false);
  const [cohortValue, setCohortValue] = React.useState([]);
  const [studentData, setStudentData] = React.useState([]);
  const [studentvalue, setStudentValue] = React.useState([]);
  const [thmuservalue, setTHMUserValue] = React.useState([]);

  const [imageFile, setImageFile] = React.useState(null);
  const [filename, setFileName] = React.useState([]);
  const [isValid, setIsValid] = React.useState(false);
  const [isVisited, setIsVisited] = React.useState(false);
  const shouldShowError = !isValid && isVisited;
  const fileRef = React.useRef(null);
  const [userSuccess, setUserSuccess] = React.useState(false);
  const [fileSuccess, setFileSuccess] = React.useState(false);
  const onChange = ({ target: { value } }) => {
    setIsValid(validateEmail(value));
    setValue(value);
  };
  const [isUploading, setIsUploading] = React.useState(false);
  const timeoutId = React.useRef();
  const [error, setError] = React.useState(false);
  useEffect(() => {
    // localStorage.setItem("currentPage", "admindata");
    localStorage.setItem("currentPage", "student");
    getCohortdata();
  }, []);

  //page access control - based on role
  // var role = localStorage.getItem("viewAs") !== null ? localStorage.getItem("viewAs") : localStorage.getItem("role");
  // var permittedRoles = getRolesPageVisibility("Admin Data");
  // if (!permittedRoles.includes(role)) return <Redirect to="/student" />;

  function Negative() {
    const [css, theme] = useStyletron();
    return (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          paddingRight: theme.sizing.scale500,
          color: theme.colors.negative400,
        })}
      >
        <Alert size="18px" />
      </div>
    );
  }
  function reset() {
    setIsUploading(false);
    clearTimeout(timeoutId.current);
  }
  function startProgress() {
    setIsUploading(true);
    timeoutId.current = setTimeout(reset, 4000);
  }

  function getCohortdata() {
    fetchCohortData()
      .then(function (response) {
        if (response.status === 200) {
          setWordData(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function CohortCall(val) {
    setCohortValue(val);
    var newArray = wordData?.find(function (el) {
      if (el.cohort_id === val[0].cohort_id) {
        return el;
      }
    });
    setStudentData(newArray?.student_list);
  }

  function submitData() {
    let formData = new FormData();
    let payload = {};
    // payload.email = email;
    //let role = localStorage.getItem("role") === "LEARNER" ? "true" : "false";
    formData.append("email", studentvalue[0].student_email);
    formData.append("is_learner", "true");
    formData.append("username", username);
    formData.append("cohortname", cohortValue[0].cohort_name);
    formData.append("first_name", firstname);
    formData.append("last_name", lastname);
    formData.append("thm_username", thmuservalue);
    userData(formData, localStorage.getItem("email"))
      .then(function (response) {
        if (response.status === 200) {
          setUserSuccess(true);
          setUserName("");
          setValue("");
          setFirstName("");
          setLastName("");
          setCohortName("");
          setThmUserName("");
          setCohortValue([]);
          setStudentValue([]);
          setTHMUserValue("");
        } else {
          setError(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setUserSuccess(false);
        setError(true);
      });
  }

  function submitthmData() {
    let formData = new FormData();
    formData.append("file", imageFile[0]);
    thmScoreboard(formData, localStorage.getItem("email"))
      .then(function (response) {
        if (response.status === 200) {
          setFileSuccess(true);
          setImageFile(null);
        } else {
          setError(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setFileSuccess(false);
      });
  }
  const role =
    localStorage.getItem("viewAs") !== null
      ? localStorage.getItem("viewAs")
      : localStorage.getItem("role");
  const userType = useMemo(() => {
    const username = localStorage.getItem("username");
    if ([null, undefined, ""].includes(username)) return null;
    //page access control - based on role
    var permittedRoles = ["ADMIN", "TENANT OWNER", "MENTOR"];
    console.log("permittedRoles", permittedRoles);
    if (permittedRoles.includes(role)) return "manager";

    return "member";
  }, []);
  if (!userType) return <Redirect to="/login" />;
  if (userType !== 'manager') return <Redirect to="/student" />;
  return (
    <>
      <Header />
      <DrawerRouterContainer>
        <React.Fragment>
          {userSuccess ? (
            <Notification
              overrides={{
                Body: {
                  style: ({ $theme }) => ({
                    position: "absolute",
                    top: "10%;",
                    right: "0",
                    background: "#69cf9c",
                    color: "#fff",
                  }),
                },
                CloseIcon: {
                  component: DeleteAlt,
                  style: { float: "right", cursor: "pointer" },
                },
              }}
              kind={KIND.positive}
              closeable
            >
              {"User added successfully."}
            </Notification>
          ) : null}
          {fileSuccess ? (
            <Notification
              overrides={{
                Body: {
                  style: ({ $theme }) => ({
                    position: "absolute",
                    top: "10%;",
                    right: "0",
                    background: "green",
                    color: "#fff",
                  }),
                },
                CloseIcon: {
                  component: DeleteAlt,
                  style: { float: "right", cursor: "pointer" },
                },
              }}
              kind={KIND.positive}
              closeable
            >
              {"THM roomscoreboard added successfully."}
            </Notification>
          ) : null}
          {error ? (
            <Notification
              overrides={{
                Body: {
                  style: ({ $theme }) => ({
                    position: "absolute",
                    top: "10%;",
                    right: "0",
                    background: "red",
                    color: "#fff",
                  }),
                },
                CloseIcon: {
                  component: DeleteAlt,
                  style: { float: "right", cursor: "pointer" },
                },
              }}
              kind={KIND.positive}
              closeable
            >
              {"Something went wrong!"}
            </Notification>
          ) : null}
        </React.Fragment>
        <main className={css(MAIN_STYLE(theme))}>
          <Title />
          <Block>{"User Data :"}</Block>
          <Block
            data-testid="summary"
            className={css(BLOCK_STYLE(theme))}
            display="flex"
            flexWrap="wrap"
            gridGap={["16px", "16px", "40px"]}
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
                submitData();
              }}
              style={{ width: "100%" }}
            >
              <Block style={{ display: "inline-flex", gap: 40, width: "100%" }}>
                <Block style={{ width: "30%" }}>
                  <FormControl
                    label={() => "First Name"}
                    overrides={{
                      ControlContainer: {
                        style: ({ $theme }) => ({
                          width: "100%",
                        }),
                      },
                    }}
                  >
                    <Input
                      value={firstname}
                      onChange={(e) => setFirstName(e.target.value)}
                      placeholder="Enter First Name"
                      clearOnEscape
                    />
                  </FormControl>
                </Block>
                <Block style={{ width: "30%" }}>
                  <FormControl
                    label={() => "Last Name"}
                    overrides={{
                      ControlContainer: {
                        style: ({ $theme }) => ({
                          width: "100%",
                        }),
                      },
                    }}
                  >
                    <Input
                      value={lastname}
                      onChange={(e) => setLastName(e.target.value)}
                      placeholder="Enter Last Name"
                      clearOnEscape
                    />
                  </FormControl>
                </Block>
                <Block style={{ width: "30%" }}>
                  <FormControl
                    label={() => "Cohort Name"}
                    overrides={{
                      ControlContainer: {
                        style: ({ $theme }) => ({
                          width: "100%",
                        }),
                      },
                    }}
                  >
                    <Select
                      options={wordData}
                      value={cohortValue}
                      labelKey="cohort_name"
                      valueKey="cohort_id"
                      placeholder="Select Cohort Name"
                      onChange={(params) => CohortCall(params.value)}
                      required
                      maxDropdownHeight="300px"
                    />
                  </FormControl>
                </Block>
              </Block>
              <Block style={{ display: "inline-flex", gap: 40, width: "100%" }}>
                <Block style={{ width: "30%" }}>
                  <FormControl
                    label={() => "Student Name"}
                    overrides={{
                      ControlContainer: {
                        style: ({ $theme }) => ({
                          width: "100%",
                        }),
                      },
                    }}
                  >
                    <Select
                      options={studentData}
                      value={studentvalue}
                      labelKey="student_email"
                      valueKey="student_email"
                      placeholder="Select Student Email"
                      onChange={(params) => setStudentValue(params.value)}
                      required
                      maxDropdownHeight="300px"
                    />
                  </FormControl>
                </Block>
                <Block style={{ width: "30%" }}>
                  <FormControl
                    label={() => "THM Username"}
                    overrides={{
                      ControlContainer: {
                        style: ({ $theme }) => ({
                          width: "100%",
                        }),
                      },
                    }}
                  >
                    <Input
                      value={thmuservalue}
                      onChange={(e) => setTHMUserValue(e.target.value)}
                      placeholder="Enter First Name"
                      clearOnEscape
                      required
                    />
                  </FormControl>
                </Block>
                <Block style={{ width: "30%" }}>
                  <FormControl
                    label={() => "UserName"}
                    overrides={{
                      ControlContainer: {
                        style: ({ $theme }) => ({
                          width: "100%",
                        }),
                      },
                    }}
                  >
                    <Input
                      value={username}
                      onChange={(e) => setUserName(e.target.value)}
                      placeholder="Enter UserName"
                      clearOnEscape
                    />
                  </FormControl>
                </Block>
              </Block>
              <Block>
                <Button
                  overrides={{
                    BaseButton: { style: BUTTON_OVERRIDE_STYLE },
                  }}
                >
                  Submit
                </Button>
              </Block>
            </form>
          </Block>
        </main>

        <main className={css(MAIN_STYLE(theme))}>
          <Block>{"THM Room Scoreboard :"}</Block>
          <Block
            data-testid="summary"
            className={css(BLOCK_STYLE(theme))}
            display="flex"
            flexWrap="wrap"
            gridGap={["16px", "16px", "40px"]}
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
                submitthmData();
              }}
              style={{ width: "100%" }}
            >
              <Block>
                <FormControl
                  label={() => "THM Room Scoreboard"}
                  overrides={{
                    ControlContainer: {
                      style: ({ $theme }) => ({
                        width: "50%",
                      }),
                    },
                    Label: {
                      style: ({ $theme }) => ({
                        marginBottom: "20px",
                      }),
                    },
                  }}
                >
                  <Block>
                    <FileUploader
                      ref={fileRef}
                      onCancel={reset}
                      onDrop={(acceptedFiles) => {
                        // handle file upload...
                        setImageFile(acceptedFiles);
                        setFileName(acceptedFiles[0]);
                      }}
                      progressMessage={
                        isUploading ? `Uploading... hang tight.` : ""
                      }
                      overrides={{
                        // ContentMessage: {
                        //   style: ({ $theme }) => ({
                        //     display: "none",
                        //     margin:0
                        //   })
                        // },
                        // FileDragAndDrop: {
                        //   style: ({ $theme }) => ({
                        //    padding:0,
                        //    margin:0
                        //   })
                        // },
                        ButtonComponent: {
                          props: {
                            overrides: {
                              // BaseButton: {
                              //   style: ({ $theme }) => ({
                              //     margin:0
                              //   })
                              // }
                            },
                          },
                        },
                      }}
                    />
                    {imageFile?.length > 0 ? (
                      <Block
                        style={{
                          padding: "10px 0 0 10px",
                          fontSize: "14px",
                          color: "green",
                          width: "100%",
                          "text-align": "center",
                        }}
                      >
                        {"1 file selected"}
                      </Block>
                    ) : null}
                  </Block>
                </FormControl>
              </Block>
              <Block>
                <Button
                  overrides={{
                    BaseButton: { style: BUTTON_OVERRIDE_STYLE },
                  }}
                >
                  Submit
                </Button>
              </Block>
            </form>
          </Block>
        </main>
      </DrawerRouterContainer>
    </>
  );
};

export default AdminData;
