import { Block } from "baseui/block";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  XYPlot,
  XAxis,
  YAxis,
  Hint,
  LineMarkSeries,
  DiscreteColorLegend,
  HorizontalGridLines,
  VerticalGridLines,
} from "react-vis";
import { useStyletron } from "baseui";
import {
  TILE_STYLE,
  TITLE_STYLE,
  HINT_STYLE,
  HINT_TEXT_STYLE,
  VISUALIZATION_HEIGHT,
  MOBILE_SCREEN_BREAKPOINT,
  SELECT_OVERRIDE_STYLE,
  stringToHex,
} from "../utils";
import { Learner_Kahoot } from "resources/constants/strings";
import { useTrackedState } from "store/store";
import { Skeleton } from "baseui/skeleton";
import { Select, SIZE } from "baseui/select";

const ALL_OPTION = { label: "All Learners", id: "all" };

const LearnerKahootLine = () => {
  const [value, setValue] = useState(false);
  const [width, setWidth] = useState();
  const [user, setUser] = useState([ALL_OPTION]);

  const [css, theme] = useStyletron();
  const parent = useRef();

  const state = useTrackedState();

  const height = VISUALIZATION_HEIGHT * 1.25;
  const isMobileScreen = width < MOBILE_SCREEN_BREAKPOINT;

  const emails = useMemo(() => {
    if (!state.kahootScore) return [];

    return state.kahootScore.reduce((acc, lesson) => {
      const emailsInLesson = lesson.userscores.map((i) => i.emailid);

      return [...new Set([...acc, ...emailsInLesson])];
    }, []);
  }, [state.kahootScore]);

  const data = useMemo(() => {
    if (!state.kahootScore) return [];

    return state.kahootScore.reduce((acc, lesson, index) => {
      const { userscores, lessonnameshort, ...rest } = lesson;
      const scoresObject = userscores.reduce((s, score) => {
        s[score.emailid] = score;
        return s;
      }, {});

      emails.forEach((email) => {
        if (!acc[email]) acc[email] = [];

        acc[email].push({
          x: lessonnameshort,
          y: scoresObject[email]?.score || 0,
          ...rest,
          email: email,
        });
      });

      return acc;
    }, {});
  }, [state.kahootScore, emails]);

  const userOptions = useMemo(() => {
    if (!emails || !emails.length) return [ALL_OPTION];

    const emailOptions = emails
      .sort()
      .map((email) => ({ label: email, id: email }));
    return [ALL_OPTION, ...emailOptions];
  }, [emails]);

  const legends = useMemo(
    () =>
      emails.map((email) => ({
        title: email,
        color: stringToHex(email.replace("@boeing.com", "")),
      })),
    [emails]
  );

  useEffect(() => {
    if (parent) setWidth(parent.current.clientWidth);
  }, []);

  return (
    <Block className={css(TILE_STYLE(theme))}>
      <Block
        display="flex"
        flexDirection={["column", "column", "row"]}
        gridGap="20px"
        justifyContent="space-between"
        marginBottom="20px"
      >
        <Block className={css({ ...TITLE_STYLE, marginBottom: 0 })} data-testid="kahoot-learners-text">
          {Learner_Kahoot}
        </Block>
        <Select
          size={SIZE.compact}
          clearable={false}
          searchable={false}
          data-testid="select-users"
          options={userOptions}
          value={user}
          onChange={(params) => setUser(params.value)}
          disabled={state.isFetchingKahoot}
          overrides={{
            Root: { style: { width: "300px", backgroundColor: "white" } },
            ControlContainer: {
              style: SELECT_OVERRIDE_STYLE.ControlContainer,
            },
          }}
        />
      </Block>
      <Block
        ref={parent}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gridGap="20px"
        className="line"
      >
        {state.isFetchingKahoot && width ? (
          <Skeleton width={`${width}px`} height={`${height}px`} />
        ) : (
          <>
            <XYPlot
              width={width || 0}
              height={height}
              xType="ordinal"
              margin={{ bottom: isMobileScreen ? 0 : 80 }}
            >
              <HorizontalGridLines />
              <VerticalGridLines />
              <XAxis
                tickLabelAngle={-90}
                style={{ text: { textAnchor: "end" } }}
              />
              <YAxis />
              {user[0].id === "all" &&
                Object.keys(data).map((email, index) => (
                  <LineMarkSeries
                    color={stringToHex(email.replace("@boeing.com", ""))}
                    key={index}
                    size={4}
                    data={data[email]}
                    onValueMouseOver={(v) => setValue(v)}
                    onValueMouseOut={() => setValue(false)}
                  />
                ))}
              {user[0].id !== "all" && data[user[0].id] && (
                <LineMarkSeries
                  color="rgb(0, 99, 255)"
                  size={4}
                  lineStyle={{ strokeWidth: "2px" }}
                  markStyle={{
                    stroke: "rgb(0, 99, 255, 0.7)",
                    fill: "rgb(0, 99, 255, 0.7)",
                  }}
                  data={data[user[0].id]}
                  onValueMouseOver={(v) => setValue(v)}
                  onValueMouseOut={() => setValue(false)}
                />
              )}
              {value !== false && (
                <Hint value={value}>
                  <Block className={css(HINT_STYLE)}>
                    <Block className={css(HINT_TEXT_STYLE)}>
                      Learner: {value.email}
                    </Block>
                    <Block className={css(HINT_TEXT_STYLE)}>
                      Lesson: {value.lessonname}
                    </Block>
                    <Block className={css(HINT_TEXT_STYLE)}>
                      Course: {value.coursename}
                    </Block>
                    <Block className={css(HINT_TEXT_STYLE)}>
                      Quiz: {value.quizname}
                    </Block>
                    <Block className={css(HINT_TEXT_STYLE)}>
                      Subject: {value.quizsubject}
                    </Block>
                    <Block className={css(HINT_TEXT_STYLE)}>
                      Score: {value.y}
                    </Block>
                  </Block>
                </Hint>
              )}
            </XYPlot>
            {user[0].id === "all" && !isMobileScreen && (
              <DiscreteColorLegend orientation="horizontal" items={legends} />
            )}
          </>
        )}
      </Block>
    </Block>
  );
};

export default LearnerKahootLine;
