import { StatefulPopover, PLACEMENT } from "baseui/popover";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Modal, ModalBody, ModalFooter, SIZE, ROLE } from "baseui/modal";
import { Button } from "baseui/button";
import { useState } from "react";
import {
  COMMON_BORDER_COLOR,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_STYLE,
  COMMON_RADIUS,
} from "utils/style";

const ITEM = {
  cursor: "pointer",
  paddingLeft: "13px",
  paddingRight: "13px",
  paddingTop: "13px",
  paddingBottom: "13px",
  fontSize:"15px",
  fontFamily:"Manrope-Medium",
  ":hover": {
    backgroundColor: "#F3F3F3",
  },
  ":last-child": {
    borderTop :"2px solid rgb(224, 224, 224)"
  }
};

const COMMON_BUTTON_STYLE = {
  lineHeight: "22px",
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  fontFamily: "Manrope-Semibold",
  width: "122px",
};

const SAVE_BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_BUTTON_STYLE,
  ...COMMON_BORDER_COLOR($theme.RAColors?.red700),
  backgroundColor: $theme.RAColors?.red700,
  ":hover": { backgroundColor: $theme.RAColors?.red700 },
  color: "white",
  ":focus": { "box-shadow": "none" },
});

const CANCEL_BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_BUTTON_STYLE,
  ...COMMON_BORDER_COLOR($theme.RAColors?.purple),
  backgroundColor: "white",
  ":hover": { backgroundColor: $theme.RAColors?.gray300 },
  color: $theme.RAColors?.purple,
});

const MODAL_OVERRIDE_STYLE = {
  Dialog: { ...COMMON_RADIUS("8px") },
  Close: {
    ":focus": { outline: "none" },
  },
  DialogContainer: {
    maxHeight: "100vh",
  },
};



const Actions = ({ children, onDelete, isDelete = true , onDuplicate = null, isDuplicate = false, onPublish = null}) => {
  const [css] = useStyletron();
  const [showPopup, setShowPopup] = useState(false);
  const confirm = () => {
    if (isDelete === true) {
      setShowPopup(false);
      onDelete && onDelete();
    }
  };

const onCreate = () => {
  onDuplicate && onDuplicate();
};
  
const onPublishRole = () =>{
  onPublish && onPublish();
}
  return (
    <>
      <StatefulPopover
        content={({ close }) => (
          <Block backgroundColor="white">
            
            {onDuplicate !== null  ?
            <Block
              className={css(ITEM)}
              onClick={onCreate}
              //disabled={!state.jobRoles}
              data-testid="job-duplicate"
            >
              Duplicate
            </Block> : null}
            {onPublish !== null && !isDuplicate ?
            <Block
              className={css(ITEM)}
              onClick={onPublishRole}
              //disabled={!state.jobRoles}
              data-testid="job-publish"
            >
              Publish
            </Block> : null}
            <Block
              className={css(ITEM)}
              onClick={() => {
                setShowPopup(true);
                close();
              }}
              data-testid="job-delete"
            >
              Delete
            </Block>
          </Block>
        )}
        placement={PLACEMENT.bottomRight}
        autoFocus
      >
        {children}
      </StatefulPopover>
      <Modal
        onClose={() => setShowPopup(false)}
        closeable
        isOpen={showPopup}
        animate
        size={SIZE.default}
        role={ROLE.dialog}
        overrides={{
          Dialog: { style: MODAL_OVERRIDE_STYLE.Dialog },
          Close: { style: MODAL_OVERRIDE_STYLE.Close },
          DialogContainer: { style: MODAL_OVERRIDE_STYLE.DialogContainer },
        }}
      >
        <ModalBody className={css({ margin: "24px !important" })}>
          <Block className={css({ fontFamily: "Manrope" })}>
            {isDelete
              ? "Are you sure you want to delete this template?"
              : "This Job Group can't be deleted as it is associated with one or more job roles."}
          </Block>
        </ModalBody>
        {isDelete && (
          <ModalFooter>
            <Block display="flex" justifyContent="flex-end" gridGap="16px">
              <Button
                overrides={{
                  BaseButton: { style: SAVE_BUTTON_OVERRIDE_STYLE },
                }}
                onClick={confirm}
                data-testid="job-delete-confirm"
              >
                Delete
              </Button>
              <Button
                overrides={{
                  BaseButton: { style: CANCEL_BUTTON_OVERRIDE_STYLE },
                }}
                onClick={() => setShowPopup(false)}
                data-testid="job-cancel-btn"
              >
                Cancel
              </Button>
            </Block>
          </ModalFooter>
        )}
      </Modal>
    </>
  );
};

export default Actions;
