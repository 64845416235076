export const TILE_STYLE = (theme) => ({
  backgroundColor: "white",
  borderRadius: "8px",
  padding: "24px",
  border: `1px solid ${theme.RAColors?.gray200}`,

  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "24px",
  },
});

export const TITLE_STYLE = {
  fontFamily: "Manrope-Bold",
  fontSize: "24px",
  lineHeight: "33px",
};

export const HINT_STYLE = {
  borderRadius: "4px",
  backgroundColor: "rgba(0, 0, 0, 0.75)",
  padding: "8px",
};

export const HINT_TEXT_STYLE = {
  color: "white",
  fontSize: "12px",
  fontFamily: "Manrope-SemiBold",
}

export const getTickFormat = (label, maxLength = 20) => {
  if (label.length <= maxLength) return label;
  if(maxLength < 2) maxLength = 2;
  console.log(label)
  return label.slice(0, maxLength - 1).concat("..");
};

export const RANGE = 14 // number of recent days to display