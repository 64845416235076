import { JOB_GROUP } from "../apiEndpoint";
import { educationAxios } from "../http/http";
export default function jobGroupFunction(
  methodType = "",
  payload = "",
  email = ""
) {
  if (methodType === "Create") {
    
    return educationAxios.post(`${JOB_GROUP}?user_email=${email}`, payload);
  } else {
    return educationAxios.get(JOB_GROUP);
  }
}