import { ReactComponent as LearnerIcon } from 'assets/svg/students.svg'
import { ReactComponent as DashboardIcon } from 'assets/svg/dashboard.svg'
import { ReactComponent as BriefcaseIcon } from 'assets/svg/briefcase.svg'
import { ReactComponent as SignOffIcon } from 'assets/svg/signoff.svg'
import { ReactComponent as TemplateIcon } from 'assets/svg/template.svg'
import { ReactComponent as AdminIcon } from 'assets/svg/admin.svg'
import { ReactComponent as RolesIcon } from 'assets/svg/roles.svg'
import { ReactComponent as TrainingIcon } from 'assets/svg/job-training.svg'
import getRolesPageVisibility from "./getPageVisibility"

export default function getMenu(){
const item = [
  {
    text: "Admin Data",
    icon: DashboardIcon,
    id: 4,
    selected: false,
    route: "/admin-data",
    role: ["ADMIN", "TENANT OWNER"],
  },
  {
    text: "RA Admin",
    icon: DashboardIcon,
    id: 2,
    selected: false,
    route: "/ra-admin",
    role: getRolesPageVisibility("RA-Admin"),
  },
  {
    text: "Learners - Range",
    icon: LearnerIcon,
    id: 4,
    selected: false,
    route: "/student",
    role: getRolesPageVisibility("Learners"), //['ADMIN','MENTOR','TENANT OWNER','MANAGER']
  },
  {
    text: "Grade Addendums",
    icon: DashboardIcon,
    id: 4,
    selected: false,
    route: "/grade-add",
    role: getRolesPageVisibility("Grade Addendums"),
  },
  // {
  //   text: "Learners - ALXP",
  //   icon: LearnerIcon,
  //   id: 6,
  //   selected: false,
  //   route: "/learners",
  //   role: getRolesPageVisibility("Learners-AXLP")
  // },
  {
    text: "Competency Sign-off",
    icon: SignOffIcon,
    id: 6,
    selected: false,
    route: "/competency-sign-off",
    // role: getRolesPageVisibility("Competency Manager")//['ADMIN', 'TENANT OWNER']
    role: getRolesPageVisibility("Competency Sign-off"), //['ADMIN', 'TENANT OWNER',"LEARNER","MENTOR"]
  },
  {
    text: "Competency Manager",
    icon: TemplateIcon,
    id: 6,
    selected: false,
    route: "/competency-manager",
    role: getRolesPageVisibility("Competency Manager"), // ['ADMIN', 'TENANT OWNER']
  },
  {
    text: "Job Roles",
    icon: BriefcaseIcon,
    id: 6,
    selected: false,
    route: "/job-roles",
    role: getRolesPageVisibility("Job Roles"), //['ADMIN', 'TENANT OWNER']
  },
  {
    text: "Dashboard",
    icon: DashboardIcon,
    id: 5,
    selected: false,
    route: "/dashboard",
    role: getRolesPageVisibility("Dashboard"), //['ADMIN','TENANT OWNER']
  },
  {
    text: "Admin Dashboard",
    icon: DashboardIcon,
    id: 5,
    selected: false,
    route: "/admin-dashboard",
    role: getRolesPageVisibility("Admin Dashboard"), //['ADMIN']
  },
  {
    text: "Roles & Permissions",
    icon: RolesIcon,
    id: 6,
    selected: false,
    route: "/roles",
    role: getRolesPageVisibility("Roles & Permissions"), //['ADMIN','TENANT OWNER']
  },
  {
    text: "Job Training",
    icon: TrainingIcon,
    id: 6,
    selected: false,
    //route: "/job-training",
    role: getRolesPageVisibility("Job Training"), //['TENANT OWNER',"LEARNER"]
  },
  {
    text: "Track User",
    icon: AdminIcon,
    id: 5,
    selected: false,
    route: "/track-user",
    role: getRolesPageVisibility("Track User"), //['ADMIN']
  },
  {
    text: "Logs",
    icon: AdminIcon,
    id: 5,
    selected: false,
    route: "/admin",
    role: getRolesPageVisibility("Logs"), //['ADMIN']
  },
];
return item;
}
