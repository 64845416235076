import React from "react";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import { BASIC_INFO_DETAILS } from "../../style.js";
import Skeleton from "components/Skeleton";
import { COLUMN_LEFT_STYLE, COLUMN_RIGHT_STYLE } from "../../style.js";

const EDUCATION_CONTAINER = {
  display: "flex",
  fontFamily: "Manrope-Medium",
  lineHeight: "22px",
  position: "relative",
  marginBottom: "8px",
  width: "100%",
};

export default function EducationInfoDetails(props) {
  const [css, theme] = useStyletron();
  const getStartDate = (date) => {
    var d = new Date(date);
    return d.getFullYear();
  };
  return (
    <>
      {!props.loader ? (
        <Block>
          {props.eduData?.length > 0
            ? props.eduData?.map((education, index) => (
                <Block
                  display="flex"
                  flexDirection={["column", "column", "row"]}
                  className={css(EDUCATION_CONTAINER)}
                  key={index}
                >
                  <Block className={css(COLUMN_LEFT_STYLE(theme))}>
                    {getStartDate(education.date_start)}-{" "}
                    {getStartDate(education.date_end)}
                  </Block>
                  <Block
                    display="flex"
                    flexDirection="column"
                    gridGap="12px"
                    className={css(COLUMN_RIGHT_STYLE(theme))}
                  >
                    <Block as="b" data-testid="details_school_name">{education.school_name} </Block>
                    <Block as="span" display="block" data-testid="details_major">
                      {education.degree} {education.major ? "of " : ""}
                      {education.major}
                    </Block>
                    <Block as="span" display="block" data-testid="details_minor">
                      {education.minor}
                    </Block>
                  </Block>
                </Block>
              ))
            : "Not specified"}
        </Block>
      ) : (
        <Block marginTop={"15px"}>
          <Skeleton
            oval={false}
            height="auto"
            width="auto"
            rows={props.eduData?.length}
          />
        </Block>
      )}
    </>
  );
}
