import React, { useEffect } from "react";
import { FileUploader } from "baseui/file-uploader";
import "./styles.css";
import UploadProfile from "services/requests/upload_api";
import { useSetState } from "store/store";
import getProfile from "services/requests/image";
import userAvatar from "assets/images/profile-placeholder.png";
import { useStyletron } from "baseui";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "baseui/modal";
import { Button as BaseButton } from "baseui/button";
import { Block } from "baseui/block";
import Loader from "react-js-loader";

const HEADING_STYLE = {
  fontSize: "24px",
  lineHeight: "32px",
  fontFamily: "Manrope-Semibold",
  color: "black",
};

const MODAL_DIALOG_STYLE = {
  borderTopLeftRadius: "4px",
  borderTopRightRadius: "4px",
  borderBottomLeftRadius: "4px",
  borderBottomRightRadius: "4px",
  boxShadow: "box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)",
};

const COMMON_BUTTON_STYLE = {
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  borderBottomLeftRadius: "8px",
  borderBottomRightRadius: "8px",
  fontSize: "16px",
  lineHeight: "22px",
  fontFamily: "Manrope-Bold",
};

const getCancelButtonStyle = (theme) => ({
  marginRight: "16px",
  ...COMMON_BUTTON_STYLE,
  backgroundColor: theme.RAColors?.gray100,
  color: theme.RAColors?.gray600,
  ":hover": {
    backgroundColor: theme.RAColors?.gray200,
  },
});

const getDoneButtonStyle = (theme) => ({
  ...COMMON_BUTTON_STYLE,
  backgroundColor: "#DCE3FF",
  color: "#424F80",
  ":hover": {
    backgroundColor: "#627af5",
  },
});

function useInterval(callback, delay) {
  const savedCallback = React.useRef(() => {});
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default function UploadProfileimage() {
  const [progress, setProgress] = React.useState(0);
  const [isActive, setIsActive] = React.useState(false);
  const [visible, setVisible] = React.useState(false);
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  const [selected, setSelected] = React.useState(false);
  const setState = useSetState();
  const [css, theme] = useStyletron();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [imageFile, setImageFile] = React.useState(null);
  const [val, setVal] = React.useState(0);
  const [loader, setloader] = React.useState(false);
  const [disable, setDisable] = React.useState(true);


  useEffect(() => {
    const { current } = uploadedImage;
    current.src =
      localStorage.getItem("image") !== "null" &&
      localStorage.getItem("image") !== null && localStorage.getItem("image") !== "" &&  localStorage.getItem("image") !== undefined
        ? localStorage.getItem("image")
        : userAvatar;
  });
  const toggleDialog = () => {
    setVisible(!visible);
  };
  const onClose = () => {
    uploadImage(imageFile);
  };

  useInterval(
    () => {
      if(val === 40){
        if(progress >= 40){
          setErrorMessage("Your selected file type is not allowed");    
          stopProgress();
        }
      }
      if (progress === 100) {
        stopProgress();
      }
      else {
        setProgress(progress + 10);
      }
    },
    isActive ? 500 : null
  );

  const uploadImage = (image) => {
    setloader(true);
    UploadProfile(localStorage.getItem("email"), image[0]).then(function (
      response
    ) {
      if (response.status === 200) {
        getImage(localStorage.getItem("email"));
      }else{
        localStorage.setItem("image", null);
      }
    })
    .catch((err) => {
      localStorage.setItem("image", null);
    });
  };

  function stopProgress() {  
    setIsActive(false);
    setProgress(0);
    setSelected(true);
    setTimeout(() => {
      setErrorMessage("");
      setProgress(0);
    }, 3000);  
  }
  function onCancelProgress() {
    setIsActive(false);
    setProgress(0);
    setErrorMessage("");
    setDisable(true)
  }
  function startProgress(acceptedFiles) {
    let imagetype = acceptedFiles[0].type.toUpperCase();
    if(imagetype === 'IMAGE/PNG' || imagetype === 'IMAGE/JPG' || imagetype === 'IMAGE/JPEG'){
      setVal(0);
      setDisable(false)  
    }else{
      setVal(40);
      setDisable(true)  
    }
    setIsActive(true);
    setSelected(false);
  }

  function getImage(email) {
    getProfile(email)
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("image", response.data?.image);
          const { current } = uploadedImage;
          current.src = response.data?.image;
          setState((prev) => ({ ...prev, image: response.data?.image }));
          setloader(false);
          setSelected(false)
          toggleDialog();
        }
      })
      .catch((err) => {});
  }

  return (
    <div className="profile-section" style={{ justifyContent: "center" }}>
       {loader ? (
          <Loader
            type="bubble-loop"
            bgColor={"#332ff0"}
            color={"#332ff0"}
            size={100}
          />
      ) : null}
      <input type="file" accept="image/*" style={{ display: "none" }} />
      <div className="imageDispaly">
        <img ref={uploadedImage} alt="" className="uploadedImage" />
        <span onClick={toggleDialog} className="upload_text">
          Click to upload Image
        </span>
        <div className="Phone-Number-Popup">
          <Modal
            onClose={() => setVisible(false)}
            closeable
            isOpen={visible}
            animate
            autoFocus={false}
            overrides={{
              Dialog: { style: MODAL_DIALOG_STYLE },
            }}
          >
            <ModalHeader className={css({ margin: "24px !important" })}>
              <Block className={css(HEADING_STYLE)}>
                Change Your Profile Photos
              </Block>
            </ModalHeader>
            <ModalBody>
              <p className="p_text">{"Formats: png, jpeg"}</p>
              <FileUploader
              errorMessage={errorMessage}
               onCancel={onCancelProgress}
               onRetry={onCancelProgress}
                onDrop={(acceptedFiles, rejectedFiles) => {
                  // handle file upload...
                  setImageFile(acceptedFiles)
                  startProgress(acceptedFiles);
                 // uploadImage(acceptedFiles);
                }}
                progressAmount={progress}
                ref={imageUploader}
                progressMessage={
                  progress ? `Uploading... ${progress}% of 100%` :  ""
                }
              />
              {selected && val === 0? (
                <p class="successMsg">{"Image is selected"}</p>
              ) : null}
            </ModalBody>
            <ModalFooter>
              <BaseButton
                onClick={toggleDialog}
                overrides={{
                  BaseButton: { style: getCancelButtonStyle(theme) },
                }}
              >
                Cancel
              </BaseButton>
              <BaseButton
                onClick={onClose}
                overrides={{
                  BaseButton: { style: getDoneButtonStyle(theme) },
                }}
                disabled={disable}
              >
                Done
              </BaseButton>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    </div>
  );
}
