import { ASSIGN_JOB_ROLE } from "../apiEndpoint";
import { educationAxios } from "../http/http";
export default function assignJobRoleFunction(
  payload = "",
  email = "",
  action = ""
) {
  if (action === "delete") {
    return educationAxios.delete(
      `${ASSIGN_JOB_ROLE}?user_email=${email}&learner_email=${payload?.LearnerEmail}`,
      payload
    );
  } else {
    return educationAxios.post(
      `${ASSIGN_JOB_ROLE}?user_email=${email}`,
      payload
    );
  }
}
