import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import SearchIcon from "baseui/icon/search";
import { Input } from "baseui/input";
import { Select } from "baseui/select";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import useSortTable from "hooks/useSortTable";
import { Suspense, lazy, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Status_Data } from "resources/constants/constData";
import {
  ADD_SKILL,
  Core_Skills,
  Enter_Names,
  Name,
  Status,
  Track_Progress,
} from "resources/constants/strings";
import { useSetState, useTrackedState } from "store/store";
import {
  COMMON_BORDER_COLOR,
  COMMON_BORDER_STYLE,
  COMMON_BORDER_WIDTH,
  COMMON_RADIUS,
} from "utils/style";
import {
  TableFlexScore,
  TableHeader,
  TableName,
  TableScore,
  TableStatus,
  TableTrackProgress,
} from "./table";

const ModalComponent = lazy(() => import("./modal/index.js"));

const BLOCK_STYLE = (theme) => ({
  backgroundColor: "white",
  borderRadius: "8px",
  padding: "24px",
  border: `1px solid ${theme.RAColors?.gray200}`,

  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "24px",
  },
});

const FILTER_STYLE = (theme) => ({
  [theme.mediaQuery.small]: {
    marginBottom: "16px",
  },
  [theme.mediaQuery.medium]: {
    marginBottom: "24px",
  },
});

const LABEL_STYLE = (theme) => ({
  lineHeight: "22px",
  color: theme.RAColors?.gray900,
  margin: "0 0 4px",

  [theme.mediaQuery.small]: {
    fontSize: "14px",
  },
  [theme.mediaQuery.medium]: {
    fontSize: "16px",
  },
});

const INPUT_OVERRIDE_STYLE = {
  Input: ({ $theme }) => ({
    backgroundColor: "none",
    color: $theme.RAColors?.gray900,
  }),
  Root: ({ $theme }) => ({
    backgroundColor: "none",
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    width: "100%",
  }),
  StartEnhancer: ({ $theme }) => ({
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: "transparent",
  }),
};

const SELECT_OVERRIDE_STYLE = {
  Root: ({ $theme }) => ({ width: "100%" }),
  ControlContainer: ({ $theme }) => ({
    backgroundColor: "none",
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    color: $theme.RAColors?.gray900,
  }),
};

const TABLE_OVERRIDE_STYLE = {
  Root: ({ $theme }) => ({
    ...COMMON_RADIUS("8px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    ...COMMON_BORDER_STYLE("solid"),
  }),
  NameCell: { width: "250px", maxWidth: "250px", minWidth: "250px" },
  TrackCell: { width: "400px", maxWidth: "400px", minWidth: "250px" },
  ScoreCell: { width: "300px", maxWidth: "300px", minWidth: "250px" },
  FlexScoreCell: { width: "200px", maxWidth: "200px", minWidth: "200px" },
  StatusCell: {
    width: "122px",
    maxWidth: "150px",
    minWidth: "122px",
    textAlign: "right",
    position: "relative",
  },
};

const TABLE_CUSTOM_CELL_STYLE = {
  ...TABLE_OVERRIDE_STYLE.TrackCell,
  cursor: "pointer",
  fontSize: "14px",
  lineHeight: "20px",
  padding: "16px",
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
};

const ALL_STATUS = Status_Data.map((status) => ({ label: status, id: status }));
const ALL_TRACKS = [{ label: "All tracks", id: "All tracks" }];

export default function Students() {
  const [css, theme] = useStyletron();
  const history = useHistory();
  const setState = useSetState();
  const state = useTrackedState();
  const { onSort, sortAsc, sortColumn } = useSortTable("student_overview_sort");

  const [searchString, setSearchString] = useState("");
  const [track, setTrack] = useState([ALL_TRACKS[0]]);
  const [status, setStatus] = useState([ALL_STATUS[0]]);
  const [flex, setFlex] = useState(false);
  const [row, setRow] = useState();

  const uniqueTracks = useMemo(() => {
    const tracks = (state.learners || []).map(
      (learner) => learner.learning_path_name
    );
    return [...new Set(tracks)].map((track) => ({ label: track, id: track }));
  }, [state.learners]);

  const filteredLearners = useMemo(
    () =>
      (state.learners || []).filter((learner) => {
        const {
          name = "",
          learning_path_name,
          learner_progress_bar = {},
          email_id = "",
        } = learner;
        const progressStatus =
          learner_progress_bar?.progress_learning_path?.progress_status.replace(
            "_",
            " "
          );

        const isMatchedTrack =
          track[0].id === "All tracks" || track[0].id === learning_path_name;
        const isMatchedStatus =
          status[0].id === Status_Data[0] || status[0].id === progressStatus;

        const lowercasedSearchString = searchString.toLowerCase();
        const isMatchedString =
          name.toLowerCase().includes(lowercasedSearchString) ||
          email_id.toLowerCase().includes(lowercasedSearchString);

        return isMatchedStatus && isMatchedString && isMatchedTrack;
      }),
    [searchString, track, status, state.learners]
  );

  const sortedLearners = useMemo(() => {
    if (!sortColumn) return filteredLearners;
    const sortOrder = sortAsc ? 1 : -1;

    const sortedLearners = filteredLearners.sort((a, b) => {
      let firstValue, secValue;

      if (sortColumn === Name) {
        firstValue = a.name;
        secValue = b.name;
      } else if (sortColumn === Track_Progress) {
        firstValue =
          a.learner_progress_bar.progress_learning_path.progress_actual;
        secValue =
          b.learner_progress_bar.progress_learning_path.progress_actual;
      } else if (sortColumn === Core_Skills) {
        firstValue = a.learner_radar_skills.skill_score;
        secValue = b.learner_radar_skills.skill_score;
      } else if (sortColumn === ADD_SKILL) {
        firstValue = a.learner_radar_skills_flex?.skill_score;
        secValue = b.learner_radar_skills_flex?.skill_score;
      } else {
        firstValue =
          a.learner_progress_bar.progress_learning_path.progress_status;
        secValue =
          b.learner_progress_bar.progress_learning_path.progress_status;
      }

      return (
        (firstValue !== secValue ? (firstValue < secValue ? -1 : 1) : 0) *
        sortOrder
      );
    });

    return sortedLearners;
  }, [sortColumn, sortAsc, filteredLearners]);

  const goToTrack = (track) => {
    setState(
      (prev) => ({ ...prev, track }),
      () => {
        history.push("/tracksummary");
      }
    );
  };
  const openModal = (row) => {
    setFlex(true);
    setRow(row);
  };
  function closeModal() {
    setFlex(false);
    setRow(null);
  }

  return (
    <Block className={css(BLOCK_STYLE(theme))}>
      <Suspense fallback={<div />}>
        {row && (
          <ModalComponent isFlex={flex} row={row} close={() => closeModal()} />
        )}
      </Suspense>
      <Block
        display="flex"
        flexWrap="wrap"
        gridGap={["16px", "8px", "24px"]}
        className={css(FILTER_STYLE(theme))}
        data-html2canvas-ignore
      >
        <Block data-testid="filter-item" width={["100%", "100%", "300px"]}>
          <Block
            as="h5"
            data-testid="filter-item-title"
            className={css(LABEL_STYLE(theme))}
          >
            Search
          </Block>
          <Input
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            placeholder={Enter_Names}
            clearOnEscape
            overrides={{
              Input: { style: INPUT_OVERRIDE_STYLE.Input },
              Root: { style: INPUT_OVERRIDE_STYLE.Root },
              StartEnhancer: { style: INPUT_OVERRIDE_STYLE.StartEnhancer },
            }}
            startEnhancer={
              <SearchIcon size={24} color={theme.RAColors?.gray900} />
            }
            data-testid="filter-item-search"
          />
        </Block>
        <Block
          data-testid="filter-item"
          width={["calc(50% - 4px)", "calc(50% - 4px)", "200px"]}
        >
          <Block
            as="h5"
            data-testid="filter-item-title"
            className={css(LABEL_STYLE(theme))}
          >
            Track
          </Block>
          <Select
            clearable={false}
            searchable={false}
            options={[...ALL_TRACKS, ...uniqueTracks]}
            value={track}
            onChange={(params) => setTrack(params.value)}
            overrides={{
              Root: { style: SELECT_OVERRIDE_STYLE.Root },
              ControlContainer: {
                style: SELECT_OVERRIDE_STYLE.ControlContainer,
              },
            }}
          />
        </Block>
        <Block
          data-testid="filter-item"
          width={["calc(50% - 4px)", "calc(50% - 4px)", "200px"]}
        >
          <Block
            as="h5"
            data-testid="filter-item-title"
            className={css(LABEL_STYLE(theme))}
          >
            Status
          </Block>
          <Select
            clearable={false}
            searchable={false}
            options={ALL_STATUS}
            value={status}
            onChange={(params) => setStatus(params.value)}
            overrides={{
              Root: { style: SELECT_OVERRIDE_STYLE.Root },
              ControlContainer: {
                style: SELECT_OVERRIDE_STYLE.ControlContainer,
              },
            }}
          />
        </Block>
      </Block>
      <TableBuilder
        data={sortedLearners}
        overrides={{
          Root: { style: TABLE_OVERRIDE_STYLE.Root },
          TableBodyRow: {
            component: ({ children }) => (
              <tr data-testid="student-table-row">{children}</tr>
            ),
          },
        }}
        isLoading={state.isFetching || false}
        emptyMessage="No data"
      >
        <TableBuilderColumn
          header={
            <TableHeader
              name={Name}
              sorted={sortColumn}
              asc={sortAsc}
              onSort={onSort}
            />
          }
          overrides={{
            TableHeadCell: { style: TABLE_OVERRIDE_STYLE.NameCell },
            TableBodyCell: { style: TABLE_OVERRIDE_STYLE.NameCell },
          }}
        >
          {(row) => <TableName row={row} onClickName={() => goToTrack(row)} />}
        </TableBuilderColumn>
        <TableBuilderColumn
          header={
            <TableHeader
              name={Track_Progress}
              sorted={sortColumn}
              asc={sortAsc}
              onSort={onSort}
            />
          }
          overrides={{
            TableHeadCell: { style: TABLE_OVERRIDE_STYLE.TrackCell },
            TableBodyCell: {
              component: ({ $row, children, ...rest }) => (
                <td
                  className={css(TABLE_CUSTOM_CELL_STYLE)}
                  onClick={() => goToTrack($row)}
                >
                  {children}
                </td>
              ),
            },
          }}
        >
          {(row) => <TableTrackProgress row={row} />}
        </TableBuilderColumn>
        <TableBuilderColumn
          header={
            <TableHeader
              name={Core_Skills}
              sorted={sortColumn}
              asc={sortAsc}
              onSort={onSort}
            />
          }
          overrides={{
            TableHeadCell: { style: TABLE_OVERRIDE_STYLE.ScoreCell },
            TableBodyCell: { style: TABLE_OVERRIDE_STYLE.ScoreCell },
          }}
        >
          {(row) => <TableScore row={row} onOpenScore={() => setRow(row)} />}
        </TableBuilderColumn>
        <TableBuilderColumn
          header={
            <TableHeader
              name={ADD_SKILL}
              sorted={sortColumn}
              asc={sortAsc}
              onSort={onSort}
            />
          }
          overrides={{
            TableHeadCell: { style: TABLE_OVERRIDE_STYLE.FlexScoreCell },
            TableBodyCell: { style: TABLE_OVERRIDE_STYLE.FlexScoreCell },
          }}
        >
          {(row) => (
            <TableFlexScore row={row} onOpenScore={() => openModal(row)} />
          )}
        </TableBuilderColumn>
        <TableBuilderColumn
          header={
            <TableHeader
              name={Status}
              sorted={sortColumn}
              asc={sortAsc}
              onSort={onSort}
            />
          }
          overrides={{
            TableHeadCell: { style: TABLE_OVERRIDE_STYLE.StatusCell },
            TableBodyCell: { style: TABLE_OVERRIDE_STYLE.StatusCell },
          }}
        >
          {(row) => <TableStatus row={row} />}
        </TableBuilderColumn>
      </TableBuilder>
    </Block>
  );
}
