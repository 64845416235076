import React from "react";
import { Block } from "baseui/block";
import { TENANT_MANAGEMENT } from "resources/constants/strings";
import { useStyletron } from "baseui";
import DownloadIcon from "assets/images/download-icon.svg";
import {
  COMMON_RADIUS,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_STYLE,
} from "utils/style";

const HEADING_STYLE = (theme) => ({
  fontFamily: "Epilogue-Semibold",
  fontSize: "28px",
  lineHeight: "40px",
  color: theme.RAColors?.gray900,
});

const BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  backgroundColor: "white",
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  borderColor: $theme.RAColors?.purple,
  paddingTop: "12px",
  paddingBottom: "12px",
  paddingRight: "24px",
  paddingLeft: "24px",
  ":hover": { backgroundColor: $theme.RAColors?.gray300 },
  color: $theme.RAColors?.purple,

  [$theme.mediaQuery.small]: {
    width: "100%",
  },
  [$theme.mediaQuery.medium]: {
    width: "initial",
  },
});

const DOWNLOAD_ICON_STYLE = {
  width: "12px",
  height: "12px",
  marginRight: "16px",
};

export default function Title({ exportPdf}) {
  const [css, theme] = useStyletron();
  return (
    <Block
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gridGap="16px"
      marginBottom={["16px", "16px", "20px"]}
      flexWrap="wrap"
    >
      <Block as="h3" className={css(HEADING_STYLE(theme))} data-testid="title">
        {"Admin Data"}
      </Block>
    </Block>
  );
}
