import axios from "axios";
import { giniusBaseURL, studentURL } from "../config";

const APILinkforceErrorLogAxios = axios.create({
  baseURL: giniusBaseURL
})

const LinkforceControllerErrorLogAxios = axios.create({
  baseURL: studentURL
})

APILinkforceErrorLogAxios.defaults.headers.common = {
  ...(APILinkforceErrorLogAxios.defaults.headers.common || {}),
  ApiKey: 'A9ACAF05-B32B-435C-8D7D-3033B138D387'
}

LinkforceControllerErrorLogAxios.defaults.headers.common = {
  ...(LinkforceControllerErrorLogAxios.defaults.headers.common || {}),
  ApiKey: 'TESTDEV-0000-1111-2222-333333333333'
}

export const fetchAPILinkforceErrorLogAxios = () => APILinkforceErrorLogAxios.get('/Accounts/Logs')
export const fetchLinkforceControllerErrorLogAxios = () => LinkforceControllerErrorLogAxios('/errors')
