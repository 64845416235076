import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { useTrackedState } from "store/store";
import { useMemo } from "react";
import {
  All_Student,
  Student_Failing,
  Student_At_Risk,
  On_Track as Studend_On_Track,
  Student_Exceeds,
} from "resources/constants/strings";

const BLOCK_STYLE = (theme) => ({
  backgroundColor: "white",
  borderRadius: "8px",
  padding: "24px",
  border: `1px solid ${theme.RAColors?.gray200}`,

  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "24px",
  },
});
const OVERVIEW_ITEM_TITLE_STYLE = {
  margin: 0,
  fontFamily: "Manrope-SemiBold",
  fontSize: "14px",
  lineHeight: "19px",
};
const OVERVIEW_ITEM_TEXT_STYLE = theme => ({
  margin: 0,
  fontFamily: "Epilogue-Medium",
  lineHeight: 1,

  [theme.mediaQuery.small]: {
    fontSize: "32px",
  },
  [theme.mediaQuery.medium]: {
    fontSize: "48px",
  },
});
const DOT_STYLE = { width: "12px", height: "12px", borderRadius: "100%" };

export default function Summary() {
  const [css, theme] = useStyletron();
  const state = useTrackedState();

  const overviewItems = useMemo(() => {
    const {
      learner_count_total: total = 0,
      learner_status_count_map: status = {},
    } = state.studentOverview || {};
    const {
      Action_Needed = 0,
      At_Risk = 0,
      Exceeds = 0,
      On_Track = 0,
    } = status;

    return [
      { name: All_Student, color: "", count: total },
      { name: Student_Failing, color: "#edc12f", count: Action_Needed },
      { name: Student_At_Risk, color: "#f2994a", count: At_Risk },
      { name: Studend_On_Track, color: "#0c953a", count: On_Track },
      { name: Student_Exceeds, color: "#eaeeff", count: Exceeds },
    ];
  }, [state.studentOverview]);

  return (
    <Block
      data-testid="summary"
      className={css(BLOCK_STYLE(theme))}
      display="flex"
      flexWrap="wrap"
      gridGap={['16px', '16px', "40px"]}
    >
      {overviewItems.map((item, index) => (
        <Block
          data-testid="summary-item"
          key={index}
          color={theme.RAColors?.gray800}
        >
          <Block
            display="flex"
            gridGap="8px"
            alignItems="center"
            marginBottom="12px"
          >
            {item.color && (
              <Block
                className={css({ ...DOT_STYLE, backgroundColor: item.color })}
              />
            )}
            <Block
              data-testid="summary-item-title"
              as="h4"
              className={css(OVERVIEW_ITEM_TITLE_STYLE)}
            >
              {item.name}
            </Block>
          </Block>
          <Block
            data-testid="summary-item-number"
            as="h5"
            className={css(OVERVIEW_ITEM_TEXT_STYLE(theme))}
          >
            {item.count}
          </Block>
        </Block>
      ))}
    </Block>
  );
}
