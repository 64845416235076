import {
  COMMON_RADIUS,
  COMMON_BORDER_COLOR,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_STYLE,
} from "utils/style";

export const BASIC_INFO_DETAILS = (theme)=> ({
  width: "100%",
  float: "left",
  margin: "7px",
  verticalAlign: "middle",
  display: "block",
});

export const COLUMN_LEFT_STYLE = theme => ({
  fontSize: '16px',
  lineHeight: '22px',
  fontFamily: "Manrope-Semibold",

  [theme.mediaQuery.small]: {
    width:"100%",
  },
  [theme.mediaQuery.medium]: {
    width:"25%",
  },
});
export const COLUMN_RIGHT_STYLE = theme => ({
  fontSize: '16px',
  lineHeight: '22px',
  fontFamily: "Manrope-Medium",

  overflow: 'hidden',
  [theme.mediaQuery.small]: {
    width:"100%",
  },
  [theme.mediaQuery.medium]: {
    width:"60%",
  },
});
export const CHANGE_TEXT_STYLE = {
  marginLeft: "10px",
  fontFamily: "Manrope-Medium",
  color: "#3a3fdf",
  fontSize: "16px",
  cursor: "pointer",
};

export const MB3_STYLE = {
  backgroundColor: "#FFFFFF",
  border: "1px solid #CCCCCC",
  borderRadius: "4px",
  lineHeight: "27px",
};

export const COLUMN_INPUTS_STYLE = (theme, n = 2) => ({
  display: "grid",
  gridTemplateColumns: `repeat(1, 1fr)`,
  columnGap: "20px",
  marginBottom: "20px",

  [theme.mediaQuery.medium]: {
    gridTemplateColumns: `repeat(${n}, 1fr)`,
  },
});

export const CARD_STYLE = theme => ({
  position: "relative",
  display: "grid",
  columnGap: "10px",
  rowGap: "27px",
  borderRadius: "8px",
  border: "1px solid #E0E0E0",
  textAlign: "initial",
  backgroundColor: "#FFFFFF",

  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.large]: {
    padding: "40px",
  },
});
export const CARD_HEADING_STYLE = {
  color: "black",
  fontFamily: "Manrope-Bold",
};
export const CARD_SUB_HEADING_STYLE = {
  color: "black",
  fontFamily: "Manrope-Bold",
  fontSize: "18px",
  marginBottom: "20px",
};
export const SEPARATOR = {
  borderBottom: "1px solid #e0e0e0",
  marginTop: "32px",
  marginBottom: "32px",
};
export const RESET_PASSWORD_BTN = {
  background: "#dce3ff",
  borderRadius: "8px",
  color: "#424f80",
  border: "0px",
  paddingTop: "12px",
  paddingBottom: "12px",
  paddingRight: "24px",
  paddingLeft: "24px",
  fontFamily: "Manrope-Medium",
};
export const MARGIN_TOP_STYLE = {
  lineHeight: "22px",
  padding: "0px",
  margin: "0px",
};
export const TAB_OVERRIDE_STYLE = ({ $theme, $active }) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "8px",
  width: "245px",
  height: "49px",
  fontFamily: "Manrope-Semibold",
  fontSize: "16px",
  lineHeight: "25px",
  color: $active ? "#141414" : "#858585",
  backgroundColor: $active ? "#F3F3F3" : "transparent",
  marginRight: 0,
  paddingLeft: "12px",
  paddingRight: "12px",
  paddingTop: "24px",
  paddingBottom: "24px",
  marginLeft: "0px",

  [$theme.mediaQuery.small]: {
    borderRadius: "0px",
    width: "50%",
  },
  [$theme.mediaQuery.large]: {
    borderRadius: "8px",
    width: "245px",
  },
});
export const TAB_CONTENT_HEADING_STYLE = {
  fontFamily: "Epilogue-Semibold",
  fontSize: "28px",
  lineHeight: "40px",
};
export const CARD_EDIT_ICON_STYLE = theme => ({
  position: "absolute",
  borderRadius: "8px",
  backgroundColor: "#dce3ff",
  paddingTop: "6px",
  paddingBottom: "6px",
  paddingRight: "12px",
  paddingLeft: "12px",
  fontSize: "12px",
  color: "#332ff1",
  cursor: 'pointer',

  [theme.mediaQuery.small]: {
    top: "16px",
    right: "16px",
  },
  [theme.mediaQuery.large]: {
    top: "40px",
    right: "40px",
  },
});
export const REMOVE_TEXT_STYLE = {
  fontFamily: "Manrope-Medium",
  fontSize: "18px",
  lineHeight: "25px",
  display: "flex",
  alignItems: "center",
  color: "#a62e2e",
  marginTop: "10px",
  marginBottom: "40px",
  cursor: "pointer",
};
export const ADD_TEXT_STYLE = {
  ...REMOVE_TEXT_STYLE,
  color: "#332ff1",
};

export const TAB_CONTENT_OVERRIDE_STYLE = ({$theme}) => ({
  paddingRight: "0px",
  paddingTop: "0px",
  paddingBottom: "0px",

  [$theme.mediaQuery.small]: {
    width: "100%",
    paddingLeft: "0px",
    paddingTop: "16px",
  },
  [$theme.mediaQuery.large]: {
    width: "80%",
    paddingLeft: "40px",
    paddingBottom: "40px",
    paddingTop: "0px",
  },
})

export const TAB_BAR_OVERRIDE_STYLE = ({$theme}) => ({
  backgroundColor: "none",
  paddingLeft: "0px",
  paddingRight: "0px",
  paddingTop: "0px",
  paddingBottom: "0px",
  position: "sticky",
  top: 0,
  alignSelf: 'flex-start',
  zIndex: 1,

  [$theme.mediaQuery.small]: {
    width: "100%",
    position: "static",
  },
  [$theme.mediaQuery.large]: {
    width: "initial",
    position: "sticky",
  },
})

export const INPUT_LABEL_STYLE = (theme) => ({
  display: 'inline-block',
  lineHeight: "22px",
  color: theme.RAColors?.gray900,
  margin: "0 0 4px",

  [theme.mediaQuery.small]: {
    fontSize: "14px",
  },
  [theme.mediaQuery.medium]: {
    fontSize: "16px",
  },
});

export const INPUT_OVERRIDE_STYLE = {
  Input: ({ $theme }) => ({
    backgroundColor: "none",
    color: $theme.RAColors?.gray900,
  }),
  Root: ({ $theme }) => ({
    backgroundColor: "none",
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    width: "100%",
  }),
};

export const SELECT_OVERRIDE_STYLE = {
  Root: ({ $theme }) => ({ width: "100%" }),
  ControlContainer: ({ $theme }) => ({
    backgroundColor: "none",
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    color: $theme.RAColors?.gray900,
  }),
};

const COMMON_BUTTON_STYLE = {
  lineHeight: '22px',
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  fontFamily: 'Manrope-Semibold',
  width: '122px'
}

export const SAVE_BTN_STYLE = ({ $theme }) => ({
  ...COMMON_BUTTON_STYLE,
  ...COMMON_BORDER_COLOR($theme.RAColors?.blue700),
  backgroundColor: $theme.RAColors?.blue700,
  ":hover": { backgroundColor: $theme.RAColors?.blue700 },
  color: 'white',
});

export const CANCEL_BTN_STYLE = ({ $theme }) => ({
  ...COMMON_BUTTON_STYLE,
  ...COMMON_BORDER_COLOR($theme.RAColors?.gray100),
  backgroundColor: $theme.RAColors?.gray100,
  ":hover": { backgroundColor: $theme.RAColors?.gray100 },
  color: $theme.RAColors?.gray800,
});
export const THM_ALERT_MESSAGE_STYLE = ({ $theme }) => ({
  paddingLeft: "2rem",
  fontSize: "small",
});