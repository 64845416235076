import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { useStyletron } from "baseui";
import DownloadIcon from "assets/images/download-icon.svg";
import {
  COMMON_RADIUS,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_STYLE,
} from "utils/style";
import { ChevronRight } from "baseui/icon";
import { Link } from "react-router-dom";
import {
  Download_Data,
  Summary_Text,
  All_Learner,
  Learner,
} from "resources/constants/strings.js";
import { filteredAdmin } from "utils/app-utils";

const BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  backgroundColor: "white",
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  borderColor: $theme.RAColors?.purple,
  paddingTop: "12px",
  paddingBottom: "12px",
  paddingRight: "24px",
  paddingLeft: "24px",
  ":hover": { backgroundColor: $theme.RAColors?.gray300 },
  color: $theme.RAColors?.purple,

  [$theme.mediaQuery.small]: {
    width: "100%",
  },
  [$theme.mediaQuery.medium]: {
    width: "initial",
  },
});

const DOWNLOAD_ICON_STYLE = {
  width: "12px",
  height: "12px",
  marginRight: "16px",
};

const TITLE_STYLE = (theme) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "16px",
  flexWrap: "wrap",

  [theme.mediaQuery.small]: {
    marginBottom: "16px",
  },
  [theme.mediaQuery.medium]: {
    marginBottom: "32px",
  },
});

const ITEM_TITLE_STYLE = {
  fontFamily: "Manrope-Medium",
  fontSize: "12px",
  lineHeight: "16px",
};

const ITEM_CONTENT_STYLE = {
  fontFamily: "Manrope-Bold",
  fontSize: "18px",
  lineHeight: "25px",
  textTransform: "capitalize",
};

const BreadCrumb = ({ exportPdf, user }) => {
  const [css, theme] = useStyletron();
  return (
    <Block className={css(TITLE_STYLE(theme))}>
      <Block
        display="flex"
        flexDirection="row"
        gridGap="20px"
        alignItems="center"
      >
        {filteredAdmin(localStorage.getItem("role")) && (localStorage.getItem("viewAs") !== "LEARNER") ? (
          <Block display="flex" flexDirection="column" gridGap="4px">
            <Block
              className={css(ITEM_TITLE_STYLE)}
              data-testid="breadcrumb-summary"
            >
              {Summary_Text}
            </Block>
            <Link
              to="/student"
              className={css({
                ...ITEM_CONTENT_STYLE,
                color: theme?.RAColors?.blue700,
              })}
              data-testid="breadcrumb-all-learner"
            >
              {All_Learner}
            </Link>
          </Block>
        ) : null}
        {filteredAdmin(localStorage.getItem("role")) && (localStorage.getItem("viewAs") !== "LEARNER")  ? (
          <ChevronRight size={32} />
        ) : null}
        <Block display="flex" flexDirection="column" gridGap="4px">
          <Block
            className={css(ITEM_TITLE_STYLE)}
            data-testid="breadcrumb-learner"
          >
            {Learner}
          </Block>
          <Link
            to="/tracksummary"
            className={css({
              ...ITEM_CONTENT_STYLE,
              color: theme?.RAColors?.gray800,
            })}
            data-testid="breadcrumb-learner-content"
          >
         {user?.first_name+' '+user?.last_name}
          </Link>
        </Block>
      </Block>
      <Button
        onClick={exportPdf}
        overrides={{ BaseButton: { style: BUTTON_OVERRIDE_STYLE } }}
        data-testid="download-button"
      >
        <img
          src={DownloadIcon}
          alt="Download"
          className={css(DOWNLOAD_ICON_STYLE)}
        />
        <span>{Download_Data}</span>
      </Button>
    </Block>
  );
};

export default BreadCrumb;
