import React from "react";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import MentorProficientBlock from "../dolCompetency/mentorProficientBlock";
import LearnerProficientBlock from "../dolCompetency/learnerProficientBlock";
import TaskBlockArrow from "../dolCompetency/taskBlockArrow";

const BLOCK_STYLE = (theme) => ({
  display: "inline-block",
  alignItems: "center",
  flexDirection: "row",
  gridGap: "10px",
  cursor: "pointer",
  backgroundColor: theme.RAColors?.emerald50,
  ":hover": {
    backgroundColor: "rgba(12, 57, 26, 0.08)",
  },
  borderRadius: "16px",
  padding: "16px",
  border: `1px solid ${theme.RAColors?.emerald500}`,
  width: "auto",
  [theme.mediaQuery.small]: {
    width: "max-content",
  },
  [theme.mediaQuery.medium]: {
    width: "max-content",
  },
  [theme.mediaQuery.large]: {
    width: "auto",
  },
});
const OTHER_BLOCK_STYLE = (theme) => ({
  display: "inline-block",
  alignItems: "center",
  flexDirection: "row",
  gridGap: "10px",
  cursor: "pointer",
  backgroundColor: "white",
  borderRadius: "16px",
  border: `1px solid ${theme.RAColors?.gray200}`,
  width: "auto",
  [theme.mediaQuery.small]: {
    padding: "16px",
    width: "max-content",
  },
  [theme.mediaQuery.medium]: {
    padding: "24px",
    width: "max-content",
  },
  [theme.mediaQuery.large]: {
    padding: "16px",
    width: "auto",
  },
});
const MAIN_BLOCK_STYLE = (theme) => ({
  borderRadius: "26px",
  padding: "10px",
  border: `1px solid ${theme.RAColors?.emerald500}`,
  width: "auto",
  display: "flex",
  [theme.mediaQuery.small]: {
    width: "max-content",
  },
  [theme.mediaQuery.medium]: {
    width: "max-content",
  },
  [theme.mediaQuery.large]: {
    width: "auto",
  },
});

const ACTIVE_INDIVISUAL_BLOCK_STYLE = (theme) => ({
  display: "flex",
  backgroundColor: "white",
  borderRadius: "16px",
  border: `1px solid black`,
  width: "auto",
  [theme.mediaQuery.small]: {
    padding: "16px",
    width: "max-content",
    display: "inline-grid",
    gridGap: "10px"
  },
  [theme.mediaQuery.medium]: {
    padding: "24px",
    width: "max-content",
    display: "flex",
    gridGap: "0px"
  },
  [theme.mediaQuery.large]: {
    padding: "16px",
    width: "auto",
    display: "flex",
    gridGap: "0px"
  },
});
const ACTIVE_BLOCK_STYLE = (theme) => ({
  display: "flex",
  backgroundColor: "white",
  borderRadius: "26px",
  border: `1px solid black`,
  width: "auto",
  [theme.mediaQuery.small]: {
    padding: "16px",
    width: "max-content",
    display: "inline-grid",
    gridGap: "10px"
  },
  [theme.mediaQuery.medium]: {
    padding: "24px",
    width: "max-content",
    display: "flex",
    gridGap: "0px"
  },
  [theme.mediaQuery.large]: {
    padding: "10px",
    width: "auto",
    display: "flex",
    gridGap: "0px"
  },
});
const INACTIVE_BLOCK_STYLE = (theme) => ({
  display: "flex",
  backgroundColor: "white",
  borderRadius: "26px",
  border: `1px solid ${theme.RAColors?.gray200}`,
  width: "auto",
  [theme.mediaQuery.small]: {
    padding: "16px",
    width: "max-content",
    display: "inline-grid",
    gridGap: "10px"
  },
  [theme.mediaQuery.medium]: {
    padding: "24px",
    width: "max-content",
    display: "flex",
    gridGap: "0px"
  },
  [theme.mediaQuery.large]: {
    padding: "10px",
    width: "auto",
    display: "flex",
    gridGap: "0px"
  },
});
export default function ProficientTaskBlock({
  item,
  itemData,
  Data,
  changeDolData,
  selectedIndex,
  rolespermission,
  handleClick,
  openVerifyCompetencyModal,
  categoryData,
  isRoleSpecificActivity,
  index,
}) {
  const [css, theme] = useStyletron();
  const isOJTEnable = itemData["OJT"];

  const showCommentAndStatusElementBasedOnTaskStatus = (
    taskName,
    itemObject,
    changeTaskStatus = false
  ) => {
    let preDefinedSignedTask = ["RTI", "RTIStatus", "JS", "JSStatus"];
    let definedSignedTask = [
      // "OJT",
      // "OJTStatus",
      "MentorProficientStatus",
      "LearnerProficientStatus",
      "FinalApprovalStatus",
    ];
    let isOJTEnable = itemObject?.OJT;
    let availableListedTask = Object.keys(itemObject);
    let show = false;
    let filteredAvailableListedTask = definedSignedTask.filter(function (n) {
      return availableListedTask.indexOf(n) !== -1;
    });
    definedSignedTask = definedSignedTask.filter(function (n) {
      return availableListedTask.indexOf(n) !== -1;
    });
    if (preDefinedSignedTask.includes(taskName)) {
      for (const element of preDefinedSignedTask) {
        if (taskName === element) {
          return show;
        }
      }
    }
    preDefinedSignedTask = preDefinedSignedTask.filter(function (n) {
      return availableListedTask.indexOf(n) !== -1;
    });
    const calculateDefinedSignedTask = () => {
      if (definedSignedTask.includes(taskName)) {
        for (let i = 0; i < definedSignedTask.length; i++) {
          let definedTaskName = definedSignedTask[i];
          let previousDefinedTaskName = definedSignedTask[i - 1];
          let nextDefinedTaskName = definedSignedTask[i + 1];

          if (itemObject[previousDefinedTaskName]?.CompletionStatus === false) {
            return false;
          } else {
            if (taskName === definedTaskName) {
              if (
                itemObject[nextDefinedTaskName]?.CompletionStatus === false &&
                (isOJTEnable || isRoleSpecificActivity)
              ) {
                return true;
              } else if (
                itemObject[nextDefinedTaskName]?.CompletionStatus ===
                  undefined &&
                (isOJTEnable || isRoleSpecificActivity)
              ) {
                return true;
              }
              for (const ele of filteredAvailableListedTask) {
                if (
                  ele === taskName &&
                  itemObject[ele]?.CompletionStatus === false &&
                  (isOJTEnable || isRoleSpecificActivity)
                ) {
                  return true;
                }
                if (
                  ("FinalApprovalStatus" === taskName ||
                    "LearnerProficientStatus" === taskName) &&
                  !isOJTEnable
                ) {
                  return true;
                }
              }
              return false;
            }
          }
        }
      }
    };
    if (preDefinedSignedTask.length >= 1) {
      let show = 1;

      for (const element of preDefinedSignedTask) {
        if (itemObject[element]?.CompletionStatus === true) {
          show = show * 1;
        } else {
          show = show * 0;
        }
      }
      if (show === 0) {
        return false;
      } else {
        return calculateDefinedSignedTask();
      }
    } else {
      return calculateDefinedSignedTask();
    }
  };
  return (
    <>
      {isOJTEnable || isRoleSpecificActivity? (
        <Block
          className={
            itemData[item]?.CompletionStatus === true &&
            itemData["LearnerProficientStatus"]?.CompletionStatus === true
              ? css({ ...MAIN_BLOCK_STYLE(theme) })
              : showCommentAndStatusElementBasedOnTaskStatus(
                item,
                itemData
              )
            ? css(ACTIVE_BLOCK_STYLE(theme))
              : css(INACTIVE_BLOCK_STYLE(theme))
          }
        >
          {(isOJTEnable || isRoleSpecificActivity) && (
            <>
              <Block
                key={index}
                className={
                  itemData[item]?.CompletionStatus === true
                    ? css({ ...BLOCK_STYLE(theme) })
                    : css(OTHER_BLOCK_STYLE(theme))
                }
              >
                <MentorProficientBlock
                  key={1}
                  index={1}
                  item={item}
                  itemData={itemData}
                  Data={Data}
                  changeDolData={changeDolData}
                  selectedIndex={selectedIndex}
                  rolespermission={rolespermission}
                  handleClick={handleClick}
                  openVerifyCompetencyModal={openVerifyCompetencyModal}
                  categoryData={categoryData}
                  isRoleSpecificActivity={isRoleSpecificActivity}
                  paddingBottom={false}
                ></MentorProficientBlock>
              </Block>
              <Block width={"10px"} display="inline-block" />
            </>
          )}
          <Block
            key={1}
            marginLeft="auto"
            className={
              itemData["LearnerProficientStatus"]?.CompletionStatus === true
                ? css({ ...BLOCK_STYLE(theme) })
                : css(OTHER_BLOCK_STYLE(theme))
            }
          >
            <LearnerProficientBlock
              key={0}
              index={0}
              item={item}
              itemData={itemData}
              Data={Data}
              changeDolData={changeDolData}
              selectedIndex={selectedIndex}
              rolespermission={rolespermission}
              handleClick={handleClick}
              openVerifyCompetencyModal={openVerifyCompetencyModal}
              categoryData={categoryData}
              isRoleSpecificActivity={isRoleSpecificActivity}
            ></LearnerProficientBlock>
          </Block>
        </Block>
      ) : (
        <>
          {(isOJTEnable || isRoleSpecificActivity) && (
            <>
              <Block
                key={index}
                className={
                  itemData[item]?.CompletionStatus === true
                    ? css({ ...BLOCK_STYLE(theme) })
                    : showCommentAndStatusElementBasedOnTaskStatus(
                      item,
                      itemData
                    )
                  ? css(ACTIVE_INDIVISUAL_BLOCK_STYLE(theme))
                    : css(OTHER_BLOCK_STYLE(theme))
                }
              >
                <MentorProficientBlock
                  key={1}
                  index={1}
                  item={item}
                  itemData={itemData}
                  Data={Data}
                  changeDolData={changeDolData}
                  selectedIndex={selectedIndex}
                  rolespermission={rolespermission}
                  handleClick={handleClick}
                  openVerifyCompetencyModal={openVerifyCompetencyModal}
                  categoryData={categoryData}
                  isRoleSpecificActivity={isRoleSpecificActivity}
                  paddingBottom={false}
                ></MentorProficientBlock>
              </Block>
              <TaskBlockArrow vals={true} />
            </>
          )}
          <Block
            key={1}
            marginLeft="auto"
            className={
              itemData["LearnerProficientStatus"]?.CompletionStatus === true
                ? css({ ...BLOCK_STYLE(theme) })
                : showCommentAndStatusElementBasedOnTaskStatus(
                  "LearnerProficientStatus",
                  itemData
                )
              ? css(ACTIVE_INDIVISUAL_BLOCK_STYLE(theme))
                : css(OTHER_BLOCK_STYLE(theme))
            }
          >
            <LearnerProficientBlock
              key={0}
              index={0}
              item={item}
              itemData={itemData}
              Data={Data}
              changeDolData={changeDolData}
              selectedIndex={selectedIndex}
              rolespermission={rolespermission}
              handleClick={handleClick}
              openVerifyCompetencyModal={openVerifyCompetencyModal}
              categoryData={categoryData}
              isRoleSpecificActivity={isRoleSpecificActivity}
            ></LearnerProficientBlock>
          </Block>
        </>
      )}
    </>
  );
}
