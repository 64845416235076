import { useState } from "react";
import { Header } from "components/Header";
import DrawerRouterContainer from "components/newDrawer";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Redirect } from "react-router-dom";
import { Select } from "baseui/select";
import { SELECT_OVERRIDE_STYLE } from "./utils";
import CohortProgress from "./components/CohortProgress";
import LearnerProgress from "./components/LearnerProgress";
import LearnerKahootAggregate from "./components/LearnerKahootAggregate";
import LearnerKahootHeatMap from "./components/LearnerKahootHeatMap";
import LearnerKahootLine from "./components/LearnerKahootLine";
import LearnerKahootCandleStick from "./components/LearnerKahootCandleStick";
import Flex from "./components/Flex";
import Core from "./components/Core";
import "../../../node_modules/react-vis/dist/style.css";
import "./main.scss";
import { useSetState, useTrackedState } from "store/store";
import StudentAPI from "services/requests/student_api";
import KahootScoreAPI from "services/requests/kahootApi";
import { useEffect } from "react";
import getRolesPageVisibility from "../../components/getPageVisibility"
import NestedMenu from "components/nestedMenu";

const MAIN_STYLE = (theme) => ({
  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "40px",
  },
});

const HEADING_STYLE = (theme) => ({
  fontFamily: "Epilogue-Semibold",
  fontSize: "28px",
  lineHeight: "40px",
  color: theme.RAColors?.gray900,
});

const GRID = (theme) => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",

  [theme.mediaQuery.large]: {
    gridTemplateColumns: "repeat(3, 1fr)",
    gridTemplateRows: "repeat(auto, 1fr)",
    gap: "20px",
  },
});

const SELECT_ROOT_STYLE = ({ $theme }) => ({
  width: "100%",
  backgroundColor: "white",
  [$theme.mediaQuery.medium]: {
    width: "200px",
  },
});

const OPTIONS = [
  { label: "Student Data", id: "Student Data" },
  { label: "Kahoot Data", id: "Kahoot Data" },
];

const Dashboard = () => {
  const [css, theme] = useStyletron();

  const setState = useSetState();
  const state = useTrackedState();

  const [option, setOption] = useState([OPTIONS[0]]);

  const role = localStorage.getItem("role") || localStorage.getItem("viewAs");

  const [width, setWidth] = useState(0);

  useEffect(() => {
    let isUnmounted = false;
    localStorage.setItem("currentPage", "admin-dashboard");

    const resizeEvent = window.addEventListener("resize", () => {
      if (!isUnmounted) setWidth(window.innerWidth);
    });

    const fetchStudentOverview = async () => {
      const userEmail = localStorage.getItem("email") || "";

      try {
        setState((prev) => ({
          ...prev,
          isFetching: true,
          isFetchingKahoot: true,
        }));

        const [studentResponse, kahootResponse] = await Promise.all([
          StudentAPI(userEmail),
          KahootScoreAPI(),
        ]);

        if (
          studentResponse.status !== 200 ||
          kahootResponse.status !== 200 ||
          isUnmounted
        )
          return;

        const { students_overview_summary, learner_profile_list } =
          studentResponse.data[0];
        const kahootScore = kahootResponse.data;

        setState((prev) => ({
          ...prev,
          studentOverview: students_overview_summary,
          learners: learner_profile_list,
          kahootScore,
          isFetching: false,
          isFetchTrackData: true,
          isFetchingKahoot: false,
        }));
      } catch (e) {
        if (isUnmounted) return;
        console.log(e);
        setState((prev) => ({
          ...prev,
          studentOverview: {},
          learners: [],
          kahootScore: [],
          isFetching: false,
          isFetchTrackData: false,
          isFetchingKahoot: false,
        }));
      }
    };

    if (!state.kahootScore || !state.learners) fetchStudentOverview();

    return () => {
      isUnmounted = true;
      window.removeEventListener("resize", resizeEvent);
    };
  }, []);

  //page access control - based on role
  var permittedRoles = getRolesPageVisibility("Admin Dashboard");

  if (!permittedRoles.includes(role)) return <Redirect to="/student" />;

  return (
    <>
      <Header />
      <DrawerRouterContainer>
        <main className={`${css(MAIN_STYLE(theme))} dashboard`}>
          <Block
            marginBottom="16px"
            display={["block", "block", "flex"]}
            justifyContent="flex-end"
            alignItems="center"
            margin={"auto"}
          >
            <Block>
              <NestedMenu />
            </Block>
          </Block>
          <Block
            marginBottom="16px"
            display={["block", "block", "flex"]}
            justifyContent="space-between"
            alignItems="center"
          >
            <Block
              as="h3"
              className={css(HEADING_STYLE(theme))}
              data-testid="dashboard-text"
            >
              Dashboard
            </Block>

            <Select
              clearable={false}
              searchable={false}
              options={OPTIONS}
              value={option}
              data-testid="select-dropdown"
              onChange={(params) => setOption(params.value)}
              overrides={{
                Root: { style: SELECT_ROOT_STYLE },
                ControlContainer: {
                  style: SELECT_OVERRIDE_STYLE.ControlContainer,
                },
              }}
            />
          </Block>
          {option[0].id === "Student Data" ? (
            <Block className={css(GRID(theme))} key={width}>
              <CohortProgress />
              <Core />
              <Flex />
              <LearnerProgress />
            </Block>
          ) : (
            <Block
              display="flex"
              flexDirection="column"
              gridGap="20px"
              key={width - 1}
            >
              <LearnerKahootAggregate />
              <LearnerKahootHeatMap />
              <LearnerKahootLine />
              <LearnerKahootCandleStick />
            </Block>
          )}
        </main>
      </DrawerRouterContainer>
    </>
  );
};

export default Dashboard;
