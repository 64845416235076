import axios from "axios";
import { jsURL } from "../config";

const JumpStartData = axios.create({
  baseURL: jsURL
})

JumpStartData.defaults.headers.common = {
  ...(JumpStartData.defaults.headers.common || {}),
  'x-api-key': 'hauWj2023nxi@sis#$20'
}
const FetchCohortData = (email) => JumpStartData.get(`cohort_student_list?email_id=${email}`)

export default FetchCohortData

