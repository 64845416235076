import React, { lazy, Suspense, useState, useEffect, useMemo } from "react";
import { Header } from "components/Header";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import { Redirect } from "react-router-dom";
import DrawerRouterContainer from "components/newDrawer";
import Title from "./components/title";
import { FilterSection } from "./components/filterSection";
import Summary from "./components/summary";
import sortArray from "sort-array";
import { useStateWithCallbackLazy } from "use-state-with-callback";
import DolChecklistService from "services/requests/dol_checklist";
import { COMMON_CARD_STYLE } from "utils/style";
import { useSetState, useTrackedState } from "store/store";
import RolesPermission from "../RolesPermission/getRolesPermission";
import RolesPermissions from "services/requests/rolesPermissions";
import getRolesPageVisibility from "../../components/getPageVisibility"


const MAIN_STYLE = (theme) => ({
  position:"relative",
  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "36px",
  },
});
const ModalComponent = lazy(() =>
  import("../DolChecklist/components/chatModal/index")
);
const VerifyCompetencyModalComponent = lazy(() =>
  import("../DolChecklist/components/verifyCompetency/index")
);
export default function DolChecklist() {
  const [dolAPiRes, setDolAPiRes] = React.useState([]);
  const [dolData, setDolData] = useStateWithCallbackLazy([]);
  const [alldolData, setAllDolData] = React.useState([]);
  const [count, setCount] = React.useState(0);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [selectedLearnerEmail,setSelectedLearnerEmail] = React.useState(0);
  const [callIndivisualApi, setCallIndivisualApi] = React.useState(0);
  const [loader, setLoader] = React.useState(true);
  const [chatModalRow, setChatModalRow] = useState(false);
  const [taskData, setTaskData] = useState(false);
  const [categoriesData, setCategoriesData] = useState(false);
  const [isSpecificActivity, setSpecificActivity] = useState(false);
  const [updatedEmail, setUpdatedEmail] = React.useState();
  const [verifyCompetencyRow, setVerifyCompetencyRow] = useState(false);
  const [css, theme] = useStyletron();
  const [rolespermission, setRolesPermission] = React.useState();
  const setState = useSetState();
  const state = useTrackedState();
  const [datalength, setDataLength] = React.useState(0);
  let compData = [];
  useEffect(() => {
    const userEmail = localStorage.getItem("email");
    rolesPermissions(userEmail,localStorage.getItem("role"))  
    allLearnerData(userEmail);  
    localStorage.setItem("currentPage","competency-sign-off")  
  }, []);


  const allLearnerData = (userEmail) => {
    DolChecklistService("All",localStorage.getItem("role"), "", userEmail)
      .then(function (response) {
        if (response.status === 200) {
          sortArray(response?.data, {
            by: "LastName",
            order: "asc",
          });
          setDolAPiRes(response?.data);
          compData = response?.data;
          setAllDolData(response?.data);      
          if (callIndivisualApi === 0 && count === 0) {          
            const isObjectPresent = compData?.filter((obj) => {    
              if ((obj.EmailId).toLowerCase() === (localStorage.getItem("email")).toLowerCase()) {
                return obj;
              }
            });
            if(localStorage.getItem("viewAs") !== "LEARNER" && localStorage.getItem("role") !== "LEARNER"){
              setDolData(response?.data[0]) //myArrayFiltered[0]
            }          
            var emailId = isObjectPresent.length > 0 ? localStorage.getItem("email") : response?.data[0]?.EmailId //myArrayFiltered[0].EmailId
            getDolChecklistIndividualData(emailId, 0);
          }
         
        }
      })
      .catch((err) => {
        //console.log(err);
        setLoader(false);
      });
  };

  const rolesPermissions = (userEmail,role) => {
    RolesPermissions(userEmail,role)
      .then(function (response) {
        if (response.status === 200) {
          localStorage.setItem(
            "rolesPermission",
            JSON.stringify(response.data)
          );
          const rolename = localStorage.getItem("viewAs") === null ? localStorage.getItem("role") : localStorage.getItem("viewAs")
          const rolesRes = RolesPermission(
            rolename,
            "Competency Sign-off"
          );
          setRolesPermission(rolesRes?.[0]?.items) 
        }
       
      })
      .catch((err) => {
        console.log(err);
      });
}

  const changeDolData = (learnerEmail, index) => {
    setUpdatedEmail(learnerEmail);
    setLoader(true);
    setCallIndivisualApi(index);
    getDolChecklistIndividualData(learnerEmail, index);
  };

  function findArrayElementByTitle(array, EmailId, Progress) {
    return array.find((element) => {
      if (element.EmailId === EmailId) {
        return element.LearnerChecklist?.Progress === Progress;
      }
    });
  }

  const getDolChecklistIndividualData = (learnerEmail, index) => {
    const userEmail = localStorage.getItem("email");
    DolChecklistService("Individual", localStorage.getItem("role"), learnerEmail, userEmail)
      .then(function (response) {
        if (response.status === 200) {
          setDolData(response.data[0], (dolData) => {
            getUpdatedRowData(dolData);
          });
          setSelectedIndex(index);
          setSelectedLearnerEmail(learnerEmail)
          setLoader(false);
          if (
            findArrayElementByTitle(
              compData.length > 0 ? compData : alldolData,
              response.data[0].EmailId,
              response?.data[0].LearnerChecklist?.Progress
            ) === undefined
          ) {
            setCount(1);
            allLearnerData(userEmail);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getUpdatedRowData = (data) => {
    if (isSpecificActivity) {
      let result;
      data?.LearnerChecklist?.RoleSpecificActivities?.filter((obj) => {
        if (obj.TaskID === state?.CompetencyID) {
          result = obj;
        }
      });
      setChatModalRow(result);
    } else {
      let result;
      data?.LearnerChecklist?.Category?.filter((obj) =>
        obj?.Criteria?.filter((criteria) => {
          if (criteria.CriteriaID === state?.CompetencyID) {
            result = criteria;
          }
        })
      );
      setChatModalRow(result);
    }
  };
  const role = localStorage.getItem("viewAs") !== null ? localStorage.getItem("viewAs") : localStorage.getItem("role");
  const userType = useMemo(() => {
    const username = localStorage.getItem("username");
    if ([null, undefined, ""].includes(username)) return null;
    //page access control - based on role
    var permittedRoles = getRolesPageVisibility("Competency Sign-off");
    if (permittedRoles.includes(role))
      return "manager";

    return "member";
  }, []);

  const handleCallback = (updateData) => {
    if (updateData) {
      allLearnerData(localStorage.getItem("email"));
      getDolChecklistIndividualData(updatedEmail, selectedIndex);
    }
  };
  const handleClick = (
    data,
    taskData,
    categoryData,
    isRoleSpecificActivity
  ) => {
    let id = isRoleSpecificActivity ? data?.TaskID : data?.CriteriaID;
    setState((prev) => ({ ...prev, CompetencyID: id }));
    setChatModalRow(data);
    setTaskData(taskData);
    setSpecificActivity(isRoleSpecificActivity);
    setCategoriesData(categoryData);
  };

  function searchtext(val){
    if(val === true){
      setCount(1);
    }
  }
  function closeModal() {
    setChatModalRow(null);
    setState((prev) => ({ ...prev, CompetencyID: null }));
  }
  const openVerifyCompetencyModal = (
    data,
    taskData,
    categoryData,
    isRoleSpecificActivity
  ) => {
    setVerifyCompetencyRow(data);
    setTaskData(taskData);
    setSpecificActivity(isRoleSpecificActivity);
    setCategoriesData(categoryData);
  };
  function closeVerifyCompetencyModal() {
    setVerifyCompetencyRow(null);
  }
  if (!userType) return <Redirect to="/login" />;
  if (userType !== 'manager') return <Redirect to="/student" />;
  return (
    <Block>
      <Header />
      <DrawerRouterContainer>
        <Block className={css(MAIN_STYLE(theme))}>
          <Title exportPdf={dolData} />
          <Block>
            <Block
              display="flex"
              flexDirection={["column", "column", "column", "row"]}
              gridGap={["16px", "16px", "20px"]}
            >
              {localStorage.getItem("role") !== "LEARNER" &&
              localStorage.getItem("viewAs") !== "LEARNER" ? (
                <Block
                  width={["initial", "initial", "initial", "343px"]}
                  display="flex"
                  flexDirection="column"
                  gridGap="20px"
                >
                  <FilterSection
                    Data={dolAPiRes}
                    loader={loader}
                    changeDolData={changeDolData}
                    allLearnerData={allLearnerData}
                    selectedIndex={selectedIndex}
                    selectedLearnerEmail={selectedLearnerEmail}
                    updateData={handleCallback}
                    rolespermission={rolespermission}
                    dataLength={datalength}
                    searchText={searchtext}
                  />
                </Block>
              ) : null}
              <Block flex="1">
                <Block className={css(COMMON_CARD_STYLE(theme))}>
                  <Summary
                    Data={dolData}
                    loader={loader}
                    changeDolData={changeDolData}
                    allLearnerData={allLearnerData}
                    selectedIndex={selectedIndex}
                    updateData={handleCallback}
                    rolespermission={rolespermission}
                    handleClick={handleClick}
                    openVerifyCompetencyModal={openVerifyCompetencyModal}
                  />
                </Block>
              </Block>
            </Block>
          </Block>
        </Block>
      </DrawerRouterContainer>
      <Suspense fallback={<div />}>
        {chatModalRow && (
          <ModalComponent
            data={chatModalRow}
            taskData={taskData}
            categoriesData={categoriesData}
            changeDolData={changeDolData}
            selectedIndex={selectedIndex}
            rolespermission={rolespermission}
            type={"Add_Competency"}
            isRoleSpecificActivity={isSpecificActivity}
            isLearners={false}
            close={() => closeModal()}
          />
        )}
        {verifyCompetencyRow && (
          <VerifyCompetencyModalComponent
            data={verifyCompetencyRow}
            taskData={taskData}
            categoriesData={categoriesData}
            changeDolData={changeDolData}
            selectedIndex={selectedIndex}
            close={() => closeVerifyCompetencyModal()}
            isRoleSpecificActivity={isSpecificActivity}
          />
        )}
      </Suspense>
    </Block>
  );
}
