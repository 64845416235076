import MacrocertificationCoreCyberSecurityFundamentals from "assets/badges/macrocertification_core_cyber_security_fundamentals.svg";
import MacrocertificationSecureSystemsFundamentals from "assets/badges/macrocertification_secure_systems_fundamentals.svg";
import MacrocertificationOperationalFundamentals from "assets/badges/macrocertification_operational_fundamentals.svg";
import MacrocertificationRaCapstone from 'assets/badges/macrocertification_ra_capstone.png'

import MacrocertificationCoreCyberSecurityFundamentalsLarge from "assets/badges-large/macrocertification_core_cyber_security_fundamentals_large.svg";
import MacrocertificationSecureSystemsFundamentalsLarge from "assets/badges-large/macrocertification_secure_systems_fundamentals_large.svg";
import MacrocertificationOperationalFundamentalsLarge from "assets/badges-large/macrocertification_operational_fundamentals_large.svg";
import MacrocertificationRaCapstoneLarge from 'assets/badges-large/macrocertification_ra_capstone_large.png'

import * as React from "react";
import { Block } from "baseui/block";
import { StatefulPopover, TRIGGER_TYPE, PLACEMENT } from "baseui/popover";
import { useStyletron } from "baseui";
import { COMMON_RADIUS } from "utils/style";

const MACRO_IMAGE_MAP = {
  "macrocertification_secure_systems_fundamentals.png": {
    small: MacrocertificationSecureSystemsFundamentals,
    large: MacrocertificationSecureSystemsFundamentalsLarge,
  },
  "macrocertification_operational_fundamentals.png": {
    small: MacrocertificationOperationalFundamentals,
    large: MacrocertificationOperationalFundamentalsLarge,
  },
  "macrocertification_core_cyber_security_fundamentals.png": {
    small: MacrocertificationCoreCyberSecurityFundamentals,
    large: MacrocertificationCoreCyberSecurityFundamentalsLarge
  },
  "macrocertification_ra_capstone.png": {
    small: MacrocertificationRaCapstone,
    large: MacrocertificationRaCapstoneLarge
  }
};

const MacroBadge = ({
  name = "macrocertification_core_cyber_security_fundamentals.png",
  isFaded = false,
  showModal,
}) => {
  const [css] = useStyletron();

  const imageSmall =
    MACRO_IMAGE_MAP[name]?.small || MacrocertificationCoreCyberSecurityFundamentals;
  const imageLarge =
    MACRO_IMAGE_MAP[name]?.large || MacrocertificationCoreCyberSecurityFundamentalsLarge;

  const handleClick = (e) => {
    e.stopPropagation()
    if (showModal) showModal(imageLarge);
  };

  return (
    <StatefulPopover
      content={isFaded ? null : (
        <Block
          paddingLeft="20px"
          paddingRight="20px"
          paddingTop="40px"
          paddingBottom="40px"
          className={css({
            backgroundColor: "white",
            ...COMMON_RADIUS("8px"),
            cursor: "pointer",
          })}
          onClick={handleClick}
        >
          <Block
            as="img"
            src={imageLarge}
            alt="Macro Badge Large"
            width="220px"
            height="220px"
            data-testid="macro-badge-large"
          />
        </Block>
      )}
      triggerType={TRIGGER_TYPE.hover}
      placement={PLACEMENT.rightTop}
      returnFocus
      autoFocus
    >
      <Block
        as="img"
        src={imageSmall}
        alt="Macro Badge"
        width="40px"
        className={css({
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          borderRadius: "4px",
          opacity: isFaded ? 0.5 : 1,
        filter: isFaded ? 'blur(1px)' : 'none',
        })}
        data-testid="macro-badge"
      />
    </StatefulPopover>
  );
};

export default MacroBadge;