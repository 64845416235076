import { BULK_SIGNOFF } from "../apiEndpoint";
import { educationAxios } from "../http/http";
export default function bulkSignoff(
  payload = "",
  email = "",
  learnerEmail='',
  userName =''
) {
    return educationAxios.post(
      `${BULK_SIGNOFF}?user_email=${email}&learner_email=${learnerEmail}&user_name=${userName}`,
      payload
    );
  }
