import React from "react";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import ForwardArrowIcon from "assets/images/forward-arrow.svg";

const FORWARD_ARROW_STYLE = (theme) => ({
  width: "20px",
  height: "20px",
  objectFit: "contain",
  display: "inline-block",
  [theme.mediaQuery.small]: {
    transform: "rotate(90deg)",
    marginLeft: "auto",
    marginRight: "auto",
  },
  [theme.mediaQuery.medium]: {
    marginLeft: "0px",
    transform: "none",
  },
  [theme.mediaQuery.large]: {
    marginLeft: "0px",
    transform: "none",
  },
});

export default function TaskBlockArrow(vals) {
  const [css, theme] = useStyletron();
  return (
    <Block
      as="img"
      src={ForwardArrowIcon}
      alt="User Avatar"
      className={css(FORWARD_ARROW_STYLE(theme))}
    />
  );
}
