import { Block } from "baseui/block";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  XYPlot,
  XAxis,
  YAxis,
  Hint,
  HeatmapSeries,
  LabelSeries,
  ContinuousColorLegend
} from "react-vis";
import { useStyletron } from "baseui";
import { scaleLinear } from "d3-scale";
import {
  TILE_STYLE,
  TITLE_STYLE,
  HINT_STYLE,
  HINT_TEXT_STYLE,
  VISUALIZATION_HEIGHT,
  MOBILE_SCREEN_BREAKPOINT,
} from "../utils";
import { Learner_Kahoot } from "resources/constants/strings";
import { useTrackedState } from "store/store";
import { Skeleton } from "baseui/skeleton";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";

const LearnerKahootHeatMap = () => {
  const [value, setValue] = useState(false);
  const [width, setWidth] = useState();
  const [showScore, setShowScore] = useState(true)

  const [css, theme] = useStyletron();
  const parent = useRef();

  const state = useTrackedState();

  const height = VISUALIZATION_HEIGHT;
  const isMobileScreen = width < MOBILE_SCREEN_BREAKPOINT;

  const lessonShortNames = useMemo(() => {
    if (!state.kahootScore) return [];

    return state.kahootScore.map((lesson) => lesson.lessonnameshort);
  }, [state.kahootScore]);

  const emails = useMemo(() => {
    if (!state.kahootScore) return [];

    return state.kahootScore.reduce((acc, lesson) => {
      const emailsInLesson = lesson.userscores.map((i) => i.emailid);

      return [...new Set([...acc, ...emailsInLesson])];
    }, []);
  }, [state.kahootScore]);

  const data = useMemo(() => {
    if (!state.kahootScore) return [];

    return state.kahootScore.reduce((acc, lesson, index) => {
      const { userscores, lessonnameshort, ...rest } = lesson;
      const scoresObject = userscores.reduce((s, score) => {
        s[score.emailid] = score;
        return s;
      }, {});

      const scoreByLesson = emails.map((email) => ({
        x: lessonnameshort,
        y: email,
        value: scoresObject[email]?.score || 0,
        ...(scoresObject[email] || {}),
        ...rest,
      }));

      return [...acc, ...scoreByLesson];
    }, []);
  }, [state.kahootScore, emails]);

  const { min, max } = data.reduce(
    (acc, row) => ({
      min: Math.min(acc.min, row.value),
      max: Math.max(acc.max, row.value),
    }),
    { min: Infinity, max: -Infinity }
  );

  const scaleColor = scaleLinear()
    .domain([min, (min + max) / 2, max])
    .range(["#574384", "#1D86F6", "#A8FCFF"]);

  const getTickFormat = (label) => {
    if (label.length <= 32) return label;
    return label.slice(0, 31).concat("..");
  };

  useEffect(() => {
    if (parent) setWidth(parent.current.clientWidth);
  }, []);

  console.log(2, data)

  if (isMobileScreen) return null;

  return (
    <Block className={css(TILE_STYLE(theme))}>
      <Block
        display="flex"
        flexDirection={["column", "column", "row"]}
        gridGap="20px"
        justifyContent="space-between"
        marginBottom="20px"
      >
        <Block className={css({ ...TITLE_STYLE, marginBottom: 0 })} data-testid="learner-kahoot-text">
          {Learner_Kahoot}
        </Block>
        <Checkbox
          checked={showScore}
          data-testid="kahoot-checkbox"
          onChange={e => setShowScore(e.target.checked)}
          labelPlacement={LABEL_PLACEMENT.right}
        >
          Show Learner Score
        </Checkbox>
      </Block>
      <Block
        ref={parent}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gridGap="20px"
        className="heatmap"
      >
        {state.isFetchingKahoot && width ? (
          <Skeleton width={`${width}px`} height={`${height}px`} />
        ) : (
          <>
            <XYPlot
              xType="ordinal"
              xDomain={lessonShortNames}
              yType="ordinal"
              yDomain={emails}
              margin={{
                top: isMobileScreen ? 0 : 60,
                left: isMobileScreen ? 0 : 200,
              }}
              width={width || 0}
              height={height}
            >
              {!isMobileScreen && (
                <XAxis
                  style={{ text: { textAnchor: "start" } }}
                  orientation="top"
                  tickLabelAngle={-90}
                />
              )}
              {!isMobileScreen && (
                <YAxis
                  style={{ text: { textAnchor: "end" } }}
                  tickFormat={getTickFormat}
                />
              )}
              <HeatmapSeries
                colorType="literal"
                getColor={(d) => scaleColor(d.value)}
                data={data}
                onValueMouseOver={(v) => setValue(v)}
                onSeriesMouseOut={(v) => setValue(false)}
              />
              {showScore && (
                <LabelSeries
                  style={{ pointerEvents: "none", fontSize: 12 }}
                  data={data}
                  labelAnchorX="middle"
                  labelAnchorY="middle"
                  getLabel={(d) => `${d.value}`}
                />
              )}
              {value !== false && (
                <Hint value={value}>
                  <Block className={css(HINT_STYLE)}>
                    <Block className={css(HINT_TEXT_STYLE)}>
                      Learner: {value.y}
                    </Block>
                    <Block className={css(HINT_TEXT_STYLE)}>
                      Lesson: {value.lessonname}
                    </Block>
                    <Block className={css(HINT_TEXT_STYLE)}>
                      Course: {value.coursename}
                    </Block>
                    <Block className={css(HINT_TEXT_STYLE)}>
                      Quiz: {value.quizname}
                    </Block>
                    <Block className={css(HINT_TEXT_STYLE)}>
                      Subject: {value.quizsubject}
                    </Block>
                    <Block className={css(HINT_TEXT_STYLE)}>
                      Score: {value.value}
                    </Block>
                    <Block className={css(HINT_TEXT_STYLE)}>
                      Correct Answers: {value.correctanswers}
                    </Block>
                    <Block className={css(HINT_TEXT_STYLE)}>
                      Incorrect Answers: {value.incorrectanswers}
                    </Block>
                  </Block>
                </Hint>
              )}
            </XYPlot>
            <ContinuousColorLegend
              width={300}
              startTitle="0"
              midTitle="50"
              endTitle="100"
              startColor="#574384"
              midColor="#1D86F6"
              endColor="#A8FCFF"
            />
          </>
        )}
      </Block>
    </Block>
  );
};

export default LearnerKahootHeatMap;
