import { CHECKLIST_MANAGER } from "../apiEndpoint";
import { educationAxios } from "../http/http";
export default function checkListManagerFunction(
  payload = "",
  email = "",
  learnerEmail = "",
  role = ""
) {
  return educationAxios.post(
    `${CHECKLIST_MANAGER}?user_email=${email}&learner_email=${learnerEmail}&role=${role}`,
    payload
  );
}
