import React,{useMemo} from "react";
import { Header } from "components/Header";
import DrawerRouterContainer from "components/newDrawer";
import { useStyletron } from "baseui";
import JobList from "./JobList";
import Title from "./Components/title";
import { fetchJobRoles } from "services/requests/job_role";
import { useSetState } from "store/store";
import { useEffect } from "react";
import { fetchDOLTemplate } from "services/requests/dol_template";
import getRolesPageVisibility from "../../components/getPageVisibility"
import { Redirect} from "react-router-dom";

const MAIN_STYLE = (theme) => ({
  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "40px",
  },
});

const Jobs = ({ match }) => {
  const [css, theme] = useStyletron();
  const setState = useSetState();

  useEffect(() => {
    let isMounted = true;
    localStorage.setItem("currentPage","job-roles")
    fetchRoles(isMounted);
    return () => {
      isMounted = false;
    };
  }, []);

  const fetchRoles = async (isMounted) => {
    try {
      const userEmail = localStorage.getItem("email") || "";

      const [jobResponse, dolTemplatesResponse] = await Promise.all([
        fetchJobRoles(userEmail,''),
        fetchDOLTemplate(userEmail)
      ]);

      if (jobResponse.status !== 200 || dolTemplatesResponse.status !== 200 || !isMounted) return;
      setState((prev) => ({
        ...prev,
        jobRoles: jobResponse.data,
        dolTemplates: [...dolTemplatesResponse.data.reverse()]
      }));
    } catch (e) {}
  };

  const handleCallback = (childData) => {
    if (childData === "Created" || childData === "Deleted") {
      fetchRoles(true);
    }
  };


  //page access control - based on role
  var role = localStorage.getItem("viewAs") !== null ? localStorage.getItem("viewAs") : localStorage.getItem("role");
  var permittedRoles = getRolesPageVisibility("Job Roles");
  if (!permittedRoles.includes(role)) return <Redirect to="/student" />;

  return (
    <>
      <Header />
      <DrawerRouterContainer>
        <main className={css(MAIN_STYLE(theme))}>
          <Title parentCallback={handleCallback} />
          <JobList jobAction={handleCallback}/>
        </main>
      </DrawerRouterContainer>
    </>
  );
};

export default Jobs;
