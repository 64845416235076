import { StatefulPopover, PLACEMENT } from "baseui/popover";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Modal, ModalBody, ModalFooter, SIZE, ROLE } from "baseui/modal";
import { Button } from "baseui/button";
import { useState } from "react";
import {
  COMMON_BORDER_COLOR,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_STYLE,
  COMMON_RADIUS,
} from "utils/style";

const ITEM = {
  cursor: "pointer",
  paddingLeft: "13px",
  paddingRight: "13px",
  paddingTop: "13px",
  paddingBottom: "13px",
  ":hover": {
    backgroundColor: "#F3F3F3",
  },
};
const SEPRATOR = {
  borderBottom: "2px solid #e0e0e0",
};
const COMMON_BUTTON_STYLE = {
  lineHeight: "22px",
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  fontFamily: "Manrope-Semibold",
  width: "122px",
};

const SAVE_BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_BUTTON_STYLE,
  ...COMMON_BORDER_COLOR($theme.RAColors?.red700),
  backgroundColor: $theme.RAColors?.red700,
  ":hover": { backgroundColor: $theme.RAColors?.red700 },
  color: "white",
  ":focus": { "box-shadow": "none" },
});

const CANCEL_BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_BUTTON_STYLE,
  ...COMMON_BORDER_COLOR($theme.RAColors?.purple),
  backgroundColor: "white",
  ":hover": { backgroundColor: $theme.RAColors?.gray300 },
  color: $theme.RAColors?.purple,
});

const MODAL_OVERRIDE_STYLE = {
  Dialog: { ...COMMON_RADIUS("8px") },
  Close: {
    ":focus": { outline: "none" },
  },
  DialogContainer: {
    maxHeight: "100vh",
  },
};

const Actions = ({
  children,
  onDelete,
  exportTemplate,
  type = "template",
  setShowDuplicateConfirm,
}) => {
  const [css] = useStyletron();
  const [showPopup, setShowPopup] = useState(false);
  const confirm = () => {
    setShowPopup(false);
    onDelete && onDelete();
  };
  return (
    <>
      <StatefulPopover
        content={({ close }) => (
          <Block backgroundColor="white">
            {type === "template" && (
              <>
                <Block
                  className={css(ITEM)}
                  onClick={(e) => {
                    e.stopPropagation();
                    // duplicate && duplicate()
                    setShowDuplicateConfirm(true);
                    close();
                  }}
                  data-testid="actions-duplicate"
                >
                  Duplicate
                </Block>
                <Block
                  className={css(ITEM)}
                  onClick={(e) => {
                    e.stopPropagation();
                    exportTemplate();
                    close();
                  }}
                  data-testid="actions-export"
                >
                  Export
                </Block>
              </>
            )}
            <Block className={css(SEPRATOR)} />
            <Block
              className={css(ITEM)}
              onClick={() => {
                setShowPopup(true);
                close();
              }}
              data-testid="actions-delete"
            >
              Delete
            </Block>
          </Block>
        )}
        placement={PLACEMENT.bottomRight}
        autoFocus
      >
        {children}
      </StatefulPopover>
      <Modal
        onClose={() => setShowPopup(false)}
        closeable
        isOpen={showPopup}
        animate
        size={SIZE.default}
        role={ROLE.dialog}
        overrides={{
          Dialog: { style: MODAL_OVERRIDE_STYLE.Dialog },
          Close: { style: MODAL_OVERRIDE_STYLE.Close },
          DialogContainer: { style: MODAL_OVERRIDE_STYLE.DialogContainer },
        }}
      >
        <ModalBody className={css({ margin: "24px !important" })}>
          <Block className={css({ fontFamily: "Manrope" })}>
            Are you sure you want to delete this {type}?
          </Block>
        </ModalBody>
        <ModalFooter>
          <Block display="flex" justifyContent="flex-end" gridGap="16px">
            <Button
              overrides={{
                BaseButton: { style: SAVE_BUTTON_OVERRIDE_STYLE },
              }}
              onClick={confirm}
              data-testid="delete-confirm"
            >
              Delete
            </Button>
            <Button
              overrides={{
                BaseButton: { style: CANCEL_BUTTON_OVERRIDE_STYLE },
              }}
              onClick={() => setShowPopup(false)}
              data-testid="delete-cancel"
            >
              Cancel
            </Button>
          </Block>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Actions;
