import React, { useEffect } from "react";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import {
  COMMON_BORDER_COLOR,
  COMMON_BORDER_WIDTH,
  COMMON_RADIUS,
} from "utils/style";
import { Select } from "baseui/select";
import { useSetState, useTrackedState } from "store/store";
import getCompetencyChecklist from "services/requests/get_competency_checklist.js";
import { sortAZSelectOptions } from "utils/select";

const COMMON_TEXT_STYLE = {
  fontFamily: "Manrope",
  lineHeight: "20px",
  fontWeight: 700,
};
const SEPARATOR = {
  margin: "30px 0px",
};
const HEADING_TEXT_STYLE = (theme) => ({
  ...COMMON_TEXT_STYLE,
  fontSize: "24px",
  width: "max-content",
  color: theme.RAColors?.black,
});
const SELECT_OVERRIDE_STYLE = {
  Root: ({ $theme }) => ({ width: "100%" }),
  ControlContainer: ({ $theme, $error }) => ({
    backgroundColor: "none",
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_COLOR(
      $error ? $theme.RAColors?.red600 : $theme.RAColors?.gray200
    ),
    ...COMMON_BORDER_WIDTH("1px"),
    color: $theme.RAColors?.gray900,
  }),
};

export default function DOLCompetency({
  validation,
  title,
  moduleName,
  fieldName = "Trait Name",
}) {
  const [css, theme] = useStyletron();
  const [dolCompetencyOptions, setDolCompetencyOptions] = React.useState([]);
  const setState = useSetState();
  const state = useTrackedState();
  const userEmail = localStorage.getItem("email") || "";

  useEffect(() => {
    try {
      const fetchOption = async () => {
        const response = await getCompetencyChecklist(userEmail);
        let dolCompetencyArray = [];
        response?.data?.forEach((element, index) => {
          let obj = {};
          obj.label = element.TemplateName + ' - v' +element.TemplateVersion;
          obj.id = index + 1;
          obj.value = element.TemplateName
          obj.templateID = element.TemplateId;
          dolCompetencyArray.push(obj);
        });
        setDolCompetencyOptions(dolCompetencyArray);
      };
      fetchOption();
    } catch (e) {
      //console.log(e);
    }
  }, []);

  const changeValue = (tempId) => {
    setState(
      (prev) => ({
        ...prev,
        jobObject: {
          ...prev.jobObject,
          TemplateID : tempId
        },
      })
    );
  };

  return (
    <Block>
      <Block width={["100%", "100%", "100%", "300px"]}>
        <Block className={css(HEADING_TEXT_STYLE(theme))} marginBottom="30px" data-testid="title_text">
          {title}
        </Block>
        <Block display="flex" flexDirection="column" gridGap="24px">
          <Block>
            <Block
              display="flex"
              alignItems="center"
              gridGap="20px"
              data-testid="trait_name_block"
            >
              <Select
              maxDropdownHeight="300px"
                clearable={false}
                searchable={false}
                options={sortAZSelectOptions(dolCompetencyOptions)}
                placeholder="Select..."
                data-testid="trait_name_input"
                value={dolCompetencyOptions.filter(d => d.templateID === state.jobObject?.TemplateID)}
                onChange={(params) =>
                  changeValue(params.value[0].templateID)
                }
                overrides={{
                  Root: { style: SELECT_OVERRIDE_STYLE.Root },
                  ControlContainer: {
                    style: SELECT_OVERRIDE_STYLE.ControlContainer,
                  },
                }}
                error={validation?.TemplateId}
              />
            </Block>
          </Block>
        </Block>
      </Block>
      <Block className={css(SEPARATOR)} />
    </Block>
  );
}
