import * as React from "react";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { useSetState, useTrackedState } from "store/store";
import RolesPermissionsUpdate from "../../services/requests/rolesPermissionUpdate";
import { Button } from "baseui/button";
import {
  COMMON_RADIUS,
  COMMON_BORDER_COLOR,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_STYLE,
} from "utils/style";
import {
  TableExport,
  TableHeader,
  TableRead,
  TableWrite,
  TableActions,
} from "./table";

const TABLE_OVERRIDE_STYLE = {
  Root: ({ $theme }) => ({
    //...COMMON_RADIUS("8px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    ...COMMON_BORDER_STYLE("solid"),
    borderRadius: "0 0 8px 8px",
  }),
  NameCell: { width: "250px", maxWidth: "250px", minWidth: "250px" },
  TrackCell: { width: "400px", maxWidth: "400px", minWidth: "250px" },
  ScoreCell: { width: "400px", maxWidth: "400px", minWidth: "250px" },
  FlexScoreCell: { width: "200px", maxWidth: "200px", minWidth: "200px" },
  StatusCell: {
    width: "122px",
    maxWidth: "150px",
    minWidth: "122px",
    textAlign: "right",
    position: "relative",
  },
};

const TABLE_CUSTOM_CELL_STYLE = {
  ...TABLE_OVERRIDE_STYLE.TrackCell,
  cursor: "pointer",
  fontSize: "14px",
  lineHeight: "20px",
  padding: "16px",
  borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
};

export const SAVE_BTN_STYLE = ($theme) => ({
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  ...COMMON_BORDER_COLOR($theme.RAColors?.blue700),
  width: "max-content",
  paddingLeft: "24px",
  paddingRight: "24px",
  paddingBottom: "10px",
  paddingTop: "10px",
  fontFamily: "Manrope-Bold",
  fontStyle: "normal",
  fontSize: "16px",
  fontWeight: "700",
  justifyContent: "center",
  alignItems: "center",
  lineHeight: "22px",
  marginTop: "20px",
  ...COMMON_RADIUS("8px"),
  marginBottom: "0px",
  backgroundColor: $theme.RAColors?.blue700,
  color: "#FFFFFF",
  ":hover": {
    backgroundColor: $theme.RAColors?.blue800,
  },
  [$theme.mediaQuery.small]: {
    width: "100%",
    marginBottom: "12px",
  },
  [$theme.mediaQuery.medium]: {
    width: "initial",
    marginBottom: "0px",
  },
});

export default function TableData(props) {
  const [css, theme] = useStyletron();
  const state = useTrackedState();
  const [activeKey, setActiveKey] = React.useState("2");
  const [data, setData] = React.useState(props.dataValues);
  const [showSave, setShowSave] = React.useState(false);
  props.updatedData("");
  const updateState = (val) => {
    const newState = data.map((obj) => {
      //  if id equals 2 replace object
      if (obj.Action_name === val.Action_name) {
        return val;
      }

      //  otherwise return object as is
      return obj;
    });
    setData(newState);
  };

  const saveData = () => {
    var object = {};
    object.page = props.obj.page;
    object.items = data;
    object.role = props.roleVal;
    RolesPermissionsUpdate(localStorage.getItem("email"), object)
      .then(function (response) {
        if (response.status === 200) {
          setShowSave(false);
          props.updatedData("Updated");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSave = (val) => {
    setShowSave(true);
    updateState(val);
  };
  return (
    <Block>
      <TableBuilder
        data={data}
        overrides={{
          Root: { style: TABLE_OVERRIDE_STYLE.Root },
          TableBodyRow: {
            component: ({ children }) => (
              <tr data-testid="student-table-row">{children}</tr>
            ),
          },
        }}
        isLoading={state.isFetching || false}
        emptyMessage="No data"
      >
        <TableBuilderColumn
          header={
            <TableHeader
              name={"ACTIONS"}
              //   sorted={sortColumn}
              //   asc={sortAsc}
              //   onSort={onSort}
            />
          }
          overrides={{
            TableHeadCell: { style: TABLE_OVERRIDE_STYLE.FlexScoreCell },
            TableBodyCell: { style: TABLE_OVERRIDE_STYLE.FlexScoreCell },
          }}
        >
          {(row) => <TableActions row={row} />}
        </TableBuilderColumn>
        {/* <TableBuilderColumn
          header={
            <TableHeader
              name={"View"}
            //   sorted={sortColumn}
            //   asc={sortAsc}
            //   onSort={onSort}
            />
          }
          overrides={{
            TableHeadCell: { style: TABLE_OVERRIDE_STYLE.NameCell },
            TableBodyCell: { style: TABLE_OVERRIDE_STYLE.NameCell },
          }}
        >
          {(row) => <TableRead row={row} onSelectCheckbox={handleSave}/>}
        </TableBuilderColumn> */}
        <TableBuilderColumn
          header={
            <TableHeader
              name={"ENABLE"}
              //   sorted={sortColumn}
              //   asc={sortAsc}
              //   onSort={onSort}
            />
          }
          // overrides={{
          //   TableHeadCell: { style: TABLE_OVERRIDE_STYLE.TrackCell },
          //   TableBodyCell: {
          //     component: ({ $row, children, ...rest }) => (
          //       <td
          //         className={css(TABLE_CUSTOM_CELL_STYLE)}

          //       >
          //         {children}
          //       </td>
          //     ),
          //   },
          // }}
          overrides={{
            TableHeadCell: { style: TABLE_OVERRIDE_STYLE.ScoreCell },
            TableBodyCell: { style: TABLE_OVERRIDE_STYLE.ScoreCell },
          }}
        >
          {(row) => <TableWrite row={row} onSelectCheckbox={handleSave} roleVal={props.roleVal}/>}
        </TableBuilderColumn>
        {/* <TableBuilderColumn
          header={
            <TableHeader
              name={"Execute"}
            //   sorted={sortColumn}
            //   asc={sortAsc}
            //   onSort={onSort}
            />
          }
          overrides={{
            TableHeadCell: { style: TABLE_OVERRIDE_STYLE.ScoreCell },
            TableBodyCell: { style: TABLE_OVERRIDE_STYLE.ScoreCell },
          }}
        >
          {(row) => <TableExport row={row}  onSelectCheckbox={handleSave} />}
        </TableBuilderColumn> */}
      </TableBuilder>
      <Block>
        {showSave ? (
          <Button
            type="submit"
            onClick={saveData}
            overrides={{ BaseButton: { style: SAVE_BTN_STYLE(theme) } }}
          >
            Save
          </Button>
        ) : null}
      </Block>
    </Block>
  );
}
