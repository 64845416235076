import { Block } from "baseui/block";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalBarSeries,
  Hint,
  LineSeries,
  DiscreteColorLegend,
} from "react-vis";
import { useStyletron } from "baseui";
import {
  TILE_STYLE,
  TITLE_STYLE,
  HINT_STYLE,
  HINT_TEXT_STYLE,
  VISUALIZATION_HEIGHT,
  MOBILE_SCREEN_BREAKPOINT,
} from "../utils";
import { Learner_Kahoot_Aggregate_Accuracy } from "resources/constants/strings";
import { useTrackedState } from "store/store";
import { Skeleton } from "baseui/skeleton";

const ITEMS = [{ color: "rgb(0, 99, 255)", title: "Total Kahoot Score" }];

const LearnerKahootAggregate = () => {
  const [css, theme] = useStyletron();

  const [value, setValue] = useState(false);
  const [width, setWidth] = useState(0);
  const parent = useRef();

  const state = useTrackedState();

  const height = VISUALIZATION_HEIGHT;
  const isMobileScreen = width < MOBILE_SCREEN_BREAKPOINT;

  const scores = useMemo(() => {
    if (!state.kahootScore) return []

    return state.kahootScore.reduce((acc, item) => {
      const { userscores } = item;
  
      userscores.forEach((score) => {
        const { emailid } = score;
        if (!acc[emailid]) acc[emailid] = 0;
  
        acc[emailid] += score.kahootscore;
      });
  
      return acc;
    }, {});
  }, [state.kahootScore])

  const data = Object.entries(scores).map(([email, score]) => ({
    y: email,
    x: score,
  }));

  const xDomain = [0, Math.max(...Object.values(scores)) * 1.2];

  const average = useMemo(() => {
    const sum = Object.values(scores).reduce((a, b) => a + b, 0);
    const avg = Math.round(sum / Object.values(scores).length) || 0;

    return data.map((d) => ({ ...d, x: avg }));
  }, [scores, data]);

  const getTickFormat = (label) => {
    const MAX_LENGTH = isMobileScreen ? 10 : 31
    if (label.length <= MAX_LENGTH) return label;
    return label.slice(0, MAX_LENGTH - 1).concat("..");
  };

  useEffect(() => {
    if (parent) setWidth(parent.current.clientWidth);
  }, []);

  return (
    <Block className={css(TILE_STYLE(theme))}>
      <Block className={css(TITLE_STYLE)} data-testid="learner-kahoot-aggregate">
        {Learner_Kahoot_Aggregate_Accuracy}
      </Block>
      <Block
        ref={parent}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gridGap="20px"
        className="kahoot-aggregate"
      >
        {state.isFetchingKahoot ? (
          <Skeleton width={`${width}px`} height={`${height}px`} />
        ) : (
          <>
            <XYPlot
              width={width}
              height={height}
              stackBy="x"
              yType="ordinal"
              margin={{ left: isMobileScreen ? 80 : 250 }}
              xDomain={xDomain}
            >
              <VerticalGridLines />
              <XAxis
                className="kahoot-aggregate-x"
                tickFormat={(v) => v / 1000 + "K"}
              />
              <YAxis style={{ text: { textAnchor: "end" } }} tickFormat={getTickFormat} />
              <HorizontalBarSeries
                data={data}
                onValueMouseOver={(v) => setValue(v)}
                onSeriesMouseOut={() => setValue(false)}
                color="rgb(0, 99, 255)"
                barWidth={0.4}
              />
              <LineSeries
                data={average}
                style={{ stroke: "rgba(228, 229, 231)", strokeWidth: "3px" }}
              />
              {value && (
                <Hint value={value}>
                  <Block className={css(HINT_STYLE)}>
                    <Block className={css(HINT_TEXT_STYLE)}>
                      Learner: {value.y}
                    </Block>
                    <Block className={css(HINT_TEXT_STYLE)}>
                      Total Kahoot Score: {value.x}
                    </Block>
                  </Block>
                </Hint>
              )}
            </XYPlot>
            <DiscreteColorLegend orientation="horizontal" items={ITEMS} />
          </>
        )}
      </Block>
    </Block>
  );
};

export default LearnerKahootAggregate;
