import axios from "axios";
import { jsURL } from "../config";

const Email = process.env.REACT_APP_EMAIL_COHORT
const JumpStartData = axios.create({
  baseURL: jsURL
})

JumpStartData.defaults.headers.common = {
  ...(JumpStartData.defaults.headers.common || {}),
  'x-api-key': '#JSk$zH68$!9093'
}

//export const fetchJSData = (email,role) => JumpStartData.get(`cohort_student_data?email_id=${email}&role=${role}`)
const fetchCohortData = () => JumpStartData.get(`master/all_cohort_student_list?email_id=${Email}&role=admin`)

export default fetchCohortData;
