import { Block } from "baseui/block";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalBarSeries,
  LabelSeries,
  HorizontalGridLines
} from "react-vis";
import { useStyletron } from "baseui";
import {
  TILE_STYLE,
  TITLE_STYLE,
} from "../utils";
import { useTrackedState } from "store/store";
import { Skeleton } from "baseui/skeleton";
import { Core_Scores } from "resources/constants/strings";
import { VISUALIZATION_HEIGHT, MOBILE_SCREEN_BREAKPOINT } from "../utils";

const STEP = 1000;

const Core = () => {
  const [css, theme] = useStyletron();

  const [width, setWidth] = useState(0);
  const parent = useRef();

  const state = useTrackedState();

  const height = VISUALIZATION_HEIGHT
  const isMobileScreen = width < MOBILE_SCREEN_BREAKPOINT

  const progress = useMemo(() => {
    if (!state.learners) return [];

    const filteredLearners = state.learners.filter(
      (learner) => learner.last_name.toLowerCase() !== "learner"
    );

    return filteredLearners.map(
      (learner) => learner.learner_radar_skills.skill_score
    );
  }, [state.learners]);

  const data = useMemo(() => {
    const max = Math.max(...progress) || 0;
    const min = Math.min(...progress) || 0;

    const slices = Math.ceil(max / STEP);

    let d = [];
    for (let i = Math.floor(min / STEP); i <= slices; i++) {
      const floor = STEP * i;
      const ceiling = STEP * (i + 1);
      d.push({
        x: `${floor} - ${ceiling - 1}`,
        y: progress.filter((i) => i >= floor && i < ceiling).length,
      });
    }

    return d;
  }, [progress]);

  const getLabel = d => {
    const value = d.y - 1

    if (!value) return ''

    if (isMobileScreen) return value

    return `${value} Learner${value > 1 ? 's' : ''}`
  }

  useEffect(() => {
    if (parent) setWidth(parent.current.clientWidth);
  }, []);

  return (
    <Block
      className={css({
        ...TILE_STYLE(theme),
        gridColumn: "span 3",
      })}
    >
      <Block className={css(TITLE_STYLE)} data-testid="core-score">{Core_Scores}</Block>
      <Block
        ref={parent}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gridGap="20px"
        className="core-scores"
      >
        {state.isFetching ? (
          <Skeleton width={`${width}px`} height={`${height}px`} />
        ) : (
          <XYPlot
            width={width}
            height={height}
            xType="ordinal"
            xDistance={0}
            margin={{ left: 40, right: 40 }}
          >
            <HorizontalGridLines />
            <XAxis style={{ text: { textAnchor: "middle", display: isMobileScreen ? 'none': 'block' } }} />
            <YAxis />
            <VerticalBarSeries
              barWidth={0.8}
              data={data}
              color="rgb(0, 99, 255)"
            />
            <LabelSeries
              data={data.map((d) => ({ ...d, y: d.y + 1 }))}
              getLabel={getLabel}
              className="core-scores-label"
            />
          </XYPlot>
        )}
      </Block>
    </Block>
  );
};

export default Core;
