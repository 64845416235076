import { GRADE_ADDS } from "../apiEndpoint";
import { educationAxios } from "../http/http";
export default function gradeAdds(
  payload = "",
  email = "",
) {
    return educationAxios.post(
      `${GRADE_ADDS}?user_email=${email}`,
      payload
    );
  }
