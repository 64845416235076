import { useStyletron } from "baseui";
import React, { useEffect, useState } from "react";
import { Block } from "baseui/block";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useSetState, useTrackedState } from "store/store";
import { Panel, Accordion } from "baseui/accordion";
import { TableBuilder, TableBuilderColumn, Table } from "baseui/table-semantic";
import { Input } from "baseui/input";
import SearchIcon from "baseui/icon/search";
import { TableHeader } from "./Components/table";
import Actions from "./Components/actions";
import {
  COMMON_RADIUS,
  COMMON_BORDER_COLOR,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_STYLE,
  COMMON_PADDING,
} from "utils/style";
import ToggleIcon from "components/toggleIcon";
import { deleteJobRole,createJobRole } from "services/requests/job_role";
import createNewJobRole from "services/requests/createNewJobRole";
import DeleteRejection from "components/deleteRejection";

const INPUT_OVERRIDE_STYLE = {
  Input: ({ $theme }) => ({
    backgroundColor: "none",
    color: $theme.RAColors?.gray900,
  }),
  Root: ({ $theme }) => ({
    backgroundColor: "none",
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    width: "100%",
  }),
  StartEnhancer: ({ $theme }) => ({
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: "#fff",
  }),
};

const ACCORDION_HEADER_STYLE = {
  ...COMMON_PADDING("0px"),
  fontFamily: "Manrope-Bold",
  fontSize: "18px",
  lineHeight: "43px"
};
const ACCORDION_HEADER_STYLE_NO_DATA = {
  ...COMMON_PADDING("0px"),
  fontFamily: "Manrope-Bold",
  fontSize: "18px",
  lineHeight: "43px",
  //display: "none"
};

const ACCORDION_CONTENT_STYLE = ({ $theme }) => ({
  paddingLeft: "0px",
  paddingRight: "0px",
  paddingTop: "20px",
  paddingBottom: "0px",
});

const NAME_STYLE = (theme) => ({
  fontFamily: "Epilogue",
  color: theme.RAColors?.black,
  fontSize: "14px",
  lineHeight: "19px",
});
const TABLE_OVERRIDE_STYLE_HEAD ={
  backgroundColor: "transparent"
}
const TABLE_OVERRIDE_STYLE = {
  Root: ({ $theme }) => ({
    ...COMMON_RADIUS("8px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    ...COMMON_BORDER_STYLE("solid"),
  }),
  NameCell: { width: "40%", maxWidth: "40%", minWidth: "200px" },
  CodeCell: { width: "20%", maxWidth: "20%", minWidth: "80px" },
  FunctionCell: { width: "25%", maxWidth: "25%", minWidth: "150px" },
  StatusCell: { width: "10%", maxWidth: "10%", minWidth: "70px" },
  ActionCell: { width: "5%", maxWidth: "5%", minWidth: "50px" },
};

const DETAILS_CARD_STYLE = (theme) => ({
  backgroundColor: "white",
  borderRadius: "8px",
  height: "initial",
  marginTop: "16px",
  border: `1px solid ${theme.RAColors?.gray200}`,
  [theme.mediaQuery.small]: {
    padding: "8px",
  },
  [theme.mediaQuery.medium]: {
    padding: "20px",
  },
});

const DEFAULT_JOB_VALIDATION = {
  RoleName: false,
  Function: false,
  Description: false,
  Department: false,
  RoleCode: false,
  JobGroup: false,
  TemplateId: false
};

const JobList = ({ jobAction }) => {
  const [css, theme] = useStyletron();
  const state = useTrackedState();
  const history = useHistory();
  const setState = useSetState();

  const [sort, setSort] = useState({});
  const [searchString, setSearchString] = useState("");
  const [showDeleteRejection, setShowDeleteRejection] = useState(false)
  const [jobValidation, setJobValidation] = React.useState({
    ...DEFAULT_JOB_VALIDATION,
  });


  const COLUMNS = ['Job Name', 'Job Code', 'Function', 'No. of Users', 'Status', 'Action'];

  const goToJobEdit = (data) => {
    if (
      data.Status.toLowerCase() === "published" ||
      data.Status.toLowerCase() === "publish"
    ) {
      history.push("/job-roles/" + data.Index);
    } else if (data.Status.toLowerCase() === "draft" && data.CreatedBy === localStorage.getItem("email")) {
      setState(
        (prev) => ({ ...prev, editJobObject: data }),
        () => {
          history.push("/job-roles/create");
        }
      );
    }else{
      history.push("/job-roles/" + data.Index);
    }
  };

  const onDeleteRole = async (row) => {
    let payload = {};
    payload.Index = row.Index;

    // delete request
    try {
      await deleteJobRole(payload, localStorage.getItem("email"));

      setState((prev) => ({
        ...prev,
        jobRoles: {
          ...state.jobRoles,
          JobRoles: state.jobRoles.JobRoles.filter((i) => i.Index !== row.Index),
        },
      }));
    } catch {
      setShowDeleteRejection(true)
    }
  };

  const onDuplicateRole = async (row) => {
    const newRole = { ...(row || {}) };

    newRole.RoleName = "Duplicate of " + newRole.RoleName;
    newRole.RoleCode = newRole.RoleCode + "-1";
    newRole.Status = "Draft";
    newRole.UserCount = 0;
    delete newRole.Index;
    const userEmail = localStorage.getItem("email") || "";
    await createJobRole(newRole, userEmail);

    // add to store
    setState((prev) => ({
      ...prev,
      jobRoles: {
        ...state.jobRoles,
        JobRoles: [...state.jobRoles.JobRoles, newRole],
      },
    }));

    jobAction("Created")
  };

  const onPublish = async(row) => {
    let isValid = true;
    let newJobValidation = {};
  
    let clonedJobForm = { ...row }
    clonedJobForm.TemplateId = clonedJobForm.TemplateID || ''

    Object.keys(jobValidation).forEach((key) => {
      let isValueInvalid = clonedJobForm[key] === "";

      newJobValidation[key] = isValueInvalid;
      if (isValid && isValueInvalid) isValid = false;
    });
    setJobValidation(newJobValidation);

    if (isValid) {
      const payload = { ...row, Status: "Published" };
      createNewJobRole(localStorage.getItem("email"), payload, true).then(
        function (response) {
          if (response.status === 200) {
            jobAction("Created")
            // setLoader(false);
            // history.push("/job-roles");
          }
        }
      );
    }
  }

  const isDuplicate = (row) =>{
      if(row.Status === "Published"){
        return true
      }else{return false}
  }

  const jobsByDepartment = useMemo(() => {
    if (!state.jobRoles || !state.jobRoles.JobRoles) return [];

    const filteredJobs = state.jobRoles.JobRoles.reduce((acc, role) => {
      const { JobGroup, RoleName, RoleCode } = role;
      if (!Object.keys(acc).includes(role.JobGroup)) acc[JobGroup] = [];

      const isMatchedString =
        RoleName.toLowerCase().includes(searchString.toLowerCase()) ||
        RoleCode.toLowerCase().includes(searchString.toLowerCase());

      if (isMatchedString) acc[JobGroup].push(role);

      return acc;
    }, {});

    Object.keys(filteredJobs).forEach((key) => {
      if (!sort[key]?.sortColumn) return;

      const sortOrder = sort[key]?.sortAsc ? 1 : -1;
      filteredJobs[key] = filteredJobs[key].sort((a, b) => {
        const firstValue = a[sort[key].sortColumn];
        const secValue = b[sort[key].sortColumn];

        return (
          (firstValue !== secValue ? (firstValue < secValue ? -1 : 1) : 0) *
          sortOrder
        );
      });
    });

    return filteredJobs;
  }, [state.jobRoles, searchString, sort]);


  const onSort = (department, name, asc) => {
    if (!sort[department]) return;

    const { sortColumn, sortAsc } = sort[department];
    const newSortAsc = name === sortColumn ? !sortAsc : true;

    const newSortByDepartment = {
      sortAsc: newSortAsc,
      sortColumn: name,
    };

    setSort({ ...sort, [department]: newSortByDepartment });
  };

  useEffect(() => {
    if (!state?.jobRoles?.JobRoles) return;

    let jobGroups = state.jobRoles.JobRoles.map((role) => role.JobGroup);
    jobGroups = [...new Set(jobGroups)];

    const sortState = jobGroups.reduce((acc, group) => {
      acc[group] = { sortAsc: true, sortColumn: "" };
      return acc;
    }, {});

    setSort(sortState);
  }, [state.jobRoles]);

  return (
    <Block>
      <Block
        width={["100%", "100%", "309px"]}
        style={{ backgroundColor: "#fff" }}
      >
        <Input
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
          name="search"
          data-testid="search_input"
          placeholder={"Search for names or codes"}
          clearOnEscape
          overrides={{
            Input: { style: INPUT_OVERRIDE_STYLE.Input },
            Root: { style: INPUT_OVERRIDE_STYLE.Root },
            StartEnhancer: { style: INPUT_OVERRIDE_STYLE.StartEnhancer },
          }}
          startEnhancer={
            <SearchIcon size={24} color={theme.RAColors?.gray900} />
          }
        />
      </Block>

      {Object.keys(jobsByDepartment).map((department, index) => (
        <Block key={index} className={css(DETAILS_CARD_STYLE(theme))}>
          <Accordion
            accordion={false}
            initialState={{
              expanded: (jobsByDepartment[department] || []).map((_, i) =>
                String(i)
              ),
            }}
            overrides={{
              ToggleIcon,
              Content: { style: ACCORDION_CONTENT_STYLE },
              Header: { style: jobsByDepartment[department][0]?.Index !== "" ? ACCORDION_HEADER_STYLE: ACCORDION_HEADER_STYLE_NO_DATA},
              PanelContainer: { style: { borderBottomWidth: "0px" } },
            }}
          >
            <Panel title={<Block>{department}</Block>}>
              {/* {jobsByDepartment[department][0]?.Index === "" ? (
                <Block>{"No Data"}</Block>
              ) : ( */}
              {jobsByDepartment[department][0]?.Index !== "" ? (
                <TableBuilder
                  data={jobsByDepartment[department]}
                  overrides={{
                    Root: { style: TABLE_OVERRIDE_STYLE.Root },
                    TableBodyRow: {
                      component: ({ children, $row, ...rest }) => (
                        <Block
                          as="tr"
                          className={css({ cursor: "pointer" })}
                          onClick={() => goToJobEdit($row)}
                          data-testid="student-table-row"
                        >
                          {children}
                        </Block>
                      ),
                    },
                  }}
                  isLoading={false}
                  emptyMessage="No data"
                >
                  <TableBuilderColumn
                    header={
                      <TableHeader
                        name={"Job Name"}
                        sortKey="RoleName"
                        asc={sort?.[department]?.sortAsc || true}
                        sorted={sort?.[department]?.sortColumn || ""}
                        onSort={(name, asc) => onSort(department, name, asc)}
                      />
                    }
                    overrides={{
                      TableHeadCell: {
                        style: TABLE_OVERRIDE_STYLE.NameCell,
                      },
                      TableBodyCell: {
                        style: TABLE_OVERRIDE_STYLE.NameCell,
                      },
                    }}
                  >
                    {(row) => (
                      <Block className={css(NAME_STYLE(theme))}>
                       {jobsByDepartment[department][0]?.Index === "" ? "No Data" : row?.RoleName}
                      </Block>
                    )}
                  </TableBuilderColumn>
                  <TableBuilderColumn
                    header={
                      <TableHeader
                        name={"Job Code"}
                        sortKey="RoleCode"
                        asc={sort?.[department]?.sortAsc || true}
                        sorted={sort?.[department]?.sortColumn || ""}
                        onSort={(name, asc) => onSort(department, name, asc)}
                      />
                    }
                    overrides={{
                      TableHeadCell: {
                        style: TABLE_OVERRIDE_STYLE.CodeCell,
                      },
                      TableBodyCell: {
                        style: TABLE_OVERRIDE_STYLE.CodeCell,
                      },
                    }}
                  >
                    {(row) => (
                      <Block className={css(NAME_STYLE(theme))}>
                        {row?.RoleCode}
                      </Block>
                    )}
                  </TableBuilderColumn>
                  <TableBuilderColumn
                    header={
                      <TableHeader
                        name={"Function"}
                        sortKey="Function"
                        asc={sort?.[department]?.sortAsc || true}
                        sorted={sort?.[department]?.sortColumn || ""}
                        onSort={(name, asc) => onSort(department, name, asc)}
                      />
                    }
                    overrides={{
                      TableHeadCell: {
                        style: TABLE_OVERRIDE_STYLE.FunctionCell,
                      },
                      TableBodyCell: {
                        style: TABLE_OVERRIDE_STYLE.FunctionCell,
                      },
                    }}
                  >
                    {(row) => (
                      <Block className={css(NAME_STYLE(theme))}>
                        {row?.Function}
                      </Block>
                    )}
                  </TableBuilderColumn>
                  <TableBuilderColumn
                    header={
                      <TableHeader
                        name={"No. of Users"}
                        sortKey="UserCount"
                        asc={sort?.[department]?.sortAsc || true}
                        sorted={sort?.[department]?.sortColumn || ""}
                        onSort={(name, asc) => onSort(department, name, asc)}
                      />
                    }
                    overrides={{
                      TableHeadCell: {
                        style: TABLE_OVERRIDE_STYLE.StatusCell,
                      },
                      TableBodyCell: {
                        style: TABLE_OVERRIDE_STYLE.StatusCell,
                      },
                    }}
                  >
                    {(row) => (
                      <Block className={css(NAME_STYLE(theme))}>
                        {jobsByDepartment[department][0]?.Index === "" ? null : row?.UserCount }
                      </Block>
                    )}
                  </TableBuilderColumn>
                  <TableBuilderColumn
                    header={
                      <TableHeader
                        name={"Status"}
                        sortKey="Status"
                        asc={sort?.[department]?.sortAsc || true}
                        sorted={sort?.[department]?.sortColumn || ""}
                        onSort={(name, asc) => onSort(department, name, asc)}
                      />
                    }
                    overrides={{
                      TableHeadCell: {
                        style: TABLE_OVERRIDE_STYLE.StatusCell,
                      },
                      TableBodyCell: {
                        style: TABLE_OVERRIDE_STYLE.StatusCell,
                      },
                    }}
                  >
                    {(row) => (
                      <Block className={css(NAME_STYLE(theme))}>
                        {row?.Status}
                      </Block>
                    )}
                  </TableBuilderColumn>
                  <TableBuilderColumn
                    header={<TableHeader name={"Action"} />}
                    overrides={{
                      TableHeadCell: {
                        style: TABLE_OVERRIDE_STYLE.ActionCell,
                      },
                      TableBodyCell: {
                        style: TABLE_OVERRIDE_STYLE.ActionCell,
                      },
                    }}
                  >
                  
                    {(row) => (
                      <Block
                        className={css({ textAlign: "right" })}
                        onClick={(e) => e.stopPropagation()}
                      >
                      
                      {jobsByDepartment[department][0]?.Index === "" ? "" : 
                        <Actions onDelete={() => onDeleteRole(row)} onDuplicate ={() => onDuplicateRole(row)} isDuplicate={isDuplicate(row)} onPublish = {() => onPublish(row)}>
                          <Block
                            as="span"
                            className={css({ cursor: "pointer" })}
                          >
                            •••
                          </Block>
                        </Actions> }
                      </Block>
                    )}
                  </TableBuilderColumn>
                </TableBuilder> ) :
                <Block style={{border:"1px solid rgba(0, 0, 0, 0.12)", borderRadius:"8px", }}>
                <Table style={{backgroundColor:"transparent"}} overrides={{ TableHeadCell: {style : TABLE_OVERRIDE_STYLE_HEAD }}} columns={COLUMNS} data={[]} /> 
                <Block style={{padding:"14px"}}>{"No Data"}</Block>
                </Block> }
              {/* )} */}
            </Panel>
          </Accordion>
        </Block>
      ))}

      {showDeleteRejection && (
        <DeleteRejection  rejectionType={"Deletion"} type="job role" close={() => setShowDeleteRejection(false)} />
      )}
    </Block>
  );
}

export default JobList;
