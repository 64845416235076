import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import {
  TILE_STYLE,
  TITLE_STYLE,
  HINT_STYLE,
  HINT_TEXT_STYLE,
  getTickFormat,
} from "./utils";
import { RadioGroup, Radio, ALIGN } from "baseui/radio";
import { useEffect, useMemo, useState, useRef } from "react";
import { useTrackedState } from "store/store";
import { Select } from "baseui/select";
import { Skeleton } from "baseui/skeleton";
import {
  COMMON_BORDER_COLOR,
  COMMON_BORDER_WIDTH,
  COMMON_RADIUS,
} from "utils/style";
import {
  XYPlot,
  XAxis,
  YAxis,
  HeatmapSeries,
  Hint,
  ContinuousColorLegend,
  LabelSeries,
} from "react-vis";
import { scaleLinear } from "d3-scale";

const SELECT_OVERRIDE_STYLE = {
  ControlContainer: ({ $theme, $disabled }) => ({
    backgroundColor: "none",
    opacity: $disabled ? 0.5 : 1,
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    color: $theme.RAColors?.gray900,
    fontFamily: "Manrope",
  }),
  Root: () => ({
    height: "46px",
    width: "300px",
    maxWidth: "100%",
  }),
};

const OPTION_ITEM_STYLE = (left = "16px", isSelected = false) => ({
  paddingLeft: left,
  paddingRight: "16px",
  paddingTop: "8px",
  paddingBottom: "8px",
  ":hover": {
    backgroundColor: "#f7f6f6",
    cursor: "pointer",
  },
  fontFamily: "system-ui, sans-serif",
  fontSize: "14px",
  color: isSelected ? "#000000" : "#545454",
  fontWeight: isSelected ? "bold" : "normal",
});

const CustomDropdown = ({ options = [], phase = "", macro = "", onSelect }) => {
  const [css] = useStyletron();

  return (
    <Block
      paddingTop="8px"
      paddingBottom="8px"
      backgroundColor="white"
      maxHeight="300px"
      overflow="auto"
      display="flex"
      flexDirection="column"
      gridGap="4px"
    >
      {options.map((ph, phIndex) => (
        <Block key={phIndex}>
          <Block
            className={css(
              OPTION_ITEM_STYLE("16px", ph.name === phase && !macro)
            )}
            onClick={() => onSelect({ phase: ph.name, macro: "" })}
          >
            {ph.name}
          </Block>
          <Block>
            {ph.macro.map((ma, maIndex) => (
              <Block
                key={maIndex}
                className={css(
                  OPTION_ITEM_STYLE(
                    "32px",
                    ph.name === phase && ma.name === macro
                  )
                )}
                onClick={() => onSelect({ phase: ph.name, macro: ma.name })}
              >
                {ma.name}
              </Block>
            ))}
          </Block>
        </Block>
      ))}
    </Block>
  );
};

const scaleColor = scaleLinear()
  .domain([0, 50, 100])
  .range(["#6495ED", "#F0FFFF", "#00FFFF"]);

const Progress = () => {
  const [css, theme] = useStyletron();
  const state = useTrackedState();

  const [skillName, setSkillName] = useState("core"); // core | optional | combined
  const [selectedPhaseName, setSelectedPhaseName] = useState("");
  const [selectedMacroName, setSelectedMacroName] = useState("");
  const [hoverValue, setHoverValue] = useState(false);
  const [width, setWidth] = useState();

  const parent = useRef();

  const selectedValue = useMemo(() => {
    if (!selectedPhaseName) return [];
    const value = selectedMacroName
      ? `Macro: ${selectedMacroName}`
      : `Phase: ${selectedPhaseName}`;

    return [{ id: value, label: value }];
  }, [selectedMacroName, selectedPhaseName]);

  // for displaying select options
  const options = useMemo(() => {
    if (!state?.learners) return [];

    let tree = [];

    state.learners.forEach((learner) => {
      const phases = learner?.learning_path?.phase_list || [];

      phases.forEach((phase) => {
        const { macro_certification_list = [], phase_name } = phase;

        let phaseIndex = tree.findIndex((i) => i.name === phase_name);

        if (phaseIndex === -1) {
          tree.push({ name: phase_name, macro: [] });
          phaseIndex = tree.length - 1;
        }

        macro_certification_list.forEach((macro) => {
          const { macro_certification_name } = macro;

          let macroIndex = tree[phaseIndex].macro.findIndex(
            (i) => i.name === macro_certification_name
          );

          if (macroIndex === -1) {
            tree[phaseIndex].macro.push({
              name: macro_certification_name,
              micro: [],
            });
          }
        });
      });
    });

    return tree;
  }, [state.learners]);

  const heatmapData = useMemo(() => {
    if (!state.learners || !selectedPhaseName)
      return { labelX: [], labelY: [], value: [] };

    let labelX = [];
    let labelY = [];
    let value = [];

    state.learners.forEach((learner) => {
      const phases = learner?.learning_path?.phase_list || [];

      if (!labelY.includes(learner.name)) labelY.push(learner.name);

      phases.forEach((phase) => {
        const { macro_certification_list = [], phase_name } = phase;

        if (phase_name !== selectedPhaseName) return;

        macro_certification_list.forEach((macro) => {
          const { macro_certification_name, course_list } = macro;

          if (
            selectedMacroName &&
            macro_certification_name !== selectedMacroName
          )
            return;

          course_list.forEach((micro) => {
            const { lesson_list } = micro;

            lesson_list.forEach((lesson) => {
              const { assignment_list } = lesson;

              assignment_list.forEach((assignment) => {
                const {
                  assignment_name,
                  assignment_type,
                  assignment_progress,
                } = assignment;

                if (
                  skillName === "core" &&
                  assignment_type === "Exercise__Independent_Optional"
                )
                  return;
                if (skillName === "optional" && assignment_type === "") return;

                const { progress_actual_cnt, progress_expected_cnt } =
                  assignment_progress;

                const shortName = assignment_name.split(" ")[0];

                if (!labelX.includes(shortName)) labelX.push(shortName);

                const v = {
                  x: shortName,
                  y: learner.name,
                  value: progress_expected_cnt
                    ? Math.round(
                        (progress_actual_cnt / progress_expected_cnt) * 100
                      )
                    : 0,
                  actual: progress_actual_cnt,
                  expected: progress_expected_cnt,
                  name: assignment_name,
                  email: learner.email_id
                };
                value.push(v);
              });
            });
          });
        });
      });
    });

    // sort labelY alphabetically
    labelY = labelY.sort((a, b) => -a.localeCompare(b))

    return { labelX, labelY, value };
  }, [selectedPhaseName, selectedMacroName, skillName, state.learners]);

  const onSelect = ({ phase, macro }) => {
    if (!phase) return;
    setSelectedMacroName(macro);
    setSelectedPhaseName(phase);
  };

  const heatMapOffsetTop = 70;
  const heatMapOffsetLeft = 180;
  const heatMapHeight = heatMapOffsetTop + heatmapData.labelY.length * 40;
  const heatMapRealWidth = heatMapOffsetLeft + heatmapData.labelX.length * 40;
  const heatMapWidth = heatMapRealWidth < width ? width : heatMapRealWidth;

  useEffect(() => {
    if (!options.length) return;

    const { name } = options[0];
    setSelectedPhaseName(name);
  }, [options]);

  useEffect(() => {
    if (parent) setWidth(parent.current.clientWidth);
  }, [state.isFetching]);

  return (
    <Block className={css(TILE_STYLE(theme))}>
      <Block
        display="flex"
        justifyContent="space-between"
        alignItems={["flex-start", "flex-start", "flex-start", "center"]}
        marginBottom="20px"
        flexDirection={["column", "column", "column", "row"]}
        gridGap={["16px", "16px", "16px", 0]}
      >
        <Block className={css(TITLE_STYLE)} data-testid="progress-title">Progress</Block>
        <Block
          display="flex"
          alignItems="center"
          gridGap="16px"
          flexDirection={["column", "column", "column", "row"]}
        >
          <Select
            options={selectedValue}
            value={selectedValue}
            placeholder="Select phase or macro"
            searchable={false}
            clearable={false}
            disabled={!options.length}
            overrides={{
              ControlContainer: {
                style: SELECT_OVERRIDE_STYLE.ControlContainer,
              },
              Root: {
                style: SELECT_OVERRIDE_STYLE.Root,
                props: {
                  'data-testid': 'progress-select',
                },
              },
              Dropdown: () => (
                <CustomDropdown
                  macro={selectedMacroName}
                  phase={selectedPhaseName}
                  options={options}
                  onSelect={onSelect}
                />
              ),
            }}
          />
          <Block data-testid="progress-radio-group">
            <RadioGroup
              value={skillName}
              onChange={(e) => setSkillName(e.currentTarget.value)}
              name="number"
              align={ALIGN.horizontal}
            >
              <Radio value="core">Core</Radio>
              <Radio value="optional">Optional</Radio>
              <Radio value="combined">Combined</Radio>
            </RadioGroup>
          </Block>
        </Block>
      </Block>

      <Block ref={parent}>
        {state.isFetching ? (
          <Skeleton width={`${width}px`} height="300px" />
        ) : (
          <>
            <Block overflow="auto" data-testid="progress-visualization">
              <XYPlot
                xType="ordinal"
                xDomain={heatmapData.labelX}
                yType="ordinal"
                yDomain={heatmapData.labelY}
                margin={{ top: heatMapOffsetTop, left: heatMapOffsetLeft }}
                width={heatMapWidth || 0}
                height={heatMapHeight}
              >
                <XAxis
                  style={{
                    text: { textAnchor: "start", alignmentBaseline: "central" },
                  }}
                  orientation="top"
                  tickLabelAngle={-90}
                  tickFormat={(l) => getTickFormat(l, 10)}
                />
                <YAxis
                  style={{ text: { textAnchor: "end" } }}
                  tickFormat={(l) => getTickFormat(l, 24)}
                />
                <HeatmapSeries
                  colorType="literal"
                  getColor={(d) => scaleColor(d.value)}
                  data={heatmapData.value}
                  onValueMouseOver={(v) => setHoverValue(v)}
                  onSeriesMouseOut={(v) => setHoverValue(false)}
                />
                <LabelSeries
                  style={{ pointerEvents: "none", fontSize: 9 }}
                  data={heatmapData.value}
                  labelAnchorX="middle"
                  labelAnchorY="middle"
                  getLabel={(d) => `${d.value}`}
                />
                {hoverValue !== false && (
                  <Hint value={hoverValue}>
                    <Block className={css(HINT_STYLE)} data-testid="progress-hint">
                      <Block className={css(HINT_TEXT_STYLE)}>
                        Learner: {hoverValue.y}
                      </Block>
                      <Block className={css(HINT_TEXT_STYLE)}>
                        Email: {hoverValue.email}
                      </Block>
                      <Block className={css(HINT_TEXT_STYLE)}>
                        Assignment Name: {hoverValue.name}
                      </Block>
                      <Block className={css(HINT_TEXT_STYLE)}>
                        Completion Percentage: {hoverValue.value}%
                      </Block>
                      <Block className={css(HINT_TEXT_STYLE)}>
                        Actual: {hoverValue.actual}
                      </Block>
                      <Block className={css(HINT_TEXT_STYLE)}>
                        Expected: {hoverValue.expected}
                      </Block>
                    </Block>
                  </Hint>
                )}
              </XYPlot>
            </Block>
            <Block display="flex" justifyContent="center" datat-testid="progress-legend">
              <ContinuousColorLegend
                width={300}
                startTitle="0%"
                midTitle="50%"
                endTitle="100%"
                startColor="#6495ED"
                midColor="#F0FFFF"
                endColor="#00FFFF"
              />
            </Block>
          </>
        )}
      </Block>
    </Block>
  );
};

export default Progress;
