import React from "react";
import "./style.css";
import { Header } from "components/Header";
import { Redirect } from "react-router-dom";

export default function Landing() {
  return localStorage.getItem("role") === "LEARNER" ||
    localStorage.getItem("role") === "COACH" || localStorage.getItem("viewAs")? (
    <div className="landing">
      <Header />
      <div>
        <div className="overview_mainSection_landing">
          <h2 data-testid="landing-title">RapidAscent</h2>
          <h1 data-testid="landing-subtitle">Coming Soon</h1>
          <p>
            Operational training that moves you from written exams to verified
            tasks and skills
          </p>
        </div>
        <div className="overview_mainSection_image" data-testid="landing-image"></div>
      </div>
    </div>
  ) : (
    <Redirect to="/login" />
  );
}
