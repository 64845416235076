import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Input } from "baseui/input";
import { Textarea as BaseTextarea } from "baseui/textarea";
import { Select } from "baseui/select";
import {
  COMMON_RADIUS,
  COMMON_BORDER_COLOR,
  COMMON_BORDER_WIDTH,
  COMMON_CARD_STYLE,
  COMMON_BORDER_STYLE,
} from "utils/style";
import CloseIcon from "assets/svg/close.svg";
import { useEffect, useMemo, useState, lazy, Suspense } from "react";
import { fetchJobGroups } from "services/requests/job_role.js";
import getCompetencyChecklist from "services/requests/get_competency_checklist.js";
import { Button } from "baseui/button";
import Actions from "./actions.js";
import { sortAZSelectOptions } from "utils/select.js";

const INPUT_OVERRIDE_STYLE = {
  Input: ({ $theme }) => ({
    backgroundColor: "none",
    color: $theme.RAColors?.gray900,
    fontFamily: "Manrope",
  }),
  Root: ({ $theme }) => ({
    backgroundColor: "none",
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    width: "100%",
  }),
};

const SELECT_OVERRIDE_STYLE = {
  Root: ({ $theme }) => ({
    width: "100%",
    [$theme.mediaQuery.medium]: {
      width: "150px",
    },
  }),
  ControlContainer: ({ $theme }) => ({
    backgroundColor: "none",
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    color: $theme.RAColors?.gray900,
  }),
};

const TEXTAREA_OVERRIDE_STYLE = {
  Input: {
    style: ({ $theme }) => ({
      backgroundColor: "white",
      fontFamily: "Manrope",
    }),
  },
  Root: {
    style: ({ $theme }) => ({
      ...COMMON_RADIUS("4px"),
      ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
      ...COMMON_BORDER_WIDTH("1px"),
    }),
  },
};

const HEADING = {
  fontFamily: "Manrope-Medium",
  fontSize: "14px",
  lineHeight: "20px",
};

const DOL_CATEGORY_HEADING = {
  fontFamily: "Manrope-Medium",
  fontSize: "18px",
  lineHeight: "24px",
};

const ADD_MORE = (theme) => ({
  fontSize: "16px",
  lineHeight: "22px",
  fontFamily: "Manrope-Semibold",
  color: theme.RAColors?.blue800,
  cursor: "pointer",
});

const COMMON_BUTTON_STYLE = {
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingRight: "24px",
  paddingLeft: "24px",
  lineHeight: "22px",
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  fontFamily: "Manrope-Bold",
};

const DUPLICATE_BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_BUTTON_STYLE,
  ...COMMON_BORDER_COLOR($theme.RAColors?.purple),
  backgroundColor: "white",
  ":hover": { backgroundColor: $theme.RAColors?.gray300 },
  color: $theme.RAColors?.purple,

  [$theme.mediaQuery.small]: {
    width: "100%",
  },
  [$theme.mediaQuery.medium]: {
    width: "initial",
  },
});

const NEW_ROLE_BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_BUTTON_STYLE,
  ...COMMON_BORDER_COLOR($theme.RAColors?.blue700),
  backgroundColor: $theme.RAColors?.blue700,
  ":hover": { backgroundColor: $theme.RAColors?.blue800 },
  color: "white",

  [$theme.mediaQuery.small]: {
    width: "100%",
  },
  [$theme.mediaQuery.medium]: {
    width: "initial",
  },
});

const JobGroupModal = lazy(() => import("./newJobGroup.js"));

export const BasicInfo = ({ data, onChange }) => {
  const [css] = useStyletron();

  const [jobGroupOptions, setJobGroupOptions] = useState([]);
  const [showJobGroupModal, setShowJobGroupModal] = useState(false);

  const userEmail = localStorage.getItem("email") || "";

  const jobGroupValue = useMemo(
    () => jobGroupOptions.filter((option) => option.id === data.JobGroup),
    [data.JobGroup, jobGroupOptions]
  );

  const onSelectJobGroup = (params) => {
    if (params.value[0].id === "Create a New Job Group") {
      setShowJobGroupModal(true);
    } else {
      onChange("JobGroup", params.value[0].id);
    }
  };

  useEffect(() => {
    const base = [
      // { id: 'Create a New Job Group', label: 'Create a New Job Group' }
    ];

    const fetchOptions = async () => {
      const response = await fetchJobGroups(userEmail);

      if (response.status !== 200) return base;

      const options = response.data.map(({ JobGroup: i }) => i);

      const selectOptions = options.map((i) => ({ label: i, id: i }));

      let addition = [];

      if (!options.includes(data.JobGroup)) {
        addition = [{ label: data.JobGroup, id: data.JobGroup }];
      }

      setJobGroupOptions([...base, ...addition, ...selectOptions]);
    };

    fetchOptions();
  }, []);

  return (
    <Block width="800px" maxWidth="100%">
      <Block marginBottom="24px">
        <Block marginBottom="4px" className={css(HEADING)} data-testid="job-role-name">
          Role Name
        </Block>
        <Input
          id="edit-job-rolename"
          value={data.RoleName}
          data-testid="job-role-name-input"
          onChange={(e) => onChange("RoleName", e.target.value)}
          overrides={{
            Input: { style: INPUT_OVERRIDE_STYLE.Input },
            Root: { style: INPUT_OVERRIDE_STYLE.Root },
          }}
        />
      </Block>
      <Block
        marginBottom="24px"
        display="flex"
        gridGap={["8px", "8px", "24px"]}
        flexDirection={["column", "column", "row"]}
      >
        <Block>
          <Block marginBottom="4px" className={css(HEADING)} data-testid="job-role-code">
            Code
          </Block>
          <Input
            value={data.RoleCode}
            data-testid="job-role-code-input"
            onChange={(e) => onChange("RoleCode", e.target.value)}
            overrides={{
              Input: { style: INPUT_OVERRIDE_STYLE.Input },
              Root: { style: INPUT_OVERRIDE_STYLE.Root },
            }}
          />
        </Block>
        <Block>
          <Block marginBottom="4px" className={css(HEADING)} data-testid="job-function">
            Function
          </Block>
          <Input
            value={data.Function}
            data-testid="job-function-input"
            onChange={(e) => onChange("Function", e.target.value)}
            overrides={{
              Input: { style: INPUT_OVERRIDE_STYLE.Input },
              Root: { style: INPUT_OVERRIDE_STYLE.Root },
            }}
          />
        </Block>
        <Block>
          <Block marginBottom="4px" className={css(HEADING)} data-testid="job-department">
            Department
          </Block>
          <Input
            value={data.Department}
            data-testid="job-department-input"
            onChange={(e) => onChange("Department", e.target.value)}
            overrides={{
              Input: { style: INPUT_OVERRIDE_STYLE.Input },
              Root: { style: INPUT_OVERRIDE_STYLE.Root },
            }}
          />
        </Block>
        <Block>
          <Block marginBottom="4px" className={css(HEADING)} data-testid="job-group-text">
            Job Group
          </Block>
          <Select
            clearable={false}
            searchable={false}
            options={sortAZSelectOptions(jobGroupOptions)}
            value={jobGroupValue}
            data-testid="job-group-input"
            onChange={onSelectJobGroup}
            overrides={{
              Root: {
                style: ({ $theme }) => ({
                  width: "100%",
                  [$theme.mediaQuery.medium]: {
                    width: "200px",
                  },
                }),
              },
              ControlContainer: {
                style: SELECT_OVERRIDE_STYLE.ControlContainer,
              },
            }}
          />
        </Block>
      </Block>

      <Block>
        <Block
          className={css(HEADING)}
          marginBottom="4px"
          display="flex"
          justifyContent="space-between"
        >
          <Block data-testid="job-description">Description</Block>
          <Block>{data?.Description?.length || 0}/2000</Block>
        </Block>
        <BaseTextarea
          rows={5}
          value={data.Description}
          onChange={(e) => onChange("Description", e.target.value)}
          placeholder="Enter text here"
          overrides={TEXTAREA_OVERRIDE_STYLE}
          maxLength={2000}
        />
      </Block>

      <Suspense fallback={<div />}>
        {showJobGroupModal && (
          <JobGroupModal
            showModal={showJobGroupModal}
            close={() => setShowJobGroupModal(false)}
          />
        )}
      </Suspense>
    </Block>
  );
};

export const List = ({ value, onChange, onDelete, onAdd, name, fieldName }) => {
  const [css, theme] = useStyletron();

  return (
    <Block display="flex" flexDirection="column" gridGap="16px">
      {value.map((item, index) => (
        <Block
          key={index}
          width="700px"
          maxWidth="100%"
          data-testid="edit-job-list-item"
        >
          <Block marginBottom="4px" className={css(HEADING)}>
            {fieldName || "Name"}
          </Block>
          <Block display="flex" alignItems="center" gridGap="16px">
            <Input
              value={item[name]}
              onChange={(e) =>
                onChange({ ...item, [name]: e.target.value }, index)
              }
              data-testid="nameInput"
              overrides={{
                Input: { style: INPUT_OVERRIDE_STYLE.Input },
                Root: { style: INPUT_OVERRIDE_STYLE.Root },
              }}
            />
            <Block
              as="img"
              width="24px"
              src={CloseIcon}
              alt="Close"
              className={css({ cursor: "pointer" })}
              onClick={() => onDelete(index)}
              data-testid="edit-job-list-delete"
            />
          </Block>
        </Block>
      ))}
      <Block
        className={css(ADD_MORE(theme))}
        onClick={onAdd}
        data-testid="edit-job-list-add-more"
      >
        Add more
      </Block>
    </Block>
  );
};

const OPTIONS = [
  { label: "Low", id: "Low" },
  { label: "Medium", id: "Medium" },
  { label: "High", id: "High" },
];

const FREQUENCY_OPITONS = [
  { label: "Daily", id: "Daily" },
  { label: "Weekly", id: "Weekly" },
  { label: "Bi-weekly", id: "Bi-weekly" },
  { label: "Monthly", id: "Monthly" },
];

export const ActivityList = ({ value, onChange, onDelete, onAdd }) => {
  const [css, theme] = useStyletron();

  return (
    <Block display="flex" flexDirection="column" gridGap="16px">
      {value.map((item, index) => (
        <Block
          key={index}
          display="flex"
          gridGap="16px"
          data-testid="edit-job-activity-list-item"
          flexDirection={["column", "column", "row"]}
          alignItems={['flex-start', 'flex-start', 'center']}
        >
          <Block width={['100%', '100%', 'initial']}>
            <Block marginBottom="4px" className={css(HEADING)} data-testid="activity_name_label">
              Name
            </Block>
            <Input
              value={item.ActivityName}
              onChange={(e) =>
                onChange({ ...item, ActivityName: e.target.value }, index)
              }
              data-testid="activity_name_input"
              overrides={{
                Input: { style: INPUT_OVERRIDE_STYLE.Input },
                Root: { style: INPUT_OVERRIDE_STYLE.Root },
              }}
            />
          </Block>
          <Block width={['100%', '100%', 'initial']}>
            <Block marginBottom="4px" className={css(HEADING)} data-testid="activity_importance_label">
              Importance
            </Block>
            <Select
              clearable={false}
              searchable={false}
              options={OPTIONS}
              data-testid="activity_importance_input"
              value={OPTIONS.filter(
                (option) =>
                  option.id.toLowerCase() === item.Importance.toLowerCase()
              )}
              onChange={(params) =>
                onChange({ ...item, Importance: params.value[0].id }, index)
              }
              overrides={{
                Root: { style: SELECT_OVERRIDE_STYLE.Root },
                ControlContainer: {
                  style: SELECT_OVERRIDE_STYLE.ControlContainer,
                },
              }}
            />
          </Block>
          <Block width={['100%', '100%', 'initial']}>
            <Block marginBottom="4px" className={css(HEADING)} data-testid="activity_difficulty_label">
              Difficulty
            </Block>
            <Select
              clearable={false}
              searchable={false}
              options={OPTIONS}
              data-testid="activity_difficulty_input"
              value={OPTIONS.filter(
                (option) =>
                  option.id.toLowerCase() === item.Difficulty.toLowerCase()
              )}
              onChange={(params) =>
                onChange({ ...item, Difficulty: params.value[0].id }, index)
              }
              overrides={{
                Root: { style: SELECT_OVERRIDE_STYLE.Root },
                ControlContainer: {
                  style: SELECT_OVERRIDE_STYLE.ControlContainer,
                },
              }}
            />
          </Block>
          <Block width={['100%', '100%', 'initial']}>
            <Block marginBottom="4px" className={css(HEADING)} data-testid="activity_frequency_label">
              Frequency
            </Block>
            <Select
              clearable={false}
              searchable={false}
              options={FREQUENCY_OPITONS}
              data-testid="activity_frequency_input"
              value={FREQUENCY_OPITONS.filter(
                (option) =>
                  option.id.toLowerCase() === item.Frequency.toLowerCase()
              )}
              onChange={(params) =>
                onChange({ ...item, Frequency: params.value[0].id }, index)
              }
              overrides={{
                Root: { style: SELECT_OVERRIDE_STYLE.Root },
                ControlContainer: {
                  style: SELECT_OVERRIDE_STYLE.ControlContainer,
                },
              }}
            />
          </Block>
          <Block
            as="img"
            width="24px"
            src={CloseIcon}
            alt="Close"
            className={css({ cursor: "pointer", marginTop:"20px" })}
            onClick={() => onDelete(index)}
            data-testid="edit-job-activity-list-delete"
          />
        </Block>
      ))}
      <Block
        className={css(ADD_MORE(theme))}
        onClick={onAdd}
        data-testid="edit-job-activity-list-add-more"
      >
        Add more
      </Block>
    </Block>
  );
};

export const DOLCriteria = () => {
  const [css, theme] = useStyletron();
  const [categories, setCategories] = useState([
    ["Text 01", "Text 02"],
    ["Text 01", "Text 02"],
  ]);

  const addCategory = () => {
    setCategories([...categories, [""]]);
  };

  const deleteCategory = (index) => {
    const newCategories = [...categories];
    newCategories.splice(index);

    setCategories(newCategories);
  };

  const addCriteria = (categoryIndex) => {
    const currentCategory = categories[categoryIndex];
    const newCategory = [...currentCategory, "Text"];

    const newCategories = [...categories];
    newCategories[categoryIndex] = newCategory;

    setCategories(newCategories);
  };

  return (
    <Block>
      <Block
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="24px"
      >
        <Block display="flex" gridGap="16px" alignItems="center">
          <Block className={css(DOL_CATEGORY_HEADING)} data-testid="template_label">Template Name</Block>
          <Block className={css(HEADING)} data-testid="template_heading_text">CyberSecurity_v1.20220813</Block>
        </Block>
        <Block display="flex" gridGap="16px" alignItems="center">
          <Button
            overrides={{
              BaseButton: { style: NEW_ROLE_BUTTON_OVERRIDE_STYLE },
            }}
            onClick={() => alert("Edit")}
            data-testid="template_edit_button"
          >
            Edit
          </Button>
          <Button
            overrides={{
              BaseButton: { style: DUPLICATE_BUTTON_OVERRIDE_STYLE },
            }}
            onClick={() => alert("Add new template")}
            data-testid="template_new_button"
          >
            New
          </Button>
          <Button
            overrides={{
              BaseButton: { style: DUPLICATE_BUTTON_OVERRIDE_STYLE },
            }}
            onClick={() => alert("Duplicate template")}
            data-testid="template_duplicate_button"
          >
            Duplicate
          </Button>
        </Block>
      </Block>
      <Block
        marginBottom="24px"
        display="flex"
        flexDirection="column"
        gridGap="24px"
      >
        {categories.map((category, index) => (
          <Block
            key={index}
            className={css(COMMON_CARD_STYLE(theme))}
            display="flex"
            flexDirection="column"
            gridGap="16px"
          >
            <Block display="flex" justifyContent="space-between">
              <Block
                display="flex"
                gridGap="16px"
                alignItems="center"
                className={css(DOL_CATEGORY_HEADING)}
                data-testid="template_title_heading"
              >
                Design, build and test network systems
              </Block>
              <Block display="flex" gridGap="16px" alignItems="center">
                <Actions onDelete={() => deleteCategory(index)}>
                  <Block className={css(ADD_MORE(theme))}>•••</Block>
                </Actions>
              </Block>
            </Block>

            {category.map((item, itemIndex) => (
              <Input
                key={itemIndex}
                overrides={{
                  Input: { style: INPUT_OVERRIDE_STYLE.Input },
                  Root: { style: INPUT_OVERRIDE_STYLE.Root },
                }}
                value={item}
              />
            ))}
            <Block
              className={css(ADD_MORE(theme))}
              onClick={() => addCriteria(index)}
              data-testid="add_criteria_button"
            >
              Add Criteria
            </Block>
          </Block>
        ))}
      </Block>
      <Block className={css(ADD_MORE(theme))} onClick={addCategory} data-testid="add_category_button">
        Add Category
      </Block>
    </Block>
  );
};

export const CompetencyChecklist = ({ value, onChange }) => {
  const [css] = useStyletron();
  const [dolCompetencyOptions, setDolCompetencyOptions] = useState([]);
  const userEmail = localStorage.getItem("email") || "";

  useEffect(() => {
    try {
      const fetchOption = async () => {
        const response = await getCompetencyChecklist(userEmail);
        let dolCompetencyArray = [];
        response?.data?.forEach((element, index) => {
          let obj = {};
          obj.label = element.TemplateName + ' - v' +element.TemplateVersion;
          obj.id = index + 1;
          obj.value = element.TemplateName
          obj.TemplateID = element.TemplateId;
          dolCompetencyArray.push(obj);
        });
        setDolCompetencyOptions(dolCompetencyArray);
      };
      fetchOption();
    } catch (e) {
      //console.log(e);
    }
  }, []);

  return (
    <Block width="800px" maxWidth="100%">
      <Block
        marginBottom="24px"
        display="flex"
        gridGap={["8px", "8px", "24px"]}
        flexDirection={["column", "column", "row"]}
      >
        <Block>
          <Block marginBottom="4px" className={css(HEADING)} data-testid="competency_checklist_text">
            Competency Checklist
          </Block>
          <Select
          maxDropdownHeight="300px"
            clearable={false}
            searchable={false}
            data-testid="competency_checklist_select"
            options={sortAZSelectOptions(dolCompetencyOptions)}
            value={dolCompetencyOptions.filter(
              (option) => option.TemplateID === value
            )}
            onChange={(params) => onChange(params.value[0].TemplateID)}
            overrides={{
              Root: {
                style: ({ $theme }) => ({
                  width: "100%",
                  [$theme.mediaQuery.medium]: {
                    width: "300px",
                  },
                }),
              },
              ControlContainer: {
                style: SELECT_OVERRIDE_STYLE.ControlContainer,
              },
            }}
          />
        </Block>
      </Block>
    </Block>
  );
};
