import axios from "axios";
import { studentURL } from "../config";

const LinkforceControllerLabCompletion = axios.create({
  baseURL: studentURL,
});

LinkforceControllerLabCompletion.defaults.headers.common = {
  ...(LinkforceControllerLabCompletion.defaults.headers.common || {}),
  ApiKey: "TESTDEV-0000-1111-2222-333333333333",
};

export const fetchLabCompletion = (email = "", limit = 14) =>
  LinkforceControllerLabCompletion.get(
    `/labcompletion?user_email=${email}&limit=${limit}`
  );
