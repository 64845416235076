import React from "react";
import { Block } from "baseui/block";
import { SnackbarProvider } from "baseui/snackbar";
import PropTypes from 'prop-types'; // ES6


export default function SnackBar(props) {

  SnackBar.propTypes = {
    placement: PropTypes.string,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    textAlignment: PropTypes.string,
    message: PropTypes.string
  }

  SnackBar.defaultProps ={
    placement: "bottomRight",
    backgroundColor: "green",
    textColor: "#FFFFFF",
    textAlignment: "center",
    message: "empty text"
  }

  return (
    <SnackbarProvider placement={props.placement}>
      <Block display="flex" justifyContent="center">
        <Block
          backgroundColor={props.backgroundColor}
          display="flex"
          justifyContent={props.textAlignment}
          alignItems={props.textAlignment}
          color={props.textColor}
          width="50%"
          height="40px"
        >
          {props.message}
        </Block>
      </Block>
    </SnackbarProvider>
  );
}
