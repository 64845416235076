import { Header } from "components/Header";
import DrawerRouterContainer from "components/newDrawer";
import { useStyletron } from "baseui";
import Title from "./components/title";
import React, { useEffect, useMemo, useState } from "react";
import { Block } from "baseui/block";
import { COMMON_PADDING } from "utils/style";
import { Panel, StatelessAccordion } from "baseui/accordion";
import ToggleIcon from "components/toggleIcon";
import AccordionHeader from "./components/accordionHeader";
import TemplateTable from "./components/templateTable";
import EditTemplate from "./components/editTemplate";
import { useHistory , Redirect} from "react-router-dom";
import { useSetState, useTrackedState } from "store/store";
import { convertDateToYYYYMMDD, deepCopy } from "./util";
import {
  COMMON_BORDER_COLOR,
  COMMON_BORDER_WIDTH,
  COMMON_RADIUS,
} from "utils/style";
import { Select } from "baseui/select";
import {
  fetchDOLTemplate,
  createDOLTemplate,
  deleteDOLTemplate,
  updateDOLTemplate,
} from "services/requests/dol_template";
import DeleteRejection from "components/deleteRejection";
import getRolesPageVisibility from "../../components/getPageVisibility"

const MAIN_STYLE = (theme) => ({
  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "40px",
  },
});

const DETAILS_CARD_STYLE = (theme) => ({
  backgroundColor: "white",
  borderRadius: "8px",
  height: "initial",
  marginTop: "16px",
  border: `1px solid ${theme.RAColors?.gray200}`,
  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "20px",
  },
});

const ACCORDION_HEADER_STYLE = {
  ...COMMON_PADDING("0px"),
  fontFamily: "Manrope-Bold",
  fontSize: "18px",
  lineHeight: "43px",
  outline: "none",
};

const ACCORDION_CONTENT_STYLE = ({ $theme }) => ({
  paddingLeft: "0px",
  paddingRight: "0px",
  paddingTop: "20px",
  paddingBottom: "0px",
});

const SELECT_OVERRIDE_STYLE = {
  Root: ({ $theme }) => ({
    [$theme.mediaQuery.small]: {
      width: "100%",
    },
    [$theme.mediaQuery.medium]: {
      width: "250px",
    },
  }),
  ControlContainer: ({ $theme }) => ({
    backgroundColor: "none",
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    color: $theme.RAColors?.gray900,
    fontFamily: "Manrope",
  }),
};

const LABEL_STYLE = {
  fontFamily: "Manrope",
  fontSize: "14px",
};

const SUBTEXT_STYLE = (theme) => ({
  fontSize: "14px",
  lineHeight: "20px",
  marginTop: "4px",
  color: theme?.RAColors.red600,
  display: "inline-block",
});

const Criteria = () => {
  const [css, theme] = useStyletron();
  const history = useHistory();
  const state = useTrackedState();
  const setState = useSetState();

  const [editTemplates, setEditTemplates] = useState([]);
  const [editTemplateVersions, setEditTemplateVersions] = useState({});
  const [showDeleteRejection, setShowDeleteRejection] = useState(false);
  const [rejectionType, setRejectionType] = useState("");
  const [expands, setExpands] = useState([]);

  const showWarningDialogue = (type) => {
    setRejectionType(type);
    setShowDeleteRejection(true);
  };
  const groupedTemplates = useMemo(() => {
    if (!state.dolTemplates) return {};

    const group = state.dolTemplates.reduce((acc, template) => {
      if (!acc[template.TemplateName]) acc[template.TemplateName] = [];
      acc[template.TemplateName].push(template);
      return acc;
    }, {});

    Object.keys(group).forEach((key) => {
      const templates = group[key];
      const sortedTemplates = templates.sort((a, b) => {
        const aVersion = Number(a.TemplateVersion.split(".")[0]);
        const bVersion = Number(b.TemplateVersion.split(".")[0]);
        return bVersion - aVersion;
      });
      group[key] = sortedTemplates;
    });

    return group;
  }, [state.dolTemplates]);

  const duplicateTemplate = async (name, key) => {
    const newTemplates = [...state.dolTemplates];
    const templateIndex = newTemplates.findIndex(
      (t) =>
        t.TemplateVersion === editTemplateVersions[key] &&
        t.TemplateName === key
    );
    const duplicatedTemplate = { ...newTemplates[templateIndex] };
    duplicatedTemplate.TemplateName = name;
    duplicatedTemplate.TemplateVersion = `1.${convertDateToYYYYMMDD(
      new Date()
    )}`;

    duplicatedTemplate.Email = localStorage.getItem("email");
    const response = await createDOLTemplate(
      duplicatedTemplate,
      localStorage.getItem("email")
    );

    if (response.status === 200) {
      const getResponse = await fetchDOLTemplate(localStorage.getItem("email"));
      const createdTemplate =
        getResponse.data.find(
          (i) =>
            i.TemplateName === duplicatedTemplate.TemplateName &&
            i.TemplateVersion === duplicatedTemplate.TemplateVersion
        ) || null;

      duplicatedTemplate.TemplateId = createdTemplate?.TemplateId || 0;

      setState((prev) => ({
        ...prev,
        dolTemplates: [duplicatedTemplate, ...state.dolTemplates],
      }));
    }
  };

  const exportTemplate = async (key) => {
    const newTemplates =  await fetchDOLTemplate(localStorage.getItem("email"));
    const templateIndexData = newTemplates?.data?.filter(
      (obj) => obj.TemplateName === key
    );
    //taking only the object
    const exportObject = JSON.parse(JSON.stringify(templateIndexData[0]));
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(exportObject)
    )}`;
    const link = document.createElement("a");
    link.href = jsonString;
    link.download = "dol-criteria-template.json";
    link.click();
  };

  const deleteTemplate = async (key, index) => {
    setEditTemplates(editTemplates.filter((i) => i !== index));

    const newTemplates = [...state.dolTemplates];
    const templateIndex = newTemplates.findIndex(
      (t) =>
        t.TemplateVersion === editTemplateVersions[key] &&
        t.TemplateName === key
    );

    try {
      const response = await deleteDOLTemplate({
        TemplateId: Number(newTemplates[templateIndex].TemplateId),
      });

      if (response.status === 200) {
        newTemplates.splice(templateIndex, 1);
        setState((prev) => ({ ...prev, dolTemplates: newTemplates }));
      }
    } catch {
      showWarningDialogue("Deletion");
    }
  };

  const editTemplate = (Currentindex) => {
    const newExpands = expands.map((obj, index) => {
      if (index === Currentindex) {
        obj.expanded = true;
        obj.showActionIcons = false;
      }
      return obj;
    });
    setExpands(newExpands);
    if (!editTemplates.includes(Currentindex))
      setEditTemplates([...editTemplates, Currentindex]);
  };

  const close = (editTemplateName, Currentindex) => {
    setEditTemplates(editTemplates.filter((i) => i !== Currentindex));
    setEditTemplateVersions({
      ...editTemplateVersions,
      [editTemplateName]: groupedTemplates[editTemplateName][0].TemplateVersion,
    });
    const newExpands = expands.map((obj, index) => {
      if (index === Currentindex) {
        obj.showActionIcons = true;
      }
      return obj;
    });
    setExpands(newExpands);
  };

  const save = async (template, index) => {
    try {
      const newTemplates = [...state.dolTemplates];
      const templateIndex = newTemplates.findIndex(
        (t) =>
          t.TemplateVersion === template.TemplateVersion &&
          t.TemplateName === template.TemplateName
      );
      newTemplates[templateIndex] = template;
      const putTemplate = deepCopy(template);
      putTemplate.Email = localStorage.getItem("email") || "";

      const response = await updateDOLTemplate(
        putTemplate,
        localStorage.getItem("email")
      );

      if (response.status === 200) {
        close(template.TemplateName, index);
        setState((prev) => ({ ...prev, dolTemplates: newTemplates }));
      }
    } catch {
      showWarningDialogue("Updation");
    }
  };

  const saveNewVersion = async (template, index) => {
    const newTemplate = { ...template };
    const versionNumber =
      Number(
        groupedTemplates[template.TemplateName][0].TemplateVersion.split(".")[0]
      ) + 1;
    newTemplate.TemplateVersion = `${versionNumber}.${convertDateToYYYYMMDD(
      new Date()
    )}`;

    newTemplate.Email = localStorage.getItem("email");
    const response = await createDOLTemplate(
      newTemplate,
      localStorage.getItem("email")
    );

    if (response.status === 200) {
      const getResponse = await fetchDOLTemplate(localStorage.getItem("email"));
      const createdTemplate =
        getResponse.data.find(
          (i) =>
            i.TemplateName === newTemplate.TemplateName &&
            i.TemplateVersion === newTemplate.TemplateVersion
        ) || null;

      newTemplate.TemplateId = createdTemplate?.TemplateId || 0;

      close(template.TemplateName, index);
      setState((prev) => ({
        ...prev,
        dolTemplates: [newTemplate, ...state.dolTemplates],
      }));
    }
  };

  useEffect(() => {
    let isMounted = true;
    localStorage.setItem("currentPage","competency-manager")  
    const getTemplates = async () => {
      const response = await fetchDOLTemplate(localStorage.getItem("email"));
      if (response.status !== 200 || !isMounted) return;
      // console.log("GET", response.data);

      setState((prev) => ({
        ...prev,
        dolTemplates: [...response.data.reverse()],
      }));
    };

    if (!state.dolTemplates) getTemplates();

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let versions = {};
    let parents = [];
    Object.keys(groupedTemplates).forEach((key) => {
      versions[key] = groupedTemplates[key][0].TemplateVersion;
      const parent = {
        expanded: true,
        showActionIcons: true,
      };
      parents.push({
        ...parent,
      });
    });
    setEditTemplateVersions(versions);
    setExpands(parents);
  }, [groupedTemplates]);

  function ExpandVal(callback) {
    const newExpands = expands.map((expand) => {
      return { expanded: callback, showActionIcons: true };
    });
    setExpands(newExpands);
  }

  const changeParent = (parentIndex) => {
    const newExpands = [...expands];
    newExpands[parentIndex].expanded = !newExpands[parentIndex].expanded;
    setExpands(newExpands);
  };

  //page access control - based on role
  var role = localStorage.getItem("viewAs") !== null ? localStorage.getItem("viewAs") : localStorage.getItem("role");
  var permittedRoles = getRolesPageVisibility("Competency Manager");
  if (!permittedRoles.includes(role)) return <Redirect to="/student" />;

  return (
    <>
      <Header />
      <DrawerRouterContainer>
        <main className={css(MAIN_STYLE(theme))}>
          <Title
            expandval={ExpandVal}
            addNew={() => history.push("/competency-manager/new")}
          />

          {Object.keys(groupedTemplates).length ? (
            <>
              {Object.keys(groupedTemplates).map((key, index) => (
                <Block key={index} className={css(DETAILS_CARD_STYLE(theme))}>
                  <React.Fragment key={index}>
                    <StatelessAccordion
                      accordion={false}
                      expanded={expands[index]?.expanded ? ["panel"] : []}
                      onChange={() => changeParent(index)}
                      overrides={{
                        ToggleIcon,
                        Content: { style: ACCORDION_CONTENT_STYLE },
                        Header: { style: ACCORDION_HEADER_STYLE },
                        PanelContainer: { style: { borderBottomWidth: "0px" } },
                      }}
                    >
                      <Panel
                        key="panel"
                        title={
                          <AccordionHeader
                            name={
                              editTemplates.includes(index)
                                ? key
                                : `${key} - v${groupedTemplates[key][0].TemplateVersion}`
                            }
                            duplicate={(name) => duplicateTemplate(name, key)}
                            exportTemplate={() => exportTemplate(key)}
                            deleteItem={() => deleteTemplate(key, index)}
                            edit={() => editTemplate(index)}
                            expands={expands[index]}
                          />
                        }
                      >
                        {editTemplates.includes(index) ? (
                          <>
                            <Block
                              paddingLeft={[0, 0, "32px"]}
                              paddingRight={[0, 0, "32px"]}
                              marginBottom={["16px", "16px", 0]}
                            >
                              <Block
                                marginBottom="8px"
                                className={css(LABEL_STYLE)}
                                data-testid="version-text"
                              >
                                Version
                              </Block>
                              <Select
                                clearable={false}
                                searchable={false}
                                data-testid="edit_template_version"
                                options={groupedTemplates[key].map((t) => ({
                                  id: t.TemplateVersion,
                                  label: "v" + t.TemplateVersion,
                                }))}
                                value={[
                                  {
                                    id: editTemplateVersions[key],
                                    label: "v" + editTemplateVersions[key],
                                  },
                                ]}
                                onChange={(params) =>
                                  setEditTemplateVersions({
                                    ...editTemplateVersions,
                                    [key]: params.option.id,
                                  })
                                }
                                overrides={{
                                  Root: { style: SELECT_OVERRIDE_STYLE.Root },
                                  ControlContainer: {
                                    style:
                                      SELECT_OVERRIDE_STYLE.ControlContainer,
                                  },
                                }}
                              />
                              {editTemplateVersions[key] !==
                                groupedTemplates[key][0].TemplateVersion && (
                                <Block
                                  as="span"
                                  className={css(SUBTEXT_STYLE(theme))}
                                  data-testid="not-latest-version"
                                >
                                  This is not the latest version.
                                </Block>
                              )}
                            </Block>
                            <EditTemplate
                              data={groupedTemplates[key].find(
                                (t) =>
                                  t.TemplateVersion ===
                                  editTemplateVersions[key]
                              )}
                              save={(template) => save(template, index)}
                              saveNewVersion={(template) =>
                                saveNewVersion(template, index)
                              }
                              cancel={(template) =>
                                close(template.TemplateName, index)
                              }
                            />
                          </>
                        ) : (
                          <TemplateTable data={groupedTemplates[key][0]} />
                        )}
                      </Panel>
                    </StatelessAccordion>
                  </React.Fragment>
                </Block>
              ))}
            </>
          ) : state.dolTemplates ? (
            <Block className={css(LABEL_STYLE)}>No data</Block>
          ) : (
            ""
          )}

          {showDeleteRejection && (
            <DeleteRejection
              close={() => setShowDeleteRejection(false)}
              rejectionType={rejectionType}
            />
          )}
        </main>
      </DrawerRouterContainer>
    </>
  );
};

export default Criteria;
