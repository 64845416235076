import Axios from "axios";
import {
  // env,
  // devBaseURL,
  // prodBaseURL,
  // uatBaseURL,
  // giniusdevBaseURL,giniusuatBaseURL ,giniusprodBaseURL,
  studentURL,giniusBaseURL,BaseURL
} from "../config";

// function baseUrl(env) {
//   switch (env) {
//     case "prod":
//       return prodBaseURL;
//     case "dev":
//       return devBaseURL;   
//   }
// }

// function giniusBaseurl(env) {
//   switch (env) {
//     case "prod":
//       return giniusprodBaseURL;
//     case "dev":
//       return giniusdevBaseURL;   
//   }
// }

/**
 * @name HttpClient
 * @description Creates a http client with axios
 * @returns {Axios}
 * @author Lathika
 */

const mainAxios = Axios.create({
  //baseURL: baseUrl(env),
  baseURL:BaseURL
});

const profileAxios = Axios.create({
  //baseURL: giniusBaseurl(env),
  baseURL:giniusBaseURL
});

const studentAxios = Axios.create({
  baseURL: studentURL,
});

const educationAxios = Axios.create({
  baseURL: studentURL,
});

mainAxios.defaults.headers.common[
  "ApiKey"
] = `${process.env.REACT_APP_BASE_URL_APIKEY}`;
profileAxios.defaults.headers.common[
  "ApiKey"
] = `${process.env.REACT_APP_GENIUS_URL_APIKEY}`;
educationAxios.defaults.headers.common[
  "ApiKey" ] = `${process.env.REACT_APP_STUDENT_GO_APIKEY}`;

export { mainAxios, profileAxios, studentAxios,educationAxios };
