import { Block } from "baseui/block";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalBarSeries,
  Hint,
  DiscreteColorLegend,
} from "react-vis";
import { Select, SIZE } from "baseui/select";
import { useStyletron } from "baseui";
import {
  TILE_STYLE,
  TITLE_STYLE,
  HINT_STYLE,
  HINT_TEXT_STYLE,
  HINT_TITLE_STYLE,
  SELECT_OVERRIDE_STYLE,
  MOBILE_SCREEN_BREAKPOINT,
} from "../utils";
import { Cohort_Progress } from "resources/constants/strings";
import { useTrackedState } from "store/store";
import { Skeleton } from "baseui/skeleton";
import { VISUALIZATION_HEIGHT } from "../utils";

const SELECT_ROOT_STYLE = ({ $theme }) => ({
  width: "100%",
  backgroundColor: "white",
  [$theme.mediaQuery.medium]: {
    width: "200px",
  },
});

const ITEMS = [
  { color: "rgb(0, 99, 255)", title: "Completed Learners" },
  { color: "rgba(0, 99, 255, 0.3)", title: "Incompleted Learners" },
];

const OPTIONS = [
  { label: "Phase", id: "phase" },
  { label: "Macro Credential", id: "macro" },
  { label: "Course", id: "micro" },
  { label: "Lesson", id: "lesson" },
  { label: "Assignment", id: "assignment" },
];

const CohortProgress = () => {
  const [css, theme] = useStyletron();

  const [value, setValue] = useState(false);
  const [width, setWidth] = useState(0);
  const [option, setOption] = useState([OPTIONS[0]]);
  const parent = useRef();

  const state = useTrackedState();

  // tile padding = 24 & component spans 2 columns

  const height = VISUALIZATION_HEIGHT;
  const isMobileScreen = width < MOBILE_SCREEN_BREAKPOINT;

  const updateValue = (acc, key, name, value) => {
    if (!acc[key][name]) acc[key][name] = { completed: 0, incompleted: 0 };
    acc[key][name][value < 100 ? "incompleted" : "completed"]++;
  };

  const getData = (value, valueKey) =>
    Object.keys(value).map((key) => {
      const total = value[key].completed + value[key].incompleted;
      const calcValue = value[key][valueKey];
      return {
        y: key,
        x: calcValue,
        total,
        percentage: Math.round((calcValue / total) * 100),
        value: calcValue,
        type: valueKey,
      };
    });

  const progress = useMemo(() => {
    if (!state.learners) return null;

    const defaultProgress = {
      phase: {},
      macro: {},
      micro: {},
      lesson: {},
      assignment: {},
    };

    const filteredLearners = state.learners.filter(
      (learner) => learner.last_name.toLowerCase() !== "learner"
    );

    return filteredLearners.reduce((acc, learner) => {
      const phases = learner.learning_path.phase_list;

      phases.forEach((phase) => {
        updateValue(
          acc,
          "phase",
          phase.phase_name,
          phase?.progress_phase?.progress_actual || 0
        );
        const macros = phase.macro_certification_list;

        macros.forEach((macro) => {
          updateValue(
            acc,
            "macro",
            macro.macro_certification_name,
            macro?.progress_macro_certification?.progress_actual || 0
          );
          const micros = macro.course_list;

          micros.forEach((micro) => {
            updateValue(
              acc,
              "micro",
              micro.course_name,
              micro?.course_progress?.progress_actual || 0
            );
            const lessons = micro.lesson_list;

            lessons.forEach((lesson) => {
              updateValue(
                acc,
                "lesson",
                lesson.lesson_name,
                lesson?.progress_lesson?.progress_actual || 0
              );
              const assignments = lesson.assignment_list;
              assignments.forEach((assignment) => {
                if (
                  assignment.assignment_type !==
                  "Exercise__Independent_Optional"
                ) {
                  updateValue(
                    acc,
                    "assignment",
                    assignment.assignment_name,
                    assignment?.assignment_progress?.progress_actual || 0
                  );
                }
              });
            });
          });
        });
      });

      return acc;
    }, defaultProgress);
  }, [state.learners]);

  const completedData = useMemo(() => {
    if (!progress) return [];

    const currentProgress = progress[option[0].id];
    return getData(currentProgress, "completed");
  }, [progress, option]);

  const incompletedData = useMemo(() => {
    if (!progress) return [];

    const currentProgress = progress[option[0].id];
    return getData(currentProgress, "incompleted");
  }, [progress, option]);

  useEffect(() => {
    if (parent) setWidth(parent.current.clientWidth);
  }, []);

  const getTickFormat = (label) => {
    const MAX_LENGTH = isMobileScreen ? 10 : 31;
    if (label.length <= MAX_LENGTH) return label;
    return label.slice(0, MAX_LENGTH - 1).concat("..");
  };

  const onValueMouseOver = (v) => {
    setValue(v);
  };

  const onSeriesMouseOut = () => {
    setValue(false);
  };

  return (
    <Block className={css({ ...TILE_STYLE(theme) })}>
      <Block
        display="flex"
        flexDirection={["column", "column", "row"]}
        justifyContent="space-between"
        alignItems="flex-start"
        gridGap="16px"
        marginBottom="20px"
      >
        <Block className={css({ ...TITLE_STYLE, marginBottom: 0 })} data-testid="cohort-text">
          {Cohort_Progress}
        </Block>
        <Select
          size={SIZE.compact}
          clearable={false}
          searchable={false}
          options={OPTIONS}
          data-testid="cohort-select"
          value={option}
          onChange={(params) => setOption(params.value)}
          overrides={{
            Root: { style: SELECT_ROOT_STYLE },
            ControlContainer: { style: SELECT_OVERRIDE_STYLE.ControlContainer },
          }}
        />
      </Block>
      <Block
        ref={parent}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gridGap="20px"
        className="cohort-progress"
      >
        {state.isFetching ? (
          <Skeleton width={`${width}px`} height={`${height}px`} />
        ) : (
          <>
            <XYPlot
              width={width}
              height={
                option[0].id === "lesson"
                  ? height * 2
                  : option[0].id === "assignment"
                  ? height * 5
                  : height
              }
              stackBy="x"
              yType="ordinal"
              margin={{ left: isMobileScreen ? 80 : 200 }}
            >
              <VerticalGridLines />
              <XAxis />
              <YAxis
                tickFormat={getTickFormat}
                style={{ text: { textAnchor: "end" } }}
              />
              <HorizontalBarSeries
                data={completedData}
                onValueMouseOver={onValueMouseOver}
                onSeriesMouseOut={onSeriesMouseOut}
                color="rgb(0, 99, 255)"
                barWidth={0.4}
              />
              <HorizontalBarSeries
                data={incompletedData}
                onValueMouseOver={onValueMouseOver}
                onSeriesMouseOut={onSeriesMouseOut}
                color="rgba(0, 99, 255, 0.3)"
                barWidth={0.4}
              />
              {value !== false && (
                <Hint value={value}>
                  <Block className={css(HINT_STYLE)}>
                    <Block className={css(HINT_TITLE_STYLE)}>{value.y}</Block>
                    <Block className={css(HINT_TEXT_STYLE)}>
                      {value.type === "completed" ? "Completed" : "Incompleted"}
                      &nbsp; Learners: {value.value} / {value.total}
                      &nbsp; ({value.percentage}%)
                    </Block>
                  </Block>
                </Hint>
              )}
            </XYPlot>
            <DiscreteColorLegend orientation="horizontal" items={ITEMS} />
          </>
        )}
      </Block>
    </Block>
  );
};

export default CohortProgress;
