import React,{useMemo,useState} from "react";
import { Header } from "components/Header";
import DrawerRouterContainer from "components/newDrawer";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import Title from "./Components/title";
import { fetchJSData } from "services/requests/jsData";
import { useSetState ,useTrackedState} from "store/store";
import { useEffect } from "react";
import { fetchDOLTemplate } from "services/requests/dol_template";
import getRolesPageVisibility from "../../components/getPageVisibility"
import { Redirect} from "react-router-dom";
import StudentData from "./Components/studentData"
import {Select} from 'baseui/select';
import { Skeleton } from "baseui/skeleton";
import {ProgressBar} from 'baseui/progress-bar';

const MAIN_STYLE = (theme) => ({
  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "40px",
  },
});

const BLOCK_STYLE = (theme) => ({
  backgroundColor: "white",
  borderRadius: "8px",
  padding: "24px",
  border: `1px solid ${theme.RAColors?.gray200}`,
  //width: '100%',
  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "24px",
  },
});

const SUMMARY_DROPDOWN =(theme)=>({
  display:'flex',
  width:'100%'
})

const JS = ({ match }) => {
  const [css, theme] = useStyletron();
  const setState = useSetState();
 // let datas = [];
  const [value, setValue] = React.useState([]);
 // const [cohortOptions, setCohortOptions] = React.useState([]);
  const [coursevalue, setCourseValue] = React.useState([]);
  const [journeyvalue, setJourneyValue] = React.useState([]);
  const [courseOptions, setCourseOptions] = React.useState([]);
  const [journeyOptions, setJourneyOptions] = React.useState([]);
  const [courseData, setCourseData] = React.useState([]);
  const [msg, setMsg] = React.useState(null);
  const state = useTrackedState();

  const [pvalue, setPValue] = React.useState(0);
  useInterval(() => {
    if (pvalue < 90 ) {
      setPValue(pvalue + 10);
    } 
    // else {
    //   setPValue(0);
    // }
  }, 1000);

  function useInterval(callback: () => void, delay: number | null) {
    const savedCallback = React.useRef(() => {});
    // Remember the latest callback.
    React.useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
    // Set up the interval.
    React.useEffect((): any => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  useEffect(() => {
    let isUnmounted = false;
    localStorage.setItem("currentPage","learners")
    const getJSData = async () => {
      const userEmail = localStorage.getItem("email") || "";
      const role = localStorage.getItem("role") || "";
      try {
        setState((prev) => ({ ...prev, isDataFetching: true }));
        const response = await fetchJSData(userEmail,role);
        if ((response.status !== 200 && response.status !== 403) || isUnmounted)
          return;
         
        if(response.data.length === 0 || response.data.status === 404){
            setMsg("Logged in user is not found in ALXP data list. Please reach out to admin.")
            const message = "Logged in user is not found in ALXP data list. Please reach out to admin."
            setState((prev) => ({
              ...prev,
              message
            }));
            return;
        }
      //  datas.push(response?.data);
        var newArray = [];
        response?.data?.map((item) => {
          var obj={}
          if(item.cohorts_data !== null)
          {
            obj.id = item.cohort_name;
            obj.name = item.cohort_name
            newArray.push(obj);
          }  
        })
        const cohortOptions = newArray
        const data = response.data
        setState((prev) => ({
          ...prev,
          cohortOptions,
          data,
          isDataFetching: false,
          message:null
        }));
      } catch (e) {
        if (isUnmounted) return
        console.log(e);
        setState((prev) => ({
          ...prev,
          cohortOptions : [],
          data : [],
          message:null,
          isDataFetching: false,
        }));
      }
    };
    if (!state.isDataFetching && !state?.data) getJSData();
    return () => {
      isUnmounted = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callCourseList = (value) =>{
    setJourneyValue(value)
    setCourseValue('')
    var newArray = courseData?.find(function (el) {
      return el.journey_name === value[0].name;
    });
    setCourseOptions(newArray.course)
  }

  const callJourneyList = (value) =>{
    setValue(value)
    setJourneyValue('')
    setCourseValue('')
    var newArray = state?.data?.find(function (el) {
      if(el.cohorts_data !== null || el.cohorts_data !== '')
      {
        return el.cohort_name === value[0].name;
      } 
    });
    setCourseData(newArray?.cohorts_data)
    var newArray1 = [];
    newArray?.cohorts_data?.map((item) => {
      var obj={}
      if(item.cohorts_data !== null && item.cohorts_data !== '')
      {
        obj.id = item.journey_name;
        obj.name = item.journey_name
        newArray1.push(obj);
      }  
    })
    setJourneyOptions( newArray1)
  }

  //page access control - based on role
  // var role = localStorage.getItem("viewAs") !== null ? localStorage.getItem("viewAs") : localStorage.getItem("role");
  // var permittedRoles = getRolesPageVisibility("Learners-AXLP");
  // if (!permittedRoles.includes(role)) return <Redirect to="/student" />;

  return (
    <>
      <Header />
      <DrawerRouterContainer>
        <main className={css(MAIN_STYLE(theme))}>
          <Title />
          <Block
            data-testid="summary"
            className={css(BLOCK_STYLE(theme))}
            display="flex"
            flexWrap="wrap"
            gridGap={["16px", "16px", "40px"]}
          >
        { (state?.data?.length === 0 || state?.data?.length === undefined) && state?.message === undefined ? 
          // <Skeleton width="100%" height={`50px`} animation/>
          <ProgressBar
      value={pvalue}
      overrides={{
        BarProgress: {
          style: ({$theme, $value}) => {
            return {
              ...$theme.typography.font350,
              backgroundColor: '#0000EE',
              color: $theme.colors.mono200,
              position: 'relative',
              ':after': {
                position: 'absolute',
                content: $value > 5 ? `"${$value}%"` : '',
                right: '10px',
              },
            };
          },
        },
        Bar: {
          style: ({$theme}) => ({
            height: $theme.sizing.scale800,
          }),
        },
      }}
    />
        :  state?.message !== null 
        ?
          <Block>{state?.message}</Block> 
        :
          state?.cohortOptions?.length === 0 
        ? (
                // <Skeleton width="100%" height={`50px`} animation/>
                <ProgressBar
                value={pvalue}
                overrides={{
                  BarProgress: {
                    style: ({$theme, $value}) => {
                      return {
                        ...$theme.typography.font350,
                        backgroundColor: '#0000EE',
                        color: $theme.colors.mono200,
                        position: 'relative',
                        ':after': {
                          position: 'absolute',
                          content: $value > 5 ? `"${$value}%"` : '',
                          right: '10px',
                        },
                      };
                    },
                  },
                  Bar: {
                    style: ({$theme}) => ({
                      height: $theme.sizing.scale800,
                    }),
                  },
                }}
              />
          ) : (
            <Block className={css(SUMMARY_DROPDOWN(theme))}>
              <Block style={{width:'100%', display:'flex'}}>
              <Block style={{paddingTop:'12px'}}>{'Cohort Name: '}</Block>
              <Select
                options={state?.cohortOptions}
                labelKey="id"
                valueKey="id"
                onChange={({ value }) => callJourneyList(value)}
                value={value}
                maxDropdownHeight="300px"
                overrides={{
                  Root: {
                    style: ({ $theme }) => ({
                      width:'62%',
                      marginRight:'5%',
                      marginLeft:'3%'
                    })
                  },
                  ControlContainer: {
                    style: ({ $theme }) => ({
                      backgroundColor: '#fff',
                      width:'250px'
                    })
                  }
                }}
              />
             </Block>
             <Block style={{width:'100%', display:'flex'}}>
              <Block style={{paddingTop:'12px'}}>{'Journey Name: '}</Block>
              <Select
                options={journeyOptions}
                labelKey="id"
                valueKey="id"
                onChange={({ value }) => callCourseList(value)}
                value={journeyvalue}
                maxDropdownHeight="300px"
                overrides={{
                  Root: {
                    style: ({ $theme }) => ({
                      width:'62%',
                      marginRight:'5%',
                      marginLeft:'3%'
                    })
                  },
                  ControlContainer: {
                    style: ({ $theme }) => ({
                      backgroundColor: '#fff',
                      width:'250px'
                    })
                  }
                }}
              />
             </Block>         
             <Block style={{width:'100%',display:'flex'}}>
              <Block style={{paddingTop:'12px'}}>{'Course Name: '}</Block>
              <Select
                options={courseOptions}
                labelKey="course_name"
                valueKey="course_name"
                onChange={({ value }) => setCourseValue(value)}
                value={coursevalue}
                maxDropdownHeight="300px"
                overrides={{
                  Root: {
                    style: ({ $theme }) => ({
                      width:'62%',
                      marginLeft:'3%',
                    })
                  },
                  ControlContainer: {
                    style: ({ $theme }) => ({
                      backgroundColor: '#fff',
                      width:'250px'
                    })
                  }
                }}
              />
               </Block>
            </Block>
        )}
          </Block>
          <Block flex="1">
            {coursevalue !== null && coursevalue !== [] && state?.cohortOptions !== undefined? (
              <StudentData data={coursevalue} cohort={state?.cohortOptions}/>
            ) : null}
          </Block>
        </main>
      </DrawerRouterContainer>
    </>
  );
};

export default JS;
