export const Track_Data = [
  "Product Security Analysis",
  "Product Security Testing",
  "Incident Response & threat hunting",
  "Item C",
  "Item D",
];
export const Status_Data = [
  "All statuses",
  "At Risk",
  "Action Needed",
  "On Track",
  "Exceeds",
];
export const TrackSummaryitems = [
  {
    id: "Summary",
    text: "Summary",
  },
  {
    id: "Learner",
    text: "Learner",
  },
];

export const TrackSummaryLessons = [
  "Lecture - Instructor Lead",
  "Lecture - Independent",
  "Exercise - Independent",
  "Exercise - Team",
  "Project - Independent",
];

export const Degree_Data = [
  "Post-Doc",
  "PhD",
  "Masters",
  "Bachelors",
  "Associates",
  "Certification",
  "Diploma",
];

export const Managers_Data = [
  "All managers",
  "ADMIN",
  "TENANT",
  "BOEING MANAGER",
  "INSTRUCTOR",
  "COACH",
  "LEARNER",
];

export const Assignment_Data = ["Lecture"];

export const Attributes_Data = ["All"];

export const ADMIN_LEVEL_ACCESS =[
  "ADMIN",
  "TENANT OWNER",
  "MENTOR"
]