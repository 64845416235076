import Track from 'assets/badges/track.svg'
import TrackLarge from 'assets/badges-large/track_large.svg'

import * as React from "react";
import { Block } from "baseui/block";
import { StatefulPopover, TRIGGER_TYPE, PLACEMENT } from "baseui/popover";
import { useStyletron } from "baseui";
import { COMMON_RADIUS } from "utils/style";

const TrackBadge = ({
  isFaded = false,
  showModal,
}) => {
  const [css] = useStyletron();

  const handleClick = (e) => {
    e.stopPropagation()
    if (showModal) showModal(TrackLarge);
  };

  return (
    <StatefulPopover
      content={isFaded ? null : (
        <Block
          paddingLeft="20px"
          paddingRight="20px"
          paddingTop="40px"
          paddingBottom="40px"
          className={css({
            backgroundColor: "white",
            ...COMMON_RADIUS("8px"),
            cursor: "pointer",
          })}
          onClick={handleClick}
        >
          <Block
            as="img"
            src={TrackLarge}
            alt="Track Badge Large"
            width="220px"
            height="220px"
            data-testid="track-badge-large"
          />
        </Block>
      )}
      triggerType={TRIGGER_TYPE.hover}
      placement={PLACEMENT.rightTop}
      returnFocus
      autoFocus
    >
      <Block
        as="img"
        src={Track}
        alt="Track Badge"
        width="62px"
        data-testid="track-badge"
      />
    </StatefulPopover>
  );
};

export default TrackBadge;