import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { Provider } from "./store/store";
import NewStudent from "./pages/NewStudentOverview";
import TrackSummary from "./pages/TrackSummary/";
// import CourseSummary from "./pages/CourseSummary";
import PersonalInfo from "./pages/Profile/PersonalInfo";
import Login from "./pages/Login/index";
import Landing from "./pages/Landing";
import EmailVerification from "./pages/EmailVerification";
import Assignments from "./pages/NewAssignments";
import Admin from "./pages/Admin";
import AdminDashboard from "./pages/AdminDashboard";
import Dashboard from "./pages/Dashboard";
import DolChecklist from "./pages/DolChecklist";
// import SSOHome from "./pages/SSOHome";
import Jobs from "./pages/Jobs";
import CreateJob from "./pages/Jobs/CreateJob";
import EditJob from "./pages/Jobs/EditJob";
import DOLCriteria from "./pages/DolCriteria";
import NewDOLCriteria from "./pages/DolCriteria/newTemplate";
import CacheBuster from "./CacheBuster";
import Roles from "./pages/RolesPermission";
import "./assets/styles/main.scss";
import KeyCloakSSO from "pages/KeyCloak";
import JS from "pages/JS";
import AdminData from "pages/AdminData";
import GradeAdds from "pages/GradeAdds";
import TrackUser from "pages/TrackUser";
import RaAdmin from "pages/RaAdmin";
import LearnerList from "components/LearnerList";

function App() {
  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return (
          <Provider>
            <div className="App">
              <Router>
                <Switch>
                  <Route exact={true} path="/" component={Login} />
                  <Route exact={true} path="/landing" component={Landing} />
                  <Route exact={true} path="/student" component={NewStudent} />
                  <Route
                    exact={true}
                    path="/tracksummary"
                    component={TrackSummary}
                  />
                  <Route exact={true} path="/roles" component={Roles} />
                  {/* <Route
              exact={true}
              path="/coursesummary"
              component={CourseSummary}
            /> */}
                  <Route
                    exact={true}
                    path="/personal-info"
                    component={PersonalInfo}
                  />
                  <Route exact={true} path="/login" component={Login} />
                  {/* <Route exact={true} path="/company" component={CompanyOverview} /> */}
                  <Route
                    exact={true}
                    path="/assignment"
                    component={Assignments}
                  />
                  <Route
                    exact={true}
                    path="/email-verification"
                    component={EmailVerification}
                  />
                  <Route
                    exact={true}
                    path="/sso-home"
                    component={KeyCloakSSO}
                  />
                  {/* <Route exact={true} path="/sso-home" component={SSOHome} /> */}
                  <Route exact={true} path="/admin" component={Admin} />
                  <Route
                    exact={true}
                    path="/admin-dashboard"
                    component={AdminDashboard}
                  />
                  <Route exact={true} path="/dashboard" component={Dashboard} />
                  <Route
                    exact={true}
                    path="/competency-sign-off"
                    component={DolChecklist}
                  />

                  <Route exact={true} path="/job-roles" component={Jobs} />
                  <Route
                    exact={true}
                    path={"/job-roles/create"}
                    component={CreateJob}
                  />
                  <Route
                    exact={true}
                    path={"/job-roles/:id"}
                    component={EditJob}
                  />

                  <Route
                    exact={true}
                    path={"/competency-manager"}
                    component={DOLCriteria}
                  />
                  <Route
                    exact={true}
                    path={"/competency-manager/new"}
                    component={NewDOLCriteria}
                  />
                  <Route exact={true} path="/learners" component={JS} />
                  <Route
                    exact={true}
                    path="/admin-data"
                    component={AdminData}
                  />
                  <Route exact={true} path="/grade-add" component={GradeAdds} />
                  <Route exact={true} path="/ra-admin" component={RaAdmin} />

                  <Route path="/?error=" component={Login}></Route>
                  <Route path="/track-user" component={TrackUser}></Route>
                  <Route
                    exact={true}
                    path="/lerner"
                    component={GradeAdds}
                  ></Route>
                  <Route
                    exact={true}
                    path="/learner-list"
                    component={LearnerList}
                  ></Route>
                </Switch>
              </Router>
            </div>
          </Provider>
        );
      }}
    </CacheBuster>
  );
}

export default App;
