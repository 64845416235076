import { useStyletron } from "baseui";
import { useEffect, useMemo, useState } from "react";
import { Block } from "baseui/block";
import { Select } from "baseui/select";
import {
  COMMON_BORDER_COLOR,
  COMMON_BORDER_WIDTH,
  COMMON_RADIUS,
} from "utils/style";
import { courseLists } from "services/requests/dol_template";
import { useSetState, useTrackedState } from "store/store";
import { Input } from "baseui/input";

const SELECT_OVERRIDE_STYLE = {
  ControlContainer: ({ $theme, $disabled }) => ({
    backgroundColor: "none",
    opacity: $disabled ? 0.5 : 1,
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    color: $theme.RAColors?.gray900,
  }),
  ValueContainer: ({ $theme }) => ({
    flexWrap: "nowrap",
    overflow: "auto",
  }),
  Root: () => ({
    height: "46px",
    width: "300px",
    maxWidth: "100%",
  }),
};

const INPUT_OVERRIDE_STYLE = {
  Input: ({ $theme }) => ({
    backgroundColor: "none",
    color: $theme.RAColors?.gray900,
    fontFamily: "Manrope",
  }),
  Root: ({ $theme, $error }) => ({
    maxWidth: "100%",
    backgroundColor: "none",
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_COLOR(
      $error ? $theme.RAColors?.red600 : $theme.RAColors?.gray200
    ),
    ...COMMON_BORDER_WIDTH("1px"),
    [$theme.mediaQuery.small]: {
      width: "auto",
    },
    [$theme.mediaQuery.medium]: {
      width: "400px",
    },
  }),
};

const LABEL_STYLE = {
  fontFamily: "Manrope",
  fontSize: "14px",
};

const OPTION_STYLE = (isSelected = false) => ({
  fontSize: "14px",
  lineHeight: "20px",
  color: isSelected ? "#000000" : "#545454",
  fontWeight: isSelected ? "bold" : "normal",
  fontFamily: "system-ui, sans-serif",
  paddingTop: "8px",
  paddingBottom: "8px",
  paddingLeft: "16px",
  paddingRight: "16px",
  cursor: "pointer",
  ":hover": {
    backgroundColor: "#f7f6f6",
  },
});

const CustomDropdown = ({
  options = [],
  value = [],
  onChange,
  name = "",
  multi = false,
}) => {
  const [sort, setSort] = useState("");
  const [key, setKey] = useState("");
  const [css, theme] = useStyletron();

  const filteredOptions = useMemo(() => {
    if (!key) return options;

    return options.filter((o) =>
      o.label.toLowerCase().includes(key.toLowerCase())
    );
  }, [options, key]);

  const sortedOptions = useMemo(() => {
    if (sort === "") return filteredOptions;
    const sortOrder = sort ? 1 : -1;

    return filteredOptions.sort((a, b) => {
      let firstValue = a.label;
      let secValue = b.label;

      return (
        (firstValue !== secValue ? (firstValue < secValue ? -1 : 1) : 0) *
        sortOrder
      );
    });
  }, [filteredOptions, sort]);

  const valueIds = value.map((v) => v.id);

  const handleSort = () => {
    if (sort === "") setSort(true);
    else setSort(!sort);
  };

  const onSelect = (selectedOption) => {
    if (valueIds.includes(selectedOption.id)) return;

    onChange({
      option: selectedOption,
      type: "select",
      value: multi ? [selectedOption, ...value] : [selectedOption],
    });
  };

  return (
    <Block paddingTop="8px" paddingBottom="8px" backgroundColor="white">
      <Block marginBottom="8px" paddingLeft="16px" paddingRight="16px">
        <Input
          overrides={{
            Input: { style: INPUT_OVERRIDE_STYLE.Input },
            Root: { style: INPUT_OVERRIDE_STYLE.Root },
          }}
          value={key}
          onChange={(e) => setKey(e.target.value)}
          placeholder="Enter text here"
        />
        <Block
          display="flex"
          gridGap="8px"
          alignItems="center"
          marginTop="16px"
        >
          <Block as="span" className={css({ fontFamily: "Manrope" })}>
            {name}
          </Block>
          <Block
            onClick={handleSort}
            className={css({
              color: sort !== "" ? "#212121" : theme.RAColors?.gray200,
              cursor: "pointer",
            })}
          >
            <svg width="6" height="12" viewBox="0 0 6 12">
              <path
                d="M3.38381 0.67962C3.33534 0.625845 3.2761 0.582852 3.20996 0.553424C3.14381 0.523995 3.07221 0.508789 2.99981 0.508789C2.92741 0.508789 2.85582 0.523995 2.78967 0.553424C2.72352 0.582852 2.66429 0.625845 2.61581 0.67962L0.115813 3.67962C0.0550083 3.75262 0.0162609 3.84143 0.00410785 3.93566C-0.00804516 4.02989 0.0068993 4.12563 0.0471913 4.21167C0.0874832 4.29771 0.151455 4.37049 0.231615 4.42148C0.311775 4.47248 0.404806 4.49958 0.499813 4.49962H1.99981V10.9996C1.99981 11.1322 2.05249 11.2594 2.14626 11.3532C2.24003 11.4469 2.3672 11.4996 2.49981 11.4996H3.49981C3.63242 11.4996 3.7596 11.4469 3.85337 11.3532C3.94713 11.2594 3.99981 11.1322 3.99981 10.9996V4.49962H5.49981C5.59482 4.49958 5.68785 4.47248 5.76801 4.42148C5.84817 4.37049 5.91214 4.29771 5.95243 4.21167C5.99273 4.12563 6.00767 4.02989 5.99552 3.93566C5.98337 3.84143 5.94462 3.75262 5.88381 3.67962L3.38381 0.67962Z"
                fill="currentColor"
              />
            </svg>
            <svg width="6" height="12" viewBox="0 0 6 12">
              <path
                d="M3.49981 0.5H2.49981C2.3672 0.5 2.24003 0.552678 2.14626 0.646447C2.05249 0.740215 1.99981 0.867392 1.99981 1V7.5H0.499813C0.404806 7.50004 0.311775 7.52714 0.231615 7.57814C0.151455 7.62913 0.0874835 7.70191 0.0471915 7.78795C0.00689952 7.87399 -0.0080452 7.96973 0.00410782 8.06396C0.0162608 8.15819 0.0550086 8.247 0.115813 8.32L2.61581 11.32C2.66274 11.3763 2.72146 11.4215 2.78781 11.4526C2.85417 11.4837 2.92654 11.4998 2.99981 11.4998C3.07308 11.4998 3.14546 11.4837 3.21181 11.4526C3.27817 11.4215 3.33689 11.3763 3.38381 11.32L5.88381 8.32C5.94462 8.247 5.98336 8.15819 5.99552 8.06396C6.00767 7.96973 5.99273 7.87399 5.95243 7.78795C5.91214 7.70191 5.84817 7.62913 5.76801 7.57814C5.68785 7.52714 5.59482 7.50004 5.49981 7.5H3.99981V1C3.99981 0.867392 3.94713 0.740215 3.85337 0.646447C3.7596 0.552678 3.63242 0.5 3.49981 0.5Z"
                fill="currentColor"
              />
            </svg>
          </Block>
        </Block>
      </Block>
      <Block maxHeight="250px" overflow="auto">
        {sortedOptions.map((option, index) => (
          <Block
            key={index}
            className={css(OPTION_STYLE(valueIds.includes(option.id)))}
            onClick={() => onSelect(option)}
          >
            {option.label}
          </Block>
        ))}
      </Block>
    </Block>
  );
};

const CriteriaSelect = ({
  showRTI,
  showJS,
  rti,
  js,
  onRTIChange,
  onJSChange,
}) => {
  const [css] = useStyletron();
  const setState = useSetState();
  const state = useTrackedState();
  const [COURSES, setCOURSES] = useState([]);
  const [ASSIGNMENTS, setASSIGNMENTS] = useState([]);

  useEffect(() => {
    let isMounted = true;
    const getCourses = async () => {
      const response = await courseLists(localStorage.getItem("email"));
      if (response.status !== 200 || !isMounted) return;
      courstList(response.data);
      setState((prev) => ({ ...prev, coursesList: [...response.data] }));
      getAllAssignmnets(response.data);
    };

    if (!state.coursesList) {
      getCourses();
    } else {
      courstList(state.coursesList);
      getAllAssignmnets(state.coursesList);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const courstList = (data) => {
    data.map((obj) => {
      var object = {};
      object.label = obj.CourseName;
      object.id = obj.CourseName;
      COURSES.push(object);
      return COURSES;
    });
    setCOURSES(COURSES);
  };

  const getAllAssignmnets = (data) => {
    var array = [];
    data.map((data) => {
      data.Assignments.map((obj) => {
        var object = {};
        object.label = obj;
        object.id = obj;
        array.push(object);
      });
    });
    setASSIGNMENTS(array);
  };
  return (
    <>
      {showRTI && (
        <Block width={["100%", "100%", "initial"]}>
          <Block marginBottom="8px" className={css(LABEL_STYLE)}>
            RTI
          </Block>
          <Select
            multi
            clearable={false}
            searchable={false}
            options={COURSES}
            value={COURSES.filter((a) => rti?.includes(a.id.trim()))}
            maxDropdownHeight="300px"
            onChange={(value) => {
              onRTIChange(value);
              //onRTIChangeFun(value)
            }}
            overrides={{
              ControlContainer: {
                style: SELECT_OVERRIDE_STYLE.ControlContainer,
              },
              Root: {
                style: SELECT_OVERRIDE_STYLE.Root,
              },
              ValueContainer: {
                style: SELECT_OVERRIDE_STYLE.ValueContainer,
              },
              Dropdown: {
                component: () => (
                  <CustomDropdown
                    options={COURSES}
                    value={COURSES.filter((a) => rti?.includes(a.id.trim()))}
                    onChange={(value) => {
                      onRTIChange(value);
                    }}
                    name="Courses"
                    multi
                  />
                ),
              },
            }}
          />
        </Block>
      )}
      {showJS && (
        <Block width={["100%", "100%", "initial"]}>
          <Block marginBottom="8px" className={css(LABEL_STYLE)}>
            JS
          </Block>
          <Select
            clearable={false}
            searchable={false}
            options={ASSIGNMENTS}
            value={ASSIGNMENTS.filter((a) => a.id.trim() === js)}
            onChange={onJSChange}
            maxDropdownHeight="300px"
            overrides={{
              ControlContainer: {
                style: SELECT_OVERRIDE_STYLE.ControlContainer,
              },
              Root: {
                style: SELECT_OVERRIDE_STYLE.Root,
              },
              ValueContainer: {
                style: SELECT_OVERRIDE_STYLE.ValueContainer,
              },
              Dropdown: {
                component: () => (
                  <CustomDropdown
                    options={ASSIGNMENTS}
                    value={ASSIGNMENTS.filter((a) => a.id.trim() === js)}
                    onChange={onJSChange}
                    name="Assignments"
                  />
                ),
              },
            }}
          />
        </Block>
      )}
    </>
  );
};

export default CriteriaSelect;
