import { UPLOAD_PROFILE, GET_PROFILE } from '../apiEndpoint';
import { studentAxios } from '../http/http'
// var FormData = require('form-data');
// var fs = require('fs');

export default function uploadProfile(emailid,image) {
    //console.log("image",image)
    var form = new FormData();
    //form.append('user_email',emailid);
    form.append('image',image)
    studentAxios.defaults.headers.post['Content-Type'] = 'multipart/form-data'

return studentAxios.post(UPLOAD_PROFILE+'?user_email='+ emailid ,form,
    {headers: {
    "ApiKey":process.env.REACT_APP_STUDENT_GO_APIKEY
  }}
);
}



