import React, { lazy, Suspense, useState, useEffect, useMemo } from "react";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Input } from "baseui/input";
import { Select } from "baseui/select";
import { Checkbox, STYLE_TYPE } from "baseui/checkbox";
import SearchIcon from "baseui/icon/search";
import ProgressBar from "components/progress";
import Loader from "react-js-loader";
import { ChevronDown } from "baseui/icon";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faSliders } from "@fortawesome/free-solid-svg-icons";
import WithoutAssignRolesAvatar from "assets/images/without_assign_roles_avatar.svg";
import DefaultAvatar from "assets/images/user-profile.svg";
import NoAssignRolesAvatar from "assets/images/no_assign_avatar.svg";
import { Enter_Names_Email, Search_Text } from "resources/constants/strings";
import {
  COMMON_RADIUS,
  COMMON_BORDER_COLOR,
  COMMON_BORDER_WIDTH,
  COMMON_CARD_STYLE,
  COMMON_PADDING,
} from "utils/style";
import { StatefulTooltip, PLACEMENT } from "baseui/tooltip";
import automaticSignoff from "services/requests/automaticSignoff";
import { sortAZSelectOptions } from "utils/select";
import { fetchJobRoles } from "services/requests/job_role";

const INPUT_OVERRIDE_STYLE = {
  Input: ({ $theme }) => ({
    backgroundColor: "none",
    color: $theme.RAColors?.gray900,
  }),
  Root: ({ $theme }) => ({
    backgroundColor: "none",
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    width: "100%",
  }),
  StartEnhancer: ({ $theme }) => ({
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: "transparent",
  }),
};
const LABEL_STYLE = (theme) => ({
  fontWeight: 500,
  lineHeight: "22px",
  color: theme.RAColors?.gray900,
  margin: "0 0 4px",
  fontFamily: "Manrope-Medium",

  [theme.mediaQuery.small]: {
    fontSize: "14px",
  },
  [theme.mediaQuery.medium]: {
    fontSize: "16px",
  },
});
const WITHOUT_ROLES_TEXT_STYLE = (theme) => ({
  fontFamily: "Manrope-Bold",
  fontSize: "16px",
  lineHeight: "22px",
  fontWeight: "700px",
  color: "#4C1515",
  textTransform: "capitalize",
});
const ASSIGN_ROLES_TEXT_STYLE = (theme) => ({
  fontFamily: "Manrope-Semibold",
  fontSize: "14px",
  lineHeight: "19px",
  fontWeight: "600px",
  color: theme?.RAColors?.gray800,
});
const NO_ASSIGN_ROLES_TEXT_STYLE = (theme) => ({
  fontFamily: "Manrope-Semibold",
  fontSize: "14px",
  lineHeight: "19px",
  fontWeight: "600px",
  marginTop: "5px",
  color: "#4C1515",
});

const NAME_STYLE = (theme) => ({
  fontFamily: "Manrope-bold",
  fontSize: "16px",
  lineHeight: "22px",
  fontWeight: "700px",
  paddingLeft: "3px",
  color: theme?.RAColors?.black,
});
const LABEL_SORTBY_STYLE = (theme) => ({
  fontFamily: "Manrope",
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "19px",
  width: "70px",
});
const ITEM_CONTENT_STYLE = (theme) => ({
  fontFamily: "Manrope-bold",
  fontSize: "14px",
  lineHeight: "22px",
  fontWeight: "600px",
  color: theme?.RAColors?.gray800,
});
export const SELECT_OVERRIDE_STYLE = {
  ControlContainer: ({ $theme }) => ({
    width: "fit-content",
    backgroundColor: "none",
    color: $theme.RAColors?.blue700,
    ...COMMON_BORDER_WIDTH("0px"),
  }),
};
const SELECT_OVERRIDE_STYLE_JOB = {
  Root: ({ $theme }) => ({ width: "100%" }),
  ControlContainer: ({ $theme }) => ({
    backgroundColor: "none",
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    color: $theme.RAColors?.gray900,
  }),
};
const FILTER_BLOCK_STYLE = (theme) => ({
  maxHeight: "700px",
  overflowY: "scroll",
  overflowX: "hidden",
  width: "300px",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  [theme.mediaQuery.small]: {
    width: "100%",
  },
  [theme.mediaQuery.medium]: {
    width: "100%",
  },
});
const WITHOUT_ROLES_BLOCK_STYLE = (theme) => ({
  display: "flex",
  gridGap: "11px",
  alignItems: "center",
  cursor: "pointer",
  padding: "12px 16px",
  backgroundColor: "#FAEBEB", //#FDF7F7
  borderRadius: "8px",
  height: "40px",
});
const BLOCK_STYLE = (theme) => ({
  display: "flex",
  gridGap: "11px",
  alignItems: "center",
  cursor: "pointer",
  padding: "12px 16px",
});
const SELECTED_BLOCK_STYLE = (theme) => ({
  display: "flex",
  gridGap: "11px",
  alignItems: "center",
  cursor: "pointer",
  padding: "12px 16px",
  backgroundColor: theme.RAColors?.gray100,
  borderRadius: "8px",
  border: `1px solid ${theme.RAColors?.gray200}`,
});

const OPTIONS = [
  { label: "Alphabetical (A to Z)", id: "1" },
  { label: "Alphabetical (Z to A)", id: "2" },
  { label: "Completion (Low to High)", id: "3" },
  { label: "Completion (High to Low)", id: "4" },
  // { label: "Last Updated", id: "5" },
];

const AVATAR_STYLE = { width: "40px", height: "40px", borderRadius: "100%" };
const NO_ASSIGN_AVATAR_STYLE = {
  width: "14px",
  height: "14px",
};
const SIGNOFF_BLOCK_STYLE = (theme) => ({
  backgroundColor: "white",
  //borderRadius: "8px",
  padding: "16px",
  //border: `1px solid ${theme.RAColors?.gray200}`,
  width: "auto",
  [theme.mediaQuery.small]: {
    width: "100%",
  },
  [theme.mediaQuery.medium]: {
    width: "auto",
  },
  [theme.mediaQuery.large]: {
    width: "auto",
  },
});
const TOGGLE_OVERRIDE_STYLE = {
  Root: () => ({
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    height: "44px",
    alignItems: "center",
  }),
  Label: () => ({
    fontFamily: "Manrope",
  }),
};
const clear_btn ={
  display: "inline-block",
    textAlign: "right",
    cursor: "pointer",
    fontSize: "14px",
    color: "rgb(48, 54, 178)",
    margin:'0 0 4px',
    width:"20%",
    fontFamily:"Manrope-Semibold"
}

// const PROFILE_OPTION_ICON_STYLE = {
//   //width: "40px",
//   display: "flex",
//   justifyContent: "end",
//   width: "-webkit-fill-available",
// };
// const DOT_ICON_STYLE = {
//   height: "10px",
//   width: "10px",
//   backgroundColor: "#FF0000",
//   borderRadius: "50%",
// };
// const DOT_ICON_ASSIGNED = {
//   height: "10px",
//   width: "10px",
//   backgroundColor: "green",
//   borderRadius: "50%",
// };
const SORT_STORAGE_KEY = "dol-sort-option";

// const getDefaultSortValue = () => {
//   const defaultOption = [OPTIONS[0]];
//   const storedSortOption = localStorage.getItem(SORT_STORAGE_KEY);

//    if (!storedSortOption) return defaultOption;

//    try {
//      const { sort, expiredTime } = JSON.parse(storedSortOption);

//      if (expiredTime > Date.now()) return sort;

//      return defaultOption;
//   } catch {
//      return defaultOption;
//   }
// };
const ModalComponent = lazy(() => import("./switchRoles/switchRoles.js"));
const AssignRoleModalComponent = lazy(() =>
  import("./assignRoles/assignRoles.js")
);
export const FilterSection = ({
  Data,
  loader,
  changeDolData,
  allLearnerData,
  selectedIndex,
  selectedLearnerEmail,
  updateData = null,
  defaultOption = null,
  rolespermission,
  dataLength,
  searchText
}) => {
  const [css, theme] = useStyletron();
  const [row, setRow] = useState(false);
  const [assignRolesData, setAssignRolesData] = useState(false);
  const [option, setOption] = useState(defaultOption || [OPTIONS[0]]);
  const [searchString, setSearchString] = useState("");
  const [counter, setCounter] = useState(0);
  const [checked, setChecked] = React.useState(false);
  const [assignJobLoader, setAssignJobLoader] = React.useState(false);
  const [autoSIgnOff, setAutoSIgnOff] = React.useState([]);
  const [jobRoleOptions, setJobRoleOptions] = React.useState([]);
  const [jobRole, setJobRole] = useState([]);
  const userEmail = localStorage.getItem("email") || "";
 
  useEffect(() => {
   if(rolespermission?.length > 0)
   {
    const SignoffActions = rolespermission?.filter(
      (obj) => obj.Action_name === "Automated Sign-Off Controls"
    );
    getAutoSignoff(SignoffActions);
   } 
  }, [rolespermission]);

  useEffect(() => {
    try {
      const fetchOption = async () => {
        let jobRoleArray = await getJobRole(userEmail);
        setJobRoleOptions(jobRoleArray);
      };

      fetchOption();
    } catch (e) {
      console.log(e);
    }
  },[])

  const getJobRole = async () => {
    const response = await fetchJobRoles(userEmail, "Published");
    let jobRolesArray = [];
    var newArray = response?.data?.JobRoles?.filter(function (el) {
      return el.RoleName !== "";
    });
    newArray?.forEach((element, index) => {
      let obj = {};
      obj.label = element.RoleName;
      obj.id = index + 1;
      obj.value = element.RoleName;
      obj.index = parseInt(element.Index);
      //jobRolesArray.push(SELECT_JOB[0]);
      jobRolesArray.push(obj);
    });
    return [
      ...new Map(jobRolesArray.map((item) => [item["value"], item])).values(),
    ];
  };

  const assignRolesHandleClick = (data) => {
    setAssignRolesData(data);
  };
  const handleClick = (data) => {
    setRow(data);
  };
  function closeModal() {
    setRow(false);
    setAssignRolesData(false);
    updateData(true);
  }
  function closeAssignRolesModal() {
    setAssignRolesData(false);
    updateData(true);
  }
  const calculateWithoutAssign = (withoutAssignList) => {
    var newArray = withoutAssignList?.filter(function (el) {
      return !el.hasOwnProperty("RoleName");
    });
    if (newArray?.length > 0) {
      return newArray?.length;
    } else {
      return 0;
    }
  };
  const getUnassignedLearners = (dataList) => {
    var filterednewArray = dataList?.filter(function (el) {
      return !el.hasOwnProperty("RoleName");
    });
    return filterednewArray;
  };
  const setSortOption = (params) => {
    const { value } = params;
    setOption(value);
    //const oneDayInMs = 1000 * 60 * 60 * 24;
    //const expiredTime = Date.now() + oneDayInMs;
    // localStorage.setItem(
    //   SORT_STORAGE_KEY,
    //   JSON.stringify({ sort: value, expiredTime })
    // );
    setCounter(1);
  };

  const mockDataVal = (Data || []).filter((learnersList) => {
    const isMatchedJob = jobRole[0]?.value === '' || jobRole[0]?.value === undefined || jobRole[0]?.value === learnersList?.RoleName ;
    const lowercasedSearchString = searchString.toLowerCase();
    const isMatchedNameString = (
      learnersList?.LastName?.toLowerCase() +
      " " +
      learnersList?.FirstName?.toLowerCase()
    ).includes(lowercasedSearchString);
    const isMatchedEmailString = (learnersList?.EmailId).includes(searchString);
    // const isMatchedProductString = learnersList?.product
    //   ?.toLowerCase()
    //   .includes(lowercasedSearchString);
    return (
      (isMatchedNameString || isMatchedEmailString) && isMatchedJob
      //|| isMatchedProductString
    );
  });

  var sortedValues = useMemo(() => {
    if (!option) return mockDataVal;
    let sortOrder;
    var sortedValues = mockDataVal.sort((a, b) => {
      let firstValue, secValue;
      if (option[0].label === "Alphabetical (A to Z)") {
        sortOrder = 1;
        firstValue = a.LastName.toLowerCase();
        secValue = b.LastName.toLowerCase();
      } else if (option[0].label === "Alphabetical (Z to A)") {
        sortOrder = -1;
        firstValue = a.LastName.toLowerCase();
        secValue = b.LastName.toLowerCase();
      }
      // else if (option[0].label === "Last Updated") {
      //   sortOrder = -1;
      //   firstValue = a.UpdateDate;
      //   secValue = b.UpdateDate;
      // }
      else if (option[0].label === "Completion (Low to High)") {
        sortOrder = 1;
        firstValue = a?.LearnerChecklist?.Progress || 0;
        secValue = b?.LearnerChecklist?.Progress || 0;
      } else if (option[0].label === "Completion (High to Low)") {
        sortOrder = -1;
        firstValue = a?.LearnerChecklist?.Progress || 0;
        secValue = b?.LearnerChecklist?.Progress || 0;
      }
      return (
        (firstValue !== secValue ? (firstValue < secValue ? -1 : 1) : 0) *
        sortOrder
      );
    });
    return sortedValues;
  });

  //role permission
  const filteredactions = rolespermission?.filter(
    (obj) => obj.Action_name === "Display All Learners"
  );
  if (filteredactions && filteredactions[0]?.Edit === "N" && dataLength > 0) {
    sortedValues = sortedValues.filter(function (el) {
      return el.EmailId === localStorage.getItem("email"); //Adrian.learner@rapidascent.io
    });
  }

  const initialLoad = () => {
    changeDolData(sortedValues[0]?.EmailId, 0);
  };

  if (counter === 1) {
    setCounter(0);
    initialLoad();
  }
  function call() {
    if(searchString !== ''){
      searchText(true)
    }else{
      searchText(false)
    }

    if (sortedValues.length > 0) {
      changeDolData(sortedValues[0]?.EmailId, 0);
    }
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const capitalizeName = (firstName = "", lastName = "") =>
    `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)}`;

  const NAME_MAX_LENGTH = 20;

  const truncateName = (name = "") =>
    name.length <= NAME_MAX_LENGTH
      ? name
      : name.slice(0, NAME_MAX_LENGTH - 1).concat("..");

  const autoSignoffActions = rolespermission?.filter(
    (obj) => obj.Action_name === "Automated Sign-Off Controls"
  );
  const getAutoSignoff = async (SignoffActions) => {
    const response = await automaticSignoff("", userEmail, "GET");
    if (response?.data?.Value === "true") {
      if (SignoffActions && SignoffActions[0]?.Edit === "N") {
        const payload = {
          Key: "autosignoff",
          Value: "false",
          type: "bool",
        };
        automaticSignoff(payload, userEmail, "PUT")
          .then(function (response) {
            if (response.status === 200) {
              setChecked(false);
            }
          })
          .catch((err) => {
            setAssignJobLoader(false);
            console.log(err);
          });
      }
      setChecked(true);
    }
    setAutoSIgnOff([response?.status, response?.data?.Value]);
  };
  const setAutoSignoffToggle = async (checkedValue) => {
    let isCheckedValue = "";
    if (checkedValue === true) {
      isCheckedValue = "true";
    } else {
      isCheckedValue = "false";
    }
    const payload = {
      Key: "autosignoff",
      Value: isCheckedValue,
      type: "bool",
    };
    setAssignJobLoader(true);
    if (autoSIgnOff[0] === 200) {
      automaticSignoff(payload, userEmail, "PUT")
        .then(function (response) {
          if (response.status === 200) {
            setAssignJobLoader(false);
            setChecked(checkedValue);
            changeDolData(selectedLearnerEmail, selectedIndex);
          }
        })
        .catch((err) => {
          setAssignJobLoader(false);
          console.log(err);
        });
    } else {
      automaticSignoff(payload, userEmail, "POST")
        .then(function (response) {
          if (response.status === 200) {
            setAssignJobLoader(false);
            setChecked(checkedValue);
            changeDolData(selectedLearnerEmail, selectedIndex);
          }
        })
        .catch((err) => {
          setAssignJobLoader(false);
          console.log(err);
        });
    }
  };
  function clearData(){
    setJobRole([])
  }
  return (
    <Block className={css(COMMON_CARD_STYLE(theme))}>
      <Block display="flex" flexWrap="wrap" gridGap="24px" data-testid="filter">
        {autoSignoffActions && autoSignoffActions[0]?.Edit === "Y" && (
          <Block
            width={["100%", "100%", "300px"]}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Block
              className={css(LABEL_STYLE(theme))}
              data-testid="automatic_signoff_title"
            >
              {"Automatic Sign-Off"}
            </Block>
            <Block display="flex" gridGap="16px" alignItems="center">
              <Checkbox
                checked={checked}
                name="automatic_signoff_toggle"
                onChange={(e) => setAutoSignoffToggle(e.target.checked)}
                checkmarkType={STYLE_TYPE.toggle}
                overrides={{
                  Root: { style: TOGGLE_OVERRIDE_STYLE.Root },
                  Label: { style: TOGGLE_OVERRIDE_STYLE.Label },
                }}
              />
            </Block>
          </Block>
        )}
        <Block width={["100%", "100%", "300px"]} data-testid="filter-item">
          <Block
            as="h5"
            className={css(LABEL_STYLE(theme))}
            data-testid="filter-item-title"
          >
            {Search_Text}
          </Block>
          <Input
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            placeholder={Enter_Names_Email}
            clearOnEscape
            data-testid="search_input"
            overrides={{
              Input: { style: INPUT_OVERRIDE_STYLE.Input },
              Root: { style: INPUT_OVERRIDE_STYLE.Root },
              StartEnhancer: { style: INPUT_OVERRIDE_STYLE.StartEnhancer },
            }}
            startEnhancer={
              <SearchIcon size={24} color={theme.RAColors?.gray900} />
            }
            onBlur={call}
          />
        </Block>
        <Block
          data-testid="filter-item"
          width="100%"
        >
          <Block
            as="h5"
            data-testid="filter-item-title"
            className={css(LABEL_STYLE(theme))}
            style={{display:"inline-block",margin:"0 0 4px",width:"80%"}}
          >
            Job Roles
          </Block>
          <Block
            as="h5"
            data-testid="filter-item-title"
            className={css(clear_btn)}
            onClick = {clearData}
          >
           Clear
          </Block>
          <Select
            clearable={false}
            searchable={false}
            options={sortAZSelectOptions(jobRoleOptions)}
            placeholder="Select Job Role"
            value={jobRole}
            onChange={(params) => setJobRole(params.value)}
            maxDropdownHeight="300px"
            overrides={{
              Root: { style: SELECT_OVERRIDE_STYLE_JOB.Root },
              ControlContainer: {
                style: SELECT_OVERRIDE_STYLE_JOB.ControlContainer,
              },
            }}
          />
        </Block>
        <Block
          display={"flex"}
          alignItems={"baseline"}
          width={["100%", "100%", "300px"]}
          data-testid="filter-item"
        >
          <Block
            data-testid="filter-item-title"
            className={css(LABEL_SORTBY_STYLE(theme))}
          >
            {"Sort By"}
          </Block>
          <Select
            clearable={false}
            searchable={false}
            options={OPTIONS}
            value={option}
            data-testid="select_sortBy"
            onChange={setSortOption}
            overrides={{
              Root: {
                style: {
                  fontSize: "14px",
                  fontWeight: "700",
                },
              },
              ValueContainer: {
                style: { ...COMMON_PADDING(0) },
              },
              ControlContainer: {
                style: SELECT_OVERRIDE_STYLE.ControlContainer,
              },
              SelectArrow: () => <ChevronDown size={24} />,
            }}
          />
        </Block>
        <Block
          className={css(FILTER_BLOCK_STYLE(theme))}
          data-testid="student-table-row"
        >
          {calculateWithoutAssign(sortedValues) !== 0 && (
            <Block
              className={css(WITHOUT_ROLES_BLOCK_STYLE(theme))}
              onClick={(e) => {
                e.stopPropagation();
                assignRolesHandleClick([]);
              }}
              width={["100%", "initial"]}
              data-testid="without-roles-block"
            >
              <Block
                as="img"
                src={WithoutAssignRolesAvatar}
                alt="User Avatar"
                className={css(AVATAR_STYLE)}
              />
              <Block overflow="hidden" width="-webkit-fill-available">
                <Block display={"flex"} alignItems="baseline">
                  <Block
                    className={css(WITHOUT_ROLES_TEXT_STYLE(theme))}
                    width="100%"
                    name={"without-roles"}
                  >
                    {calculateWithoutAssign(sortedValues) + " without roles"}
                  </Block>
                </Block>
                <Block
                  display="flex"
                  gridGap="16px"
                  alignItems="center"
                  // marginTop="5px"
                >
                  <Block
                    className={css(ASSIGN_ROLES_TEXT_STYLE(theme))}
                    data-testid="no-roles"
                  >
                    {"Assign roles"}
                  </Block>
                </Block>
              </Block>
            </Block>
          )}
          {sortedValues.map((learnersList, index) => (
            <Block
              style={
                loader && index !== selectedIndex
                  ? { opacity: "0.4", pointerEvent: "none" }
                  : { opacity: "1", pointerEvent: "auto" }
              }
              className={css(
                index === selectedIndex
                  ? SELECTED_BLOCK_STYLE(theme)
                  : BLOCK_STYLE(theme)
              )}
              key={index}
              data-testid="changeDolBlock"
              onClick={() => changeDolData(learnersList?.EmailId, index)}
              width={["100%", "initial"]}
            >
              <Block
                as="img"
                src={learnersList?.ProfileImage || DefaultAvatar}
                alt="User Avatar"
                className={css(AVATAR_STYLE)}
              />
              <Block overflow="hidden" width="-webkit-fill-available">
                <Block display={"flex"} alignItems="center">
                  {!learnersList?.RoleName && (
                    <Block
                      as="img"
                      src={NoAssignRolesAvatar}
                      alt="no assigned Avatar"
                      className={css(NO_ASSIGN_AVATAR_STYLE)}
                    />
                  )}
                  <StatefulTooltip
                    content={() =>
                      capitalizeName(
                        learnersList?.LastName,
                        learnersList?.FirstName
                      ).length > NAME_MAX_LENGTH ? (
                        <Block
                          padding="4px"
                          className={css({
                            ...NAME_STYLE(theme),
                            color: "white",
                          })}
                        >
                          {capitalizeName(
                            learnersList?.LastName,
                            learnersList?.FirstName
                          )}
                        </Block>
                      ) : null
                    }
                    showArrow
                    placement={PLACEMENT.top}
                  >
                    <Block className={css(NAME_STYLE(theme))}>
                      {truncateName(
                        capitalizeName(
                          learnersList?.LastName,
                          learnersList?.FirstName
                        )
                      )}
                    </Block>
                  </StatefulTooltip>
                  {/* <Block
                    className={css(
                      learnersList?.RoleName
                        ? DOT_ICON_ASSIGNED
                        : DOT_ICON_STYLE
                    )}
                  /> */}
                </Block>
                {learnersList?.RoleName ? (
                  <Block
                    display="flex"
                    gridGap="16px"
                    alignItems="center"
                    marginTop="5px"
                  >
                    <Block width="65px">
                      <ProgressBar
                        name={"Progress"}
                        value={learnersList?.LearnerChecklist?.Progress}
                        targetValue={0}
                      />
                    </Block>
                    <Block
                      className={css(ITEM_CONTENT_STYLE(theme))}
                      data-testid="progress_content"
                    >
                      {learnersList?.LearnerChecklist?.Progress
                        ? learnersList?.LearnerChecklist?.Progress
                        : 0}
                      %
                    </Block>
                    {/* {!learnersList?.RoleName
                  <Block className={css(PROFILE_OPTION_ICON_STYLE)}>
                    <FontAwesomeIcon
                      icon={faSliders}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleClick(learnersList);
                      }}
                    />
                  </Block>
                  : null} */}
                  </Block>
                ) : (
                  <Block
                    className={css(NO_ASSIGN_ROLES_TEXT_STYLE(theme))}
                    data-testid="no_roles_assign"
                  >
                    {"No role assigned"}
                  </Block>
                )}
              </Block>
            </Block>
          ))}
        </Block>
      </Block>
      <Suspense fallback={<div />}>
        {row && (
          <ModalComponent
            row={row}
            close={() => closeModal()}
            changeDolData={changeDolData}
            selectedIndex={selectedIndex}
          />
        )}
        {assignRolesData && calculateWithoutAssign(sortedValues) !== 0 && (
          <AssignRoleModalComponent
            assignRolesData={assignRolesData}
            close={() => closeAssignRolesModal()}
            allLearnerData={allLearnerData}
            withoutAssignRolesLearner={getUnassignedLearners(sortedValues)}
            handleClick={(data) => handleClick(data)}
          />
        )}
      </Suspense>
      {assignJobLoader ? (
        <Loader
          type="bubble-loop"
          bgColor={"#332ff0"}
          color={"#332ff0"}
          size={100}
        />
      ) : null}
    </Block>
  );
};

export default FilterSection;
