export const convertDateToYYYYMMDD = (d) => d.toISOString().split('T')[0].split('-').join('')

export const deepCopy = (value) => JSON.parse(JSON.stringify(value))

export const trimSpacesInObject = (value) => {
  const replacer = (key, value) => {
    if (typeof value === 'string') return value.trim();
    if (value === undefined) return null;
    return value;
  };

  return JSON.parse(JSON.stringify(value, replacer));
}