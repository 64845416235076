//import Data from "../pages/MockData/rolespermission.json"

export default function getRolesPageVisibility(pageName) {
    var arr = [];
    var Data = JSON.parse(localStorage.getItem("rolesPermission"))
    Data?.filter(function (el) {
      if(el?.data[0]?.page === "Page Visibility"){
        el?.data[0]?.items?.filter(function (obj) {
           if(obj?.Action_name === pageName && obj?.Edit === "Y"){
              arr.push(el.role)
           }
        })
      }
    });
    return arr;
  }