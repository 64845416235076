import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Button, KIND } from "baseui/button";
import { ButtonGroup } from "baseui/button-group";
import { ArrowLeft, DeleteAlt } from "baseui/icon";
import { Input } from "baseui/input";
import { Modal, ModalBody } from "baseui/modal";
import { Notification } from "baseui/notification";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import { StatefulTooltip } from "baseui/tooltip";
import DrawerRouterContainer from "components/newDrawer";
import React, { useEffect } from "react";
import DownloadIcon from "assets/images/download-icon.svg";
import {
  BACK,
  Download_Data,
  Kahoot_Username,
  LEARNER_LIST,
  THM_USERNAME,
} from "resources/constants/strings";
import basicInfoUpdate from "services/requests/student_basicinfo_update";
import { fetchStudentList } from "services/requests/tenats";
import {
  CANCEL_BUTTON_OVERRIDE_STYLE,
  COMMON_BORDER_COLOR,
  COMMON_BORDER_STYLE,
  COMMON_BORDER_WIDTH,
  COMMON_RADIUS,
  DOWNLOAD_ICON_STYLE,
  HEADING_TEXT_STYLE,
  INPUT_OVERRIDE_STYLE,
  MODAL_BODY_STYLE,
  SAVE_BTN_STYLE,
  TITLE_STYLE,
} from "utils/style";
import { Header } from "./Header";
import { useHistory } from "react-router-dom";
import { fetchLearner } from "services/requests/cohort_student_data";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const MAIN_STYLE = (theme) => ({
  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "40px",
  },
});

const TABLE_OVERRIDE_STYLE = {
  Root: ({ $theme }) => ({
    ...COMMON_RADIUS("8px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    ...COMMON_BORDER_STYLE("solid"),
  }),
  MessageCell: { minWidth: "8%", width: "25%" },
  TimeCell: { width: "25%" },
  IdCell: { width: "25%" },
  ViewCell: { width: "5%" },
};

const BLOCK_STYLE = (theme) => ({
  backgroundColor: "white",
  borderRadius: "8px",
  padding: "24px",
  border: `1px solid ${theme.RAColors?.gray200}`,

  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "24px",
  },
});
const BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  paddingTop: "12px",
  paddingBottom: "12px",
  paddingRight: "24px",
  paddingLeft: "24px",
  ...COMMON_BORDER_COLOR($theme.RAColors?.blue700),
  backgroundColor: $theme.RAColors?.blue700,
  ":hover": { backgroundColor: $theme.RAColors?.blue800 },
  color: "white",

  [$theme.mediaQuery.small]: {
    width: "100%",
  },
  [$theme.mediaQuery.medium]: {
    width: "initial",
  },
});
export const COLUMN_INPUTS_STYLE = (theme, n = 2) => ({
  display: "grid",
  gridTemplateColumns: `repeat(1, 1fr)`,
  columnGap: "20px",
  marginBottom: "20px",

  [theme.mediaQuery.medium]: {
    gridTemplateColumns: `repeat(${n}, 1fr)`,
  },
});

export const INPUT_LABEL_STYLE = (theme) => ({
  display: "inline-block",
  lineHeight: "22px",
  color: theme.RAColors?.gray900,
  margin: "0 0 4px",

  [theme.mediaQuery.small]: {
    fontSize: "14px",
  },
  [theme.mediaQuery.medium]: {
    fontSize: "16px",
  },
});

export default function LearnerList() {
  const [css, theme] = useStyletron();
  const [learnersList, setLearnersList] = React.useState([]);
  const [isOpenModal, setIsOpenModal] = React.useState(false);
  const [names, setNames] = React.useState({
    email: "",
    thmusername: "",
    kahootusername: "",
  });
  const [successMessage, setSuccessMessage] = React.useState(false);
  const today = new Date();
  const month = (today.getMonth() + 1).toString().padStart(2, "0");
  const year = today.getFullYear();
  const date = today.getDate().toString().padStart(2, "0");
  const history = useHistory();
  useEffect(() => {
    getStudentList();
  }, []);
  const handleModal = (item) => {
    setNames({
      thmusername: item?.thmusername,
      kahootusername: item?.kahootusername,
      email: item?.learner_email,
    });
    setIsOpenModal(!isOpenModal);
  };
  const getStudentList = () => {
    fetchStudentList(localStorage.getItem("email"))
      .then(function (response) {
        console.log("Response", response.data);
        setLearnersList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    basicInfoUpdate(names?.email, names).then(function (response) {
      console.log(response, names);
      //setApiStatusMessage(true);
      if (response.status === 200) {
        //setApiStatusMessage(false);
        setSuccessMessage(true);
        setIsOpenModal(false);
        setTimeout(() => {
          setSuccessMessage(false);
        }, 3000);
      }
      getStudentList();
    });
  };
  function downloadCSV(data, filename) {
    const csv = data.join("\n");
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  const getLearnerList = (row, index) => {
    fetchLearner(row.learner_email)
      .then(function (response) {
        const numbersArray = `q${index}`;

        downloadCSV(
          Array(response.data),
          `${row.firstName}_${row.lastName}_${numbersArray}_${year}${month}${date}.csv`
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <Header />
      <DrawerRouterContainer>
        <main className={css(MAIN_STYLE(theme))}>
          <Block
            width={"100%"}
            display={"flex"}
            paddingBottom={"1rem"}
            justifyContent={"flex-end"}
            lineHeight={10}
            gridGap="16px"
          >
            <Block>
              <Button
                onClick={() => history.push("/ra-admin")}
                overrides={{
                  BaseButton: { style: BUTTON_OVERRIDE_STYLE },
                }}
                data-testid="download-button"
              >
                <span>{BACK}</span>
              </Button>
            </Block>
          </Block>
          <Block className={css(BLOCK_STYLE(theme))}>
            <TableBuilder
              data={learnersList}
              //   isLoading={isFetching || false}
              emptyMessage="No Data"
              overrides={{ Root: { style: TABLE_OVERRIDE_STYLE.Root } }}
            >
              <TableBuilderColumn
                header="Email"
                overrides={{
                  TableHeadCell: { style: TABLE_OVERRIDE_STYLE.IdCell },
                  TableBodyCell: { style: TABLE_OVERRIDE_STYLE.IdCell },
                }}
              >
                {(row) => (
                  <div data-testid="row-message-linkforce">
                    {row.learner_email}
                  </div>
                )}
              </TableBuilderColumn>
              <TableBuilderColumn
                header="Name"
                overrides={{
                  TableHeadCell: { style: TABLE_OVERRIDE_STYLE.TimeCell },
                  TableBodyCell: { style: TABLE_OVERRIDE_STYLE.TimeCell },
                }}
              >
                {(row) => (
                  <div data-testid="row-message-linkforce">
                    {row.firstName} {row.lastName}
                  </div>
                )}
              </TableBuilderColumn>
              <TableBuilderColumn
                header="THM Username"
                overrides={{
                  TableHeadCell: {
                    style: TABLE_OVERRIDE_STYLE.MessageCell,
                  },
                  TableBodyCell: {
                    style: TABLE_OVERRIDE_STYLE.MessageCell,
                  },
                }}
              >
                {(row) => <div data-testid="row-time">{row.thmusername}</div>}
              </TableBuilderColumn>
              <TableBuilderColumn
                header="Kahoot Username"
                overrides={{
                  TableHeadCell: {
                    style: TABLE_OVERRIDE_STYLE.MessageCell,
                  },
                  TableBodyCell: {
                    style: TABLE_OVERRIDE_STYLE.MessageCell,
                  },
                }}
              >
                {(row) => (
                  <div data-testid="row-time">{row.kahootusername}</div>
                )}
              </TableBuilderColumn>
              <TableBuilderColumn
                header="Action"
                overrides={{
                  TableHeadCell: {
                    style: TABLE_OVERRIDE_STYLE.MessageCell,
                  },
                  TableBodyCell: {
                    style: TABLE_OVERRIDE_STYLE.MessageCell,
                  },
                }}
              >
                {(row, index) => (
                  <Block display={"flex"} gridGap="12px">
                    <StatefulTooltip content="Edit">
                      <div data-testid="row-time">
                        <FontAwesomeIcon
                          icon={faEdit}
                          style={{ cursor: "pointer" }}
                          // className={css(DOWNLOAD_ICON_STYLE)}
                          onClick={(e) => handleModal(row)}
                          size={30}
                          className="warning cursor-pointer"
                        />
                      </div>
                    </StatefulTooltip>
                    <StatefulTooltip content="Download">
                      <div data-testid="row-time">
                        <FontAwesomeIcon
                          icon={faDownload}
                          style={{ cursor: "pointer" }}
                          // className={css(DOWNLOAD_ICON_STYLE)}
                          onClick={(e) => getLearnerList(row, index)}
                          size={30}
                          className="warning"
                        />
                      </div>
                    </StatefulTooltip>
                  </Block>
                )}
              </TableBuilderColumn>
            </TableBuilder>
          </Block>
        </main>
      </DrawerRouterContainer>
      <Modal
        onClose={handleModal}
        closeable
        isOpen={isOpenModal}
        animate
        autoFocus
      >
        <ModalBody className={css(MODAL_BODY_STYLE(theme))}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
            style={{ width: "100%" }}
          >
            <Block
              display="block"
              justifyContent="space-between"
              alignItems="center"
              gridGap="16px"
              marginBottom={["16px", "16px", "20px"]}
              flexWrap="wrap"
            >
              <Block className={css(TITLE_STYLE(theme))}>
                <Block
                  as="h5"
                  margin={0}
                  className={css(HEADING_TEXT_STYLE(theme))}
                >
                  <span>{"Learner UserName"}</span>
                </Block>
              </Block>
              <Block marginBottom={"16px"}>
                <Block
                  className={css(INPUT_LABEL_STYLE(theme))}
                  data-testid="basic_thmUsername_label"
                >
                  {Kahoot_Username}
                </Block>
                <Input
                  value={names.kahootusername || ""}
                  onChange={(e) =>
                    setNames({
                      ...names,
                      kahootusername: e.target.value,
                    })
                  }
                  data-testid="roleNameInput"
                  required={true}
                  overrides={{
                    Input: { style: INPUT_OVERRIDE_STYLE.Input },
                    Root: { style: INPUT_OVERRIDE_STYLE.Root },
                  }}
                />
              </Block>
              <Block>
                <Block
                  className={css(INPUT_LABEL_STYLE(theme))}
                  data-testid="basic_thmUsername_label"
                >
                  {THM_USERNAME}
                </Block>
                <Input
                  value={names.thmusername || ""}
                  onChange={(e) =>
                    setNames({
                      ...names,
                      thmusername: e.target.value,
                    })
                  }
                  data-testid="roleNameInput"
                  required={true}
                  overrides={{
                    Input: { style: INPUT_OVERRIDE_STYLE.Input },
                    Root: { style: INPUT_OVERRIDE_STYLE.Root },
                  }}
                />
              </Block>
            </Block>
            <Block>
              <ButtonGroup
                size="compact"
                overrides={{
                  Root: { style: { display: "flex", gap: "20px" } },
                }}
              >
                <Button
                  type="submit"
                  data-testid="save"
                  overrides={{ BaseButton: { style: SAVE_BTN_STYLE } }}
                >
                  Save
                </Button>
                <Button
                  type="button"
                  onClick={handleModal}
                  overrides={{
                    BaseButton: { style: CANCEL_BUTTON_OVERRIDE_STYLE },
                  }}
                  data-testid="cancel"
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </Block>
          </form>
        </ModalBody>
      </Modal>
      {successMessage && (
        <Notification
          overrides={{
            Body: {
              style: ({ $theme }) => ({
                position: "absolute",
                top: "10%;",
                right: "0",
                background: "green",
                color: "#fff",
              }),
            },
            CloseIcon: {
              component: DeleteAlt,
              style: { float: "right", cursor: "pointer" },
            },
          }}
          kind={KIND.positive}
          closeable
        >
          {"data saved successfully"}
        </Notification>
      )}
    </>
  );
}
