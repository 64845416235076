import { JOB_ROLE } from "../apiEndpoint";
import { educationAxios } from "../http/http";

export default function createNewJobRole(useremail,payload, isUpdate) {
  if(isUpdate){
    return educationAxios.put(`${JOB_ROLE}?user_email=${useremail}`, payload);
  }else{
    return educationAxios.post(`${JOB_ROLE}?user_email=${useremail}`, payload);

  }
}
