import "./styles.css";
import { Link } from "react-router-dom";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import { useMemo, useState, useEffect } from "react";
import { Menu } from "baseui/icon";
import { env } from "resources/envManage";
//import { item } from "./menu.js";
import getMenu from "./menu"

const DRAWER_STYLE = (theme, isMobile = true, isCollapsed) => ({
  boxSizing: "border-box",
  background: "white",
  width: isMobile ? (isCollapsed ? 0 : "256px") : "50px",
  boxShadow: "2px 0px 4px rgb(0 0 0 / 5%)",
  transition: "width 0.3s",
  overflow: "hidden",
  zIndex: 10000,
  position: "absolute",
  height: "calc(100vh - 68px)",
  ":hover": isMobile
    ? {}
    : {
        width: "256px",
      },
});

const DRAWER_ITEM_STYLE = {
  padding: "12px 9px",
  margin: "6px",
  ":hover": {
    background: "rgba(0, 0, 0, 0.04)",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

const DRAWER_ITEM_TEXT_STYLE = {
  fontFamily: "Manrope-Bold",
  paddingLeft: "16px",
  fontSize: "16px",
  color: "black",
  minWidth: "200px",
};

const OVERLAY_STYLE = {
  height: "calc(100vh - 68px)",
  width: "100%",
  position: "absolute",
  zIndex: 9999,
};

const DRAWER_CONTENT_STYLE = (theme) => ({
  flex: "1 1 auto",
  height: "calc(100vh - 68px)",
  overflow: "auto",
  [theme.mediaQuery.small]: {
    marginLeft: "0px",
  },
  [theme.mediaQuery.medium]: {
    marginLeft: "50px",
  },
});

const Drawer = (props) => {
  const [css, theme] = useStyletron();
  const [isCollapsed, setCollapsed] = useState(true);
  const [isMobile, setMobile] = useState(false);

  const role = localStorage.getItem("role") === "ADMIN" ? localStorage.getItem("viewAs") === null ? localStorage.getItem("role") : localStorage.getItem("viewAs") : localStorage.getItem("role");
  const path = "https://training.rapidascent.com/sign-in";
  var item  = getMenu();
  const filteredItem = useMemo(() => {
    return item.filter((i) => {
      if (!role || !i.role || !Array.isArray(i.role)) return true;

      return i.role.includes(role);
    });
  }, [props?.update]);
  
  
  const filteredEnvironment = useMemo(() => {
    const currentEnv = process.env.REACT_APP_ENVIRONMENT;

    return (
      env.filter((i) => {
        return i === currentEnv;
        //&& role==="LEARNER";
      }).length > 0
    );
  }, []);

  useEffect(() => {
    let isMounted = true;
    setMobile(window.innerWidth < 600);

    const resizeEvent = window.addEventListener("resize", () => {
      if (isMounted) {
        setMobile(window.innerWidth < 600);
        setCollapsed(true);
      }
    });

    return () => {
      window.removeEventListener("resize", resizeEvent);
      isMounted = false;
    };
  }, []);

  return (
    <>
      <Block
        display={["flex", "flex", "none"]}
        position="absolute"
        top="18px"
        left="8px"
        className={css({ zIndex: 9999, cursor: "pointer" })}
        onClick={() => setCollapsed((v) => !v)}
      >
        <Menu size={32} />
      </Block>
      <Block display="flex">
        {isMobile && !isCollapsed && (
          <Block
            className={css(OVERLAY_STYLE)}
            onClick={() => setCollapsed(true)}
          />
        )}
        {filteredEnvironment ? null : (
          <Block className={css(DRAWER_STYLE(theme, isMobile, isCollapsed))}>
            {filteredItem.map((i, index) => {
              const IconTag = i.icon

              return (
                <Link
                  to={i.text === "Job Training" ? { pathname: path } : (i.text === "Learners - THM" && localStorage.getItem("role") === 'LEARNER') ? { pathname: '/tracksummary' } : i.route}
                  key={index}
                  target={i.text === "Job Training" ? "_blank" : null}
                >
                  <Block
                    as="li"
                    display="flex"
                    alignItems="center"
                    className={css(DRAWER_ITEM_STYLE)}
                  >
                    <Block display="flex" width="20px">
                      <IconTag />
                    </Block>
                    <Block as="span" className={css(DRAWER_ITEM_TEXT_STYLE)}>
                      {i.text}
                    </Block>
                  </Block>
                </Link>
              )
            })}
          </Block>
        )}

        <Block className={css(DRAWER_CONTENT_STYLE(theme))}>
          {props.children}
        </Block>
      </Block>
    </>
  );
};

export default Drawer;
