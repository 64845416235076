import React from "react";
import { Redirect } from "react-router-dom";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import { Tabs, Tab, ORIENTATION } from "baseui/tabs";
import { Your_Profile, Security_Privacy } from "resources/constants/strings";
import BasicInfo from "./Components/BasicInfo";
import SecurityInfo from "./Components/SecurityInfo";
import Education from "./Components/Education";
import TryHackMeInfo from "./Components/Tryhackme";
import Links from "./Components/Links";
import userProfile from "services/requests/user_api";
import { Header } from "components/Header";
import DrawerRouterContainer from "components/newDrawer";
import {
  TAB_OVERRIDE_STYLE,
  TAB_CONTENT_HEADING_STYLE,
  TAB_CONTENT_OVERRIDE_STYLE,
  TAB_BAR_OVERRIDE_STYLE,
} from "./style.js";

export default function PersonalInfo() {
  const [css] = useStyletron();
  const [userProfileData, setUserProfileData] = React.useState([]);
  const [loader, setLoader] = React.useState(false);
  const [activeKey, setActiveKey] = React.useState("0");
  const [updateData, setUpdateData] = React.useState(false);
  const [isLargeScreen, setLargeScreen] = React.useState(true);

  React.useEffect(() => {
    setLoader(true);
    localStorage.setItem("currentPage", "personal-info");
    userProfile(localStorage.getItem("email"))
      .then(function (response) {
        if (response.status === 200) {
          setUserProfileData(response.data[0]);
          if (response.data[0].firstname || response.data[0].lastname) {
            localStorage.setItem("firstName", response.data[0].firstname);
            localStorage.setItem("lastName", response.data[0].lastname);
            localStorage.setItem("thm_username", response.data[0].thmusername);
            var uName =
              response.data[0].firstname + " " + response.data[0].lastname;
            localStorage.setItem("username", uName);
          }

          setLoader(false);
          setUpdateData(false);
        } else {
          setLoader(false);
        }
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        console.error("Error during service worker registration:", error);
      });

    setLargeScreen(window.innerWidth > 1136);

    const handleResize = () => {
      setLargeScreen(window.innerWidth > 1136);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [updateData]);
  return localStorage.getItem("username") === "" ||
    localStorage.getItem("username") === null ||
    localStorage.getItem("username") === undefined ? (
    <Redirect to="/login" />
  ) : (
    <Block>
      <Header />
      <DrawerRouterContainer>
        <Block
          padding={["16px", "16px", "16px", "40px"]}
          overflow="auto"
          // height="calc(100vh - 98px)"
        >
          <Tabs
            onChange={({ activeKey }) => {
              setActiveKey(activeKey);
            }}
            overrides={{
              TabBar: {
                style: TAB_BAR_OVERRIDE_STYLE,
              },
              Root: { style: { backgroundColor: "#F9F9F9" } },
              TabContent: {
                style: TAB_CONTENT_OVERRIDE_STYLE,
              },
            }}
            activeKey={activeKey}
            orientation={
              isLargeScreen ? ORIENTATION.vertical : ORIENTATION.horizontal
            }
          >
            <Tab
              title={Your_Profile}
              overrides={{
                Tab: {
                  style: TAB_OVERRIDE_STYLE,
                },
              }}
            >
              <Block
                display="flex"
                flexDirection="column"
                gridGap={["16px", "16px", "16px", "20px"]}
              >
                <Block
                  className={css(TAB_CONTENT_HEADING_STYLE)}
                  data-testid="your_profile"
                >
                  {Your_Profile}
                </Block>
                <BasicInfo
                  basicProfileData={userProfileData}
                  updateData={updateData}
                  setUpdateData={setUpdateData}
                  loader={loader}
                />
                <Links
                  profileData={userProfileData}
                  updateData={updateData}
                  setUpdateData={setUpdateData}
                  loader={loader}
                />
                <Education loader={loader} />
              </Block>
            </Tab>
            <Tab
              title={Security_Privacy}
              overrides={{
                Tab: {
                  style: TAB_OVERRIDE_STYLE,
                },
              }}
            >
              <Block display="flex" flexDirection="column" gridGap="20px">
                <Block
                  className={css(TAB_CONTENT_HEADING_STYLE)}
                  data-testid="security_info"
                >
                  {Security_Privacy}
                </Block>
                <SecurityInfo
                  updateData={updateData}
                  setUpdateData={setUpdateData}
                  profileData={userProfileData}
                  loader={loader}
                />
                <TryHackMeInfo loader={loader} />
              </Block>
            </Tab>
          </Tabs>
        </Block>
      </DrawerRouterContainer>
    </Block>
  );
}
