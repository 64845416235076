import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { ChevronDown, ChevronUp } from "baseui/icon";
import { Panel, StatelessAccordion } from "baseui/accordion";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import Loader from "react-js-loader";
import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import TaskBlock from "../dolCompetency/taskBlock";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "../style.css";

import CompletedCircle from "assets/svg/completedCircle.svg";
import IncompletedCircle from "assets/svg/incompletedCircle.svg";
import EmptyCircle from "assets/svg/emptyCircle.svg";
import Progress1 from "assets/svg/progress1.svg";
import Progress2 from "assets/svg/progress2.svg";
import Progress3 from "assets/svg/progress3.svg";
import Progress4 from "assets/svg/progress4.svg";
import Progress5 from "assets/svg/progress5.svg";
import Progress6 from "assets/svg/progress6.svg";
import Progress7 from "assets/svg/progress7.svg";
import Progress8 from "assets/svg/progress8.svg";
import Progress9 from "assets/svg/progress9.svg";
import Progress10 from "assets/svg/progress10.svg";
import { ContinuousColorLegend } from "react-vis";
const COMMON_TEXT_STYLE = {
  fontSize: "14px",
  lineHeight: "19px",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
const PANEL_TITLE_STYLE = (theme) => ({
  display: "flex",
  alignItems: "center",
  fontFamily: "Manrope-semibold",
  fontWeight: 600,
  lineHeight: "22px",
  color: theme.RAColors?.black,
  fontSize: "18px",
});
const ToggleIcon = ({ $expanded, $theme }) => {
  const [css] = useStyletron();
  const color = $theme?.RAColors?.gray900;
  if ($expanded)
    return (
      <ChevronUp
        data-html2canvas-ignore
        className={css({ minWidth: "20px" })}
        size={20}
        color={color}
      />
    );
  return (
    <ChevronDown
      data-html2canvas-ignore
      size={20}
      className={css({ minWidth: "20px" })}
      color={color}
    />
  );
};
const CATEGORY_ACCORDION_STYLE = {
  Header: ({ $theme }) => ({
    paddingBottom: "0px",
    paddingRight: "0px",
    paddingLeft: "0px",
    paddingTop: "0px",
  }),
  Content: ({ $theme }) => ({
    paddingRight: "0px",
    paddingLeft: "0px",
    paddingTop: "20px",
    paddingBottom: "0px",
    [$theme.mediaQuery.small]: {
      paddingLeft: "0px",
    },
    [$theme.mediaQuery.medium]: {
      paddingLeft: "0px",
    },
    [$theme.mediaQuery.large]: {
      paddingLeft: "0px",
    },
  }),
};
const COMPETENCY_ACCORDION_STYLE = {
  Header: ({ $theme }) => ({
    paddingBottom: "10px",
    paddingRight: "16px",
    paddingLeft: "16px",
    paddingTop: "10px",
    backgroundColor: "#F9F9F9",
  }),
  Content: ({ $theme }) => ({
    paddingRight: "0px",
    paddingLeft: "16px",
    paddingTop: "16px",
    paddingBottom: "16px",
    [$theme.mediaQuery.small]: {
      marginRight: "0px",
      overflowX: "none",
    },
    [$theme.mediaQuery.medium]: {
      marginRight: "16px",
      overflowX: "auto",
    },
    [$theme.mediaQuery.large]: {
      marginRight: "0px",
      overflowX: "none",
    },
  }),
  Root: ({ $theme }) => ({
    border: `1px solid ${$theme.RAColors?.gray200}`,
    borderRadius: "8px",
    overflow: "hidden",
    boxSizing: "border-box",
  }),
};
const DESCRIPTION_TEXT_STYLE = (theme) => ({
  ...COMMON_TEXT_STYLE,
  fontFamily: "Manrope-semibold",
  fontWeight: 600,
  color: theme.RAColors?.black,
});
const TASKS_BLOCK_STYLE = (theme) => ({
  display: "flex",
  alignItems: "center",
  [theme.mediaQuery.small]: {
    display: "block",
  },
  [theme.mediaQuery.medium]: {
    display: "flex",
  },
  [theme.mediaQuery.large]: {
    display: "flex",
  },
});
const COMPETENCY_ICON_STYLE = { width: "16px", height: "16px" };
const SEPRATOR = {
  borderBottom: "1px solid #e0e0e0",
  marginBottom: "24px",
};
const OTHER_BLOCK_STYLE = (theme) => ({
  backgroundColor: "white",
  borderRadius: "16px",
  border: `1px solid ${theme.RAColors?.gray200}`,
  width: "60px",
  height: "60px",
  padding: "12px",
});
const VERIFY_TEXT_STYLE = (theme) => ({
  display: "flex",
  justifyContent: "center",
  paddingTop: "10px",
  fontFamily: "Manrope",
  fontWeight: 600,
  lineHeight: "16px",
  color: theme.RAColors?.gray700,
  fontSize: "16px",
});
const DolCompetencies = forwardRef(
  (
    {
      Data,
      dolDetails,
      getAvailableTasks,
      changeDolData,
      selectedIndex,
      rolespermission,
      handleClick,
      learnerCheckboxValue,
      technicalCheckboxValue,
      managerCheckboxVlue,
      selectedValue,
      rtiCheckboxValue,
      openVerifyCompetencyModal,
      loader,
      bulkItems,
      collapse

    },
    ref
  ) => {
    const [css, theme] = useStyletron();
    const [expands, setExpands] = useState(null);
    const [items, setItems] = useState(null);
    const [checked, setChecked] = React.useState(false);
    const [bulksignoffItems, setBulkSignOffItems] = React.useState([]);
    const [filteredsignoffItems, setFilteredSignOffItems] = React.useState([]);
    const changeParent = (parentIndex,method) => {
      if (!expands?.[parentIndex]) return;

      const newExpands = [...expands];
      if(method === "checkbox"){
        newExpands[parentIndex].checked = !newExpands[parentIndex].checked;
      }else{
        newExpands[parentIndex].expanded = !newExpands[parentIndex].expanded;
      } 

      setExpands(newExpands);
    };

    if(selectedValue === 'All competencies'){
      learnerCheckboxValue = false;
      technicalCheckboxValue = false; 
       managerCheckboxVlue = false;
      rtiCheckboxValue = false;
    }

    useEffect(() => {
      if(selectedValue === 'All competencies'){
        learnerCheckboxValue = false;
        technicalCheckboxValue = false;
        managerCheckboxVlue = false;
        rtiCheckboxValue = false;
      }
      fetchData(dolDetails);
    }, [Data,learnerCheckboxValue,technicalCheckboxValue,selectedValue,managerCheckboxVlue,rtiCheckboxValue,dolDetails]);

    const fetchData  = (data) => {
      let result = [];
      let newarr = [];

      let parents = [];
      data?.Category?.forEach((cat) => {
        const parent = {
          expanded: collapse === "collapse" ? false : cat.isAccordionOpen === true,
          checked:false,
          children: (cat?.Criteria || []).map((cri) => ({
            expanded: collapse === "collapse" ? false : cri.isAccordionOpen === true ,
            checked:false,
            completion: cri.CircleFillValue, //getCircleValue(cri.CircleFillValue),
          })),
        };
        parents.push({
          ...parent,
          completion: cat.CircleFillValue, //getCircleValue(cat.CircleFillValue),
        });
  
      });
      if(learnerCheckboxValue){
       data?.Category?.filter((obj,ind) => {
        let category = {};
        let newarr1 = [];
        obj?.Criteria?.filter((objs,ind1) => {
          if( (objs?.hasOwnProperty("RTI") && objs?.hasOwnProperty("JS") && objs?.["RTI"]?.CompletionStatus === true && objs?.["JS"]?.CompletionStatus === true) ||
              (objs?.hasOwnProperty("RTI") && objs?.["RTI"]?.CompletionStatus === true &&  !objs?.hasOwnProperty("JS")) ||
              (!objs?.hasOwnProperty("RTI") && objs?.hasOwnProperty("JS") &&  objs?.["JS"]?.CompletionStatus === true) ||
              (!objs?.hasOwnProperty("RTI") && !objs?.hasOwnProperty("JS")) )
            {
                  if(objs?.hasOwnProperty("LearnerProficientStatus") && objs?.["LearnerProficientStatus"]?.CompletionStatus !== true)
                  {
                    category.CategoryId = obj.CategoryId
                    category.CategoryName = obj.CategoryName
                    category.CircleFillValue=obj.CircleFillValue
                    category.IsCatagoryCompleted=obj.IsCatagoryCompleted
                    category.TemplateId=obj.TemplateId
                    newarr1.push(objs)
                    category.Criteria = newarr1
                    newarr.push(category)
                    result = newarr;    
                  }
            }
            checkedFilters(ind,ind1,"Enable",'',parents)
            });
          })
      }
      if(technicalCheckboxValue){
        data?.Category?.filter((obj,ind) => {
          let category = {};
          let newarr1 = [];
            obj?.Criteria?.filter((objs,ind1) => {
              if( (objs?.hasOwnProperty("RTI") && objs?.hasOwnProperty("JS") && objs?.["RTI"]?.CompletionStatus === true && objs?.["JS"]?.CompletionStatus === true) ||
                  (objs?.hasOwnProperty("RTI") && objs?.["RTI"]?.CompletionStatus === true &&  !objs?.hasOwnProperty("JS")) ||
                  (!objs?.hasOwnProperty("RTI") && objs?.hasOwnProperty("JS") &&  objs?.["JS"]?.CompletionStatus === true) ||
                  (!objs?.hasOwnProperty("RTI") && !objs?.hasOwnProperty("JS")) )
                {
                  if(objs?.hasOwnProperty("MentorProficientStatus") && objs?.["MentorProficientStatus"]?.CompletionStatus !== true)
                  {
                    category.CategoryId = obj.CategoryId
                    category.CategoryName = obj.CategoryName
                    category.CircleFillValue=obj.CircleFillValue
                    category.IsCatagoryCompleted=obj.IsCatagoryCompleted
                    category.TemplateId=obj.TemplateId
                    newarr1.push(objs)
                    category.Criteria = newarr1
                    newarr.push(category)
                    result = newarr
                  }
                } 
                checkedFilters(ind,ind1,"Enable",'',parents)
            });
        });
      }
      if(managerCheckboxVlue){
      let signoffarr = [];
      data?.Category?.filter((obj,ind) => {
        let category = {};
        let newarr1 = [];
          obj?.Criteria?.filter((objs,ind1) => {
            if( (objs?.hasOwnProperty("MentorProficientStatus") && objs?.["MentorProficientStatus"]?.CompletionStatus === true && !objs?.hasOwnProperty("LearnerProficientStatus")) ||
                (!objs?.hasOwnProperty("MentorProficientStatus") && objs?.hasOwnProperty("LearnerProficientStatus") && objs?.["LearnerProficientStatus"]?.CompletionStatus === true) ||
                (objs?.hasOwnProperty("MentorProficientStatus") && objs?.hasOwnProperty("LearnerProficientStatus") && objs?.["MentorProficientStatus"]?.CompletionStatus === true && objs?.["LearnerProficientStatus"]?.CompletionStatus === true)
              )
              {
                if(objs?.hasOwnProperty("FinalApprovalStatus") && objs?.["FinalApprovalStatus"]?.CompletionStatus !== true)
                {
                      category.CategoryId = obj.CategoryId
                      category.CategoryName = obj.CategoryName
                      category.CircleFillValue=obj.CircleFillValue
                      category.IsCatagoryCompleted=obj.IsCatagoryCompleted
                      category.TemplateId=obj.TemplateId
                      objs.parentIndex = ind;
                      objs.childIndex = ind1;
                      newarr1.push(objs)
                      category.Criteria = newarr1
                      newarr.push(category)
                      result = newarr
                      signoffarr.push(objs)                     
                }
              }
              checkedFilters(ind,ind1,"Enable",signoffarr,parents)
            setBulkSignOffItems(signoffarr)
            
          });   
      });
   }
   if(rtiCheckboxValue){
    data?.Category?.filter((obj,ind) => {
      let category = {};
      let newarr1 = [];
        obj?.Criteria?.filter((objs,ind1) => {
          if(objs?.["RTI"]?.CompletionStatus !== true &&
          objs?.hasOwnProperty("RTI")){
             category.CategoryId = obj.CategoryId
             category.CategoryName = obj.CategoryName
             category.CircleFillValue=obj.CircleFillValue
             category.IsCatagoryCompleted=obj.IsCatagoryCompleted
             category.TemplateId=obj.TemplateId
             newarr1.push(objs)
             category.Criteria = newarr1
             newarr.push(category)
            result = newarr;          
          }
          checkedFilters(ind,ind1,"Enable",'',parents) //Enable - checkbox enable
        });
  });
}
  if(!rtiCheckboxValue && !managerCheckboxVlue && !technicalCheckboxValue && !learnerCheckboxValue){
      data?.Category?.filter((obj,ind) => {
          obj?.Criteria?.filter((objs,ind1) => {
            checkedFilters(ind,ind1,"Disable",'',parents)
        })
      })
      result = data?.Category ?  data?.Category : []
    }

    const results = result.reduce((r, {CategoryId, Criteria,CategoryName,CircleFillValue ,IsCatagoryCompleted,TemplateId}) => {
      if(r.get(CategoryId)) r.get(CategoryId).Criteria.push(...Criteria);
      else r.set(CategoryId, {CategoryId, CategoryName,CircleFillValue ,IsCatagoryCompleted,TemplateId,Criteria});
      return r;
    }, new Map).values(); 

    let vals = [...results];

    let filteres_list = vals?.filter((obj) => {   
      let newarr1 = [];  
        obj.Criteria = obj?.Criteria?.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.CriteriaID === value.CriteriaID
        ))
        )  
        //console.log("obj.Criteria",obj.Criteria)
    })
    

    // obj.Criteria.filter( (ele, ind) => ind === obj.Criteria.findIndex( elem => elem.CriteriaID === ele.CriteriaID))    
    //ind === obj.Criteria.findIndex( elem => elem.CriteriaID === ele.CriteriaID)
    //let filteres_list = result.filter( (ele, ind) => ind === result.findIndex( elem => elem.CategoryId === ele.CategoryId))
    //console.log("filteres_list",filteres_list)
    setItems(vals);
  }
  const checkedFilters = (parentIndex, childIndex,val,data,parents) =>{
    if (!parents?.[parentIndex]?.children?.[childIndex]) return;
      const newExpands = [...parents];
      if(val === "Enable"){
        newExpands[parentIndex].children[childIndex].checked = true;
       
        newExpands[parentIndex].children[childIndex].expanded = true
        if(data){
          setFilteredSignOffItems(data)
          bulkItems(data,"core");
        }    
      }else{
        newExpands[parentIndex].children[childIndex].checked = false
          setFilteredSignOffItems([])
          bulkItems([],"core");
      }    
      setExpands(newExpands);
  }
    const changeChild = (parentIndex, childIndex,method,Id,val) => {
      if (!expands?.[parentIndex].children?.[childIndex]) return;
      const newExpands = [...expands];
      const newBulkItems = [...bulksignoffItems]
      if(method === "checkbox"){
        newExpands[parentIndex].children[childIndex].checked =
        !newExpands[parentIndex].children[childIndex].checked;
        var arr = []
        if(filteredsignoffItems && !val){
          var newArray = filteredsignoffItems.filter(function(item)
          {
              return  !(item.CriteriaID === Id)
          });
          arr = newArray;
        }else{
          var addArr = newBulkItems.find(function(item)
          {
              return (item.CriteriaID === Id)
          });
          const newArray = [...filteredsignoffItems];
          newArray.push(addArr)
          arr = newArray
        }
        setFilteredSignOffItems(arr)
        bulkItems(arr,"core");
      }else{
        newExpands[parentIndex].children[childIndex].expanded =
        !newExpands[parentIndex].children[childIndex].expanded;
      }
      setExpands(newExpands);
    };

    const expandAll = () => {
      const newExpands = expands.map((expand) => {
        const { children } = expand;
        const newChildren = children.map((child) => ({
          expanded: true,
          checked: child.checked,
          completion: child.completion,
        }));
        return {
          expanded: true,
          completion: expand.completion,
          checked: expand.checked,
          children: newChildren,
        };
      });
      setExpands(newExpands);
    };

    const collapseAll = () => {
      const newExpands = expands.map((expand) => {
        const { children } = expand;
        const newChildren = children.map((child) => ({
          expanded: false,
          checked: child.checked,
          completion: child.completion,
        }));
        return {
          expanded: false,
          completion: expand.completion,
          checked: expand.checked,
          children: newChildren,
        };
      });
      setExpands(newExpands);
    };

    const getCircleIcon = (status = "empty") => {
      const maps = {
        empty: EmptyCircle,
        incompleted: IncompletedCircle,
        completed: CompletedCircle,
      };

      return maps[status] || EmptyCircle;
    };

    const getCircleValue = (progress) => {
      if (progress === 100) return "completed";
      else if (progress === 50) return "incompleted";
      return "empty";
    };

    useImperativeHandle(ref, () => ({
      expandAll,
      collapseAll,
    }));

    // useEffect(() => {
    //   let parents = [];
    //   dolDetails?.Category?.forEach((cat) => {
    //     const parent = {
    //       expanded: cat.isAccordionOpen === true,
    //       checked:false,
    //       children: (cat?.Criteria || []).map((cri) => ({
    //         expanded: cri.isAccordionOpen === true,
    //         checked:false,
    //         completion: cri.CircleFillValue, //getCircleValue(cri.CircleFillValue),
    //       })),
    //     };

    //     parents.push({
    //       ...parent,
    //       completion: cat.CircleFillValue, //getCircleValue(cat.CircleFillValue),
    //     });
    //   });

    //   setExpands(parents);
    //   console.log("useeffect expand--->",parents)
    // }, [dolDetails]);
    const AllActivities = ({ itemData, categoryData }) => {
      return (
        <>
          <Block className={css(TASKS_BLOCK_STYLE(theme))}>
            {Object.keys(itemData).map((item, index) => {
              if (getAvailableTasks(itemData)?.includes(item)) {
                return (
                  <TaskBlock
                    item={item}
                    itemData={itemData}
                    Data={Data}
                    categoryData={categoryData}
                    changeDolData={changeDolData}
                    selectedIndex={selectedIndex}
                    rolespermission={rolespermission}
                    handleClick={handleClick}
                    openVerifyCompetencyModal={openVerifyCompetencyModal}
                    key={index}
                    index={index}
                  />
                );
              }
            })}
          </Block>
        </>
      );
    };

    const RenderAccordion = ({
      item,
      index,
      expand,
      changeParent,
      changeChild,
    }) => {
      const accordionItem = item?.Criteria;

      return (
        <React.Fragment key={index}>
          <StatelessAccordion
            accordion={false}
            expanded={expand?.expanded ? ["panel"] : []}
            onChange={() => changeParent(index,'')}
          >
            <Panel
              key="panel"
              overrides={{
                ToggleIcon,
                Content: {
                  style: CATEGORY_ACCORDION_STYLE.Content,
                },
                Header: {
                  style: CATEGORY_ACCORDION_STYLE.Header,
                },
                PanelContainer: { style: { borderBottomWidth: "0px" } },
              }}
              title={
                <Block className={css(PANEL_TITLE_STYLE(theme))}>
                  <StatefulPopover
                    content={() => (
                      <Block
                        className={css(OTHER_BLOCK_STYLE(theme))}
                        data-testid="percentage-popup-block"
                      >
                        <CircularProgressbar
                          value={item?.CircleFillValue}
                          text={`${item?.CircleFillValue}%`}
                          styles={buildStyles({
                            pathColor: `#09702B`,
                            textColor: `#09702B`,
                          })}
                        />
                      </Block>
                    )}
                    triggerType={TRIGGER_TYPE.hover}
                    showArrow
                    returnFocus
                    autoFocus
                  >
                    <Block
                      as="img"
                      src={
                        item?.CircleFillValue >= 10 && item?.CircleFillValue < 19
                          ? Progress2
                          : item?.CircleFillValue >= 20 && item?.CircleFillValue < 29
                          ? Progress3
                          : item?.CircleFillValue >= 30 && item?.CircleFillValue < 39
                          ? Progress4
                          : item?.CircleFillValue >= 40 && item?.CircleFillValue < 49
                          ? Progress5
                          : item?.CircleFillValue >= 50 && item?.CircleFillValue < 59
                          ? Progress6
                          : item?.CircleFillValue >= 60 && item?.CircleFillValue < 69
                          ? Progress7
                          : item?.CircleFillValue >= 70 && item?.CircleFillValue < 79
                          ? Progress8
                          : item?.CircleFillValue >= 80 && item?.CircleFillValue < 89
                          ? Progress9
                          : item?.CircleFillValue >= 90 && item?.CircleFillValue < 99
                          ? Progress10
                          : item?.CircleFillValue === 100
                          ? CompletedCircle
                          : Progress1
                      }
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "100%",
                        marginRight: "12px",
                      }}
                    />
                  </StatefulPopover>
                  {item?.CategoryName}
                </Block>
              }
            >
              <Block display="flex" flexDirection="column" gridGap="16px">
                {accordionItem?.map((itemData, indexItem) => (
                  <Block key={indexItem} display="flex" alignItems="baseline">
                    {!rtiCheckboxValue && managerCheckboxVlue && !technicalCheckboxValue && !learnerCheckboxValue ?
                     <Checkbox
                     onChange={() => changeChild(index, indexItem,"checkbox",itemData?.CriteriaID,!expand?.children?.[indexItem]?.checked)}
                      checked={expand?.children?.[indexItem]?.checked
                      }
                      data-testid="learner-category"
                      labelPlacement={LABEL_PLACEMENT.right}
                      overrides={{
                        Root: {
                          style: {
                            marginRight: "12px",
                          },
                        },
                      }}
                    /> : null}
                    <StatelessAccordion
                      accordion={false}
                      expanded={
                        expand?.children?.[indexItem]?.expanded
                          ? ["childPanel"]
                          : []
                      }
                      onChange={() => changeChild(index, indexItem,'','','')}
                      overrides={{
                        Content: {
                          style: COMPETENCY_ACCORDION_STYLE.Content,
                        },
                        Header: {
                          style: COMPETENCY_ACCORDION_STYLE.Header,
                        },
                        Root: {
                          style: COMPETENCY_ACCORDION_STYLE.Root,
                        },
                        PanelContainer: {
                          style: { borderBottomWidth: "0px" },
                        },
                      }}
                    >
                      <Panel
                        key="childPanel"
                        title={
                          <Block
                            display="flex"
                            gridGap="10px"
                            alignItems="center"
                          >
                            <StatefulPopover
                              content={() => (
                                <Block
                                  className={css(OTHER_BLOCK_STYLE(theme))}
                                  data-testid="percentage-popup-block"
                                >
                                  <CircularProgressbar
                                    value={itemData?.CircleFillValue
                                      //expand?.children?.[indexItem]?.completion
                                    }
                                    text={`${itemData?.CircleFillValue}%`}
                                    styles={buildStyles({
                                      pathColor: `#09702B`,
                                      textColor: `#09702B`,
                                    })}
                                  />
                                </Block>
                              )}
                              triggerType={TRIGGER_TYPE.hover}
                              showArrow
                              returnFocus
                              autoFocus
                            >
                              <Block
                                as="img"
                                src={
                                  itemData?.CircleFillValue >=
                                    10 &&
                                    itemData?.CircleFillValue < 19
                                    ? Progress2
                                    : itemData?.CircleFillValue >= 20 &&
                                    itemData?.CircleFillValue < 29
                                    ? Progress3
                                    : itemData?.CircleFillValue >= 30 &&
                                    itemData?.CircleFillValue < 39
                                    ? Progress4
                                    : itemData?.CircleFillValue >= 40 &&
                                    itemData?.CircleFillValue < 49
                                    ? Progress5
                                    : itemData?.CircleFillValue >= 50 &&
                                    itemData?.CircleFillValue < 59
                                    ? Progress6
                                    : itemData?.CircleFillValue >= 60 &&
                                    itemData?.CircleFillValue < 69
                                    ? Progress7
                                    : itemData?.CircleFillValue >= 70 &&
                                    itemData?.CircleFillValue < 79
                                    ? Progress8
                                    : itemData?.CircleFillValue >= 80 &&
                                    itemData?.CircleFillValue < 89
                                    ? Progress9
                                    : itemData?.CircleFillValue >= 90 &&
                                    itemData?.CircleFillValue < 99
                                    ? Progress10
                                    : itemData?.CircleFillValue === 100
                                    ? CompletedCircle
                                    : Progress1
                                }
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "100%",
                                  marginRight: "12px",
                                }}
                              />
                            </StatefulPopover>
                            <Block
                              className={css(DESCRIPTION_TEXT_STYLE(theme))}
                              key={indexItem}
                            >
                              {itemData?.CriteriaName}
                            </Block>
                          </Block>
                        }
                      >
                        <AllActivities
                          itemData={itemData}
                          categoryData={item}
                        />
                      </Panel>
                    </StatelessAccordion>
                  </Block>
                ))}
              </Block>
            </Panel>
          </StatelessAccordion>
        </React.Fragment>
      );
    };
    const RenderAccordionWrapper = ({
      data,
      expands,
      changeParent,
      changeChild,
    }) => {
      return (
        <Block display="flex" flexDirection="column" gridGap="16px">
          {data?.length ? (
            data?.map((item, index) => (
              <RenderAccordion
                key={index}
                item={item}
                index={index}
                expand={expands[index]}
                changeParent={changeParent}
                changeChild={changeChild}
              />
            ))
          ) : (
            <Block>No competencies match the filter selection</Block>
          )}
        </Block>
      );
    };

    if (!dolDetails || !expands) return <Block />;

    return (
      <Block>
        <Block display="flex" flexDirection="column">
          {/* <Block className={css(SEPRATOR)} /> */}
          <RenderAccordionWrapper
            expands={expands}
           //data={dolDetails?.Category}
            data={items}
            changeParent={changeParent}
            changeChild={changeChild}
          />
        </Block>
        {loader ? (
          <Loader
            type="bubble-loop"
            bgColor={"#332ff0"}
            color={"#332ff0"}
            size={100}
          />
        ) : null}
      </Block>
    );
  }
);

export default DolCompetencies;
