import React from "react";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import {
  COMMON_RADIUS,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_STYLE,
} from "utils/style";

const HEADING_STYLE = (theme) => ({
  fontFamily: "Epilogue-Semibold",
  fontSize: "28px",
  lineHeight: "40px",
  color: theme.RAColors?.gray900,
});

export default function Title({ exportPdf}) {
  const [css, theme] = useStyletron();
  return (
    <Block
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gridGap="16px"
      marginBottom={["16px", "16px", "20px"]}
      flexWrap="wrap"
    >
      <Block as="h3" className={css(HEADING_STYLE(theme))} data-testid="title">
        {"RA-Admin Student Lists"}
      </Block>
    </Block>
  );
}
