import { LOGGEDIN_USER } from "../apiEndpoint";
import { educationAxios } from "../http/http";

export default function loggedUser(
  useremail = "",
  name="",
  methodType = ""
) {
  if (methodType === "GET") {
    return educationAxios.get(`${LOGGEDIN_USER}`)
  } else if (methodType === "POST") {
    return educationAxios.post(`${LOGGEDIN_USER}?user_email=${useremail}&name=${name}`)
  }
}
