import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { useMemo } from "react";
import * as dayjs from "dayjs";
import { COMPLETED_ON, QUESTIONS_ANSWERED } from "resources/constants/strings";
import {
  StatefulPopover,
  PLACEMENT,
  TRIGGER_TYPE,
} from 'baseui/popover';
import InfoIcon from "assets/images/info_icon.svg";
import { Link } from "react-router-dom";

const COMMON_TEXT_STYLE = {
  fontSize: "14px",
  lineHeight: "19px",
  overflow: "hidden",
  textOverflow: "ellipsis",
};
const NAME_STYLE = (theme) => ({
  ...COMMON_TEXT_STYLE,
  fontFamily: "Manrope-Bold",
  color: theme.RAColors?.blue700,
  marginBottom: "8px",
});
const TASKS_TAGS_STYLE = {
  ...COMMON_TEXT_STYLE,
  fontFamily: "Manrope-Medium",
  padding: "6px",
  width: "50%",
  borderRadius: "8px",
  textAlign: "center",
  height: "calc(100% - 60px)",
};
const SKILL_TEXT_STYLE = (theme) => ({
  ...COMMON_TEXT_STYLE,
  fontFamily: "Manrope-Medium",
  padding: "6px 12px",
  borderRadius: "100px",
  textAlign: "center",
  height: "calc(100% - 32px)",
  [theme.mediaQuery.small]: {
    padding: "10px 12px",
    height:"auto",
    borderRadius: "20px",
    width:"100%"
  },
  [theme.mediaQuery.large]: {
    padding: "6px 12px",
    height: "calc(100% - 32px)",
    borderradius:"100px",
    width: "auto"
  }
});
const SKILL_TEXT_STYLE_BLOCK = (theme) => ({
  display: "flex",
  gridGap: "10px",
  flexDirection: "row",
  flexWrap: "wrap",
});
const STATUS_BLOCK = {
  top: "16px",
  left: "16px",
  width: "calc(100% - 32px)",
  height: "calc(100% - 32px)",
};
const STATUS_STYLE =  {
  ...COMMON_TEXT_STYLE,
  fontFamily: "Manrope-Medium",
  padding: "6px 12px",
  borderRadius: "100px",
};
const COMMON_PROGRESS_STYLE = {
  height: "6px",
  width: "120px",
  borderRadius: "100px",
};
const QUESTION_ANSWERED_TEXT = (theme) => ({
  fontFamily: "Manrope",
  fontSize: "14px",
  color: theme.RAColors?.gray800
})
const PROGRESS_SECTION = (theme) =>({
  display: "flex",
  [theme.mediaQuery.small]: {
    display: "inline-flex",
  },
  [theme.mediaQuery.large]: {
    display: "flex",
  }
})
const QUESTION_ANSWERED_VALUE = (theme) => ({
  fontFamily: "Manrope",
  fontSize: "14px",
  marginLeft: "12px",
  color: theme.RAColors?.black
})
const COMPLETION_DATE_TEXT = (theme) => ({
  fontFamily: "Manrope-semibold",
  fontSize: "12px",
  color: theme.RAColors?.gray900
})

const COMPETENCY_ICON_STYLE = { width: "16px", height: "16px" , marginLeft: "10px" };
export const TableHeader = ({ name, sorted=[], asc=false, onSort=()=>{}, size = "lg",enableSort=false }) => {
  const [css,theme] = useStyletron();
  return (
    <Block data-testid="table-header">
      <Block
        as="span"
        className={css({
          fontSize: size === "lg" ? "16px" : "14px",
          lineHeight: size === "lg" ? "22px" : "19px",
          fontFamily: 'Manrope-Semibold',
        })}
        data-testid="table-header-text"
      >
        {name}
      </Block>
      {enableSort?  <Block
        as="span"
        marginLeft="8px"
        className={css({
          color: sorted === name ? "#212121" : theme.RAColors?.gray200,
          cursor: "pointer",
        })}
        data-testid="table-header-icon"

        onClick={() => onSort(name, asc)}
      >
        <svg width="6" height="12" viewBox="0 0 6 12">
          <path
            d="M3.38381 0.67962C3.33534 0.625845 3.2761 0.582852 3.20996 0.553424C3.14381 0.523995 3.07221 0.508789 2.99981 0.508789C2.92741 0.508789 2.85582 0.523995 2.78967 0.553424C2.72352 0.582852 2.66429 0.625845 2.61581 0.67962L0.115813 3.67962C0.0550083 3.75262 0.0162609 3.84143 0.00410785 3.93566C-0.00804516 4.02989 0.0068993 4.12563 0.0471913 4.21167C0.0874832 4.29771 0.151455 4.37049 0.231615 4.42148C0.311775 4.47248 0.404806 4.49958 0.499813 4.49962H1.99981V10.9996C1.99981 11.1322 2.05249 11.2594 2.14626 11.3532C2.24003 11.4469 2.3672 11.4996 2.49981 11.4996H3.49981C3.63242 11.4996 3.7596 11.4469 3.85337 11.3532C3.94713 11.2594 3.99981 11.1322 3.99981 10.9996V4.49962H5.49981C5.59482 4.49958 5.68785 4.47248 5.76801 4.42148C5.84817 4.37049 5.91214 4.29771 5.95243 4.21167C5.99273 4.12563 6.00767 4.02989 5.99552 3.93566C5.98337 3.84143 5.94462 3.75262 5.88381 3.67962L3.38381 0.67962Z"
            fill="currentColor"
          />
        </svg>
        <svg width="6" height="12" viewBox="0 0 6 12">
          <path
            d="M3.49981 0.5H2.49981C2.3672 0.5 2.24003 0.552678 2.14626 0.646447C2.05249 0.740215 1.99981 0.867392 1.99981 1V7.5H0.499813C0.404806 7.50004 0.311775 7.52714 0.231615 7.57814C0.151455 7.62913 0.0874835 7.70191 0.0471915 7.78795C0.00689952 7.87399 -0.0080452 7.96973 0.00410782 8.06396C0.0162608 8.15819 0.0550086 8.247 0.115813 8.32L2.61581 11.32C2.66274 11.3763 2.72146 11.4215 2.78781 11.4526C2.85417 11.4837 2.92654 11.4998 2.99981 11.4998C3.07308 11.4998 3.14546 11.4837 3.21181 11.4526C3.27817 11.4215 3.33689 11.3763 3.38381 11.32L5.88381 8.32C5.94462 8.247 5.98336 8.15819 5.99552 8.06396C6.00767 7.96973 5.99273 7.87399 5.95243 7.78795C5.91214 7.70191 5.84817 7.62913 5.76801 7.57814C5.68785 7.52714 5.59482 7.50004 5.49981 7.5H3.99981V1C3.99981 0.867392 3.94713 0.740215 3.85337 0.646447C3.7596 0.552678 3.63242 0.5 3.49981 0.5Z"
            fill="currentColor"
          />
        </svg>
      </Block> : null}
     
    </Block>
  );
};
export const TableTask = ({ row }) => {
  const [css, theme] = useStyletron();
  return (
    <Block display="block" gridGap="16px">
      <Block className={css(NAME_STYLE(theme))} data-testid="table-task-assignment-name">{row?.assignment_name}</Block>
      {/* {row.assignment_type_major && (
        <Block
          className={css({
            ...TASKS_TAGS_STYLE,
            backgroundColor: "#F3F3F3",
            marginBottom: "10px",
          })}
        >
          {row.assignment_type_major}
        </Block>
      )}
      {row.assignment_type_minor && (
        <Block
          className={css({
            ...TASKS_TAGS_STYLE,
            backgroundColor: "#F3F3F3",
          })}
        >
          {row.assignment_type_minor}
        </Block>
      )} */}
    </Block>
  );
};
export const TableSkillsPoints = ({ row,index }) => {
  const [css, theme] = useStyletron();
  return (
    <Block className={css(SKILL_TEXT_STYLE_BLOCK(theme))}  data-testid="table-skill-points-content">
      {Object.keys(row?.skill_point_map).map(function (key, index) {
        return (
          <Block
          key={index}
            className={css({
              ...SKILL_TEXT_STYLE(theme),
              backgroundColor: row?.skill_point[index].split(" ")[0] === 0 ? "#F9F9F9" : "#EAEEFF",
              textAlign: "center",
              marginBottom: "10px",
              color: "#000000",
              fontFamily: "Manrope-Bold",
            })}
          >
            {row?.skill_point[index]}
          </Block>
         );
      })} 
    </Block>
  );
};
export const TableCompletionDate = ({ row }) => {
  const progressData = (row?.assignment_progress?.progress_actual  * 100) / row?.assignment_progress?.total_questions;
  const [css, theme] = useStyletron();

  function thmval(val){
    let fnalVal =  val - 1; 
    return fnalVal
  }

  function lxp(total,expected,actual){
      if(total === actual){
        return 1;
      }else{
        return 0
      }
  }
//console.log("row?.assignment_url",row?.assignment_url)
  return (
    <Block>
      <Block className={css(QUESTION_ANSWERED_TEXT(theme))} data-testid="question_answered">{QUESTIONS_ANSWERED}</Block>
      <Block className={css(PROGRESS_SECTION(theme))} flexDirection="row" alignItems="center" marginTop="6px" data-testid="table-progress-bar">
        <Block display="flex">
        {/* {row?.isAlxpAvailable ? */}
        <StatefulPopover
                              content={() => (
                                row?.isAlxpAvailable ?
                                <Block width="300px" padding="20px">
                                  <Link to={{pathname : row?.assignment_url}} target="_blank"><Block>{"Range - "}{thmval(row?.assignment_progress?.progress_total_cnt)}</Block></Link>
                                  <Block>{"LXP - "}{lxp(row?.assignment_progress?.progress_total_cnt,row?.assignment_progress?.progress_expected_cnt,row?.assignment_progress?.progress_actual_cnt)}</Block>
                                </Block> : null
                               )}
                              triggerType={TRIGGER_TYPE.hover}
                              returnFocus
                              autoFocus
                              placement={"rightBottom"}
                            >
          <Block
            display="flex"
            position="relative"
            className={css({
              ...COMMON_PROGRESS_STYLE,
              backgroundColor: theme.RAColors?.gray200,
            })}
          >
            <Block
              position="absolute"
              className={css({
                ...COMMON_PROGRESS_STYLE,
                backgroundColor: theme.RAColors?.blue600,
                width: `${progressData}%`,
              })}
            />
          </Block>
          </StatefulPopover> 
          {/* : null} */}
        </Block>
        <Block className={css(QUESTION_ANSWERED_VALUE(theme))} >
        {row?.assignment_progress?.progress_actual === 0 ? "0" : row?.assignment_progress?.progress_actual +"/"+ row?.assignment_progress?.total_questions}
        </Block>
        {/* {row?.isAlxpAvailable ?
        <StatefulPopover
                              content={() => (
                                
                                <Block width="500px" padding="10px">
                                  <Block>{"THM - Questions Answered : "}{thmval(row?.assignment_progress?.progress_total_cnt)}</Block>
                                  <Block>{"LXP - Questions Answered : "}{lxp(row?.assignment_progress?.progress_total_cnt,row?.assignment_progress?.progress_expected_cnt,row?.assignment_progress?.progress_actual_cnt)}</Block>
                                </Block>
                               )}
                              triggerType={TRIGGER_TYPE.hover}
                              returnFocus
                              autoFocus
                              placement={"rightBottom"}
                            >
                              <Block
                                as="img"
                                data-testid="proficient-learner-info-icon"
                                src={InfoIcon}
                                alt="Info Icon"
                                className={css(COMPETENCY_ICON_STYLE)}
                              />
                            </StatefulPopover> : null} */}
      </Block>
      <Block className={css(COMPLETION_DATE_TEXT(theme))} marginTop="6px">
        {row?.assignment_progress?.progress_actual === 100 &&
        row?.assignment_room_code !== "NOP_ROOM_CODE_NOP_ROOM_CODE"
          ? COMPLETED_ON + " " + row?.completed_on 
          : ""} 
      </Block>
    </Block>
  );
};
export const TableStatus = ({ row, isAbsolute = true }) => {
  const [css, theme] = useStyletron();
  const status = useMemo(
    () =>
      row?.learner_progress_bar?.progress_learning_path?.progress_status || "",
    [row]
  );
  const statusString = useMemo(
    () => (!status ? "-" : status.replace("_", " ")),
    [status]
  );
  const statusColor = useMemo(() => {
    const colors = {
      At_Risk: theme.RAColors?.orange100,
      Exceeds: theme.RAColors?.blue100,
      Action_Needed: theme.RAColors?.red100,
      On_Track: theme.RAColors?.emerald100,
    };
    return colors[status] || "transparent";
  }, [status, theme]);
  return (
    <Block
      display="flex"
      justifyContent="end"
      alignItems="center"
      position={isAbsolute ? "absolute" : "initial"}
      className={css(isAbsolute ? STATUS_BLOCK : {})}
      data-testid="table-score-number"
    >
      <Block data-testid="table-status-content"
        className={css({ ...STATUS_STYLE, backgroundColor: statusColor })}>
        {statusString}
      </Block>
    </Block>
  );
};