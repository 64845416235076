import axios from "axios";
import { jsURL } from "../config";

const JumpStartData = axios.create({
  baseURL: jsURL
})

JumpStartData.defaults.headers.common = {
  ...(JumpStartData.defaults.headers.common || {}),
  'x-api-key': '8jSs34s*2828'
}

export const fetchJSData = (email,role) => JumpStartData.get(`cohort_student_data?email_id=${email}&role=${role}`)
//export const fetchJSData = (email) => JumpStartData.get(`cohort_student_data?email_id=rapiddev01@gmail.com&role=learner`)
