import React from "react";
import { Skeleton } from "baseui/skeleton";
import PropTypes from "prop-types"; // ES6

export default function SkeletonComponent(props) {
  Skeleton.propTypes = {
    height: PropTypes.string,
    width: PropTypes.string,
    rows: PropTypes.number,
    animation: PropTypes.bool,
    oval: PropTypes.bool,
  };

  Skeleton.defaultProps = {
    height: "auto",
    width: "auto",
    rows: 0,
    animation: true,
    oval: false,
  };
  return (
    <Skeleton
      height={props.height}
      width={props.width}
      rows={props.rows}
      animation={props.animation}
      overrides={{
        Root: {
          style: {
            borderRadius: props.oval ? "50%" : "0%",
          },
        },
      }}
    />
  );
}
