import React, { useEffect } from "react";
import { Header } from "components/Header";
import DrawerRouterContainer from "components/newDrawer";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import Title from "./Title";
import BasicInfo from "./BasicInfo";
import Activities from "./Activities";
import Traits from "./Traits";
import { DESIRED_TRAITS } from "resources/constants/strings";
import { useSetState, useTrackedState } from "store/store";
import createNewJobRole from "services/requests/createNewJobRole";
import { useHistory } from "react-router-dom";
import { COMMON_CARD_STYLE } from "utils/style";
import { deleteJobRole } from "services/requests/job_role";
import DOLCompetency from "./DOLCompetency";

const MAIN_STYLE = (theme) => ({
  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "40px",
  },
});
const COMMON_TEXT_STYLE = {
  fontFamily: "Manrope",
  fontSize: "20px",
  lineHeight: "27px",
  color: "#000000",
};
const CONTENT = (theme) => ({
  display: "flex",

  [theme.mediaQuery.small]: {
    flexDirection: "column",
  },
  [theme.mediaQuery.large]: {
    flexDirection: "row",
  },
});
const SIDEBAR = (theme) => ({
  width: "200px",
  flexDirection: "column",
  gridGap: "20px",
  position: "sticky",
  top: "92px",
  alignSelf: "flex-start",
  [theme.mediaQuery.small]: {
    display: "none",
  },
  [theme.mediaQuery.large]: {
    display: "flex",
  },
});
const LIST_TEXT_STYLE = (theme) => ({
  ...COMMON_TEXT_STYLE,
  fontWeight: 700,
});
const LIST_TEXT__NOT_SELECTED_STYLE = (theme) => ({
  ...COMMON_TEXT_STYLE,
  fontWeight: 400,
});
let jobObject = {
  RoleName: "",
  Function: "",
  Description: "",
  Department: "",
  RoleCode: "",
  JobGroup: "",
  JobRoleTraits: [
    {
      id: 0,
      DesiredTrait: "",
    },
  ],
  JobRoleActivities: [
    {
      id: 0,
      ActivityName: "",
      Importance: "",
      Difficulty: "",
      Frequency: "",
    },
  ],
  TemplateId: '',
  Status: "Draft",
};

const DEFAULT_JOB_VALIDATION = {
  RoleName: false,
  Function: false,
  Description: false,
  Department: false,
  RoleCode: false,
  JobGroup: false,
  TemplateId: false
};

const Jobs = ({ match }) => {
  const [css, theme] = useStyletron();
  const [selectedMenu, setSelectedMenu] = React.useState("overview");
  const [loader, setLoader] = React.useState(false);
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [jobForm, setJobForm] = React.useState({});

  const [jobValidation, setJobValidation] = React.useState({
    ...DEFAULT_JOB_VALIDATION,
  });

  const setState = useSetState();
  const state = useTrackedState();
  const history = useHistory();

  useEffect(() => {
    if (
      typeof state?.editJobObject === "null" ||
      state?.editJobObject instanceof Object
    ) {
      let filteredData = state?.editJobObject;
      filteredData.JobRoleTraits.map((data, index) => {
        data.id = index;
      });
      filteredData.JobRoleActivities.map((data, index) => {
        data.id = index;
      });

      setIsUpdate(true);
      setState(
        (prev) => ({
          ...prev,
          jobObject: filteredData,
          jobObjectType: "Draft",
        }),
        () => {
          //console.log("setstate 1", filteredData);
        }
      );
    } else {
      setState(
        (prev) => ({
          ...prev,
          jobObject,
          jobObjectType: "New",
        }),
        () => {
          //console.log("setstate 2", state);
        }
      );
    }
  }, []);

  const setNewJobRole = (type) => {
    // set validation state
    let isValid = true;
    let newJobValidation = {};
    let clonedJobForm = { ...jobForm }
    clonedJobForm.TemplateId = clonedJobForm.TemplateID || ''

    Object.keys(jobValidation).forEach((key) => {
      let isValueInvalid = clonedJobForm[key] === "";

      newJobValidation[key] = isValueInvalid;
      if (isValid && isValueInvalid) isValid = false;
    });
    setJobValidation(newJobValidation);

    if (isValid) {
      const payload = { ...jobForm, Status: type };
      setLoader(true);
      createNewJobRole(localStorage.getItem("email"), payload, isUpdate).then(
        function (response) {
          if (response.status === 200) {
            setLoader(false);
            history.push("/job-roles");
          }
        }
      );
    }
  };

  const cancel = () => {
    setState({ editJobObject: null }, () => {
      history.push("/job-roles");
    });
  };

  const onDelete = async () => {
    await deleteJobRole(state.jobObject, localStorage.getItem("email"));

    setState({
      jobObject: {
        RoleName: "",
        Function: "",
        Description: "",
        Department: "",
        RoleCode: "",
        JobGroup: "",
        JobRoleTraits: [
          {
            DesiredTrait: "",
          },
        ],
        JobRoleActivities: [
          {
            ActivityName: "",
            Importance: "",
            Difficulty: "",
            Frequency: "",
          },
        ],
        TemplateId: '',
        Status: "draft",
      },
    });
    history.push("/job-roles");
  };

  useEffect(() => {
    setJobForm(state?.jobObject);
  }, [state?.jobObject]);
  return (
    <>
      <Header />
      <DrawerRouterContainer>
        <Block className={css(MAIN_STYLE(theme))} data-testid="buttonTitle">
          <Title
            saveAndPublish={(type) => setNewJobRole(type)}
            cancel={() => cancel()}
            delete={onDelete}
          />

          <Block className={css(CONTENT(theme))}>
            <Block className={css(SIDEBAR(theme))}>
              <a
                href="#BasicInfo"
                onClick={() => setSelectedMenu("overview")}
                data-testid="basicInfoTab"
              >
                <Block
                  className={
                    selectedMenu === "overview"
                      ? css(LIST_TEXT_STYLE(theme))
                      : css(LIST_TEXT__NOT_SELECTED_STYLE(theme))
                  }
                  data-testid="text-overview"
                >
                  Overview
                </Block>
              </a>
              <a
                href="#Desired"
                onClick={() => setSelectedMenu("desired")}
                data-testid="desired"
              >
                <Block
                  className={
                    selectedMenu === "desired"
                      ? css(LIST_TEXT_STYLE(theme))
                      : css(LIST_TEXT__NOT_SELECTED_STYLE(theme))
                  }
                  data-testid="text-desired-traits"
                >
                  Desired Traits
                </Block>
              </a>
              <a
                href="#Activities"
                onClick={() => setSelectedMenu("activities")}
                data-testid="activities"
              >
                <Block
                  className={
                    selectedMenu === "activities"
                      ? css(LIST_TEXT_STYLE(theme))
                      : css(LIST_TEXT__NOT_SELECTED_STYLE(theme))
                  }
                  data-testid="text-activities"
                >
                  Activities
                </Block>
              </a>
              <a
                href="#Dol_Competency"
                onClick={() => setSelectedMenu("dol_competency")}
                data-testid="dol_competency"
              >
                <Block
                  className={
                    selectedMenu === "dol_competency"
                      ? css(LIST_TEXT_STYLE(theme))
                      : css(LIST_TEXT__NOT_SELECTED_STYLE(theme))
                  }
                  data-testid="text-dol-competency"
                >
                  Competency Checklist
                </Block>
              </a>
            </Block>
            <Block display={"block"} width="100%">
              <Block
                className={css(COMMON_CARD_STYLE(theme))}
                marginBottom="20px"
              >
                <Block id="BasicInfo">
                  <BasicInfo loader={loader} validation={jobValidation} />
                </Block>
              </Block>
              <Block
                className={css(COMMON_CARD_STYLE(theme))}
                marginBottom="20px"
              >
                <Block id="Desired">
                  <Traits
                    title={DESIRED_TRAITS}
                    moduleName={"JobRoleTraits"}
                    fieldName="Trait Name"
                  />
                </Block>
              </Block>
              <Block
                className={css(COMMON_CARD_STYLE(theme))}
                marginBottom="20px"
              >
                <Block id="Activities">
                  <Activities />
                </Block>
              </Block>
              <Block
                className={css(COMMON_CARD_STYLE(theme))}
                marginBottom="20px"
              >
                <Block id="Dol_Competency">
                  <DOLCompetency
                    validation={jobValidation}
                    title={"Competency Checklist"}
                    moduleName={"TemplateId"}
                    fieldName="Competency Checklist"
                  />
                </Block>
              </Block>
            </Block>
          </Block>
        </Block>
      </DrawerRouterContainer>
    </>
  );
};

export default Jobs;
