import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { TILE_STYLE, TITLE_STYLE, HINT_STYLE, HINT_TEXT_STYLE, getTickFormat, RANGE } from "./utils";
import { useEffect, useMemo, useState, useRef } from "react";
import { useTrackedState } from "store/store";
import { Skeleton } from "baseui/skeleton";
import { scaleLinear } from "d3-scale";
import {
  XYPlot,
  XAxis,
  YAxis,
  HeatmapSeries,
  Hint,
  ContinuousColorLegend,
  LabelSeries,
} from "react-vis";

const DATE_OPTION = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

const scaleColor = scaleLinear()
  .domain([0, 4, 8])
  .range(["#6495ED", "#F0FFFF", "#00FFFF"]);


const Progress = () => {
  const [css, theme] = useStyletron();
  const state = useTrackedState();
  const [width, setWidth] = useState();
  const [hoverValue, setHoverValue] = useState(false);

  const parent = useRef();

  const heatmapData = useMemo(() => {
    if (!state.labCompletion) return { value: [], labelX: [], labelY: [] }

    // create x - y labels array
    const labelY = state.labCompletion
      .map(c => c.first_name + " " + c.last_name)
      .sort((a, b) => -a.localeCompare(b))
    const dates = [...Array(RANGE)].map((_, i) => {
      const d = new Date()
      d.setUTCHours(0,0,0,0)
      d.setDate(d.getDate() - i)
      return d.getTime()
    }).reverse()

    let value = []

    // create start - end date
    const endDate = new Date()
    endDate.setUTCHours(0,0,0,0)
    const startDate = new Date()
    startDate.setUTCHours(0,0,0,0)
    startDate.setDate(startDate.getDate() - RANGE)

    // populate date
    state.labCompletion?.forEach(learner => {
      const { labs = [] } = learner
      const filteredLabs = labs.filter(({ date }) => {
        const dateValue = new Date(date)
        dateValue.setUTCHours(0,0,0,0)
        return dateValue > startDate && dateValue <= endDate
      })

      const filledLabs = dates.map(d => {
        const existingLab = filteredLabs.find(({ date }) => new Date(date).getTime() === d)

        if (existingLab) return { count: existingLab.count, date: d }
        return { count: 0, date: d }
      })

      filledLabs.forEach(({ count, date }) => {
        value.push({
          y: learner.first_name + " " + learner.last_name,
          x: date,
          value: count,
          email: learner.email_id
        })
      })
    })

    return { value, labelX: dates, labelY }
  }, [state.labCompletion])

  const heatMapOffsetTop = 24;
  const heatMapOffsetLeft = 180;
  const heatMapHeight = heatMapOffsetTop + heatmapData.labelY.length * 40;
  const heatMapRealWidth = heatMapOffsetLeft + heatmapData.labelX.length * 40;
  const heatMapWidth = heatMapRealWidth < width ? width : heatMapRealWidth;

  useEffect(() => {
    if (parent) setWidth(parent.current.clientWidth);
  }, [state.isFetching]);

  return (
    <Block className={css(TILE_STYLE(theme))}>
      <Block marginBottom="20px">
        <Block className={css(TITLE_STYLE)} data-testid="engagement-title">Engagement - Labs Completed</Block>
      </Block>

      <Block ref={parent}>
        {state.isFetching ? (
          <Skeleton width={`${width}px`} height="300px" />
        ) : (
          <>
            <Block overflow="auto" data-testid="engagement-visualization">
              <XYPlot
                xType="ordinal"
                xDomain={heatmapData.labelX}
                yType="ordinal"
                yDomain={heatmapData.labelY}
                margin={{ top: heatMapOffsetTop, left: heatMapOffsetLeft }}
                width={heatMapWidth || 0}
                height={heatMapHeight}
              >
                <XAxis
                  orientation="top"
                  tickFormat={(l) => {
                    const d = new Date(l)
                    return `${d.getMonth() + 1}/${d.getDate()}`
                  }}
                />
                <YAxis
                  style={{ text: { textAnchor: "end" } }}
                  tickFormat={(l) => getTickFormat(l, 24)}
                />
                <HeatmapSeries
                  colorType="literal"
                  getColor={(d) => scaleColor(d.value)}
                  data={heatmapData.value}
                  onValueMouseOver={(v) => setHoverValue(v)}
                  onSeriesMouseOut={(v) => setHoverValue(false)}
                />
                <LabelSeries
                  style={{ pointerEvents: "none", fontSize: 9 }}
                  data={heatmapData.value}
                  labelAnchorX="middle"
                  labelAnchorY="middle"
                  getLabel={(d) => `${d.value}`}
                />
                {hoverValue !== false && (
                  <Hint value={hoverValue}>
                    <Block className={css(HINT_STYLE)} data-testid="engagement-hint">
                      <Block className={css(HINT_TEXT_STYLE)}>
                        Learner: {hoverValue.y}
                      </Block>
                      <Block className={css(HINT_TEXT_STYLE)}>
                        Email: {hoverValue.email}
                      </Block>
                      <Block className={css(HINT_TEXT_STYLE)}>
                        Date: {new Date(hoverValue.x).toLocaleDateString("en-US", DATE_OPTION)}
                      </Block>
                      <Block className={css(HINT_TEXT_STYLE)}>
                        Completed Courses: {hoverValue.value}
                      </Block>
                    </Block>
                  </Hint>
                )}
              </XYPlot>
            </Block>
            <Block display="flex" justifyContent="center" data-testid="engagement-legend">
              <ContinuousColorLegend
                width={300}
                startTitle="0"
                midTitle="4"
                endTitle="8+"
                startColor="#6495ED"
                midColor="#F0FFFF"
                endColor="#00FFFF"
              />
            </Block>
          </>
        )}
      </Block>
    </Block>
  );
};

export default Progress;
