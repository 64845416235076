import { Header } from "components/Header";
import DrawerRouterContainer from "components/newDrawer";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import NewTemplateTitle from "./components/newTemplateTitle";
import EditTemplate from "./components/editTemplate";
import { useHistory } from "react-router-dom";
import { useRef, useEffect } from "react";
import { DATA } from "./data";
import { useSetState, useTrackedState } from "store/store";
import { createDOLTemplate, fetchDOLTemplate } from "services/requests/dol_template";

const MAIN_STYLE = (theme) => ({
  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "40px",
  },
});

const DETAILS_CARD_STYLE = (theme) => ({
  backgroundColor: "white",
  borderRadius: "8px",
  height: "initial",
  marginTop: "16px",
  border: `1px solid ${theme.RAColors?.gray200}`,
  [theme.mediaQuery.small]: {
    padding: "8px",
  },
  [theme.mediaQuery.medium]: {
    padding: "20px",
  },
});

const NewTemplate = () => {
  const [css, theme] = useStyletron();
  const history = useHistory()

  const state = useTrackedState();
  const setState = useSetState();

  const templateEditRef = useRef(null)

  const close = () => {
    history.push('/competency-manager')
  }

  const forceSave = () => {
    templateEditRef.current.save()
  }

  const save = async (newTemplate) => {
    newTemplate.Email = localStorage.getItem('email')
    const response = await createDOLTemplate(newTemplate, localStorage.getItem("email"))

    if (response.status === 200) {
      const getResponse = await fetchDOLTemplate(localStorage.getItem("email"));
      const createdTemplate = getResponse.data.find(i =>
        i.TemplateName === newTemplate.TemplateName && i.TemplateVersion === newTemplate.TemplateVersion
      ) || null

      newTemplate.TemplateId = createdTemplate?.TemplateId || 0

      setState(prev => ({ ...prev, dolTemplates: [newTemplate, ...state.dolTemplates] }))
    }

    close()
  }

  useEffect(() => {
    if (!state.dolTemplates) setState((prev) => ({ ...prev, dolTemplates: DATA }));
  }, []);


  return (
    <>
      <Header />
      <DrawerRouterContainer>
        <main className={css(MAIN_STYLE(theme))}>
          <NewTemplateTitle save={forceSave} close={close} />
          <Block className={css(DETAILS_CARD_STYLE(theme))}>
            <EditTemplate save={save} isNew ref={templateEditRef} />
          </Block>
        </main>
      </DrawerRouterContainer>
    </>
  );
};

export default NewTemplate;
