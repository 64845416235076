import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { Select } from "baseui/select";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import { Header } from "components/Header";
import DrawerRouterContainer from "components/newDrawer";
import React, { useEffect, useMemo, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import studentList from "services/requests/learnerList";
import { tenantName } from "services/requests/tenats";
import Title from "./Components/title";
//$FlowFixMe
import {
  COMMON_BORDER_COLOR,
  COMMON_BORDER_STYLE,
  COMMON_BORDER_WIDTH,
  COMMON_RADIUS,
} from "utils/style";
import { LEARNER_LIST } from "resources/constants/strings";
import NestedMenu from "components/nestedMenu";

const MAIN_STYLE = (theme) => ({
  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "40px",
  },
});

const BLOCK_STYLE = (theme) => ({
  backgroundColor: "white",
  borderRadius: "8px",
  padding: "24px",
  border: `1px solid ${theme.RAColors?.gray200}`,
  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "24px",
  },
});
const BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  paddingTop: "12px",
  paddingBottom: "12px",
  paddingRight: "24px",
  paddingLeft: "24px",
  ...COMMON_BORDER_COLOR($theme.RAColors?.blue700),
  backgroundColor: $theme.RAColors?.blue700,
  ":hover": { backgroundColor: $theme.RAColors?.blue800 },
  color: "white",

  [$theme.mediaQuery.small]: {
    width: "100%",
  },
  [$theme.mediaQuery.medium]: {
    width: "initial",
  },
});
const LEARNER_BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  backgroundColor: "white",
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  borderColor: $theme.RAColors?.purple,
  paddingTop: "17px",
  paddingBottom: "17px",
  paddingRight: "24px",
  paddingLeft: "24px",
  ":hover": { backgroundColor: $theme.RAColors?.gray300 },
  color: $theme.RAColors?.purple,

  [$theme.mediaQuery.small]: {
    width: "100%",
  },
  [$theme.mediaQuery.medium]: {
    width: "initial",
  },
});
const HEADING_STYLE = (theme) => ({
  fontFamily: "Epilogue-Semibold",
  fontSize: "28px",
  lineHeight: "40px",
  color: theme.RAColors?.gray900,
});
const TABLE_OVERRIDE_STYLE = {
  Root: ({ $theme }) => ({
    ...COMMON_RADIUS("8px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    ...COMMON_BORDER_STYLE("solid"),
  }),
  MessageCell: { minWidth: "8%", width: "25%" },
  TimeCell: { width: "25%" },
  IdCell: { width: "25%" },
  ViewCell: { width: "5%" },
};

export const SELECT_OVERRIDE_STYLE = {
  ControlContainer: ({ $theme, $disabled }) => ({
    backgroundColor: "none",
    opacity: $disabled ? 0.5 : 1,
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    color: $theme.RAColors?.gray900,
  }),
};

const RaAdmin = ({ match }) => {
  const [css, theme] = useStyletron();
  const [value, setValue] = React.useState("");
  const [tenantList, setTenantList] = React.useState([]);
  const [learnersList, setLearnersList] = React.useState([]);
  const [imageFile, setImageFile] = React.useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const timeoutId = React.useRef();
  const [error, setError] = React.useState(false);
  const history = useHistory();
  const handleRedirect = () => {
    history.push("/learner-list");
  };
  useEffect(() => {
    // localStorage.setItem("currentPage", "admindata");
    localStorage.setItem("currentPage", "student");
    getTenantLists();
  }, []);

  //page access control - based on role
  // var role = localStorage.getItem("viewAs") !== null ? localStorage.getItem("viewAs") : localStorage.getItem("role");
  // var permittedRoles = getRolesPageVisibility("Admin Data");
  // if (!permittedRoles.includes(role)) return <Redirect to="/student" />;

  const onChangeRole = (params) => {
    console.log("params:", params.value[0].name);
    setValue(params);
  };

  function getTenantLists() {
    tenantName()
      .then(function (response) {
        if (response.status === 200) {
          const email = localStorage.getItem("email");
          const [, domainWithTLD] = email.split("@");
          const [domain] = domainWithTLD.split(".");

          const resultData =
            localStorage.getItem("role") === "MENTOR" ||
            localStorage.getItem("viewAs") === "MENTOR"
              ? response?.data.filter(
                  (item) => item.name === domain.toUpperCase()
                )
              : response.data;
          // setTenantList(response.data);
          setTenantList(resultData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function submitData() {
    let tenant = value[0].name;
    studentList(tenant)
      .then(function (response) {
        if (response.status === 200) {
          console.log("Response", response.data);
          setLearnersList(response.data);
        } else {
          setError(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  }

  const viewingAs = (e, email, fname, lname) => {
    localStorage.setItem("viewAs", "LEARNER");
    localStorage.setItem("email", email);
    localStorage.setItem("lastName", lname);
    localStorage.setItem("username", fname + " " + lname);
    localStorage.setItem("firstName", fname);
    localStorage.setItem("role", "LEARNER");
    localStorage.setItem("superAdmin", "RA-ADMIN");
    history.push("/sso-home");
  };

  const role =
    localStorage.getItem("viewAs") !== null
      ? localStorage.getItem("viewAs")
      : localStorage.getItem("role");
  const userType = useMemo(() => {
    const username = localStorage.getItem("username");
    if ([null, undefined, ""].includes(username)) return null;
    //page access control - based on role
    var permittedRoles = ["ADMIN", "TENANT OWNER", "MENTOR", "MANAGER"];
    if (permittedRoles.includes(role)) return "manager";

    return "member";
  }, []);
  if (!userType) return <Redirect to="/login" />;
  if (userType !== "manager") return <Redirect to="/student" />;
  return (
    <>
      <Header />

      <DrawerRouterContainer>
        <main className={css(MAIN_STYLE(theme))}>
          <Block
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            gridGap="16px"
            marginBottom={["16px", "16px", "20px"]}
            flexWrap="wrap"
          >
            <Block>
              {/* {localStorage.getItem("role") === "MENTOR" ||
            (localStorage.getItem("viewAs") === "MENTOR" && ( */}
              <Button
                onClick={handleRedirect}
                overrides={{
                  BaseButton: { style: LEARNER_BUTTON_OVERRIDE_STYLE },
                }}
                data-testid="download-button"
              >
                <span>{LEARNER_LIST}</span>
              </Button>
              {/* ))} */}
            </Block>
            <Block>
              <NestedMenu />
            </Block>
          </Block>
          <Title />
          <Block
            data-testid="summary"
            className={css(BLOCK_STYLE(theme))}
            display="flex"
            flexWrap="wrap"
            gridGap={["16px", "16px", "40px"]}
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
                submitData();
              }}
              style={{ width: "100%" }}
            >
              <Block style={{ display: "inline-flex", gap: 40, width: "100%" }}>
                <Block style={{ width: "30%" }}>
                  <Select
                    clearable={false}
                    searchable={false}
                    options={tenantList}
                    labelKey="name"
                    valueKey="id"
                    value={value}
                    data-testid="job-group-input"
                    onChange={(params) => setValue(params.value)}
                    overrides={{
                      Root: {
                        style: ({ $theme }) => ({
                          width: "100%",
                          [$theme.mediaQuery.medium]: {
                            width: "200px",
                          },
                        }),
                      },
                      ControlContainer: {
                        style: SELECT_OVERRIDE_STYLE.ControlContainer,
                      },
                    }}
                  />
                </Block>
                <Block>
                  <Button
                    overrides={{
                      BaseButton: { style: BUTTON_OVERRIDE_STYLE },
                    }}
                  >
                    Submit
                  </Button>
                </Block>
              </Block>
            </form>
          </Block>
        </main>

        <main className={css(MAIN_STYLE(theme))}>
          <Block
            as="h3"
            className={css(HEADING_STYLE(theme))}
            marginBottom="16px"
            data-testid="logs-text"
          >
            Track User
          </Block>
          <Block>
            <TableBuilder
              data={learnersList}
              isLoading={isFetching || false}
              emptyMessage="No Data"
              overrides={{ Root: { style: TABLE_OVERRIDE_STYLE.Root } }}
            >
              <TableBuilderColumn
                header="First Name"
                overrides={{
                  TableHeadCell: { style: TABLE_OVERRIDE_STYLE.IdCell },
                  TableBodyCell: { style: TABLE_OVERRIDE_STYLE.IdCell },
                }}
              >
                {(row) => (
                  <div data-testid="row-message-linkforce">{row.FirstName}</div>
                )}
              </TableBuilderColumn>
              <TableBuilderColumn
                header="Last Name"
                overrides={{
                  TableHeadCell: { style: TABLE_OVERRIDE_STYLE.TimeCell },
                  TableBodyCell: { style: TABLE_OVERRIDE_STYLE.TimeCell },
                }}
              >
                {(row) => (
                  <div data-testid="row-message-linkforce">{row.LastName}</div>
                )}
              </TableBuilderColumn>
              <TableBuilderColumn
                header="Email"
                overrides={{
                  TableHeadCell: {
                    style: TABLE_OVERRIDE_STYLE.MessageCell,
                  },
                  TableBodyCell: {
                    style: TABLE_OVERRIDE_STYLE.MessageCell,
                  },
                }}
              >
                {(row) => <div data-testid="row-time">{row.EMailID}</div>}
              </TableBuilderColumn>
              <TableBuilderColumn
                header="View As"
                overrides={{
                  TableHeadCell: {
                    style: TABLE_OVERRIDE_STYLE.ViewCell,
                  },
                  TableBodyCell: {
                    style: TABLE_OVERRIDE_STYLE.ViewCell,
                  },
                }}
              >
                {(row) => (
                  <div data-testid="row-time">
                    <Button
                      overrides={{
                        BaseButton: { style: BUTTON_OVERRIDE_STYLE },
                      }}
                      onClick={(e) => {
                        viewingAs(e, row.EMailID, row.FirstName, row.LastName);
                      }}
                    >
                      View
                    </Button>
                  </div>
                )}
              </TableBuilderColumn>
            </TableBuilder>
          </Block>
        </main>
      </DrawerRouterContainer>
    </>
  );
};

export default RaAdmin;
