import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { COMMON_BORDER_COLOR, COMMON_BORDER_WIDTH, COMMON_BORDER_STYLE, COMMON_RADIUS } from "utils/style";

const COMMON_BUTTON_STYLE = {
  lineHeight: '22px',
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  fontFamily: 'Manrope-Semibold',
  width: '122px'
}

const SAVE_BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_BUTTON_STYLE,
  ...COMMON_BORDER_COLOR($theme.RAColors?.blue700),
  backgroundColor: $theme.RAColors?.blue700,
  ":hover": { backgroundColor: $theme.RAColors?.blue700 },
  color: 'white',
});

const CANCEL_BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_BUTTON_STYLE,
  ...COMMON_BORDER_COLOR($theme.RAColors?.gray100),
  backgroundColor: $theme.RAColors?.gray100,
  ":hover": { backgroundColor: $theme.RAColors?.gray100 },
  color: $theme.RAColors?.gray800,
});

const EditButtons = ({ save, close}) => {
  return (
    <Block marginTop="24px" display="flex" gridGap="20px">
      <Button
        onClick={save}
        overrides={{ BaseButton: { style: SAVE_BUTTON_OVERRIDE_STYLE } }}
        data-testid="edit-button-save"
      >
        Save
      </Button>
      <Button
        onClick={close}
        overrides={{ BaseButton: { style: CANCEL_BUTTON_OVERRIDE_STYLE } }}
        data-testid="edit-button-cancel"
      >
        Cancel
      </Button>
    </Block>
  )
}

export default EditButtons