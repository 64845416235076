import { COMMON_RADIUS } from "utils/style";
import { Modal, ModalBody, SIZE } from "baseui/modal";
import { Button } from "baseui/button";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";

const MODAL_BODY_OVERRIDE_STYLE = {
  marginTop: "24px !important",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
};

const MODAL_TITLE_STYLE = (theme) => ({
  fontFamily: "Manrope-Bold",
  fontSize: "24px",
  lineHeight: "33px",
  color: theme?.RAColors?.gray900,
});

const BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_RADIUS("8px"),
  backgroundColor: $theme?.RAColors?.blue700,
  color: "white",
  fontFamily: "Manrope-Semibold",
  fontSize: "18px",
  lineHeight: "25px",
  width: "100%",
  ":hover": { backgroundColor: $theme?.RAColors?.blue700 },
});

const BadgeModal = ({ badge, close }) => {
  const [css, theme] = useStyletron();

  return (
    <Modal
      onClose={close}
      closeable
      isOpen={Boolean(badge)}
      animate
      size={SIZE.auto}
      autoFocus={false}
      overrides={{
        Dialog: { style: { ...COMMON_RADIUS("8px"), width: "387px" } },
      }}
    >
      <ModalBody className={css(MODAL_BODY_OVERRIDE_STYLE)}>
        <Block className={css(MODAL_TITLE_STYLE(theme))} data-testid="badge-modal-title">
          Earned badge
        </Block>
        <Block display="flex" justifyContent="center" data-testid="badge-modal-image">
          <Block as="img" src={badge} alt="Micro Badge Large" width="220px" />
        </Block>
        <Block>
          <Button
            overrides={{
              BaseButton: { style: BUTTON_OVERRIDE_STYLE },
            }}
            onClick={close}
            data-testid="badge-modal-button"
          >
            OK
          </Button>
        </Block>
      </ModalBody>
    </Modal>
  );
};

export default BadgeModal;
