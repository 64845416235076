import React, { lazy, Suspense, useState } from "react";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { useStyletron } from "baseui";
import {
  COMMON_RADIUS,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_STYLE,
  COMMON_BORDER_COLOR,
} from "utils/style";
import { New_Template, DOL_Template } from "resources/constants/strings";

const HEADING_STYLE = (theme) => ({
  fontFamily: "Epilogue-Semibold",
  fontSize: "28px",
  lineHeight: "40px",
  color: theme.RAColors?.gray900,
});

const BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  borderColor: $theme.RAColors?.purple,
  paddingTop: "12px",
  paddingBottom: "12px",
  paddingRight: "24px",
  paddingLeft: "24px",
  ...COMMON_BORDER_COLOR($theme.RAColors?.blue700),
  backgroundColor: $theme.RAColors?.blue700,
  ":hover": { backgroundColor: $theme.RAColors?.blue800 },
  color: "white",

  [$theme.mediaQuery.small]: {
    width: "100%",
  },
  [$theme.mediaQuery.medium]: {
    width: "initial",
  },
});

const SEC_BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  backgroundColor: "white",
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  borderColor: $theme.RAColors?.purple,
  paddingTop: "12px",
  paddingBottom: "12px",
  paddingRight: "24px",
  paddingLeft: "24px",
  float: "right",
  fontFamily: "Manrope-Bold",
  ":hover": { backgroundColor: $theme.RAColors?.gray300 },
  color: $theme.RAColors?.purple,

  [$theme.mediaQuery.small]: {
    width: "100%",
  },
  [$theme.mediaQuery.medium]: {
    width: "initial",
  },
});
const SEPRATOR = {
  borderLeft: "2px solid #e0e0e0",
};
const ModalComponent = lazy(() => import("./importTemplate.js"));
export default function Title({ expandval, addNew }) {
  const [css, theme] = useStyletron();
  const [row, setRow] = useState(false);

  const collapseAll = () => {
    expandval(false);
  };
  const expandAll = () => {
    expandval(true);
  };
  const handleClick = () => {
    setRow(true);
  };
  function closeModal() {
    setRow(false);
  }
  const SearchRender = ({ collapse, expand }) => {
    return (
      <>
        <Block display="flex" gridGap="20px" justifyContent="flex-end">
          <Button
            onClick={collapse}
            data-testid="collapse-all"
            overrides={{ BaseButton: { style: SEC_BUTTON_OVERRIDE_STYLE } }}
          >
            Collapse All
          </Button>
          <Button
            onClick={expand}
            data-testid="expand-all"
            overrides={{ BaseButton: { style: SEC_BUTTON_OVERRIDE_STYLE } }}
          >
            Expand All
          </Button>
          <Block className={css(SEPRATOR)}/>
          <Button
            onClick={handleClick}
            data-testid="title-import-template"
            overrides={{ BaseButton: { style: SEC_BUTTON_OVERRIDE_STYLE } }}
          >
            Import Template
          </Button>
        </Block>
      </>
    );
  };
  return (
    <Block
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gridGap="16px"
      marginBottom={["16px", "16px", "20px"]}
      flexWrap="wrap"
    >
      <Block as="h3" className={css(HEADING_STYLE(theme))} data-testid="title">
        {DOL_Template}
      </Block>
      <Block display="flex" gridGap="16px" alignItems="center">
        <SearchRender expand={expandAll} collapse={collapseAll} />
        <Button
          onClick={addNew}
          data-testid="title-new-template"
          overrides={{ BaseButton: { style: BUTTON_OVERRIDE_STYLE } }}
        >
          {New_Template}
        </Button>
      </Block>
      <Suspense fallback={<div />}>
        {row && <ModalComponent row={row} close={() => closeModal()} />}
      </Suspense>
    </Block>
  );
}
