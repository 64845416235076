import jsPDF from "jspdf";
import html2canvas from 'html2canvas';
import { useRef } from "react";

const useExportPdf = (title = '', offset = 16) => {
  const pdfComponentRef = useRef();

  const exportPdf = () => {
    if (!pdfComponentRef.current) return

    const w = pdfComponentRef.current.offsetWidth;
    const h = pdfComponentRef.current.offsetHeight;

    const orientation = w + offset * 2 > h + offset * 2 ? 'l' : 'p'
    const format = orientation === 'l' ? [h + offset * 2, w + offset * 2] : [w + offset * 2, h + offset * 2]

    html2canvas(pdfComponentRef.current)
      .then(function(canvas) {
        const img = canvas.toDataURL("image/png", 1);
        const doc = new jsPDF(orientation, 'px', format,true);
        doc.addImage(img, 'PNG', offset, offset, w, h);
        doc.save(`Report for ${title}.pdf`);
      });
  }

  return { pdfComponentRef, exportPdf }
}

export default useExportPdf