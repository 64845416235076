import React from "react";
import { Block } from "baseui/block";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import { useStyletron } from "baseui";
import CircleIcon from "assets/images/circle-complete.svg";
import RightCircleIcon from "assets/images/right-circleTick.svg";
import ChatIconBlank from "assets/images/chat-blank.svg";
import ChatIcon from "assets/images/chat-grey.svg";
import CompetencyLevel1 from "assets/images/comp_level 1.svg";
import CompetencyLevel2 from "assets/images/comp_level 2.svg";
import CompetencyLevel3 from "assets/images/comp_level 3.svg";
import RightCircleGrayIcon from "assets/images/right-circleTickGray.svg";
import dayjs from "dayjs";
import VerifyCompetencyPopUp from "../dolCompetency/verifyCompetencyPopUp";

const OTHER_BLOCK_STYLE = (theme) => ({
  backgroundColor: "white",
  borderRadius: "16px",
  border: `1px solid ${theme.RAColors?.gray200}`,
  width: "auto",
  [theme.mediaQuery.small]: {
    padding: "16px",
    width: "max-content",
  },
  [theme.mediaQuery.medium]: {
    padding: "24px",
    width: "max-content",
  },
  [theme.mediaQuery.large]: {
    padding: "12px",
    width: "auto",
  },
});
const CONTENT_BLOCK_STYLE = (theme) => ({
  display: "flex",
  gridGap: "7px",
  alignItems: "center",
});
const PANEL_TITLE_STYLE = (theme) => ({
  display: "flex",
  alignItems: "center",
  fontFamily: "Manrope-semibold",
  fontWeight: 600,
  lineHeight: "19px",
  color: theme.RAColors?.black,
  fontSize: "14px",
});
const VERIFY_TEXT_STYLE = (theme) => ({
  fontFamily: "Manrope",
  fontWeight: 400,
  lineHeight: "16px",
  color: theme.RAColors?.gray700,
  fontSize: "14px",
});
const SUB_TITLE_STYLE = (theme) => ({
  fontFamily: "Manrope-Medium",
  fontWeight: 500,
  lineHeight: "16px",
  color: theme.RAColors?.gray700,
  fontSize: "12px",
  cursor: "pointer",
});
const CHAT_STYLE = { width: "8px", height: "8px", padding: "6px" };
const CHAT_BOX_STYLE = {
  borderRadius: "50%",
  backgroundColor: "rgba(2, 31, 11, 0.1)",
  display: "flex",
};
const SEPARATOR = {
  borderBottom: "1px solid #e0e0e0",
  margin: "20px 0px",
};

export default function LearnerProficientBlock({
  itemData,
  handleClick,
  changeDolData,
  selectedIndex,
  rolespermission,
  categoryData,
  isRoleSpecificActivity,
}) {
  const [css, theme] = useStyletron();
  const setTitle = (itemName) => {
    let title;
    if (itemName === "LearnerProficientStatus") {
      //title = "Proficient (learner)";
      title = "Learner Sign-Off";
    }
    return title;
  };
  const filteredactions = rolespermission?.filter(
    (obj) => obj.Action_name === setTitle("LearnerProficientStatus")
  );
  const showCommentAndStatusElementBasedOnTaskStatus = (
    taskName,
    itemObject,
    showStatusOption = false
  ) => {
    let preDefinedSignedTask = ["RTI", "RTIStatus", "JS", "JSStatus"];
    let definedSignedTask = [
      // "OJT",
      // "OJTStatus",
      "MentorProficientStatus",
      "LearnerProficientStatus",
      "FinalApprovalStatus",
    ];
    let isOJTEnable = itemObject?.OJT === true ? true : false;
    let availableListedTask = Object.keys(itemObject);
    let show = false;
    let filteredAvailableListedTask = definedSignedTask.filter(function (n) {
      return availableListedTask.indexOf(n) !== -1;
    });
    definedSignedTask = definedSignedTask.filter(function (n) {
      return availableListedTask.indexOf(n) !== -1;
    });
    if (preDefinedSignedTask.includes(taskName)) {
      for (const element of preDefinedSignedTask) {
        if (taskName === element) {
          if (showStatusOption) {
            return true;
          }
          return show;
        }
      }
    }
    preDefinedSignedTask = preDefinedSignedTask.filter(function (n) {
      return availableListedTask.indexOf(n) !== -1;
    });
    const calculateDefinedSignedTask = () => {
      if (definedSignedTask.includes(taskName)) {
        for (let i = 0; i < definedSignedTask.length; i++) {
          let definedTaskName = definedSignedTask[i];
          let previousDefinedTaskName = definedSignedTask[i - 1];
          let nextDefinedTaskName = definedSignedTask[i + 1];

          if (
            previousDefinedTaskName === "MentorProficientStatus" &&
            (isOJTEnable || isRoleSpecificActivity)
          ) {
            return true;
          }
          if (itemObject[previousDefinedTaskName]?.CompletionStatus === false) {
            return false;
          } else {
            if (taskName === definedTaskName) {
              if (
                nextDefinedTaskName === "MentorProficientStatus" &&
                (isOJTEnable || isRoleSpecificActivity)
                // itemObject["MentorProficientStatus"]?.ChangeStatusText !==
                //   "Not Yet Rated" &&
                // itemObject["MentorProficientStatus"]?.ChangeStatusText !== "" &&
                // itemObject["MentorProficientStatus"]?.ChangeStatusText !==
                //   undefined
              ) {
                return true;
              } else if (
                itemObject[nextDefinedTaskName]?.CompletionStatus === false
                //&& (isOJTEnable || isRoleSpecificActivity)
              ) {
                return true;
              } else if (
                itemObject[nextDefinedTaskName]?.CompletionStatus === undefined
                //&&(isOJTEnable || isRoleSpecificActivity)
              ) {
                return true;
              }
              for (const ele of filteredAvailableListedTask) {
                if (
                  ele === taskName &&
                  itemObject[ele]?.CompletionStatus === false
                  //&&(isOJTEnable || isRoleSpecificActivity)
                ) {
                  return true;
                }
                // if (
                //   ("FinalApprovalStatus" === taskName ||
                //     "LearnerProficientStatus" === taskName) &&
                //   !isOJTEnable &&
                //   showStatusOption
                // ) {
                //   return true;
                // }
              }
              return false;
            }
          }
        }
      }
    };
    if (preDefinedSignedTask.length >= 1) {
      let show = 1;

      for (const element of preDefinedSignedTask) {
        if (itemObject[element]?.CompletionStatus === true) {
          show = show * 1;
        } else {
          show = show * 0;
        }
      }
      if (show === 0) {
        return false;
      } else {
        return calculateDefinedSignedTask();
      }
    } else {
      return calculateDefinedSignedTask();
    }
  };
  const getAvailableCommentsStatus = (commentsList) => {
    const newArray = commentsList?.filter((a) => a.IsDeleted !== true);
    if (newArray?.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <>
      {itemData["LearnerProficientStatus"]?.CompletionStatus ? (
        <Block
          className={css(CONTENT_BLOCK_STYLE(theme))}
          data-testid="proficient-learner-text-onclick-block"
          style={{ cursor: "pointer" }}
        >
          {showCommentAndStatusElementBasedOnTaskStatus(
            "LearnerProficientStatus",
            itemData
          ) ? (
            <StatefulPopover
              content={() => (
                <VerifyCompetencyPopUp
                  data={itemData}
                  taskData={"LearnerProficientStatus"}
                  changeDolData={changeDolData}
                  selectedIndex={selectedIndex}
                  rolespermission={rolespermission}
                  isRoleSpecificActivity={isRoleSpecificActivity}
                />
              )}
              triggerType={TRIGGER_TYPE.click}
              placement="rightBottom"
              showArrow
              returnFocus
              autoFocus
            >
              <Block
                as="img"
                data-testid="proficient-learner-status-icon"
                src={
                  itemData["LearnerProficientStatus"]?.ChangeStatusText ===
                    "Not Yet Rated" ||
                  itemData["LearnerProficientStatus"]?.ChangeStatusText === ""
                    ? CircleIcon
                    : itemData["LearnerProficientStatus"]?.ChangeStatusText ===
                      "Learning- Observed through job training or shadowing"
                    ? CompetencyLevel1
                    : itemData["LearnerProficientStatus"]?.ChangeStatusText ===
                      "With help - Completes with assistance"
                    ? CompetencyLevel2
                    : itemData["LearnerProficientStatus"]?.ChangeStatusText ===
                      "On their own - Completes independently, with review and guidance"
                    ? CompetencyLevel3
                    : itemData["LearnerProficientStatus"]?.ChangeStatusText ===
                  "Unable to Assess - Did not get opportunity to validate on the job due to available work"
                ? RightCircleGrayIcon
                    : RightCircleIcon
                }
                alt="Status Circle"
                style={{ height: "20px", width: "20px" }}
              />
            </StatefulPopover>
          ) : (
            <Block
              as="img"
              data-testid="proficient-learner-status-icon"
              src={
                itemData["LearnerProficientStatus"]?.ChangeStatusText ===
                  "Not Yet Rated" ||
                itemData["LearnerProficientStatus"]?.ChangeStatusText === ""
                  ? CircleIcon
                  : itemData["LearnerProficientStatus"]?.ChangeStatusText ===
                    "Learning- Observed through job training or shadowing"
                  ? CompetencyLevel1
                  : itemData["LearnerProficientStatus"]?.ChangeStatusText ===
                    "With help - Completes with assistance"
                  ? CompetencyLevel2
                  : itemData["LearnerProficientStatus"]?.ChangeStatusText ===
                    "On their own - Completes independently, with review and guidance"
                  ? CompetencyLevel3
                  : itemData["LearnerProficientStatus"]?.ChangeStatusText ===
                  "Unable to Assess - Did not get opportunity to validate on the job due to available work"
                ? RightCircleGrayIcon
                  : RightCircleIcon
              }
              alt="Status Circle"
              style={{ height: "20px", width: "20px" }}
            />
          )}
          <StatefulPopover
            content={() => (
              <Block
                className={css(OTHER_BLOCK_STYLE(theme))}
                data-testid="proficient-learner-task-block"
              >
                <Block
                  className={css(PANEL_TITLE_STYLE(theme))}
                  data-testid="proficient-learner-title"
                >
                  {setTitle("LearnerProficientStatus")}
                </Block>
                <Block className={css(SEPARATOR)} />
                <Block
                  className={css(VERIFY_TEXT_STYLE(theme))}
                  data-testid="proficient-learner-verified_date"
                >
                  {"Verified on " +
                    dayjs(
                      itemData["LearnerProficientStatus"]?.CompletionDate
                    ).format("MM/DD/YYYY")}
                </Block>
              </Block>
            )}
            triggerType={TRIGGER_TYPE.hover}
            placement="rightBottom"
            showArrow
            returnFocus
            autoFocus
          >
            <Block
              overflow="hidden"
              onClick={() =>
                handleClick(
                  itemData,
                  "LearnerProficientStatus",
                  categoryData,
                  isRoleSpecificActivity
                )
              }
            >
              <Block
                className={css(PANEL_TITLE_STYLE(theme))}
                data-testid="proficient_learner_task-title"
              >
                {setTitle("LearnerProficientStatus")}
              </Block>
              <Block
                display="flex"
                gridGap="4px"
                marginTop="4px"
                alignItems="center"
              >
                <Block className={css(CHAT_BOX_STYLE)}>
                  <Block
                    as="img"
                    data-testid="proficient-learner-chat-icon"
                    src={
                      itemData["LearnerProficientStatus"]?.CompletionStatus ===
                        true &&
                      getAvailableCommentsStatus(
                        itemData["LearnerProficientStatus"]?.NoteList
                      )
                        ? ChatIcon
                        : ChatIconBlank
                    }
                    alt="Chat Icon"
                    className={css(CHAT_STYLE)}
                  />
                </Block>
                <Block
                  className={css(SUB_TITLE_STYLE(theme))}
                  data-testid="proficient-learner-add-comment-text"
                >
                  {"View details"}
                </Block>
              </Block>
            </Block>
          </StatefulPopover>
        </Block>
      ) : (
        <>
          {showCommentAndStatusElementBasedOnTaskStatus(
            "LearnerProficientStatus",
            itemData
          ) ? (
            <Block
              className={css(CONTENT_BLOCK_STYLE(theme))}
              marginTop={"0px"}
              data-testid="proficient-learner-text-onclick-block"
              style={{ cursor: "pointer" }}
            >
              {showCommentAndStatusElementBasedOnTaskStatus(
                "LearnerProficientStatus",
                itemData
              ) ? (
                <StatefulPopover
                  content={() => (
                    <VerifyCompetencyPopUp
                      data={itemData}
                      taskData={"LearnerProficientStatus"}
                      changeDolData={changeDolData}
                      selectedIndex={selectedIndex}
                      rolespermission={rolespermission}
                      isRoleSpecificActivity={isRoleSpecificActivity}
                    />
                  )}
                  triggerType={TRIGGER_TYPE.click}
                  placement="rightBottom"
                  showArrow
                  returnFocus
                  autoFocus
                >
                  <Block
                    as="img"
                    data-testid="proficient-learner-status-icon"
                    src={
                      itemData["LearnerProficientStatus"]?.ChangeStatusText ===
                        "Not Yet Rated" ||
                      itemData["LearnerProficientStatus"]?.ChangeStatusText ===
                        ""
                        ? CircleIcon
                        : itemData["LearnerProficientStatus"]
                            ?.ChangeStatusText ===
                          "Learning- Observed through job training or shadowing"
                        ? CompetencyLevel1
                        : itemData["LearnerProficientStatus"]
                            ?.ChangeStatusText ===
                          "With help - Completes with assistance"
                        ? CompetencyLevel2
                        : itemData["LearnerProficientStatus"]
                            ?.ChangeStatusText ===
                          "On their own - Completes independently, with review and guidance"
                        ? CompetencyLevel3
                        : RightCircleIcon
                    }
                    alt="Status Circle"
                    style={{ height: "20px", width: "20px" }}
                  />
                </StatefulPopover>
              ) : (
                <Block
                  as="img"
                  data-testid="proficient-learner-status-icon"
                  src={
                    itemData["LearnerProficientStatus"]?.ChangeStatusText ===
                      "Not Yet Rated" ||
                    itemData["LearnerProficientStatus"]?.ChangeStatusText === ""
                      ? CircleIcon
                      : itemData["LearnerProficientStatus"]
                          ?.ChangeStatusText ===
                        "Learning- Observed through job training or shadowing"
                      ? CompetencyLevel1
                      : itemData["LearnerProficientStatus"]
                          ?.ChangeStatusText ===
                        "With help - Completes with assistance"
                      ? CompetencyLevel2
                      : itemData["LearnerProficientStatus"]
                          ?.ChangeStatusText ===
                        "On their own - Completes independently, with review and guidance"
                      ? CompetencyLevel3
                      : RightCircleIcon
                  }
                  alt="Status Circle"
                  style={{ height: "20px", width: "20px" }}
                />
              )}
              <Block
                overflow="hidden"
                onClick={() =>
                  handleClick(
                    itemData,
                    "LearnerProficientStatus",
                    categoryData,
                    isRoleSpecificActivity
                  )
                }
              >
                <Block
                  className={css(PANEL_TITLE_STYLE(theme))}
                  data-testid="proficient_learner_task-title"
                >
                  {setTitle("LearnerProficientStatus")}
                </Block>
                <Block
                  display="flex"
                  gridGap="4px"
                  marginTop="4px"
                  alignItems="center"
                >
                  <Block className={css(CHAT_BOX_STYLE)}>
                    <Block
                      as="img"
                      data-testid="proficient-learner-chat-icon"
                      src={
                        itemData["LearnerProficientStatus"]
                          ?.CompletionStatus === true ||
                        getAvailableCommentsStatus(
                          itemData["LearnerProficientStatus"]?.NoteList
                        )
                          ? ChatIcon
                          : ChatIconBlank
                      }
                      alt="Chat Icon"
                      className={css(CHAT_STYLE)}
                    />
                  </Block>
                  <Block
                    className={css(SUB_TITLE_STYLE(theme))}
                    data-testid="proficient-learner-add-comment-text"
                  >
                    {"View details"}
                  </Block>
                </Block>
              </Block>
            </Block>
          ) : (
            <StatefulPopover
              content={() => (
                <Block
                  className={css(OTHER_BLOCK_STYLE(theme))}
                  data-testid="tasks-block"
                >
                  <Block>
                    {
                      "Preceding steps must be completed prior to signing off this item"
                    }
                  </Block>
                </Block>
              )}
              triggerType={TRIGGER_TYPE.hover}
              placement="rightBottom"
              showArrow
              returnFocus
              autoFocus
            >
              <Block
                className={css(CONTENT_BLOCK_STYLE(theme))}
                marginTop={"0px"}
                data-testid="proficient-learner-text-onclick-block"
                style={{ cursor: "pointer", opacity: "40%" }}
              >
                {showCommentAndStatusElementBasedOnTaskStatus(
                  "LearnerProficientStatus",
                  itemData
                ) ? (
                  <StatefulPopover
                    content={() => (
                      <VerifyCompetencyPopUp
                        data={itemData}
                        taskData={"LearnerProficientStatus"}
                        changeDolData={changeDolData}
                        selectedIndex={selectedIndex}
                        rolespermission={rolespermission}
                        isRoleSpecificActivity={isRoleSpecificActivity}
                      />
                    )}
                    triggerType={TRIGGER_TYPE.click}
                    placement="rightBottom"
                    showArrow
                    returnFocus
                    autoFocus
                  >
                    <Block
                      as="img"
                      data-testid="proficient-learner-status-icon"
                      src={
                        itemData["LearnerProficientStatus"]
                          ?.ChangeStatusText === "Not Yet Rated" ||
                        itemData["LearnerProficientStatus"]
                          ?.ChangeStatusText === ""
                          ? CircleIcon
                          : itemData["LearnerProficientStatus"]
                              ?.ChangeStatusText ===
                            "Learning- Observed through job training or shadowing"
                          ? CompetencyLevel1
                          : itemData["LearnerProficientStatus"]
                              ?.ChangeStatusText ===
                            "With help - Completes with assistance"
                          ? CompetencyLevel2
                          : itemData["LearnerProficientStatus"]
                              ?.ChangeStatusText ===
                            "On their own - Completes independently, with review and guidance"
                          ? CompetencyLevel3
                          : RightCircleIcon
                      }
                      alt="Status Circle"
                      style={{ height: "20px", width: "20px" }}
                    />
                  </StatefulPopover>
                ) : (
                  <Block
                    as="img"
                    data-testid="proficient-learner-status-icon"
                    src={
                      itemData["LearnerProficientStatus"]?.ChangeStatusText ===
                        "Not Yet Rated" ||
                      itemData["LearnerProficientStatus"]?.ChangeStatusText ===
                        ""
                        ? CircleIcon
                        : itemData["LearnerProficientStatus"]
                            ?.ChangeStatusText ===
                          "Learning- Observed through job training or shadowing"
                        ? CompetencyLevel1
                        : itemData["LearnerProficientStatus"]
                            ?.ChangeStatusText ===
                          "With help - Completes with assistance"
                        ? CompetencyLevel2
                        : itemData["LearnerProficientStatus"]
                            ?.ChangeStatusText ===
                          "On their own - Completes independently, with review and guidance"
                        ? CompetencyLevel3
                        : RightCircleIcon
                    }
                    alt="Status Circle"
                    style={{ height: "20px", width: "20px" }}
                  />
                )}
                <Block
                  overflow="hidden"
                  onClick={() =>
                    handleClick(
                      itemData,
                      "LearnerProficientStatus",
                      categoryData,
                      isRoleSpecificActivity
                    )
                  }
                >
                  <Block
                    className={css(PANEL_TITLE_STYLE(theme))}
                    data-testid="proficient_learner_task-title"
                  >
                    {setTitle("LearnerProficientStatus")}
                  </Block>
                  <Block
                    display="flex"
                    gridGap="4px"
                    marginTop="4px"
                    alignItems="center"
                  >
                    <Block className={css(CHAT_BOX_STYLE)}>
                      <Block
                        as="img"
                        data-testid="proficient-learner-chat-icon"
                        src={
                          itemData["LearnerProficientStatus"]
                            ?.CompletionStatus === true ||
                          getAvailableCommentsStatus(
                            itemData["LearnerProficientStatus"]?.NoteList
                          )
                            ? ChatIcon
                            : ChatIconBlank
                        }
                        alt="Chat Icon"
                        className={css(CHAT_STYLE)}
                      />
                    </Block>
                    <Block
                      className={css(SUB_TITLE_STYLE(theme))}
                      data-testid="proficient-learner-add-comment-text"
                    >
                      {"View details"}
                    </Block>
                  </Block>
                </Block>
              </Block>
            </StatefulPopover>
          )}
        </>
      )}
    </>
  );
}
