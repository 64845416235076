export const FACEBOOK_ERROR = "Please enter valid facebook url";
export const LINKEDIN_ERROR = "Please enter valid LinkedIn url";
export const TRYHACKME_ERROR = "Please enter valid TryHackMe url";
export const MAILBOX_MSG = "Please check your mailbox at";
export const FOR_EMAIL_RESET_PASSWORD = "for an email to reset your password.";
export const DID_NOT_RECEIVED_MSG = "Did not receive it?";
export const YOU_CAN_RESEND_MSG = "You can resend password reset email in";
export const TIME_MSG = "23 hours 59 minutes.";
export const EMAIL_SUCCESS_MSG =
  "Please check your registered email id to reset your email!";
export const YOUR_USER_EMAIL = "Your TryHackMe username is";
export const MANAGE_OUR_ACCOUNT = "Manage our account settings in";
export const PASSWORD_SUCCESS_MSG =
  "Please check your registered email id to reset your password!";
export const THM_UPDATE_MESSAGE =
  "Updates happen overnight, please log in tomorrow to see your scores!";