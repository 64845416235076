import React from "react";
import { Block } from "baseui/block";
import { Input } from "baseui/input";
import { ButtonGroup } from "baseui/button-group";
import { Button } from "baseui/button";
import SnackBar from "components/SnackBar";
import { useStyletron } from "baseui";
import Skeleton from "components/Skeleton";
import {
  FACEBOOK_ERROR,
  LINKEDIN_ERROR,
  TRYHACKME_ERROR,
} from "resources/constants/messages";
import {
  Linkedin,
  Facebook,
  TryHackMe,
} from "resources/constants/strings";
import socialAccountsUpdate from "services/requests/student_socialaccount_update";
import {
  SAVE_BTN_STYLE,
  CANCEL_BTN_STYLE,
  MB3_STYLE,
  COLUMN_INPUTS_STYLE,
  INPUT_LABEL_STYLE,
  INPUT_OVERRIDE_STYLE,
} from "../../style.js";

export default function LinksEdit(props) {
  const [css, theme] = useStyletron();
  const [apiStatusMessage, setApiStatusMessage] = React.useState(false);
  const [payloadObj, setPayloadObj] = React.useState({
    linkedInId: "",
    facebookId: "",
    twitterId: "",
  });
  const [urlError, setURLError] = React.useState({
    linkedInId: false,
    facebookId: false,
    twitterId: false,
  });
  const facebookSiteDomain = [
    "facebook",
    "fb",
    "fbe2e",
    "fbinfra",
    "fbpigeon",
    "fbsbx",
    "fbwat",
    "wit",
    "atlassolutions",
    "fbcdn",
    "atdmt",
    "accountkit",
  ];
  const linkedInSiteDomain = ["licdn", "linkedin", "lnkd"];
  const TryHackMeDomain = ["tryhackme"];

  React.useEffect(() => {
    setPayloadObj({
      linkedInId: props.profileData?.linkedinid || "",
      facebookId: props.profileData?.facebookid || "",
      twitterId: props.profileData?.twitterid || "",
    });
  }, [props.profileData]);

  const inputsHandler = (e) => {
    const { name, value } = e.target;

    setPayloadObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const setError = (fieldName, value) => {
    setURLError((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  function validateURL(value, domainArray) {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?[a-zA-Z0-9_-]\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]*)/g;
    // const regex = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/g;

    if (value !== "" ) {
      if (regex.test(value?.trim())) {
        let result = domainArray.filter((domain) => {
          return value.includes(domain);
        });
        return result.length >= 1;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateURL(payloadObj.linkedInId, linkedInSiteDomain)) {
      setError("linkedInId", false);
      if (validateURL(payloadObj.facebookId, facebookSiteDomain)) {
        setError("facebookId", false);
        if (validateURL(payloadObj.twitterId, TryHackMeDomain)) {
          setError("twitterId", false);
          setApiStatusMessage(true);
          socialAccountsUpdate(localStorage.getItem("email"), payloadObj).then(
            function (response) {
              if (response.status === 200) {
                props.setUpdateData(true);
                setTimeout(() => {
                  setApiStatusMessage(false);
                  props.parentCallback();
                }, 2000);
              }
            }
          );
        } else {
          setError("twitterId", true);
        }
      } else {
        setError("facebookId", true);
      }
    } else {
      setError("linkedInId", true);
    }
  };

  const cancel = () => {
    props.parentCallback(false);
  };

  return (
    <>
      {!apiStatusMessage ? (
        <form onSubmit={handleSubmit}>
          <Block width="80%" className={css(COLUMN_INPUTS_STYLE(theme, 2))}>
            <Block data-testid="linksform">
              <Block className={css(INPUT_LABEL_STYLE(theme))}>{Linkedin} URL</Block>
              <Input
                overrides={{
                  Input: { style: INPUT_OVERRIDE_STYLE.Input },
                  Root: { style: INPUT_OVERRIDE_STYLE.Root },
                }}
                name="linkedInId"
                data-testid="linkedInId"
                minLength={2}
                onChange={inputsHandler}
                value={payloadObj?.linkedInId || ""}
              />
              {urlError.linkedInId ? (
                <Block as="span" style={{ color: "red" }}>
                  {LINKEDIN_ERROR}
                </Block>
              ) : (
                <Block as="span" style={{ visibility: "hidden" }}>
                  ff
                </Block>
              )}
            </Block>
            <Block>
              <Block className={css(INPUT_LABEL_STYLE(theme))} data-testid="facebook_url">{Facebook} URL</Block>
              <Input
                overrides={{
                  Input: { style: INPUT_OVERRIDE_STYLE.Input },
                  Root: { style: INPUT_OVERRIDE_STYLE.Root },
                }}
                name="facebookId"
                minLength={2}
                data-testid="facebookId"
                onChange={inputsHandler}
                value={payloadObj?.facebookId || ""}
              />
              {urlError.facebookId ? (
                <Block as="span" style={{ color: "red" }}>
                  {FACEBOOK_ERROR}
                </Block>
              ) : (
                <Block as="span" style={{ visibility: "hidden" }}>
                  ff
                </Block>
              )}
            </Block>
            <Block>
              <Block className={css(INPUT_LABEL_STYLE(theme))} data-testid="try_hack_me_url">{TryHackMe} URL</Block>
              <Input
                overrides={{
                  Input: { style: INPUT_OVERRIDE_STYLE.Input },
                  Root: { style: INPUT_OVERRIDE_STYLE.Root },
                }}
                name="twitterId"
                minLength={2}
                data-testid="twitterId"
                onChange={inputsHandler}
                value={payloadObj?.twitterId || ""}
              />
              {urlError.twitterId ? (
                <Block as="span" style={{ color: "red" }}>
                  {TRYHACKME_ERROR}
                </Block>
              ) : (
                <Block as="span" style={{ visibility: "hidden" }}>
                  ff
                </Block>
              )}
            </Block>
          </Block>
          <Block>
            <ButtonGroup
              size="compact"
              overrides={{
                Root: { style: { display: 'flex', gap: '20px' } }
              }}
            >
              <Button
                type="submit"
                data-testid="save"
                overrides={{ BaseButton: { style: SAVE_BTN_STYLE } }}
              >
                Save
              </Button>
              <Button
                onClick={cancel}
                data-testid="cancel"
                overrides={{ BaseButton: { style: CANCEL_BTN_STYLE } }}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </Block>
          {apiStatusMessage && (
            <SnackBar message={"Values Updated Successfully"} />
          )}
        </form>
      ) : (
        <Block marginTop={"15px"}>
          <Skeleton oval={false} height="auto" width="auto" rows={4} />
        </Block>
      )}
    </>
  );
}
