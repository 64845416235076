import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { useStyletron } from "baseui";
import { useHistory } from "react-router-dom";
import React, { lazy, Suspense, useState, useEffect } from "react";
import {
  COMMON_RADIUS,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_STYLE,
} from "utils/style";
import { CREATE_ROLE, JOB_ROLE_TITLE } from "resources/constants/strings.js";
import { fetchJobGroups } from "services/requests/job_role.js";

const BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  backgroundColor: "white",
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  borderColor: $theme.RAColors?.purple,
  paddingTop: "12px",
  paddingBottom: "12px",
  paddingRight: "24px",
  paddingLeft: "24px",
  float: "right",
  fontFamily: "Manrope-Bold",
  ":hover": { backgroundColor: $theme.RAColors?.gray300 },
  color: $theme.RAColors?.purple,

  [$theme.mediaQuery.small]: {
    width: "100%",
  },
  [$theme.mediaQuery.medium]: {
    width: "initial",
  },
});

const TITLE_STYLE = (theme) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  paddingLeft: "4px",
  paddingRight: "4px",
  [theme.mediaQuery.small]: {
    marginBottom: "16px",
  },
  [theme.mediaQuery.medium]: {
    marginBottom: "36px",
  },
});
const HEADING_STYLE = (theme) => ({
  fontFamily: "Epilogue-Semibold",
  fontSize: "28px",
  lineHeight: "40px",
  color: theme.RAColors?.gray900,
  display: "inline",
});
// const HEADING_SECTION = () => ({
//   display: "flex",
//   width: "100%",
//   backgroundColor: "red",
//   justifyContent: "space-between",
// });

const ModalComponent = lazy(() => import("./manageJobGroup.js"));
const CreateJobRole = lazy(() => import("./newJobGroup.js"));

const Title = ({ parentCallback}) => {
  const [css, theme] = useStyletron();
  const history = useHistory();
  const [showModal, setShowModal] = React.useState(false);
  const [showModalCreate, setShowModalCreate] = React.useState(false);
  const [datavals, setDatavals] = useState("");

  useEffect(() => {
    const base = [];
    const userEmail = localStorage.getItem("email") || "";
    fetchOptions(userEmail, base);
  }, []);

  const fetchOptions = async (userEmail, base) => {
    const response = await fetchJobGroups(userEmail);

    if (response.status !== 200) return base;

    setDatavals(response.data);
  };

  const gotojobGroup = () => {
    const base = [];
    const userEmail = localStorage.getItem("email") || "";
    setShowModal(true);
    fetchOptions(userEmail, base);
  };

  function closeModal() {
    setShowModal(false);
  }

  const addDept = () => {
    setShowModal(false);
    setShowModalCreate(true);
  };

  function closeModalCreate() {
    setShowModalCreate(false);
  }
  const jobGroupCallback = (childdata) => {
    parentCallback(childdata);
  };

  return (
    <Block className={css(TITLE_STYLE(theme))}>
      <Block
        width="100%"
        display="flex"
        alignItems={["flex-start", "flex-start", "center"]}
        justifyContent="space-between"
        flexDirection={["column", "column", "row"]}
        gridGap="16px"
      >
        <Block
          as="h3"
          className={css(HEADING_STYLE(theme))}
          data-testid="title"
        >
          {JOB_ROLE_TITLE}
        </Block>
        <Block
          display="flex"
          flexDirection={["column", "column", "row"]}
          gridGap="16px"
          width={["100%", "100%", "initial"]}
        >
          <Button
            overrides={{ BaseButton: { style: BUTTON_OVERRIDE_STYLE } }}
            data-testid="download-button"
            onClick={gotojobGroup}
          >
            <span>{"Manage Job Groups"}</span>
          </Button>
          <Button
            onClick={() => history.push("/job-roles/create")}
            overrides={{ BaseButton: { style: BUTTON_OVERRIDE_STYLE } }}
            data-testid="download-button"
          >
            <span>{CREATE_ROLE}</span>
          </Button> 
        </Block>
      </Block>
      <Suspense fallback={<div />}>
        {showModal && (
          <ModalComponent
            datavals={datavals}
            jobGroup={jobGroupCallback}
            addDept={addDept}
            showModal={showModal}
            close={() => closeModal()}
          />
        )}
        {showModalCreate && (
          <CreateJobRole
            datavals={datavals}
            jobGroup={jobGroupCallback}
            showModal={showModalCreate}
            close={() => closeModalCreate()}
          />
        )}
      </Suspense>
    </Block>
  );
};

export default Title;
