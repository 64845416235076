import { Header } from "components/Header";
import DrawerRouterContainer from "components/newDrawer";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Redirect } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import getRolesPageVisibility from "../../components/getPageVisibility"
import loggedUser from "services/requests/loggedUser";
import {
  COMMON_RADIUS,
  COMMON_BORDER_COLOR,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_STYLE,
} from "utils/style";

const TABLE_OVERRIDE_STYLE = {
  Root: ({ $theme }) => ({
    ...COMMON_RADIUS("8px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    ...COMMON_BORDER_STYLE("solid"),
  }),
  MessageCell: { minWidth: '8%', width:'10%' },
  TimeCell: { width: '33%'},
  IdCell: { width: '20%'},
};

const MAIN_STYLE = (theme) => ({
  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "40px",
  },
});

const HEADING_STYLE = (theme) => ({
  fontFamily: "Epilogue-Semibold",
  fontSize: "28px",
  lineHeight: "40px",
  color: theme.RAColors?.gray900,
});

const TrackUser = () => {
  const [css, theme] = useStyletron();

  const role = localStorage.getItem("viewAs") === null ? localStorage.getItem("role") : localStorage.getItem("viewAs");
  const [isFetching, setIsFetching] = useState(false);
  const [APILinkforceLogs, setAPILinkforceLogs] = useState([]);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    localStorage.setItem("currentPage","Track User")
    loggedDetail()
  }, []);

  const loggedDetail = () =>{
    loggedUser("","","GET")
        .then(function (response) {
          if (response.status === 200) {
            setUserData(response.data.slice(0, 19))
          }       
        })
        .catch((err) => {
          console.log(err);
        });
  }

  const formatTime = (t) => {
    const date = new Date(t);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  //page access control - based on role
  const userType = useMemo(() => {
    var permittedRoles = getRolesPageVisibility("Track User");
    if (permittedRoles.includes(role)) {
      return "manager";
    }
  });
  if (userType !== 'manager') return <Redirect to="/student" />

  // if (role !== "ADMIN") return <Redirect to="/student" />;

  return (
    <>
      <Header />
      <DrawerRouterContainer>
        <main className={css(MAIN_STYLE(theme))}>
          <Block
            as="h3"
            className={css(HEADING_STYLE(theme))}
            marginBottom="16px"
            data-testid="logs-text"
          >
            Track User
          </Block>
          <Block>
                <TableBuilder
                  data={userData}
                  isLoading={isFetching || false}
                  emptyMessage="No Data"
                  overrides={{ Root: { style: TABLE_OVERRIDE_STYLE.Root } }}
                >
                  <TableBuilderColumn
                    header="Name"
                    overrides={{
                      TableHeadCell: { style: TABLE_OVERRIDE_STYLE.IdCell },
                      TableBodyCell: { style: TABLE_OVERRIDE_STYLE.IdCell },
                    }}
                  >
                    {(row) => (
                      <div data-testid="row-message-linkforce">
                      {row.name}
                    </div>
                    )}
                  </TableBuilderColumn>
                  <TableBuilderColumn
                    header="Email"
                    overrides={{
                      TableHeadCell: { style: TABLE_OVERRIDE_STYLE.TimeCell },
                      TableBodyCell: { style: TABLE_OVERRIDE_STYLE.TimeCell },
                    }}
                  >
                    {(row) => (
                      <div data-testid="row-message-linkforce">
                        {row.email_id}
                      </div>
                    )}
                  </TableBuilderColumn>
                  <TableBuilderColumn
                    header="Logged In Date and Time"
                    overrides={{
                      TableHeadCell: {
                        style: TABLE_OVERRIDE_STYLE.MessageCell,
                      },
                      TableBodyCell: {
                        style: TABLE_OVERRIDE_STYLE.MessageCell,
                      },
                    }}
                  >
                    {(row) => (
                      <div data-testid="row-time">
                        {row.last_logged_time === null ? "-" : formatTime(row.last_logged_time)}
                      </div>
                    )}
                  </TableBuilderColumn>
                </TableBuilder>
          </Block>
        </main>
      </DrawerRouterContainer>
    </>
  );
};

export default TrackUser;
