import { EDUCATION_GET_UPDATE } from "../apiEndpoint";
import { educationAxios } from "../http/http";
export default function educationUpdate(methodType, payload) {
  if (methodType === "delete") {
    const config = {
      data: payload
    };
    return educationAxios.delete(EDUCATION_GET_UPDATE, config);
  } else if (methodType === "add") {
    return educationAxios.post(EDUCATION_GET_UPDATE, payload);
  } else {
    return educationAxios.put(EDUCATION_GET_UPDATE, payload);
  }
}
