import { useStyletron } from "baseui";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import {
  COMMON_BORDER_COLOR,
  COMMON_BORDER_STYLE,
  COMMON_BORDER_WIDTH,
  COMMON_RADIUS,
} from "utils/style";
import TableHeader from "./tableHeader";
import { Block } from "baseui/block";
import { useMemo } from "react";

const TABLE_OVERRIDE_STYLE = {
  Root: ({ $theme }) => ({
    ...COMMON_RADIUS("8px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    ...COMMON_BORDER_STYLE("solid"),
  }),
  NameCell: { width: "40%", maxWidth: "40%", minWidth: "200px" },
  IdCell: { width: "5%", maxWidth: "5%", minWidth: "20px" },
  CategoryCell: { width: "35%", maxWidth: "35%", minWidth: "200px" },
  CriteriaCell: { width: "60%", maxWidth: "60%", minWidth: "200px" },
  OtherCell: { width: "10%", maxWidth: "10%", minWidth: "150px" },
  OJTCell: { width: "5%", maxWidth: "5%", minWidth: "40px" },
};

const NAME_STYLE = (theme) => ({
  fontFamily: "Epilogue",
  color: theme.RAColors?.black,
  fontSize: "14px",
  lineHeight: "19px",
});

const TemplateTable = ({ data }) => {
  const [css, theme] = useStyletron();

  const sanitizedData = useMemo(() => {
    const alphabet = "abcdefghijklmnopqrstuvwxyz";
    const { TemplateCategories } = data;
    let item = [];

    TemplateCategories.forEach((category, index) => {
      const { TemplateCriteria, CategoryName } = category;

      TemplateCriteria.forEach((criteria, criteriaIndex) => {
        item.push({
          id: index + 1 + alphabet[criteriaIndex],
          category: CategoryName,
          criteria,
        });
      });
    });

    return item;
  }, [data]);

  // if (!sanitizedData.length) {
  //   return "No Data"
  // }

  return (
    <TableBuilder
      data={sanitizedData}
      overrides={{
        Root: { style: TABLE_OVERRIDE_STYLE.Root },
        TableBodyRow: {
          component: ({ children, $row, ...rest }) => (
            <Block as="tr">{children}</Block>
          ),
        },
      }}
      isLoading={false}
      emptyMessage="No data"
    >
      <TableBuilderColumn
        header={<TableHeader name="ID" />}
        overrides={{
          TableHeadCell: {
            style: TABLE_OVERRIDE_STYLE.IdCell,
          },
          TableBodyCell: {
            style: TABLE_OVERRIDE_STYLE.IdCell,
          },
        }}
      >
        {(row) => (
          <Block
            data-testid="template-table-id"
            className={css(NAME_STYLE(theme))}
          >
            {row?.id}
          </Block>
        )}
      </TableBuilderColumn>
      <TableBuilderColumn
        header={<TableHeader name="Category" />}
        overrides={{
          TableHeadCell: {
            style: TABLE_OVERRIDE_STYLE.CategoryCell,
          },
          TableBodyCell: {
            style: TABLE_OVERRIDE_STYLE.CategoryCell,
          },
        }}
      >
        {(row) => (
          <Block
            data-testid="template-table-category"
            className={css(NAME_STYLE(theme))}
          >
            {row?.category}
          </Block>
        )}
      </TableBuilderColumn>
      <TableBuilderColumn
        header={<TableHeader name="Criteria" />}
        overrides={{
          TableHeadCell: {
            style: TABLE_OVERRIDE_STYLE.CriteriaCell,
          },
          TableBodyCell: {
            style: TABLE_OVERRIDE_STYLE.CriteriaCell,
          },
        }}
      >
        {(row) => (
          <Block
            data-testid="template-table-criteria"
            className={css(NAME_STYLE(theme))}
          >
            {row?.criteria.CriteriaName}
          </Block>
        )}
      </TableBuilderColumn>
      {/* {data?.TemplateCriteriaFlow?.rti && ( */}
        <TableBuilderColumn
          header={<TableHeader name="RTI" />}
          overrides={{
            TableHeadCell: {
              style: TABLE_OVERRIDE_STYLE.OtherCell,
            },
            TableBodyCell: {
              style: TABLE_OVERRIDE_STYLE.OtherCell,
            },
          }}
        >
          {(row) =>
            row?.criteria?.rti?.length ? (
              row.criteria.rti.map((c, index) => (
                <Block data-testid="template-table-rti" key={index}>
                  {c}
                </Block>
              ))
            ) : (
              <Block data-testid="template-table-rti">Not Specified</Block>
            )
          }
        </TableBuilderColumn>
      {/* )} */}
      {/* {data?.TemplateCriteriaFlow?.js && ( */}
        <TableBuilderColumn
          header={<TableHeader name="JS" />}
          overrides={{
            TableHeadCell: {
              style: TABLE_OVERRIDE_STYLE.OtherCell,
            },
            TableBodyCell: {
              style: TABLE_OVERRIDE_STYLE.OtherCell,
            },
          }}
        >
          {(row) =>
            row?.criteria?.js ? (
              <Block data-testid="template-table-js">{row.criteria.js}</Block>
            ) : (
              <Block data-testid="template-table-js">Not Specified</Block>
            )
          }
        </TableBuilderColumn>
      {/* )} */}
      {/* {data?.TemplateCriteriaFlow?.ojt && ( */}
        <TableBuilderColumn
          header={<TableHeader name="OJT" />}
          overrides={{
            TableHeadCell: {
              style: TABLE_OVERRIDE_STYLE.OJTCell,
            },
            TableBodyCell: {
              style: TABLE_OVERRIDE_STYLE.OJTCell,
            },
          }}
        >
          {(row) =>
            // row?.criteria?.ojt ? (
              <Block data-testid="template-table-ojt">{row.criteria.ojt ? "Yes" : "No"}</Block>
            // ) : (
            //   <Block data-testid="template-table-ojt">Not Specified</Block>
            // )
          }
        </TableBuilderColumn>
      {/* )} */}
    </TableBuilder>
  );
};

export default TemplateTable;
