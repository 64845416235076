import React from "react";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import TaskBlockArrow from "../dolCompetency/taskBlockArrow";
import ProficientTaskBlock from "../dolCompetency/proficientTaskBlock";
import ConditionalTaskBlock from "../dolCompetency/conditionalTaskBlock";

const INNER_TASKS_BLOCK_STYLE = (theme) => ({
  display: "flex",
  alignItems: "center",
  [theme.mediaQuery.small]: {
    display: "grid",
    justifyContent: "center",
  },
  [theme.mediaQuery.medium]: {
    display: "flex",
    justifyContent: "flex-start",
  },
  [theme.mediaQuery.large]: {
    display: "flex",
    justifyContent: "flex-start",
  },
});

const TaskBlock = ({
  item,
  itemData,
  Data,
  changeDolData,
  selectedIndex,
  rolespermission,
  handleClick,
  openVerifyCompetencyModal,
  categoryData = "",
  isRoleSpecificActivity = false,
  index,
}) => {
  const [css, theme] = useStyletron();

  const getAvailableTasks = (itemObject) => {
    let array = [
      "RTI",
      "RTIStatus",
      "JS",
      "JSStatus",
      "OJT",
      "OJTStatus",
      "MentorProficientStatus",
      "LearnerProficientStatus",
      "FinalApprovalStatus",
    ];
    let availableListedTask = Object.keys(itemObject);

    let filteredAvailableListedTask = array.filter(function (n) {
      return availableListedTask.indexOf(n) !== -1;
    });
    return filteredAvailableListedTask;
  };
  const proficientArray = ["MentorProficientStatus", "LearnerProficientStatus"];
  const multipleExist = proficientArray.every((value) => {
    return getAvailableTasks(itemData).includes(value);
  });
  let array = [
    "RTI",
    "RTIStatus",
    "JS",
    "JSStatus",
    "OJT",
    "OJTStatus",
    "FinalApprovalStatus",
  ];

  if (multipleExist && item === "MentorProficientStatus") {
    return (
      <Block className={css(INNER_TASKS_BLOCK_STYLE(theme))}>
        <ProficientTaskBlock
          key={index}
          index={index}
          item={item}
          itemData={itemData}
          Data={Data}
          changeDolData={changeDolData}
          selectedIndex={selectedIndex}
          rolespermission={rolespermission}
          handleClick={handleClick}
          openVerifyCompetencyModal={openVerifyCompetencyModal}
          categoryData={categoryData}
          isRoleSpecificActivity={isRoleSpecificActivity}
          isMultiple={multipleExist}
        />
        <TaskBlockArrow vals={true} />
      </Block>
    );
  } else if (
    !multipleExist &&
    (item === "MentorProficientStatus" || item === "LearnerProficientStatus")
  ) {
    return (
      <Block className={css(INNER_TASKS_BLOCK_STYLE(theme))}>
        <ProficientTaskBlock
          key={index}
          index={index}
          item={item}
          itemData={itemData}
          Data={Data}
          changeDolData={changeDolData}
          selectedIndex={selectedIndex}
          rolespermission={rolespermission}
          handleClick={handleClick}
          openVerifyCompetencyModal={openVerifyCompetencyModal}
          categoryData={categoryData}
          isRoleSpecificActivity={isRoleSpecificActivity}
          isMultiple={multipleExist}
        />
        <TaskBlockArrow vals={true} />
      </Block>
    );
  } else if (array.includes(item)) {
    return (
      <Block className={css(INNER_TASKS_BLOCK_STYLE(theme))}>
        <ConditionalTaskBlock
          key={index}
          index={index}
          item={item}
          itemData={itemData}
          Data={Data}
          changeDolData={changeDolData}
          selectedIndex={selectedIndex}
          rolespermission={rolespermission}
          handleClick={handleClick}
          openVerifyCompetencyModal={openVerifyCompetencyModal}
          categoryData={categoryData}
          isRoleSpecificActivity={isRoleSpecificActivity}
        />
        {item === "FinalApprovalStatus" ? null : (
          <TaskBlockArrow vals={false} />
        )}
      </Block>
    );
  } else {
    return null;
  }
};
export default TaskBlock;
