import React from "react";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import Skeleton from "../../../../../components/Skeleton";
import { TryHackMe } from "resources/constants/strings";
import {
  YOUR_USER_EMAIL,
  MANAGE_OUR_ACCOUNT,
} from "resources/constants/messages";
import {
  CARD_STYLE,
  CARD_HEADING_STYLE,
  MARGIN_TOP_STYLE,
} from "../../style.js";

export default function Tryhackmeinfo(props) {
  const [css, theme] = useStyletron();
  return (
    <Block className={css({ ...CARD_STYLE(theme), rowGap: "20px" })}>
      <Block
        as="h3"
        className={css(CARD_HEADING_STYLE)}
        data-testid="TryHackMe_Text"
      >
        {TryHackMe}
      </Block>
      {!props.loader ? (
        <>
          <Block as="p" className={css(MARGIN_TOP_STYLE)}>
            {/* {YOUR_USER_EMAIL + " " + localStorage.getItem("username")} */}
          </Block>
          <Block as="p" className={css(MARGIN_TOP_STYLE)}>
            {MANAGE_OUR_ACCOUNT + " "}
            <Block as="a" href="https://tryhackme.com/profile" target="_blank">
              <Block as="span" color="#332ff1" data-testid="TryHackMe_Link">
                {TryHackMe}
              </Block>
            </Block>
          </Block>
        </>
      ) : (
        <Block marginTop={"15px"}>
          <Skeleton oval={false} height="auto" width="auto" rows={1} />
        </Block>
      )}
    </Block>
  );
}
