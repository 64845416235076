import axios from "axios";
import { STUDENT_LIST, TENANTS } from "../apiEndpoint";
import { studentURL } from "../config";

const LinkforceControllerTenantName = axios.create({
  baseURL: studentURL,
});

LinkforceControllerTenantName.defaults.headers.common = {
  ...(LinkforceControllerTenantName.defaults.headers.common || {}),
  ApiKey: "TESTDEV-0000-1111-2222-333333333333",
};

const tenantName = (course_name) =>
  LinkforceControllerTenantName.get(`${TENANTS}?`);

const fetchStudentList = (email) =>
  LinkforceControllerTenantName.get(`${STUDENT_LIST}?user_email=${email}`);

export { fetchStudentList, tenantName };
