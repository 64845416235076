import { Block } from "baseui/block";
import React from "react";
import { Button } from "baseui/button";
import { useStyletron } from "baseui";
import DownloadIcon from "assets/images/download-icon.svg";
import {
  COMMON_RADIUS,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_STYLE,
} from "utils/style";
import MyDocument from "./PdfExport";
import {
  PDFDownloadLink
  } from '@react-pdf/renderer';
import {
  Download_PDF,
  Dol_Checklist_Title,
} from "resources/constants/strings.js";
import {Select, TYPE} from 'baseui/select';

//const BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
//   backgroundColor: "white",
//   ...COMMON_RADIUS("8px"),
//   ...COMMON_BORDER_WIDTH("1px"),
//   ...COMMON_BORDER_STYLE("solid"),
//   //borderColor: $theme.RAColors?.purple,
//   paddingTop: "12px",
//   paddingBottom: "12px",
//   paddingRight: "24px",
//   paddingLeft: "24px",
//  // ":hover": { backgroundColor: $theme.RAColors?.gray300 },
//   //color: $theme.RAColors?.purple,

//   [$theme.mediaQuery.small]: {
//     width: '100%',
//   },
//   [$theme.mediaQuery.medium]: {
//     width: "initial",
//   },
// });
const DOWNLOAD_ICON_STYLE = {
  width: "12px",
  height: "12px",
  marginRight: "16px",
};
const TITLE_STYLE = theme => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: '16px',
  flexWrap: 'wrap',
  paddingLeft: "4px",
  paddingRight: "4px",
  [theme.mediaQuery.small]: {
    marginBottom: "16px",
  },
  [theme.mediaQuery.medium]: {
    marginBottom: "36px",
  },
});
const HEADING_STYLE = (theme) => ({
  fontFamily: "Epilogue-Semibold",
  fontSize: "28px",
  lineHeight: "40px",
  color: theme.RAColors?.gray900,
});

// const Download_Dropdown = (theme) => ({
//   width: "40%"
// })

const Title = ({ exportPdf }) => {
  const [css, theme] = useStyletron();
  const [value, setValue] = React.useState([{id: 'Core Competency', value: 'Core Competency'}]);
  return (
    <Block className={css(TITLE_STYLE(theme))}>
       <Block as="h3" className={css(HEADING_STYLE(theme))} data-testid="title">
        {Dol_Checklist_Title}
      </Block>
      {(localStorage.getItem("role") === "ADMIN" && (localStorage.getItem("viewAs") === "ADMIN" || localStorage.getItem("viewAs") === "TENANT OWNER" || localStorage.getItem("viewAs") === "LEARNER")) || localStorage.getItem("role") === "TENANT OWNER" || localStorage.getItem("role") === "LEARNER"  ?
      <Block width="43%">
        <Block style={{width:"30%",display:"inline",fontFamily:'Manrope-Medium'}}>{'Report For:'}</Block>
        <Select
        overrides={{
          Root: {
            style: ({ $theme }) => ({
              display:"inline-block",
              width:"54%",
              padding:"0 10px",
              margin: "5px 0",
              borderRadius:"8px"
            })
          },
          DropdownContainer:{
            style: ({ $theme }) => ({
             width:"254px"
            })
          },
          ControlContainer:{
            style: ({ $theme }) => ({
              border:"1px solid rgb(224, 224, 224)",
              padding:"1px 0 0",
             })
          },
          ValueContainer:{
            style: ({ $theme }) => ({
              
              padding:"5px 10px 8px",
             })
          }
        }}
        clearable={false}
      searchable={false}
      options={[
        {id: 'Core Competency', value: 'Core Competency'},
        {id: 'Role Specific Activity', value: 'Role Specific Activity'},
        {id: 'Core Competency & Role specific Activity', value: 'Core Competency & Role specific Activity'}
      ]}
      labelKey="id"
      valueKey="value"
      data-testid="report-select"
      //placeholder="Choose a color"
      maxDropdownHeight="300px"
      onChange={({value}) =>setValue(value)}
      value={value}
     
    />
    <Block style={{border:"1px solid", borderRadius:"8px",color:"grey",padding:"10px",display:"inline-block"}} data-testid="download-button">
    <img
          src={DownloadIcon}
          alt="Download"
          className={css(DOWNLOAD_ICON_STYLE)}
        />
        <span></span>
    <PDFDownloadLink document={<MyDocument data={exportPdf} report={value[0].value}/>} fileName={exportPdf?.FirstName + ' ' +exportPdf?.LastName +' Report.pdf'}>
      {({ blob, url, loading, error }) => (loading ? 'Loading...' : Download_PDF)}
    </PDFDownloadLink></Block>
    </Block>
    : null}
      {/* <Button
        //onClick={exportPdf}
        overrides={{ BaseButton: { style: BUTTON_OVERRIDE_STYLE } }}
        data-testid="download-button"
      >
        <img
          src={DownloadIcon}
          alt="Download"
          className={css(DOWNLOAD_ICON_STYLE)}
        />
        <span></span>
        <PDFDownloadLink document={<MyDocument data={exportPdf}/>} fileName="dolchecklist.pdf">
      {({ blob, url, loading, error }) => (loading ? 'Loading document...' : Download_PDF)}
    </PDFDownloadLink>
      </Button> */}
    </Block>
  );
};

export default Title;
