import axios from "axios";
import { studentURL } from "../config";
import { CHOROT, GRADES, QUEST } from "services/apiEndpoint";

const CohortData = axios.create({
  baseURL: studentURL,
});

CohortData.defaults.headers.common = {
  ...(CohortData.defaults.headers.common || {}),
  ApiKey: "TESTDEV-0000-1111-2222-333333333333",
};
const fetchCohortStudentData = (email, role) =>
  CohortData.get(`/cohortStudentData?user_email=${email}&role=${role}`);

const fetchCohortData = () => CohortData.get(`${CHOROT}`);

const fetchQuest = (name) => CohortData.get(`${QUEST}?cohort_name=${name}`);

const fetchGrade = (cohorot, quest) =>
  CohortData.get(`${GRADES}?cohort_name=${cohorot}&quest_name=${quest}`);
//const getWordpressData = (email) => JumpStartData.get(`master/all_course_data?email_id=bsamani@rapidascent.io`)

const fetchLearner = (email) => CohortData.get(`${GRADES}?user_email=${email}`);

const postCohort = (payload) => CohortData.post(`${CHOROT}`, payload);
export {
  fetchCohortStudentData,
  fetchCohortData,
  fetchQuest,
  fetchGrade,
  postCohort,
  fetchLearner,
};