import React from "react";
import { Block } from "baseui/block";
import { Input } from "baseui/input";
import { Select } from "baseui/select";
import { useStyletron } from "baseui";
import { ACTIVITIES } from "resources/constants/strings.js";
import closeIcon from "assets/images/close-btn-white.png";
import {
  COMMON_RADIUS,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_COLOR,
} from "utils/style";
import { useSetState, useTrackedState } from "store/store";

const COMMON_TEXT_STYLE = {
  fontFamily: "Manrope",
  lineHeight: "20px",
  fontWeight: 700,
};
const HEADING_TEXT_STYLE = (theme) => ({
  ...COMMON_TEXT_STYLE,
  fontSize: "24px",
  color: theme.RAColors?.black,
});
const BLOCK_STYLE = (theme) => ({
  width: "20%",
  [theme.mediaQuery.small]: {
    width: "40%",
  },
  [theme.mediaQuery.medium]: {
    width: "40%",
  },
  [theme.mediaQuery.large]: {
    width: "20%",
  },
});
const LABEL_STYLE = (theme) => ({
  ...COMMON_TEXT_STYLE,
  fontFamily: "Manrope-medium",
  fontWeight: 500,
  lineHeight: "22px",
  margin: "0 0 9px",
  [theme.mediaQuery.small]: {
    fontSize: "14px",
  },
  [theme.mediaQuery.medium]: {
    fontSize: "16px",
  },
});
const CLOSE_ICON_BLOCK_STYLE = (theme) => ({
  position: "absolute",
  top: "68px",
  right: "95px",
  [theme.mediaQuery.small]: {
    top: "95px",
    right: "20px"
  },
  [theme.mediaQuery.medium]: {
    top: "120px",
    right: "35px"
  },
  [theme.mediaQuery.large]: {
    top: "68px",
    right: "95px"
  },
});
const CLOSE_ICON_STYLE = (theme) => ({
  width: "24px",
  height: "24px",
  borderRadius: "100%",
  cursor: "pointer",
});
const INPUT_OVERRIDE_STYLE = {
  Input: ({ $theme }) => ({
    backgroundColor: "none",
    color: $theme.RAColors?.gray900,
  }),
  Root: ({ $theme }) => ({
    backgroundColor: "none",
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    width: "100%",
  }),
};
const SELECT_OVERRIDE_STYLE = {
  Root: ({ $theme }) => ({ width: "100%" }),
  ControlContainer: ({ $theme }) => ({
    backgroundColor: "none",
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    color: $theme.RAColors?.gray900,
  }),
};
const FILTER_STYLE = (theme) => ({
  position: "relative",
  borderRadius: "16px",
  border: `1px solid ${theme.RAColors?.gray200}`,
  [theme.mediaQuery.small]: {
    marginBottom: "16px",
    padding: "12px",
  },
  [theme.mediaQuery.medium]: {
    marginBottom: "24px",
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    marginBottom: "24px",
    padding: "24px",
  },
});
const ADD_TASK_STYLE = (theme) => ({
  cursor: "pointer",
  fontFamily: "Manrope-semibold",
  fontWeight: 700,
  lineHeight: "22px",
  color: "#3036B2",
  fontSize: "16px",
  marginTop: "15px",
});

const SEPARATOR = {
  margin: "30px 0px",
};
const ALL_IMPORTANCE = [
  { label: "Low", id: "Low" },
  { label: "Medium", id: "Medium" },
  { label: "High", id: "High" },
];
const ALL_DIFFICULTY = [
  { label: "Low", id: "Low" },
  { label: "Medium", id: "Medium" },
  { label: "High", id: "High" },
];
const ALL_FREQUENCIES = [
  { label: "Daily", id: "Daily" },
  { label: "Weekly", id: "Weekly" },
  { label: "Bi-weekly", id: "Bi-weekly" },
  { label: "Monthly", id: "Monthly" },
];
export default function Activities() {
  const [css, theme] = useStyletron();
  const setState = useSetState();
  const state = useTrackedState();

  const addForm = () => {
    const activities = state?.jobObject?.JobRoleActivities;
    let lastId =
      activities.length > 0 ? activities[activities.length - 1].id + 1 : 0;
    let forms = {
      id: lastId,
      ActivityName: "",
      Importance: "",
      Difficulty: "",
      Frequency: "",
    };
    //setFormArray((prevState) => [...prevState, forms]);
    setState((prev) => ({
      jobObject: {
        ...prev.jobObject,
        JobRoleActivities: [...activities, forms],
      },
    }));
  };
  const removeForm = (formId) => {
    const activities = state?.jobObject?.JobRoleActivities;

    const newArr = activities.filter((activity) => {
      return activity.id !== formId;
    });
    //setFormArray(newArr);
    setState((prev) => ({
      jobObject: {
        ...prev.jobObject,
        JobRoleActivities: newArr,
      },
    }));
  };

  const changeValue = (name, value, index) => {
    const newData = state?.jobObject?.JobRoleActivities?.map((activity) => {
      if (activity.id === index) {
        return { ...activity, [name]: value };
      }
      return activity;
    });

    setState((prev) => ({
      ...prev,
      jobObject: {
        ...prev.jobObject,
        JobRoleActivities: newData,
      },
    }));
  };

  return (
    <Block>
      <Block
        className={css(HEADING_TEXT_STYLE(theme))}
        marginBottom="30px"
        data-testid="title"
      >
        {ACTIVITIES + " (optional)"}
      </Block>
      {state?.jobObject?.JobRoleActivities.length > 0 &&
        state?.jobObject?.JobRoleActivities?.map((item, index) => (
          <Block
            key={index}
            display="flex"
            flexWrap="wrap"
            gridGap={["16px", "8px", "24px"]}
            className={css(FILTER_STYLE(theme))}
          >
            <Block className={css(BLOCK_STYLE(theme))}>
              <Block
                as="h5"
                className={css(LABEL_STYLE(theme))}
                data-testid="activity_name"
              >
                {"Activity name"}
              </Block>
              <Input
                value={item.ActivityName}
                onChange={(e) =>
                  changeValue("ActivityName", e.target.value, index)
                }
                placeholder={"Enter text here"}
                clearOnEscape
                data-testid="activity_name_input"
                overrides={{
                  Input: { style: INPUT_OVERRIDE_STYLE.Input },
                  Root: { style: INPUT_OVERRIDE_STYLE.Root },
                }}
              />
            </Block>
            <Block className={css(BLOCK_STYLE(theme))}>
              <Block
                as="h5"
                className={css(LABEL_STYLE(theme))}
                data-testid="importance_label"
              >
                {"Importance"}
              </Block>
              <Select
                clearable={false}
                searchable={false}
                options={ALL_IMPORTANCE}
                data-testid="importance_select"
                placeholder=""
                value={
                  item.Importance
                    ? [{ label: item.Importance, id: item.Importance }]
                    : ""
                }
                onChange={(params) =>
                  changeValue("Importance", params.value[0].label, index)
                }
                overrides={{
                  Root: { style: SELECT_OVERRIDE_STYLE.Root },
                  ControlContainer: {
                    style: SELECT_OVERRIDE_STYLE.ControlContainer,
                  },
                }}
              />
            </Block>
            <Block className={css(BLOCK_STYLE(theme))}>
              <Block
                as="h5"
                className={css(LABEL_STYLE(theme))}
                data-testid="difficulty_label"
              >
                {"Difficulty"}
              </Block>
              <Select
                clearable={false}
                searchable={false}
                data-testid="difficulty_select"
                options={ALL_DIFFICULTY}
                placeholder=""
                value={
                  item.Difficulty
                    ? [{ label: item.Difficulty, id: item.Difficulty }]
                    : ""
                }
                onChange={(params) =>
                  changeValue("Difficulty", params.value[0].label, index)
                }
                overrides={{
                  Root: { style: SELECT_OVERRIDE_STYLE.Root },
                  ControlContainer: {
                    style: SELECT_OVERRIDE_STYLE.ControlContainer,
                  },
                }}
              />
            </Block>
            <Block className={css(BLOCK_STYLE(theme))}>
              <Block
                as="h5"
                className={css(LABEL_STYLE(theme))}
                data-testid="frequency_label"
              >
                {"Frequency"}
              </Block>
              <Select
                clearable={false}
                searchable={false}
                data-testid="frequency_select"
                options={ALL_FREQUENCIES}
                placeholder=""
                value={
                  item.Frequency
                    ? [{ label: item.Frequency, id: item.Frequency }]
                    : ""
                }
                onChange={(params) =>
                  changeValue("Frequency", params.value[0].label, index)
                }
                overrides={{
                  Root: { style: SELECT_OVERRIDE_STYLE.Root },
                  ControlContainer: {
                    style: SELECT_OVERRIDE_STYLE.ControlContainer,
                  },
                }}
              />
            </Block>
            <Block className={css(CLOSE_ICON_BLOCK_STYLE(theme))}>
              <Block
                as="img"
                src={closeIcon}
                alt="Close icon"
                data-testid="remove-form"
                className={css(CLOSE_ICON_STYLE(theme))}
                onClick={() => removeForm(item?.id)}
              />
            </Block>
          </Block>
        ))}
      <Block
        data-testid="add-more"
        className={css(ADD_TASK_STYLE(theme))}
        onClick={() => addForm()}
      >
        {"Add more"}
      </Block>
      <Block className={css(SEPARATOR)} />
    </Block>
  );
}
