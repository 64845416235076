import { COURSE_NAME } from "../apiEndpoint";
import axios from "axios";
import { studentURL } from "../config";

const LinkforceControllerCourseName = axios.create({
  baseURL: studentURL
})

LinkforceControllerCourseName.defaults.headers.common = {
  ...(LinkforceControllerCourseName.defaults.headers.common || {}),
  ApiKey: 'TESTDEV-0000-1111-2222-333333333333'
}

const courseName = (course_name) => LinkforceControllerCourseName.get(`${COURSE_NAME}?course_name=${course_name}`)

export default courseName;
