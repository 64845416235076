import React, { useEffect } from "react";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import Skeleton from "../../../../../components/Skeleton";
import {
  Linkedin,
  Facebook,
  TryHackMe,
} from "../../../../../resources/constants/strings";
import {
  BASIC_INFO_DETAILS,
  COLUMN_LEFT_STYLE,
  COLUMN_RIGHT_STYLE,
} from "../../style.js";

export default function LinksDetails(props) {
  const [css, theme] = useStyletron();
  const [linksProfileData, setLinksProfileData] = React.useState();

  useEffect(() => {
    setLinksProfileData(props.linkProfileData);
  }, [props.linkProfileData]);

  return (
    <>
      {!props.loader ? (
        <Block display="flex" flexDirection="column" gridGap="16px">
          <Block display="flex" flexDirection={["column", "column", "row"]}>
            <Block as="span" className={css(COLUMN_LEFT_STYLE(theme))} data-testid="linkedin_text">
              {Linkedin}
            </Block>
            <Block as="span" className={css(COLUMN_RIGHT_STYLE(theme))}>
              {linksProfileData?.linkedinid ? (
                <a href={linksProfileData?.linkedinid}>
                  {linksProfileData?.linkedinid}
                </a>
              ) : (
                "Not specified"
              )}
            </Block>
          </Block>

          <Block display="flex" flexDirection={["column", "column", "row"]}>
            <Block as="span" className={css(COLUMN_LEFT_STYLE(theme))} data-testid="facebook_text">
              {Facebook}
            </Block>
            <Block as="span" className={css(COLUMN_RIGHT_STYLE(theme))}>
              {linksProfileData?.facebookid ? (
                <a href={linksProfileData?.facebookid}>
                  {linksProfileData?.facebookid}
                </a>
              ) : (
                "Not specified"
              )}
            </Block>
          </Block>

          <Block display="flex" flexDirection={["column", "column", "row"]}>
            <Block as="span" className={css(COLUMN_LEFT_STYLE(theme))} data-testid="tryhack_me_text">
              {TryHackMe}
            </Block>
            <Block as="span" className={css(COLUMN_RIGHT_STYLE(theme))}>
              {linksProfileData?.twitterid ? (
                <a href={linksProfileData?.twitterid}>
                  {linksProfileData?.twitterid}
                </a>
              ) : (
                "Not specified"
              )}
            </Block>
          </Block>
        </Block>
      ) : (
        <Block marginTop={"15px"}>
          <Skeleton oval={false} height="auto" width="auto" rows={3} />
        </Block>
      )}
    </>
  );
}
