import React, { useState, useEffect } from "react";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import CircleIcon from "assets/images/circle-complete.svg";
import RightCircleIcon from "assets/images/right-circleTick.svg";
import CompetencyLevel1 from "assets/images/comp_level 1.svg";
import CompetencyLevel2 from "assets/images/comp_level 2.svg";
import CompetencyLevel3 from "assets/images/comp_level 3.svg";
import RightCircleGrayIcon from "assets/images/right-circleTickGray.svg";
import checkListManagerFunction from "services/requests/dol_checklistmanager";
import { useTrackedState } from "store/store";
import Loader from "react-js-loader";

const BLOCK_STYLE = (theme) => ({
  backgroundColor: "#FFFFFF",
  border: "1px solid #A3A3A3",
  boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.15)",
  borderRadius: "12px",
  width: "440px",
  [theme.mediaQuery.small]: {
    padding: "16px",
    width: "max-content",
  },
  [theme.mediaQuery.medium]: {
    padding: "24px",
    width: "max-content",
  },
  [theme.mediaQuery.large]: {
    padding: "12px",
    width: "440px",
  },
});
const STATUS_OPTIONS_BLOCK_STYLE = (theme) => ({
  display: "flex",
  alignItems: "center",
  gridGap: "10px",
  padding: "8px",
});
const SELECTED_STATUS_OPTIONS_BLOCK_STYLE = (theme) => ({
  display: "flex",
  alignItems: "center",
  gridGap: "10px",
  background: "#F5FCF8",
  padding: "8px",
  borderRadius: "12px",
  border: "1px solid #72D493",
});
const HEADING_LABEL_STYLE = (theme) => ({
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#141414",
});
const SUB_HEADING_LABEL_STYLE = (theme) => ({
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "14px",
  lineHeight: "24px",
  color: "#141414",
});
const CompetencyLevel_Icon = {
  width: "20px",
  height: "20px",
  borderRadius: "100%",
};
export default function VerifyCompetencyPopUp({
  data,
  taskData,
  changeDolData,
  selectedIndex,
  rolespermission,
  isRoleSpecificActivity,
}) {
  const [css, theme] = useStyletron();
  const [status, setStatus] = useState("");
  const [loader, setLoader] = React.useState(false);
  const userEmail = localStorage.getItem("email") || "";
  const userName = localStorage.getItem("username") || "";
  const state = useTrackedState();

  let STATUS_OPTIONS = [];
  if (taskData === "MentorProficientStatus") {
    STATUS_OPTIONS = [
      {
        label: "Not Yet Rated",
        id: "Not Yet Rated",
        value: "Not Yet Rated",
        image: CircleIcon,
      },
      {
        label: "Learning- Observed through job training or shadowing",
        id: "Learning- Observed through job training or shadowing",
        value: "Learning- Observed through job training or shadowing",
        image: CompetencyLevel1,
      },
      {
        label: "With help - Completes with assistance",
        id: "With help - Completes with assistance",
        value: "With help - Completes with assistance",
        image: CompetencyLevel2,
      },
      {
        label:
          "On their own - Completes independently, with review and guidance",
        id: "On their own - Completes independently, with review and guidance",
        value:
          "On their own - Completes independently, with review and guidance",
        image: CompetencyLevel3,
      },
      {
        label:
          "Proficient - Completes without assistance, with quality and consistency",
        id: "Proficient - Completes without assistance, with quality and consistency",
        value:
          "Proficient - Completes without assistance, with quality and consistency",
        image: RightCircleIcon,
      },
      {
        label:
          "Unable to Assess - Did not get opportunity to validate on the job due to available work",
        id: "Unable to Assess - Did not get opportunity to validate on the job due to available work",
        value:
          "Unable to Assess - Did not get opportunity to validate on the job due to available work",
        image: RightCircleGrayIcon,
      },
    ];
  } else if((taskData === "LearnerProficientStatus" || taskData === "FinalApprovalStatus") && isRoleSpecificActivity &&  data.MentorProficientStatus.ChangeStatusText === "Unable to Assess - Did not get opportunity to validate on the job due to available work"){
    STATUS_OPTIONS = [
      {
        label: "Not Yet Rated",
        id: "Not Yet Rated",
        value: "Not Yet Rated",
        image: CircleIcon,
      },
      {
        label:
          "Proficient - Completes without assistance, with quality and consistency",
        id: "Proficient - Completes without assistance, with quality and consistency",
        value:
          "Proficient - Completes without assistance, with quality and consistency",
        image: RightCircleIcon,
      },
      {
        label:
          "Unable to Assess - Did not get opportunity to validate on the job due to available work",
        id: "Unable to Assess - Did not get opportunity to validate on the job due to available work",
        value:
          "Unable to Assess - Did not get opportunity to validate on the job due to available work",
        image: RightCircleGrayIcon,
      },
    ];
  }
  else {
    STATUS_OPTIONS = [
      {
        label: "Not Yet Rated",
        id: "Not Yet Rated",
        value: "Not Yet Rated",
        image: CircleIcon,
      },
      {
        label:
          "Proficient - Completes without assistance, with quality and consistency",
        id: "Proficient - Completes without assistance, with quality and consistency",
        value:
          "Proficient - Completes without assistance, with quality and consistency",
        image: RightCircleIcon,
      }
    ];
  }
  useEffect(() => {
    if (
      data[taskData]?.ChangeStatusText !== "" &&
      STATUS_OPTIONS.filter((a) => a.value === data[taskData]?.ChangeStatusText)
    ) {
      setStatus(data[taskData]?.ChangeStatusText);
    } else {
      setStatus(STATUS_OPTIONS[0]?.value);
    }
  }, []);
  const setTitle = (itemName) => {
    let title;
    if (itemName === "RTI") {
      title = "Related Technical Instruction";
    } else if (itemName === "JS" || itemName === "JSStatus") {
      title = "Job Simulation";
    } else if (
      itemName === "OJS" ||
      itemName === "OJT" ||
      itemName === "OJTStatus"
    ) {
      title = "On the Job Simulation";
    } else if (itemName === "LearnerProficientStatus") {
      title = "Learner Sign-Off";
    } else if (itemName === "MentorProficientStatus") {
      title = "Technical Sign-Off";
    } else if (itemName === "FinalApprovalStatus") {
      title = "Final Sign-Off";
    }
    return title;
  };
  const setCompetencyLevelHeading = (levelValue) => {
    let title = "";
    if (levelValue !== "") {
      const splitedLevelValue = levelValue.split("-");
      title = splitedLevelValue[0];
    }
    return title;
  };
  const setCompetencyLevelSubheading = (levelValue) => {
    let title = "";
    if (levelValue !== "") {
      const splitedLevelValue = levelValue.split("-");
      title = splitedLevelValue[1];
    }
    return title;
  };
  const filteredactions = rolespermission?.filter(
    (obj) => obj.Action_name === setTitle(taskData)
  );
  const handleStatusUpdate = (taskData, OJTStatus, selected) => {
    if (
      checkOptionEnable(taskData, OJTStatus) &&
      showCommentAndStatusElementBasedOnTaskStatus(taskData, data) &&
      filteredactions &&
      filteredactions[0]?.Edit === "Y"
    ) {
      if (STATUS_OPTIONS.filter((a) => a.value === selected)) {
        if (status !== selected) {
          setStatus(selected);
          onSave(selected);
        }
      }
    }
  };
  const checkOptionEnable = (taskName, OJTStatus = true) => {
    if (taskName === "RTI" || taskName === "JS") {
      return false;
    } else if (
      filteredactions &&
      filteredactions[0]?.Edit === "N" &&
      taskName === "LearnerProficientStatus"
    ) {
      return false;
    } else if (
      filteredactions &&
      filteredactions[0]?.Edit === "N" &&
      taskName === "MentorProficientStatus"
    ) {
      return false;
    } else if (
      filteredactions &&
      filteredactions[0]?.Edit === "N" &&
      taskName === "FinalApprovalStatus"
    ) {
      return false;
    } else {
      return true;
    }
  };
  // const checkOptionEnable = (taskName, OJTStatus = true) => {
  //   if (taskName === "RTI" || taskName === "JS") {
  //     return false;
  //   }
  //   // else if (!OJTStatus && taskName === "LearnerProficientStatus") {
  //   //   return false;
  //   // } else if (!OJTStatus && taskName === "FinalApprovalStatus") {
  //   //   return false;
  //   // }
  //   else {
  //     return true;
  //   }
  // };
  // }
  // else if (!OJTStatus && taskName === "LearnerProficientStatus") {
  //   return false;
  // } else if (!OJTStatus && taskName === "FinalApprovalStatus") {
  //   return false;
  // }
  // else {
  //   return true;
  // }
  //};
  const showCommentAndStatusElementBasedOnTaskStatus = (
    taskName,
    itemObject,
    showStatusOption = false
  ) => {
    let preDefinedSignedTask = ["RTI", "RTIStatus", "JS", "JSStatus"];
    let definedSignedTask = [
      // "OJT",
      // "OJTStatus",
      "MentorProficientStatus",
      "LearnerProficientStatus",
      "FinalApprovalStatus",
    ];
    let isOJTEnable = itemObject?.OJT === true ? true : false;
    let availableListedTask = Object.keys(itemObject);
    let show = false;
    let filteredAvailableListedTask = definedSignedTask.filter(function (n) {
      return availableListedTask.indexOf(n) !== -1;
    });
    definedSignedTask = definedSignedTask.filter(function (n) {
      return availableListedTask.indexOf(n) !== -1;
    });
    if (preDefinedSignedTask.includes(taskName)) {
      for (const element of preDefinedSignedTask) {
        if (taskName === element) {
          if (showStatusOption) {
            return true;
          }
          return show;
        }
      }
    }
    preDefinedSignedTask = preDefinedSignedTask.filter(function (n) {
      return availableListedTask.indexOf(n) !== -1;
    });
    const calculateDefinedSignedTask = () => {
      if (definedSignedTask.includes(taskName)) {
        for (let i = 0; i < definedSignedTask.length; i++) {
          let definedTaskName = definedSignedTask[i];
          let previousDefinedTaskName = definedSignedTask[i - 1];

          let nextDefinedTaskName = definedSignedTask[i + 1];
          if (
            (taskName === "MentorProficientStatus" ||
              taskName === "LearnerProficientStatus") &&
            (isOJTEnable || isRoleSpecificActivity) &&
            itemObject["FinalApprovalStatus"]?.CompletionStatus === false
          ) {
            return true;
          } else if (
            itemObject[previousDefinedTaskName]?.CompletionStatus === false
          ) {
            return false;
          } else {
            if (taskName === definedTaskName) {
              if (
                nextDefinedTaskName === "MentorProficientStatus" &&
                itemObject["MentorProficientStatus"]?.ChangeStatusText !==
                  "Not Yet Rated" &&
                itemObject["MentorProficientStatus"]?.ChangeStatusText !== "" &&
                itemObject["MentorProficientStatus"]?.ChangeStatusText !==
                  undefined
              ) {
                return false;
              } else if (
                itemObject[nextDefinedTaskName]?.CompletionStatus === false
                //&& (isOJTEnable || isRoleSpecificActivity)
              ) {
                return true;
              } else if (
                itemObject[nextDefinedTaskName]?.CompletionStatus === undefined
                //&&(isOJTEnable || isRoleSpecificActivity)
              ) {
                return true;
              }
              for (const ele of filteredAvailableListedTask) {
                if (
                  ele === taskName &&
                  itemObject[ele]?.CompletionStatus === false
                  //&&(isOJTEnable || isRoleSpecificActivity)
                ) {
                  return true;
                }
                // if (
                //   ("FinalApprovalStatus" === taskName ||
                //     "LearnerProficientStatus" === taskName) &&
                //   !isOJTEnable &&
                //   showStatusOption
                // ) {
                //   return true;
                // }
              }
              return false;
            }
          }
        }
      }
    };
    if (preDefinedSignedTask.length >= 1) {
      let show = 1;

      for (const element of preDefinedSignedTask) {
        if (itemObject[element]?.CompletionStatus === true) {
          show = show * 1;
        } else {
          show = show * 0;
        }
      }
      if (show === 0) {
        return false;
      } else {
        return calculateDefinedSignedTask();
      }
    } else {
      return calculateDefinedSignedTask();
    }
  };
  const setJobStatus = (status) => {
    let booleanStatus;
    if (
      status ===
        "Proficient - Completes without assistance, with quality and consistency" ||
      status ===
        "Unable to Assess - Did not get opportunity to validate on the job due to available work"
    ) {
      booleanStatus = true;
    } else if (
      status === "Not Yet Rated" ||
      status === "Learning- Observed through job training or shadowing" ||
      status === "With help - Completes with assistance" ||
      status ===
        "On their own - Completes independently, with review and guidance"
    ) {
      booleanStatus = false;
    }
    return booleanStatus;
  };
  const onSave = (selectedStatus) => {
    if (selectedStatus !== "") {
      const payload = {
        ID: isRoleSpecificActivity ? data?.TaskID : data?.CriteriaID,
        IsTask: isRoleSpecificActivity,
        EntityName: taskData.includes("Status")
          ? taskData
          : taskData + "Status",
        Action: "Sign",
        Metadata: {
          SignerEMailID: userEmail,
          SignerFullName: userName,
          CompletionStatus: setJobStatus(selectedStatus),
          ChangeStatusText: selectedStatus,
          Comment: "",
        },
      };
      setLoader(true);
      checkListManagerFunction(
        payload,
        userEmail,
        state?.learnerEmail,
        localStorage.getItem("role")
      )
        .then(function (response) {
          if (response.status === 200) {
            changeDolData(state?.learnerEmail, selectedIndex);
            setLoader(false);
          }
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
        });
    }
  };
  return (
    <Block className={css(BLOCK_STYLE(theme))} data-testid="tasks-block">
      <Block marginTop="10px">
        {STATUS_OPTIONS.map((option, index) => (
          <Block
            className={
              option?.value === status
                ? css(SELECTED_STATUS_OPTIONS_BLOCK_STYLE(theme))
                : css(STATUS_OPTIONS_BLOCK_STYLE(theme))
            }
            style={{
              cursor:
                checkOptionEnable(taskData, data?.OJT) &&
                showCommentAndStatusElementBasedOnTaskStatus(taskData, data)
                  ? "pointer"
                  : "not-allowed",
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleStatusUpdate(taskData, data?.OJT, option?.value);
            }}
            key={index}
          >
            <Block
              as="img"
              src={option?.image}
              alt="icon"
              style={{ height: "24px", width: "24px" }}
              className={css(CompetencyLevel_Icon)}
            />
            <Block>
              <Block className={css(HEADING_LABEL_STYLE(theme))}>
                {setCompetencyLevelHeading(option?.label)}
              </Block>
              <Block className={css(SUB_HEADING_LABEL_STYLE(theme))}>
                {setCompetencyLevelSubheading(option?.label)}
              </Block>
            </Block>
          </Block>
        ))}
      </Block>
      {loader ? (
        <Loader
          type="bubble-loop"
          bgColor={"#332ff0"}
          color={"#332ff0"}
          size={100}
        />
      ) : null}
    </Block>
  );
}
