import React from "react";
import Loader from "react-js-loader";
import emailUpdate from "../../services/requests/updateEmail";
import "../Login/style.css";

export default function EMailVerification(props) {
  const [loader, setloader] = React.useState(false);
  const [message, setmessage] = React.useState("");

  const redirectAfterSuccessMessage = () => {
    if (
      localStorage.getItem("username") === "" ||
      localStorage.getItem("username") === null ||
      localStorage.getItem("username") === undefined
    ) {
      props.history.push("/login");
    } else {
      props.history.push("/personal-info");
    }
  };
  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const oldEmail = queryParams.get("oldemail");
    const newEmail = queryParams.get("newemail");
    const emailPayload = {
      oldEmail: oldEmail,
      newEmail: newEmail,
    };
    if (oldEmail !== null && newEmail !== null) {
      setloader(true);
      emailUpdate("confirm_change", emailPayload)
        .then((response) => {
          setloader(false);
          if (response.status === 200) {
            setmessage(
              <span className="successClass">
                You Have Successfully Verified Your Email, Will Redirect in 2
                Seconds
              </span>
            );
            setTimeout(() => {
              setloader(false);
              redirectAfterSuccessMessage();
            }, 2000);
          } else {
            setmessage(
              <span className="errorClass">
                Something Went Wrong Please Try Again Later,Will Redirect in 2
                Seconds
              </span>
            );
            setTimeout(() => {
              setloader(false);
              redirectAfterSuccessMessage();
            }, 2000);
          }
        })
        .catch((err) => {
          setloader(false);
        });
    }
  }, []);
  return (
    <div className="overview_loginSection">
      {loader ? (
          <Loader
            type="bubble-loop"
            bgColor={"#332ff0"}
            color={"#332ff0"}
            size={100}
          />
      ) : null}
      <div className="overview_loginSection_block">
        <div>
          <div className="brandLogo"></div>
        </div>
        <div className="overview_EmailVerification">
          <div data-testid="message">{message}</div>
        </div>
      </div>
    </div>
  );
}
