import React from "react";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import SnackBar from "components/SnackBar";
// import SecurityInfoDetails from "./security_info";
import {
  Reset_Password,
  RAPID_ASCENT,
  ACCOUNT_ACCESS,
  YOUR_PASSWORD,
  RESEND,
} from "resources/constants/strings";
import {
  MAILBOX_MSG,
  FOR_EMAIL_RESET_PASSWORD,
  DID_NOT_RECEIVED_MSG,
  YOU_CAN_RESEND_MSG,
  TIME_MSG,
  EMAIL_SUCCESS_MSG,
  PASSWORD_SUCCESS_MSG,
} from "resources/constants/messages";
import Skeleton from "components/Skeleton";
import {
  CARD_STYLE,
  CARD_HEADING_STYLE,
  CARD_SUB_HEADING_STYLE,
  SEPARATOR,
  RESET_PASSWORD_BTN,
  MARGIN_TOP_STYLE,
} from "../../style.js";

export default function SecurityInfo(props) {
  const [css, theme] = useStyletron();
  const [visible, setVisible] = React.useState(true);
  const [resendCount, setResendCount] = React.useState(0);
  const [loader, setLoader] = React.useState(false);

  const [state, setState] = React.useState({
    success: false,
  });
  const onToggle = (flag) => setState({ ...state, [flag]: !state[flag] });
  const { success } = state;

  const ResetPwd2 = () => {
    setVisible(!visible);
    countResendClick();
  };

  function ssoForgotpassword() {
    setResendCount(resendCount + 1);
    onToggle("success");
    let url = process.env.REACT_APP_SSO_FORGOT_PASSWORD;
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
    setTimeout(() => {
      setState({ success: false });
    }, 2000);
    setLoader(false);
  }

  const callbackFunction = (childData) => {};
  const countResendClick = () => {
    setLoader(true);
    ssoForgotpassword();
  };
  return (
    <Block className={css(CARD_STYLE(theme))}>
      <Block
        as="h3"
        className={css(CARD_HEADING_STYLE)}
        data-testid="main_heading"
      >
        {RAPID_ASCENT}
      </Block>
      <Block>
        {/* <Block
          className={css(CARD_SUB_HEADING_STYLE)}
          data-testid="sub_heading"
        >
          {ACCOUNT_ACCESS}
        </Block>
        <SecurityInfoDetails
          updateData={props.updateData}
          setUpdateData={props.setUpdateData}
          parentCallback={callbackFunction}
          userDetails={props.profileData}
          loader={props.loader}
        />
        <Block className={css(SEPARATOR)} /> */}
        <Block
          className={css(CARD_SUB_HEADING_STYLE)}
          data-testid="password_heading"
        >
          {YOUR_PASSWORD}
        </Block>
        <Block>
          {visible ? (
            <button
              className={css(RESET_PASSWORD_BTN)}
              onClick={ResetPwd2}
              data-testid="reset"
            >
              {Reset_Password}
            </button>
          ) : (
            <>
              {!loader ? (
                <Block>
                  <Block as="p" className={css(MARGIN_TOP_STYLE)} data-testid="mail_message">
                    {MAILBOX_MSG + " "}
                    <Block as="span" className={css(CARD_SUB_HEADING_STYLE)} data-testid="user-email-text">
                      {props.profileData?.email}
                    </Block>{" "}
                    <Block as="span" data-testid="reset_password_text">{FOR_EMAIL_RESET_PASSWORD} </Block>
                  </Block>
                  <Block as="p" className={css(MARGIN_TOP_STYLE)} data-testid="did_not_recieve_message">
                    {DID_NOT_RECEIVED_MSG + " "}
                    {resendCount <= 3 ? (
                      <Block
                        as="span"
                        color="#332ff1"
                        onClick={countResendClick}
                        data-testid="resend"
                      >
                        {RESEND + " "}({resendCount}/3)
                      </Block>
                    ) : (
                      <Block as="span" data-testid="you_can_resend_message">
                        {YOU_CAN_RESEND_MSG + " "}
                        <Block
                          as="span"
                          className={css(CARD_SUB_HEADING_STYLE)}
                          data-testid="time_message"
                        >
                          {TIME_MSG}
                        </Block>
                      </Block>
                    )}
                  </Block>
                </Block>
              ) : (
                <Block marginTop={"15px"}>
                  <Skeleton oval={false} height="auto" width="auto" rows={2} />
                </Block>
              )}
            </>
          )}
        </Block>
        {success ? (
          <SnackBar
            placement="bottomRight"
            backgroundColor="green"
            textColor="#FFFFFF"
            textAlignment="center"
            message={PASSWORD_SUCCESS_MSG}
          />
        ) : null}
      </Block>
    </Block>
  );
}
