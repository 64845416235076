import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import DefaultAvatar from "assets/images/user-profile.svg";
import { useMemo } from "react";
import { Skill_Score,Points_Gained } from "resources/constants/strings";
import Chart from "components/newChart";

const COMMON_TEXT_STYLE = {
  fontSize: "14px",
  lineHeight: "19px",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const AVATAR_STYLE = { width: "40px", height: "40px", borderRadius: "100%" };
const NAME_STYLE = (theme) => ({
  ...COMMON_TEXT_STYLE,
  fontFamily: "Manrope-Bold",
  color: theme.RAColors?.blue700,
  marginBottom: "8px",
  cursor: "pointer",
});
const EMAIL_STYLE = { ...COMMON_TEXT_STYLE, fontFamily: "Manrope-Semibold" };
const STATUS_BLOCK = {
  top: "0",
  left: "16px",
  width: "calc(100% - 32px)",
  height: "calc(100% - 32px)",
};
const STATUS_STYLE = {
  ...COMMON_TEXT_STYLE,
  fontFamily: "Manrope-Medium",
  padding: "6px 12px",
  borderRadius: "100px",
  textAlign:"center"
};
const SCORE_STYLE = { ...COMMON_TEXT_STYLE };

export const TableHeader = ({ name, sorted, asc, onSort, size = "lg" }) => {
  const [css, theme] = useStyletron();

  return (
    <Block
      className={css({ cursor: "pointer" })}
      onClick={() => onSort(name, asc)}
      data-testid="table-header"
    >
      <Block
        as="span"
        className={css({
          fontSize: size === "lg" ? "16px" : "14px",
          lineHeight: size === "lg" ? "22px" : "19px",
          fontFamily: 'Manrope-Semibold',
        })}
        data-testid="table-header-text"
      >
        {name}
      </Block>
      <Block
        as="span"
        marginLeft="8px"
        className={css({
          color: sorted === name ? "#212121" : theme.RAColors?.gray200,
        })}
        data-html2canvas-ignore
        data-testid="table-header-icon"
      >
        <svg width="6" height="12" viewBox="0 0 6 12">
          <path
            d="M3.38381 0.67962C3.33534 0.625845 3.2761 0.582852 3.20996 0.553424C3.14381 0.523995 3.07221 0.508789 2.99981 0.508789C2.92741 0.508789 2.85582 0.523995 2.78967 0.553424C2.72352 0.582852 2.66429 0.625845 2.61581 0.67962L0.115813 3.67962C0.0550083 3.75262 0.0162609 3.84143 0.00410785 3.93566C-0.00804516 4.02989 0.0068993 4.12563 0.0471913 4.21167C0.0874832 4.29771 0.151455 4.37049 0.231615 4.42148C0.311775 4.47248 0.404806 4.49958 0.499813 4.49962H1.99981V10.9996C1.99981 11.1322 2.05249 11.2594 2.14626 11.3532C2.24003 11.4469 2.3672 11.4996 2.49981 11.4996H3.49981C3.63242 11.4996 3.7596 11.4469 3.85337 11.3532C3.94713 11.2594 3.99981 11.1322 3.99981 10.9996V4.49962H5.49981C5.59482 4.49958 5.68785 4.47248 5.76801 4.42148C5.84817 4.37049 5.91214 4.29771 5.95243 4.21167C5.99273 4.12563 6.00767 4.02989 5.99552 3.93566C5.98337 3.84143 5.94462 3.75262 5.88381 3.67962L3.38381 0.67962Z"
            fill="currentColor"
          />
        </svg>
        <svg width="6" height="12" viewBox="0 0 6 12">
          <path
            d="M3.49981 0.5H2.49981C2.3672 0.5 2.24003 0.552678 2.14626 0.646447C2.05249 0.740215 1.99981 0.867392 1.99981 1V7.5H0.499813C0.404806 7.50004 0.311775 7.52714 0.231615 7.57814C0.151455 7.62913 0.0874835 7.70191 0.0471915 7.78795C0.00689952 7.87399 -0.0080452 7.96973 0.00410782 8.06396C0.0162608 8.15819 0.0550086 8.247 0.115813 8.32L2.61581 11.32C2.66274 11.3763 2.72146 11.4215 2.78781 11.4526C2.85417 11.4837 2.92654 11.4998 2.99981 11.4998C3.07308 11.4998 3.14546 11.4837 3.21181 11.4526C3.27817 11.4215 3.33689 11.3763 3.38381 11.32L5.88381 8.32C5.94462 8.247 5.98336 8.15819 5.99552 8.06396C6.00767 7.96973 5.99273 7.87399 5.95243 7.78795C5.91214 7.70191 5.84817 7.62913 5.76801 7.57814C5.68785 7.52714 5.59482 7.50004 5.49981 7.5H3.99981V1C3.99981 0.867392 3.94713 0.740215 3.85337 0.646447C3.7596 0.552678 3.63242 0.5 3.49981 0.5Z"
            fill="currentColor"
          />
        </svg>
      </Block>
    </Block>
  );
};

export const TableName = ({ row, onClickName }) => {
  const [css, theme] = useStyletron();

  return (
    <Block display="flex" gridGap="16px">
      <Block
        as="img"
        src={row?.LearnerProfileImageUrl ? row?.LearnerProfileImageUrl : DefaultAvatar}
        alt="User Avatar"
        className={css(AVATAR_STYLE)}
        data-testid="table-name-image"
      />
      <Block overflow="hidden">
        <Block
          className={css(NAME_STYLE(theme))}
          onClick={onClickName}
          data-testid="table-name-name"
        >
          {row?.name}
        </Block>
        <Block className={css(EMAIL_STYLE)} data-testid="table-name-email">
          {row?.email_id}
        </Block>
      </Block>
    </Block>
  );
};

export const TableStatus = ({ row, isAbsolute = true }) => {
  const [css, theme] = useStyletron();

  const status = useMemo(
    () =>
      row?.learner_progress_bar?.progress_learning_path?.progress_status || "",
    [row]
  );
  const statusString = useMemo(
    () => (!status ? "-" : status.replace("_", " ")),
    [status]
  );
  const statusColor = useMemo(() => {
    const colors = {
      At_Risk: theme.RAColors?.orange100,
      Exceeds: theme.RAColors?.blue100,
      Action_Needed: '#f7ecb7',
      On_Track: theme.RAColors?.emerald100,
    };

    return colors[status] || "transparent";
  }, [status, theme]);

  return (
    <Block
      display="flex"
      justifyContent="end"
      alignItems="center"
      position={isAbsolute ? "absolute" : "initial"}
      className={css(isAbsolute ? STATUS_BLOCK : {})}
      data-testid="table-status"
    >
      <Block
        data-testid="table-status-content"
        className={css({ ...STATUS_STYLE, backgroundColor: statusColor })}
      >
        {statusString}
      </Block>
    </Block>
  );
};

export const TableScore = ({ row, onOpenScore }) => {
  const [css, theme] = useStyletron();

  const handleClick = (e) => {
    e.stopPropagation();
    onOpenScore();
  };

  return (
    <Block display="flex" alignItems="center">
      <StatefulPopover
        content={() => (
          <div
            data-testid="table-chart-popover"
            className={css({ width: "500px", backgroundColor: "white" })}
          >
            <Chart
              data={row?.learner_radar_skills?.sub_category_map}
              visibleLabel
            />
          </div>
        )}
        triggerType={TRIGGER_TYPE.hover}
        returnFocus
        autoFocus
      >
        <div data-testid="table-chart">
          <Chart
            data={row?.learner_radar_skills?.sub_category_map}
            style={{ height: "64px", width: "64px" }}
          />
        </div>
      </StatefulPopover>
      <Block
        as="span"
        marginLeft="16px"
        marginRight="8px"
        className={css(SCORE_STYLE)}
      >
        {Skill_Score}
      </Block>
      <Block
        as="span"
        color={theme.RAColors?.blue700}
        className={css({ ...SCORE_STYLE, cursor: "pointer" })}
        onClick={handleClick}
        data-testid="table-score-number"
      >
        {row?.learner_radar_skills?.skill_completion_pct}
      </Block>
    </Block>
  );
};

export const TableFlexScore = ({ row, onOpenScore }) => {
  const [css, theme] = useStyletron();
  const handleClick = (e) => {
    e.stopPropagation();
    onOpenScore();
  };
  return (
    <Block display="flex" alignItems="center">
      <StatefulPopover
        content={() => (
          <div
            data-testid="table-flex-chart-popover"
            className={css({ width: "500px", backgroundColor: "white" })}
          >
            <Chart
              data={row?.learner_radar_skills_flex?.sub_category_map}
              visibleLabel
              showChartValue= {true}
              skillMax={row?.learner_radar_skills_flex?.skill_score}
            />
          </div>
        )}
        triggerType={TRIGGER_TYPE.hover}
        returnFocus
        autoFocus
      >
        <div data-testid="table-chart-flex" onClick={handleClick} className={css({ cursor: "pointer" })}>
          <Chart
            data={row?.learner_radar_skills_flex?.sub_category_map}
            style={{ height: "64px", width: "64px" }}
            showChartValue= {true}
            skillMax={row?.learner_radar_skills_flex?.skill_score}
          />
        </div>
      </StatefulPopover>
      {/* <Block
        as="span"
        marginLeft="16px"
        marginRight="8px"
        className={css(SCORE_STYLE)}
      >
        {Points_Gained}
      </Block>
      <Block
        as="span"
        color={theme.RAColors?.blue700}
        className={css({ ...SCORE_STYLE, cursor: "pointer" })}
        data-testid="table-flex-score-number"
        onClick={handleClick}
      >
        {row?.learner_radar_skills_flex?.skill_score}
      </Block> */}
    </Block>
  );
};

export const TableTrackProgress = ({ row }) => {
  const [css, theme] = useStyletron();

  const progress = useMemo(() => {
    const { learner_progress_bar } = row || {};
    const { active_course_name = "", progress_learning_path = {} } =
      learner_progress_bar || {};

    const {
      progress_actual = "",
      progress_behind = "",
      progress_exceeded = "",
    } = progress_learning_path;

    const isBehind = Boolean(progress_behind);

    return {
      active_course_name,
      progress_actual,
      progress_behind,
      progress_exceeded,
      isBehind,
    };
  }, [row]);

  const courseStatus = useMemo(() => {
    const {
      active_course_name,
      progress_actual,
      progress_behind,
      progress_exceeded,
      isBehind,
    } = progress;

    return (
      <>
        {Boolean(progress_actual) && (
          <Block as="span">{`${active_course_name} • `}</Block>
        )}
        <Block as="span">{`${progress_actual}% `}</Block>
        <Block
          as="span"
          className={css({
            color: isBehind
              ? theme.RAColors?.red700
              : theme.RAColors?.emerald400,
          })}
        >
          {`(${isBehind ? progress_behind : progress_exceeded}% ${
            isBehind ? "from target" : "exceeds"
          })`}
        </Block>
      </>
    );
  }, [progress]);

  const COMMON_PROGRESS_STYLE = { height: "6px", borderRadius: "100px" };

  return (
    <Block>
      <Block>{row?.learning_path_name}</Block>
      <Block
        display="flex"
        position="relative"
        marginTop="12px"
        marginBottom="8px"
        className={css({
          ...COMMON_PROGRESS_STYLE,
          backgroundColor: theme.RAColors?.gray200,
        })}
      >
        <Block
          position="absolute"
          className={css({
            ...COMMON_PROGRESS_STYLE,
            backgroundColor: theme.RAColors?.blue600,
            width: `${
              progress.isBehind
                ? progress.progress_actual
                : progress.progress_actual - progress.progress_exceeded
            }%`,
          })}
        />
        <Block
          className={css({
            ...COMMON_PROGRESS_STYLE,
            backgroundColor: progress.isBehind
              ? '#edc12f'
              : theme.RAColors?.emerald400,
            width: `${
              progress.isBehind
                ? progress.progress_actual + progress.progress_behind
                : progress.progress_actual
            }%`,
          })}
        />
      </Block>
      <Block>{courseStatus}</Block>
    </Block>
  );
};
