import React, { useMemo, useState } from "react";
import { Header } from "components/Header";
import DrawerRouterContainer from "components/newDrawer";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import Title from "./Components/title";
import { useSetState, useTrackedState } from "store/store";
import { useEffect } from "react";
import getRolesPageVisibility from "../../components/getPageVisibility";
import { Redirect } from "react-router-dom";
import { Select } from "baseui/select";
import { Input } from "baseui/input";
import { FormControl } from "baseui/form-control";
import { Alert } from "baseui/icon";
import { Button } from "baseui/button";
import { FileUploader } from "baseui/file-uploader";
import userData from "services/requests/userData";
import thmScoreboard from "services/requests/thmScoreboard";
import gradeAdds from "services/requests/gradeAddendums"
import {Notification, KIND} from 'baseui/notification';
import DeleteAlt from "baseui/icon/delete-alt";
import getWordpressData from "services/requests/lessonData"
import {Radio, RadioGroup} from 'baseui/radio';
//$FlowFixMe
import { validate as validateEmail } from "email-validator";
import {
  COMMON_RADIUS,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_COLOR,
  COMMON_BORDER_STYLE,
} from "utils/style";

const MAIN_STYLE = (theme) => ({
  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "40px",
  },
});

const BLOCK_STYLE = (theme) => ({
  backgroundColor: "white",
  borderRadius: "8px",
  padding: "24px",
  border: `1px solid ${theme.RAColors?.gray200}`,
  // width: '100%',
  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "24px",
  },
});
const BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  //borderColor: $theme.RAColors?.purple,
  paddingTop: "12px",
  paddingBottom: "12px",
  paddingRight: "24px",
  paddingLeft: "24px",
  ...COMMON_BORDER_COLOR($theme.RAColors?.blue700),
  backgroundColor: $theme.RAColors?.blue700,
  ":hover": { backgroundColor: $theme.RAColors?.blue800 },
  color: "white",

  [$theme.mediaQuery.small]: {
    width: "100%",
  },
  [$theme.mediaQuery.medium]: {
    width: "initial",
  },
});

const SUMMARY_DROPDOWN = (theme) => ({
  display: "flex",
  width: "100%",
});

const SAVE_BTN_STYLE = ($theme) => ({
  borderRadius: "10px",
  paddingTop: "14px",
  paddingBottom: "14px",
  paddingLeft: "16px",
  paddingRight: "16px",
  backgroundColor: "#141414",
  borderColor: "#332FF1",
  color: "#FFFFFF",
  ":hover": { backgroundColor: "#141414" },
});

const GradeAdd = ({ match }) => {
  const [css, theme] = useStyletron();
  const [value, setValue] = React.useState("");
  const [oldGrade, setOldGrade] = React.useState("");
  const [newgrade, setNewGrade] = React.useState("");
  const [isValid, setIsValid] = React.useState(false);
  const [isVisited, setIsVisited] = React.useState(false);
  const shouldShowError = !isValid && isVisited;
  const [userSuccess, setUserSuccess] = React.useState(false);
  const [fileSuccess, setFileSuccess] = React.useState(false);
  const [wordData, setWordData] = React.useState(false);
  const [dropvalue, setDropValue] = React.useState([]);
  const [stageData, setStageData] = React.useState([])
  const [roomData, setRoomData] = React.useState([])
  const [stagevalue, setStageValue] = React.useState([])
  const [roomvalue, setRoomValue] = React.useState([])
  const [rvalue, setRValue] = React.useState('skill');

  const onChange = ({ target: { value } }) => {
    setIsValid(validateEmail(value));
    setValue(value);
  };
  const timeoutId = React.useRef();
  const [error, setError] = React.useState(false);

  const [questData, setQuestData] = React.useState([]);

  useEffect(() => {
    localStorage.setItem("currentPage", "grade-add");
    getDatafromWordpress(localStorage.getItem("email"))
  }, []);

function getDatafromWordpress(){
    getWordpressData(localStorage.getItem("email"))
    .then(function (response) {
      if (response.status === 200) {
        setWordData(response.data.data)
        const arr1 = []
        response.data.data.map((obj) =>{
            let ele = { }
            ele.questID = obj.quest_id
            ele.quest_name = obj.quest_name
            arr1.push(ele)
        })
        let pp = arr1.filter( (ele, ind) => ind === arr1.findIndex( elem => elem.questID === ele.questID ))
        setQuestData(pp)
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

  //page access control - based on role
  var role = localStorage.getItem("viewAs") !== null ? localStorage.getItem("viewAs") : localStorage.getItem("role");
  var permittedRoles = getRolesPageVisibility("Grade Addendums");
  if (!permittedRoles.includes(role)) return <Redirect to="/student" />;

  function Negative() {
    const [css, theme] = useStyletron();
    return (
      <div
        className={css({
          display: "flex",
          alignItems: "center",
          paddingRight: theme.sizing.scale500,
          color: theme.colors.negative400,
        })}
      >
        <Alert size="18px" />
      </div>
    );
  }
  

  function submitData() {
    let payload = {};
    payload.questNumber =  dropvalue[0].questID
    payload.stageNumber = stagevalue[0].stage_id
    payload.roomName = roomvalue[0].room_name
    payload.overallNewGrade = newgrade
    payload.oldGrade = oldGrade
    payload.emailId = value
    payload.gradeSet = rvalue
    gradeAdds(payload, localStorage.getItem("email"))
      .then(function (response) {
        if (response.status === 200) {
          setUserSuccess(true)
          setError(false)
          setDropValue([])
          setStageValue([])
          setRoomValue([])
          setNewGrade("")
          setOldGrade("")
          setValue("")
        }
      })
      .catch((err) => {
        console.log(err);
        setUserSuccess(false)
        setError(true)
      });
  }

function stageCall(val ){
    setDropValue(val);
    var newArray = wordData?.find(function (el) {
        if(el.quest_id === val[0].questID)
        {
          return el.stage;
        } 
      });
      setStageData(newArray.stage)
}

function roomCall (val){
    setStageValue(val)
    var newArray = stageData?.find(function (el) {
        if(el.stage_id === val[0].stage_id)
        {
          return el;
        } 
      });
      setRoomData(newArray.room)
}
//   const role = localStorage.getItem("viewAs") !== null ? localStorage.getItem("viewAs") : localStorage.getItem("role");
//   const userType = useMemo(() => {
//     const username = localStorage.getItem("username");
//     if ([null, undefined, ""].includes(username)) return null;
//     //page access control - based on role
//     var permittedRoles =['ADMIN','TENANT OWNER'];
//     if (permittedRoles.includes(role))
//       return "manager";

//     return "member";
//   }, []);
//   if (!userType) return <Redirect to="/login" />;
//   if (userType !== 'manager') return <Redirect to="/student" />;


  return (
    <>
      <Header />
      <DrawerRouterContainer>
      <React.Fragment>
        {userSuccess && !error?
        <Notification
          overrides={{
            Body: {
              style: ({ $theme }) => ({
                position: "absolute",
                top: "10%;",
                right: "0",
                background: "#69cf9c",
                color: "#fff",
              }),
            },
            CloseIcon: {
              component: DeleteAlt,
              style: { float: "right", cursor: "pointer" },
            },
          }}
          kind={KIND.positive}
          closeable
        >
          {"User added successfully."}
        </Notification>
      : null}
      
   {error && !userSuccess? 
    <Notification
    overrides={{
      Body: {
        style: ({ $theme }) => ({
          position: "absolute",
          top: "10%;",
          right: "0",
          background: "red",
          color: "#fff",
        }),
      },
      CloseIcon: {
        component: DeleteAlt,
        style: { float: "right", cursor: "pointer" },
      },
    }}
    kind={KIND.positive}
    closeable
  >
   {"Something went wrong!"}
  </Notification>
    : null}
    </React.Fragment>
        <main className={css(MAIN_STYLE(theme))}>
          <Title />
          <Block
            data-testid="summary"
            className={css(BLOCK_STYLE(theme))}
            display="flex"
            flexWrap="wrap"
            gridGap={["16px", "16px", "40px"]}
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
                submitData();
              }}
              style={{ width: "100%" }}
            >
              <Block>
                <FormControl>
                  
                  <RadioGroup
                    align="horizontal"
                    name="horizontal"
                    onChange={e => setRValue(e.target.value)}
                    value={rvalue}
                  ><Block style={{paddingRight:'5px'}}>{"Grade For :  "}</Block>
                    <Radio value="skill">Skill Checks</Radio>
                    <Radio value="assignment">Assignments</Radio>
                  </RadioGroup>
                </FormControl>
              </Block>
              <Block style={{ display: "inline-flex", gap: 40, width: "100%" }}>
                <Block style={{ width: "30%" }}>
                  <FormControl
                    label={() => "Quest Number"}
                    overrides={{
                      ControlContainer: {
                        style: ({ $theme }) => ({
                          width: "100%",
                        }),
                      },
                    }}
                  >
                    <Select
      options={questData}
      value={dropvalue}
      labelKey="questID"
      valueKey="questID"
      placeholder="Select ID"
      onChange={params => stageCall(params.value)}
      required
    />
                  </FormControl>
                </Block>
                <Block style={{ width: "30%" }}>
                <FormControl
                    label={() => "Stage Number"}
                    overrides={{
                      ControlContainer: {
                        style: ({ $theme }) => ({
                          width: "100%",
                        }),
                      },
                    }}
                  >
                    <Select
      options={stageData}
      value={stagevalue}
      labelKey="stage_id"
      valueKey="stage_id"
      placeholder="Select ID"
      onChange={params => roomCall(params.value)}
      required
    />
                  </FormControl>
                </Block>
                <Block style={{ width: "30%" }}>
                
                <FormControl
                    label={() => "Room Name"}
                    overrides={{
                      ControlContainer: {
                        style: ({ $theme }) => ({
                          width: "100%",
                        }),
                      },
                    }}
                  >
                    <Select
      options={roomData}
      value={roomvalue}
      labelKey="room_name"
      valueKey="room_id"
      placeholder="Select Room Name"
      onChange={params => setRoomValue(params.value)}
      required
    />
                  </FormControl>
                </Block>
              </Block>
              <Block style={{ display: "inline-flex", gap: 40, width: "100%" }}>
                <Block style={{ width: "30%" }}>
                  <FormControl
                    label={() => "Old Grade(%)"}
                    overrides={{
                      ControlContainer: {
                        style: ({ $theme }) => ({
                          width: "100%",
                        }),
                      },
                    }}
                  >
                    <Input
                      value={oldGrade}
                      onChange={(e) => setOldGrade(e.target.value)}
                      placeholder="Old Grade(%)"
                      clearOnEscape
                      required 
                    />
                  </FormControl>
                </Block>
                <Block style={{ width: "30%" }}>
                  <FormControl
                    label={() => "Overall New Grade(%)"}
                    overrides={{
                      ControlContainer: {
                        style: ({ $theme }) => ({
                          width: "100%",
                        }),
                      },
                    }}
                  >
                    <Input
                      value={newgrade}
                      onChange={(e) => setNewGrade(e.target.value)}
                      placeholder="Overall New Grade(%)"
                      clearOnEscape
                      required
                    />
                  </FormControl>
                </Block>
                <Block style={{ width: "30%" }}>
                  <FormControl
                    label="Email"
                    overrides={{
                      Label: {
                        style: ({ $theme }) => ({
                          fontFamily: "Roboto",
                          fontSize: "15px",
                        }),
                      },
                      ControlContainer: {
                        style: ({ $theme }) => ({
                          width: "100%",
                        }),
                      },
                    }}
                    error={
                      shouldShowError
                        ? "Please input a valid email address"
                        : null
                    }
                  >
                    <Input
                      id="email-input-id"
                      value={value}
                      onChange={onChange}
                      onBlur={() => setIsVisited(true)}
                      error={shouldShowError}
                      overrides={shouldShowError ? { After: Negative } : {}}
                      type="email"
                      placeholder="Enter Email"
                      required
                    />
                  </FormControl>
                </Block>
              </Block>
              
              <Block>
                <Button
                  overrides={{
                    BaseButton: { style: BUTTON_OVERRIDE_STYLE },
                  }}
                >
                  Submit
                </Button>
              </Block>
            </form>
          </Block>
        </main>

        
      </DrawerRouterContainer>
    </>
  );
};

export default GradeAdd;
