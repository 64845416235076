import { Header } from "components/Header";
import DrawerRouterContainer from "components/newDrawer";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Redirect } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import { StatefulTabs, Tab } from "baseui/tabs";
import { Tag, KIND, VARIANT } from "baseui/tag";
import getRolesPageVisibility from "../../components/getPageVisibility"

import {
  fetchAPILinkforceErrorLogAxios,
  fetchLinkforceControllerErrorLogAxios,
} from "services/requests/error_log_api";
import {
  COMMON_RADIUS,
  COMMON_BORDER_COLOR,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_STYLE,
} from "utils/style";

const TABLE_OVERRIDE_STYLE = {
  Root: ({ $theme }) => ({
    ...COMMON_RADIUS("8px"),
    ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
    ...COMMON_BORDER_WIDTH("1px"),
    ...COMMON_BORDER_STYLE("solid"),
  }),
  MessageCell: { minWidth: "200px" },
  TimeCell: { width: "200px", maxWidth: "200px", minWidth: "200px" },
  IdCell: { width: "100px", maxWidth: "100px", minWidth: "100px" },
};

const MAIN_STYLE = (theme) => ({
  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "40px",
  },
});

const HEADING_STYLE = (theme) => ({
  fontFamily: "Epilogue-Semibold",
  fontSize: "28px",
  lineHeight: "40px",
  color: theme.RAColors?.gray900,
});

const Admin = () => {
  const [css, theme] = useStyletron();

  const role = localStorage.getItem("viewAs") === null ? localStorage.getItem("role") : localStorage.getItem("viewAs");
  const [isFetching, setIsFetching] = useState(false);
  const [APILinkforceLogs, setAPILinkforceLogs] = useState([]);
  const [LinkforceControllerLogs, setLinkforceControllerLogs] = useState([]);

  const separatedLinkforceControllerLogs = useMemo(() => {
    return (LinkforceControllerLogs || []).map((log) => {
      let items = log.split(" ");

      if (items[1] !== "linkforce") items.splice(1, 1);

      const timeStamp = items[0];
      const state = items[3];
      const message = items.slice(4).join(" ");

      return { timeStamp, state, message };
    });
  }, [LinkforceControllerLogs]);

  useEffect(() => {
    let isMounted = true;
    localStorage.setItem("currentPage","admin")

    const fetchErrorLog = async () => {
      try {
        setIsFetching(true);
        //[APILinkforceLogResponse, LinkforceControllerLogResponse]
        const [LinkforceControllerLogResponse] =
          await Promise.all([
          //  fetchAPILinkforceErrorLogAxios(),
            fetchLinkforceControllerErrorLogAxios(),
          ]);

        if (
           LinkforceControllerLogResponse.status !== 200 ||
          !isMounted
        )
          return;

        //setAPILinkforceLogs(APILinkforceLogResponse.data.reverse());
        setLinkforceControllerLogs(
          LinkforceControllerLogResponse.data.error_list.reverse()
        );
      } catch (e) {
        console.log(e);
      } finally {
        setIsFetching(false);
      }
    };

    fetchErrorLog();

    return () => {
      isMounted = false;
    };
  }, []);

  const formatTime = (t) => {
    const date = new Date(t);
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  };

  //page access control - based on role
  const userType = useMemo(() => {
    var permittedRoles = getRolesPageVisibility("Logs");
    if (permittedRoles.includes(role)) {
      return "manager";
    }
  });
  if (userType !== 'manager') return <Redirect to="/student" />

  // if (role !== "ADMIN") return <Redirect to="/student" />;

  return (
    <>
      <Header />
      <DrawerRouterContainer>
        <main className={css(MAIN_STYLE(theme))}>
          <Block
            as="h3"
            className={css(HEADING_STYLE(theme))}
            marginBottom="16px"
            data-testid="logs-text"
          >
            Logs
          </Block>
          <Block>
            <StatefulTabs
              initialState={{ activeKey: "0" }}
              overrides={{
                Tab: { style: { marginLeft: 0 } },
                TabBar: { style: { paddingLeft: 0 } },
                TabContent: { style: { paddingLeft: 0, paddingRight: 0 } },
              }}
            >
              {/* <Tab title="APILinkforce">
                <TableBuilder
                  data={APILinkforceLogs}
                  isLoading={isFetching || false}
                  emptyMessage="No logs"
                  overrides={{ Root: { style: TABLE_OVERRIDE_STYLE.Root } }}
                >
                  <TableBuilderColumn
                    header="Id"
                    overrides={{
                      TableHeadCell: { style: TABLE_OVERRIDE_STYLE.IdCell },
                      TableBodyCell: { style: TABLE_OVERRIDE_STYLE.IdCell },
                    }}
                  >
                    {(row) => <div data-testid="row-id">{row.id}</div>}
                  </TableBuilderColumn>
                  <TableBuilderColumn
                    header="Message"
                    overrides={{
                      TableHeadCell: {
                        style: TABLE_OVERRIDE_STYLE.MessageCell,
                      },
                      TableBodyCell: {
                        style: TABLE_OVERRIDE_STYLE.MessageCell,
                      },
                    }}
                  >
                    {(row) => (
                      <div data-testid="row-message">{row.message}</div>
                    )}
                  </TableBuilderColumn>
                  <TableBuilderColumn
                    header="Time"
                    overrides={{
                      TableHeadCell: { style: TABLE_OVERRIDE_STYLE.TimeCell },
                      TableBodyCell: { style: TABLE_OVERRIDE_STYLE.TimeCell },
                    }}
                  >
                    {(row) => (
                      <div data-testid="row-timestamp">
                        {formatTime(row.timeStamp)}
                      </div>
                    )}
                  </TableBuilderColumn>
                </TableBuilder>
              </Tab> */}
              <Tab title="LinkforceController">
                <TableBuilder
                  data={separatedLinkforceControllerLogs}
                  isLoading={isFetching || false}
                  emptyMessage="No logs"
                  overrides={{ Root: { style: TABLE_OVERRIDE_STYLE.Root } }}
                >
                  <TableBuilderColumn
                    header="State"
                    overrides={{
                      TableHeadCell: { style: TABLE_OVERRIDE_STYLE.IdCell },
                      TableBodyCell: { style: TABLE_OVERRIDE_STYLE.IdCell },
                    }}
                  >
                    {(row) => (
                      <Tag
                        data-testid="row-state"
                        kind={
                          row.state === "ERROR" ? KIND.negative : row.state === "NOTICE" ? KIND.green : KIND.warning
                        }
                        variant={VARIANT.solid}
                        closeable={false}
                        overrides={{
                          Root: {
                            style: { marginLeft: "0px", marginTop: "0px" },
                          },
                        }}
                      >
                        {row.state}
                      </Tag>
                    )}
                  </TableBuilderColumn>
                  <TableBuilderColumn
                    header="Message"
                    overrides={{
                      TableHeadCell: {
                        style: TABLE_OVERRIDE_STYLE.MessageCell,
                      },
                      TableBodyCell: {
                        style: TABLE_OVERRIDE_STYLE.MessageCell,
                      },
                    }}
                  >
                    {(row) => (
                      <div data-testid="row-message-linkforce">
                        {row.message}
                      </div>
                    )}
                  </TableBuilderColumn>
                  <TableBuilderColumn
                    header="Time"
                    overrides={{
                      TableHeadCell: { style: TABLE_OVERRIDE_STYLE.TimeCell },
                      TableBodyCell: { style: TABLE_OVERRIDE_STYLE.TimeCell },
                    }}
                  >
                    {(row) => (
                      <div data-testid="row-time">
                        {formatTime(row.timeStamp)}
                      </div>
                    )}
                  </TableBuilderColumn>
                </TableBuilder>
              </Tab>
            </StatefulTabs>
          </Block>
        </main>
      </DrawerRouterContainer>
    </>
  );
};

export default Admin;
