import PhaseCyberAnalystFoundational from "assets/badges/phase_cyber_analyst_foundational.svg";
import PhaseGuidedOtj from "assets/badges/phase_guided_otj.svg";
import PhaseOtj from "assets/badges/phase_otj.svg";

import PhaseCyberAnalystFoundationalLarge from "assets/badges-large/phase_cyber_analyst_foundational_large.svg";
import PhaseGuidedOtjLarge from "assets/badges-large/phase_guided_otj_large.svg";
import PhaseOtjLarge from "assets/badges-large/phase_otj_large.svg";

import * as React from "react";
import { Block } from "baseui/block";
import { StatefulPopover, TRIGGER_TYPE, PLACEMENT } from "baseui/popover";
import { useStyletron } from "baseui";
import { COMMON_RADIUS } from "utils/style";

const PHASE_IMAGE_MAP = {
  "phase_cyber_analyst_foundational.png": {
    small: PhaseCyberAnalystFoundational,
    large: PhaseCyberAnalystFoundationalLarge,
  },
  "phase_guided_otj.png": {
    small: PhaseGuidedOtj,
    large: PhaseGuidedOtjLarge,
  },
  "phase_otj.png": {
    small: PhaseOtj,
    large: PhaseOtjLarge
  },
};

const PhaseBadge = ({
  name = "phase_cyber_analyst_foundational.png",
  isFaded = false,
  showModal,
}) => {
  const [css] = useStyletron();

  const imageSmall =
    PHASE_IMAGE_MAP[name]?.small || PhaseCyberAnalystFoundational;
  const imageLarge =
    PHASE_IMAGE_MAP[name]?.large || PhaseCyberAnalystFoundationalLarge;

  const handleClick = (e) => {
    e.stopPropagation()
    if (showModal) showModal(imageLarge);
  };

  return (
    <StatefulPopover
      content={isFaded ? null : (
        <Block
          paddingLeft="20px"
          paddingRight="20px"
          paddingTop="40px"
          paddingBottom="40px"
          className={css({
            backgroundColor: "white",
            ...COMMON_RADIUS("8px"),
            cursor: "pointer",
          })}
          onClick={handleClick}
        >
          <Block
            as="img"
            src={imageLarge}
            alt="Phase Badge Large"
            width="220px"
            height="220px"
            data-testid="phase-badge-large"
          />
        </Block>
      )}
      triggerType={TRIGGER_TYPE.hover}
      placement={PLACEMENT.rightTop}
      returnFocus
      autoFocus
    >
      <Block
        as="img"
        src={imageSmall}
        alt="Phase Badge"
        width="38px"
        data-testid="phase-badge"
      />
    </StatefulPopover>
  );
};

export default PhaseBadge;