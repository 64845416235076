import { Modal, ModalBody, SIZE, ROLE } from "baseui/modal";
import { Button } from "baseui/button";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import {
  COMMON_RADIUS,
  COMMON_BORDER_COLOR,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_STYLE,
} from "utils/style";

const MODAL_OVERRIDE_STYLE = {
  Dialog: {
    style: ({ $theme }) => ({
      ...COMMON_RADIUS("8px"),
    }),
  },
};

const MODAL_BODY_STYLE = (theme) => ({
  margin: "24px !important",
  [theme.mediaQuery.small]: {
    margin: "16px !important",
  },
  [theme.mediaQuery.medium]: {
    margin: "24px !important",
  },
});

const TITLE_STYLE = (theme) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "16px",
  flexWrap: "wrap",

  [theme.mediaQuery.small]: {
    marginBottom: "16px",
  },
  [theme.mediaQuery.medium]: {
    marginBottom: "36px",
  },
});

const HEADING_TEXT_STYLE = (theme) => ({
  fontSize: "24px",
  lineHeight: "32px",
  fontFamily: "Manrope-Bold",
  color: theme.RAColors?.black,
});

const COMMON_BUTTON_STYLE = {
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  paddingLeft: "24px",
  paddingRight: "24px",
  paddingBottom: "10px",
  paddingTop: "10px",
  fontFamily: "Manrope-Bold",
  fontSize: "16px",
  lineHeight: "22px",
  ...COMMON_RADIUS("8px"),
};

const SAVE_BTN_STYLE = ({ $theme }) => ({
  ...COMMON_BUTTON_STYLE,
  ...COMMON_BORDER_COLOR($theme.RAColors?.blue700),
  backgroundColor: $theme.RAColors?.blue700,
  ":hover": {
    backgroundColor: $theme.RAColors?.blue800,
  },
  color: "#FFFFFF",
});

const DeleteRejection = ({ close, rejectionType, type = "template" }) => {
  const [css, theme] = useStyletron();

  return (
    <Modal
      onClose={close}
      closeable
      isOpen={true}
      animate
      autoFocus={false}
      focusLock
      size={SIZE.default}
      role={ROLE.dialog}
      overrides={MODAL_OVERRIDE_STYLE}
    >
      <ModalBody className={css(MODAL_BODY_STYLE(theme))}>
        <Block
          display="block"
          justifyContent="space-between"
          alignItems="center"
          gridGap="16px"
          marginBottom={["16px", "16px", "20px"]}
          flexWrap="wrap"
        >
          <Block className={css(TITLE_STYLE(theme))}>
            <Block
              as="h5"
              margin={0}
              className={css(HEADING_TEXT_STYLE(theme))}
              data-testid="delete-rejection-title"
            >
              {rejectionType === "Deletion"
                ? "Deletion Rejected"
                : "Update Rejected"}
            </Block>
          </Block>
          <Block
            width={["100%", "100%", "100%"]}
            marginTop="20px"
            marginBottom="30px"
          >
            {rejectionType === "Deletion"
              ? "This " + type + " is already assigned, so it cannot be deleted."
              : "This " + type + " is already assigned, so it cannot be updated."
            }
          </Block>
          <Block display="flex" justifyContent="flex-end">
            <Button
              onClick={close}
              overrides={{ BaseButton: { style: SAVE_BTN_STYLE } }}
              data-testid="delete-rejection-save"
            >
              OK
            </Button>
          </Block>
        </Block>
      </ModalBody>
    </Modal>
  );
};

export default DeleteRejection;
