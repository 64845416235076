import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { useStyletron } from "baseui";
import BrandLogo from "assets/images/login-logo.svg";
import { useSetState } from "store/store";
import KeyloakService from "../../services/http/KeycloakService";
import Keycloak from "keycloak-js";
import KeyCloakService from "services/http/KeycloakService";

const BLOCK_STYLE = {
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#1b246a",
};

const LOGIN_SECTION_STYLE = (theme) => ({
  display: "grid",
  gridTemplateColumns: "50% 50%",
  alignItems: "center",
  width: "60%",
  margin: "auto",
  maxWidth: "800px",
  textAlign: "center",
  backgroundColor: "#2931b5",
  borderRadius: "8px",

  [theme.mediaQuery.small]: {
    gridTemplateColumns: "auto",
    width: "80%",
  },
  [theme.mediaQuery.medium]: {
    gridTemplateColumns: "50% 50%",
    width: "80%",
  },
  [theme.mediaQuery.large]: {
    gridTemplateColumns: "50% 50%",
    width: "60%",
  },
});

const FORM_STYLE = {
  background: "#fff",
  borderRadius: "8px",
  boxSizing: "border-box",
  fontSize: "16px",
  height: "100%",
  padding: "32px",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  alignItems: "center",
  justifyContent: "center",
};

const BUTTON_OVERRIDE_STYLE = {
  paddingTop: "9px",
  paddingBottom: "9px",
  paddingLeft: "12px",
  paddingRight: "12px",
  backgroundColor: "#332FF1",
  height: "38px",
  width: "100%",
  borderTopLeftRadius: "8px",
  borderTopRightRadius: "8px",
  borderBottomLeftRadius: "8px",
  borderBottomRightRadius: "8px",
  textTransform: "uppercase",
  fontWeight: "500",
  fontSize: "14px",

  ":hover": {
    backgroundColor: "#332FF1",
    opacity: 0.92,
  },
  ":focus": {
    backgroundColor: "#332FF1",
    opacity: 0.92,
  },
};

export default function Login() {
  const [css, theme] = useStyletron();
  const history = useHistory();
  const setState = useSetState();

  useEffect(() => {
  localStorage.clear();
  if(window.location.href?.indexOf("?error=") > -1) {
    history.push("/login");
  }
 if(localStorage.getItem("logout") === "1"){
      //KeyCloakService.doLogout()
      localStorage.clear();
      
       setState({});
  } else
     if (
      localStorage.getItem("email") !== undefined &&
      localStorage.getItem("email") !== null &&
      localStorage.getItem("email") !== "null" &&
      localStorage.getItem("email") !== ""
    ) {
      loadSection();
    }
  }, []);

  const loadSection = () => {
    if (
      localStorage.getItem("role") === "ADMINISTRATOR" ||
      localStorage.getItem("role") === "ADMIN" ||
      localStorage.getItem("role") === "TENANT" ||
      localStorage.getItem("role") === "MANAGER" ||
      localStorage.getItem("role") === "BOEING MANAGER" ||
      localStorage.getItem("role") === "TENANT OWNER" ||
      localStorage.getItem("role") === "AFFILIATION" ||
      localStorage.getItem("role") === "INSTRUCTOR" ||
      localStorage.getItem("role") === "MENTOR"
    ) {
      history.push("/student");
    } else if (
      (localStorage.getItem("role") === "LEARNER" ||
        localStorage.getItem("role") === "COACH") &&
      localStorage.getItem("learner_data") > 0
    ) {
      history.push("/tracksummary");
    } else {
      history.push("/landing");
    }
  };

  const successCallback = (authenticated) => {
    history.push("/sso-home");
  };
  const failureCallback = (error) => {
    console.log("keycloak auth error", error);
  };

  function sso() {
    KeyloakService.initKeycloak(successCallback, failureCallback);
  }
  function ssoSignup() {
    // let url = process.env.REACT_APP_SSO_SIGNUP;
    // const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    // if (newWindow) newWindow.opener = null;
    let registerOption = {
      redirectUri: `${process.env.REACT_APP_LOGIN_REDIRECT_URL}`, //"http://localhost:3000/sso-home",
      action: "register",
    };
    KeyloakService.initKeycloakForSSO(
      () => {
        KeyloakService.doRegister(registerOption);
      },
      () => {}
    );
  }
  function ssoForgotpassword() {
    let url = process.env.REACT_APP_SSO_FORGOT_PASSWORD;
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  }

  return (
    <Block className={css(BLOCK_STYLE)}>
      <Block className={css(LOGIN_SECTION_STYLE(theme))}>
        <Block>
          <Block
            data-testid="login-image"
            as="img"
            src={BrandLogo}
            alt="Brand Logo"
            width="160px"
            minHeight="300px"
          />
        </Block>
        <Block className={css(FORM_STYLE)}>
          <Button
            size="compact"
            type="button"
            overrides={{ BaseButton: { style: BUTTON_OVERRIDE_STYLE } }}
            onClick={() => sso()}
            data-testid="login-login"
          >
            Log In
          </Button>
          {/* <Button
            size="compact"
            type="button"
            overrides={{ BaseButton: { style: BUTTON_OVERRIDE_STYLE } }}
            onClick={() => ssoSignup()}
            data-testid="login-signup"
          >
            Sign Up
          </Button> */}
          <Button
            size="compact"
            type="button"
            overrides={{ BaseButton: { style: BUTTON_OVERRIDE_STYLE } }}
            onClick={ssoForgotpassword}
            data-testid="login-forgot-password"
          >
            Forgot Password
          </Button>
        </Block>
      </Block>
    </Block>
  );
}
