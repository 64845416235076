import CourseCybersecurityFundamentals from "assets/badges/course_cybersecurity_fundamentals.svg";
import CourseAerospaceCapstone from "assets/badges/course_aerospace_capstone.svg";
import CourseApplicationSecurity from "assets/badges/course_application_security.svg";
import CourseIdentityAccessManagement from "assets/badges/course_identity_access_management.svg";
import CourseIncedentResponse from "assets/badges/course_incedent_response.svg";
import CourseMonitoringLogging from "assets/badges/course_monitoring_logging.svg";
import CourseNetworking from "assets/badges/course_networking.svg";
import CourseOperatingSystems from "assets/badges/course_operating_systems.svg";
import CourseRiskAnalysisAssessment from "assets/badges/course_risk_analysis_assessment.svg";
import CourseSecureSystemAnalysis from "assets/badges/course_secure_system_analysis.svg";
import CourseThreatIntelligenceManagement from "assets/badges/course_threat_intelligence_management.svg";
import CourseVulnerabilityPatchManagement from "assets/badges/course_vulnerability_patch_management.svg";

import CourseCybersecurityFundamentalsLarge from "assets/badges-large/course_cybersecurity_fundamentals_large.svg";
import CourseAerospaceCapstoneLarge from "assets/badges-large/course_aerospace_capstone_large.svg";
import CourseApplicationSecurityLarge from "assets/badges-large/course_application_security_large.svg";
import CourseIdentityAccessManagementLarge from "assets/badges-large/course_identity_access_management_large.svg";
import CourseIncedentResponseLarge from "assets/badges-large/course_incedent_response_large.svg";
import CourseMonitoringLoggingLarge from "assets/badges-large/course_monitoring_logging_large.svg";
import CourseNetworkingLarge from "assets/badges-large/course_networking_large.svg";
import CourseOperatingSystemsLarge from "assets/badges-large/course_operating_systems_large.svg";
import CourseRiskAnalysisAssessmentLarge from "assets/badges-large/course_risk_analysis_assessment_large.svg";
import CourseSecureSystemAnalysisLarge from "assets/badges-large/course_secure_system_analysis_large.svg";
import CourseThreatIntelligenceManagementLarge from "assets/badges-large/course_threat_intelligence_management_large.svg";
import CourseVulnerabilityPatchManagementLarge from "assets/badges-large/course_vulnerability_patch_management_large.svg";

import * as React from "react";
import { Block } from "baseui/block";
import { StatefulPopover, TRIGGER_TYPE, PLACEMENT } from "baseui/popover";
import { useStyletron } from "baseui";
import { COMMON_RADIUS } from "utils/style";

const MICRO_IMAGE_MAP = {
  "course_cybersecurity_fundamentals.png": {
    small: CourseCybersecurityFundamentals,
    large: CourseCybersecurityFundamentalsLarge,
  },
  "course_aerospace_capstone.png": {
    small: CourseAerospaceCapstone,
    large: CourseAerospaceCapstoneLarge,
  },
  "course_application_security.png": {
    small: CourseApplicationSecurity,
    large: CourseApplicationSecurityLarge,
  },
  "course_identity_access_management.png": {
    small: CourseIdentityAccessManagement,
    large: CourseIdentityAccessManagementLarge,
  },
  "course_incedent_response.png": {
    small: CourseIncedentResponse,
    large: CourseIncedentResponseLarge,
  },
  "course_monitoring_logging.png": {
    small: CourseMonitoringLogging,
    large: CourseMonitoringLoggingLarge,
  },
  "course_networking.png": {
    small: CourseNetworking,
    large: CourseNetworkingLarge,
  },
  "course_operating_systems.png": {
    small: CourseOperatingSystems,
    large: CourseOperatingSystemsLarge,
  },
  "course_risk_analysis_assessment.png": {
    small: CourseRiskAnalysisAssessment,
    large: CourseRiskAnalysisAssessmentLarge,
  },
  "course_secure_system_analysis.png": {
    small: CourseSecureSystemAnalysis,
    large: CourseSecureSystemAnalysisLarge,
  },
  "course_threat_intelligence_management.png": {
    small: CourseThreatIntelligenceManagement,
    large: CourseThreatIntelligenceManagementLarge,
  },
  "course_vulnerability_patch_management.png": {
    small: CourseVulnerabilityPatchManagement,
    large: CourseVulnerabilityPatchManagementLarge,
  },
};

const MicroBadge = ({
  name = "course_cybersecurity_fundamentals.png",
  isFaded = false,
  showModal,
  width="32px"
}) => {
  const [css] = useStyletron();

  const imageSmall =
    MICRO_IMAGE_MAP[name]?.small || CourseCybersecurityFundamentals;
  const imageLarge =
    MICRO_IMAGE_MAP[name]?.large || CourseCybersecurityFundamentalsLarge;

  const handleClick = (e) => {
    e.stopPropagation()
    if (showModal) showModal(imageLarge);
  };

  return (
    <StatefulPopover
      content={isFaded ? null : (
        <Block
          paddingLeft="20px"
          paddingRight="20px"
          paddingTop="40px"
          paddingBottom="40px"
          className={css({
            backgroundColor: "white",
            ...COMMON_RADIUS("8px"),
            cursor: "pointer",
          })}
          onClick={handleClick}
        >
          <Block
            as="img"
            src={imageLarge}
            alt="Micro Badge Large"
            width="220px"
            height="220px"
            data-testid="micro-badge-large"
          />
        </Block>
      )}
      triggerType={TRIGGER_TYPE.hover}
      placement={PLACEMENT.rightTop}
      returnFocus
      autoFocus
    >
      <Block
        as="img"
        src={imageSmall}
        alt="Micro Badge"
        width={width}
        className={css({
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          borderRadius: "50%",
          opacity: isFaded ? 0.5 : 1,
          filter: isFaded ? 'blur(1px)' : 'none',
        })}
        data-testid="micro-badge"
      />
    </StatefulPopover>
  );
};

export default MicroBadge;
