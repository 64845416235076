import { EMAIL_UPDATE, CONFIRM_EMAIL_CHANGE } from "../apiEndpoint";
import { profileAxios } from "../http/http";

export default function emailUpdate(APIType, payload) {
  let API_URL = EMAIL_UPDATE;
  if (APIType === "confirm_change") {
    API_URL = CONFIRM_EMAIL_CHANGE;
  }
  return profileAxios.post(`${API_URL}`, payload);
}
