import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { StatefulCalendar } from "baseui/datepicker";
import { StatefulPopover } from "baseui/popover";
import dayjs from "dayjs";

export const DATE_PICKER_STYLE = theme => ({
  backgroundColor: "#FFFFFF",
  border: `1px solid ${theme?.RAColors.gray200}`,
  borderRadius: "4px",
  lineHeight: "24px",
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingLeft: "10px",
  paddingRight: "10px",
});

export const CustomDatePicker = (props) => {
  CustomDatePicker.defaultProps = {
    dateTestId: "date",
    format: "MM/DD/YYYY",
    value: new Date(),
  };

  const [css, theme] = useStyletron();

  return (
    <StatefulPopover
      content={({ close }) => (
        <StatefulCalendar
          minDate={props.minDate}
          maxDate={props.maxDate}
          data-testid={props.dateTestId}
          onChange={(date) => props.onChange(date, close)}
        />
      )}
      returnFocus
      autoFocus
    >
      <Block
        className={css(DATE_PICKER_STYLE(theme))}
        name={props.format}
        data-testid={props.dateTestId}
        minLength={2}
        required={true}
      >
        {props.value
          ? dayjs(props?.value).format(props.format)
          : props.format}
      </Block>
    </StatefulPopover>
  );
};
