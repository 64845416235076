import React, { useEffect } from "react";
import { Block } from "baseui/block";
import { Input } from "baseui/input";
import { Select } from "baseui/select";
import { Textarea } from "baseui/textarea";
import { useStyletron } from "baseui";
import Loader from "react-js-loader";
import ModalComponent from "../Components/newJobGroup";

import { BASIC_INFO_TITLE } from "resources/constants/strings.js";
import {
  COMMON_RADIUS,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_COLOR,
} from "utils/style";
import { useSetState, useTrackedState } from "store/store.js";
import { fetchJobGroups } from "services/requests/job_role.js";
//import { sortAZSelectOptions } from "utils/select";
const COMMON_TEXT_STYLE = {
  fontFamily: "Manrope",
  lineHeight: "20px",
  fontWeight: 700,
};
const TITLE_STYLE = (theme) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "16px",
  flexWrap: "wrap",

  [theme.mediaQuery.small]: {
    marginBottom: "16px",
  },
  [theme.mediaQuery.medium]: {
    marginBottom: "36px",
  },
});
const HEADING_TEXT_STYLE = (theme) => ({
  ...COMMON_TEXT_STYLE,
  fontSize: "24px",
  color: theme.RAColors?.black,
});
const LABEL_STYLE = (theme) => ({
  ...COMMON_TEXT_STYLE,
  fontFamily: "Manrope-medium",
  fontWeight: 500,
  lineHeight: "22px",
  margin: "0 0 9px",
  [theme.mediaQuery.small]: {
    fontSize: "14px",
  },
  [theme.mediaQuery.medium]: {
    fontSize: "16px",
  },
});
const INPUT_OVERRIDE_STYLE = {
  Input: ({ $theme }) => ({
    backgroundColor: "none",
    color: $theme.RAColors?.gray900,
  }),
  Root: ({ $theme, $error }) => ({
    backgroundColor: "none",
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_COLOR(
      $error ? $theme.RAColors?.red600 : $theme.RAColors?.gray200
    ),
    ...COMMON_BORDER_WIDTH("1px"),
    width: "100%",
  }),
};
const SELECT_OVERRIDE_STYLE = {
  Root: ({ $theme }) => ({ width: "100%" }),
  ControlContainer: ({ $theme, $error }) => ({
    backgroundColor: "none",
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_COLOR(
      $error ? $theme.RAColors?.red600 : $theme.RAColors?.gray200
    ),
    ...COMMON_BORDER_WIDTH("1px"),
    color: $theme.RAColors?.gray900,
  }),
};
const TEXTAREA_OVERRIDE_STYLE = {
  Input: {
    style: ({ $theme }) => ({
      backgroundColor: "white",
    }),
  },
  Root: {
    style: ({ $theme, $error }) => ({
      ...COMMON_RADIUS("4px"),
      ...COMMON_BORDER_COLOR(
        $error ? $theme.RAColors?.red600 : $theme.RAColors?.gray200
      ),
      ...COMMON_BORDER_WIDTH("1px"),
    }),
  },
};
const FILTER_STYLE = (theme) => ({
  [theme.mediaQuery.small]: {
    marginBottom: "16px",
  },
  [theme.mediaQuery.medium]: {
    marginBottom: "24px",
  },
});
const SEPARATOR = {
  margin: "30px 0px",
};

export default function BasicInfo({ loader, validation }) {
  const [css, theme] = useStyletron();
  const [jobGroupOptions, setJobGroupOptions] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const [jobGroup, setJobGroup] = React.useState([]);
  const setState = useSetState();
  const state = useTrackedState();
  const userEmail = localStorage.getItem("email") || "";
  const [datavals, setDatavals] = React.useState("");

  // const jobGroupValueFunction = (params) => {
  //   if (params.value[0].label === "Create a New Job Group") {
  //     setShowModal(true);
  //   } else {
  //     changeValue("JobGroup", params.value[0].value);
  //     setJobGroup(params.value);
  //   }
  // };
  // async function closeModal() {
  //   setShowModal(false);
  //   let jobGroupArray = await getJobGroup();

  //   setJobGroupOptions(jobGroupArray);
  // }

  function onFormSubmit(event) {
    event.preventDefault();
  }

  const changeValue = (name, value) => {
    setState(
      (prev) => ({
        ...prev,
        jobObject: {
          ...prev.jobObject,
          [name]: value,
        },
      }),
      () => {}
    );
  };
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const getJobGroup = async () => {
    const response = await fetchJobGroups(userEmail);
    setDatavals(response?.data || [])

    let jobGroupArray = [
      {
        label: "Create a New Job Group",
        id: 0,
        value: "Create a New Job Group",
      },
    ];

    response?.data?.forEach((element, index) => {
      let obj = {};
      obj.label = element.JobGroup;
      obj.id = index + 1;
      obj.value = element.JobGroup;
      jobGroupArray.push(obj);
    });
    return [
      ...new Map(jobGroupArray.map((item) => [item["value"], item])).values(),
    ];
  };

  useEffect(() => {
    try {
      const fetchOption = async () => {
        let jobGroupArray = await getJobGroup();
        setJobGroupOptions(jobGroupArray);
        if (state?.editJobObject) {
          let fi = jobGroupArray.filter(
            (group) => group.value === state?.editJobObject?.JobGroup
          );
          if (fi.length >= 1) {
            setJobGroup([fi[0]]);
          }
        }
      };
      fetchOption();
    } catch (e) {
      //console.log(e);
    }
  }, []);
  return (
    <Block>
      <form data-testid="form" onSubmit={onFormSubmit}>
        <Block marginBottom="20px">
          <Block className={css(TITLE_STYLE(theme))}>
            <Block
              className={css(HEADING_TEXT_STYLE(theme))}
              data-testid="titleText"
            >
              {BASIC_INFO_TITLE}
            </Block>
          </Block>
          <Block
            width={["100%", "100%", "800px"]}
            maxWidth="100%"
            data-testid="inputs_block"
          >
            <Block marginTop="20px" marginBottom="30px">
              <Block
                as="h5"
                className={css(LABEL_STYLE(theme))}
                data-testid="roleNameLabel"
              >
                {"Role Name"}
              </Block>
              <Input
                value={state?.jobObject?.RoleName || ""}
                onChange={(e) =>
                  changeValue("RoleName", capitalizeFirstLetter(e.target.value))
                }
                placeholder="Enter text here"
                data-testid="roleNameInput"
                required={true}
                clearOnEscape
                overrides={{
                  Input: { style: INPUT_OVERRIDE_STYLE.Input },
                  Root: { style: INPUT_OVERRIDE_STYLE.Root },
                }}
                error={validation?.RoleName}
              />
            </Block>
            <Block
              display="flex"
              flexDirection={["column", "column", "row"]}
              gridGap={["8px", "8px", "24px"]}
              className={css(FILTER_STYLE(theme))}
            >
              <Block>
                <Block
                  as="h5"
                  className={css(LABEL_STYLE(theme))}
                  data-testid="codeLabel"
                >
                  {"Code"}
                </Block>
                <Input
                  value={state?.jobObject?.RoleCode || ""}
                  onChange={(e) =>
                    changeValue(
                      "RoleCode",
                      capitalizeFirstLetter(e.target.value)
                    )
                  }
                  clearOnEscape
                  required={true}
                  data-testid="codeInput"
                  overrides={{
                    Input: { style: INPUT_OVERRIDE_STYLE.Input },
                    Root: { style: INPUT_OVERRIDE_STYLE.Root },
                  }}
                  error={validation?.RoleCode}
                />
              </Block>
              <Block>
                <Block
                  as="h5"
                  className={css(LABEL_STYLE(theme))}
                  data-testid="functionLabel"
                >
                  {"Function"}
                </Block>
                <Input
                  value={state?.jobObject?.Function || ""}
                  onChange={(e) =>
                    changeValue(
                      "Function",
                      capitalizeFirstLetter(e.target.value)
                    )
                  }
                  clearOnEscape
                  required={true}
                  data-testid="functionInput"
                  overrides={{
                    Input: { style: INPUT_OVERRIDE_STYLE.Input },
                    Root: { style: INPUT_OVERRIDE_STYLE.Root },
                  }}
                  error={validation?.Function}
                />
              </Block>
              <Block>
                <Block
                  as="h5"
                  className={css(LABEL_STYLE(theme))}
                  data-testid="departmentLabel"
                >
                  {"Department"}
                </Block>
                <Input
                  value={state?.jobObject?.Department || ""}
                  onChange={(e) =>
                    changeValue(
                      "Department",
                      capitalizeFirstLetter(e.target.value)
                    )
                  }
                  clearOnEscape
                  required={true}
                  data-testid="departmentInput"
                  overrides={{
                    Input: { style: INPUT_OVERRIDE_STYLE.Input },
                    Root: { style: INPUT_OVERRIDE_STYLE.Root },
                  }}
                  error={validation?.Department}
                />
              </Block>
              <Block data-testid="selectInput">
                <Block
                  as="h5"
                  className={css(LABEL_STYLE(theme))}
                  data-testid="jobGroupLabel"
                >
                  {"Job Group"}
                </Block>
                <Select
                  clearable={false}
                  searchable={true}
                  //options={sortAZSelectOptions(jobGroupOptions)}
                  options={jobGroupOptions}
                  value={jobGroup || ""}
                  name="jobGroupSelect"
                  data-testid="jobGroup_select"
                  required
                  placeholder=""
                  onChange={(params) => {
                    if (params.value[0].label === "Create a New Job Group") {
                      setShowModal(true);
                    } else {
                      changeValue("JobGroup", params.value[0].value);
                      setJobGroup(params.value);
                    }
                  }}
                  overrides={{
                    Root: {
                      style: ({ $theme }) => ({
                        width: "100%",
                        [$theme.mediaQuery.medium]: {
                          width: "200px",
                        },
                      }),
                    },
                    ControlContainer: {
                      style: SELECT_OVERRIDE_STYLE.ControlContainer,
                    },
                    Dropdown: {
                      style: {
                        maxHeight: "250px",
                        overflow: "auto",
                        width: "195px",
                      },
                    },
                    Input: {
                      style: {
                        color: "white",
                      },
                    },
                  }}
                  error={validation?.JobGroup}
                />
              </Block>
            </Block>
            <Block marginTop="20px">
              <Block
                display="flex"
                justifyContent="space-between"
                marginBottom="4px"
                className={css(LABEL_STYLE(theme))}
                data-testid="descriptionLabel"
              >
                <Block>{"Description"}</Block>
                <Block>{state?.jobObject?.Description.length}/2000</Block>
              </Block>
              <Textarea
                value={state?.jobObject?.Description}
                onChange={(e) =>
                  changeValue(
                    "Description",
                    capitalizeFirstLetter(e.target.value)
                  )
                }
                placeholder="Enter text here"
                data-testid="descriptionInput"
                required={true}
                maxLength={2000}
                className="chat-textarea"
                overrides={TEXTAREA_OVERRIDE_STYLE}
                error={validation?.Description}
              />
            </Block>
          </Block>
        </Block>
      </form>
      <Block className={css(SEPARATOR)} />
      {showModal && (
        <ModalComponent
          showModal={showModal}
          close={async() => {
            setShowModal(false);
            let jobGroupArray = await getJobGroup();
            setJobGroupOptions(jobGroupArray);
          }}
          datavals={datavals}
        />
      )}
      {loader ? (
        <Loader
          type="bubble-loop"
          bgColor={"#332ff0"}
          color={"#332ff0"}
          size={100}
        />
      ) : null}
    </Block>
  );
}
