import React, { useEffect } from "react";
import { Block } from "baseui/block";
import { Input } from "baseui/input";
import { ButtonGroup } from "baseui/button-group";
import { Button } from "baseui/button";
import SnackBar from "components/SnackBar";
import { useStyletron } from "baseui";
import { CustomDatePicker } from "components/Datepicker";
import { Select } from "baseui/select";
import Skeleton from "components/Skeleton";
import { Degree_Data } from "resources/constants/constData";
import * as dayjs from "dayjs";
import educationDetailsUpdate from "services/requests/student_educationdetails_update";
import {
  SAVE_BTN_STYLE,
  CANCEL_BTN_STYLE,
  COLUMN_INPUTS_STYLE,
  REMOVE_TEXT_STYLE,
  ADD_TEXT_STYLE,
  SEPARATOR,
  INPUT_LABEL_STYLE,
  INPUT_OVERRIDE_STYLE,
  SELECT_OVERRIDE_STYLE,
} from "../../style.js";
import {
  SCHOOL_COLLEGE_NAME,
  DEGREE,
  STARTS,
  ENDS,
  MAJOR,
  MINOR,
  REMOVE,
  ADD_SCHOLL_COLLEGE,
  SAVE,
  CANCEL,
} from "resources/constants/strings";

export default function EducationInfoEdit(props) {
  const [css, theme] = useStyletron();
  const DegreeData = Degree_Data;
  const [apiStatusMessage, setApiStatusMessage] = React.useState(false);
  const [endDateValid, setEndDateValid] = React.useState(true);
  const [payloadObj, setPayloadObj] = React.useState([]);
  useEffect(() => {
    let newArray = props.eduData ? props.eduData?.map((ed, i) => {
      return { ...ed, degree: [{ label: ed.degree, id: ed.degree }] };
    }) : [];
    setPayloadObj(newArray);
  }, [props.eduData]);

  const inputsHandler = (e, index, selectedInput, close = "") => {
    let tempPayloadObj = [...payloadObj];
    if (selectedInput === "start_Date") {
      close();
      const startDate = dayjs(e.date);
      const endDate = dayjs(tempPayloadObj[index].date_end);
      if (startDate.diff(endDate, "month") > 1) {
        tempPayloadObj[index].date_end = dayjs("");
        setEndDateValid(false);
      } else {
        setEndDateValid(true);
        tempPayloadObj[index].date_start = e.date;
      }
    } else if (selectedInput === "end_Date") {
      close();
      setEndDateValid(true);
      tempPayloadObj[index].date_end = e.date;
    } else if (selectedInput === "degree") {
      tempPayloadObj[index].degree = e.value;
    } else if (
      selectedInput !== "start_Date" ||
      selectedInput !== "end_Date" ||
      selectedInput !== "degree"
    ) {
      const { name, value } = e.target;
      if (name === "school_name") {
        tempPayloadObj[index].school_name = value;
      } else if (name === "minor") {
        tempPayloadObj[index].minor = value;
      } else if (name === "major") {
        tempPayloadObj[index].major = value;
      }
    }
    setPayloadObj(tempPayloadObj);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (payloadObj?.length > 0 && endDateValid === true) {
      let newPayload = payloadObj?.map((ed, i) => {
        return { ...ed, degree: ed.degree[0].label };
      });
      let actionType = "update";
      let IndexCheck = "";
      const singleObj = Object.assign({}, ...newPayload);
      let paylaod = newPayload;
      if (newPayload.length === 1) {
        paylaod = singleObj;
        if ("index" in paylaod === false) {
          actionType = "add";
        }
      } else {
        paylaod?.map((data) => (IndexCheck = "index" in data));
        if (IndexCheck === false) {
          actionType = "add";
        }
      }
      educationDetailsUpdate(actionType, paylaod).then(function (response) {
        setApiStatusMessage(true);
        if (response.status === 200) {
          setTimeout(() => {
            setApiStatusMessage(false);
            props.parentCallback();
            props.setUpdateData();
          }, 2000);
        }
      });
    }
  };
  const cancel = () => {
    props.parentCallback(false);
  };
  const removeForm = (index, school, degree, sDate, eDate, minor, major) => {
    if(index === undefined){
      setPayloadObj(payloadObj.slice(0,-1));
    }
    const removeFormObj = {
      index: index,
      email_id: localStorage.getItem("email"),
      school_name: school,
      degree: degree[0].label,
      date_start: sDate,
      date_end: eDate,
      minor: minor,
      major: major,
    };
    educationDetailsUpdate("delete", removeFormObj).then(function (response) {
      setApiStatusMessage(true);
      if (response.status === 200) {
        setTimeout(() => {
          setApiStatusMessage(false);
          props.parentCallback();
          props.setUpdateData();
        }, 2000);
      }
    });
  };

  const addForm = () => {
    var lastId =
      payloadObj?.length > 0 ? payloadObj[payloadObj.length - 1].id + 1 : 0;
    let forms = {
      id: lastId,
      email_id: localStorage.getItem("email"),
      school_name: "",
      degree: "",
      date_start: "",
      date_end: "",
      minor: "",
      major: "",
    };
    setPayloadObj((prevState) => [...prevState, forms]);
  };

  const ALL_DEGREE = DegreeData?.map((degree) => ({
    label: degree,
    id: degree,
  }));

  return (
    <Block>
      {!apiStatusMessage ? (
        <form data-testid="eduform" onSubmit={handleSubmit}>
          {payloadObj?.map((e, index) => (
            <Block key={index}>
              <Block>
                <Block width="80%" marginBottom="20px">
                  <Block className={css(INPUT_LABEL_STYLE(theme))} data-testid="school_name_label">{SCHOOL_COLLEGE_NAME}</Block>
                  <Input
                    overrides={{
                      Input: { style: INPUT_OVERRIDE_STYLE.Input },
                      Root: { style: INPUT_OVERRIDE_STYLE.Root },
                    }}
                    inputProps={{
                      "data-testid": "school_name",
                    }}
                    data-testid="school_name"
                    name="school_name"
                    value={e.school_name}
                    required={true}
                    onChange={(e) => inputsHandler(e, index, "school_name")}
                  />
                </Block>
                <Block width="80%" className={css(COLUMN_INPUTS_STYLE(theme, 3))}>
                  <Block>
                    <Block as="label" className={css(INPUT_LABEL_STYLE(theme))} data-testid="degree_label">{DEGREE}</Block>
                    <Select
                      clearable={false}
                      searchable={false}
                      overrides={{
                        Root: { style: SELECT_OVERRIDE_STYLE.Root },
                        ControlContainer: {
                          style: SELECT_OVERRIDE_STYLE.ControlContainer,
                        },
                      }}
                      options={ALL_DEGREE}
                      name="degree"
                      data-testid="degree"
                      onChange={(e) => inputsHandler(e, index, "degree")}
                      value={e.degree}
                    />
                  </Block>
                  <Block>
                    <Block as="label" className={css(INPUT_LABEL_STYLE(theme))} data-testid="start_date_label">{STARTS}</Block>
                    <CustomDatePicker
                      name="date_start"
                      dateTestId="date_start"
                      required={true}
                      minDate={new Date("1950-01-01T07:00:00.000Z")}
                      value={e.date_start && new Date(e.date_start)}
                      placeholder="YYYY-MM"
                      format="YYYY-MM"
                      onChange={(e, close) =>
                        inputsHandler(e, index, "start_Date", close)
                      }
                    />
                  </Block>
                  <Block>
                    <Block as="label" className={css(INPUT_LABEL_STYLE(theme))} data-testid="end_date_label">{ENDS}</Block>
                    <CustomDatePicker
                      name="date_end"
                      dateTestId="date_end"
                      minDate={new Date(dayjs(e.date_start).add(1, "day"))}
                      maxDate={new Date(dayjs().add(6, "year"))}
                      value={e.date_end && new Date(e.date_end)}
                      placeholder="YYYY-MM"
                      format="YYYY-MM"
                      required={true}
                      onChange={(e, close) =>
                        inputsHandler(e, index, "end_Date", close)
                      }
                    ></CustomDatePicker>
                  </Block>
                </Block>
                <Block width="80%" className={css(COLUMN_INPUTS_STYLE(theme, 2))}>
                  <Block>
                    <Block as="label" className={css(INPUT_LABEL_STYLE(theme))} data-testid="major_label">{MAJOR}</Block>
                    <Input
                      overrides={{
                        Input: { style: INPUT_OVERRIDE_STYLE.Input },
                        Root: { style: INPUT_OVERRIDE_STYLE.Root },
                      }}
                      name="major"
                      data-testid="major"
                      value={e.major}
                      onChange={(e) => inputsHandler(e, index, "major")}
                    />
                  </Block>
                  <Block>
                    <Block as="label" className={css(INPUT_LABEL_STYLE(theme))} data-testid="minor_label">{MINOR}</Block>
                    <Input
                      overrides={{
                        Input: { style: INPUT_OVERRIDE_STYLE.Input },
                        Root: { style: INPUT_OVERRIDE_STYLE.Root },
                      }}
                      name="minor"
                      data-testid="minor"
                      value={e.minor}
                      onChange={(e) => inputsHandler(e, index, "minor")}
                    />
                  </Block>
                </Block>
                <Block
                  as="span"
                  data-testid="removeForm"
                  className={css(REMOVE_TEXT_STYLE)}
                  onClick={() =>
                    removeForm(
                      e.index,
                      e.school_name,
                      e.degree,
                      e.date_start,
                      e.date_end,
                      e.minor,
                      e.major
                    )
                  }
                >
                  {REMOVE}
                </Block>
              </Block>
              <Block className={css(SEPARATOR)} width="80%" />
            </Block>
          ))}

          <Block>
            <Block
              as="span"
              data-testid="addform"
              className={css(ADD_TEXT_STYLE)}
              onClick={() => addForm()}
            >
              {ADD_SCHOLL_COLLEGE}
            </Block>
          </Block>
          <Block>
            <ButtonGroup
              size="compact"
              overrides={{
                Root: { style: { display: 'flex', gap: '20px' } }
              }}
            >
              <Button
                data-testid="save"
                type="submit"
                overrides={{ BaseButton: { style: SAVE_BTN_STYLE } }}
              >
                {SAVE}
              </Button>
              <Button
                data-testid="cancel"
                onClick={cancel}
                overrides={{ BaseButton: { style: CANCEL_BTN_STYLE } }}
              >
                {CANCEL}
              </Button>
            </ButtonGroup>
          </Block>
          {apiStatusMessage && (
            <SnackBar message={"Values Updated Successfully"} />
          )}
        </form>
      ) : (
        <Block marginTop={"15px"}>
          <Skeleton oval={false} height="auto" width="auto" rows={4} />
        </Block>
      )}
    </Block>
  );
}
