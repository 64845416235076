import { Header } from "components/Header";
import React from "react";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import { useMemo } from "react";
import { Redirect } from "react-router-dom";
import DrawerRouterContainer from "components/newDrawer";
import Title from "./components/title";
import Summary from "./components/summary";
import { LearnerProfile, MentorProfile } from "../TrackSummary/components/about";
import Assignments from "./components/assignments";
import { useTrackedState } from "../../store/store";
import useExportPdf from "hooks/useExportPdf";

const MAIN_STYLE = (theme) => ({
  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "40px",
  },
});
export default function Assignment() {
  const [css, theme] = useStyletron();
  const { exportPdf, pdfComponentRef } = useExportPdf(new Date().getFullYear());
  const [dataVal, setDataVal] = React.useState(null);
  const [questDatas, setQuestDatas] = React.useState([]);
  const state = useTrackedState();
   localStorage.setItem("currentPage","assignment")
  if (state.assignment && state.assignment !== null) {
    localStorage.setItem("assignment", JSON.stringify(state.assignment));
  }
  const userType = useMemo(() => {
    const username = localStorage.getItem("username");
    const role = localStorage.getItem("role") || localStorage.getItem("viewAs");
    if ([null, undefined, ""].includes(username)) return null;
    if (
      [
        "ADMINISTRATOR",
        "ADMIN",
        "TENANT",
        "MANAGER",
        "BOEING MANAGER",
        "AFFILIATION",
        "TENANT OWNER",
        "INSTRUCTOR","MENTOR",
      ].includes(role)
    )
      return "manager";

    return "member";
  }, []);

  if (!userType) return <Redirect to="/login" />;

  const axlpData =(data) =>{
    setDataVal(data)
  }

  const filteredQuestData = (val) =>{
    setQuestDatas(val)
  }

  const respAssignments = JSON.parse(localStorage.getItem("assignment"));
  const resp = JSON.parse(localStorage.getItem("track"));
  return (
    <Block>
    <Header />
    <DrawerRouterContainer>
      <Block className={css(MAIN_STYLE(theme))}>
        <Title exportPdf={exportPdf} />
        <Block ref={pdfComponentRef}>
          <Summary assignments={respAssignments} email={resp?.email_id} axlpVal={axlpData} track={resp} questData={filteredQuestData}/>
          <Block
            display="flex"
            flexDirection={["column", "column", "column", "row"]}
            gridGap={["16px", "16px", "20px"]}
          >
            <Block flex="1">
              <Assignments dataval={dataVal} assgnName={respAssignments?.course_name} questFilteredData={questDatas}/>
            </Block>
            <Block
              width={["initial", "initial", "initial", "20%"]}
              display="flex"
              flexDirection="column"
              gridGap="20px"
            >
              <LearnerProfile track={resp} />
              <MentorProfile track={resp} />
            </Block>
          </Block>
        </Block>
      </Block>
    </DrawerRouterContainer>
  </Block>
  );
}
