import * as React from "react";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";
import DefaultAvatar from "assets/images/user-profile.png";
import { useMemo } from "react";
import { Skill_Score,Points_Gained } from "resources/constants/strings";
import Chart from "components/newChart";
import {
  Checkbox,
  LABEL_PLACEMENT
} from "baseui/checkbox";


const COMMON_TEXT_STYLE = {
  fontSize: "14px",
  lineHeight: "19px",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const AVATAR_STYLE = { width: "40px", height: "40px", borderRadius: "100%" };
const NAME_STYLE = (theme) => ({
  ...COMMON_TEXT_STYLE,
  fontFamily: "Manrope-Bold",
  color: theme.RAColors?.black,
  marginBottom: "8px",
  // cursor: "pointer",
});
const EMAIL_STYLE = { ...COMMON_TEXT_STYLE, fontFamily: "Manrope-Semibold" };
const STATUS_BLOCK = {
  top: "0",
  left: "16px",
  width: "calc(100% - 32px)",
  height: "calc(100% - 32px)",
};
const STATUS_STYLE = {
  ...COMMON_TEXT_STYLE,
  fontFamily: "Manrope-Medium",
  padding: "6px 12px",
  borderRadius: "100px",
  textAlign:"center"
};
const SCORE_STYLE = { ...COMMON_TEXT_STYLE };

export const TableHeader = ({ name, sorted, asc, onSort, size = "lg" }) => {
  const [css, theme] = useStyletron();


  return (
    <Block
      className={css({ cursor: "pointer" })}
      onClick={() => onSort(name, asc)}
      data-testid="table-header"
    >
      <Block
        as="span"
        className={css({
          fontSize: size === "lg" ? "16px" : "14px",
          lineHeight: size === "lg" ? "22px" : "19px",
          fontFamily: 'Manrope-Semibold',
        })}
        data-testid="table-header-text"
      >
        {name}
      </Block>
    </Block>
  );
};

export const TableActions = ({ row, onClickName }) => {
  const [css, theme] = useStyletron();

  return (
    <Block display="flex" gridGap="16px">
      <Block overflow="hidden">
        <Block
          className={css(NAME_STYLE(theme))}
          onClick={onClickName}
          data-testid="table-name-name"
        >
          {row?.Action_name}
        </Block>
      </Block>
    </Block>
  );
};

// export const TableRead = ({ row, onSelectCheckbox}) => {
//   const [checked, setChecked] = React.useState(row?.View   === 'Y' ? true : false);
//   const [css, theme] = useStyletron();
//   const onstatuschange = (val) =>{
//     var data =  row;
//     data.View = val === true ? 'Y' : 'N'
//     //setCheckedExport(val);  
//       onSelectCheckbox(data);    
//   }
//   return (
//   <Block display="flex" alignItems="center">
//   <Block
//     as="span"
//     color={theme.RAColors?.blue700}
//     className={css({ ...SCORE_STYLE, cursor: "pointer" })}
//     data-testid="table-score-number"
//   >

//     <Checkbox
//       checked={checked}
//       onChange={e => {setChecked(e.target.checked); onstatuschange(e.target.checked)}}
//       labelPlacement={LABEL_PLACEMENT.right}
//     >
//     </Checkbox>
//   </Block>
// </Block>
//   )
// };

export const TableWrite = ({ row, onSelectCheckbox ,roleVal}) => {
  const [css, theme] = useStyletron();
  const [checked, setChecked] = React.useState(row?.Edit === 'Y' ? true : false);
  const [value, setValue] = React.useState("1");
  const handleClick = (e) => {
    e.stopPropagation();
  };
  const onstatuschange = (val) =>{
    var data =  row;
    data.Edit = val === true ? 'Y' : 'N'
    //setCheckedExport(val);  
      onSelectCheckbox(data);    
  }
  const roleDisable = (rowData) =>{
    if(rowData.Action_name === "Roles & Permissions"){
      if(roleVal === "TENANT OWNER" || roleVal === "ADMIN"){ 
          return true
      }
    }else{
      return false
    }
  }
  return (
    <Block display="flex" alignItems="center">
      <Block
        as="span"
        color={theme.RAColors?.blue700}
        className={css({ ...SCORE_STYLE, cursor: "pointer" })}
        onClick={handleClick}
        data-testid="table-score-number"
      >
        <Checkbox
      checked={checked}
      onChange={e => {setChecked(e.target.checked); onstatuschange(e.target.checked)}}
      labelPlacement={LABEL_PLACEMENT.right}
      disabled={roleDisable(row)}
    >
    </Checkbox>
      </Block>
    </Block>
  );
};

// export const TableExport = ({ row, onSelectCheckbox }) => {
//   const [checkedexport, setCheckedExport] = React.useState(row?.Export === 'Y' ? true : false);
//   const [css, theme] = useStyletron();
//   const handleClick = (e) => {
//     e.stopPropagation();
//   };
//   const onstatuschange = (val) =>{
//     var data =  row;
//     data.Export = val === true ? 'Y' : 'N'
//     //setCheckedExport(val);  
//       onSelectCheckbox(data);    
//   }
//   return (
//     <Block display="flex" alignItems="center">
//       <Block
//         as="span"
//         color={theme.RAColors?.blue700}
//         className={css({ ...SCORE_STYLE, cursor: "pointer" })}
//         data-testid="table-flex-score-number"
//         onClick={handleClick}
//       >
//         <Checkbox
//       checked={checkedexport}
//       onChange={e => {setCheckedExport(e.target.checked);onstatuschange(e.target.checked)}}
//       labelPlacement={LABEL_PLACEMENT.right}
//     ></Checkbox>
//       </Block>
//     </Block>
//   );
// };
