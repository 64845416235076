import axios from "axios";
import { studentURL } from "../config";

const LinkforceControllerDOLTemplate = axios.create({
  baseURL: studentURL
})

LinkforceControllerDOLTemplate.defaults.headers.common = {
  ...(LinkforceControllerDOLTemplate.defaults.headers.common || {}),
  ApiKey: 'TESTDEV-0000-1111-2222-333333333333'
}

export const fetchDOLTemplate = (email) => LinkforceControllerDOLTemplate.get(`/template?user_email=${email}`)
export const createDOLTemplate = (payload, email = '') => LinkforceControllerDOLTemplate.post(`/template?user_email=${email}`, payload)
export const updateDOLTemplate = (payload, email) => LinkforceControllerDOLTemplate.put(`/template?user_email=${email}`, payload)
export const deleteDOLTemplate = (payload) => LinkforceControllerDOLTemplate.delete(`/template`, {
  data: payload
})
export const courseLists = (email) => LinkforceControllerDOLTemplate.get(`/courselist?user_email=${email}`)
