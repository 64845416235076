import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { useStyletron } from "baseui";
import {
  COMMON_RADIUS,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_STYLE,
  COMMON_BORDER_COLOR,
} from "utils/style";
import { New_Template } from "resources/constants/strings";

const HEADING_STYLE = (theme) => ({
  fontFamily: "Epilogue-Semibold",
  fontSize: "28px",
  lineHeight: "40px",
  color: theme.RAColors?.gray900,
});

const COMMON_BUTTON_STYLE = {
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingRight: "24px",
  paddingLeft: "24px",
  lineHeight: "22px",
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  fontFamily: "Manrope-Bold",
};

const DUPLICATE_BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_BUTTON_STYLE,
  ...COMMON_BORDER_COLOR($theme.RAColors?.purple),
  backgroundColor: "white",
  ":hover": { backgroundColor: $theme.RAColors?.gray300 },
  color: $theme.RAColors?.purple,

  [$theme.mediaQuery.small]: {
    width: "100%",
  },
  [$theme.mediaQuery.medium]: {
    width: "initial",
  },
});

const SAVE_BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_BUTTON_STYLE,
  ...COMMON_BORDER_COLOR($theme.RAColors?.blue700),
  backgroundColor: $theme.RAColors?.blue700,
  ":hover": { backgroundColor: $theme.RAColors?.blue800 },
  color: "white",

  [$theme.mediaQuery.small]: {
    width: "100%",
  },
  [$theme.mediaQuery.medium]: {
    width: "initial",
  },
});



export default function NewTemplateTitle({ save, close }) {
  const [css, theme] = useStyletron();

  return (
    <Block
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      gridGap="16px"
      marginBottom={["16px", "16px", "20px"]}
      flexWrap="wrap"
    >
      <Block as="h3" className={css(HEADING_STYLE(theme))} data-testid="title">
        {New_Template}
      </Block>
      <Block display="flex" gridGap="16px">
        <Button
          onClick={save}
          data-testid="save-button"
          overrides={{ BaseButton: { style: SAVE_BUTTON_OVERRIDE_STYLE } }}
        >
          Save
        </Button>
        <Button
          onClick={close}
          data-testid="cancel-button"
          overrides={{ BaseButton: { style: DUPLICATE_BUTTON_OVERRIDE_STYLE } }}
        >
          Cancel
        </Button>
      </Block>
    </Block>
  );
}
