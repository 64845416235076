export const Hi_Ricky = "Hi Ricky";
export const Profile = "Profile";
export const Logout = "Logout";
export const Learners_Overview = "Learners Overview";
export const Download_Data = "Download Data";
export const Download_PDF = "Download PDF";
export const Export_Data = "Export Data";
export const Name = "Name";
export const Track_Progress = "Track & Progress";
export const Score = "Scores";
export const Flex_radar = "Flex Skills";
export const Core_Skill = "Core Skills";
export const Optional_Skill = "Optional Skills";
export const Progress = "Progress";
export const Status = "Status";
export const Behind = "from target";
export const Exceeds = "exceeds";
export const Assignmnets = "Assignments";
export const Assignmnets_Completed = "Assignments completed";
export const Assessment = "Assessment";
export const Labs = "Labs";
export const Summary_Text = "Summary";
export const All_Student = "All students";
export const All_Learner = "All learners";
export const Learner = "Learner";
export const Student_Failing = "Action Needed";
export const Student_Exceeds = "Exceeds";
export const Student_At_Risk = "At Risk";
export const Search_Text = "Search";
export const Grades = "Grades";
export const Track = "Track";
export const On_Track = "On Track";
export const Details = "Details";
export const Skills = "Skills";
export const Skill_Score = "Skill Completion %";
export const Points_Gained = "Points gained";
export const Skills_Gained = "Skills gained";
export const Skills_Behind = "Skills behind";
export const Percentile = "Percentile";
export const Pct_completed = "Pct Complete";
export const Skill_Group = "Skill group";
export const Description = "Descriptions";
export const Points = "Points Pct";
export const Total_Points = "Total points";
export const Total_Points_Gained_Core = "Total Core Points Gained";
export const Total_Points_Gained_Flex =
  "Total Additional Practice Points Gained";
export const Point = "Points";
export const Done = "Done";
export const All_Skill_Group = "All skill groups";
export const Instructor = "Instructors";
export const LabScore = "Lab Score";
export const Lessons = "Lessons";
export const Lesson = "Lesson";
export const Lesson_Completed = "Lessons Completed";
export const Lesson_At_Risk = "Lessons at-risk";
export const Modules_Completed = "Quests Completed";
export const Modules_Behind = "Quests Behind";
export const Lesson_Late = "Lessons late";
export const DataNotAvailable = "Data not available";
export const ShowMore = "Show More";
export const ShowLess = "Show Less";
export const About_This_Track = "About this track";
export const Security_Governance = "Security & governance";
export const Security_Privacy = "Security & Privacy";
export const Student_Profile = "Student Profile";
export const Your_Profile = "Your Profile";
export const Email = "Email";
export const Competency = "Competency";
export const Credentials = "Credentials";
export const Mentor = "Mentor Profile";
export const Rooms_Completed = "Rooms completed";
export const Rooms_Late = "Rooms late";
export const Network = "Network";
export const Sum_Of_All_Skill = "Core Active Pct. Complete";
export const This_Cohet_Only = "This cohort only";
export const This_Cohort = "This cohort";
export const Macro = "Macro";
export const Regular = "Regular";
export const Speciality = "Specialty";
export const All_Time = "All-time";
export const Settings = "Settings";
export const Personal_Info = "Personal Info";
export const Basic_info_name = "First Name";
export const Basic_lastname = "Last Name";
export const Kahoot_Username = "Kahoot Username";
export const Basic_info_role = "Role";
export const Basic_info_dob = "Date Of Birth";
export const Basic_info_address = "Address";
export const Basic_info_address2 = "Address line2(optional)";
export const City = "City";
export const States = "State";
export const Country = "Country";
export const Zip_Code = "Zip Code";
export const New_Mobile_Number = "New mobile number";
export const New_Email_Address = "New email address";
export const Work_Email = "Work Email";
export const Personal_Email = "Personal Email";
export const Mobile_No = "Mobile No";
export const Change = "Change";
export const Reset_Password = "Reset password";
export const Resend_Password_Reset_Email = "Resend password reset email";
export const Linkedin = "Linkedin";
export const Facebook = "Facebook";
export const TryHackMe = "TryHackMe";
export const Threat_Response = "Threat response";
export const Back_All_Groups = "Back to all skill groups";
export const Company_Overview = "Company Overview";
export const Enter_Names = "Enter names";
export const Skills_Profile = "'s Skills Profile";
export const Flex_Skills_Profile = "'s Flex Skills Profile";
export const Create_Department = "Create Department";
export const Assignment_Type = "Assignment Type";
export const Attributes = "Attributes";
export const Assignments = "Assignments";
export const Search_Item = "Search item";
export const Skill_Points = "Skill points";
export const Tags = "Tags";
export const Module = "Module";
export const BASIC_INFO_TITLE = "Basic Info";
export const RAPID_ASCENT = "RapidAscent";
export const ACCOUNT_ACCESS = "Account access";
export const YOUR_PASSWORD = "Your Password";
export const RESEND = "Resend";
export const ABOUT_YOU = "About you";
export const EDUCATION = "Education";
export const SCHOOL_COLLEGE_NAME = "Name of School / College / Institution";
export const DEGREE = "Degree";
export const STARTS = "Starts";
export const ENDS = "Ends";
export const MAJOR = "Major (optional)";
export const MINOR = "Minor (optional)";
export const REMOVE = "Remove";
export const ADD_SCHOLL_COLLEGE = "Add school / college";
export const SAVE = "Save";
export const CANCEL = "Cancel";
export const SOCAIL_ACCOUNT = "Social Accounts";
export const COMPLETED_ON = "Completed on";
export const QUESTIONS_ANSWERED = "Questions answered";
export const Core_Skills = "Core Skills";
export const Pct_complete = "Pct Complete";
export const Cohort_Status = "Cohort Status";
export const Cohort_Progress = "Cohort Progress";
export const Learner_Progress = "Learner Progress";
export const Learner_Kahoot_Aggregate_Accuracy =
  "Learner Kahoot Aggregate Accuracy";
export const Learner_Kahoot = "Learner Kahoot!";
export const Manage_Templates = "Manage Templates";
export const Export_Learners = "Export Learners";
export const Dol_Checklist = "DOL Checklist";
export const Dol_Checklist_Title = "Competency Sign-off";
export const Filter_Text = "Filter by name or email";
export const Add_Quick_Filter_Text = "Add Quick Filter Buttons by Status";
export const Criteria_Templates = "Criteria Templates:";
export const Export_Learner = "Export Learner";
export const Core_Scores = "Core Scores";
export const Flex_Scores = "Flex Scores";
export const LEARNER_PROFILE_SCORE_TEXT =
  "Completion percentage is based on core content that has been made available and will fluctuate as new content is enabled";
export const FLEX_CONTENT =
  "% of Area relative effort of completion to other areas";
export const EMail = "EMail";
export const ADD_SKILL = "Additional Practice Skills";
export const PRACTICE_SKILL = "Additional Practice Rooms";
export const PRACTICE_SKILL_PROFILE = "'s Additional Practice Skills Profile";
export const Enter_Names_Email = "Enter names or email";
export const COMPETENCIES = "Competencies";
export const COMPLETION = "Completion";
export const FINAL_SIGN_OFF = "Final sign off";
export const CREATE_ROLE = "Create Role";
export const JOB_ROLE_TITLE = "Job Roles";
export const ACTIVITIES = "Activities";
export const NEW_JOB_ROLE_TITLE = "New Job Role";
export const PUBLISH = "Publish";
export const DESIRED_TRAITS = "Desired Traits";
export const DOL_COMPETENCY = "DOL Competency";
export const SAVE_AS_DRAFT = "Save as draft";
export const New_Template = "New Template";
export const DOL_Template = "Competency Manager";

export const WORK_PROCESS = "WORK PROCESS SCHEDULE";
export const CYBER_SECURITY = "CYBERSECURITY ANALYST";
export const CODE = "O*NET-SOC CODE:15-1299.05";
export const RAPID_CODE = "RAPIDS CODE:3032CB";
export const CYBER_TEXT = "Cybersecurity Analyst - Competency Checklist";
export const RTI = "RTI Training";
export const RTI_DESC =
  "Mentor/Journeyworker has provided training and demonstration of task to the apprentice.";
export const OJT = "Structured OJT";
export const OJT_DESC = "Apprentice can perform the task with some coaching.";
export const PROFICIENT = "Proficient in Task";
export const PROFICIENT_DESC =
  "Apprentice performs task properly and consistently.";
export const COMPLETION_DATE = "Apprentice Completion Date/Initials";
export const JOURNEY_WORKER = "Journeyworker Date/Initials";
export const COMPLETION_DATE_DESC =
  "Date apprentice completes final demonstration of competency.";
export const EXISTING_TITLE =
  "(EXISTING TITLE: CYBER DEFENSE INCIDENT RESPONDER)";
export const COMPLETION_D = "Completion Date";
export const EDIT = "Edit";
export const MISSING = "Missing";
export const THM_USERNAME = "THM Username";
export const COHORT_LIST = "Cohort Select List";
export const DOWNLOAD_CSV = "Download csv";
export const LEARNER_LIST = "Learner's List";
export const BACK = "BACK";

