export const DATA = [
  {
    TemplateId: 200,
    TemplateVersion: "2.20220901",
    TemplateName: "Pentester",
    TenantID: 0,
    DOLCheckListBlob: '',
    TemplateCriteriaFlow: {
      rti: true,
      js: true,
      ojt: true,
      proficientmanager: true,
      proficientlearner: false,
      finalapproval: true,
    },
    TemplateTaskFlow: {
      js: true,
      ojt: true,
      proficientmanager: true,
      proficientlearner: false,
      finalapproval: true,
    },
    TemplateCategories: [
      {
        CategoryName: "Design, build, and test networked systems 222",
        TemplateCriteria: [
          {
            CriteriaId: 1,
            CriteriaName: "Assist in the selection of appropriate design solutions of networked system components",
            rti: [],
            js: '',
            ojt: '',
          },
          {
            CriteriaId: 2,
            CriteriaName: "Ensured networked system compliance  in accordance with relevant standards and regulations",
            rti: [],
            js: '',
            ojt: '',
          },
        ],
      },
      {
        CategoryName: "Design, configure, and secure onsite embedded cloud application systems",
        TemplateCriteria: [
          {
            CriteriaId: 1,
            CriteriaName: "Assist in the selection of appropriate design solutions of networked system components",
            rti: [],
            js: '',
            ojt: '',
          },
          {
            CriteriaId: 2,
            CriteriaName: "Ensured networked system compliance  in accordance with relevant standards and regulations",
            rti: [],
            js: '',
            ojt: '',
          },
        ],
      },
    ],
  },
  {
    TemplateId: 100,
    TemplateVersion: "1.20220101",
    TemplateName: "Pentester",
    TenantID: 0,
    DOLCheckListBlob: '',
    TemplateCriteriaFlow: {
      rti: true,
      js: true,
      ojt: true,
      proficientmanager: true,
      proficientlearner: false,
      finalapproval: true,
    },
    TemplateTaskFlow: {
      js: true,
      ojt: true,
      proficientmanager: true,
      proficientlearner: false,
      finalapproval: true,
    },
    TemplateCategories: [
      {
        CategoryName: "Design, build, and test automation infrastructure 111",
        TemplateCriteria: [
          {
            CriteriaId: 1,
            CriteriaName: "Assist in the selection of appropriate design solutions of networked system components",
            rti: [],
            js: '',
            ojt: '',
          },
          {
            CriteriaId: 2,
            CriteriaName: "Ensured networked system compliance  in accordance with relevant standards and regulations",
            rti: [],
            js: '',
            ojt: '',
          },
        ],
      },
      {
        CategoryName: "Design, configure, and secure onsite embedded automation systems",
        TemplateCriteria: [
          {
            CriteriaId: 1,
            CriteriaName: "Assist in the selection of appropriate design solutions of networked system components",
            rti: [],
            js: '',
            ojt: '',
          },
          {
            CriteriaId: 2,
            CriteriaName: "Ensured networked system compliance  in accordance with relevant standards and regulations",
            rti: [],
            js: '',
            ojt: '',
          },
        ],
      },
    ],
  },
];
