import { Block } from "baseui/block";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalBarSeries,
  Hint,
  DiscreteColorLegend,
} from "react-vis";
import { useStyletron } from "baseui";
import {
  TILE_STYLE,
  TITLE_STYLE,
  HINT_STYLE,
  HINT_TEXT_STYLE,
  HINT_TITLE_STYLE,
  MOBILE_SCREEN_BREAKPOINT,
} from "../utils";
import { Learner_Progress } from "resources/constants/strings";
import { useTrackedState } from "store/store";
import { Skeleton } from "baseui/skeleton";
import { VISUALIZATION_HEIGHT } from "../utils";

const ITEMS = [
  { color: "rgb(0, 99, 255)", title: "Core Completed Score" },
  { color: "rgba(0, 99, 255, 0.3)", title: "Core Incompleted Score" },
  { color: "rgb(0, 199, 255)", title: "Flex Completed Score" },
  { color: "rgba(0, 199, 255, 0.3)", title: "Flex Incompleted Score" },
];

const LearnerProgress = () => {
  const [css, theme] = useStyletron();

  const [value, setValue] = useState(false);
  const [width, setWidth] = useState(0);
  const parent = useRef();

  const state = useTrackedState();
  const height = VISUALIZATION_HEIGHT * 2
  const isMobileScreen = width < MOBILE_SCREEN_BREAKPOINT

  const progress = useMemo(() => {
    if (!state.learners) return null;

    const filteredLearners = state.learners.filter(
      (learner) => learner.last_name.toLowerCase() !== "learner"
    );

    return filteredLearners.map((learner) => {
      const { learner_radar_skills, learner_radar_skills_flex } = learner;

      return {
        name: learner.name,
        coreCompleted: learner_radar_skills.skill_score,
        coreCompletedPercentage: learner_radar_skills.skill_completion_pct,
        coreIncompleted:
          learner_radar_skills.skill_score_max -
          learner_radar_skills.skill_score,
        coreIncompletedPercentage:
          100 - learner_radar_skills.skill_completion_pct,
        coreTotal: learner_radar_skills.skill_score_max,
        flexCompleted: learner_radar_skills_flex.skill_score,
        flexCompletedPercentage: learner_radar_skills_flex.skill_completion_pct,
        flexIncompleted:
          learner_radar_skills_flex.skill_score_max -
          learner_radar_skills_flex.skill_score,
        flexIncompletedPercentage:
          100 - learner_radar_skills_flex.skill_completion_pct,
        flexTotal: learner_radar_skills_flex.skill_score_max,
      };
    });
  }, [state.learners]);

  const coreCompletedData = useMemo(() => {
    if (!progress) return [];

    return progress.map((learner) => ({
      y: learner.name,
      x: learner.coreCompleted,
      value: learner.coreCompleted,
      total: learner.coreTotal,
      percentage: learner.coreCompletedPercentage,
      type: "Completed",
      scoreType: "Core",
    }));
  }, [progress]);

  const coreIncompletedData = useMemo(() => {
    if (!progress) return [];

    return progress.map((learner) => ({
      y: learner.name,
      x: learner.coreIncompleted,
      value: learner.coreIncompleted,
      total: learner.coreTotal,
      percentage: learner.coreIncompletedPercentage,
      type: "Incompleted",
      scoreType: "Core",
    }));
  }, [progress]);

  const flexCompletedData = useMemo(() => {
    if (!progress) return [];

    return progress.map((learner) => ({
      y: learner.name,
      x: learner.flexCompleted,
      value: learner.flexCompleted,
      total: learner.flexTotal,
      percentage: learner.flexCompletedPercentage,
      type: "Completed",
      scoreType: "Flex",
    }));
  }, [progress]);

  const flexIncompletedData = useMemo(() => {
    if (!progress) return [];

    return progress.map((learner) => ({
      y: learner.name,
      x: learner.flexIncompleted,
      value: learner.flexIncompleted,
      total: learner.flexTotal,
      percentage: learner.flexIncompletedPercentage,
      type: "Incompleted",
      scoreType: "Flex",
    }));
  }, [progress]);

  useEffect(() => {
    if (parent) setWidth(parent.current.clientWidth);
  }, []);

  const onValueMouseOver = (v) => {
    setValue(v);
  };

  const onSeriesMouseOut = () => {
    setValue(false);
  };

  const getTickFormat = (label) => {
    if (!isMobileScreen) return label

    if (label.length <= 8) return label;
    return label.slice(0, 7).concat("..");
  };

  return (
    <Block
      className={css(TILE_STYLE(theme))}
    >
      <Block className={css(TITLE_STYLE)} data-testid="learners-progress">{Learner_Progress}</Block>
      <Block
        ref={parent}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gridGap="20px"
        className="learner-progress"
      >
        {state.isFetching ? (
          <Skeleton width={`${width}px`} height={`${height}px`} />
        ) : (
          <>
            <XYPlot
              width={width}
              height={height}
              stackBy="x"
              yType="ordinal"
              margin={{ left: isMobileScreen ? 64 : 150 }}
            >
              <VerticalGridLines />
              <XAxis />
              <YAxis tickFormat={getTickFormat} style={{ text: { textAnchor: 'end' } }} />
              <HorizontalBarSeries
                cluster="Core"
                data={coreCompletedData}
                onValueMouseOver={onValueMouseOver}
                onSeriesMouseOut={onSeriesMouseOut}
                color="rgb(0, 99, 255)"
                barWidth={0.6}
              />
              <HorizontalBarSeries
                cluster="Core"
                data={coreIncompletedData}
                onValueMouseOver={onValueMouseOver}
                onSeriesMouseOut={onSeriesMouseOut}
                color="rgba(0, 99, 255, 0.3)"
                barWidth={0.6}
              />
              <HorizontalBarSeries
                cluster="Flex"
                data={flexCompletedData}
                onValueMouseOver={onValueMouseOver}
                onSeriesMouseOut={onSeriesMouseOut}
                color="rgb(0, 199, 255)"
                barWidth={0.6}
              />
              <HorizontalBarSeries
                cluster="Flex"
                data={flexIncompletedData}
                onValueMouseOver={onValueMouseOver}
                onSeriesMouseOut={onSeriesMouseOut}
                color="rgba(0, 199, 255, 0.3)"
                barWidth={0.6}
              />
              {value !== false && (
                <Hint value={value}>
                  <Block className={css(HINT_STYLE)}>
                    <Block className={css(HINT_TITLE_STYLE)}>{value.y}</Block>
                    <Block className={css(HINT_TEXT_STYLE)}>
                      {value.type} {value.scoreType}
                      &nbsp; Score: {value.value} / {value.total}
                      &nbsp; ({value.percentage}%)
                    </Block>
                  </Block>
                </Hint>
              )}
            </XYPlot>
            <DiscreteColorLegend orientation="horizontal" items={ITEMS} />
          </>
        )}
      </Block>
    </Block>
  );
};

export default LearnerProgress;
