import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { useStyletron } from "baseui";
import DownloadIcon from "assets/images/download-icon.svg";
import {
  COMMON_RADIUS,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_STYLE,
} from "utils/style";
import { ChevronRight } from "baseui/icon";
import { Link } from "react-router-dom";
import {
  Download_Data,
  Summary_Text,
  All_Learner,
  Learner,
  Module
} from "resources/constants/strings.js";
import { filteredAdmin } from "utils/app-utils";

const BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  backgroundColor: "white",
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  borderColor: $theme.RAColors?.purple,
  paddingTop: "12px",
  paddingBottom: "12px",
  paddingRight: "24px",
  paddingLeft: "24px",
  ":hover": { backgroundColor: $theme.RAColors?.gray300 },
  color: $theme.RAColors?.purple,

  [$theme.mediaQuery.small]: {
    width: '100%',
  },
  [$theme.mediaQuery.medium]: {
    width: "initial",
  },
});

const DOWNLOAD_ICON_STYLE = {
  width: "12px",
  height: "12px",
  marginRight: "16px",
};
const TITLE_STYLE = theme => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: '16px',
  flexWrap: 'wrap',
  paddingLeft: "4px",
  paddingRight: "4px",
  [theme.mediaQuery.small]: {
    marginBottom: "16px",
  },
  [theme.mediaQuery.medium]: {
    marginBottom: "36px",
  },
});
const BREADCRUMB_TITLE_STYLE = theme => ({
  display: "flex",
  flexDirection: "column", 
  gridGap: "4px",
  height: "auto",
});

const ITEM_TITLE_STYLE = {
  fontFamily: "Manrope-Medium",
  fontSize: "12px",
  lineHeight: "16px",
};

const ITEM_CONTENT_STYLE = theme => ({
  fontFamily: "Manrope-Bold",
  fontSize: "18px",
  lineHeight: "25px",
  textTransform: 'capitalize',
  color: theme?.RAColors?.blue700,
  [theme.mediaQuery.small]: {
    fontSize:"12px",
  },
  [theme.mediaQuery.large]: {
    fontSize:"18px",
  },
});

const TITLE_BREDCRUMB = theme => ({
  display:"flex",
  flexDirection:"row",
  gridGap:"20px",
  alignItems:"center",
  [theme.mediaQuery.small]: {
    alignItems:"flex-start",
  },
  [theme.mediaQuery.large]: {
    alignItems:"center",
  },
});

const ITEM_CONTENT_STYLE_BLUE_800 =theme => ({
  fontFamily: "Manrope-Bold",
  fontSize: "18px",
  lineHeight: "25px",
  textTransform: 'capitalize',
  color: theme?.RAColors?.gray800,
  [theme.mediaQuery.small]: {
    fontSize: "14px"
  },
  [theme.mediaQuery.large]: {
    fontSize: "18px",
  },
});

const BreadCrumb = ({ exportPdf }) => {
  const assignmentResp = JSON.parse(localStorage.getItem("assignment"));
  const resp = JSON.parse(localStorage.getItem("track"));
  const [css, theme] = useStyletron();
  return (
    <Block className={css(TITLE_STYLE(theme))}>
      <Block className={css(TITLE_BREDCRUMB(theme))}>
        {filteredAdmin(localStorage.getItem("role"))   ? 
        <Block className={css(BREADCRUMB_TITLE_STYLE(theme))}>
          <Block className={css(ITEM_TITLE_STYLE)} data-testid="summary_text">{Summary_Text}</Block>
          <Link
            to="/student"
            className={css(
              ITEM_CONTENT_STYLE(theme),
            )}
            data-testid="All_learners_text"
          >
            {All_Learner}
          </Link>
        </Block>: null}
        {filteredAdmin(localStorage.getItem("role")) ? <ChevronRight size={32} /> : null}
        <Block className={css(BREADCRUMB_TITLE_STYLE(theme))}>
          <Block data-testid="learner_text" className={css(ITEM_TITLE_STYLE)}>{Learner}</Block>
          <Link
            to="/tracksummary"
            className={css(
              ITEM_CONTENT_STYLE(theme)
            )}
          >
            {resp?.name}
          </Link>
        </Block>
         <ChevronRight size={32} />
        <Block display="flex" flexDirection="column" gridGap="4px">
          <Block data-testid="module_text" className={css(ITEM_TITLE_STYLE)}>{Module}</Block>
          <Block
            className={css(
              ITEM_CONTENT_STYLE_BLUE_800(theme)
            )}
          >
            {assignmentResp?.course_name}
          </Block>
        </Block>
      </Block>
      <Button
        onClick={exportPdf}
        overrides={{ BaseButton: { style: BUTTON_OVERRIDE_STYLE } }}
        data-testid="download-button"
      >
        <img
          src={DownloadIcon}
          alt="Download"
          className={css(DOWNLOAD_ICON_STYLE)}
        />
        <span>{Download_Data}</span>
      </Button>
    </Block>
  );
};

export default BreadCrumb;
