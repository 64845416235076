import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { Input } from "baseui/input";
import { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import {
  COMMON_BORDER_COLOR,
  COMMON_BORDER_STYLE,
  COMMON_BORDER_WIDTH,
  COMMON_PADDING,
  COMMON_RADIUS,
} from "utils/style";
import Actions from "./actions";
import CloseIcon from "assets/svg/close.svg";
import ReorderIcon from "assets/images/reorder.png";
import { ReactSortable } from "react-sortablejs";
import { convertDateToYYYYMMDD, deepCopy, trimSpacesInObject } from "../util";
import { useTrackedState } from "store/store";
import Flow from "./flow";
import {Checkbox, STYLE_TYPE} from 'baseui/checkbox';
import { Panel, Accordion } from "baseui/accordion";
import SaveModal from "./saveModal";
import CriteriaSelect from "./criteriaSelect";

const ACCORDION_HEADER_STYLE = {
  ...COMMON_PADDING("0px"),
  fontFamily: "Manrope-Semibold",
  fontSize: "16px",
  lineHeight: "20px",
  outline: "none",
  paddingLeft: "32px",
  paddingRight: "32px",
  paddingTop: '16px',
  paddingBottom: '16px',
  backgroundColor: "#F9F9F9",
};

const ACCORDION_CONTENT_STYLE = ({ $theme }) => ({
  ...COMMON_PADDING("32px"),
});

const ACCORDION_ROOT_STYLE = ({ $theme }) => ({
  border: `1px solid ${$theme.RAColors?.gray200}`,
  borderRadius: "8px",
  overflow: "hidden",
});

const INPUT_OVERRIDE_STYLE = {
  Input: ({ $theme }) => ({
    backgroundColor: "none",
    color: $theme.RAColors?.gray900,
    fontFamily: "Manrope",
  }),
  Root: ({ $theme, $error }) => ({
    maxWidth: "100%",
    backgroundColor: "none",
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_COLOR(
      $error ? $theme.RAColors?.red600 : $theme.RAColors?.gray200
    ),
    ...COMMON_BORDER_WIDTH("1px"),
    [$theme.mediaQuery.small]: {
      width: "100%",
    },
    [$theme.mediaQuery.large]: {
      width: "400px",
    },
  }),
};

const INPUT_OVERRIDE_COMP  = ({ $theme, $error }) => ({
  maxWidth: "100%",
  backgroundColor: "none",
  ...COMMON_RADIUS("4px"),
  ...COMMON_BORDER_COLOR(
    $error ? $theme.RAColors?.red600 : $theme.RAColors?.gray200
  ),
  ...COMMON_BORDER_WIDTH("1px"),
  [$theme.mediaQuery.small]: {
    width: "100%",
  },
  [$theme.mediaQuery.large]: {
    width: "auto",
    marginRight:"3px"
  },
})

const ADD_MORE = (theme) => ({
  fontSize: "16px",
  lineHeight: "22px",
  fontFamily: "Manrope-Semibold",
  color: theme.RAColors?.blue800,
  cursor: "pointer",
});

const COMMON_BUTTON_STYLE = {
  lineHeight: "22px",
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  fontFamily: "Manrope-Semibold",
};

const LABEL_STYLE = {
  fontFamily: "Manrope",
  fontSize: "14px",
};

const SAVE_BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_BUTTON_STYLE,
  ...COMMON_BORDER_COLOR($theme.RAColors?.blue700),
  backgroundColor: $theme.RAColors?.blue700,
  ":hover": { backgroundColor: $theme.RAColors?.blue700 },
  color: "white",
});

const CANCEL_BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_BUTTON_STYLE,
  ...COMMON_BORDER_COLOR($theme.RAColors?.gray100),
  backgroundColor: $theme.RAColors?.gray100,
  ":hover": { backgroundColor: $theme.RAColors?.gray100 },
  color: $theme.RAColors?.gray800,
});

const DUPLICATE_BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_BUTTON_STYLE,
  ...COMMON_PADDING("8px"),
  ...COMMON_BORDER_COLOR($theme.RAColors?.purple),
  backgroundColor: "white",
  ":hover": { backgroundColor: $theme.RAColors?.gray300 },
  color: $theme.RAColors?.purple,
});

const DRAG_ICON = (theme) => ({
  cursor: "pointer",
  border: `1px solid transparent`,
  padding: "4px",
  borderRadius: "4px",
  marginBottom: "6px",
  visibility: "hidden",
  display: 'none',
  [theme.mediaQuery.large]: {
    display: 'block'
  },
});

const CRITERIA_BOX = theme => ({
  ":hover img": {
    visibility: "visible",
  },
  marginLeft: 0,
  [theme.mediaQuery.large]: {
    marginLeft: "-32px",
  },
});

const SUBTEXT_STYLE = (theme) => ({
  fontSize: "14px",
  lineHeight: "20px",
  marginTop: "4px",
  color: theme?.RAColors?.red600,
  display: "inline-block",
});

const TOGGLE_OVERRIDE_STYLE = {
  Root: () => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '44px',
    alignItems: 'center'
  }),
  Label: () => ({
    fontFamily: 'Manrope'
  })
}

const CATEGORIES_STYLE = (theme) => ({
  borderTop: `1px solid ${theme.RAColors?.gray200}`,
  paddingTop: "20px",
});

const CATEGORIES_LABEL_STYLE = {
  fontFamily: "Manrope-Semibold",
  fontSize: "18px",
  marginBottom: "20px",
};

const NEW_TEMPLATE_DATA = {
  TemplateName: "",
  TemplateVersion: `1.${convertDateToYYYYMMDD(new Date())}`,
  TenantID: 0,
  DOLCheckListBlob: "",
  // TemplateCriteriaFlow: {
  //   rti: true,
  //   js: true,
  //   ojt: true,
  //   proficientmanager: true,
  //   proficientlearner: false,
  //   finalapproval: true,
  // },
  // TemplateTaskFlow: {
  //   // js: true,
  //   ojt: true,
  //   proficientmanager: true,
  //   proficientlearner: false,
  //   finalapproval: true,
  // },
  TemplateCategories: [
    {
      CategoryName: "",
      TemplateCriteria: [
        { CriteriaId: 1, CriteriaName: "", rti: [], js: "", ojt: false },
      ],
    },
  ],
};

const EditTemplate = forwardRef(
  ({ data, save, saveNewVersion, cancel, isNew = false }, ref) => {
    const [css, theme] = useStyletron();
    const state = useTrackedState();

    const [currentData, setCurrentData] = useState(
      isNew ? NEW_TEMPLATE_DATA : deepCopy(data) || {}
    );
    const [error, setError] = useState(false);
    const [showSaveModal, setShowSaveModal] = useState(false);

    // const taskFlow = useMemo(() => {
    //   const { js, ...rest } = currentData.TemplateTaskFlow

    //   return rest
    // }, [currentData.TemplateTaskFlow])

    const addCriteria = (categoryIndex) => {
      const newTemplate = deepCopy(currentData);
      newTemplate.TemplateCategories[categoryIndex].TemplateCriteria.push({
        CriteriaId: Math.floor(Math.random() * 100000),
        CriteriaName: "",
        rti: [],
        js: "",
        ojt: false,
      });
      setCurrentData(newTemplate);
    };

    const addCategory = () => {
      const newTemplate = deepCopy(currentData);
      newTemplate.TemplateCategories.push({
        CategoryName: "",
        TemplateCriteria: [],
      });
      setCurrentData(newTemplate);
    };

    const deleteCriteria = (categoryIndex, criteriaIndex) => {
      const newTemplate = deepCopy(currentData);
      newTemplate.TemplateCategories[categoryIndex].TemplateCriteria.splice(
        criteriaIndex,
        1
      );
      setCurrentData(newTemplate);
    };

    const deteleCategory = (categoryIndex) => {
      const newTemplate = deepCopy(currentData);
      newTemplate.TemplateCategories.splice(categoryIndex, 1);
      setCurrentData(newTemplate);
    };

    const updateName = (e, trim = false) => {
      let value = e.target.value
      if (trim) value = value?.trim()
      setCurrentData({ ...currentData, TemplateName: value });
    };

    const updateCategoryName = (e, categoryIndex, trim = false) => {
      let value = e.target.value
      if (trim) value = value?.trim()

      const newTemplate = deepCopy(currentData);
      newTemplate.TemplateCategories[categoryIndex].CategoryName = value
      setCurrentData(newTemplate);
    };

    const updateCriteriaField = (key, value, categoryIndex, criteriaIndex, trim = false) => {
      let newValue = value
      if (trim) newValue = newValue?.trim()

      const newTemplate = deepCopy(currentData);
      newTemplate.TemplateCategories[categoryIndex].TemplateCriteria[
        criteriaIndex
      ][key] = newValue;
      setCurrentData(newTemplate);
    };

    const sort = (categoryIndex, criterias) => {
      const newTemplate = deepCopy(currentData);

      // clear unused fields
      criterias.forEach((c) => {
        delete c.chosen;
        delete c.selected;
      });

      newTemplate.TemplateCategories[categoryIndex].TemplateCriteria =
        criterias;
      setCurrentData(newTemplate);
    };

    // const saveFlow = (type = "criteria", flow) => {
    //   if (type === "criteria") {
    //     setCurrentData({ ...currentData, TemplateCriteriaFlow: flow });
    //   } else {
    //     setCurrentData({ ...currentData, TemplateTaskFlow: flow });
    //   }
    // };

    const update = () => {
      const templates = state.dolTemplates || [];
      const isNameUnique = !templates.some(
        (template) => template.TemplateName === currentData.TemplateName
      );

      if (isNew) {
        if (!currentData.TemplateName || !isNameUnique) {
          setError(true);
          return;
        }
      }

      save(deepCopy(trimSpacesInObject(currentData)));
      setShowSaveModal(false);
    };

    const version = () => {
      saveNewVersion(deepCopy(trimSpacesInObject(currentData)));
      setShowSaveModal(false);
    };

    useImperativeHandle(ref, () => ({
      getCurrentData: () => currentData,
      save: () => update(),
    }));

    useEffect(() => {
      if (data) setCurrentData(data);
    }, [data]);

    return (
      <Block
        display="flex"
        flexDirection="column"
        gridGap="24px"
        padding={[0, 0, "32px"]}
      >
        <Block maxWidth="100%">
          <Block marginBottom="8px" className={css(LABEL_STYLE)} data-testid="template_name">
            Template Name
          </Block>
          <Input
            value={currentData.TemplateName}
            overrides={{
              Input: { style: INPUT_OVERRIDE_STYLE.Input },
              Root: { style: INPUT_OVERRIDE_STYLE.Root },
            }}
            disabled={!isNew}
            data-testid="template_name_input"
            onChange={updateName}
            onBlur={e => updateName(e, true)}
            error={error}
          />
          {isNew && (
            <Block as="span" className={css(SUBTEXT_STYLE(theme))}>
              * Template name must be unique
            </Block>
          )}
        </Block>
        {/* <Flow
          flow={currentData.TemplateCriteriaFlow}
          saveFlow={(flow) => saveFlow("criteria", flow)}
        /> */}
        {/* <Flow
          isCriteria={false}
          flow={taskFlow}
          saveFlow={(flow) => saveFlow("task", flow)}
        /> */}

        <Block className={css(CATEGORIES_STYLE(theme))}>
          <Block className={css(CATEGORIES_LABEL_STYLE)} data-testid="template_categories">Categories</Block>
          <Block display="flex" flexDirection="column" gridGap="16px">
            {currentData.TemplateCategories.map((category, index) => (
              <Accordion
                key={index}
                accordion={false}
                initialState={{
                  expanded: currentData.TemplateCategories.map(
                    (_, i) => `${i}`
                  ),
                }}
                overrides={{
                  Content: { style: ACCORDION_CONTENT_STYLE },
                  Header: { style: ACCORDION_HEADER_STYLE },
                  Root: { style: ACCORDION_ROOT_STYLE },
                  PanelContainer: { style: { borderBottomWidth: "0px" } },
                }}
              >
                <Panel title={category.CategoryName}>
                  <Block
                    key={index}
                    display="flex"
                    flexDirection="column"
                    gridGap="16px"
                  >
                    <Block
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      marginBottom="16px"
                      gridGap={["8px", "8px", "48px"]}
                      flexWrap="wrap"
                    >
                      <Block width="800px" maxWidth="100%">
                        <Block marginBottom="8px" className={css(LABEL_STYLE)}>
                          Name
                        </Block>
                        <Input
                          value={category.CategoryName}
                          overrides={{
                            Input: { style: INPUT_OVERRIDE_STYLE.Input },
                            Root: { style: INPUT_OVERRIDE_STYLE.Root },
                          }}
                          onChange={(e) => updateCategoryName(e, index)}
                          onBlur={e => updateCategoryName(e, index, true)}
                        />
                      </Block>
                      <Actions
                        type="category"
                        onDelete={() => deteleCategory(index)}
                      >
                        <Button
                          overrides={{
                            BaseButton: {
                              style: DUPLICATE_BUTTON_OVERRIDE_STYLE,
                            },
                          }}
                        >
                          •••
                        </Button>
                      </Actions>
                    </Block>
                    <Block>
                      <ReactSortable
                        list={category.TemplateCriteria}
                        setList={(c) => sort(index, c)}
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "16px",
                        }}
                      >
                        {category.TemplateCriteria.map(
                          (criteria, criteriaIndex) => (
                            <Block key={criteria.CriteriaId}>
                              <Block
                                position="relative"
                                display="flex"
                                alignItems="flex-end"
                                className={css(CRITERIA_BOX(theme))}
                              >
                                <Block
                                  as="img"
                                  src={ReorderIcon}
                                  width="24px"
                                  className={css(DRAG_ICON(theme))}
                                />
                                <Block width={['100%', '100%', 'initial']}  maxWidth="calc(100vw - 80px)" flex="1">
                                  <Block
                                    display="flex"
                                    flexDirection={["column", "column", "row"]}
                                    gridGap="16px"
                                    alignItems={['flex-start', 'flex-start', "center"]}
                                  >
                                    <Block flex="1" width={['100%', '100%', 'initial']}>
                                      <Block
                                        marginBottom="8px"
                                        className={css(LABEL_STYLE)}
                                      >
                                        Competency
                                      </Block>
                                      <Input
                                        value={criteria.CriteriaName}
                                        overrides={{
                                          Input: {
                                            style: INPUT_OVERRIDE_STYLE.Input,
                                          },
                                          Root: {
                                            style: INPUT_OVERRIDE_COMP,
                                          },
                                        }}
                                        onChange={(e) =>
                                          updateCriteriaField(
                                            "CriteriaName",
                                            e.target.value,
                                            index,
                                            criteriaIndex
                                          )
                                        }
                                        onBlur={(e) =>
                                          updateCriteriaField(
                                            "CriteriaName",
                                            e.target.value,
                                            index,
                                            criteriaIndex,
                                            true
                                          )
                                        }
                                      />
                                    </Block>
                                    <CriteriaSelect
                                      showRTI={true}
                                      showJS={true}
                                      rti={criteria?.rti}
                                      js={criteria?.js}
                                      onRTIChange={(params) => {
                                        updateCriteriaField(
                                          "rti",
                                          params.value.map((i) => i.label),
                                          index,
                                          criteriaIndex
                                        );
                                      }}
                                      onJSChange={(params) =>
                                        updateCriteriaField(
                                          "js",
                                          params.option.id,
                                          index,
                                          criteriaIndex
                                        )}
                                    />
                                    {/* {currentData.TemplateCriteriaFlow.ojt && ( */}
                                      <Block>
                                        <Block
                                          marginBottom="8px"
                                          className={css(LABEL_STYLE)}
                                        >
                                          OJT
                                        </Block>
                                        <Checkbox
                                          key={index}
                                          checked={criteria.ojt === true}
                                          onChange={e => updateCriteriaField(
                                            "ojt",
                                            e.currentTarget.checked ? true : false,
                                            index,
                                            criteriaIndex
                                          )}
                                          checkmarkType={STYLE_TYPE.toggle}
                                          overrides={{
                                            Root: { style: TOGGLE_OVERRIDE_STYLE.Root },
                                            Label: { style: TOGGLE_OVERRIDE_STYLE.Label }
                                          }}
                                        />
                                      </Block>
                                     {/* )} */}
                                    <Block
                                      as="img"
                                      width="24px"
                                      src={CloseIcon}
                                      alt="Close"
                                      className={css({ cursor: "pointer" })}
                                      marginLeft={[0, 0, "16px"]}
                                      marginTop={[0, 0, "27px"]}
                                      onClick={() =>
                                        deleteCriteria(index, criteriaIndex)
                                      }
                                    />
                                  </Block>
                                </Block>
                              </Block>
                            </Block>
                          )
                        )}
                      </ReactSortable>
                    </Block>
                    <Block
                      className={css(ADD_MORE(theme))}
                      onClick={() => addCriteria(index)}
                    >
                      Add Competency
                    </Block>
                  </Block>
                </Panel>
              </Accordion>
            ))}
          </Block>
        </Block>

        <Block onClick={addCategory} className={css(ADD_MORE(theme))}  data-testid="template_add_category">
          Add Category
        </Block>
        {!isNew && (
          <Block display="flex" gridGap="16px">
            <Button
              overrides={{ BaseButton: { style: SAVE_BUTTON_OVERRIDE_STYLE } }}
              onClick={() => setShowSaveModal(true)}
              data-testid="template_save_btn"
            >
              Save
            </Button>
            <Button
              overrides={{
                BaseButton: { style: CANCEL_BUTTON_OVERRIDE_STYLE },
              }}
              onClick={() => cancel(currentData)}
              data-testid="template_cancel_btn"
            >
              Cancel
            </Button>
          </Block>
        )}
        <SaveModal
          isOpen={showSaveModal}
          cancel={() => setShowSaveModal(false)}
          update={update}
          version={version}
        />
      </Block>
    );
  }
);

export default EditTemplate;
