import { useEffect } from "react";
import { Header } from "components/Header";
import DrawerRouterContainer from "components/newDrawer";
import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Redirect } from "react-router-dom";
import "react-vis/dist/style.css";
import Progress from "./progress";
import Engagement from "./engagement";
import StudentAPI from "services/requests/student_api";
import { fetchLabCompletion } from "services/requests/lab_completion_api";
import { useSetState, useTrackedState } from "store/store";
import { RANGE } from "./utils";
import getRolesPageVisibility from "../../components/getPageVisibility"

const MAIN_STYLE = (theme) => ({
  [theme.mediaQuery.small]: {
    padding: "16px",
  },
  [theme.mediaQuery.medium]: {
    padding: "40px",
  },
});

const HEADING_STYLE = (theme) => ({
  fontFamily: "Epilogue-Semibold",
  fontSize: "28px",
  lineHeight: "40px",
  color: theme.RAColors?.gray900,
});

const Dashboard = () => {
  const [css, theme] = useStyletron();

  const setState = useSetState();
  const state = useTrackedState();

  const role = localStorage.getItem("viewAs") !== null ? localStorage.getItem("viewAs") : localStorage.getItem("role");

  useEffect(() => {
    let isUnmounted = false;
    localStorage.setItem("currentPage","dashboard")

    const fetchStudentOverview = async () => {
      const userEmail = localStorage.getItem("email") || "";

      try {
        setState((prev) => ({ ...prev, isFetching: true }));

        const [studentResponse, labResponse] = await Promise.all([
          StudentAPI(userEmail),
          fetchLabCompletion(userEmail, RANGE)
        ]);

        if (studentResponse.status !== 200 || labResponse.status !== 200 || isUnmounted) return;

        const { students_overview_summary, learner_profile_list } = studentResponse.data[0]

        setState((prev) => ({
          ...prev,
          studentOverview: students_overview_summary,
          learners: learner_profile_list,
          isFetching: false,
          isFetchTrackData: true,
          labCompletion: labResponse.data
        }));
      } catch (e) {
        if (isUnmounted) return;
        console.log(e);
      }
    };

    if (!state.learners || !state.labCompletion) fetchStudentOverview();

    return () => {
      isUnmounted = true;
    };
  }, []);

   //page access control - based on role
  var permittedRoles = getRolesPageVisibility("Dashboard");
  if (!permittedRoles.includes(role)) return <Redirect to="/student" />;

  return (
    <>
      <Header />
      <DrawerRouterContainer>
        <main className={css(MAIN_STYLE(theme))}>
          <Block
            marginBottom="16px"
            display={["block", "block", "flex"]}
          >
            <Block as="h3" className={css(HEADING_STYLE(theme))} data-testid="dashboard-text">
              Dashboard
            </Block>
          </Block>
          <Block display="flex" flexDirection="column" gridGap="16px">
            <Progress/>
            <Engagement />
          </Block>
        </main>
      </DrawerRouterContainer>
    </>
  );
};

export default Dashboard;
