import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { lazy, Suspense, useState } from "react";
import {
  COMMON_BORDER_COLOR,
  COMMON_BORDER_STYLE,
  COMMON_BORDER_WIDTH,
  COMMON_RADIUS,
} from "utils/style";
import Actions from "./actions";

const COMMON_BUTTON_STYLE = ($theme) => ({
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingRight: "24px",
  paddingLeft: "24px",
  lineHeight: "22px",
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  fontFamily: "Manrope-Bold",
  height: "42px",
  [$theme.mediaQuery.small]: {
    paddingRight: "10px",
    paddingLeft: "10px",
    width: "initial",
  },
  [$theme.mediaQuery.medium]: {
    paddingRight: "24px",
    paddingLeft: "24px",
    width: "initial",
  },
});

const DUPLICATE_BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_BUTTON_STYLE($theme),
  ...COMMON_BORDER_COLOR($theme.RAColors?.purple),
  backgroundColor: "white",
  ":hover": { backgroundColor: $theme.RAColors?.gray300 },
  color: $theme.RAColors?.purple,
});

const TITLE_STYLE = {
  fontSize: "20px",
  wordBreak: "break-all"
};

const MAIN_BLOCK_STYLE = (theme) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  marginRight: "8px",
  flexDirection: "row",
  [theme.mediaQuery.small]: {
    flexDirection: "column",
  },
  [theme.mediaQuery.medium]: {
    flexDirection: "row",
  },
});
const Confirm = lazy(() => import("./newTemplateName.js"));

const AccordionHeader = ({
  name,
  duplicate,
  exportTemplate,
  edit,
  deleteItem,
  expands,
}) => {
  const [css, theme] = useStyletron();
  const [showDuplicateConfirm, setShowDuplicateConfirm] = useState(false);

  const onSave = (name) => {
    duplicate(name);
    setShowDuplicateConfirm(false);
  };

  return (
    <>
      <Block className={css(MAIN_BLOCK_STYLE(theme))}>
        <Block className={css(TITLE_STYLE)} data-testid="accordion-header-name">
          {name}
        </Block>
        {expands?.showActionIcons ? (
          <Block display="flex" gridGap={["8px", "8px", "16px"]}>
            <Button
              overrides={{
                BaseButton: { style: DUPLICATE_BUTTON_OVERRIDE_STYLE },
              }}
              onClick={(e) => {
                e.stopPropagation();
                edit && edit();
              }}
              data-testid="accordion-header-edit"
            >
              <svg width="12" height="12" viewBox="0 0 20 18">
                <path
                  d="M0,14.2 L0,18 L3.8,18 L14.8,6.9 L11,3.1 L0,14.2 L0,14.2 Z M17.7,4 C18.1,3.6 18.1,3 17.7,2.6 L15.4,0.3 C15,-0.1 14.4,-0.1 14,0.3 L12.2,2.1 L16,5.9 L17.7,4 L17.7,4 Z M9,16 L7,18 L20,18 L20,16 L9,16 L9,16 Z"
                  fill="currentColor"
                />
              </svg>
            </Button>
            {/* <Button
            overrides={{ BaseButton: { style: DUPLICATE_BUTTON_OVERRIDE_STYLE } }}
            onClick={e => {
              e.stopPropagation()
              // duplicate && duplicate()
              setShowDuplicateConfirm(true)
            }}
            data-testid="accordion-header-duplicate"
          >
            Duplicate
          </Button> */}
            <Block onClick={(e) => e.stopPropagation()}>
              <Actions
                onDelete={() => deleteItem && deleteItem()}
                exportTemplate={() => exportTemplate && exportTemplate()}
                setShowDuplicateConfirm={setShowDuplicateConfirm}
              >
                <Button
                  overrides={{
                    BaseButton: { style: DUPLICATE_BUTTON_OVERRIDE_STYLE },
                  }}
                  data-testid="accordion-header-dots"
                >
                  •••
                </Button>
              </Actions>
            </Block>
          </Block>
        ) : null}
      </Block>
      <Suspense fallback={<div />}>
        {showDuplicateConfirm && (
          <Block
            onClick={(e) => e.stopPropagation()}
            onKeyPress={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
            onKeyUp={(e) => e.stopPropagation()}
          >
            <Confirm
              cancel={() => setShowDuplicateConfirm(false)}
              save={onSave}
            />
          </Block>
        )}
      </Suspense>
    </>
  );
};

export default AccordionHeader;
