import React from "react";
import {Document, Page, Text, View, StyleSheet, Font, Image} from "@react-pdf/renderer";
import src1 from "assets/styles/fonts/Manrope-Bold.ttf";
// import src2 from "../assets/styles/fonts/Manrope-Medium.ttf";
import RightCircleIcon from "assets/images/right_circle_tick.png";
import {
    WORK_PROCESS,EXISTING_TITLE,RAPID_CODE,JOURNEY_WORKER,
    CYBER_SECURITY,CODE,CYBER_TEXT,RTI,RTI_DESC,OJT,OJT_DESC,PROFICIENT,PROFICIENT_DESC,COMPLETION_D,COMPLETION_DATE,COMPLETION_DATE_DESC
  } from "resources/constants/strings.js";
  import dayjs from "dayjs";
  import {getCurrentDate} from '../../../utils/date'


Font.register({
  family: "Manrope-Bold",
  src: src1,
  fontStyle: "normal",
  fontWeight: "bold",
});
Font.register({
  family: "Manrope-Medium",
  src: src1,
  fontStyle: "normal",
  fontWeight: "500",
});

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    //paddingBottom:60,
    lineHeight: 1.5,
    flexDirection: "column",
     paddingBottom: "70px",
    height: 'auto',
    overflow: "hidden",
    margin:'20px 0px 0px'
  },
  // box: { width: '100%', marginBottom: 30, borderRadius: 5 },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  titleContainer: {
    // flexDirection: 'row',
    marginTop: 24,
    display: "block",
    width: "100%",
    marginBottom:"15px"
  },
  reportTitle: {
    color: "#000",
    // letterSpacing: "1px",
    fontSize: 14,
    textAlign: "left",
    textTransform: "capitalize",
    display: "block",
    width: "100%",
    fontFamily: "Manrope-Bold",
  },
  reportTitlesub: {
    color: "#000",
    fontSize: 12,
    textAlign: "left",
    textTransform: "capitalize",
    display: "block",
    width: "100%",
    // marginTop: 10,
    fontFamily: "Manrope-Medium",
    paddingTop:"5px"
  },
  reportTitlesubT:{
    color: "#000",
    fontSize: 12,
    textAlign: "left",
    textTransform: "capitalize",
    display: "block",
    width: "100%",
    marginTop: 20,
    fontFamily: "Manrope-Medium",
    paddingTop:"5px"
  },
  subTitle: {
    color: "#000",
    fontSize: 10,
    textAlign: "left",
    display: "block",
    width: "100%",
    paddingTop:"5px"
  },
  subTitleMain: {
    fontSize: 16,
    marginBottom: 10,
    fontFamily: "Manrope-Bold",
  },
  main: {
    fontFamily: "Manrope-Bold",
  },
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 10,
    borderTopWidth: 1,
    borderColor: "#fff",
    //marginTop:"30px"
  },
  row: {
    flexDirection: "row",
    borderBottomColor: "#fff",
    borderBottomWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
    // borderTopWidth: 1,
    alignItems: "center",
    fontStyle: "bold",
    width: "100%",
    display: "flex",
    fontSize: "8px",
    backgroundColor:"#f2f2f2",
    justifyContent:"center",
    borderColor:"#fff"
    //flexGrow:1,
  },
  slno: {
    width: "6%",
   // textAlign: "left",
    borderRight: "1px solid #fff",
    minHeight: "95px",
    height:"95px",
    padding: "5px",
    alignItems:"center",
    justifyContent:"center",
    display:"flex",
    //flexGrow:1
  },
  slnoa: {
    width: "5%",
    textAlign: "left",
    borderRight: "1px solid #fff",
    minHeight: "25px",
    height:"25px",
    padding: "5px",
    backgroundColor:'#dfe9f2',
    alignItems:"center"
  },
  description: {
    width: "50%",
    textAlign: "left",
    borderRight: "1px solid #fff",
    minHeight: "95px",
    height:"95px",
    padding: "5px",
    alignItems:"center",
    display:"flex",
    alignContent:"center",
    overflow: "hidden",
   // flexGrow:1
  },
  description_full:{
    width: "95%",
    textAlign: "left",
   // borderRight: "1px solid #000",
    minHeight: "25px",
    height:"25px",
    padding: "5px",
    backgroundColor:'#dfe9f2',
    alignItems:"center"
  },
  bc: {
    backgroundColor: "#c0d7eb",
    fontSize:"10px",
    //fontWeight:700,
   // fontFamily: 'Manrope-Bold'
  },
  rti: {
    width: "13%",
    borderRight: "1px solid #fff",
    textAlign: "center",
    minHeight: "95px",
    height:"95px",
    padding: "5px",
    alignItems:"center",
    //flexGrow:1
  },
  ojt: {
    width: "16%",
    borderRight: "1px solid #fff",
    textAlign: "center",
    minHeight: "95px",
    height:"95px",
    padding: "5px",
    alignItems:"center",
   // flexGrow:1
  },
  task: {
    width: "15%",
    borderRight: "1px solid #fff",
    textAlign: "center",
    minHeight: "95px",
    height:"95px",
    padding: "5px",
    alignItems:"center",
    //flexGrow:1
  },
  manager: {
    width: "15%",
    borderRight: "1px solid #fff",
    textAlign: "center",
    minHeight: "95px",
    height:"95px",
    padding: "5px",
    alignItems:"center",
    //flexGrow:1
  },
  date: {
    width: "15%",
    textAlign: "center",
    minHeight: "95px",
    height:"95px",
    padding: "5px",
    alignItems:"center",
    //flexGrow:1
  },
  r_slno: {
    width: "6%",
    textAlign: "left",
    borderRight: "1px solid #fff",
    minHeight: "90px",
    padding: "5px",
  },
  r_description: {
    width: "50%",
    textAlign: "left",
    borderRight: "1px solid #fff",
    minHeight: "90px",
    padding: "5px",
  },
  r_rti: {
    width: "13%",
    borderRight: "1px solid #fff",
    textAlign: "center",
    minHeight: "90px",
    padding: "5px",
  },
  r_ojt: {
    width: "16%",
    borderRight: "1px solid #fff",
    textAlign: "center",
    minHeight: "90px",
    padding: "5px",
  },
  r_task: {
    width: "15%",
    borderRight: "1px solid #fff",
    textAlign: "center",
    minHeight: "90px",
    padding: "5px",
  },
  r_manager: {
    width: "15%",
    borderRight: "1px solid #fff",
    textAlign: "center",
    minHeight: "90px",
    padding: "5px",
  },
  r_date: {
    width: "15%",
    textAlign: "center",
    minHeight: "90px",
    padding: "5px",
  },

  logo: {
    width: 10,
    height: 10,
    marginLeft: "auto",
    marginRight: "auto",
  },
  pageNumbers: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    height:30
  },
  fulllength: {
    width:"80%",
    display: "flex",
    flexDirection: "row",
    margin:"0 10%",
    fontSize: 13,
    fontFamily: "Manrope-Bold",
  },
  spaceLeft: {
    width: "50%",
    display: "flex",
    flexDirection: "row",
  },
    body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    //fontFamily: 'Oswald'
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
   // fontFamily: 'Oswald'
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: 'justify',
   // fontFamily: 'Times-Roman'
  },
  image: {
    marginVertical: 30,
    marginHorizontal: 100,
  },
  emphasis: {
    margin: 12,
    fontSize: 24,
    color: '#F22300',
   // fontFamily: 'Oswald'
  },
  breakable:{
    width:"100%",
    height:100
  },
  cyberText:{
    fontFamily:"Manrope-Bold",
    fontSize: 15,
    textTransform:"capitalize",
    color:'#608eeb',
    marginBottom:20
  },
  sub:{
    color:'#608eeb',
    fontFamily:"Manrope-Bold",
    fontSize: 14,
    textTransform:"capitalize",
    marginBottom:10
  },
  container:{
    marginBottom:20,
    paddingTop:5,
    textAlign:"left",
    width:"100%",
    margin:"5px 0px"
  },
  head:{
    color:'#608eeb',
    fontFamily:"Manrope-Bold",
    fontSize: 13,
    textTransform:"capitalize",
    paddingTop:5,
    textAlign:"left",
    width:"100%"
  },
  subhead:{
    fontFamily:"Manrope-Medium",
    fontSize: 12,
    textTransform:"capitalize",
    color:'#000'
  },
  ul:{
    marginTop:10,
    marginLeft:20,
  },
  li:{
    fontFamily:"Manrope-Medium",
    fontSize: 12,
    textTransform:"capitalize",
    color:'#000',
    padding:5
  },
  subsection:{
    paddingTop:10
  }
});


// Create Document Component
const PdfExport = (data) => {
  let uniqueDolList = data?.data?.LearnerChecklist
  // data?.data?.DOLChecklistTemplate?.criteria_list?.filter(
  //   (element, index, array) =>
  //     array.findIndex(
  //       (t) => t.category_description === element.category_description
  //     ) === index
  // );
  const UNABLE_TEXT ="Unable to Assess - Did not get opportunity to validate on the job due to available work"
let count = 0;
let core =0;
let page = 1;
let PSE = "Product Security Test Engineer";

let ACS = "Architecture & Cloud Security";

let ISG = "Classified Information Systems"

let IRTH = "Incident Response & Threat Hunting"

let RMC = "Risk Management and Compliance"

  const filteredData = (data = [], description) => {
    return data.filter(
      (element) => description === element.category_description
    );
  };
  const aa = () =>{
    count = 0;
    return true;
  }
  const bb = () =>{
    core = 0;
    page = 2;
    return true;
  }
  return (
    <Document>
    <Page size="A4" style={styles.page} wrap>
        <View style={styles.titleContainer}>
          <Text style={styles.reportTitle}>{"Cybersecurity Analyst"}</Text>
          <Text style={styles.reportTitlesub}>
          {data.report === "Core Competency" ? "Core Competency Checklists" : data.report === "Core Competency & Role specific Activity" ? "Core Competency and Role Specific Activity Checklists" : "Role Specific Activity Checklists"}
             
          </Text>
          <Text style={styles.subTitle}>
            <Text style={styles.reportTitlesub}>{"Report Date : "}</Text>
            {getCurrentDate()}
          </Text>
          <Text style={styles.subTitle}>
            <Text style={styles.reportTitlesub}>{"Apprentice Name : "}</Text>
            {data?.data?.LastName}{" "}{data?.data?.FirstName}
          </Text>
          <Text style={styles.subTitle}>
            <Text style={styles.reportTitlesub}>{"Job Role : "}</Text>
            {data?.data?.RoleName === "PSE" ? PSE : data?.data?.RoleName === "A&CS" ? ACS : data?.data?.RoleName === "IS&G" ? ISG : data?.data?.RoleName === "IR&TH" ? IRTH : data?.data?.RoleName === "RM&C" ? RMC : data?.data?.RoleName}
          </Text>
          {/* <Text style={styles.subTitle}>
            <Text style={styles.reportTitlesub}>{"Completion Date : "}</Text>
           {getCurrentDate()}
          </Text> */}
        </View>
        {data.report === "Core Competency" || data.report === "Core Competency & Role specific Activity" ?
       <View ><Text style={styles.reportTitlesub}>{"Core Competencies :"}</Text>
        <View style={styles.tableContainer} wrap>
           <View>
              <View style={styles.row} >
                <Text style={[styles.slno, styles.bc]}>{"ID"}</Text>
                <Text style={[styles.description, styles.bc]}>
                  {"Competency"}
                </Text>
                
                <Text style={[styles.ojt, styles.bc]}>{"Related Technical Instruction Signoff"}</Text>
                <Text style={[styles.task, styles.bc]}>{"Apprentice Signoff"}</Text>
                <Text style={[styles.manager, styles.bc]}>{"Program Signoff"}</Text>
                <Text style={[styles.date, styles.bc]}>{"Proficient"}</Text>
            </View>
          {uniqueDolList?.Category?.map((item, index) => (
          
          <View >
          {/* <View>  */}
           <View style={styles.row} key={core++} break={core <= 5 && page === 1 ? (core > 1 && core % 5 === 0 ? bb() : false) :  core % 8 === 0 ? true : false}>
             <Text style={styles.slnoa}>{index+1}</Text>
             <Text style={styles.description_full}>{item.CategoryName}</Text>
            </View>
          
              {item?.Criteria?.map((itemData, indexItem) => (
                <View style={styles.row} key={core++} break={core <= 5  && page === 1 ? (core > 1 && core % 5 === 0 ? bb() : false) :  core % 8 === 0 ? true : false}>
                  <Text style={styles.slno}>{index+1}{(indexItem+10).toString(36)}</Text>
                  <Text style={styles.description}>
                    {itemData?.CriteriaName}
                  </Text>
                  <Text style={styles.ojt}>{ itemData?.RTI?.CompletionDate ? dayjs(itemData?.RTI?.CompletionDate).format('MM-DD-YYYY') : ''}{itemData?.RTI?.CompletionDate ? " / BTAP" : ''}</Text>
                  <Text style={styles.task}>{itemData?.LearnerProficientStatus?.CompletionDate ? dayjs(itemData?.LearnerProficientStatus?.CompletionDate).format('MM-DD-YYYY') : ''}{itemData?.LearnerProficientStatus?.SignerFullName ? " / " + itemData?.LearnerProficientStatus?.SignerFullName : ''}</Text>
                  <Text style={styles.manager}>{itemData?.MentorProficientStatus?.CompletionDate ? dayjs(itemData?.MentorProficientStatus?.CompletionDate).format('MM-DD-YYYY') : ''}{itemData?.MentorProficientStatus?.ChangeStatusText === UNABLE_TEXT ?  " / BTAP" : (itemData?.MentorProficientStatus?.SignerFullName ? " / " + itemData?.MentorProficientStatus?.SignerFullName : '')}
                  {itemData?.MentorProficientStatus?.CompletionDate && itemData?.FinalApprovalStatus?.CompletionDate ? "\n\n" : ''}
                  {itemData?.FinalApprovalStatus?.CompletionDate ? dayjs(itemData?.FinalApprovalStatus?.CompletionDate).format('MM-DD-YYYY') : ''}{itemData?.FinalApprovalStatus?.SignerFullName ? " / " + (itemData?.FinalApprovalStatus?.SignerFullName  === "Linkforce System" ? "BTAP" : itemData?.FinalApprovalStatus?.SignerFullName) : ''}</Text>
                  <Text style={styles.date}>{itemData?.FinalApprovalStatus?.CompletionStatus  ? <Image style={styles.logo} src={RightCircleIcon} /> : ""}</Text>
                </View>
              ))}
            
           </View>
          ))}
          </View>
        </View> </View> : null}
       {data.report === "Role Specific Activity" || data.report === "Core Competency & Role specific Activity" ?
       <View  break={data.report === "Core Competency & Role specific Activity" ? true : false}><Text style={styles.reportTitlesubT}>{"Role Specific Activities :"}</Text>
        <View style={styles.tableContainer} wrap>
           <View >
              <View style={styles.row} >
                <Text style={[styles.r_slno, styles.bc]}>{"ID"}</Text>
                <Text style={[styles.r_description, styles.bc]}>
                  {"Activity"}
                </Text>
                
                {/* <Text style={[styles.ojt, styles.bc]}>{"Related Technical Instruction Signoff"}</Text> */}
                <Text style={[styles.r_task, styles.bc]}>{"Apprentice Signoff"}</Text>
                <Text style={[styles.r_manager, styles.bc]}>{"Program Signoff"}</Text>
                <Text style={[styles.r_date, styles.bc]}>{"Proficient"}</Text>
              </View>
              {uniqueDolList?.RoleSpecificActivities?.map((item, index) => (
              <View style={styles.row} key={count++}  break={index < 6 ? (count > 1 && count % 5 === 0 ? aa() : false) :  count % 7 === 0 ? true : false}>
                <Text style={styles.r_slno}>{index+1}</Text>
                <Text style={styles.r_description}>
                  {item?.TaskName}
                </Text>
                {/* <Text style={styles.ojt}>{ item?.RTI?.CompletionDate ? dayjs(item?.RTI?.CompletionDate).format('YYYY-MM-DD') : ''}</Text> */}
                <Text style={styles.r_task}>{item?.LearnerProficientStatus?.CompletionDate ? dayjs(item?.LearnerProficientStatus?.CompletionDate).format('MM-DD-YYYY') : ''}{item?.LearnerProficientStatus?.SignerFullName ? " / " + item?.LearnerProficientStatus?.SignerFullName : ''}</Text>
                {/* <Text style={styles.r_manager}>{(item?.FinalApprovalStatus?.CompletionStatus && item?.FinalApprovalStatus?.CompletionDate) ? dayjs(item?.FinalApprovalStatus?.CompletionDate).format('YYYY-MM-DD') : ''}{(item?.FinalApprovalStatus?.CompletionStatus && item?.FinalApprovalStatus?.SignerFullName) ? " / " + (item?.FinalApprovalStatus?.SignerFullName === "Linkforce System" ? "BTAP" : item?.FinalApprovalStatus?.SignerFullName) : ''}</Text> */}

                <Text style={styles.r_manager}>{item?.MentorProficientStatus?.CompletionDate ? dayjs(item?.MentorProficientStatus?.CompletionDate).format('MM-DD-YYYY') : ''}{item?.MentorProficientStatus?.ChangeStatusText === UNABLE_TEXT ?  " / BTAP" : (item?.MentorProficientStatus?.SignerFullName ? " / " + item?.MentorProficientStatus?.SignerFullName : '')}
                  {item?.MentorProficientStatus?.CompletionDate && item?.FinalApprovalStatus?.CompletionDate ? "\n\n" : ''}
                  {item?.FinalApprovalStatus?.CompletionDate ? dayjs(item?.FinalApprovalStatus?.CompletionDate).format('MM-DD-YYYY') : ''}{item?.FinalApprovalStatus?.SignerFullName ? " / " + (item?.FinalApprovalStatus?.SignerFullName  === "Linkforce System" ? "BTAP" : item?.FinalApprovalStatus?.SignerFullName) : ''}</Text>   
                <Text style={styles.r_date}>{item?.FinalApprovalStatus?.CompletionStatus  ? <Image style={styles.logo} src={RightCircleIcon} /> : ""}</Text>
              </View>
              ))}
            
            </View>
          </View>
        </View> : null }
        <View  break>
        <View style={styles.tableContainer} wrap>
              <Text style={styles.cyberText}>{"Cybersecurity Analyst Required Technical Training"}</Text>
              <Text style={styles.sub}>{"Cyber Analyst Foundational"}</Text>
              {/* <View style={styles.container}> */}
                <Text  style={styles.head}>{"Core Cybersecurity Fundamentals"}</Text>
                <Text style={styles.subhead}>{"IS100B Cybersecurity Fundamentals"}</Text>
                <View style={styles.ul}>
                  <Text style={styles.li}>{"• Introduction to Cybersecurity"}</Text>
                  <Text style={styles.li}>{"• Virtualization and Containers"}</Text>
                  <Text style={styles.li}>{"• Vulnerability Analysis and Threat Profiling"}</Text>
                  <Text style={styles.li}>{"• Introduction to Scripting"}</Text>
                  <Text style={styles.li}>{"• Introduction to Security Analysis"}</Text>
                </View>
              {/* </View> */}
              <View>
                <Text style={[styles.subhead,styles.subsection]}>{"OS100B OS Fundamentals - Linux and Windows"}</Text>
                <View  style={styles.ul}>
                  <Text style={styles.li}>{"• Linux Fundamentals"}</Text>
                  <Text style={styles.li}>{"• Linux Fundamentals 2"}</Text>
                  <Text style={styles.li}>{"• Windows Fundamentals"}</Text>
                  <Text style={styles.li}>{"• Windows Fundamentals 2"}</Text>
                  <Text style={styles.li}>{"• Azure Cloud Fundamentals"}</Text>
                </View>
              </View>
              <View>
                <Text style={[styles.subhead,styles.subsection]}>{"NET100B Networking Fundamentals"}</Text>
                <View  style={styles.ul}>
                  <Text style={styles.li}>{"• Networking Fundamentals 1"}</Text>
                  <Text style={styles.li}>{"• Networking Fundamentals 2"}</Text>
                  <Text style={styles.li}>{"• Network Firewalls and Cloud Networking"}</Text>
                  <Text style={styles.li}>{"• Network Defenses and Troubleshooting"}</Text>
                  <Text style={styles.li}>{"• Building out a Network"}</Text>
                </View>
              </View>
              <View style={styles.container}>
                <Text  style={styles.head}>{"Operational Fundamentals"}</Text>
                <Text style={styles.subhead}>{"TM100B Threat Intel and Management"}</Text>
                <View style={styles.ul}>
                  <Text style={styles.li}>{"• OSINT and Threat Intelligence"}</Text>
                  <Text style={styles.li}>{"• Introduction to Threat Hunting"}</Text>
                  <Text style={styles.li}>{"• Cyber Kill Chain and Attack Lifecycle"}</Text>
                  <Text style={styles.li}>{"• Detecting Threats"}</Text>
                  <Text style={styles.li}>{"• Threat Management Capstone Exercise"}</Text>
                </View>
              </View>
              <View>
                <Text style={[styles.subhead,styles.subsection]}>{"IA100B Identity and Access Management"}</Text>
                <View  style={styles.ul}>
                  <Text style={styles.li}>{"• Cryptography"}</Text>
                  <Text style={styles.li}>{"• Cryptography"}</Text>
                  <Text style={styles.li}>{"• IAM Fundamentals"}</Text>
                  <Text style={styles.li}>{"• IAM Threat Vectors and Attacks"}</Text>
                  <Text style={styles.li}>{"• IAM Best Practices and Defenses"}</Text>
                </View>
              </View>
              <View>
                <Text style={[styles.subhead,styles.subsection]}>{"VF100B Vulnerability and Patch Management Fundamentals"}</Text>
                <View  style={styles.ul}>
                  <Text style={styles.li}>{"• Vulnerability Scanning"}</Text>
                  <Text style={styles.li}>{"• Patch Management"}</Text>
                  <Text style={styles.li}>{"• Exploiting Vulnerabilities"}</Text>
                  <Text style={styles.li}>{"• Cloud Security 1"}</Text>
                  <Text style={styles.li}>{"• Cloud Security 2"}</Text>
                </View>
              </View>
              <View>
                <Text style={[styles.subhead,styles.subsection]}>{"ML100B Monitoring and Logging Fundamentals"}</Text>
                <View  style={styles.ul}>
                  <Text style={styles.li}>{"• Network Traffic Packet Analysis"}</Text>
                  <Text style={styles.li}>{"• Windows and Linux Log Fundamentals"}</Text>
                  <Text style={styles.li}>{"• SIEM in Practice"}</Text>
                </View>
              </View>
              <View>
                <Text style={[styles.subhead,styles.subsection]}>{"IR100B Incident Response"}</Text>
                <View  style={styles.ul}>
                  <Text style={styles.li}>{"• Incident Response Fundamentals"}</Text>
                  <Text style={styles.li}>{"• Introduction to Malware"}</Text>
                  <Text style={styles.li}>{"• Incident Response Best Practices"}</Text>
                </View>
              </View>
              <View style={styles.container} break>
                <Text  style={styles.head}>{"Secure Systems Fundamentals"}</Text>
                <Text style={styles.subhead}>{"AS100B Application Security"}</Text>
                <View style={styles.ul}>
                <Text style={styles.li}>{"• Application Development 1"}</Text>
                  <Text style={styles.li}>{"• Application Development 2"}</Text>
                  <Text style={styles.li}>{"• DevSecOps"}</Text>
                  <Text style={styles.li}>{"• OWASP Security Standards"}</Text>
                  <Text style={styles.li}>{"• Application Security Best Practices"}</Text>
                </View>
              </View>
              <View>
                <Text style={styles.subhead}>{"RA100B Risk Analysis and Assessment"}</Text>
                <View style={styles.ul}>
                  <Text style={styles.li}>{"• Risk Frameworks"}</Text>
                  <Text style={styles.li}>{"• Risk Governance"}</Text>
                  <Text style={styles.li}>{"• Compliance Standards"}</Text>
                </View>
              </View>
              <View style={styles.container}>
                <Text style={styles.subhead}>{"SA100B Secure System Analysis"}</Text>
                <View style={styles.ul}>
                  <Text style={styles.li}>{"• Reverse Engineering"}</Text>
                  <Text style={styles.li}>{"• Malware Analysis"}</Text>
                  <Text style={styles.li}>{"• Secure System Analysis 1"}</Text>
                </View>
              </View>
              <View style={styles.container}>
                <Text  style={styles.head}>{"Capstone"}</Text>
                <Text style={styles.subhead}>{"Cybersecurity Aerospace Capstone"}</Text>
                <View style={styles.ul}>
                  <Text style={styles.li}>{"• Cybersecurity Aerospace Individual Capstone Project"}</Text>
                  <Text style={styles.li}>{"• Cybersecurity Aerospace Group Capstone Project Score"}</Text>
                </View>
              </View>
              </View>
              <View style={styles.container}>
              <Text style={[styles.sub, {marginTop:"10px",marginBottom:"5px"}]}>{"Cybersecurity Analyst"}</Text>
              <View style={styles.container}>
                <Text  style={styles.head}>{"Securing, Monitor & Logging"}</Text>
                <Text style={styles.subhead}>{"TR201B Adv Secure Network/IAT Certification"}</Text>
                <View style={styles.ul}>
                  <Text style={styles.li}>{"• Secure Wired and Wireless Network Architecture"}</Text>
                  <Text style={styles.li}>{"• Secure Csubloud Architecture and Design"}</Text>
                  <Text style={styles.li}>{"• Cryptography and Encryption"}</Text>
                  <Text style={styles.li}>{"• Identity and Access Controls"}</Text>
                  <Text style={styles.li}>{"• Governance, Risk & Compliance"}</Text>
                  <Text style={styles.li}>{"• Threats and Vulnerability Management "}</Text>
                  <Text style={styles.li}>{"• Firewall and Security Devices"}</Text>
                  <Text style={styles.li}>{"• SOAR Configuration and Implementation"}</Text>
                  <Text style={styles.li}>{"• SIEM in Practice"}</Text>
                  <Text style={styles.li}>{"• Advanced Threat Detection"}</Text>
                </View>
              </View>
              <View style={styles.container}>
                <Text  style={styles.head}>{"Advanced Incident Response"}</Text>
                <Text style={styles.subhead}>{"TR202B Track 2 Incident Responder"}</Text>
                <View style={styles.ul}>
                  <Text style={styles.li}>{"• Advanced Digital Forensics"}</Text>
                  <Text style={styles.li}>{"• Practice Incident Handling"}</Text>
                  <Text style={styles.li}>{"• Automated Incident Response"}</Text>
                  <Text style={styles.li}>{"• SOC 3.0 Operations & Analytics"}</Text>
                </View>
              </View>
              <View style={styles.container}>
                <Text  style={styles.head}>{"Cloud Security Analyst"}</Text>
                <Text style={styles.subhead}>{"TR202B Track 2 Cloud Security Analyst"}</Text>
                <View style={styles.ul}>
                  <Text style={styles.li}>{"• Cloud security principals"}</Text>
                  <Text style={styles.li}>{"• Azure security best practice"}</Text>
                  <Text style={styles.li}>{"• AWS Security & Compliance "}</Text>
                  <Text style={styles.li}>{"• AWS Security Best Practices"}</Text>
                  <Text style={styles.li}>{"• Cloud security auditing and event management"}</Text>
                </View>
              </View>
              <View style={styles.container}>
                <Text  style={styles.head}>{"Operations and Secure Systems"}</Text>
                <Text style={styles.subhead}>{"TR203B Track 3 Security Operations Analyst and Advanced Secure Systems Analysis"}</Text>
                <View style={styles.ul}>
                  <Text style={styles.li}>{"• Vulnerability Scanning"}</Text>
                  <Text style={styles.li}>{"• Auditing and Reporting"}</Text>
                  <Text style={styles.li}>{"• Security monitoring tools and controls "}</Text>
                  <Text style={styles.li}>{"• Advanced Secure System Analysis"}</Text>
                  <Text style={styles.li}>{"• Advanced System Analysis Exercises"}</Text>
                </View>
              </View>
              <View style={styles.container}>
                <Text  style={styles.head}>{"Risk Analysis & Management"}</Text>
                <Text style={styles.subhead}>{"TR204B Track 4 Risk Mgmt. Professional"}</Text>
                <View style={styles.ul}>
                  <Text style={styles.li}>{"• Advanced Risk Analysis"}</Text>
                  <Text style={styles.li}>{"• Risk Assessments and Frameworks"}</Text>
                  <Text style={styles.li}>{"• Advanced Risk Exercises"}</Text>
                 
                </View>
              </View>
              <View style={styles.container}>
                <Text  style={styles.head}>{"Network Pen Tester"}</Text>
                <Text style={styles.subhead}>{"TR204B Track 4 Network Pen Tester"}</Text>
                <View style={styles.ul}>
                  <Text style={styles.li}>{"• Network Penetration Tester"}</Text>
                  <Text style={styles.li}>{"• Practical Network Pen Testing"}</Text>
                </View>
              </View>
        </View>
        </View>
        <Text style={styles.pageNumbers} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed />
      </Page>
    </Document>
  );
};

export default PdfExport;
