import { DOL_GET_CHECKLIST } from "../apiEndpoint";
import { studentAxios } from "../http/http";

export default function dolChecklistGetData(Type, role, learnerEmail="",Useremail = "") {
  if (Type === "All") {
    return studentAxios.get(DOL_GET_CHECKLIST +"?user_email=" + Useremail +"&role="+ role + "&learner_email="+ learnerEmail, {
      headers: {
        ApiKey: process.env.REACT_APP_STUDENT_GO_APIKEY,
      },
    });
  } else {
    return studentAxios.get(DOL_GET_CHECKLIST + "?user_email=" + Useremail +"&role="+ role +"&learner_email="+ learnerEmail, {
      headers: {
        ApiKey: process.env.REACT_APP_STUDENT_GO_APIKEY,
      },
    });
  }
}
