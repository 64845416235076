import { Block } from "baseui/block";
import { useRef, useState, useEffect, useMemo } from "react";
import {
  XAxis,
  YAxis,
  FlexibleWidthXYPlot,
  AbstractSeries,
  LineSeries,
  Hint,
} from "react-vis";
import { useStyletron } from "baseui";
import {
  TILE_STYLE,
  TITLE_STYLE,
  HINT_STYLE,
  HINT_TEXT_STYLE,
  VISUALIZATION_HEIGHT,
  SELECT_OVERRIDE_STYLE,
  MOBILE_SCREEN_BREAKPOINT,
} from "../utils";
import { Learner_Kahoot } from "resources/constants/strings";
import { std } from "mathjs";
import { Skeleton } from "baseui/skeleton";
import { useTrackedState } from "store/store";
import { Select, SIZE } from "baseui/select";

class CandleStickItem extends AbstractSeries {
  render() {
    const { className, data, marginLeft, marginTop, onMouseOver, onMouseOut } =
      this.props;
    if (!data) {
      return null;
    }

    const xFunctor = this._getAttributeFunctor("x");
    const yFunctor = this._getAttributeFunctor("y");
    const strokeFunctor =
      this._getAttributeFunctor("stroke") || this._getAttributeFunctor("color");
    const fillFunctor =
      this._getAttributeFunctor("fill") || this._getAttributeFunctor("color");
    const opacityFunctor = this._getAttributeFunctor("opacity");

    const distance = Math.abs(xFunctor(data[1]) - xFunctor(data[0])) * 0.2;

    return (
      <g
        className={`${className}`}
        transform={`translate(${marginLeft},${marginTop})`}
      >
        {data.map((d, i) => {
          const xTrans = xFunctor(d);
          const yHigh = yFunctor({ ...d, y: d.yHigh });
          const yOpen = yFunctor({ ...d, y: d.yOpen });
          const yClose = yFunctor({ ...d, y: d.yClose });
          const yLow = yFunctor({ ...d, y: d.yLow });

          const lineAttrs = {
            stroke: strokeFunctor && strokeFunctor(d),
          };

          const xWidth = distance * 2;
          return (
            <g
              transform={`translate(${xTrans})`}
              opacity={opacityFunctor ? opacityFunctor(d) : 1}
              key={i}
              onClick={(e) => this._valueClickHandler(d, e)}
              onMouseOver={(e) => {
                this._valueMouseOverHandler(d, e);
                onMouseOver && onMouseOver(d);
              }}
              onMouseOut={(e) => {
                this._valueMouseOutHandler(d, e);
                onMouseOut && onMouseOut(d);
              }}
            >
              <line
                x1={-xWidth}
                x2={xWidth}
                y1={yHigh}
                y2={yHigh}
                {...lineAttrs}
              />
              <line x1={0} x2={0} y1={yHigh} y2={yLow} {...lineAttrs} />
              <line
                x1={-xWidth}
                x2={xWidth}
                y1={yLow}
                y2={yLow}
                {...lineAttrs}
              />
              <rect
                x={-xWidth}
                width={Math.max(xWidth * 2, 0)}
                y={yOpen}
                height={Math.abs(yOpen - yClose)}
                fill={fillFunctor && fillFunctor(d)}
              />
            </g>
          );
        })}
      </g>
    );
  }
}

const MACRO_OPTIONS = [{ label: "All Macro", id: "All Macro" }];
const TYPE_OPTIONS = [
  { label: "Tests", id: "Tests" },
  { label: "Users", id: "Users" },
  { label: "Certifications", id: "Certifications" },
];

const LearnerKahootCandleStick = () => {
  const [value, setValue] = useState(false);
  const [width, setWidth] = useState(0);
  const [macro, setMacro] = useState([MACRO_OPTIONS[0]]);
  const [type, setType] = useState([TYPE_OPTIONS[0]]);

  const state = useTrackedState();

  const [css, theme] = useStyletron();
  const parent = useRef();

  const isMobileScreen = width < MOBILE_SCREEN_BREAKPOINT;
  const height = isMobileScreen
    ? VISUALIZATION_HEIGHT
    : VISUALIZATION_HEIGHT * 1.5;

  const macroList = useMemo(() => {
    if (!state.learners) return null;

    const list = {};

    const filteredLearners = state.learners.filter(
      (learner) => learner.last_name.toLowerCase() !== "learner"
    );

    filteredLearners.forEach((learner) => {
      const phases = learner.learning_path.phase_list;

      phases.forEach((phase) => {
        const macros = phase.macro_certification_list;

        macros.forEach((macro) => {
          const name = macro.macro_certification_name;
          const macroCourses = macro.course_list.map(
            (course) => course.course_name.split(" ")[1]
          );

          if (!list[name]) list[name] = [];

          list[name] = [...new Set([...list[name], ...macroCourses])];
        });
      });
    });

    return list;
  }, [state.learners]);

  const kahootDataByMacro = useMemo(() => {
    const kahootData = state.kahootScore || [];

    const base = { "All Macro": kahootData };
    if (!macroList) return base;

    return Object.keys(macroList).reduce((acc, key) => {
      const courses = macroList[key];
      const filteredLesson = kahootData.filter((lesson) =>
        courses.includes(lesson.coursename.split(" ")[1])
      );

      if (filteredLesson.length) acc[key] = filteredLesson;

      return acc;
    }, base);
  }, [macroList, state.kahootScore]);

  const macroOptions = useMemo(
    () =>
      Object.keys(kahootDataByMacro).map((key) => ({ label: key, id: key })),
    [kahootDataByMacro]
  );

  const lessonShortNames = kahootDataByMacro[macro[0].id].map(
    (lesson) => lesson.lessonnameshort
  );

  const ids = kahootDataByMacro[macro[0].id].reduce((acc, lesson) => {
    const { userscores } = lesson;

    userscores.forEach((score) => {
      const { emailid } = score;

      const slicedEmailId =
        emailid <= 11 ? emailid : emailid.slice(0, 10).concat("..");

      if (!acc.includes(slicedEmailId)) acc.push(slicedEmailId);
    });

    return acc.sort();
  }, []);

  const certifications = Object.keys(kahootDataByMacro).filter(key => key !== 'All Macro')

  const getIndividualData = (scores = [], additionalData, index) => {
    scores = scores.filter(score => score >= 5);
    const maxScore = Math.max(...scores);
    const minScore = Math.min(...scores);
    const sum = scores.reduce((acc, score) => acc + score, 0);
    const mean = Number((sum / scores.length).toFixed(2));
    const stdev = Number(std(...scores).toFixed(2));

    const open = Number((mean + stdev).toFixed(2));
    const close = Number((mean - stdev).toFixed(2));

    return {
      x: index,
      y: mean,
      yHigh: maxScore,
      yLow: minScore,
      yOpen: open > maxScore ? maxScore : open,
      yClose: close < minScore ? minScore : close,
      color: mean < 50 ? "rgb(0, 99, 255, 0.3)" : "rgb(0, 99, 255)",
      opacity: mean > 75 ? 0.7 : 1,
      ...additionalData,
    };
  };

  const kahootDataByTests = useMemo(() => {
    const lessons = kahootDataByMacro[macro[0].id];

    return lessons.map((lesson, i) => {
      const { userscores, ...rest } = lesson;
      const calculatedScores = userscores.map((score) => score.score);

      return getIndividualData(calculatedScores, rest, i);
    });
  }, [kahootDataByMacro, macro]);

  const kahootDataByUsers = useMemo(() => {
    const lessons = kahootDataByMacro[macro[0].id];
    let scoreByUsers = {};

    lessons.forEach((lesson) => {
      const { userscores } = lesson;

      userscores.forEach((score) => {
        const { emailid } = score;

        if (!scoreByUsers[emailid]) scoreByUsers[emailid] = [];

        const { score: s } = score;
        scoreByUsers[emailid].push(s);
      });
    });

    return Object.keys(scoreByUsers)
      .sort()
      .map((key, i) => getIndividualData(scoreByUsers[key], { email: key }, i));
  }, [kahootDataByMacro, macro]);

  const kahootDataByCertifications = useMemo(() => {
    const certifications = Object.keys(kahootDataByMacro).filter(key => key !== 'All Macro')
    let scoreByCertification = {}

    certifications.forEach((certification, i) => {
      const lessons = kahootDataByMacro[certification]
      
      lessons.forEach((lesson) => {
        const { userscores } = lesson;
  
        userscores.forEach((score) => {
          if (!scoreByCertification[certification]) scoreByCertification[certification] = []

          const { score: s } = score;
  
          scoreByCertification[certification].push(s);
        });
      });
    })

    return Object.keys(scoreByCertification)
      .map((cert, i) => getIndividualData(scoreByCertification[cert], { cert }, i));
  }, [kahootDataByMacro])

  const dataByType = {
    Tests: kahootDataByTests,
    Users: kahootDataByUsers,
    Certifications: kahootDataByCertifications,
  }

  const tickDataByType = {
    Tests: lessonShortNames,
    Users: ids,
    Certifications: certifications,
  }

  const candleStickData = dataByType[type[0].id] || kahootDataByTests
  const tickData = tickDataByType[type[0].id] || lessonShortNames;

  useEffect(() => {
    if (parent) setWidth(parent.current.clientWidth);
  }, []);

  return (
    <Block className={css(TILE_STYLE(theme))}>
      <Block
        display="flex"
        justifyContent="space-between"
        flexDirection={["column", "column", "row"]}
        marginBottom="20px"
      >
        <Block className={css({ ...TITLE_STYLE, marginBottom: 0 })} data-testid="learner-kahoot">
          {Learner_Kahoot}
        </Block>
        <Block display="flex" gridGap="20px">
          <Select
            size={SIZE.compact}
            clearable={false}
            searchable={false}
            options={TYPE_OPTIONS}
            data-testid="select-kahoot"
            value={type}
            onChange={(params) => setType(params.value)}
            overrides={{
              Root: { style: { width: "200px", backgroundColor: "white" } },
              ControlContainer: {
                style: SELECT_OVERRIDE_STYLE.ControlContainer,
              },
            }}
          />
          <Select
            size={SIZE.compact}
            clearable={false}
            searchable={false}
            data-testid="select-certification"
            options={[...macroOptions]}
            value={macro}
            disabled={type[0].id === 'Certifications'}
            onChange={(params) => setMacro(params.value)}
            overrides={{
              Root: { style: { width: "200px", backgroundColor: "white" } },
              ControlContainer: {
                style: SELECT_OVERRIDE_STYLE.ControlContainer,
              },
            }}
          />
        </Block>
      </Block>
      <Block
        ref={parent}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gridGap="20px"
        className="candle-stick"
      >
        {state.isFetching || state.isFetchingKahoot ? (
          <Skeleton width={`${width}px`} height={`${height}px`} />
        ) : (
          <Block className={css({ width: "100%" })}>
            <Block className={css({ width: "100%" })}>
              <FlexibleWidthXYPlot
                yDomain={[0, 110]}
                xDomain={[-1, candleStickData.length]}
                height={height}
                margin={{ bottom: isMobileScreen ? 0 : 80 }}
              >
                {true && (
                  <XAxis
                    tickFormat={(v) => tickData[v] || ""}
                    tickLabelAngle={type[0].id === 'Certifications' ? 0 : -90}
                    tickTotal={tickData.length + 1}
                    style={{ text: { textAnchor: type[0].id === 'Certifications' ? 'middle' : "end" } }}
                  />
                )}
                <YAxis />
                <LineSeries color="#0c953a" data={candleStickData} />
                <CandleStickItem
                  colorType="literal"
                  opacityType="literal"
                  stroke="#A3A3A3"
                  data={candleStickData}
                  onMouseOver={(d) => setValue(d)}
                  onMouseOut={() => setValue(false)}
                />
                {value && (
                  <Hint value={value}>
                    <Block className={css(HINT_STYLE)}>
                      {value.coursename && (
                        <Block className={css(HINT_TEXT_STYLE)}>
                          Course: {value.coursename}
                        </Block>
                      )}
                      {value.lessonname && (
                        <Block className={css(HINT_TEXT_STYLE)}>
                          Lesson: {value.lessonname}
                        </Block>
                      )}
                      {value.quizname && (
                        <Block className={css(HINT_TEXT_STYLE)}>
                          Quiz: {value.quizname}
                        </Block>
                      )}
                      {value.quizsubject && (
                        <Block className={css(HINT_TEXT_STYLE)}>
                          Subject: {value.quizsubject}
                        </Block>
                      )}
                      {value.email && (
                        <Block className={css(HINT_TEXT_STYLE)}>
                          Learner: {value.email}
                        </Block>
                      )}
                      <Block className={css(HINT_TEXT_STYLE)}>
                        High Score: {value.yHigh}
                      </Block>
                      <Block className={css(HINT_TEXT_STYLE)}>
                        Low Score: {value.yLow}
                      </Block>
                      <Block className={css(HINT_TEXT_STYLE)}>
                        Open Score: {value.yOpen}
                      </Block>
                      <Block className={css(HINT_TEXT_STYLE)}>
                        Close Score: {value.yClose}
                      </Block>
                    </Block>
                  </Hint>
                )}
              </FlexibleWidthXYPlot>
            </Block>
          </Block>
        )}
      </Block>
    </Block>
  );
};

export default LearnerKahootCandleStick;
