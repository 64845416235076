import React, { useState, useEffect } from "react";
import { Block } from "baseui/block";
import { useStyletron } from "baseui";
import {
  Track,
  Progress,
  Behind,
  Exceeds,
} from "resources/constants/strings.js";
import { useSetState } from "store/store";
import { useHistory } from "react-router-dom";
import { COMMON_CARD_STYLE } from "utils/style";
import ProgressBar from "components/progress";
import { Accordion, Panel } from "baseui/accordion";
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic";
import {
  COMMON_RADIUS,
  COMMON_BORDER_COLOR,
  COMMON_BORDER_WIDTH,
  COMMON_BORDER_STYLE,
  COMMON_PADDING,
} from "utils/style";
import Lesson from "../../../assets/images/js-lessons-icon.png";
import Badge from "../../../assets/badges/badge-axlp.svg";
// import PhaseBadge from "./badges/phaseBadge";
// import MicroBadge from "./badges/microBadge";
// import MacroBadge from "./badges/macroBadge";
// import BadgeModal from './badges/badgeModal'
import ToggleIcon from "components/toggleIcon";
import { Skeleton } from "baseui/skeleton";
import Chart from "./newChart";
import { StatefulPopover, TRIGGER_TYPE } from "baseui/popover";

const HEADING_STYLE = {
  fontSize: "18px",
  lineHeight: "25px",
  fontFamily: "Manrope-Bold",
  marginBottom: "24px",
};

const PHASE_ACCORDION_HEADER_STYLE = {
  ...COMMON_PADDING("0px"),
  height: "54px",
  fontFamily: "Manrope-Bold",
  fontSize: "16px",
  lineHeight: "22px",
  // background: '#efefef',
  // padding:'0 0 0 10px'
};
const PHASE_ACCORDION_CONT_STYLE = {
  //...COMMON_PADDING("0px"),
  height: "54px",
  fontFamily: "Manrope-Bold",
  fontSize: "16px",
  lineHeight: "22px",
  background: "rgb(245,245,245)",
  padding: "0 20px 0 10px",
  borderBottom: "1px solid rgb(226, 226, 226)",
  borderRadius: "8px",
};

const PHASE_ACCORDION_CONTENT_STYLE = ({ $theme }) => ({
  paddingLeft: "20px",
  paddingRight: "0px",
  paddingTop: "12px",

  [$theme.mediaQuery.small]: {
    paddingTop: "16px",
    paddingBottom: "16px",
    paddingLeft: "0",
  },
  [$theme.mediaQuery.medium]: {
    paddingTop: "32px",
    paddingBottom: "24px",
  },
  [$theme.mediaQuery.large]: {
    paddingLeft: "20px",
    paddingTop: "12px",
  },
});

const PROGRESS_SECTION = (theme) => ({
  [theme.mediaQuery.small]: {
    width: "70%",
  },
  [theme.mediaQuery.large]: {
    width: "128px",
    marginBottom: "8px",
  },
});

const TRACK_ACCORDION_HEADER_STYLE = {
  ...COMMON_PADDING("0px"),
  height: "54px",
  paddingRight: "20px",
};

const TRACK_ACCORDION_CONTENT_STYLE = ({ $theme }) => ({
  paddingLeft: "50px",
  paddingRight: "0px",

  [$theme.mediaQuery.small]: {
    paddingTop: "16px",
    paddingBottom: "16px",
  },
  [$theme.mediaQuery.medium]: {
    paddingTop: "20px",
    paddingBottom: "24px",
  },
});

const TRACK_TITLE_STYLE = (theme) => ({
  fontFamily: "Manrope-SemiBold",
  fontSize: "16px",
  lineHeight: "22px",
  color: theme?.RAColors?.blue700,
});

const TABLE_ROOT_OVERRIDE_STYLE = ({ $theme }) => ({
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_COLOR($theme.RAColors?.gray200),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
});

const TABLE_HEADER_STYLE = (theme) => ({
  fontFamily: "Manrope-SemiBold",
  fontSize: "14px",
  lineHeight: "19px",
  [theme.mediaQuery.small]: {
    textAlign: "center",
  },
  [theme.mediaQuery.large]: {
    textAlign: "left",
  },
});

const TRACK_ITEM_TITLE_STYLE = {
  fontFamily: "Manrope-Medium",
  fontSize: "14px",
  lineHeight: "19px",
  display:"inline"
};
const TRACK_ITEM_TITLE_STYLE_RADAR = {
  fontFamily: "Manrope-Medium",
  fontSize: "14px",
  lineHeight: "19px",
  // display:"inline"
};


const TRACK_ITEM_CONTENT_STYLE = {
  fontFamily: "Manrope",
  fontSize: "24px",
  lineHeight: "32px",
  width:'90px'
};
const TRACK_ITEM_CONTENT_STYLE_PROGRESS= {
  fontFamily: "Manrope",
  fontSize: "20px",
  lineHeight: "32px",
  width:'73px'
};

const TRACK_ITEM_CONTENT_THME = {
  fontFamily: "Manrope",
  fontSize: "14px",
  lineHeight: "21px",
 
};

const TRACK_ITEM_CONTENT_STYLE_TAG = {
  fontFamily: "Manrope",
  fontSize: "16px",
  lineHeight: "32px",
};

const MODULE_NAME_STYLE = (theme) => ({
  cursor: "pointer",
  color: theme?.RAColors?.blue700,
  fontFamily: "Manrope-Medium",
  fontSize: "14px",
  lineHeight: "19px",
});

const FIRST_ROW_OVERRIDE_STYLE = ({ $theme }) => ({
  minWidth: "400px",
  [$theme.mediaQuery.small]: {
    display: "grid",
    ...COMMON_PADDING("10px"),
    boxSizing: "border-box",
    border: "none",
    margin: "0",
    minWidth: "auto",
  },
  [$theme.mediaQuery.large]: {
    minWidth: "400px",
    display: "table-cell",
    ...COMMON_PADDING("16px"),
    boxSizing: "inherit",
    borderBottomColor: "rgba(0, 0, 0, 0.12)",
    margin: "0",
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
  },
});

const SECOND_ROW_OVERRIDE_STYLE = ({ $theme }) => ({
  borderLeftWidth: "1px",
  borderLeftStyle: "solid",
  borderLeftColor: $theme?.RAColors?.gray200,
  width: "300px",
  minWidth: "200px",
  [$theme.mediaQuery.small]: {
    display: "grid",
    ...COMMON_PADDING("10px"),
    boxSizing: "border-box",
    border: "none",
    borderBottom: "1px solid rgba(0,0,0,0.2)",
    minWidth: "auto",
    width: "auto",
    margin: "0 10px 20px 10px",
    paddingBottom: "20px",
  },
  [$theme.mediaQuery.large]: {
    display: "table-cell",
    borderLeftWidth: "1px",
    borderLeftStyle: "solid",
    borderLeftColor: $theme?.RAColors?.gray200,
    width: "300px",
    minWidth: "200px",
    ...COMMON_PADDING("16px"),
    borderBottomColor: "rgba(0, 0, 0, 0.12)",
    margin: "auto",
    paddingBottom: "auto",
  },
});

const JS_HEADING = ({ $theme }) => ({
  display: "inline",
  width: "30%",
  fontFamily: "Manrope-Bold",
 // paddingRight: "15px",
});
const JS_HEADING_EMAIL = ({ $theme }) => ({
  display: "inline",
  width: "40%",
  fontFamily: "Manrope-Bold",
 // paddingRight: "15px",
});

const JS_CONT = ({ $theme }) => ({
  display: "inline",
  width: "30%",
  fontFamily: "'Manrope-Medium'",
 //30% paddingRight:"15px"
});

const JS_CONT_EMAIL = ({ $theme }) => ({
  display: "inline",
  width: "40%",
  fontFamily: "'Manrope-Medium'",
  //paddingRight:"15px"
});

const JS_HEADING_SEC = ({ $theme }) => ({
  display: "inline",
  width: "15%",
  fontFamily: "Manrope-Bold",
});
const JS_HEADING_SEC_BADGE = ({ $theme }) => ({
  display: "inline",
  //width: "16%",
  fontFamily: "Manrope-Bold",
  padding:'15px 0',
  marginRight:'10px'
});

const JS_CONT_SEC = ({ $theme }) => ({
  display: "inline",
  width: "15%",
  fontFamily: "'Manrope-Medium'",
});
const JS_CONT_SEC_BADGE = ({ $theme }) => ({
  display: "inline",
  width: "16%",
  fontFamily: "'Manrope-Medium'",
  paddingTop:'10px'
});

const JS_HEADING_MAIN = ({ $theme }) => ({
  display: "flex",
  paddingBottom: "20px",
  borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
  width: "100%",
});
const JS_HEADING_CONT = ({ $theme }) => ({
  display: "flex",
  paddingBottom: "10px",
  paddingTop: "10px",
  width: "100%",
});

const AVATAR_STYLE = { borderRadius: "100%" };
const AVATAR_STYLE_IMG = { float: "left", paddingRight: "10px" };

const StudentData = (props) => {
  const [css, theme] = useStyletron();
  const setState = useSetState();
  const history = useHistory();

  const [activeBadge, setActiveBadge] = useState(null);
  const [row, setRow] = useState();

  const sortRoomTags = (tags) => {
    const tag_list = tags
    const new_arr = [];
    tag_list.map((obj,ind) =>{
    const tag_obj = {}
    tag_obj.tag_name = obj[0]
    tag_obj.tag_weight = obj[1]
       new_arr.push(tag_obj)
  })
    const tag_vals = new_arr.sort((a, b) => b.tag_weight - a.tag_weight);
    const slicedArray = tag_vals.slice(0, 6);
    return slicedArray;
  };
  let resp = props?.data[0]?.student_data;
  const goToAssignment = (assignment) => {
    // setState(
    //   (prev) => ({ ...prev, assignment }),
    //   () => {
    //     history.push("/assignment");
    //   }
    // );
  };

  const quizSec  = (data) =>{
    let flag = false;
    data?.map((key, ind) => {
      if(key.quiz_score !== 0 ){
        flag = true;
      }
    });
    return flag;
  }

  function range(tag) {
    return tag.split("=").pop();
  }

  return (
    <Block
      className={css(COMMON_CARD_STYLE(theme))}
      style={{ marginTop: "20px" }}
    >
      {props.cohort?.length === 0 ? (
        <Skeleton width="100%" height={`50px`} animation />
      ) : (
        <Block>
          {/* <Block className={css(HEADING_STYLE)} data-testid="track-progress-title">
        {"Student Data"}
      </Block> */}
          <Block width="98%" paddingRight="21px" className={css(JS_HEADING_MAIN(theme))}>
            <Block className={css(JS_HEADING(theme))}>{"Name"}</Block>
            <Block className={css(JS_HEADING_EMAIL(theme))}>{"Email"}</Block>
            {/* <Block className={css(JS_HEADING_SEC(theme))}>
              {"Badges Earned"}
            </Block> */}
            <Block className={css(JS_HEADING_SEC(theme))}>{"Progress"}</Block>
            <Block className={css(JS_HEADING_SEC(theme))}>
              {"Quizzes Taken"}
            </Block>
          </Block>
          <Block>
            {resp?.length > 0 ? (
              <Accordion
                accordion={false}
                initialState={{
                  expanded: (resp || []).map((_, phaseIndexAccordion) =>
                  null
                   // String(phaseIndexAccordion)
                  ),
                }}
                overrides={{
                  ToggleIcon,
                  Content: { style: PHASE_ACCORDION_CONTENT_STYLE },
                  Header: { style: PHASE_ACCORDION_HEADER_STYLE },
                  PanelContainer: { style: { borderBottomWidth: "1px" } },
                }}
              >
                {resp?.map((data, dataIndex) => (
                  <Panel
                    key={dataIndex}
                    title={
                      <Block
                        display="flex"
                       // gridGap="20px"
                        alignItems="center"
                        className={css(JS_HEADING_CONT(theme))}
                      >
                        {/* <PhaseBadge
                    name={phase.phase_image}
                    isFaded={(phase?.progress_phase?.progress_actual || 0) < 100}
                    showModal={(image) => setActiveBadge(image)}
                  /> */}
                        <Block
                          data-testid="track-progress-phase-name"
                          className={css(JS_CONT(theme))}
                        >
                          {data.student_name}
                        </Block>
                        <Block
                          data-testid="track-progress-phase-name"
                          className={css(JS_CONT_EMAIL(theme))}
                        >
                          {data.student_email}
                        </Block>
                        
                        <Block
                          data-testid="track-progress-phase-name"
                          className={css(JS_CONT_SEC(theme))}
                        >
                          {data.course_progress}{"%"}
                        </Block>
                        <Block
                          data-testid="track-progress-phase-name"
                          className={css(JS_CONT_SEC(theme))}
                        >
                          {data.quizzes_taken}
                        </Block>
                      </Block>
                    }
                  >
                    <Block style={{display:'inline-flex',width:'100%',paddingLeft:'15px'}}>
                      <Block style={{display:'inline-flex',width:'100%'}}>
                        <Block className={css(JS_HEADING_SEC_BADGE(theme))}> {"Badges Earned : "}</Block>
                        <Block
                          data-testid="track-progress-phase-name"
                          className={css(JS_CONT_SEC_BADGE(theme))}
                        >
                          <StatefulPopover
                            content={() => (
                              <div
                                data-testid="table-flex-chart-popover"
                                className={css({
                                  width: "260px",
                                  // height:"300px",
                                  backgroundColor: "white",
                                  float: "left",
                                  padding: "20px",
                                })}
                              >
                                {/* <Chart
                                                data={sortRoomTags(
                                                  track?.room_tags
                                                )}
                                                visibleLabel
                                                showChartValue={true}
                                                skillMax={100} 
                                                />*/}
                                {data?.badge_detail?.map((key, ind) =>
                                  key.badge_icon_url !== null ? (
                                    <Block style={{ minHeight: "34px" }}>
                                      <Block
                                        as="img"
                                        src={key.badge_icon_url}
                                        width="34px"
                                        height="34px"
                                        alt="User Avatar"
                                        className={css(AVATAR_STYLE_IMG)}
                                      />
                                      <Block
                                        style={{ padding: "10px 0px 0px 10px" }}
                                      >
                                        {key?.badge_name}
                                      </Block>
                                      <Block>
                                        <br />
                                      </Block>
                                    </Block>
                                  ) : null
                                )}
                              </div>
                            )}
                            triggerType={TRIGGER_TYPE.hover}
                            returnFocus
                            autoFocus
                          >
                            <div
                              data-testid="table-chart-flex"
                              className={css({
                                cursor: "pointer",
                              })}
                            >
                              <Block
                                as="img"
                                src={Badge}
                                alt="User Avatar"
                                className={css(AVATAR_STYLE_IMG)}
                              />
                            </div>
                          </StatefulPopover>
                          <Block
                            style={{
                              float: "left",
                              top: "5px",
                              position: "relative",
                              left: "5px",
                            }}
                          >
                            {data.badges_earned}
                          </Block>
                        </Block>
                    </Block>
                    <Block style={{display:'inline-flex',width:'100%',paddingLeft:'15px'}}>
                        <Block className={css(JS_HEADING_SEC_BADGE(theme))}> {"Assignments Approved: "}</Block>
                        <Block
                            style={{
                              float: "left",
                              top: "15px",
                              position: "relative",
                              left: "5px",
                            }}
                          >
                            {data.assignment_approved}
                          </Block>
                    </Block>
                    <Block style={{display:'inline-flex',width:'100%',paddingLeft:'15px'}}>
                        <Block className={css(JS_HEADING_SEC_BADGE(theme))}> {"Assignments Submitted : "}</Block>
                        <Block
                            style={{
                              float: "left",
                              top: "15px",
                              position: "relative",
                              left: "5px",
                            }}
                          >
                            {data.assignment_submitted}
                          </Block>
                    </Block>
                    </Block>
                    <Accordion
                      accordion={false}
                      initialState={{
                        expanded: (data?.lessons || []).map(
                          (_, phaseIndexAccordion) =>
                            //String(phaseIndexAccordion)
                            null
                        ),
                      }}
                      overrides={{
                        ToggleIcon,
                        Content: { style: PHASE_ACCORDION_CONTENT_STYLE },
                        Header: { style: PHASE_ACCORDION_CONT_STYLE },
                        PanelContainer: { style: { borderBottomWidth: "0px" } },
                      }}
                    >
                      {data?.lessons.map((phase, phaseIndex) => (
                        <Panel
                          key={phaseIndex}
                          title={
                            <Block
                              display="flex"
                              gridGap="20px"
                              alignItems="center"
                              width="100%"
                            >
                              {/* <PhaseBadge
                    name={phase.phase_image}
                    isFaded={(phase?.progress_phase?.progress_actual || 0) < 100}
                    showModal={(image) => setActiveBadge(image)}
                  /> */}
                              <Block style={{ display: "inline-flex",width:"100%" }}>
                                <Block
                                  data-testid="track-progress-phase-name"
                                  style={{ paddingTop: "5px", width: "80%", color:'rgb(58, 63, 223)' }}
                                >
                                  {phase.lesson_name}
                                </Block>
                                {/* <Block marginBottom="20px"> */}
                                <Block
                                  display="flex"
                                  gridGap="12px"
                                  alignItems="center"
                                  //marginLeft="100px"
                                >
                                  <Block
                                    as="span"
                                    className={css(TRACK_ITEM_CONTENT_STYLE)}
                                    style={{color:'rgb(58, 63, 223)'}}
                                  >
                                    {Math.round(phase?.lesson_progress * 100) /
                                      100 || 0}
                                    {"%"}
                                  </Block>
                                  <Block width="100px">
                                    <ProgressBar
                                      value={phase?.lesson_progress % 1 === 0 ? phase?.lesson_progress :
                                        (
                                          Math.round(
                                            phase?.lesson_progress * 100
                                          ) / 100
                                        ).toFixed(2) || 0
                                      }
                                      //  value={50}
                                    />
                                  </Block>
                                </Block>
                                {/* </Block> */}
                              </Block>
                            </Block>
                          }
                        >
                          <Accordion
                            accordion={false}
                            initialState={{
                              expanded: (phase?.room || []).map(
                                (track, trackIndexAccordion) =>
                                  track?.task_complete < 100
                                    ? String(trackIndexAccordion)
                                    : null
                              ),
                            }}
                            overrides={{
                              ToggleIcon,
                              Content: { style: TRACK_ACCORDION_CONTENT_STYLE },
                              Header: { style: TRACK_ACCORDION_HEADER_STYLE },
                              PanelContainer: {
                                style: { borderBottomWidth: "0px" },
                              },
                            }}
                          >
                            {phase?.room.map((track, trackIndex) => (
                              <Panel
                                key={trackIndex}
                                title={
                                  <Block
                                    display="flex"
                                    gridGap="15px"
                                    alignItems="center"
                                    width="100%"
                                  >
                                    {/* <MacroBadge
                          name={track.macro_certification_image}
                          isFaded={
                            (track?.progress_bar?.progress_learning_path?.progress_actual || 0) < 100
                          }
                          showModal={(image) => setActiveBadge(image)}
                        /> */}
                                    <Block
                                      as="img"
                                      src={Lesson}
                                      alt="User Avatar"
                                      className={css(AVATAR_STYLE)}
                                    />
                                    <Block
                                      className={css(TRACK_TITLE_STYLE(theme))}
                                      data-testid="track-progress-macro-name"
                                      width={'42%'}
                                    >
                                      {track?.room_name}
                                    </Block>
                                    {/* {track?.room_thm.length > 0 ? ( */}
                                    <Block
                                      //marginBottom="20px"
                                      marginLeft="5px"
                                      display="inline"
                                      width="14%"
                                      style={{'word-break':'break-all'}}
                                    >
                                      <Block
                                        marginBottom="4px"
                                        className={css(TRACK_ITEM_TITLE_STYLE)}
                                        data-testid="progress_text"
                                      >
                                        {"Range: "}
                                      </Block>
                                      <Block
                                        display="inline"
                                        gridGap="12px"
                                        alignItems="center"
                                      >
                                        {track?.room_thm.length > 0 ? 
                                        track?.room_thm?.map((obj, ind) => (
                                          <Block
                                            as="span"
                                            className={css(
                                              TRACK_ITEM_CONTENT_THME
                                            )}
                                          >
                                            {range(obj)}
                                            {ind < track?.room_thm?.length - 1
                                              ? ","
                                              : ""}
                                          </Block>
                                        )) : "-"
                                      }
                                      </Block>
                                    </Block>
                                  {/* ) : null} */}
                                  <Block>
                                    
                                    <Block
                                      display="flex"
                                      gridGap="12px"
                                      alignItems="center"
                                    >
                                      <Block
                                        as="span"
                                        className={css(
                                          TRACK_ITEM_CONTENT_STYLE_PROGRESS
                                        )}
                                      >
                                        {track?.task_complete % 1 === 0 ? track?.task_complete : (
                                          Math.round(
                                            track?.task_complete * 100
                                          ) / 100
                                        ).toFixed(2) || 0}
                                        {"%"}
                                      </Block>
                                      <Block width="100px">
                                        <ProgressBar
                                          value={track?.task_complete || 0}
                                          //  value={50}
                                        />
                                      </Block>
                                    </Block>
                                  </Block>
                                  {track?.room_tags?.length > 0 ? (
                                    <Block
                                      marginBottom="20px"
                                      marginTop="15px"
                                      marginLeft="50px"
                                    >
                                      {/* <Block
                                        marginBottom="4px"
                                        className={css(TRACK_ITEM_TITLE_STYLE)}
                                        data-testid="progress_text"
                                      >
                                        {"Tags: "}
                                      </Block> */}
                                      <Block
                                        display="flex"
                                        gridGap="12px"
                                        alignItems="center"
                                      >
                                        <StatefulPopover
                                          content={() => (
                                            <div
                                              data-testid="table-flex-chart-popover"
                                              className={css({
                                                width: "500px",
                                                backgroundColor: "white",
                                              })}
                                            >
                                              <Chart
                                                data={sortRoomTags(
                                                  track?.room_tags
                                                )}
                                                visibleLabel
                                                showChartValue={true}
                                                skillMax={100}
                                              />
                                            </div>
                                          )}
                                          triggerType={TRIGGER_TYPE.hover}
                                          returnFocus
                                          autoFocus
                                        >
                                          <div
                                            data-testid="table-chart-flex"
                                            className={css({
                                              cursor: "pointer",
                                            })}
                                          >
                                            <Chart
                                              data={sortRoomTags(
                                                track?.room_tags
                                              )}
                                              style={{
                                                height: "45px",
                                                width: "45px",
                                              }}
                                              showChartValue={true}
                                              skillMax={100}
                                            />
                                          </div>
                                        </StatefulPopover>
                                      </Block>
                                    </Block>
                                    ) : null}
                                  </Block>
                                  
                                }
                              >
                                {/* <Block display={"flex"}>
                                  
                                  {track?.room_tags?.length > 0 ? (
                                    <Block
                                      marginBottom="20px"
                                      //marginLeft="100px"
                                    >
                                      <Block
                                        marginBottom="4px"
                                        className={css(TRACK_ITEM_TITLE_STYLE_RADAR)}
                                        data-testid="progress_text"
                                      >
                                        {"Tags"}
                                      </Block>
                                      <Block
                                        display="flex"
                                        gridGap="12px"
                                        alignItems="center"
                                      >
                                        <StatefulPopover
                                          content={() => (
                                            <div
                                              data-testid="table-flex-chart-popover"
                                              className={css({
                                                width: "500px",
                                                backgroundColor: "white",
                                              })}
                                            >
                                              <Chart
                                                data={sortRoomTags(
                                                  track?.room_tags
                                                )}
                                                visibleLabel
                                                showChartValue={true}
                                                skillMax={100}
                                              />
                                            </div>
                                          )}
                                          triggerType={TRIGGER_TYPE.hover}
                                          returnFocus
                                          autoFocus
                                        >
                                          <div
                                            data-testid="table-chart-flex"
                                            className={css({
                                              cursor: "pointer",
                                            })}
                                          >
                                            <Chart
                                              data={sortRoomTags(
                                                track?.room_tags
                                              )}
                                              style={{
                                                height: "64px",
                                                width: "64px",
                                              }}
                                              showChartValue={true}
                                              skillMax={100}
                                            />
                                          </div>
                                        </StatefulPopover>
                                      </Block>
                                    </Block>
                                    ) : null}
                              
                                </Block> */}
                                {quizSec(track.quiz) === true ? 
                                <TableBuilder
                                  data={track.quiz}
                                  overrides={{
                                    Root: { style: TABLE_ROOT_OVERRIDE_STYLE },
                                    TableBodyRow: {
                                      component: ({ children }) => (
                                        <tr>{children}</tr>
                                      ),
                                    },
                                  }}
                                  emptyMessage="No data"
                                  className="track_progress__table"
                                >
                                  <TableBuilderColumn
                                    header={
                                      <Block
                                        className={css(
                                          TABLE_HEADER_STYLE(theme)
                                        )}
                                      >
                                        Quiz
                                      </Block>
                                    }
                                    overrides={{
                                      TableHeadCell: {
                                        style: FIRST_ROW_OVERRIDE_STYLE,
                                      },
                                      TableBodyCell: {
                                        style: FIRST_ROW_OVERRIDE_STYLE,
                                      },
                                    }}
                                  >
                                    {(row) => (
                                      <Block
                                        display="flex"
                                        gridGap="16px"
                                        alignItems="center"
                                        //className={css(TD_STYLE(theme))}
                                      >
                                        {/* <MicroBadge
                              width="32px"
                              name={row.course_image}
                              isFaded={
                                (row?.course_progress?.progress_actual || 0) < 100
                              }
                              showModal={(image) => setActiveBadge(image)}
                            /> */}
                                        <Block
                                          onClick={() => goToAssignment(row)}
                                          className={css(
                                            MODULE_NAME_STYLE(theme)
                                          )}
                                          data-testid="track-progress-micro-name"
                                        >
                                          {row.quiz_name}
                                        </Block>
                                      </Block>
                                    )}
                                  </TableBuilderColumn>
                                  <TableBuilderColumn
                                    header={
                                      <Block
                                        className={css(
                                          TABLE_HEADER_STYLE(theme)
                                        )}
                                      >
                                        Quiz Score
                                      </Block>
                                    }
                                    overrides={{
                                      TableHeadCell: {
                                        style: SECOND_ROW_OVERRIDE_STYLE,
                                      },
                                      TableBodyCell: {
                                        style: SECOND_ROW_OVERRIDE_STYLE,
                                      },
                                    }}
                                  >
                                    {(row) => (
                                      <Block
                                        display="flex"
                                        gridGap="16px"
                                        alignItems="center"
                                        //className={css(TD_STYLE(theme))}
                                      >
                                        {/* <MicroBadge
                              width="32px"
                              name={row.course_image}
                              isFaded={
                                (row?.course_progress?.progress_actual || 0) < 100
                              }
                              showModal={(image) => setActiveBadge(image)}
                            /> */}
                                        <Block
                                          onClick={() => goToAssignment(row)}
                                          className={css(
                                            MODULE_NAME_STYLE(theme)
                                          )}
                                          data-testid="track-progress-micro-name"
                                        >
                                          {(
                                            Math.round(row?.quiz_score * 100) /
                                            100
                                          ).toFixed(2)}
                                        </Block>
                                      </Block>
                                    )}
                                  </TableBuilderColumn>
                                  <TableBuilderColumn
                                    header={
                                      <Block
                                        className={css(
                                          TABLE_HEADER_STYLE(theme)
                                        )}
                                      >
                                        Attempts
                                      </Block>
                                    }
                                    overrides={{
                                      TableHeadCell: {
                                        style: SECOND_ROW_OVERRIDE_STYLE,
                                      },
                                      TableBodyCell: {
                                        style: SECOND_ROW_OVERRIDE_STYLE,
                                      },
                                    }}
                                  >
                                    {(row) => (
                                      <Block
                                        display="flex"
                                        gridGap="16px"
                                        alignItems="center"
                                        //className={css(TD_STYLE(theme))}
                                      >
                                        {/* <MicroBadge
                              width="32px"
                              name={row.course_image}
                              isFaded={
                                (row?.course_progress?.progress_actual || 0) < 100
                              }
                              showModal={(image) => setActiveBadge(image)}
                            /> */}
                                        <Block
                                          onClick={() => goToAssignment(row)}
                                          className={css(
                                            MODULE_NAME_STYLE(theme)
                                          )}
                                          data-testid="track-progress-micro-name"
                                        >
                                          {row.attempts}
                                        </Block>
                                      </Block>
                                    )}
                                  </TableBuilderColumn>
                                </TableBuilder> : null}
                              </Panel>
                            ))}
                          </Accordion>
                        </Panel>
                      ))}
                    </Accordion>
                  </Panel>
                ))}
              </Accordion>
            ) : (
              <Block style={{ paddingTop: "20px" }}>{"No Data"}</Block>
            )}
          </Block>

          {/* {activeBadge && <BadgeModal badge={activeBadge} close={() => setActiveBadge(null)} />} */}
        </Block>
      )}
    </Block>
  );
};

export default StudentData;
