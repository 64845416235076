import { useStyletron } from "baseui";
import { Block } from "baseui/block";
import { Button } from "baseui/button";
import { NestedMenus, StatefulMenu } from "baseui/menu";
import { Popover } from "baseui/popover";
import React, { useCallback, useEffect, useState } from "react";
import { COHORT_LIST } from "resources/constants/strings";
import {
  fetchCohortData,
  fetchGrade,
  fetchQuest,
  postCohort,
} from "services/requests/cohort_student_data";
import {
  COMMON_BORDER_COLOR,
  COMMON_BORDER_STYLE,
  COMMON_BORDER_WIDTH,
  COMMON_RADIUS,
} from "utils/style";

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonGroup } from "baseui/button-group";
import { ChevronDown, ChevronUp } from "baseui/icon";
import { Input } from "baseui/input";
import { Modal, ModalBody } from "baseui/modal";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Textarea } from "baseui/textarea";

const View_AS = {
  display: "inline-block",
  marginLeft: 0,
};

const COMMON_TEXT_STYLE = {
  fontSize: "14px",
  lineHeight: "19px",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const TITLE_STYLE = (theme) => ({
  fontFamily: "Manrope-Bold",
  fontSize: "24px",
  lineHeight: "33px",
  marginBottom: "20px",
  [theme.mediaQuery.small]: {
    fontSize: "18px",
  },
  [theme.mediaQuery.large]: {
    fontSize: "24px",
  },
});

const BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  backgroundColor: "white",
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  borderColor: $theme.RAColors?.purple,
  paddingTop: "12px",
  paddingBottom: "12px",
  paddingRight: "24px",
  paddingLeft: "24px",
  ":hover": { backgroundColor: $theme.RAColors?.gray300 },
  color: $theme.RAColors?.purple,

  [$theme.mediaQuery.small]: {
    width: "-webkit-fill-available",
  },
  [$theme.mediaQuery.medium]: {
    width: "max-content",
  },
});

const MENU_OVERRIDE_STYLE = ({ $theme }) => ({
  width: "5rem",
  maxHeight: "300px",
  overflowY: "auto",
  border: "none",
  position: "fixed",
  alignSelf: "start",
  right: "5rem",
  top: "1rem",
  transform: "none",
  [$theme.mediaQuery.small]: {
    width: "72%",
  },
  [$theme.mediaQuery.medium]: {
    width: "max-content",
  },
});

const INPUT_OVERRIDE_STYLE = {
  Input: ({ $theme }) => ({
    backgroundColor: "none",
    color: $theme.RAColors?.gray900,
    fontFamily: "Manrope",
  }),
  Root: ({ $theme, $error }) => ({
    maxWidth: "100%",
    backgroundColor: "none",
    ...COMMON_RADIUS("4px"),
    ...COMMON_BORDER_WIDTH(
      $error ? $theme.RAColors?.red600 : $theme.RAColors?.gray200
    ),
    ...COMMON_BORDER_WIDTH("1px"),
    [$theme.mediaQuery.small]: {
      width: "auto",
    },
    [$theme.mediaQuery.medium]: {
      width: "400px",
    },
  }),
};

const DOWNLOAD_ICON_STYLE = {
  width: "0.89rem",
  marginRight: "16px",
  color: "red",
  cursor: "pointer !important",
};

const HEADING_TEXT_STYLE = (theme) => ({
  ...COMMON_TEXT_STYLE,
  fontSize: "24px",
  color: theme.RAColors?.black,
});

const MODAL_BODY_STYLE = (theme) => ({
  margin: "24px !important",
  [theme.mediaQuery.small]: {
    margin: "16px !important",
  },
  [theme.mediaQuery.medium]: {
    margin: "24px !important",
  },
});

const SAVE_BTN_STYLE = ({ $theme }) => ({
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  ...COMMON_BORDER_COLOR($theme.RAColors?.blue700),
  width: "max-content",
  paddingLeft: "24px",
  paddingRight: "24px",
  paddingBottom: "10px",
  paddingTop: "10px",
  fontFamily: "Manrope-Bold",
  fontStyle: "normal",
  fontSize: "16px",
  fontWeight: "700",
  justifyContent: "center",
  alignItems: "center",
  lineHeight: "22px",
  marginTop: 0,
  ...COMMON_RADIUS("8px"),
  marginBottom: "0px",
  backgroundColor: $theme.RAColors?.blue700,
  color: "#FFFFFF",
  ":hover": {
    backgroundColor: $theme.RAColors?.blue800,
  },
  [$theme.mediaQuery.small]: {
    width: "100%",
    marginBottom: "12px",
  },
  [$theme.mediaQuery.medium]: {
    width: "initial",
    marginBottom: "0px",
  },
});

const CANCEL_BUTTON_OVERRIDE_STYLE = ({ $theme }) => ({
  backgroundColor: "white",
  ...COMMON_RADIUS("8px"),
  ...COMMON_BORDER_WIDTH("1px"),
  ...COMMON_BORDER_STYLE("solid"),
  borderColor: $theme.RAColors?.purple,
  paddingTop: "10px",
  paddingBottom: "10px",
  paddingRight: "24px",
  paddingLeft: "24px",
  marginLeft: "12px",
  fontFamily: "Manrope",
  fontStyle: "normal",
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "22px",
  display: "flex",
  ":hover": { backgroundColor: $theme.RAColors?.gray300 },
  color: $theme.RAColors?.purple,

  [$theme.mediaQuery.small]: {
    width: "100%",
  },
  [$theme.mediaQuery.medium]: {
    width: "initial",
  },
});

function downloadCSV(data, filename) {
  const csv = data.join("\n");
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function NestedMenu() {
  const [css, theme] = useStyletron();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [options, setOptions] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [names, setNames] = useState({
    learning_path_name_old: "",
    learning_path_name_new: "",
  });

  const today = new Date();
  const month = (today.getMonth() + 1).toString().padStart(2, "0");
  const year = today.getFullYear();
  const date = today.getDate().toString().padStart(2, "0");

  const handleClick = (item) => {
    if (selectedItem === item) {
      // setSelectedItem(null); // Close submenu if same item is clicked again
    } else {
      setSelectedItem(item);
      getQuestList(item.label);
    }
  };

  const handleDownloadCsv = (e, label) => {
    e.preventDefault();
    e.stopPropagation(); // Prevent the event from bubbling up and closing the menu
    getGradeList(label);
  };

  const renderMenu = (items) => {
    return (
      <Popover
        isOpen
        overrides={{
          Body: {
            props: {
              className: !items[0]?.img ? "custom-popover" : "custom-left",
            },
          },

          preventOverflow: { enabled: false }, // Add the preventOverflow modifier
        }}
        content={
          <StatefulMenu
            items={items}
            onItemSelect={({ item }) => !item?.img && handleClick(item)}
            overrides={{
              List: {
                style: MENU_OVERRIDE_STYLE,
                props: {
                  className: items[0]?.img && "cursor-pointer",
                },
              },

              Option: {
                props: {
                  size: "compact",
                  style: {
                    cursor: items[0]?.img && "default",
                    backgroundColor: "white",
                  },
                  isHighlighted: (item) => selectedItem === item,
                  getItemLabel: (item, i) => {
                    return (
                      <>
                        {!item.img ? (
                          <Block>
                            <p
                              style={{
                                display: "inline-block",
                              }}
                              className="cursor-none"
                              onClick={(e) => console.log("hi")}
                            >
                              {item?.label}
                            </p>

                            <FontAwesomeIcon
                              data-tooltip-id="my-tooltip-1"
                              icon={faEdit}
                              className={css(DOWNLOAD_ICON_STYLE)}
                              onClick={(e) => {
                                e.stopPropagation();
                                setNames({
                                  learning_path_name_old: item?.label,
                                  learning_path_name_new: item?.label,
                                });
                                setIsOpenModal(true);
                              }}
                              size={30}
                              style={{
                                color: "blue",
                                width: 20,
                                height: 15,
                              }}
                            />
                          </Block>
                        ) : (
                          <>
                            <p
                              style={{
                                paddingRight: 50,
                                display: "inline-block",
                              }}
                              aria-selected={true}
                              aria-expanded={!!item.children}
                            >
                              {item.label}
                            </p>

                            <FontAwesomeIcon
                              icon={faDownload}
                              className={css(DOWNLOAD_ICON_STYLE)}
                              onClick={(e) => handleDownloadCsv(e, item.label)}
                              style={{ color: "blue" }}
                            />
                          </>
                        )}
                      </>
                    );
                  },
                  getChildMenu: (item) => {
                    if (item.children && selectedItem === item) {
                      return renderMenu(item.children);
                    }

                    return undefined;
                  },

                  onMouseEnter: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  },
                },
              },
            }}
          />
        }
      >
        <span />
      </Popover>
    );
  };
  const getKhoothScore = () => {
    fetchCohortData()
      .then(function (response) {
        const array = Object.entries(response.data).map(([key, value]) => {
          return { label: value };
        });
        setOptions(array);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getQuestList = useCallback(
    (name, callback) => {
      fetchQuest(name)
        .then(function (response) {
          const array = Object.entries(response.data).map(([key, value]) => {
            return { label: value, img: true };
          });
          const updatedOptions = options.map((item) => {
            if (item.label === name) {
              item.children = array;
            }
            return item;
          });
          setOptions(updatedOptions);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    [options]
  );
  const getGradeList = (label) => {
    fetchGrade(selectedItem.label, label)
      .then(function (response) {
        const file = selectedItem.label.split(" ").join("_");
        const numbersArray = `q${label.match(/\d+/g)[0]}.csv`;

        console.log(numbersArray);
        downloadCSV(
          Array(response.data),
          `${file}_${numbersArray}_${year}${month}${date}`
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getKhoothScore();
  }, []);

  const handleMenu = () => {
    if (!isOpenMenu) {
      const updatedOption = options.map(({ childrean, ...rest }) => rest);
      setOptions(updatedOption);
    }
    setIsOpenMenu(!isOpenMenu);
  };

  const handleModal = () => {
    setIsOpenModal(!isOpenModal);
  };
  const handelForm = (data) => {
    postCohort(data)
      .then(() => {
        getKhoothScore();
        setIsOpenModal(false);
      })
      .catch((err) => {});
  };
  return (
    <>
      <Button
        onClick={() => handleMenu()}
        overrides={{ BaseButton: { style: BUTTON_OVERRIDE_STYLE } }}
      >
        <span>{COHORT_LIST}</span>
        {!isOpenMenu ? <ChevronDown size={30} /> : <ChevronUp size={30} />}
      </Button>
      {isOpenMenu && (
        <Block className={css(View_AS)}>
          <NestedMenus>{renderMenu(options)}</NestedMenus>
        </Block>
      )}
      <Modal
        onClose={handleModal}
        closeable
        isOpen={isOpenModal}
        animate
        autoFocus
      >
        <ModalBody className={css(MODAL_BODY_STYLE(theme))}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handelForm(names);
            }}
            style={{ width: "100%" }}
          >
            <Block
              display="block"
              justifyContent="space-between"
              alignItems="center"
              gridGap="16px"
              marginBottom={["16px", "16px", "20px"]}
              flexWrap="wrap"
            >
              <Block className={css(TITLE_STYLE(theme))}>
                <Block
                  as="h5"
                  margin={0}
                  className={css(HEADING_TEXT_STYLE(theme))}
                >
                  <span>{"Cohort Name"}</span>
                </Block>
              </Block>
              <Block>
                <Textarea
                  value={names.learning_path_name_new || ""}
                  onChange={(e) =>
                    setNames({
                      ...names,
                      learning_path_name_new: e.target.value,
                    })
                  }
                  data-testid="roleNameInput"
                  required={true}
                  overrides={{
                    Input: { style: INPUT_OVERRIDE_STYLE.Input },
                    Root: { style: INPUT_OVERRIDE_STYLE.Root },
                  }}
                  autoFocus
                  size="compact"
                />
              </Block>
            </Block>
            <Block>
              <ButtonGroup
                size="compact"
                overrides={{
                  Root: { style: { display: "flex", gap: "20px" } },
                }}
              >
                <Button
                  type="submit"
                  data-testid="save"
                  overrides={{ BaseButton: { style: SAVE_BTN_STYLE } }}
                >
                  Save
                </Button>
                <Button
                  type="button"
                  onClick={handleModal}
                  overrides={{
                    BaseButton: { style: CANCEL_BUTTON_OVERRIDE_STYLE },
                  }}
                  data-testid="cancel"
                >
                  Cancel
                </Button>
              </ButtonGroup>
            </Block>
          </form>
        </ModalBody>
      </Modal>
      <ReactTooltip
        id="my-tooltip-1"
        place="top"
        effect="solid"
        style={{ zIndex: 12000 }}
      >
        Edit
      </ReactTooltip>
    </>
  );
}

export default React.memo(NestedMenu);
